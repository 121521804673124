import React, { useCallback, useContext, useEffect, useState } from "react";
import ULModal from "../../../Common/Modal";
import { getCustomerDeatils, saveCustomer } from "../../../Services/PomService";
import { COLUMNS } from "./CustomerCols";
import { notify } from "../../../Common/toast";
import { BlockInvalidChar } from "../../../utility/helper";

const AddCustomer = (props) => {
  let [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dealStatus, setDealStatus] = useState([]);
  const getCustomerData = useCallback((payload) => {
    getCustomerDeatils(payload)
      .then((response) => {
        setDealStatus(response.custdealstatus);
        if (response.customerDetails?.length)
          setCustomers([...response.customerDetails]);
        else {
          let customer = {
            customerNumber: 1,
            customer_name: "",
            customer_price: "",
            customer_quantity: props.record.qty_to_offer ?? "0",
            customer_quantity_cs:props.record.qtyToOfferCs??"0",
            customer_week: "",
            customer_deal_status: "",
            mrdr_id: props.record.mrdr_id,
            descriptionId: props.record.descriptionId,
            status_id: props.record.status_id,
            plantCodeId: props.record.plantCodeId,
            countryId: props.record.countryId,
            customer_gross_margin:""
          };
          customers.push(customer);
          setCustomers([...customers]);
        }
      })
      .catch(() => {
        notify({
          type: "error",
          message: "Something went wrong",
        });
      });
  }, []);

  useEffect(() => {
    if (props.show)
      getCustomerData({
        mrdr_id: props.record.mrdr_id,
        descriptionId: props.record.descriptionId,
        status_id: props.record.status_id,
        plantCodeId: props.record.plantCodeId,
        countryId: props.record.countryId,
        customerRefId: props.record.customerRefId,
        costprice: props.record.costprice
      });
  }, [getCustomerData, props.record, props.show]);
  const newCustomer = () => {
    if (
      customers.length &&
      (props.tabKey=='ZUN'?!customers[customers.length - 1].customer_quantity:!customers[customers.length - 1].customer_quantity_cs)
    ) {
      notify({
        type: "warn",
        message: "There is no quantity available in recent customer",
      });
      return;
    } else if (
      customers.length &&
      customers
        .map((i) => parseFloat(props.tabKey=='ZUN'?(i.customer_quantity ?? 0):(i.customer_quantity_cs??0)))
        .reduce((acc, val) => acc + val, 0) >= ((props.tabKey=='ZUN'?props.record.qty_to_offer:props.record.qtyToOfferCs) ?? 0)
    ) {
      notify({
        type: "warn",
        message: "Overall quantity more than Quantity available for sales offer"
      });
      return;
    }
    let customer = {
      customerNumber: customers.length + 1,
      customer_name: "",
      customer_price: "",
      customer_quantity:
        (props.record.qty_to_offer ?? "0") -
        customers
          .map((i) => parseFloat(i.customer_quantity ?? 0))
          .reduce((acc, val) => acc + val, 0),
      customer_quantity_cs:
        (props.record.qtyToOfferCs ?? "0") -
        customers
          .map((i) => parseFloat(i.customer_quantity_cs ?? 0))
          .reduce((acc, val) => acc + val, 0),
      customer_week: "",
      customer_deal_status: "",
      mrdr_id: props.record.mrdr_id,
      descriptionId: props.record.descriptionId,
      status_id: props.record.status_id,
      plantCodeId: props.record.plantCodeId,
      countryId: props.record.countryId,
      customer_gross_margin: "",
    };
    customers.push({ ...customer });
    setCustomers([...customers]);
  };
  const handleCellChange = (value, prop, index) => {
    if (prop === "customer_price") {
      if (value) {
        customers[index]["customer_gross_margin"] =
          ((value - (props.record.costprice??0)) * (100 / value)).toFixed(2);
      } else {
        customers[index]["customer_gross_margin"] = "";
      }
    }
    customers[index][prop] = value;
    setCustomers([...customers]);
  };
  const deleteCustomer = (id, index) => {
    if (id) {
      customers = customers.filter((val) => val.customerNumber !== id);
      customers.forEach((val, index) => {
        val.customerNumber = index + 1;
      });
    } else {
      customers.splice(index, 1);
    }
    setCustomers([...customers]);
  };
  const handleUpdateCustomers = () => {
    var total = 0;
    for (let i = 0; i <= 10; i++) {
      var status = customers[i]?.customer_deal_status;
      if (status && status !== "4") {
        total += (props.tabKey=='ZUN'?+customers[i]?.customer_quantity:
          // props.tabKey=='CASES'?+customers[i]?.customer_quantity:
          +customers[i]?.customer_quantity_cs);
      }
    }
    setIsLoading(true);
    saveCustomer({
      custData: customers,
      costprice: props.record.costprice,
      customerRefId: props.record.customerRefId,
      multiplicationFactorToCs: props.record.multiplicationFactorToCs,
      multiplicationFactorToCases: props.record.multiplicationFactorToCases,
      typeOfMeasure: props.tabKey == "ZUN" ? "ZUN" : props.tabKey == "CASES"?"CASES":"CU",
      totalQtyCases: props.tabKey == "CASES" ? props.record.totalQtyCases : total,
      totalLeftCases: props.tabKey == "CASES"
      ? ((props.record.qtyToOfferCases ?? 0) - (total ?? 0)) > 0
        ? (props.record.qtyToOfferCases ?? 0) - (total ?? 0)
        : 0
      : props.record.totalLeftCases,
      total_qty: props.tabKey == "CU" ? props.record.total_qty : total,
      total_left:
        props.tabKey == "ZUN"
          ? ((props.record.qty_to_offer ?? 0) - (total ?? 0)) > 0
            ? (props.record.qty_to_offer ?? 0) - (total ?? 0)
            : 0
          : props.record.total_left,
      totalLeftCs:
        props.tabKey == "CU"
          ? ((props.record.qtyToOfferCs ?? 0) - (total ?? 0)) > 0
            ? (props.record.qtyToOfferCs ?? 0) - (total ?? 0)
            : 0
          : props.record.totalLeftCs,
      totalQtyCs: props.tabKey == "CU" ? total : props.record.totalQtyCs,
    })
      .then((response) => {
        setIsLoading(false);
        if (response) {
          notify({ type: "success", message: "Submitted successfully" });
          props.onHide();
          props.reLoad();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <ULModal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      isheader="true"
      heading={
        props.rowdata[0][0].column.Header.id == "id"
          ? props.rowdata[0].value
          : ""
      }
      className={`page-modal addCustomersPopup`}
    >
      <div className="row">
        <div className="col-md-3 position-relative">
          <div className="listings-wrap">
            <div className="listings no-cta">
              {COLUMNS.map((key, index) => {
                if (props.record[key.accessor] !== undefined) {
                  return (
                    <div
                      className={`row-rowdata item d-flex ${
                        props.record[key.accessor]
                      }`}
                      key={index}
                    >
                      <>
                        <div className="prop">
                          <p title={key.Header}>{key.Header}</p>
                        </div>
                        <div className="val">
                          <p title={props.record[key.accessor]}>
                            {" "}
                            &nbsp; : &nbsp;{props.record[key.accessor]}
                          </p>
                        </div>
                      </>
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className="listings-wrap-overlay"></div>
        </div>
        <div className="col-md-9">
          <div className="d-flex  flex-column justify-content-between h-100">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <h5>Add Customers </h5>
              <button
                disabled={customers.length > 9}
                type="button"
                className="btn btn-secondary btn-slim btn-add-customers"
                onClick={() => newCustomer()}
              >
                <span className="btn-in">
                  <svg
                    width="9"
                    height="8"
                    viewBox="0 0 9 8"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path d="M4.45898 0L4.45898 8" />
                    <path d="M0.274902 4L8.64187 4" />
                  </svg>
                  <span>Add Customer</span>
                </span>
              </button>
            </div>
            <div className="d-flex flex-column justify-content-between flex-basis-100">
              <div className="table_design d-flex">
                <div className="table sticky scroll  data-table-two display nowrap scrollclass">
                  <div className="header">
                    <div className="tr">
                      <div className="th">Name</div>
                      <div className="th">Price</div>
                      <div className="th">Quantity</div>
                      <div className="th">Week</div>
                      <div className="th">Deal Status</div>
                      <div className="th">Cost Price</div>
                      <div className="th">Gross Margin</div>
                      <div className="th actions-btns">Action</div>
                    </div>
                  </div>
                  <div className="body">
                    {customers.map((customer, index) => (
                      <div className="tr" key={index}>
                        <div className="td">
                          <input
                            type="text"
                            className="bg-black "
                            value={customer.customer_name}
                            onChange={(e) => {
                              handleCellChange(
                                e.target.value,
                                "customer_name",
                                index
                              );
                            }}
                          />
                        </div>
                        <div className="td">
                          <span className=""></span>
                          <input
                            type="number"
                            min={0}
                            className="bg-black"
                            value={customer.customer_price}
                            onChange={(e) => {
                              handleCellChange(
                                e.target.value,
                                "customer_price",
                                index
                              );
                            }}
                          />
                        </div>
                        <div className="td">
                          <input
                            min={0}
                            type="number"
                            className="bg-black"
                            value={props.tabKey=='ZUN'?customer.customer_quantity:customer.customer_quantity_cs}
                            onChange={(ev) => {
                              if (
                                parseFloat(ev.target.value ?? "0") >
                                  parseFloat(
                                    (props.tabKey=='ZUN'?props.record.qty_to_offer:props.record.qtyToOfferCs) ?? "0"
                                  ) ||
                                customers
                                  .map((i, indexValue) =>
                                    parseFloat(
                                      indexValue === index
                                        ? ev.target.value ?? 0
                                        : (props.tabKey=='ZUN'?(i.customer_quantity??0):(i.customer_quantity_cs??0))
                                    )
                                  )
                                  .reduce((acc, val) => acc + val, 0) >
                                  ((props.tabKey=='ZUN'?props.record.qty_to_offer:props.record.qtyToOfferCs) ?? 0)
                              ) {
                                notify({
                                  type: "warn",
                                  message:
                                    "Should not enter more than Quantity available for sales offer",
                                });
                                ev.preventDefault();
                              } else {
                                handleCellChange(
                                  ev.target.value,
                                  props.tabKey=='ZUN'?"customer_quantity":"customer_quantity_cs",
                                  index
                                );
                              }
                            }}
                            onKeyDown={(ev) => {
                              if (ev.key === ".") {
                                ev.preventDefault();
                              }

                              BlockInvalidChar(ev);
                            }}
                          />
                        </div>
                        <div className="td">
                          <input
                            type="text"
                            className="bg-black"
                            value={customer.customer_week}
                            onChange={(e) => {
                              handleCellChange(
                                e.target.value,
                                "customer_week",
                                index
                              );
                            }}
                          />
                        </div>
                        <div className="td">
                          <select
                            className="input-edit-mode btn-slim"
                            onChange={(e) => {
                              handleCellChange(
                                e.target.value,
                                "customer_deal_status",
                                index
                              );
                            }}
                            autoFocus
                            value={
                              customer.customer_deal_status
                                ? customer.customer_deal_status
                                : "Select"
                            }
                          >
                            <option value="Select">Select</option>
                            {dealStatus.map((value) => (
                              <option value={value.id}>
                                {value.deal_status}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="td">
                          <input
                            type="text"
                            min={0}
                            title=""
                            disabled
                            className="bg-black"
                            value={props.record.costprice && `${props.record.costprice}€`}
                          />
                        </div>
                        <div className="td">
                          <input
                            type="number"
                            title=""
                            min={0}
                            disabled
                            className="bg-black"
                            value={customer.customer_gross_margin}
                          />
                        </div>
                        <div className="td actions-btns text-center">
                          <button
                            onClick={() => {
                              deleteCustomer(customer.customerNumber, index);
                            }}
                            type="button"
                            className="btn d-inline-block btn-round btn-primary size-sm text-white"
                            title="Remove Customer details"
                            key={index}
                          >
                            <span className="btn-round-in">-</span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* <div className="delete-customers-row-wrap">
                {customers.map((customer, index) => (
                  <div className="delete-customers-row">
                   <button type="button" className="btn btn-round btn-primary size-sm text-white" title="Remove Customer details" key={index} ><span className="btn-round-in">-</span></button>
                  </div>
                ))}
                </div> */}
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => handleUpdateCustomers()}
                  disabled={
                    (customers.length &&customers.filter((val) => val.customer_name === "")
                      .length) ||
                    isLoading
                  }
                  className={`btn btn-primary`}
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ULModal>
  );
};

export default AddCustomer;
