import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { getResidualVsProvisionData } from "../../../Services/WasteRiskSummaryService";
import {
  chunkString,
  LEGEND_COLOR,
  LABEL_COLOR,
  payloadWithFilters,
} from "../helpers";
import Loader from "../../../Common/loader"; 
import { UserAccessContext } from "../../Application";

const ResidualProvisionsChart = ({ dropDownValues,displayType ,showModal}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userAccess = useContext(UserAccessContext)


  useEffect(() => {
    if(dropDownValues){
      getChartData(payloadWithFilters(dropDownValues,userAccess));
     }
  }, [dropDownValues]);

  const getChartData = useCallback((payload) => {
    getResidualVsProvisionData(payload)
      .then((response) => {
        setChartData(response?.WasteRiskDetailsResult);
      })
      .finally(() => setIsLoading(false));
  });

  const options = {
    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "start",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          padding: 20,
          color: LEGEND_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },
    },
    responsive: true,
    aspectRatio: displayType==="popupDisplay"?0:1.1,
    scales: {
      x: {
        gird: {
          display: false,
        },
        ticks: {
          maxRotation: displayType==="popupDisplay"?0:45, // Set the maximum rotation angle to 0 degrees (horizontal)
          minRotation: displayType==="popupDisplay"?0:45,
          maxTicksLimit:displayType==="popupDisplay"?15:12,
          align:"left",
          color: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
          callback(value) {
            return chunkString(this.getLabelForValue(value), 10);
          },
        },
      },
      y: {
        gird: {
          display: false,
        },
        display: true,
        ticks:{
          // stepSize: 10000,
          callback: (value) => `€ ${(value / 1000000).toFixed()}M`,
          color: LABEL_COLOR,
          font: {
            family: "unilevershilling",
          },
        }
      },
    },
  };

  const labels = chartData?.map((data) => data.mco);
  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Provisions in €",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false,
        data: chartData?.map((data) => data.provisions.toFixed(2)),
        datalabels: {
          align: "top",
          display:false,
          color: "#000000",
          backgroundColor: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },
      {
        type: "bar",
        label: "Residual Stock in €",
        backgroundColor: "rgb(143, 202, 235)",
        data: chartData?.map((data) => data.residual),
        datalabels: {
          display:false,
          align: "top",
          anchor: "end",
          offset: -3,
          color: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },
    ],
  };

  if (isLoading) return <Loader hideText={true} />;

  return <Chart type="bar" data={data} options={options} />;
};

export default ResidualProvisionsChart;
