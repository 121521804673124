import React, { useState, useEffect, useCallback } from "react";
import ClickIcon from "../../../Assets/img/clickicon.svg";
import Loadersml from "../../../Common/loadersml";
import Offer from "./Offer";
import _ from "lodash";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory } from "react-router-dom";


const BiddingListBar = (props) => {
  const bucketNames = {
    eligible_for_central_deal: "Eligible for Central Deal",
    eligible_for_central_deal_ui: "Eligible for Central Deal UI",
    assigned_to_central_deal_topbrands: "Assigned to Central Deal - Top Brands",
    my_list: "My List",
  };
  const dealIds = {
    eligible_for_central_deal: "2",
    assigned_to_central_deal_topbrands: "4",
    my_list: "",
  }
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [isLoadingBiddingList, setLoadingBiddingList] = useState(true);
  const [isOfferModalOpen, setOfferModalOpen] = useState(false);
  const [isSidebarContentVisible, setSidebarContentVisible] = useState(false);
  const [biddingObject, setBiddingObject] = useState({});
  const [isNoData, setNoData] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const history = useHistory();
  const handleSidebarExpand = (sidebarStatus) => {
    props.onSidebarExpand(sidebarStatus);
  };

  const handleSidebarStatus = (sidebar_open_status) => {
    setSidebarStatus(sidebar_open_status);
    if (!props.isExternal) handleSidebarExpand(sidebar_open_status);
    setTimeout(() => {
      setSidebarContentVisible(sidebar_open_status);
    }, 500);
  };

  useEffect(() => {
    if (sidebarStatus && props.onNewListCreation) {
      handleSidebarStatus(true);
      props.onToggle(!sidebarStatus);
    }
  }, props.onNewListCreation);

  const [biddingList, setBiddingList] = useState({});

  useEffect(() => {
    setLoadingBiddingList(true);
  }, [props.newListName])

  useEffect(() => {
    setLoadingBiddingList(false);
    setBiddingList(props.biddingList);
    Object.keys(props.biddingList ?? {})?.map((key, index) => {
      if (props.biddingList[key] !== "number" && props.biddingList[key]?.length > 0) {
        setNoData(false);
      }
    })
  }, [props.biddingList]);

  var tooltip = (
    <Tooltip id="tooltip" style={{ height: "40px", width: "370px" }}>
      <p>kkkdk kk</p>
    </Tooltip>
  );

  const buildList = (list, i2) => {
    return <div className={`listings status-filled ${list?.detail?.biddingStatusClass}`} key={i2} >
      <OverlayTrigger
        overlay={<Tooltip id="tooltip">
          <div className="content">
            <p className="note big text-white">{list?.detail?.BiddingStatus} ({list?.detail?.biddingStatusClass})</p>
          </div>
        </Tooltip>}
        trigger={["hover", "focus"]}
        placement={props.isForVendor ? 'left' : 'top'}
        delay={{ show: 600, hide: 0 }}
      >
        <div
          className="item mb-2"
          onClick={() => {
            console.log("list--", list);
            setBiddingObject(list);
            setTimeout(() => {
              setOfferModalOpen(true);
              sessionStorage.setItem('bidding_list_opened', JSON.stringify({ id: list?.biddingListID, status: list?.detail?.BiddingStatus }))
            }, 100);
          }}
        >
          {list.biddingListName}
        </div>
      </OverlayTrigger>
    </div>
  }

  const handelEditlistRout=(x,y)=>{
    console.log(x,y)
    props.setEditBuddingListDealType(x)

    if(!props.isExternal){
      history.push({
        pathname: `/dealmanagement`,
        state: {
          dealId: x,
          heading: y,
        },
      })
    }

  
}

  return (
    <>
      <div className={`sidebar-in h-100 isExternal-${props.isExternal}`}>
        <div className="toggle-handle-wrap">
          <div
            className="toggle-handle"
            title={`${!sidebarStatus ? "Open" : "Close"} Bidding List`}
            onClick={() => {
              handleSidebarStatus(sidebarStatus ? false : true);
              props.onToggle(!sidebarStatus);
            }}
          >
            <span className="c"></span>
          </div>
        </div>
        <div
          className={`sidebar-content h-100 ${isSidebarContentVisible
              ? "sidebar_content_appear"
              : "sidebar_content_disappear"
            } isExternal-${props.isExternal}`}
        >
          {isLoadingBiddingList && (
            <div className="loader-sml">
              <Loadersml mp0={true} />
            </div>
          )}

          <div
            className="content-minimized"
            title={`${!sidebarStatus ? "Open" : "Close"} Bidding List`}
            onClick={() => {
              handleSidebarStatus(sidebarStatus ? false : true);
              props.onToggle(!sidebarStatus);
            }}
          >
            {!props.isExternal && <h5>Bidding Lists</h5>}
          </div>

          <div
            className="content-minimized-overlay"
            title={`${!sidebarStatus ? "Open" : "Close"} Bidding List`}
            onClick={() => {
              handleSidebarStatus(sidebarStatus ? false : true);
              props.onToggle(!sidebarStatus);
            }}
          ></div>

          <div className="content-maximized">
            <div>
              <div className="mb-4">
                <h4>Bidding Lists</h4>
                <div className="row-click-link d-flex align-items-center">
                  <img src={ClickIcon} alt="" />
                  <p className="mb-0">Click to edit</p>
                </div>
              </div>
              <div className="status-legend">
                <ul className="listing-dots p-0">
                  <li>
                    <div className="dot bg-green"></div>
                    <div className="text">Accepted</div>
                  </li>
                  <li>
                    <div className="dot bg-red"></div>
                    <div className="text">Rejected</div>
                  </li>
                  <li>
                    <div className="dot bg-blue"></div>
                    <div className="text">In-Progress</div>
                  </li>
                  <li>
                    <div className="dot bg-dull-border"></div>
                    <div className="text">Blank</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bidding-content">
              <div className="bidding-list-group-wrap">
                {!isLoadingBiddingList && isNoData && (
                  <div className="no-data body-container w-100 d-flex align-items-center justify-content-center">
                    <p className="note text-center mb-0">No Bidding Lists</p>
                  </div>
                )}
                {!isLoadingBiddingList &&
                  Object.keys(biddingList ?? {})?.map((key, index) => {
                    return (
                      <>
                        {typeof biddingList[key] !== "number" &&
                          biddingList[key]?.length > 0 && (
                            <div className="bidding-list-group" key={index}>
                              {biddingList[key]?.length > 0 && (
                                <div className="bidding-list-group-name">
                                  {bucketNames[key] ==
                                    "Eligible for Central Deal" && (
                                      <h5 className="text-sm text-green">
                                        {bucketNames[key]}:
                                      </h5>
                                    )}
                                  {bucketNames[key] ==
                                    "Eligible for Central Deal UI" && (
                                      <h5 className="text-sm text-purple">
                                        {bucketNames[key]}:
                                      </h5>
                                    )}
                                  {bucketNames[key] ==
                                    "Assigned to Central Deal - Top Brands" && (
                                      <h5 className="text-sm text-yellow">
                                        {bucketNames[key]}:
                                      </h5>
                                    )}
                                  {bucketNames[key] == "My List" && (
                                    <h5 className="text-sm text-white">
                                      {bucketNames[key]}:
                                    </h5>
                                  )}
                                </div>
                              )}
                              <div className="bidding-list-group-listings">
                                {biddingList[key]?.sg?.d.length > 0 && (
                                  <>
                                    <h5>Accepted ({biddingList[key]?.sg?.d.length})</h5>
                                    <div className="bidding-list-group-listings-in">
                                      {biddingList[key]?.sg?.d.map((lkkl, i2) => buildList(lkkl, i2))}
                                    </div>
                                  </>
                                )}
                                {biddingList[key]?.sg?.c.length > 0 && (
                                  <>
                                    <h5>Rejected ({biddingList[key]?.sg?.c.length})</h5>
                                    <div className="bidding-list-group-listings-in">
                                      {biddingList[key]?.sg?.c.map((lkkl, i2) => buildList(lkkl, i2))}
                                    </div>
                                  </>
                                )}
                                {biddingList[key]?.sg?.b.length > 0 && (
                                  <>
                                    <h5>In-progress ({biddingList[key]?.sg?.b.length})</h5>
                                    <div className="bidding-list-group-listings-in">
                                      {biddingList[key]?.sg?.b.map((lkkl, i2) => buildList(lkkl, i2))}
                                    </div>
                                  </>
                                )}
                                {biddingList[key]?.sg?.a.length > 0 && (
                                  <>
                                    <h5>Pending ({biddingList[key]?.sg?.a.length})</h5>
                                    <div className="bidding-list-group-listings-in" 
                                    onClick={() => handelEditlistRout(dealIds[key],bucketNames[key])}
                                    >
                                      {biddingList[key]?.sg?.a.map((lkkl, i2) => buildList(lkkl, i2))}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Both the admin and customer bidding portal modal */}
      {Object.keys(biddingObject).length > 0 && isOfferModalOpen && (
        <Offer
          show={isOfferModalOpen}
          onHide={() => setOfferModalOpen(false)}
          biddingObject={{ ...biddingObject }}
          setOfferModalOpen={(val) => {
            setOfferModalOpen(val);
          }}
          isExternal={props.isExternal}
          setIsEditBiddingList={props.setIsEditBiddingList}
          setEditedBiddingListData={props.setEditedBiddingListData}
        />
      )}
    </>
  );
};

export default React.memo(BiddingListBar);
