import React, { useContext, useEffect, useState } from "react";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import { withRouter } from "react-router";
import Dropdown from "../../Views/common/Dropdown";
import { useHistory } from "react-router-dom";
import InflowRiskVisibilityTable from "./InflowRiskVisibilityTable";
import { DropDownContext, UserContext } from "../Application";
import { getInflowUpdatedDate, getInflowUserPreferences, saveInflowUserPreferences } from '../../Services/InflowRiskVisibilityService';
import Crtracker from "../../Common/crtracker/Crtracker";

const InflowRiskVisibility = (props) => {
  const [dropDownValues, setDropDownValues] = useState();
  const [updateTime, setUpdatedTime] = useState('');
  const [dealList, setDealList] = useState([]);
  const history = useHistory();
  const [isExpand, setIsExpand] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [reload, setReload] = useState(false)
  const { setDropDownValues: setSelection } = useContext(DropDownContext);
  const [dropDownPreference,setDropDownPreference] = useState();
  const [columnSelected,SetColumnSelected]= useState()
  const [userSavePreferenceData, setUserSavePreferenceData]=useState()
  const [publishdropdownReload, setPublishdropdownReload]=useState(false)


  const [reeload,setreeload] = useState(false)

  const user = useContext(UserContext)


  const getUpdateDate = async () => {
    try {
      const updateDatedata = await getInflowUpdatedDate();
      setUpdatedTime(updateDatedata.update_date); // Assuming the key for update_date is "update_date" in the response data
    } catch (error) {
      console.error("Error while fetching update_date:", error);
    }

  }

  useEffect(() => {
    getUpdateDate()
  }, [])


  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };
  const handleSavePreference =()=>{
    const dummy = {
        actiontype:dropDownPreference?.actiontype??[],
        brand:dropDownPreference?.brand??[],
        forecastbiasL4w:dropDownPreference?.forecastbiasL4w??[],
        forecastbiasL12w:dropDownPreference?.forecastbiasL12w??[],
        forecastfamily:dropDownPreference?.forecastfamily??[],
        fsIndicator:dropDownPreference?.fsIndicator??[],
        riskLevel:dropDownPreference?.riskLevel??[],
        riskType:dropDownPreference?.riskType??[],
        businessGroup:dropDownPreference?.businessGroup??[],
        category:dropDownPreference?.category??[],
        country:dropDownPreference?.country??[],
         plant:dropDownPreference?.plant??[]
    }
    console.log(dropDownPreference)
    const data={

      preferenceData:dummy,
      selectedColumns:columnSelected?columnSelected:window.sessionStorage.getItem("preferences")?JSON.parse(window.sessionStorage.getItem("preferences")).selectedColumns:["all"]
    }
    const payload = {
      userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
      userRole:user.userRole,
      userEmail:user.email,
      userPreference:data
    }
    console.log(payload)
    saveInflowUserPreferences(payload).then((res)=>console.log(res))

  }
  const [ asddd,setasddd] =useState(false)
  useEffect(()=>{
    console.log(user)
    const dummy = {
      actiontype:[],
      brand:[],
      forecastbiasL4w:[],
      forecastbiasL12w:[],
      forecastfamily:[],
      fsIndicator:[],
      riskLevel:[],
      riskType:[],
      businessGroup:[],
      category:[],
      country:[],
       plant:[]
  }    
    getInflowUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)       
      window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
      setasddd(true)
      }    
      else{
        const payload = {
          userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
          userRole:user.userRole,
          userEmail:user.email,
          userPreference:{ preferenceData:dummy,
            selectedColumns:["all"]
          }}          
        console.log(payload)
        saveInflowUserPreferences(payload).then((res)=>{setreeload(!reeload)})
        console.log(dummy)
        window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:{},selectedColumns:['all']}))
      }  
      
    }
  )
  },[])
  useEffect(()=>{
      getInflowUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }       
    }
  )
  },[reeload])

  // useEffect(() => {
  //   setSelection({})
  // }, [])

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className="container-fluid tablegrid-height-fluid pl-0 pr-0">
        <div className="main_header align-baseline page-el">
        <Header isNotProduction={!isProd()}  updateTime={updateTime} updateTimeText={'Update Date: '} />
        </div>
        <div className={`main-content-row ${isExpand ? "table-expanded" : ""}`}>
          <div className="side-menu">
            <SideMenu />
          </div>
          <div className="main-div-content">
            {/* <div class="page-body-top-content">
              <div class="main-update-date">
                <p className="m-0">The above Data is as of {updateTime}</p>
              </div>
              <div className="d-inline-block ml-3">
                <Crtracker />
              </div>
            </div> */}
            {!isExpand && (
              <div className="page-el">
                {asddd &&<Dropdown
                  list={["Business Group", "Action Type", "Country", "Category", "Brand", "Plant Code", "Risk Type","DOH vs ISL Risk Level","Forecast Bias L12","Forecast Bias L4","Forecast family", "FoodSolutionIndicator"]}
                  setDealList={(val = []) => {
                    setDealList([...val]);
                  }}
                  onChange={(value) => {
                    setDropDownValues({ ...value });
                  }}
                  setDropDownValues={
                    setDropDownValues}
                  reload={reload}
                setReload={setReload}
                setDropDownPreference={setDropDownPreference}
                handleSavePreference={handleSavePreference}
                userSavePreferenceData={userSavePreferenceData}
                publishdropdownReload={publishdropdownReload}
                showPrefe = {true}
                />}
              </div>
            )}

            {/* <Grid /> */}
            <InflowRiskVisibilityTable
              dropDownValues={dropDownValues}
              toggleExpandCollapse={toggleExpandCollapse}
              isExpand={isExpand}
              setStatistics={setStatistics}
              loadDropdownsData={() => {
                setReload(true)
                setPublishdropdownReload(true)
              }}
              SetColumnSelected={SetColumnSelected}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(InflowRiskVisibility);
