import { API } from "../backend";
import axios from "axios";

export const GetInflowActionChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowActionChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  export const GetInflowForcastChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowForecastChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  export const GetInflowRisklevelWeekChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowRisklevelWeekChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  export const GetInflowRisktypeWeekChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowRisktypeWeekChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };
  export const GetInflowForecastbiasl4wWeekChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowForecastbiasl4wWeekChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };
  export const GetInflowForecastbiasl12wWeekChart = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getInflowForecastbiasl12wWeekChart`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };
