import { API } from '../backend';
import axios from '../utility/axiosInstance';
// import { ValidFilterData } from '../utility/helper';
// import { CheckUnauthorizedRequest } from './SessionExpireServices';

const CheckUnauthorizedRequest = console.log;
const ValidFilterData = console.log;

export const verifyUserAccess = async () => {
  try {
    const response = await axios.get(`${API}/user/verifyUserAccess`);
    return response.data;
  } catch (err) {
    //console.log(err);
    CheckUnauthorizedRequest(err);
  }
};
export const getLoggedUserInfo = async (email) => {
  try {
    const response = await axios.post(`${API}/store/getUserInfo`,
     { UserEmail: email });
    return response.data;
  } catch (err) {
    CheckUnauthorizedRequest(err);
  }
};
// export const createUserAccess = async () => {
//   try {
//     const response = await axios.get(`${API}/user/createUserAccess`);
//     return response.data;
//   } catch (err) {
//     //console.log(err);
//     CheckUnauthorizedRequest(err);
//   }
// };

export const inflationConfigDetail = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/inflation/config_details`,
      ValidFilterData(payload)
    );
    return response.data;
  } catch (err) {
    CheckUnauthorizedRequest(err);
  }
};

export const CategoryPeriodInfo = async (payload) => {
  const BGSK = payload.BGSK[0];
  const CategorySK = payload.CategorySK[0];
  try {
    const response = await axios.get(
      `${API}/global-filters/getCategoryPeriodInfo?BGSK[]=${BGSK}&CategorySK[]=${CategorySK}`,
      payload
    );
    return response.data;
  } catch (err) {
    CheckUnauthorizedRequest(err);
  }
};

export const createNotification = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/createNotifications`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getNotifications = async (emailId) => {
  try {
    const response = await axios.get(`${API}/store/getNotifications?vendorEmail=${emailId}`);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const updateApprovalStatus = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/updateApprovalStatus`, payload );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const updatedBySuperAdminBiddingList = async (payload) => {
  try {
    const response = await axios.put(`${API}/store/vendor/updatedBySuperAdminBiddingList`, payload );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getUserInfo = async (email) => {
  try {
    const response = await axios.post(`${API}/store/getUserInfo`,
     { UserEmail: email });
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};