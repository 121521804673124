import React, { useEffect, useRef, useState } from 'react'
import { getActionType, getAdvice, getCalendarDealType, getDealTypeList, getNextStep, getPackType, updateActionType, updateAdvice, updateCalendarDealType, updateDealType, updateNextStep, updatePackType } from '../../../Services/UserManagementService2';
import { Checkbox } from '../../CentralDealManagement/common/Checkbox';
import PlusIcon from "../../../Assets/img/Plus.svg";
import TrashIconWhite from "../../../Assets/img/icon-trash-white.svg";
import HideIcon from "../../../Assets/img/icon-view.svg"
import { useRadioGroup } from '@material-ui/core';
import { isCancel } from 'axios';
import { Dropdown } from 'react-bootstrap';
import Grid from "@material-ui/core/Grid";
import { notify } from '../../../Common/toast';

function ListUpdate() {
    const [screenoption, setScreenOption] = useState("Select");
    const [pagesettingOptions, setPagesettingOption] = useState("Select");
    const [actionTYpe, setActionType] = useState("Select");
    const [packData, setPackData] = useState([]);
    const [newpackData, setNewPackData] = useState([]);
    const [newPackType, setNewPackType] = useState([]);
    const [editPackInput, seteditPackInput] = useState([]);
    const [deletePackType, setDeletePackType] = useState([]);
    const newPackRef = useRef(null)

    const Tools = ["FG SLOB", "INFLOW", "POM"]
    const pageSettingsFGslob = ["Waste Risk Details"] //, "Central Deal Calender"
    const pageSettingsInflow = ["Risk Visibility"]
    const pageSettingsPom = ["Risk Details"]
    const fgslobRiskActionType = ["Pack Type", "Deal Type"]
    const fgslobCalenderActionType = ["Calender Type"]
    const InflowActionType = ["Action Type"]
    const PomActionType = ["Advice List", "Next Step"]
    const [refreshData, setRefreshData] = useState(false)
    const [calenderData, setCalenderData] = useState([])
    const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);


    useEffect(()=>{
        setNewPackData(null)
    },[screenoption])


    useEffect(() => {
        if (actionTYpe === "Pack Type") {
            getPackType().then(data => {
                setPackData(data.data)
                setNewPackData([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
        if (actionTYpe === "Deal Type") {
            getDealTypeList().then(data => {
                setPackData(data.data)
                setNewPackData([])
                // setNewPackType([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
        if (actionTYpe === "Action Type") {
            getActionType().then(data => {
                setPackData(data.data)
                setNewPackData([])
                // setNewPackType([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
        if (actionTYpe === "Advice List") {
            getAdvice().then(data => {
                setPackData(data.data)
                setNewPackData([])
                // setNewPackType([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
        if (actionTYpe === "Next Step") {
            getNextStep().then(data => {
                setPackData(data.data)
                setNewPackData([])
                // setNewPackType([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
        if (actionTYpe === "Calender Type") {
            getCalendarDealType().then(data => {
                console.log(data)
                setPackData(data.data)
                setNewPackData([])
                // setNewPackType([])
                seteditPackInput([])
                setDeletePackType([])
            })
        }
    }, [actionTYpe, refreshData])
    useEffect(() => {
        console.log(packData)
        setNewPackData(packData)
    }, [packData])

    const handelNewPackType = () => {
        let newPack
        if(newPackRef.current.value.trim()===""){
            return;
        }
        if (actionTYpe === "Pack Type") {
            console.log("packData",packData)
            let exists = false;
            for (const pack of packData) {
                if (pack.pack_type === newPackRef.current.value || pack.old_pack_type === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            for (const pack of newPackType) {
                if (pack.pack_type === newPackRef.current.value || pack.old_pack_type === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
            newPack = {
                pack_type: newPackRef.current.value,
                old_pack_type: "",
                parent_pack_type: "",
                isDelete: "0"
            }
            }else{
                notify({
                    message: "Data already present",
                    type: "error",
                });  
                newPackRef.current.value = ""      
                return;
            }
        }
        if (actionTYpe === "Action Type") {
            console.log("packData",packData)
            let exists = false;
            for (const pack of packData) {
                if (pack.actiontype === newPackRef.current.value || pack.old_actiontype === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            for (const pack of newPackType) {
                if (pack.actiontype === newPackRef.current.value || pack.old_actiontype === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
                newPack = {
                    actiontype: newPackRef.current.value,
                    old_actiontype: "",
                    parent_actiontype: "",
                    isDelete: "0"
                }
            }else{
                notify({
                    message: "Data already present",
                    type: "error",
                });  
                newPackRef.current.value = ""      
                return;
            }
            
        }
        if (actionTYpe === "Deal Type") {
            console.log("packData",packData)
            let exists = false;
            for (const pack of packData) {
                if (pack.action_name === newPackRef.current.value || pack.old_action_name === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            for (const pack of newPackType) {
                if (pack.deal_type === newPackRef.current.value || pack.old_deal_type === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
                newPack = {
                    deal_type: newPackRef.current.value,
                    old_deal_type: "",
                    parent_deal_type: "",
                    isDelete: "0"
                }
            }else{
                notify({
                    message: "Value already present in the List",
                    type: "error",
                });  
                newPackRef.current.value = ""      
                return;
            }
            
        }
        if (actionTYpe === "Advice List") {
            console.log("packData",packData)
            let exists = false;
            for (const pack of packData) {
                if (pack.advice === newPackRef.current.value || pack.old_advice === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            for (const pack of newPackType) {
                if (pack.advice === newPackRef.current.value || pack.old_advice === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
                newPack = {
                    advice: newPackRef.current.value,
                    old_advice: "",
                    parent_advice: "",
                    isDelete: "0"
                }
            }else{
                notify({
                    message: "Data already present",
                    type: "error",
                });  
                newPackRef.current.value = ""      
                return;
            }
            
        }
        if (actionTYpe === "Next Step") {
            console.log("packData",packData)
            let exists = false;
            for (const pack of packData) {
                if (pack.nextStep === newPackRef.current.value || pack.old_nextStep === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            for (const pack of newPackType) {
                if (pack.nextStep === newPackRef.current.value || pack.old_nextStep === newPackRef.current.value) {
                    exists = true;
                    break;
                }
            }
            if(!exists){
                newPack = {
                    nextStep: newPackRef.current.value,
                    old_nextStep: "",
                    parent_nextStep: "",
                    isDelete: "0"
                }
            }else{
                notify({
                    message: "Data already present",
                    type: "error",
                });  
                newPackRef.current.value = ""      
                return;
            }
            
        }
        console.log(newPack,"newpackType")
        setNewPackType(prev => [...prev, newPack])
        console.log(newPackRef.current.value)
        newPackRef.current.value = ""
    }
    useEffect(() => {
        console.log(newPackType)
        let dealdatanew
        if(actionTYpe==="Deal Type" && newPackType.length > 0){
            dealdatanew = {
                action_name: newPackType[newPackType.length - 1].deal_type,
                old_action_name: "",
                parent_action_name: "",
                isDelete:0
            }
        }
        if (newPackType.length > 0)
            setNewPackData(prev => [...prev, actionTYpe==="Deal Type"?dealdatanew:newPackType[newPackType.length - 1]])
    }, [newPackType])

    const handleSave = (argumentType) => {
        console.log(newPackType, deletePackType)
        console.log(actionTYpe)
        if (actionTYpe === "Calender Type") {
            updateCalendarDealType({ dealData: calenderData }).then(response => {
                setRefreshData(!refreshData)
                setNewPackType([])
                notify({
                    message: "Data edited successfully!",
                    type: "success",
                });
            })

        }
        else {
            const asqw = newPackType.filter(i => {
                if (actionTYpe === "Pack Type") {
                    return (
                        deletePackType.filter(del => i.pack_type === del.pack_type).length > 0 ? false : true
                    )
                }
                if (actionTYpe === "Action Type") {
                    return (
                        deletePackType.filter(del => i.actiontype === del.actiontype).length > 0 ? false : true
                    )
                }
                if (actionTYpe === "Deal Type") {
                    return (
                        deletePackType.filter(del => i.deal_type === del.deal_type).length > 0 ? false : true
                    )
                }
                if (actionTYpe === "Advice List") {
                    return (
                        deletePackType.filter(del => i.advice === del.advice).length > 0 ? false : true
                    )
                }
                if (actionTYpe === "Next Step") {
                    return (
                        deletePackType.filter(del => i.nextStep === del.nextStep).length > 0 ? false : true
                    )
                }
                return (true)
            })
            console.log(asqw)
            const asqww = deletePackType.filter(i => {
                if (actionTYpe === "Pack Type") {
                    return (
                        packData.filter(del => i.pack_type === del.pack_type).length > 0 ? true : false
                    )
                }
                if (actionTYpe === "Action Type") {
                    return (
                        packData.filter(del => i.actiontype === del.actiontype).length > 0 ? true : false
                    )
                }
                if (actionTYpe === "Deal Type") {
                    console.log(packData,deletePackType)
                    return (
                        
                        packData.filter(del => i.action_name === del.action_name).length > 0 ? true : false
                    )
                }
                if (actionTYpe === "Advice List") {
                    return (
                        packData.filter(del => i.advice === del.advice).length > 0 ? true : false
                    )
                }
                if (actionTYpe === "Next Step") {
                    return (
                        packData.filter(del => i.nextStep === del.nextStep).length > 0 ? true : false
                    )
                }

            })
            console.log(asqww)
            let deletePackTypeArry
            if (actionTYpe === "Pack Type") {
                deletePackTypeArry = asqww.map(i => ({ ...i, isDelete: "1" }))
                    .map(({ pack_type, old_pack_type, parent_pack_type, isDelete }) =>
                        ({ pack_type, old_pack_type, parent_pack_type, isDelete }))
            }
            if (actionTYpe === "Action Type") {
                deletePackTypeArry = asqww.map(i => ({ ...i, isDelete: "1" }))
                    .map(({ actiontype, old_actiontype, parent_actiontype, isDelete }) =>
                        ({ actiontype, old_actiontype, parent_actiontype, isDelete }))
            }
            if (actionTYpe === "Deal Type") {
                deletePackTypeArry = asqww.map(i => ({ ...i, isDelete: "1" }))
                    .map(({ action_name, old_action_name, parent_action_name, isDelete }) =>
                        ({ deal_type:action_name, old_deal_type:old_action_name, parent_deal_type:parent_action_name, isDelete }))
            }
            if (actionTYpe === "Advice List") {
                deletePackTypeArry = asqww.map(i => ({ ...i, isDelete: "1" }))
                    .map(({ advice, old_advice, parent_advice, isDelete }) =>
                        ({ advice, old_advice, parent_advice, isDelete }))
            }
            if (actionTYpe === "Next Step") {
                deletePackTypeArry = asqww.map(i => ({ ...i, isDelete: "1" }))
                    .map(({ nextStep, old_nextStep, parent_nextStep, isDelete }) =>
                        ({ nextStep, old_nextStep, parent_nextStep, isDelete }))
            }
            console.log(deletePackTypeArry)
            let editPackInputArry
            if (actionTYpe === "Pack Type") {
                editPackInputArry = editPackInput?.filter(val => val !== undefined).filter(val => val.pack_type !== "")
            }
            if (actionTYpe === "Action Type") {
                editPackInputArry = editPackInput?.filter(val => val !== undefined).filter(val => val.actiontype !== "")
            }
            if (actionTYpe === "Deal Type") {
                editPackInputArry = editPackInput?.filter(val => val !== undefined).filter(val => val.deal_type !== "")
            }
            if (actionTYpe === "Advice List") {
                editPackInputArry = editPackInput?.filter(val => val !== undefined).filter(val => val.advice !== "")
            }
            if (actionTYpe === "Next Step") {
                editPackInputArry = editPackInput?.filter(val => val !== undefined).filter(val => val.nextStep !== "")
            }
            let updatePackTypes = [...asqw, ...deletePackTypeArry, ...editPackInputArry];
            console.log(updatePackTypes, asqw, deletePackTypeArry, editPackInputArry)
            if (actionTYpe === "Pack Type") {
                setSaveLoaderInit(true);
                updatePackType({ packtypeData: updatePackTypes }).then(response => {
                    setSaveLoaderInit(false);
                    setRefreshData(!refreshData)
                    setNewPackType([])
                    notify({
                        message: "Data edited successfully!",
                        type: "success",
                    });
                })
            }
            if (actionTYpe === "Action Type") {
                setSaveLoaderInit(true);
                updateActionType({ actiontypeData: argumentType==="delete"?updatePackTypes.filter(i=>i.isDelete==="1"):updatePackTypes }).then(response => {
                    setSaveLoaderInit(false);
                    setRefreshData(!refreshData)
                    setNewPackType([])
                    notify({
                        message: "Data edited successfully!",
                        type: "success",
                    });
                })
            }
            if (actionTYpe === "Deal Type") {
                setSaveLoaderInit(true);
                updateDealType({ dealtypeData: argumentType==="delete"?updatePackTypes.filter(i=>i.isDelete==="1"):updatePackTypes }).then(response => {
                    setSaveLoaderInit(false);
                    setRefreshData(!refreshData)
                    setNewPackType([])
                    notify({
                        message: "Data edited successfully!",
                        type: "success",
                    });
                })
            }
            if (actionTYpe === "Advice List") {
                setSaveLoaderInit(true);
                updateAdvice({ adviceData: argumentType==="delete"?updatePackTypes.filter(i=>i.isDelete==="1"):updatePackTypes }).then(response => {
                    setSaveLoaderInit(false);
                    setRefreshData(!refreshData)
                    setNewPackType([])
                    notify({
                        message: "Data edited successfully!",
                        type: "success",
                    });
                })
            }
            if (actionTYpe === "Next Step") {
                setSaveLoaderInit(true);
                updateNextStep({ nextstepData: argumentType==="delete"?updatePackTypes.filter(i=>i.isDelete==="1"):updatePackTypes }).then(response => {
                    setSaveLoaderInit(false);
                    setRefreshData(!refreshData)
                    setNewPackType([])
                    notify({
                        message: "Data edited successfully!",
                        type: "success",
                    });
                })
            }

        }
        // updatePackType({ packtypeData: argumentType==="delete"?updatePackTypes.filter(i=>i.isDelete==="1"):updatePackTypes }).then(response => console.log(response))

    }
    useEffect(() => {
        console.log(calenderData)
    }, [calenderData])

    const onCheckBoxCLick = (data) => {
        console.log(data)
        if (actionTYpe === "Calender Type") {
            let pData = {
                deal_type: data.deal_type,
                status: data.status === 0 ? 1 : 0
            }
            if (calenderData.filter(i => i.deal_type === pData.deal_type).length > 0) {
                calenderData.forEach((i) => {
                    if (i.deal_type === pData.deal_type) {
                        i.status = i.status === 0 ? 1 : 0
                    }
                })
                setCalenderData([...calenderData])
            } else {
                setCalenderData([...calenderData, pData])
            }
        }
        if (deletePackType?.length === 0) {
            console.log("hahaha")
            setDeletePackType(prev => [...prev, data])
        }
        else {
            if (actionTYpe === "Pack Type") {
                if (deletePackType?.filter(i => i.pack_type === data.pack_type).length === 0) {
                    console.log("ddsd")
                    setDeletePackType(prev => [...prev, data])
                }
                else {
                    setDeletePackType(deletePackType?.filter(i => i.pack_type !== data.pack_type))
                }
            }
            if (actionTYpe === "Action Type") {
                if (deletePackType?.filter(i => i.actiontype === data.actiontype).length === 0) {
                    console.log("ddsd")
                    setDeletePackType(prev => [...prev, data])
                }
                else {
                    setDeletePackType(deletePackType?.filter(i => i.actiontype !== data.actiontype))
                }
            }
            if (actionTYpe === "Deal Type") {
                if (deletePackType?.filter(i => i.deal_type === data.deal_type).length === 0) {
                    console.log("ddsd")
                    setDeletePackType(prev => [...prev, data])
                }
                else {
                    setDeletePackType(deletePackType?.filter(i => i.deal_type !== data.deal_type))
                }
            }
            if (actionTYpe === "Advice List") {
                if (deletePackType?.filter(i => i.advice === data.advice).length === 0) {
                    console.log("ddsd")
                    setDeletePackType(prev => [...prev, data])
                }
                else {
                    setDeletePackType(deletePackType?.filter(i => i.advice !== data.advice))
                }
            }
            if (actionTYpe === "Next Step") {
                if (deletePackType?.filter(i => i.nextStep === data.nextStep).length === 0) {
                    console.log("ddsd")
                    setDeletePackType(prev => [...prev, data])
                }
                else {
                    setDeletePackType(deletePackType?.filter(i => i.nextStep !== data.nextStep))
                }
            }
        }
    }
    useEffect(() => {
        console.log(deletePackType)
    }, [deletePackType])

    const handelnewPackInput = (e, Index, data) => {
        const newInputValues = [...editPackInput];
        if (actionTYpe === "Pack Type") {
            newInputValues[Index] = { pack_type: e.target.value, old_pack_type: data.pack_type, parent_pack_type: !data.old_pack_type|| data.old_pack_type==="NULL"?data.pack_type:data.old_pack_type, isDelete: data.isDelete };
        }
        if (actionTYpe === "Action Type") {
            newInputValues[Index] = { actiontype: e.target.value, old_actiontype: data.actiontype, parent_actiontype: !data.old_actiontype|| data.old_actiontype==="NULL"?data.actiontype:data.old_actiontype, isDelete: data.isDelete };
        }
        if (actionTYpe === "Deal Type") {
            newInputValues[Index] = { deal_type: e.target.value, old_deal_type: data.action_name, parent_deal_type: !data.old_action_name|| data.old_action_name==="NULL"?data.action_name:data.old_action_name, isDelete: data.isDelete };
        }
        if (actionTYpe === "Advice List") {
            newInputValues[Index] = { advice: e.target.value, old_advice: data.advice, parent_advice: !data.old_advice|| data.old_advice==="NULL"?data.advice:data.old_advice, isDelete: data.isDelete };
        }
        if (actionTYpe === "Next Step") {
            newInputValues[Index] = { nextStep: e.target.value, old_nextStep: data.nextStep, parent_nextStep: !data.old_nextStep|| data.old_nextStep==="NULL"?data.nextStep:data.old_nextStep, isDelete: data.isDelete };
        }
        console.log(newInputValues)
        seteditPackInput(newInputValues);

    }

    useEffect(() => {
        console.log(editPackInput)
    }, [editPackInput])
    return (
        <>
            <div>
                <h3 className='heading'>Settings</h3>
                <div className='form-body-content'>
                    <div className=' filterform_bg '>
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <label>
                                    Tool
                                </label>
                                <div className=''>
                                    <select
                                        defaultValue={"Select"}
                                        className="btn-slim w-100"
                                        onChange={(event) => {
                                            setScreenOption(event.target.value)
                                            console.log(event.target.value)
                                        }}
                                    >
                                        <option key={"Select"}>Select</option>
                                        {Tools.map(i => {
                                            return (
                                                <option key={i}>{i}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={3}>
                                <label>
                                    Page Settings
                                </label>
                                <div className=''>
                                    <select
                                        defaultValue={"Select"}
                                        className="btn-slim w-100"
                                        onChange={(event) => {
                                            setPagesettingOption(event.target.value)
                                            console.log(event.target.value)
                                        }}
                                    >   <option key={"Select"}  >
                                            Select
                                        </option>
                                        {screenoption === "FG SLOB" ?
                                            pageSettingsFGslob.map(i => {
                                                return (<option key={i}>{i}</option>)
                                            }) : screenoption === "INFLOW" ?
                                                pageSettingsInflow.map(i => {
                                                    return (<option key={i}>{i}</option>)
                                                }) : screenoption === "POM" ?
                                                    pageSettingsPom.map(i => {
                                                        return (<option key={i}>{i}</option>)
                                                    }) : ""
                                        }
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                        {/* {(newpackData.length <= 0) ? 'no-data' : ''} */}
                        <Grid container spacing={2} >
                            <Grid item xs={6}>
                                <label>
                                    Action Type
                                </label>
                                <div className='' >
                                    <select
                                        defaultValue={"Select"}
                                        className="btn-slim w-100 has-table-as-dropdown"
                                        onChange={(event) => {
                                            setActionType(event.target.value)
                                            console.log(event.target.value)
                                        }}
                                    >   <option key={"Select"}  >
                                            Select
                                        </option>
                                        {screenoption === "FG SLOB" && pagesettingOptions === "Waste Risk Details" ?
                                            fgslobRiskActionType.map(i => {
                                                return (<option key={i}>{i}</option>)
                                            }) : screenoption === "FG SLOB" && pagesettingOptions === "Central Deal Calender" ?
                                                fgslobCalenderActionType.map(i => {
                                                    return (<option key={i}>{i}</option>)
                                                }) : screenoption === "INFLOW" && pagesettingOptions === "Risk Visibility" ?
                                                    InflowActionType.map(i => {
                                                        return (<option key={i}>{i}</option>)
                                                    }) : screenoption === "POM" && pagesettingOptions === "Risk Details" ?
                                                        PomActionType.map(i => {
                                                            return (<option key={i}>{i}</option>)
                                                        }) : ""
                                        }
                                    </select>
                                    {actionTYpe !== "Select" &&
                                        <div className='pack-data-div'  >
                                            {newpackData && actionTYpe!=="Calender Type"&&<div className='d-flex table-inline-search btn-group bg-black'>
                                                <img src={PlusIcon} />
                                                <input ref={newPackRef} className='bg-transparent' type='text' placeholder='Type to add New' ></input>
                                                <button className='btn btn-slim btn-primary' on onClick={handelNewPackType}>Ok</button>
                                            </div>}
                                            {newpackData &&
                                                <div className="position-relative " >
                                                    <div className="table_design w-100" >
                                                        <div className={`table sticky table-pack-data ${(newpackData.length <= 0) ? 'no-data' : ''} cells-width-auto}`}
                                                        >
                                                            <div className="header w-100">
                                                                <div className="tr w-100">
                                                                    <div className="th c1 width-auto min-width-auto" >
                                                                        <div className="th-in">
                                                                            {actionTYpe==="Calender Type"?"Select To Hide":"Select Checkbox To Delete"}
                                                                        </div>
                                                                    </div>
                                                                    <div className="th c2 width-auto min-width-auto" >
                                                                        {actionTYpe!=="Calender Type"?<div className="th-in" onClick={()=>handleSave("delete")}><img title="Delete" src={TrashIconWhite} width={15} /></div>:<div className="th-in"><img title="Delete" src={HideIcon} width={15} /></div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {newpackData.length <= 0 && (
                                                                <div className="body w-100">
                                                                    <div className="tr w-100">
                                                                        <div role="cell" className="td  w-100">
                                                                            <span className="td-in text-center">No Data</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {newpackData.length > 0 && (
                                                                <div className="body w-100">
                                                                    {newpackData?.map((data, Index) =>
                                                                        <div className="tr w-100">
                                                                            <div className="td min-width-auto c1">
                                                                                <div className="td-in">
                                                                                    {actionTYpe === "Pack Type" && <div className='list-text'>
                                                                                        <div>{data?.parent_pack_type ? data?.parent_pack_type !== "NULL" ? data.parent_pack_type : data?.pack_type : data?.pack_type}</div><span className='pl-2 pr-2'>/</span>
                                                                                        <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.pack_type} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        />
                                                                                    </div>}
                                                                                    {actionTYpe === "Action Type" && <div className='list-text'>
                                                                                    <div>{data?.parent_actiontype ? data?.parent_actiontype !== "NULL" ? data.parent_actiontype : data?.actiontype : data?.actiontype}</div><span className='pl-2 pr-2'>/</span>
                                                                                        <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.actiontype} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        />
                                                                                    </div>}
                                                                                    {actionTYpe === "Advice List" && <div className='list-text'>
                                                                                    <div>{data?.parent_advice ? data?.parent_advice !== "NULL" ? data.parent_advice : data?.advice : data?.advice}</div><span className='pl-2 pr-2'>/</span>
                                                                                        <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.advice} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        />
                                                                                    </div>}
                                                                                    {actionTYpe === "Deal Type" && <div className='list-text'>
                                                                                        {(data?.parent_action_name && data?.parent_action_name !== "NULL") ? data.parent_action_name : data?.action_name}<span className='pl-2 pr-2'>/</span>
                                                                                        <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.action_name} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        />
                                                                                    </div>}
                                                                                    {actionTYpe === "Next Step" && <div className='list-text'>
                                                                                        {data?.parent_nextStep ? data?.parent_nextStep !== "NULL" ? data.parent_nextStep : data?.nextStep : data?.nextStep}<span className='pl-2 pr-2'>/</span>
                                                                                        <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.nextStep} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        />
                                                                                    </div>}
                                                                                    {actionTYpe === "Calender Type" && <div className='list-text'>
                                                                                        {data?.deal_type}
                                                                                        {/* {data?.parent_nextStep ? data?.parent_nextStep !== "Null" ? data.parent_nextStep : data?.nextStep : data?.nextStep}<span className='pl-2 pr-2'>/</span> */}
                                                                                        {/* <input
                                                                                            key={Index}
                                                                                            style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                                                            type="text" placeholder={data?.nextStep} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                                                        /> */}
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className="td min-width-auto c2">
                                                                                <div className="td-in">
                                                                                    <Checkbox className="list-checkbox" defaultChecked={actionTYpe === "Calender Type" ? data.status === 1 ? true : false : false} onClick={() => onCheckBoxCLick(data)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>


                                                    {/* <div className="list-listing" style={{ width: "100%", height: "74%", overflow: "auto", alignItems: "center" }}>
                                    {newpackData?.map((data, Index) => {
                                        return (
                                            <div className='list-item'>
                                                <div className='list-text'>
                                                    {data?.parent_pack_type ? data.parent_pack_type : data?.pack_type}/
                                                    <input
                                                        key={Index}
                                                        style={{ width: "60%", height: "20px", border: "0px !important" }}
                                                        type="text" placeholder={data?.pack_type} onChange={(e) => handelnewPackInput(e, Index, data)}
                                                    />
                                                </div>
                                                <Checkbox className="list-checkbox" onClick={() => onCheckBoxCLick(data)} />
                                            </div>
                                        )
                                    })}
                                </div> */}

                                                </div>
                                            }
                                        </div>}
                                </div>
                            </Grid>
                        </Grid>

                    </div>
                    <div className='mt-5'>
                        <button className={`btn btn-big-width btn-big btn-primary pull-right ${isSetSaveLoaderInit ? 'processing' : ''}`} onClick={handleSave}>Save & Next</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListUpdate