import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Multiselect from "../../../Views/common/user-Management/Multiselect";

const Settings = (props) => {
  const handleMultiSelectChange = (obj) => {
    props.handleMultiSelectChange(obj);
  }
  const handleSettingsDDLoading = (obj) => {
    props.handleSettingsDDLoading(obj);
  }

  return (
    <Multiselect
      isMyAccess={props.isMyAccess}
      isSettings={true}
      onMultiSelectChange={handleMultiSelectChange}
      setDropDownValues={props.setDropDownValues}
      setDdlInfo={props.setDdlInfo}
      key={837}
      myAccessInfo={props.myAccessInfo}
      errorMssg={props.errorMssg}
      ddlDisable={props.ddlDisable}
      createType={props.createType}
      submitCount={props.submitCount}
      userAddError={props.userAddError}
      onCloseDropDown={props.onCloseDropDown}
      onSettingsDDLoading={handleSettingsDDLoading}
      dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
      isEdit={props.isEdit}
    ></Multiselect>
  );
};

export default Settings;
