export const COLUMNS = [
  {
    Header: "MRP controller",
    accessor: "mrpc",
  },
  {
    Header: "EAN code CU",
    accessor: "cu_ean",
  },
  {
    Header: "EAN code case",
    accessor: "cs_ean",
  },
  {
    Header: "EAN code pallet",
    accessor: "pal_ean",
  },
  {
    Header: "Quantity available for sales offer",
    accessor: "qty_to_offer",
  },

  {
    Header: "Group",
    accessor: "sector",
  },
  {
    Header: "Size Pack Form",
    accessor: "size_pack_form",
  },

  {
    Header: "Alert type",
    accessor: "alert_type",
  },
  {
    Header: "Timestamp (date added to POM)",
    accessor: "timestamp_added",
  },
  {
    Header: "Timestap (date offered for sale since)",
    accessor: "timestamp_offer2sales",
  },
];
