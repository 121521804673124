import { API } from "../backend";
import axios from "axios";
export const centralDealCalenderDetails = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getCentralDealCalender`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const saveUpcomingRounds = async (payload) => {
  try {
    const response = await axios.put(
      `${API}/store/updateDealCalender`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
