import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
// import SearchIcon from "../../../Assets/img/search.svg";
// import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
// import ClickIcon from "../../../Assets/img/clickicon.svg";
// import resizeIcon from "../../../Assets/img/icon-resize.svg";
// import GlosseryTable from '../Table/GlosseryTable';
// import { getPOMGlossary } from '../../../Services/UserManagementService2';
// import { Grid } from '@material-ui/core';
// import ULDropdown from '../../../Common/Dropdown';
// import Dropdown from '../../../Views/common/Dropdown';

import {
  saveAssignedOwner,
  getUserData,
} from "../../../Services/UserManagementService2";
import apiEndPoints from "../../../utils/apiEndPoints";
import axiosService from "../../../utils/apiService";
import Loadersml from "../../../Common/loadersml";
import Loader from "../../../Common/loader";
import ULDropdown from "../../../Common/Dropdown";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { useForm } from "react-hook-form";
import PlusIcon from "../../../Assets/img/Plus.svg";
import { Checkbox } from "../../CentralDealManagement/common/Checkbox";
import TrashIconWhite from "../../../Assets/img/icon-trash-white.svg";
import { notify } from "../../../Common/toast";
import { toast } from "react-toastify";
import ClickIcon from "../../../Assets/img/clickicon.svg";
import { UserAccessContext, UserContext } from "../../Application";
import { setDropdownvaluesGlobal } from "../../../utility/helper";


const styleSelect = {
  width: "120px",
  backgroundColor: "#1e232f",
  border: "1px solid #7D7D7D",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "400",
  color: "#ffffff",
  height: "24px",
  padding: "0 6px",
  margin: "0 10px",
};

const styleOwner = {
  width: "200px",
  backgroundColor: "#1e232f",
  border: "1px solid #7D7D7D",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "400",
  color: "#ffffff",
  height: "24px",
  padding: "0 6px",
  margin: "0 10px",
};

function AssignOwner(props) {
  const location = useLocation();
  const [newUsersData, setNewUsersData] = useState([]);

  const assignRef = useRef(null);

  let allValues = [
    { key: 1, value: "Waste Risk Details" },
    { key: 2, value: "Risk visibility" },
    { key: 3, value: "Risk Details" },
  ];
  const ownnerTypes = [
    { key: 3, value: "Owner" },
    {
      key: 2,
      value: "Owner Short Term Planner",
    },
    {
      key: 2,
      value: "Owner Demand Planner",
    }
  ];
  const searchKeyword = useRef(null);
  const userAccess = useContext(UserAccessContext)
  const [tableShow, setTableShow] = useState(false);
  const handleClose = () => setTableShow(false);
  const [screenoption, setScreenOption] = useState(" ");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [dropdownInfo, setDropDownInfo] = useState([]);
  const [filterOptions, setFilterOptions] = useState();
  const [selection, setSelection] = useState({ toolId: ["2"] });
  const [reload, setReload] = useState(false);
  const [bg, setBg] = useState([]);
  const [mcoA, setMco] = useState([]);
  const [countryA, setCountry] = useState([]);
  const [brandA, setBrand] = useState([]);
  const [categoryA, setCategory] = useState([]);
  const [deleteUsers, setDeleteUsers] = useState([]);
  const [showTableList, setShowTableList] = useState(false);
  const [editUsersInput, setEditUsersInput] = useState([]);
  const [hasOwner, setHasOwner] = useState(false);
  const [ownerType, setOwnerType] = useState(" ")
  const user = useContext(UserContext);
  

  const [tool, setTool] = useState([
    { key: 2, value: "INFLOW" },
    { key: 3, value: "POM" },
  ]);
  const defaultPageSelection = allValues.filter(i => i.key === 2);
  const [pageSelection, setPageSelection] = useState(defaultPageSelection);
  const [selectedOwner, setSelectedOwner] = useState([]);
  const [selectedUsersData, setSelectedUsersData] = useState([]);

  useEffect (()=>{
    console.log(userAccess,"UserAccess")
    setScreenOption(userAccess?.inflowdata.data.length===undefined?"2":"3")
    getDropDownsData();
  },[userAccess])

  const getDropDownsData = async (data) => {
    console.log(data)
    setIsLoading(true);
    let obj =
      screenoption === "3"
        ? {
          toolId: data?.tool?.length > 0 ? data?.tool : [3],
          countryId:setDropdownvaluesGlobal( data?.country ,userAccess?.pomdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(data?.category ,userAccess?.pomdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(data?.businessGroup ,userAccess?.pomdata?.data?.bussinessGroupId),
          brandId: setDropdownvaluesGlobal(data?.brand ,userAccess?.pomdata?.data?.brandId),
          businessUnitId: data?.businessUnit ?? [],
          accessId: data?.access ?? [],
          nextStepId: data?.nextStep ?? [],
          // clusterId: data?.cluster ?? [],
          tabViewSettingId: data?.tabViewSettings ?? [],
          pageSettingId: data?.pageSettings ?? [],
          userAccessCountryId :userAccess?.pomdata?.data?.countryId,
          source:"AssignOwner",
        }
        : {
          toolId: data?.tool?.length > 0 ? data?.tool : [2],
          brandId: setDropdownvaluesGlobal(data?.brand ,userAccess?.inflowdata?.data?.brandId),
          countryId: setDropdownvaluesGlobal(data?.country ,userAccess?.inflowdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(data?.category ,userAccess?.inflowdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(data?.businessGroup ,userAccess?.inflowdata?.data?.bussinessGroupId),
          businessUnitId: data?.businessUnit ?? [],
          accessId: data?.access ?? [],
          // clusterId: data?.cluster ?? [],
          mcoId: data?.mco ?? [],
          tabViewSettingId: data?.tabViewSettings ?? [],
          pageSettingId: data?.pageSettings ?? [],
          source:"AssignOwner"
        };
    const resolt = await axiosService.post(
      screenoption === "3"
        ? apiEndPoints.pomGlobalFilters
        : apiEndPoints.inflowGlobalFilters,
      obj
    );
    setIsLoading(false);
    let newresult = resolt?.data?.data
      console.log("qazwxxedc", newresult)
      if(screenoption === "3"){
        newresult.country = newresult.country?.filter(i=>userAccess?.pomdata.data.countryId?.includes(i.key))
        // newresult.businessGroup = newresult.businessGroup?.filter(i=>userAccess?.pomdata.data.businessGroupId?.includes(i.key))
        newresult.category = newresult.category?.filter(i=>userAccess?.pomdata.data.categoryId?.includes(i.key))
        newresult.brand = newresult.brand?.filter(i=>userAccess?.pomdata.data.brandId?.includes(i.key))

      }
      if(screenoption === "2"){
        newresult.country = newresult.country?.filter(i=>userAccess?.inflowdata.data.countryId?.includes(i.key))
        // newresult.businessGroup = newresult.businessGroup?.filter(i=>userAccess?.inflowdata.data.businessGroupId?.includes(i.key))
        newresult.category = newresult.category?.filter(i=>userAccess?.inflowdata.data.categoryId?.includes(i.key))
        newresult.brand = newresult.brand?.filter(i=>userAccess?.inflowdata.data.brandId?.includes(i.key))

      }

    setDropDownInfo(newresult);
    
    // getUserData({
    //   screenoption,
    //   businessGroupId: data?.businessGroup ?? [],
    //   mcoId: data?.mco ?? [],
    //   countryId: data?.country ?? [],
    //   brandId: data?.brand ?? [],
    //   categoryId: data?.category ?? [],
    //   nextStepId:data?.nextStep??[],
    //   ownerType:ownerType
    // }).then((data) => {
    //   if (Array.isArray(data?.data?.userArr)) {
    //     setNewUsersData(data.data.userArr ?? []);
    //     setSelectedUsersData(data.data.selectedUserArr??[]);

    //     if (data?.data?.userArr?.length && hasOwner === false) {
    //       setHasOwner(true)
    //       let sccs = <div className="tosterIcon"><div>{`${data.data.userArr.length} Owner available!`}</div></div>
    //       toast.success(sccs, {
    //         containerId: "A",
    //       });
    //     }
    //   }
    // });
    props.setDdlInfo?.(resolt?.data?.data);
    props.setFilterOptions && props.setFilterOptions?.(resolt?.data?.data);
  };

  useEffect(() => {
    getDropDownsData()
    setSelection([])
    setNewUsersData([]);
    setOwnerType("select")
    setSelectedUsersData([])
  }, [screenoption])

  const [isDataLoading, setDataIsLoading] = useState(false)

  useEffect(() => {
    console.log("ownerType",ownerType)
    if(ownerType==="select"){
      setNewUsersData([]);
      setSelectedUsersData([]);
      setDataIsLoading(false)
    }
    else{
    setDataIsLoading(true)
    let payload 
    if(screenoption==="2"){
      payload ={
      screenoption,
      businessGroupId: setDropdownvaluesGlobal(selection?.businessGroup,userAccess?.inflowdata?.data?.businessGroupId) ?? [],
      mcoId: setDropdownvaluesGlobal(selection?.mco,userAccess?.inflowdata?.data?.mcoId) ?? [],
      countryId: setDropdownvaluesGlobal(selection?.country,userAccess?.inflowdata?.data?.countryId) ?? [],
      brandId: setDropdownvaluesGlobal(selection?.brand,userAccess?.inflowdata?.data?.brandId) ?? [],
      categoryId: setDropdownvaluesGlobal(selection?.category,userAccess?.inflowdata?.data?.categoryId) ?? [],
      nextStepId: setDropdownvaluesGlobal(selection?.nextStep,userAccess?.inflowdata?.data?.nextStepId) ?? [],
      ownerType: ownerType
      }
    }
    else{
      payload ={
        screenoption,
        businessGroupId: setDropdownvaluesGlobal(selection?.businessGroup,userAccess?.pomdata?.data?.businessGroupId) ?? [],
        mcoId: setDropdownvaluesGlobal(selection?.mco,userAccess?.pomdata?.data?.mcoId) ?? [],
        countryId: setDropdownvaluesGlobal(selection?.country,userAccess?.pomdata?.data?.countryId) ?? [],
        brandId: setDropdownvaluesGlobal(selection?.brand,userAccess?.pomdata?.data?.brandId) ?? [],
        categoryId: setDropdownvaluesGlobal(selection?.category,userAccess?.pomdata?.data?.categoryId) ?? [],
        nextStepId: setDropdownvaluesGlobal(selection?.nextStep,userAccess?.pomdata?.data?.nextStepId) ?? [],
        ownerType: ownerType
        }

    }
    getUserData({
      // setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
      screenoption,
      businessGroupId:selection?.businessGroup ?? [],
      mcoId: selection?.mco ?? [],
      countryId: selection?.country ?? [],
      brandId: selection?.brand ?? [],
      categoryId: selection?.category ?? [],
      nextStepId: selection?.nextStep ?? [],
      ownerType: ownerType
    }).then((data) => {
      if (Array.isArray(data?.data?.userArr)) {
        setNewUsersData(data.data.userArr ?? []);
        setDataIsLoading(false)
        setSelectedUsersData(data.data.selectedUserArr ?? []);

        if (data?.data?.userArr?.length && hasOwner === false) {
          setHasOwner(true)
          let sccs = <div className="tosterIcon"><div>{`${data.data.userArr.length} Owner available!`}</div></div>
          toast.success(sccs, {
            containerId: "A",
          });
        }
      }
    });
  }
    console.log(selection)
  }, [ownerType])

  useEffect(() => { console.log(selection) }, [selection])

  const isChecked = (objValue) => {
    console.log(objValue)
    console.log(selectedUsersData)
    let isExist = deleteUsers?.length && deleteUsers.filter(i => i?.userEmail === objValue?.userEmail)?.length
    console.log(objValue, isExist)
    if (isExist) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    setDeleteUsers(selectedUsersData)
  }, [selectedUsersData])

  const onCheckBoxCLick = (data) => {
    console.log(data)
    const isAlreadyExist = deleteUsers.find(i => i.userEmail === data.userEmail)
    let updatedList = [];
    if (isAlreadyExist) {
      updatedList = deleteUsers.filter(i => i.userEmail !== data.userEmail)
    } else {
      updatedList = deleteUsers.concat([data]);
    }
    console.log(updatedList)
    setDeleteUsers(updatedList)
    // if (deleteUsers?.length === 0) {
    //   setDeleteUsers((prev) => [...prev, data]);
    // } else {
    //   if (deleteUsers?.filter((i) => i.id === data.id).length === 0) {
    //     setDeleteUsers((prev) => [...prev, data]);
    //   } else {
    //     setDeleteUsers(deleteUsers?.filter((i) => i.id !== data.id));
    //   }
    // }
  };

  const handelNewUserType = () => {
    let newAssign = {
      pack_type: assignRef.current.value,
      old_pack_type: assignRef.current.value,
      parent_pack_type: assignRef.current.value,
      isDelete: "0",
    };
    setNewUsersData((prev) => [...prev, newAssign]);
  };

  useEffect(() => {
    if (props.myAccessInfo?.tool?.length) {
      let keyObj = {
        tool: "tool",
        businessGroup: "businessGroup",
        mco: "mco",
        country: "country",
        access: "access",
        tabViewSettings: "tabViewSettings",
      };
      if (props.myAccessInfo && Object.keys(props.myAccessInfo ?? {})?.length) {
        let selectionData = { ...selection };
        Object.keys(props.myAccessInfo).forEach((key) => {
          selectionData[keyObj[key]] = props.myAccessInfo[key] ?? [];
        });
        console.log(selectionData);
        getDropDownsData({ ...selectionData });
        setSelection({ ...selectionData });
      }
    } else if (!Object.keys(props.myAccessInfo ?? {})?.length) {
      getDropDownsData({ ...selection });
    }
  }, [props.myAccessInfo?.tool?.length]);

  const getRemoveAll = (selectedFilterData) => {
    console.log(selectedFilterData);
    //console.log(selectedFilterData)
    for (var key in selectedFilterData) {
      console.log(selectedFilterData, key);
      selectedFilterData[key] = selectedFilterData[key]?.filter(
        (i) => i !== "all"
      );
    }
    return selectedFilterData;
  };

  const handleChange = (name, options = null) =>
    (selected, action) => {
      console.log(name, options, selected, action);
      //props.onMultiSelectChange?.(action?.option?.value);
      let selectedFilterData;
      let selectedFilterData_for_customers;
      // if (props.isCustomer) {
      //props.onMultiSelectChange(name, selected);
      // }
      if (action?.option?.value === "all") {
        //Not use now
        if (action.action === "select-option") {
          selectedFilterData = {
            ...selection,
            [name]: [...options.map((item) => item.value)],
          };
          setSelection(selectedFilterData);

          props.setDropDownValues?.(getRemoveAll(selectedFilterData));
          if (!props.isCustomer) {
            getDropDownsData(getRemoveAll(selectedFilterData));
          }

          selectedFilterData_for_customers = {
            ...selection,
            [name]: [
              ...options.map((item) => ({ id: item.value, Name: item.label })),
              "all",
            ],
          };
          setTimeout(() => {
            onHandleAnyChange({
              current: name,
              data: selectedFilterData_for_customers,
            });
          }, 300);
        } else {
          //deselect-option
          selectedFilterData = {
            ...selection,
            [name]: [],
          };

          selectedFilterData_for_customers = {
            ...selection,
            [name]: [],
          };

          setSelection(selectedFilterData);
          setTimeout(() => {
            onHandleAnyChange({
              current: name,
              data: selectedFilterData_for_customers,
            });
          }, 300);
          props.setDropDownValues?.(getRemoveAll(selectedFilterData));
          if (!props.isCustomer) {
            getDropDownsData(getRemoveAll(selectedFilterData));
          }
        }
      } else {
        selectedFilterData = {
          ...selection,
          [name]:
            action.action !== "select-option"
              ? selected?.map((item) => item.value).filter((i) => i !== "all")
              : selected?.length === options.length
                ? ["all"].concat(selected?.map((item) => item.value))
                : selected?.map((item) => item.value),
        };

        selectedFilterData_for_customers = {
          ...selection,
          [name]:
            action.action !== "select-option"
              ? selected
                ?.map((item) => ({ id: item.value, Name: item.label }))
                .filter((i) => i !== "all")
              : selected?.length === options.length
                ? ["all"].concat(
                  selected?.map((item) => ({
                    id: item.value,
                    Name: item.label,
                  }))
                )
                : selected?.map((item) => ({ id: item.value, Name: item.label })),
        };

        setSelection(selectedFilterData);
        setTimeout(() => {
          onHandleAnyChange({
            current: name,
            data: selectedFilterData_for_customers,
          });
        }, 300);
        props.setDropDownValues?.(getRemoveAll(selectedFilterData));
        if (!props.isCustomer) {
          getDropDownsData(getRemoveAll(selectedFilterData));
        }
      }
      setOwnerType("select");
    };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      isValid,
      isSubmitted,
      submitCount,
    },
  } = useForm({
    defaultValues: {
      a_country: "",
      a_mco: "",
      a_bg: "",
      a_category: "",
      a_brand: "",
      a_nextStep: ""
    },
  });

  const removeSelection = (name, values) => {
    const selectedFilterData = {
      ...selection,
      [name]: [],
    };
    setSelection(selectedFilterData);
    props.setDropDownValues?.(getRemoveAll(selectedFilterData));
    getDropDownsData(getRemoveAll(selectedFilterData));
    setOwnerType("select")
  };

  const businessGroup = _.has(dropdownInfo, "businessGroup")
    ? dropdownInfo["businessGroup"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) =>
        location.pathname.includes("pom")
          ? element.label !== "POSM" && element.label !== "NA"
          : element
      )
    : [];

  const mco = _.has(dropdownInfo, "mco")
    ? dropdownInfo["mco"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const country = _.has(dropdownInfo, "country")
    ? dropdownInfo["country"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const category = _.has(dropdownInfo, "category")
    ? dropdownInfo["category"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null)
    : [];

  const brand = _.has(dropdownInfo, "brand")
    ? dropdownInfo["brand"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null)
    : [];

  const nextStep = _.has(dropdownInfo, "nextStep")
    ? dropdownInfo["nextStep"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null)
    : [];


  const getValue = (selection, values) => {
    let newSelection = [];
    //console.log(selection)
    //console.log(values)
    selection.filter((val) => {
      values.forEach((value) => {
        if (value.value == val) {
          newSelection.push(val);
        }
      });
    });
    return newSelection.length === values.length
      ? ["all"].concat(newSelection)
      : newSelection;
  };

  const getOptions = (ddlInfo, prop) => {
    return _.has(ddlInfo, prop)
      ? _.uniq(ddlInfo[prop])
        .map((item) => ({
          value: item.key,
          label: item.value,
        }))
        .filter((element) => {
          return (
            element.value !== null &&
            element.value !== "" &&
            element.label !== null &&
            element.label !== ""
          );
        })
      : [];
  };

  const onMultiSelectChange = (obj) => {
    let { businessGroup, mco, country, brand, category } = obj.data;
    if (obj.current == "businessGroup") {
      if (businessGroup != undefined) {
        if (!businessGroup.length) {
          setValue("a_bg", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      setBg(businessGroup);
    }
    if (obj.current == "mco") {
      if (mco != undefined) {
        if (!mco.length) {
          setValue("a_mco", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      setMco(mco);
    }
    if (obj.current == "country") {
      if (country != undefined) {
        if (!country.length) {
          setValue("a_country", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue("a_country", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setCountry(country);
    }
    if (obj.current == "brand") {
      if (brand != undefined) {
        if (!brand.length) {
          setValue("a_brand", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_brand", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setBrand(brand)
    }
    if (obj.current == "category") {
      if (category != undefined) {
        if (!category.length) {
          setValue("a_category", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue("a_category", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setCategory(category)
    }
  };
  const onHandleAnyChange = (obj) => {
    onMultiSelectChange(obj);
  };

  const handleSave = () => {
    // const asqw = newUsersData.filter((i) => {
    //   return deleteUsers.filter((del) => i.pack_type === del.pack_type).length >
    //     0
    //     ? false
    //     : true;
    // });
    // const userstoSave = deleteUsers.filter((i) => {
    //   return newUsersData.filter((del) => i.pack_type === del.pack_type)
    //     .length > 0
    //     ? true
    //     : false;
    // });
    // let deleteUsersList = userstoSave
    //   .map((i) => ({ ...i, isDelete: "1" }))
    //   .map(({ pack_type, old_pack_type, parent_pack_type, isDelete }) => ({
    //     pack_type,
    //     old_pack_type,
    //     parent_pack_type,
    //     isDelete,
    //   }));
    // const editPackInputArry = editUsersInput
    //   ?.filter((val) => val !== undefined)
    //   .filter((val) => val.pack_type !== "");
    // let updatePackTypes = [...asqw, ...deleteUsersList, ...editPackInputArry];
    // console.log(updatePackTypes);
    console.log(selection)
    const payload = {

      "toolId": screenoption,
      "UserEmail": user.email,
      "ownerData": [
        {
          "toolId": screenoption,
          "page_selection": pageSelection[0].value,
          "business_groups": selection?.businessGroup?.length ? selection.businessGroup : [],
          // "mco":  screenoption !== 3 ? (selection?.mco?.length ? selection.mco : []) : [],
          "countries": selection?.country?.length ? selection.country : [],
          "nextStep": selection?.nextStep?.length ? selection.nextStep : [],
          "brand": selection?.brand?.length ? selection.brand : [],
          "category": selection?.category?.length ? selection.category : [],
          "owner_type": ownerType,
          "assigned_owner": [...deleteUsers]
        }
      ]
    }

    setIsLoader(true)
    saveAssignedOwner(payload).then((response) => {
      setIsLoader(false)
      setShowTableList(true)
      if (deleteUsers?.length) {
        setSelectedOwner(deleteUsers)
        getDropDownsData()
        let sccs = <div className="tosterIcon"><div>{`Successfully updated the owners`}</div></div>
        toast.success(sccs, {
          containerId: "A",
        });
      }
    });
    getDropDownsData()
    setSelection([])
    setOwnerType("select")
    // window.location.reload()
  };

  const onSubmit = () => {
    // if (isValid) {
    // setTableShow(true);
    handleSave();
    // }
  };

  const handleNewUserInput = (e, Index, data) => {
    const newInputValues = [...editUsersInput];
    newInputValues[Index] = {
      pack_type: e.target.value,
      old_pack_type: data.pack_type,
      parent_pack_type: data.parent_pack_type,
      isDelete: data.isDelete,
    };
    setEditUsersInput(newInputValues);
  };

  return (
    <>
      {/* <div className="panel-custom"> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-3 ddn-fluid">

          {/* <div className="panel-custom-body"> */}
          <div style={{ width: "600px", marginBottom: "10px" }}>
            <span className="row-click-link"> <img src={ClickIcon} alt="" />You should create a User/Group with 'Write' access to assign user as an Owner.
            </span>
          </div>
          <h3 className="heading">Settings</h3>
          {isLoader ? <Loader /> :
            <>
              <div className="form-body-content pr-3">
                <div className="filterform_bg mt-3">
                  <input
                    type="text"
                    className="dom-hidden"
                    {...register("a_bg", {
                      required: {
                        value: true,
                        message: "Business Group is required.",
                      },
                    })}
                  />
                  <input
                    type="text"
                    className="dom-hidden"
                    {...register("a_mco", {
                      required: {
                        value: tool[0] === 1 ? true : false,
                        message: "MCO is required.",
                      },
                    })}
                  />
                  <input
                    type="text"
                    className="dom-hidden"
                    {...register("a_country", {
                      required: {
                        value: true,
                        message: "Country is required.",
                      },
                    })}
                  />
                  <input
                    type="text"
                    className="dom-hidden"
                    {...register("a_access", {
                      required: {
                        value: true,
                        message: "Access is required.",
                      },
                    })}
                  />
                  <input
                    type="text"
                    className="dom-hidden"
                    {...register("a_tabViewSettings", {
                      required: {
                        value: true,
                        message: "Tab View Settings is required.",
                      },
                    })}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <label>Tool <span style={{ color: "red" }}>{" "}*</span></label>
                      <div>
                        <select
                          className="btn-slim w-100"
                          defaultValue={screenoption}
                          onChange={(event) => {
                            setScreenOption(event.target.value);
                            setPageSelection(
                              allValues.filter((i) => i.key == event.target.value)
                            );
                            // getDropDownsData({tool:[event.target.value]})
                          }}
                        >
                          
                          {tool.filter((item) => {
                            if (item.value === "INFLOW" && userAccess?.inflowdata.data.length === undefined) {
                              return true
                            } else if (item.value === "POM" && userAccess?.pomdata.data.length === undefined) {
                              return true
                            } else {
                              return false
                            }
                          }).map((i, index) => {
                            return <option value={i.key} key={index}>{i.value}</option>;
                          })}
                        </select>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <label>Page Selection <span style={{ color: "red" }}>{" "}*</span></label>
                      <div>
                        <select
                          className="btn-slim w-100"
                          defaultValue={screenoption}
                          onChange={(event) => {
                            console.log(event.target.value);
                          }}
                        >
                          {pageSelection.map((i, index) => {
                            return <option value={i.key} key={index}>{i.value}</option>;
                          })}
                        </select>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className=" filterform_bg mt-3">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>

                      <label>
                        Business Group  <span style={{ color: "red" }}>{" "}*</span>
                        {isLoading && <Loadersml />}

                      </label>

                      <div>
                        <ULDropdown
                          isMyAccess={props.isMyAccess}
                          isUserManagementSelection
                          name="businessGroup"
                          value={getValue(
                            selection?.businessGroup ? selection?.businessGroup : [],
                            businessGroup
                          )}
                          options={getOptions(dropdownInfo, "businessGroup").filter(
                            (element) =>
                              location.pathname.includes("pom")
                                ? element.label !== "POSM" && element.label !== "NA"
                                : element
                          )}
                          onChange={handleChange("businessGroup", businessGroup)}
                          isMulti={true}
                          placeholder={"All"}
                          className="btn-slim w-100"
                          removeSelection={removeSelection}
                          isLoading={isLoading}
                          isOptionsDisabled={!selection.tool?.length}
                          title={getValue(
                            selection?.businessGroup ? selection?.businessGroup : [],
                            businessGroup
                          )}
                        />
                      </div>
                      <div className="error-mssg">
                        {errors.a_bg != undefined && errors.a_bg.message}
                      </div>
                    </Grid>

                    {/* {screenoption == 3 ? "" :
                              <Grid item xs={3}>
                                <label>
                                  MCO {isLoading && <Loadersml />}
                                </label>
                                <div>
                                  <ULDropdown
                                    isMyAccess={props.isMyAccess}
                                    isUserManagementSelection
                                    name="mco"
                                    value={getValue(selection?.mco ? selection?.mco : [], mco)}
                                    options={getOptions(dropdownInfo, "mco")}
                                    onChange={handleChange("mco", mco)}
                                    isMulti={true}
                                    placeholder={"All"}
                                    className="btn-slim w-100"
                                    removeSelection={removeSelection}
                                    isLoading={isLoading}
                                    isOptionsDisabled={!selection.tool?.length}
                                    title={mco}
                                  />
                                </div>
                                <div className="error-mssg">
                                  {errors.a_mco != undefined && errors.a_mco.message}
                                </div>
                              </Grid>
                            } */}

                    <Grid item xs={3}>
                      <label>
                        Country  <span style={{ color: "red" }}>{" "}*</span>{isLoading && <Loadersml />}
                      </label>
                      <div>
                        <ULDropdown
                          isMyAccess={props.isMyAccess}
                          isUserManagementSelection
                          name="country"
                          value={getValue(
                            selection?.country ? selection?.country : [],
                            country
                          )}
                          options={getOptions(dropdownInfo, "country")}
                          onChange={handleChange("country", country)}
                          isMulti={true}
                          placeholder={"All"}
                          className="react-select-container"
                          removeSelection={removeSelection}
                          isLoading={isLoading}
                          isOptionsDisabled={!selection.tool?.length}
                          title={getValue(
                            selection?.country ? selection?.country : [],
                            country
                          )}
                        />
                      </div>
                      <div className="error-mssg">
                        {errors.a_country != undefined && errors.a_country.message}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <label>
                        Category  <span style={{ color: "red" }}>{" "}*</span>{isLoading && <Loadersml />}
                      </label>
                      <div>
                        <ULDropdown
                          isMyAccess={props.isMyAccess}
                          isUserManagementSelection
                          name="category"
                          value={getValue(
                            selection?.category ? selection?.category : [],
                            category
                          )}
                          options={getOptions(dropdownInfo, "category")}
                          onChange={handleChange("category", category)}
                          isMulti={true}
                          placeholder={"All"}
                          className="react-select-container"
                          removeSelection={removeSelection}
                          isLoading={isLoading}
                          isOptionsDisabled={!selection.tool?.length}
                          title={getValue(
                            selection?.category ? selection?.category : [],
                            category
                          )}
                        />
                      </div>
                      <div className="error-mssg">
                        {errors.a_category != undefined && errors.a_category.message}
                      </div>
                    </Grid>
                    {<Grid item xs={3}>
                      <label>
                        Brand  <span style={{ color: "red" }}>{" "}*</span>{isLoading && <Loadersml />}
                      </label>
                      <div>
                        <ULDropdown
                          isMyAccess={props.isMyAccess}
                          isUserManagementSelection
                          name="brand"
                          value={getValue(
                            selection?.brand ? selection?.brand : [],
                            brand
                          )}
                          options={getOptions(dropdownInfo, "brand")}
                          onChange={handleChange("brand", brand)}
                          isMulti={true}
                          placeholder={"All"}
                          className="react-select-container"
                          removeSelection={removeSelection}
                          isLoading={isLoading}
                          isOptionsDisabled={!selection.tool?.length}
                          title={getValue(
                            selection?.brand ? selection?.brand : [],
                            country
                          )}
                        />
                      </div>
                      <div className="error-mssg">
                        {errors.a_brand != undefined && errors.a_brand.message}
                      </div>
                    </Grid>}
                    {screenoption === "3" && <Grid item xs={3}>
                      <label>
                        Next Step  <span style={{ color: "red" }}>{" "}*</span>{isLoading && <Loadersml />}
                      </label>
                      <div>
                        <ULDropdown
                          isMyAccess={props.isMyAccess}
                          isUserManagementSelection
                          name="nextStep"
                          value={getValue(
                            selection?.nextStep ? selection?.nextStep : [],
                            nextStep
                          )}
                          options={getOptions(dropdownInfo, "nextStep")}
                          onChange={handleChange("nextStep", nextStep)}
                          isMulti={true}
                          placeholder={"All"}
                          className="react-select-container"
                          removeSelection={removeSelection}
                          isLoading={isLoading}
                          isOptionsDisabled={!selection.tool?.length}
                          title={getValue(
                            selection?.nextStep ? selection?.nextStep : [],
                            nextStep
                          )}
                        />
                      </div>
                      <div className="error-mssg">
                        {errors.a_nextStep != undefined && errors.a_nextStep.message}
                      </div>
                    </Grid>}
                    {screenoption !== 1 && (
                      <Grid item xs={3}>
                        <label>Owner Type <span style={{ color: "red" }}>{" "}*</span></label>
                        <div>
                          <select
                            className="btn-slim w-100"
                            value={ownerType}
                            onChange={(event) => {
                              setOwnerType(event.target.value)
                              console.log(event.target.value);
                            }}
                          >
                            <option value="select">Select</option>
                            {ownnerTypes
                              .filter((i) => i.key == screenoption)
                              .map((i, index) => (
                                <option value={i.value} key={index}>{i.value}</option>
                              ))}
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <div className=" filterform_bg mt-3">
                  {/* <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <label>Action Type</label>
                              <div className="">
                                <select
                                  defaultValue={"select"}
                                  className="btn-slim w-100 has-table-as-dropdown"
                                onChange={(event) => {
                                  setActionType(event.target.value);
                                  console.log(event.target.value);
                                }}
                                >
                                  <option key={"select"}  >
                                                  select
                                              </option>
                                              {screenoption === "FG SLOB" && pagesettingOptions === "Waste Risk Details" ?
                                                  fgslobRiskActionType.map(i => {
                                                      return (<option key={i}>{i}</option>)
                                                  }) : screenoption === "FG SLOB" && pagesettingOptions === "Central Deal Calender" ?
                                                      fgslobCalenderActionType.map(i => {
                                                          return (<option key={i}>{i}</option>)
                                                      }) : screenoption === "INFLOW" && pagesettingOptions === "Inflow Details" ?
                                                          InflowActionType.map(i => {
                                                              return (<option key={i}>{i}</option>)
                                                          }) : screenoption === "POM" && pagesettingOptions === "POM Details" ?
                                                              InflowActionType.map(i => {
                                                                  return (<option key={i}>{i}</option>)
                                                              }) : ""
                                              }
                                  <option>Select</option>
                                </select>
                              </div>
                            </Grid>
                          </Grid> */}
                </div>
                <div className=" filterform_bg mt-3">
                  {/* <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <label>Select Owner</label>
                        <div>
                          <select
                            className="btn-slim w-100"
                            onChange={(event) => {
                              console.log(event.target.value);
                            }}
                          >
                            <option key={"all"}>All</option>
                          </select>
                        </div>
                      </Grid>
                    </Grid> */}
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <label>Select Owner</label>
                      <div className="">
                        <select
                          defaultValue={"select"}
                          className="btn-slim w-100 has-table-as-dropdown"
                          onChange={() => ''}
                        >
                          <option>Select</option>
                        </select>
                        {
                          <div className="pack-data-div">
                            {/* <div className="d-flex table-inline-search btn-group bg-black">
                                <img src={PlusIcon} />
                                <input
                                  ref={assignRef}
                                  className="bg-transparent"
                                  type="text"
                                  placeholder="Type to add New"
                                ></input>
                                <button
                                  className="btn btn-slim btn-secondary"
                                  on
                                  onClick={handelNewUserType}
                                >
                                  Ok
                                </button>
                              </div> */}

                            {newUsersData && (
                              <div className="position-relative ">
                                <div className="table_design w-100">
                                  <div
                                    className={`table sticky table-pack-data ${newUsersData?.length <= 0 ? "no-data" : ""
                                      } cells-width-auto}`}
                                  >
                                    <div className="header w-100">
                                      <div className="tr w-100">
                                        <div className="th c1 width-auto min-width-auto">
                                          <div className="th-in">
                                            Select Owners
                                          </div>
                                        </div>
                                        <div className="th c2 width-auto min-width-auto">
                                          {/* <div className="th-in">
                                                    <img
                                                      title="Delete"
                                                      src={TrashIconWhite}
                                                      width={15}
                                                    />
                                                  </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="body w-100">
                                      {(isDataLoading) && <Loader />}
                                      {newUsersData?.length && !isDataLoading && newUsersData?.map((data, index) => (
                                        <div className="tr w-100" key={index}>
                                          <div className="td min-width-auto c1">
                                            <div className="td-in">
                                              <div className="list-text">
                                                {data?.userEmail
                                                  ? data.userEmail
                                                  : data?.userName}
                                                {/* <span className="pl-2 pr-2">/</span>
                                                    <input
                                                      key={Index}
                                                      style={{
                                                        width: "60%",
                                                        height: "20px",
                                                        border: "0px !important",
                                                      }}
                                                      type="text"
                                                      placeholder={data?.userEmail}
                                                      onChange={(e) =>
                                                        handleNewUserInput(e, Index, data)
                                                      }
                                                    /> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="td min-width-auto c2">
                                            <div className="td-in">
                                              <Checkbox
                                                checked={isChecked(data)}
                                                className="list-checkbox"
                                                onClick={() => onCheckBoxCLick(data)}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {selectedUsersData?.length ?//selectedOwner?.length ?
                  <>
                    <div className="assign_owner_table-container mt-4 position-relative">
                      <div className="table_design mt-2 w-auto">
                        <div className="table sticky table-two-col data-table-two display nowrap scrollclass cells-width-auto" style={{ border: "1px solid #595757" }}>
                          <div className="header w-100">
                            <div className="tr">
                              <div className="th">
                                <div className="th-in">User Name</div>
                              </div>
                              <div className="th">
                                <div className="th-in">User Email</div>
                              </div>
                            </div>
                          </div>

                          <div className="body w-100 position-relative">
                            {selectedUsersData?.length ? selectedUsersData.map((elem, i) => {
                              return (<div className="tr" key={i}>
                                <div className="td email align-items-start">
                                  <div className="td-in w-100">{elem.userName}</div>
                                </div>
                                <div className="td user_name align-items-start">
                                  <div className="td-in w-100">{elem.userEmail}</div>
                                </div>
                              </div>)
                            })
                              :
                              <div className="tr">
                                <div className="td">
                                  <div className="td-in w-100">{"No Data"}</div>
                                </div>
                              </div>
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </>
                  : ""
                }
                <div className="mt-5">
                  <button
                    type="button"
                    onClick={onSubmit}
                    className="btn btn-big-width btn-big btn-secondary pull-right"
                  >
                    Save & Next
                  </button>
                </div>
              </div>
            </>
          }
          {/* </div> */}
        </div>
      </form>
      {/* </div> */}
    </>
  );
}

export default AssignOwner;
