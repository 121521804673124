import { API } from "../backend";
import axios from "axios";

export const BiddingPortalDetails = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/getBiddingPortal`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

// 
// export const downloadBiddingPortalData = async (payload) => {
//   try {
//     const response = await axios.get(`${API}/store/downloadBiddingPortal`, {
//       params: {
//         ...payload,
//       },
//       responseType: "arraybuffer",
//     });
//     return response.data;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };
 

export const downloadBiddingPortalData = async (payload) => {
  return axios.post(`${API}/store/downloadBiddingPortalData`, payload, {
    headers: {
      'Content-Disposition': "attachment; filename=Bidding_Portal.xlsx",
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Bidding_Portal.xlsx');
    document.body.appendChild(link);
    link.click();
    return response
  });
}
export const createBiddingNotifications = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/vendor/createBiddingNotifications`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const getBiddingNotifications = async (payload) => {
  try {
    const response = await axios.get(`${API}/store/vendor/getBiddingNotifications?vendorEmail=${payload.vendorEmail}` );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const updateBiddingNotification = async (payload) => {
  try {
    const response = await axios.put(`${API}/store/vendor/updateStatusBiddingNotifications`, payload );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

//updateStatusBiddingNotifications

// export const getBiddingListName = async (payload) => {
//   try {
//     const response = await axios.post(`${API}/store/getBiddingList`, payload);
//     return response.data;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };
export const getBiddingListName = async (payload, isVendor) => {
  try {
    const response = await axios.post(
      `${API}/store/${
        isVendor ? `vendor/displayVendorBiddingContainers` : `customer/displayCustomerBiddingContainers`
      }`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};


export const getUserBiddingListName = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getUserBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const AddToUserBiddingList = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/addToUserBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const ViewUserBiddingList = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/viewUserBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateOfferPrice = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/updateBiddingofferPrice`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateUserBiddingPortal = async (payload) => {
  try {
    const response = await axios.put(
      `${API}/store/updateBiddingPortal`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};



export const BiddingListComments = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/getAllBiddingListComments`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};


export const getBiddingComments = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/vendor/displayVendorBiddingComments`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const addToBiddingActions = async (payload,isVendor) => {
  try {
    const response = await axios.post(isVendor?`${API}/store/vendor/addToVendorBiddingActions`:`${API}/store/customer/addToCustomerBiddingActions`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};