import React, { useState, useEffect, useCallback, useContext } from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { getResidualAssignedToLocalDealData } from "../../../Services/WasteRiskSummaryService";
import { payloadWithFilters, stackBarChartOptions, stackBarChartOptions2 } from "../helpers";
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const ExpectedCompletionChart = ({ dropDownValues,displayType }) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userAccess = useContext(UserAccessContext)


  useEffect(() => {
    if(dropDownValues){
      getChartData(payloadWithFilters(dropDownValues,userAccess));
     }
  }, [dropDownValues]);

  const getChartData = useCallback((payload) => {
    getResidualAssignedToLocalDealData(payload)
      .then((response) => {
        setChartData(response.data);
      })
      .finally(() => setIsLoading(false));
  });

  const data = {
    labels: chartData?.map((data) => data.mco),
    datasets: [
      {
        label: "Jan",
        data: chartData?.map((data) => data.January),
        backgroundColor: "rgb(102, 204, 0)",
      },
      {
        label: "Feb",
        data: chartData?.map((data) => data.February),
        backgroundColor: "rgb(255, 51, 255)",
      },
      {
        label: "Mar",
        data: chartData?.map((data) => data.March),
        backgroundColor: "rgb(92,188,169)",
      },
      {
        label: "Apr",
        data: chartData?.map((data) => data.April),
        backgroundColor: "rgb(84,168,216)",
      },
      {
        label: "May",
        data: chartData?.map((data) => data.May),
        backgroundColor: "rgb(132,108,227)",
      },
      {
        label: "Jun",
        data: chartData?.map((data) => data.June),
        backgroundColor: "rgb(239,163,58)",
      },
      {
        label: "Jul",
        data: chartData?.map((data) => data.July),
        backgroundColor: "rgb(235,120,167)",
      },
      {
        label: "Aug",
        data: chartData?.map((data) => data.August),
        backgroundColor: "rgb(204, 204, 0)",
      },
      {
        label: "Sept",
        data: chartData?.map((data) => data.September),
        backgroundColor: "rgb(255, 0, 0)",
      },
      {
        label: "Oct",
        data: chartData?.map((data) => data.October),
        backgroundColor: "rgb(153, 0, 153)",
      },
      {
        label: "Nov",
        data: chartData?.map((data) => data.November),
        backgroundColor: "rgb(232, 96, 86)",
      },
      {
        label: "Dec",
        data: chartData?.map((data) => data.December),
        backgroundColor: "rgb(102, 0, 0)",
      },
      
      // {
      //   label: "> Feb",
      //   data: chartData?.map((data) => data.greater_than_February),
      //   backgroundColor: "rgb(154, 37, 37)",
      // },
      {
        label: "Blank",
        data: chartData?.map((data) => data.blank),
        backgroundColor: "rgb(83, 91, 110)",
        hidden: false
      },
    ],
  };

  if (isLoading) return <Loader hideText={true} />;

  return <Bar options={displayType==="popupDisplay"?stackBarChartOptions2:stackBarChartOptions} data={data} />;
};

export default ExpectedCompletionChart;
