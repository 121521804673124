import React, { useCallback, useState, useEffect, useContext } from "react";
import InfoIcon from "../../../Assets/img/info-white.svg";
import EditIcon from "../../../Assets/img/edit.svg";
import FileIcon from "../../../Assets/img/residual_status.svg";

import Loader from "../../../Common/loader";
import moment from "moment";
import {
  centralDealCalenderDetails,
  saveUpcomingRounds,
} from "../../../Services/CentralDealCalenderService";
import { COLUMNS } from "./heading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toast, { notify } from "../../../../src/Common/toast";
import { useHistory } from "react-router-dom";
import RefreshIcon from "../../../Assets/img/refresh.svg";
import { UserAccessContext } from "../../Application";
import { debounce } from "lodash";

const DealCalenderData = (props) => {
  const [calendardata, setCalanderData] = useState({});
  const noClickList = ["Central Deal Completed", "Central Deal Rejected"];
  const [isEdit, setIsEdit] = React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const userAccess = useContext(UserAccessContext);

  const convertToInternationalCurrencySystem = (value) => {

    if (Math.abs(Number(value)) >= 1.0e9) {
      return `€ ${(Math.abs(Number(value)) / 1.0e9).toFixed(2) + "B"}`;
    } else if (Math.abs(Number(value)) >= 1.0e6) {
      return `€ ${(Math.abs(Number(value)) / 1.0e6).toFixed(2) + "M"}`;
    } else if (Math.abs(Number(value)) >= 1.0e3) {
      return `€ ${(Math.abs(Number(value)) / 1.0e3).toFixed(2) + "K"}`;
    } else {
      return Math.abs(Number(value));
    }
  };

  // console.log(convertToInternationalCurrencySystem(1000));      
  // console.log(convertToInternationalCurrencySystem(1500000));   
  // console.log(convertToInternationalCurrencySystem(2000000000));  
  // console.log(convertToInternationalCurrencySystem(-50000));    
  // console.log(convertToInternationalCurrencySystem(123456789));

  const getDealCalendarData = useCallback((payload) => {
    centralDealCalenderDetails(payload)
      .then((response) => {
        setIsRefresh(false);
        setCalanderData(response);
      })
      .catch(() => {
        setIsRefresh(false);
      });
  }, []);
  const [upcommingRounds, setUpcommingRounds] = useState([])
  const handleDate = (prop, value, index) => {
    calendardata.upcoming_rounds[index][prop] = value;
    setCalanderData({ ...calendardata });
    let data = {
      id: calendardata.upcoming_rounds[index].id,
      key: prop,
      value: value ? moment(value).format("YYYY-MM-DD") : value,
    };
    setUpcommingRounds((prev) => [...prev, data])
  };


  const handleRoundChange = (prop, value, index) => {
    calendardata.upcoming_rounds[index][prop] = value;
    setCalanderData({ ...calendardata });
    let data = {
      id: calendardata.upcoming_rounds[index].id,
      key: prop,
      value: value
    }
    setUpcommingRounds((prev) => [...prev, data])
    // handleUpdateRound(calendardata.upcoming_rounds[index].id, prop, value)
  }
  useEffect(() => {
    console.log(upcommingRounds)
  }, [upcommingRounds])


  // const handleUpdateRound = debounce((id, key, value) => {
  //   upDateUpcomingRounds({
  //     id: id,
  //     key: key,
  //     value: value,
  //   });
  // }, 100)
  const handelUpcomingRoundsSave = useCallback(() => {
    let uniqueEntriesMap = new Map();

    // Iterate through the original data and update the map
    upcommingRounds.forEach(entry => {
      const id = entry.id+entry.key;
      // Check if the map already has an entry with the same ID and update if it's newer
      uniqueEntriesMap.set(id, entry);
      // if (!uniqueEntriesMap.has(id) ) {
      //   uniqueEntriesMap.set(id, entry);
      // }else{
      //   uniqueEntriesMap[id]=entry;
      // }
    });
    let uniqueData=[]

    // Convert the map values back to an array
    uniqueData = Array.from(uniqueEntriesMap.values());

    console.log(uniqueData);
    setIsEdit(false)
    const payload = {
      upcomingRounds:uniqueData
    }
    // uniqueData=[]
    setUpcommingRounds([])
    upDateUpcomingRounds(payload);
  },[upcommingRounds])

  const upDateUpcomingRounds = (payload) => {
    saveUpcomingRounds(payload)
      .then((response) => {
        setIsEdit(false);
        if (response.message || response.status === 200) {
          notify({
            message: `${response.message || `Sucessfully Updated`}`,
            type: "success",
          });
        }
        getDealCalendarData({
          businessGroupId: props.dropDownValues?.businessGroup ?? [],
          countryId: props.dropDownValues?.country ?? [],
          mcoId: props.dropDownValues?.mco ?? [],
        });
      })
      .catch(() => {
        notify({
          message: `failed to update`,
          type: "error",
        });
      });
  };

  useEffect(() => {
    getDealCalendarData({
      businessGroupId: props?.dropDownValues?.businessGroup ?? [],
      countryId: props?.dropDownValues?.country ?? [],
      mcoId: props?.dropDownValues?.mco ?? [],
    });
  }, [getDealCalendarData, props.dropDownValues]);

  const history = useHistory();

  return (
    <>
      <div className="page_dealCalenderData central_deal_calendar_page">
        <Toast position={"top-center"} />
        <div className="calendardata">
          <div className="row">
            <div className="col-md-10">
              <div className="graphtitle">
                <h3>
                  Central Deals &nbsp;{" "}
                  <a>
                    <img width="20px" height="20px" src={InfoIcon} />
                  </a>
                </h3>
                <p>Please click on deal type to create bidding listing</p>
              </div>
            </div>
            <div className="col-md-2 table-filters text-right">
              <div className="flex justify-content-end">
                <span className="marginset pull-none">
                  <span
                    className="info_btn"
                    onClick={() => {
                      setIsRefresh(true);
                      setIsEdit(false);
                      getDealCalendarData({
                        businessGroupId:
                          props.dropDownValues?.businessGroup ?? [],
                        countryId: props.dropDownValues?.country ?? [],
                        mcoId: props.dropDownValues?.mco ?? [],
                      });
                    }}
                  >
                    <img src={RefreshIcon} alt="Open" />
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div>
            <div className="current-round">
              <h4 className="tiltleclass mb-3">Current Round</h4>
              <ul>
                {(Object.keys(calendardata?.currentRound?.deal_type || {}).length <= 0 ||
                  isRefresh) && (
                    <li className="cursor-pointer">
                      <Loader />
                    </li>
                  )}
                {Object.keys(calendardata?.currentRound?.deal_type || {}).length > 0 &&
                  !isRefresh &&
                  Object.keys(calendardata?.currentRound?.deal_type || {}).map(
                    (key, index) => {
                      console.log(calendardata?.currentRound?.deal_type)
                      console.log(key)
                      let keys = calendardata?.currentRound?.deal_type[key].dealtype?.replace(/[^a-zA-Z0-9]+/g, '_').toLowerCase();
                      console.log(keys)

                      console.log(COLUMNS[keys])
                      if (
                        COLUMNS[keys] !== undefined
                      ) {
                        return (
                          <li
                            key={index}
                            className={
                              !noClickList.includes(COLUMNS[keys].name)
                                ? "cursor-pointer"
                                : ``
                            }
                            onClick={() => {
                              if ((userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("4") || userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("5"))) {
                              console.log(userAccess);
                              if (
                                userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("4") ||
                                  userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("5")
                              ) {
                                const dealId =
                                  calendardata?.currentRound?.deal_type[key]?.dealTypeId ||
                                  calendardata?.currentRound?.deal_type[key][0]
                                    ?.dealTypeId;
                                history.push({
                                  pathname: `/dealmanagement`,
                                  state: {
                                    dealId: dealId,
                                    heading: COLUMNS[keys].name,
                                  },
                                });
                              }
                            }}}
                          >
                            <span
                              className={`
                         ${COLUMNS[keys].class ?? "purple"}
                        `}
                            >
                              {console.log(calendardata?.currentRound)}
                              <h5>{calendardata?.currentRound?.deal_type[key]?.dealtype ||calendardata?.currentRound?.deal_type[key][0]?.dealtype}</h5>
                              <div className="inner-box ">
                                <div>
                                  <span className="prop d-inline-block">
                                    Central Deal Deadline{" "}
                                  </span>
                                  <span className="val d-inline-block">
                                    {calendardata?.central_deal_completed_date &&
                                      moment(
                                        new Date(
                                          calendardata?.central_deal_completed_date
                                        )
                                      ).format("DD-MMM")}
                                    {!calendardata?.central_deal_completed_date &&
                                      calendardata?.central_deal_completed_date !==
                                      0 && (
                                        <span className="text-dull-grey">
                                          Not Set
                                        </span>
                                      )}
                                  </span>
                                </div>
                                <div>
                                  <span className="prop d-inline-block">
                                    # of SKU
                                  </span>
                                  <span className="val d-inline-block">
                                    {
                                      calendardata?.currentRound?.deal_type[key]
                                        ?.no_of_sku ?? 0
                                    }
                                  </span>
                                </div>
                                <div>
                                  <span className="prop d-inline-block">
                                    Deal Value
                                  </span>
                                  <span className="val d-inline-block">
                                    {convertToInternationalCurrencySystem(
                                      calendardata?.currentRound?.deal_type[key]
                                        ?.deal_value ?? 0
                                    )}
                                  </span>
                                </div>
                              </div>
                            </span>
                          </li>
                        );
                      }
                    }
                  )}
              </ul>
            </div>
          </div>

          <div className="upcoming-round">
            <div className="d-flex justify-content-between">
              <h4 className="tiltleclass mb-3">Upcoming rounds</h4>

              {!isEdit ? calendardata?.upcoming_rounds?.length > 0 && (
                <div className="mb-2">
                  <button
                    type="button"
                    className="editbutton btn-secondary btn-slim"
                    onClick={() => {
                      setIsEdit(true);
                    }}
                  >
                    {" "}
                    <img src={EditIcon} className="" alt="" /> Edit
                  </button>
                </div>
              )
                :
                <div className="mb-2">
                  <button
                    type="button"
                    className="editbutton btn-secondary btn-slim"
                    onClick={handelUpcomingRoundsSave}
                  >
                    {" "}
                    <img src={EditIcon} className="" alt="" /> Save
                  </button>
                </div>
              }
            </div>

            <div className="roundtable table_design mb-4">
              <table className="display deal_calendar_upcoming_rounds_table nowrap scrollclass text-white text-sm">
                <thead>
                  <tr>
                    <th className="text-center" width="20%">
                      Round
                    </th>
                    <th className="text-center" width="20%">
                      Data Refresh in Polaris
                    </th>
                    <th className="text-center" width="20%">
                      Local market start time
                    </th>
                    <th className="text-center" width="20%">
                      Central Deal Start time
                    </th>
                    <th className="text-center" width="20%">
                      Central Deal Complete time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(!calendardata?.upcoming_rounds?.length > 0 || isRefresh) && (
                    <tr>
                      <td colSpan={5}>
                        <Loader />
                      </td>
                    </tr>
                  )}

                  {calendardata?.upcoming_rounds?.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{!isEdit ? val.round :
                          <input
                            type="text"
                            className="input-edit-mode"
                            style={{ display: 'inline-block', 'background-color': '#000' }}
                            value={val?.round === null ? "" : val.round}
                            onChange={(e) => handleRoundChange("round", e.target.value, index)}
                          // onBlur={(e) => handleUpdateRound(val.id, "round", val.round)}
                          />
                        }</td>
                        {val.data_refresh_in_polaris && !isEdit && (
                          <td className="text-center">
                            {moment(
                              new Date(val.data_refresh_in_polaris)
                            ).format("DD-MMM")}
                          </td>
                        )}
                        {val.data_refresh_in_polaris && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                val.data_refresh_in_polaris
                                  ? new Date(val.data_refresh_in_polaris)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "data_refresh_in_polaris",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}
                        {val.data_refresh_in_polaris === null &&
                          !isEdit && (
                            <td className="text-center text-dull-grey">
                              Not Set
                            </td>
                          )}
                        {val.data_refresh_in_polaris === null && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              selected={
                                val.data_refresh_in_polaris
                                  ? new Date(val.data_refresh_in_polaris)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "data_refresh_in_polaris",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}

                        {val.local_market_start_time && !isEdit && (
                          <td className="text-center">
                            {moment(
                              new Date(val.local_market_start_time)
                            ).format("DD-MMM")}
                          </td>
                        )}
                        {val.local_market_start_time && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                val.local_market_start_time
                                  ? new Date(val.local_market_start_time)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "local_market_start_time",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}
                        {val.local_market_start_time === null && !isEdit && (
                          <td className="text-center text-dull-grey">
                            Not Set
                          </td>
                        )}
                        {val.local_market_start_time === null && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              selected={
                                val.local_market_start_time
                                  ? new Date(val.local_market_start_time)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "local_market_start_time",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}

                        {val.central_deal_start_date && !isEdit && (
                          <td className="text-center">
                            {moment(
                              new Date(val.central_deal_start_date)
                            ).format("DD-MMM")}
                          </td>
                        )}
                        {val.central_deal_start_date && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                val.central_deal_start_date
                                  ? new Date(val.central_deal_start_date)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "central_deal_start_date",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}
                        {val.central_deal_start_date === null && !isEdit && (
                          <td className="text-center text-dull-grey">
                            Not Set
                          </td>
                        )}
                        {val.central_deal_start_date === null && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              selected={
                                val.central_deal_start_date
                                  ? new Date(val.central_deal_start_date)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "central_deal_start_date",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}

                        {val.central_deal_completed_date && !isEdit && (
                          <td className="text-center">
                            {moment(
                              new Date(val.central_deal_completed_date)
                            ).format("DD-MMM")}
                          </td>
                        )}
                        {val.central_deal_completed_date && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              selected={
                                val.central_deal_completed_date
                                  ? new Date(val.central_deal_completed_date)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "central_deal_completed_date",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}
                        {val.central_deal_completed_date === null &&
                          !isEdit && (
                            <td className="text-center text-dull-grey">
                              Not Set
                            </td>
                          )}
                        {val.central_deal_completed_date === null && isEdit && (
                          <td className="text-center">
                            <DatePicker
                              selected={
                                val.central_deal_completed_date
                                  ? new Date(val.central_deal_completed_date)
                                  : null
                              }
                              onChange={(date) =>
                                handleDate(
                                  "central_deal_completed_date",
                                  date,
                                  index
                                )
                              }
                            />
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealCalenderData;
