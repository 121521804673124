import React, { useState, useEffect, useRef,  useContext } from "react";
import Popup from "reactjs-popup";
import Settings from "./Settings";
import UserAdd from "../common/UserAdd";
import PlusIcon from "../../../Assets/img/Plus.svg";
import Table from "../Table/TypeCreateTable";
import { ToastContainer, toast } from "react-toastify";
import { createGroupUser, getUserGroups, updateGroupUser, updateMyGroupUsers } from "../../../Services/userManagementService";
import { notify } from "../../../Common/toast";
import { v4 } from "uuid";
import _, { forEach, indexOf, uniq, uniqBy, difference, differenceWith, xorBy } from "lodash";
import { UserAccessContext, UserContext } from "../../Application";
import { useForm, useWatch, useFormState } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { regex_email, regex_letters_only } from "../../../utility/constants";
import CreatedUsersAction from "./CreatedUsersAction";
import GroupNotifications from "./GroupNotifications";
import Loader from "../../../Common/loader";

const TypeCreate = (props) => {
  const user = useContext(UserContext);
  const groupName = useRef(null);
  const ref = useRef("")
  const childRef = useRef(null);
  const [dropDownValues, setDropDownValues] = useState({ tool: ["1"] });
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    resetField,
    setValue,
    watch,
    reset,
    trigger,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      isValid,
      isSubmitted,
      submitCount,
    },
  } = useForm({
    defaultValues: {
      "groupNameInp": props.edit ? props.createType === "Group" ? props.name : "" : "",
      "userNameInp": props.edit ? props.createType === "User" ? props.name : "" : "",
      "userEmailInp": props.edit ? props.createType === "User" ? props.email : "" : "",
      "a_tabViewSettings": props.edit ? props.createType === "Group" ? dropDownValues.tabViewSetting : "" : "",
      "a_access": props.edit ? props.createType === "Group" ? dropDownValues.access : "" : "",
      "a_country": props.edit ? props.createType === "Group" ? dropDownValues.country : "" : "",
      "a_mco": props.edit ? props.createType === "Group" ? dropDownValues.mco : "" : "",
      "a_bg": props.edit ? props.createType === "Group" ? dropDownValues.businessGroup : "" : "",
      "a_brand": props.edit ? props.createType === "Group" ? dropDownValues.brand : "" : "",
      "a_category": props.edit ? props.createType === "Group" ? dropDownValues.category : "" : ""
    }
  });
  const groupNameTxt = watch("groupNameInp", false);
  const [ddlInfo, setDdlInfo] = useState([]);
  const [createGroup, setCreateGroup] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState(false);
  const [initEditGroupName, setInitEditGroupName] = useState("");
  const [totalUsersAdded, setTotalUsersAdded] = useState([]);
  const [isUserAddFormInvalid, setUserAddFormInvalid] = useState(true);
  const [isTypeCreateModalOpen, setTypeCreateModalOpen] = useState(false);
  const [isWarnModalOpen, setWarnModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tool, setTool] = useState([]);
  const [bg, setBg] = useState([]);
  const [mco, setMco] = useState([]);
  const [country, setCountry] = useState([]);
  const [tabViewSettings, setTabViewSettings] = useState([]);
  const [myAccessValidation, setMyAccessValidation] = useState([]);
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);
  const [groupNameHandler, setGroupNameHandler] = useState([]);
  const [deletedUser, setDeletedUser] = useState([])
  const userAccess = useContext(UserAccessContext);
  const [userTableKey, updateUserTableKey] = useState(0);
  const [roleUser, setRoleUser] = useState()
  const [addUserInfo, setAddUserInfo] = useState();
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [newUserList, setNewUserList] = useState([])
  const [actionOnUser, setActionOnUser] = useState(null)
  const [openGroupNotifications, setOpenGroupNotifications] = useState(false);
  const [groupNotificationsKey, setOpenGroupNotificationsKey] = useState(0);
  const [isSettingsDDLoading, setSettingsDDLoading] = useState(false);
  const [userAddError, setUserAddError] = useState(false);
  const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);
  const [isUpdateuser,setIsupdateUser] = useState(false)

  let userDataDb;
  let finalUserData = [];
  const editModelOpen = () => {
    setTypeCreateModalOpen(true)
    let dropDownValuesDb;
    if (props.createType === "Group" || true) {
      if (props.edit) {
        const dataMapping = props.data?.map(({ Id, UserEmail, UserFirstName, UserLastName, GroupName, accessId, Permission,
          UserRole, PomPermission, InflowPermission }) => ({
            UserEmail,
            Id,
            UserFirstName,
            UserLastName,
            GroupName,
            accessId,
            UserRole,
            Permission,
            PomPermission,
            InflowPermission
          }))
        userDataDb = props.createType === "Group" ? dataMapping.filter(e => e.GroupName === props.name || e.UserEmail === props.email) : dataMapping.filter(e => e.GroupName === null && e.UserEmail === props.email);


        //setTimeout(() => {

          userDataDb.forEach((i, id) => {
            if (i.Permission) {
              let dummy = {
                id: id + 1,
                user_name: i.UserFirstName,
                email: i.UserEmail,
                user_access: JSON.parse(i.Permission)["myaccess"].accessId,
                user_role: i.UserRole,
                tool: JSON.parse(i.Permission)["myaccess"].toolId.split(','),
                businessGroup: JSON.parse(i.Permission)["myaccess"].bussinessGroupId,
                brand: JSON.parse(i.Permission)["myaccess"].brandId,
                category: JSON.parse(i.Permission)["myaccess"].categoryId,
                country: JSON.parse(i.Permission)["myaccess"].countryId,
                mco: JSON.parse(i.Permission)["myaccess"].mcoId,
                tabViewSetting: JSON.parse(i.Permission)["myaccess"].tabViewSettingId,
                ddlInfo: JSON.parse(i.Permission)["myaccess"].ddlInfo
              }
              finalUserData = [...finalUserData, dummy]
            }
            if (i.InflowPermission) {
              let dummy = {
                id: id + 1,
                user_name: i.UserFirstName,
                email: i.UserEmail,
                user_access: JSON.parse(i.InflowPermission)["myaccess"].accessId,
                user_role: i.UserRole,
                tool: JSON.parse(i.InflowPermission)["myaccess"].toolId.split(','),
                businessGroup: JSON.parse(i.InflowPermission)["myaccess"].bussinessGroupId,
                brand: JSON.parse(i.InflowPermission)["myaccess"].brandId,
                category: JSON.parse(i.InflowPermission)["myaccess"].categoryId,
                country: JSON.parse(i.InflowPermission)["myaccess"].countryId,
                mco: JSON.parse(i.InflowPermission)["myaccess"].mcoId,
                tabViewSetting: JSON.parse(i.InflowPermission)["myaccess"].tabViewSettingId,
                ddlInfo: JSON.parse(i.InflowPermission)["myaccess"].ddlInfo
              }
              finalUserData = [...finalUserData, dummy]
            }
            if (i.PomPermission) {
              let dummy = {
                id: id + 1,
                user_name: i.UserFirstName,
                email: i.UserEmail,
                user_access: JSON.parse(i.PomPermission)["myaccess"].accessId,
                user_role: i.UserRole,
                tool: JSON.parse(i.PomPermission)["myaccess"].toolId.split(','),
                businessGroup: JSON.parse(i.PomPermission)["myaccess"].bussinessGroupId,
                brand: JSON.parse(i.PomPermission)["myaccess"].brandId,
                category: JSON.parse(i.PomPermission)["myaccess"].categoryId,
                country: JSON.parse(i.PomPermission)["myaccess"].countryId,
                mco: JSON.parse(i.PomPermission)["myaccess"].mcoId,
                tabViewSetting: JSON.parse(i.PomPermission)["myaccess"].tabViewSettingId,
                ddlInfo: JSON.parse(i.PomPermission)["myaccess"].ddlInfo
              }
              finalUserData = [...finalUserData, dummy]
            }
          })


        if(props.createType === "Group"){
          dropDownValuesDb = [...new Set(props.data
            .map(({ GroupId, GroupName, Permission, InflowPermission, PomPermission }) => ({
              GroupId,
              GroupName,
              Permission, InflowPermission, PomPermission
            }))
            .filter(e => e.GroupId != null)
            .filter(e => e.GroupName === props.name)
            .map(({ GroupId, GroupName, Permission, InflowPermission, PomPermission }) => ({ GroupId, GroupName, Permission, InflowPermission, PomPermission }))
            .map(JSON.stringify))
          ].map(JSON.parse)
        }
        else{
          dropDownValuesDb = (props.data
            .map(({ GroupId,UserEmail, GroupName, Permission, InflowPermission, PomPermission }) => ({
              GroupId,
              UserEmail,
              GroupName,
              Permission, InflowPermission, PomPermission
            }))
            // .filter(e => e.GroupId != null)
            .filter(e => e.UserEmail === props.email)
            .map(({ GroupId, GroupName, Permission, InflowPermission, PomPermission }) => ({ GroupId, GroupName, Permission, InflowPermission, PomPermission }))
            .map(JSON.stringify))
          .map(JSON.parse)

        }
        
        let dropDownData;

        if (dropDownValuesDb[0].Permission) {
          dropDownData = JSON.parse(dropDownValuesDb[0].Permission)
        }

        if (dropDownValuesDb[0].InflowPermission) {
          dropDownData = JSON.parse(dropDownValuesDb[0].InflowPermission)
        }
        if (dropDownValuesDb[0].PomPermission) {
          dropDownData = JSON.parse(dropDownValuesDb[0].PomPermission)
        }

        console.log("asdfghjk",dropDownData,dropDownValuesDb)

        dropDownData.myaccess.toolId = [dropDownData["myaccess"]?.toolId]
        // dropDownData["myaccess"]["tool"] = dropDownData["myaccess"]?.toolId
       
        let dummy2 = {
          tool: dropDownData["myaccess"].toolId,
          // toolId:dropDownData["myaccess"].toolId,
          businessGroup: dropDownData["myaccess"].bussinessGroupId,
          mco: dropDownData["myaccess"].mcoId,
          country: dropDownData["myaccess"].countryId,
          access: dropDownData["myaccess"].accessId,
          tabViewSettings: dropDownData["myaccess"].tabViewSettingId,
          brand: dropDownData["myaccess"].brandId,
          category: dropDownData["myaccess"].categoryId,
        }
        console.log("dropDownData....",dropDownData,dropDownData["myaccess"].toolId,dummy2)
        setDropDownValues(dummy2)
        handleMultiSelectChange({ data: dummy2 })   
  }
    } else {
      let userDatass = props.data.filter(i => i.UserEmail === props.email)[0]
    //  console.log((userDatass))
      setRoleUser(userDatass.UserRole)

      // console.log(JSON.parse(userDatass.Permission))
      // console.log(JSON.parse(userDatass.InflowPermission))
      let dropDownData
      if (userDatass.Permission || dropDownValues.tool.includes("1")) {
        dropDownData = JSON.parse(userDatass.Permission)
      }
      if (userDatass.InflowPermission || dropDownValues.tool.includes("2")) {
        dropDownData = JSON.parse(userDatass.InflowPermission)
      }
      if (userDatass.PomPermission || dropDownValues.tool.includes("3")) {
        dropDownData = JSON.parse(userDatass.PomPermission)
      }
      dropDownData["myaccess"]["toolId"] = [dropDownData["myaccess"]?.toolId]
      let dummy = {
        tool: dropDownData["myaccess"].toolId,
        businessGroup: dropDownData["myaccess"].bussinessGroupId,
        mco: dropDownData["myaccess"].mcoId,
        country: dropDownData["myaccess"].countryId,
        access: [userDatass.accessId],//dropDownData["myaccess"].accessId,
        tabViewSettings: dropDownData["myaccess"].tabViewSettingId,
        brand: dropDownData["myaccess"].brandId,
        category: dropDownData["myaccess"].categoryId,
      }
      setDropDownValues(dummy)
    }
    setTotalUsersAdded(finalUserData)
  }

  useEffect(() => {
    // editModelOpen()
  }, [dropDownValues]);

  useEffect(() => {
    setInitEditGroupName(props.name);
  }, [props.name]);

  const checkFilled = (val) => {
    if (props.createTypeLock) {
    } else {
      if (val == "") {
        setCreateGroup(false);
      } else {
        setCreateGroup(true);
      }
    }
  };

  useEffect(() => {
    if (groupNameTxt !== null) {
      let gp_name = groupNameTxt.toString();
      checkFilled(gp_name);
    }
  }, [groupNameTxt]);

  const deleteSelected = () => {
    setActionOnUser('delete');
    childRef.current.onActionClick();
  }

  const createTypeCheck = () => {
    if (props.createType == "Group") {
      setCreateGroup(true);
      setGroupNameHandler(
        register("groupNameInp", {
          required: {
            value: true,
            message: "Group Name is required."
          },
          maxLength: {
            value: 50,
            message: "Group Name is too long."
          }
        })
      )
    } else if (props.createType == "User") {
      setCreateGroup(false);
      setGroupNameHandler(
        register("groupNameInp", {
          maxLength: {
            value: 50,
            message: "Group Name is too long."
          }
        })
      )
    }
  }

  const clearAllSettingsError = () => {
    setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true });
    setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true });
    setValue("a_country", "sl", {shouldValidate: true,shouldDirty: true});
    setValue("a_access", "sl", {shouldValidate: true,shouldDirty: true});
    setValue("a_brand", "sl", {shouldValidate: true,shouldDirty: true});
    setValue("a_category", "sl", {shouldValidate: true,shouldDirty: true});
    setValue("a_tabViewSettings", "sl", {shouldValidate: true,shouldDirty: true});
}

  const processCreate = () => {
    if (createGroup) {
      var gp = getValues("groupNameInp");
      if(gp === '') {
          setError("groupNameInp",{
            type:"manual",
            message:"Group Name is Required"
          });
          document.querySelector(".modal-content-raised").scrollTop = 0;
        toast.error(<div className="tosterIcon">Please check the errors, in the above form.</div>, {
          containerId: "ToastifyModalContainer",
        });
        return;
      }
    }

    if (!createGroup) {
      var singleUserName = getValues(["userNameInp"]);
      var singleUserEmail = getValues(["userEmailInp"]);
    }

    setIsLoading(true);
    const tabPer = [];
    dropDownValues.tabViewSettings
      ?.filter((i) => i !== "all")
      ?.forEach((val) => {
        let obj = {
          screenName: ddlInfo["tabViewSetting"].filter((i) => i.key === val)[0]
            ?.value,
          tool: ddlInfo["tool"].filter(
            (i) => i.key === dropDownValues.tool?.[0]
          )[0]?.value,
          isRead: dropDownValues.access?.includes("1"),
          isWrite: dropDownValues.access?.includes("2"),
          isAllAccess: dropDownValues.access?.length > 1,
        };
        tabPer.push(obj);
      });
    let userDataToSave = {
      RoleId:
        props.userRole === "SuperAdmin"
          ? 1
          : props.userRole === "Admin"
            ? 2
            : 3,
      GroupId: props.edit ? props.createType === "Group" ? props.GroupId : "" : gp ? v4() : "",
      SuperVisorRoleId: user.superVisorRoleId ?? 1,
      // SupervisorName: user.username,
      SupervisorEmail: user.supervisorEmail,
      UserRole: props.userRole,
      SuperVisorRole: user.superVisorRole,
      SupervisorFirstName: user.supervisorFirstName,
      SupervisorLastName: user.supervisorLastName,
      GroupName: createGroup ? gp : "",
      CreatedBy: user.email,
      param: (createGroup) ? "" : "User",
      Users: true
        ? userData([], true)
        : [
            {
              UserName: singleUserName[0] ?? singleUserName,
              UserEmail: singleUserEmail[0] ?? singleUserEmail,
              UserFirstName: singleUserName[0] ?? singleUserName,
              UserLastName: "",
              UserRole: props.userRole,
              accessId: dropDownValues.access[0]
            },
          ],
      Permission: {
        tools: {
          isFgSlob: dropDownValues.tool?.includes("1") || (!dropDownValues.tool?.includes("2") && !dropDownValues.tool?.includes("3")) ? true : false,
          isInflow: dropDownValues.tool?.includes("2") ? true : false,
          isPom: dropDownValues.tool?.includes("3") ? true : false,
          isAllPageSetting: false,
        },
        filterSettings: {
          businessGroup: dropDownValues.businessGroup,
          businessGroupAllStatus:
            dropDownValues.businessGroup?.length ===
            ddlInfo["businessGroup"]?.length,
          Country: dropDownValues.country,
          countryAllStatus:
            dropDownValues.country?.length === ddlInfo["country"]?.length,
          mco: dropDownValues.mco,
          mcoStatus: dropDownValues.mco?.length === ddlInfo["mco"]?.length,
          brand: dropDownValues.brand,
          brandStatus:
            dropDownValues.brand?.length === ddlInfo["brand"]?.length,
          category: dropDownValues.category,
          categoryStatus:
            dropDownValues.category?.length === ddlInfo["category"]?.length,
        },
        tabSettings: tabPer,
        myaccess: {
          toolId: dropDownValues.tool?.length > 0 ? dropDownValues.tool[0] : [1],
          bussinessGroupId: dropDownValues.businessGroup ?? [],
          mcoId: dropDownValues.mco ?? [],
          countryId: dropDownValues.country ?? [],
          accessId: dropDownValues.access ?? [],
          tabViewSettingId: dropDownValues.tabViewSettings ?? [],
          categoryId: dropDownValues.category ?? [],
          brandId: dropDownValues.brand ?? [],
        },
      },
    };
    if (props.edit) {
      if (props.createType === "Group"|| true) {
        let maineditedDate = {
          GroupId: userDataToSave.GroupId,
          GroupName: userDataToSave.GroupName,
          CreatedBy: userDataToSave.CreatedBy,
          SuperVisorRoleId:userDataToSave.SuperVisorRoleId,
          SupervisorEmail:userDataToSave.SupervisorEmail,
          SupervisorFirstName:userDataToSave.SupervisorFirstName,
          SupervisorLastName:userDataToSave.SupervisorLastName,
          updateData: []
        }

        let dummy = userData([], true).map((i,index) => ({
          UserRole: i.UserRole, //userDataToSave.UserRole,
          SuperVisorRoleId: userDataToSave.SuperVisorRoleId,
          SupervisorFirstName: userDataToSave.SupervisorFirstName,
          SupervisorLastName: userDataToSave.SupervisorLastName,
          SupervisorEmail: userDataToSave.SupervisorEmail,
          GroupName: userDataToSave.GroupName,
          GroupId: userDataToSave.GroupId,
          UserRoleId: i.UserRole === "SuperAdmin" ? "1" : i.UserRole === "Admin" ? "2" : "3",// userDataToSave.RoleId,
          CreatedBy: userDataToSave.CreatedBy,
          Permission: userDataToSave.Permission,
          InflowPermission: null,
          PomPermission: null,
          UserEmail: i.UserEmail,
          UserFirstName: i.UserFirstName,
          UserLastName: i.UserLastName,
          accessId: i.UserAccess
        })
        )
        maineditedDate.updateData = dummy
        let deletedfinal = [];
        if (deletedUser.length > 0) {
          const uniqueValues = new Set(totalUsersAdded.map(obj => `${obj.email}-${obj.tool[0]}`));
          const uniqueValues1 = new Set(props.data?.map(obj => obj.UserEmail));
          deletedUser?.filter(i => !uniqueValues.has(i.email))
            .filter(i => uniqueValues1.has(i.email))
            .map(i => {
              let deletedfinals = [{ ...i, UserEmail: i.email, UserFirstName: i.user_name, isRemoveFromGroup:"true" }]
              return deletedfinal = [...deletedfinal, ...deletedfinals]
            })
          setDeletedUser([]);
        }
        if(deletedfinal.length>0){
          deletedfinal=deletedfinal.filter((data)=>{
            return !totalUsersAdded.some(item => 
              item.email === data.email && 
              item.tool[0] === data.tool[0]
            );
          })
         
        }
        maineditedDate.updateData = [...maineditedDate.updateData, ...deletedfinal]
        let finalUserList = [...totalUsersAdded,...deletedfinal]
        console.log("data",finalUserList)

        let newPayload = {
          GroupId: maineditedDate.GroupId,
          SuperVisorRoleId: maineditedDate.SuperVisorRoleId,
          SupervisorEmail: maineditedDate.SupervisorEmail,
          SuperVisorRole: maineditedDate.SuperVisorRole,
          SupervisorFirstName: maineditedDate.SupervisorFirstName,
          SupervisorLastName: maineditedDate.SupervisorLastName,
          GroupName: maineditedDate.GroupName,
          CreatedBy: maineditedDate.CreatedBy,
          param:(createGroup) ? "" : "User",
          Users: finalUserList.map((i,index3) => {
            return {
              UserName:i.user_name,
              UserEmail: i.email,
              UserFirstName: i.user_name,
              UserLastName: "",
              PermissionsType: parseInt(i.tool[0]),
              Permissions: JSON.stringify({
                myaccess: {
                  toolId: i.tool?.length > 0 ? i.tool[0] : [1],
                  bussinessGroupId: i.businessGroup ?? [],
                  mcoId: i.mco?? [],
                  countryId: i.country ?? [],
                  accessId: i.user_access ?? [],
                  tabViewSettingId: i.tabViewSetting ?? [],
                  categoryId: i.category ?? [],
                  brandId: i.brand ?? [],
                  ddlInfo: i.ddlInfo
                }
              }),
              isRemoveFromGroup: i.isRemoveFromGroup??false,
              UserRole: i.user_role,
              UserRoleId: i.user_role === "SuperAdmin" ? 1 : i.user_role === "Admin" ? 2 : 3,
              accessId: i.user_access ? i.user_access : "1"
            }
          })
        }
        for (var i = 0; i < maineditedDate.updateData.length; i++) {
          let dummypermissions = maineditedDate.updateData[i].Permission
          if (dummypermissions?.myaccess?.toolId === "2") {
            maineditedDate.updateData[i].InflowPermission = dummypermissions
            maineditedDate.updateData[i].PomPermission = null
            maineditedDate.updateData[i].Permission = null
          }
          if (dummypermissions?.myaccess.toolId === "3") {
            maineditedDate.updateData[i].InflowPermission = null
            maineditedDate.updateData[i].PomPermission = dummypermissions
            maineditedDate.updateData[i].Permission = null
          }
        }
        setSaveLoaderInit(true)
        scroll_to_bottom();
        createGroupUser({
          ...newPayload
        }).then(response => {
          setIsLoading(false);
          setCreateGroup(false);
          ref.current?.close();
          if (response) {
            setSaveLoaderInit(false)
            notify({ type: "success", message: "Updated  successfully" });
            clearErrors(); reset();
            // props.onHide();
           window.location.reload();
            getUserGroups({
              UserEmail: user.email,
              UserRoleId: user.userRole === "SuperAdmin" ? 1 : user.userRole === "Admin" ? 2 : 3,
              searchText: ""
            }).then(response => {
              //setMaindata(response.data)
            //  console.log("updateMyGroupUsers >>", response);
              let listData = []
              response.data.map(u => {
                if (u.GroupName !== "") {
                  listData.push({
                    GroupId: u.GroupId,
                    GroupName: u.GroupName,
                    Permission: u.Permission,
                    UserRole: u.UserRole,
                    CreatedBy: u.CreatedBy
                  })
                }
              });
              let listData_uniq = _.uniqBy(listData, "GroupName");
              return listData_uniq;
            }).then(f => {
              if(props.createType === "Group"){
                props.handle_set_filteredArray_after_updating(f);

              }
            })
          };
        }).catch(() => setSaveLoaderInit(false))
      } else {
        let dummy = userDataToSave.Users.map((i) => ({
          Permission: userDataToSave.Permission,
          InflowPermission: null,
          PomPermission: null,
          Users: userDataToSave.Users,
          UpdateBy: user.email
        })
        )
        dummy[0].Users[0]['UserRole'] = roleUser
        dummy[0].Users[0]['UserRoleId'] = roleUser === "SuperAdmin" ? "1" : roleUser === "Admin" ? "2" : "3"
        let dummypermissions = userDataToSave.Permission
        if (dummypermissions?.myaccess?.toolId === "2") {
          dummy[0].InflowPermission = dummypermissions
          dummy[0].PomPermission = null
          dummy[0].Permission = null
        }
        if (dummypermissions?.myaccess.toolId === "3") {
          dummy[0].InflowPermission = null
          dummy[0].PomPermission = dummypermissions
          dummy[0].Permission = null
        }
        updateGroupUser(dummy[0])
          .then(response => {
            if (response) {
              notify({ type: "success", message: "Updated  successfully" });
              clearErrors(); reset();
              // props.onHide();
            };
            setIsLoading(false);
            setCreateGroup(false);
            ref.current?.close();
           window.location.reload();

          })
      }
    } else {
      setSaveLoaderInit(true)
      scroll_to_bottom();
      createGroupUser(userDataToSave)
        .then((response) => {
          setIsLoading(false);
          setCreateGroup(false);
          ref.current?.close();
          if (response) {
            setSaveLoaderInit(false)
            notify({ type: "success", message: "Submitted successfully" });
            clearErrors(); reset();
            props.onHide();
          window.location.reload();
          };
        // window.location.reload();
        })
        .catch(() => {
          setSaveLoaderInit(false)
          setIsLoading(false);
        });
    }

    setActionOnUser('delete')
  };

  const [newUserInfo, setNewUserInfo] = useState([]);
  
  useEffect(() => {
    if(addUserInfo !== undefined) setNewUserInfo((prev) => [...prev, addUserInfo]);
  }, [addUserInfo]);

  const handleUserAddedOnClick = (user) => {
    console.log("handleUserAddedOnClick",user);
    setActionOnUser('add');

    let selectedUserType = props.userRole;
    if(user.user_role==="SuperAdmin"||selectedUserType === "" || selectedUserType === "SuperAdmin") {
      toast.success(<div className="tosterIcon">SuperAdmin is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    } else if(user.user_role==="Admin" || selectedUserType === "" || selectedUserType === "Admin") {
      toast.success(<div className="tosterIcon">Admin is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    } else {
      toast.success(<div className="tosterIcon">User is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    }
  }

  const filter_unsaved_user_data = () => {
    let newUserInfoListFiltered = newUserInfo.filter(r => r !== undefined);
    let newUserInfoListFiltered_not_deleted = [];
    newUserInfoListFiltered_not_deleted = _.differenceBy(newUserInfoListFiltered , deletedUser, 'email');
  
   let newUserInfoListUnique = _.uniqBy(newUserInfoListFiltered_not_deleted, 'email');
    setNewUserInfo([...newUserInfoListUnique]);
    setNewUserList([...newUserInfoListUnique]);
  }

  useEffect(()=>{
  },[deletedUser])

  

  const getUniqueData =(data)=>{
    const newdata = data.filter(i=>{
      if(i.email!== data[data.length-1].email){
        return true
      }
      else if(i.email === data[data.length-1].email){
        if(i.tool[0] === data[data.length-1].tool[0]){
          if(i.id === data[data.length-1].id){
            return true
          }
          else{
            return false
          }
        }else{
          return true
        }
      }
    })
    return (newdata)
  }
  const onNewUser = (users) => {
    setTotalUsersAdded(getUniqueData(users));
  };

  const HandletoolReset = (o) => {
    // clearErrors();
  }

  useEffect(()=>{
  },[newUserList])

  useEffect(()=>{
  },[totalUsersAdded])

  const handleNewUserAddEdit = () => {
    let getIndex = totalUsersAdded?.map((i)=>{
      if(i?.tool[0] === dropDownValues.tool[0]){
        return _.indexOf(totalUsersAdded,i)
      }
    }).filter(i=>i!==undefined)

    let dummyuserList = getIndex.length?[...totalUsersAdded.slice(0,getIndex[0]),...totalUsersAdded.slice(getIndex[0]+1)]:[...totalUsersAdded]

    dummyuserList=[...dummyuserList,{      
      user_name: getValues(["userNameInp"])[0],
      email: getValues(["userEmailInp"])[0],
      user_access: "2",
      user_role: "Admin",
      tool: dropDownValues.tool,
      businessGroup: dropDownValues.businessGroup,
      brand: dropDownValues.brand,
      category: dropDownValues.category,
      country: dropDownValues.country,
      mco: dropDownValues.mco,
      tabViewSetting: dropDownValues.tabViewSettings,
      ddlInfo: JSON.stringify(ddlInfo),
    }]

    setTotalUsersAdded(dummyuserList)

    toast.success(<div className="tosterIcon">User Updated.</div>, {
      containerId: "ToastifyModalContainer",
    });
  }

  const userData = (data, isSave) => {
    return isSave
      ? totalUsersAdded?.map((val) => {
        if(document.querySelector(".table-design-user-modal") !== null) document.querySelector(".table-design-user-modal").scrollLeft = 0;
        if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 0;
        return {
          ...{
            UserName: val.user_name[0] ?? val.user_name, UserEmail: val.email, UserFirstName: val.user_name ?? val.user_name,
            UserLastName: "",
            UserAccess: val.user_access,
            PermissionsType:parseInt(val.tool[0]),
            Permissions: JSON.stringify({myaccess:{
              toolId: val.tool?.length > 0 ? val.tool[0] : [1],
              bussinessGroupId: val.businessGroup ?? [],
              mcoId: val.mco ?? [],
              countryId: val.country ?? [],
              accessId: val.user_access ?? [],
              tabViewSettingId: val.tabViewSetting ?? [],
              categoryId: val.category ?? [],
              brandId: val.brand ?? [],
              ddlInfo:val.ddlInfo
            }}),
            UserRole: val.user_role,
            UserRoleId:val.user_role === "SuperAdmin" ? 1 : val.user_role === "Admin" ? 2 : 3,
            accessId: val.user_access ? val.user_access : "1"
          }
        };
      })
      : totalUsersAdded;
  };

  
  useEffect(() => {

    if(actionOnUser === "add") {
        console.log("User Added",actionOnUser)
        if(document.querySelector(".table-design-user-modal") !== null) {
          document.querySelector(".table-design-user-modal").scrollLeft = 0;
          document.querySelector(".table-design-user-modal").scrollTop = 9935666;
        }
        if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 9933333;

        let allRows = document.querySelectorAll(".table-design-user-modal .body .tr");
        if(allRows.length) {
          let lastRow = allRows[allRows.length - 1];
          lastRow.classList.add('highlight_once');
        }

    } else if(actionOnUser === "delete") {
        console.log("User Deleted",actionOnUser)
        if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 9933333;

    }  else if(actionOnUser === null) {
    }  else if(actionOnUser === null) {
     // console.log("User Interaction Nothing",actionOnUser);
      }  else if(actionOnUser === null) {
     // console.log("User Interaction Nothing",actionOnUser);
        if(document.querySelector(".table-design-user-modal") !== null) {
          //overflow_hidden_remove();
          document.querySelector(".table-design-user-modal").scrollLeft = 0;
          document.querySelector(".table-design-user-modal").scrollTop = 0;
        }
    }

  },[document.querySelectorAll(".table-design-user-modal .body .tr").length]);


  useEffect(() => {
    if(actionOnUser === null) {
      if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 0;
    } 
  },[document.querySelectorAll(".modal-content-raised div").length]);


  const scroll_to_bottom = () => {
    if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 9933333;
  }

  const scroll_to_top = () => {
    if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 0;
  }

  const overflow_hidden = () => {
    if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").classList.add("overflow-hidden-imp");
  }
  const overflow_hidden_remove = () => {
    if(document.querySelector(".modal-content-raised") !== null ) {
      document.querySelector(".modal-content-raised").style.overflow = "auto";
      //document.querySelector(".modal-content-raised").classList.remove("overflow-hidden-imp");
    }
  }

  const handleUserAddFormStatus = (q) => {
    setUserAddFormInvalid(q);
  };

  const deleteUser = () => {
    setSelectedForDeletion(true);
  };

  const emptyUserDataError = () => {
    if(!totalUsersAdded.length) {
      toast.error(<div className="tosterIcon">Please add atleast one user{createGroup ? ', in the group' : ''}.</div>, {
        containerId: "ToastifyModalContainer",
      });
    }
  }

  const onError = (errors, e) => {
    console.log("Form Error", errors, e);
    emptyUserDataError();
  }

  const onSubmit = (data) => {
    if (isValid || (totalUsersAdded?.map(i => i.user_role)?.[0] == "SuperAdmin")) {
      processCreate()
    }
  };

  const onValid = () => {
    if (isValid) {
      if (createGroup) {
        if (totalUsersAdded.length > 0) {
          processCreate();
        }
      } else {
        processCreate();
      }
    }
  }

  const checkValidFields =()=>{
    let error =false;
    let formwatch = watch();
    console.log("formwatch",formwatch)
    console.log("errors",errors)
    handleSubmit(onError)
    setUserAddError(true);
    console.log("getValues('a_bg')",getValues('a_bg'))
    if(getValues('a_bg')===""){
      // alert(getValues('a_bg'))
      setError("a_bg",{
        type:"manual",
        message:"Business Group field Is Required"
      })
      error = true
    }
    if(getValues('a_brand')===""){
      setError("a_brand",{
        type:"manual",
        message:"Brand field Is Required"
      })
      error = true
    }
    if(getValues('a_category')===""){
      setError("a_category",{
        type:"manual",
        message:"Category field Is Required"
      })
      error = true
    }
    if(getValues('a_country')===""){
      setError("a_country",{
        type:"manual",
        message:"Country field Is Required"
      })
      error = true
    }
    if(getValues(['a_tabViewSettings'])===""){
      setError("a_tabViewSettings",{
        type:"manual",
        message:"Tab View Settings field Is Required"
      })
      error = true
    }
    if(getValues(['a_mco'])==="" && dropDownValues.tool[0]==="1"){
      setError("a_mco",{
        type:"manual",
        message:"mco field Is Required"
      })
      error = true
    }

    return error
    
  }

  useEffect(() => {
    let obj = {
      data: {
        businessGroup: dropDownValues?.businessGroup ?? [],
        mco: dropDownValues?.mco ?? [],
        country: dropDownValues?.country ?? [],
        access: dropDownValues?.access ?? [],
        tabViewSettings: dropDownValues?.tabViewSettings ?? [],
        brand: dropDownValues?.brand ?? [],
        category: dropDownValues?.category ?? []
      }
    }
    handleMultiSelectChange(obj, true)
  }, [])

  const handleSettingsDDLoading = (o) => {
    setSettingsDDLoading(o);
  }

  const handleMultiSelectChange = (obj, isOnLoad) => {
    let { businessGroup, mco, country, access, tabViewSettings, brand, category } = obj.data;
    if (obj.current == 'businessGroup' || props.edit) {
      if (businessGroup != undefined) {
        if (!businessGroup.length) {
          setValue("a_bg", { shouldValidate: true, shouldDirty: true })
        } else {
          setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true })
        }
      }
      setBg(businessGroup);
    }
    // if ((obj.current == 'mco' || props.edit) && !isOnLoad) {
      if (mco != undefined) {
        if (!mco.length) {
          setValue("a_mco", "", { shouldValidate: true, shouldDirty: true })
        } else {
          setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true })
        }
      }
      setMco(mco)
    // }
    if ((obj.current == 'country' || props.edit) && !isOnLoad) {
      if (country != undefined) {
        if (!country.length) {
          setValue("a_country", "", { shouldValidate: true, shouldDirty: true })
        } else {
          setValue("a_country", "sl", { shouldValidate: true, shouldDirty: true })
        }
      }
      setCountry(country)
    }
    if ((obj.current == 'access' || props.edit) && !isOnLoad) {
      if (access != undefined) {
        if (!access.length) {
          setValue("a_access", "", { shouldValidate: false, shouldDirty: true });
        } else {
          if (access[0] != '') {
            setValue("a_access", "sl", { shouldValidate: true, shouldDirty: true });
          } else {
            setValue("a_access", "", { shouldValidate: true, shouldDirty: true });
          }
        }
      }
      setMyAccessValidation(access)
    }
    if ((!isOnLoad && obj.current == "brand" || props.edit) && tool[0] != 3 && !isOnLoad) {
      if (brand != undefined) {
        if (!brand.length) {
          setValue("a_brand", "", { shouldValidate: true, shouldDirty: true });
        } else {
          if (brand[0] != "") {
            setValue("a_brand", "sl", {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue("a_brand", "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
      setBrand(brand);
    }
    if ((!isOnLoad && obj.current == "category" || props.edit) && !isOnLoad) {
      if (category != undefined) {
        if (!category.length) {
          setValue("a_category", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          if (category[0] != "") {
            setValue("a_category", "sl", {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue("a_category", "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
      setCategory(category);
    }
    if ((obj.current == 'tabViewSettings' || props.edit) && !isOnLoad) {
      if (tabViewSettings != undefined) {
        if (!tabViewSettings.length) {
          setValue("a_tabViewSettings", "", { shouldValidate: true, shouldDirty: true })
        } else {
          setValue("a_tabViewSettings", "sl", { shouldValidate: true, shouldDirty: true })
        }
      }
      setTabViewSettings(tabViewSettings)
    }
  }

  const closeDropDownFunc = () => {
    //setTimeout(() => {
      setCloseDropDown({
        key : Math.random(1000)
      });
    //}, 400);
  }

  const handleModalClose = () => {
    setWarnModalOpen(false);
  }
  const handleConfirmModalClose = () => {
    setWarnModalOpen(false);
    setTypeCreateModalOpen(false);
    setTotalUsersAdded([]);
  }

  const handleGroupNotificationModalClose = () => {
    setOpenGroupNotificationsKey(0);
    setOpenGroupNotifications(false);
  }

  const handleSetOpenGroupNotificationsKeyToZero = () => {
    setOpenGroupNotificationsKey(0);
  }

  return (
    <>
      <div>
        {!props.edit && (
          <button
            type="button" className={`btn-slim  ${!props.disabled && props.createType == "Group"
              ? "btn btn-primary"
              : "btn-published"
              }  ${!props.disabled && props.createType == "User"
                ? "btn btn-secondary"
                : "btn-published"
              }`}
            disabled={props.disabled}
            onClick={() => setTypeCreateModalOpen(true)}
          >
              <img src={PlusIcon} /> Create a {props.createType}
          </button>
        )}
        {props.edit && (
          <div className="item text-sm mb-2 cursor-pointer" onClick={editModelOpen}>
            {props.name}
          </div>
        )}
      </div>

      <Popup
        className="window-modal create-edit-user"
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        open={isTypeCreateModalOpen}
        lockScroll={false}
        modal
        onClose={() => { 
          setNewUserList([]);
          setNewUserInfo([]);
          clearErrors();
          reset();
         // overflow_hidden();
          //setActionOnUser(null);
         }}
        onOpen={() => {
          //overflow_hidden();
          setActionOnUser(null);
          setNewUserList([]);
          setNewUserInfo([]);
          createTypeCheck();
          
          // document.querySelector(".scroll-content").click();
          // document.querySelector(".scroll-content").click();

          setTimeout(() => {
            scroll_to_top();
          }, 400);
          

        }}
      >
        {(close) => (
          <>
            <ToastContainer
              enableMultiContainer
              containerId={"ToastifyModalContainer"}
              position={toast.POSITION.TOP_CENTER}
              autoClose={2000}
            />
             
             {/* {isSetSaveLoaderInit && 
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              } */}

            <div className="popup-header modal-header-raised d-flex align-items-center justify-content-between"
            // onMouseDown={closeDropDownFunc}
            >
              <h5 className="mt-2">
                {" "}
                {user?.userRole !== "User" ? props.edit
                  ? `Edit ${props.name}`
                  : `Create a ${createGroup ? "Group" : "User"}` : `View ${props.name}`}{" "}
              </h5>
              <div>
                  {createGroup && user?.userRole !== "User" && 
                    <button className="btn-slim btn-secondary" style={{marginRight: "48px"}} onClick={()=>{
                      setOpenGroupNotificationsKey(Math.random * 3443);
                      setTimeout(() => {
                        setOpenGroupNotifications(true);
                      }, 300);
                    }} >Manage Group Notifications</button>
                  }
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onMouseDown={() => filter_unsaved_user_data()}
                    onMouseUp={() => newUserList.length ? setWarnModalOpen(true) :  setTypeCreateModalOpen(false)}
                  ></button>
              </div>
            </div>

            <div tabIndex={0} className="content full-screen-modal-content modal-content-raised 3" 
            //style={{ overflow: "hidden" }} 
            >

           
              {/* <div style={{ position: 'relative', zIndex:989}}> */}
                  <div className={`scroll-content`}
                  // onMouseDown={closeDropDownFunc}
                  style={{ marginRight : "15px"}}>
                    <form   >
                      <>
                          <div>
                            {(props.createType == "Group" ||
                              (props.createType == "User" && !props.edit)) && (
                                <div className="row mb-3 row-group-name"
                                >
                                  <div className="col-md-5">
                                    <div className="filterform_bg pt-0">
                                      <div className="ddn width-auto">
                                        <div>
                                          <label>Group Name</label>
                                        </div>
                                        <input
                                          disabled={user?.userRole === "User"}
                                          type="text"
                                          className="text-left w-100"
                                          {...groupNameHandler}
                                        />
                                      </div>
                                      <div className="error-mssg">
                                        {errors.groupNameInp && (
                                          <p key={errors.groupNameInp.message}>{errors.groupNameInp.message}</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="filterform_bg pt-0">
                                      <div className="ddn width-auto">
                                        <div>
                                          <label></label>
                                        </div>
                                        {!createGroup && <p className="note" style={{ display: "inline"}}>(If you specify 'Group name', then you would be creating group)</p>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            {user?.userRole !== "User" && <div className="row mb-3 row-user-settings" style={{ position : 'relative' , zIndex : '433'}} >
                              <div className="col-md-12">
                                <h6 className="title">
                                  {createGroup ? "Group" : "User"} Settings
                                </h6>
                                <Settings
                                  setDropDownValues={(values) => {
                                    setDropDownValues({ ...values });
                                  }}
                                  handleMultiSelectChange={handleMultiSelectChange}
                                  setDdlInfo={setDdlInfo}
                                  errorMssg={errors}
                                  myAccessInfo={dropDownValues}
                                  createType={props.createType}
                                  // onCloseDropDown={closeDropDown}
                                  handleSettingsDDLoading={handleSettingsDDLoading}
                                  submitCount={submitCount}
                                  userAddError={userAddError}
                                  dropDownInModal={true}
                                  isEdit={true}
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {
                                  ...register("a_bg", {
                                    required: {
                                      value: true,
                                      message: "Business Group is required."
                                    },
                                  })
                                  }
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {
                                  ...register("a_mco", {
                                    required: {
                                      value: userAccess?.fgSlobData?.data?.toolId === '1' ? true : false,
                                      message: "MCO is required."
                                    },
                                  })
                                  }
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {
                                  ...register("a_country", {
                                    required: {
                                      value: true,
                                      message: "Country is required."
                                    },
                                  })
                                  }
                                />
                                <input
                                    type="text"
                                    className="dom-hidden"
                                    {...register("a_category", {
                                      required: {
                                        value: true,
                                        message: "Category is required.",
                                      },
                                    })}
                                  />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {...register("a_brand", {
                                    required: {
                                      value:  true,
                                      message: "Brand is required.",
                                    },
                                  })}
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {
                                  ...register("a_access", {
                                    required: {
                                      value: createTypeCheck ? false : true,
                                      message: "Access is required."
                                    },
                                  })
                                  }
                                />
                                <input
                                  type="text"
                                  className="dom-hidden"
                                  {
                                  ...register("a_tabViewSettings", {
                                    required: {
                                      value: true,
                                      message: "Tab View Settings is required."
                                    },
                                  })
                                  }
                                />

                              </div>
                            </div>}
                            {false && (
                              <div className=' filterform_bg'>
                                <Grid item xs={3} >
                                  <label>
                                    Role
                                  </label>
                                  <div className='filterform_bg'>
                                    <select
                                      defaultValue={roleUser}
                                      className="btn-slim w-100"
                                      onChange={(event) => {
                                        setRoleUser(event.target.value)
                                      //  console.log(event.target.value)
                                      }}
                                    >
                                      <option key={"SuperAdmin"}>SuperAdmin</option>
                                      <option key={"Admin"}>Admin</option>
                                      <option key={"User"}>User</option>
                                    </select>
                                  </div>
                                </Grid>
                              </div>
                            )}
                      {/* Adding user fields user name and email id when it is from user  */}
                            {!createGroup && (
                              <div className="row mb-1 row-user-settings row-user-manage" style={{ position : 'relative' , zIndex : '335'}}  >
                                <div className="col-md-12">
                                <div className="filterform_bg ddn-fluid w-100">
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <div className="ddn">
                                          <div><label>User Name</label></div>
                                          <input
                                            disabled={true}
                                            className="bg-black"
                                            type="text"
                                            {
                                            ...register("userNameInp", {
                                              required: {
                                                value: true,
                                                message: "User Name is required."
                                              },
                                              pattern: {
                                                value: regex_letters_only,
                                                message: "User Name is Invalid."
                                              }
                                            })
                                            }
                                          />
                                        </div>
                                        <div className="error-mssg">
                                          {errors.userNameInp && (
                                            <p key={errors.userNameInp.message}>{errors.userNameInp.message}</p>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div className="ddn">
                                          <div><label>Email ID</label></div>
                                          <input
                                            disabled={true}
                                            className="bg-black"
                                            type="email"
                                            {
                                            ...register("userEmailInp", {
                                              required: {
                                                value: true,
                                                message: "Email is required."
                                              },
                                              pattern: {
                                                value: regex_email,
                                                message: "Email is Invalid."
                                              }
                                            })
                                            }
                                          />
                                        </div>
                                        <div className="error-mssg">
                                          {errors.userEmailInp && (
                                            <p key={errors.userEmailInp.message}>{errors.userEmailInp.message}</p>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item>
                                        <div className="ddn">
                                            <div><label>&nbsp;</label></div>
                                            <button type="button" onClick={() => handleNewUserAddEdit()} className={`btn-slim btn-primary pl-2 pr-2`}  >
                                                <span>{createGroup ? 'Add' : 'Update'} User</span>
                                            </button>
                                        </div>
                                    </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )}

                            {true && (
                              <div className={`row mb-5 row-user-settings usg`} style={{ position : 'relative' , zIndex : '332'}} >
                                <div className="col-md-12">
                                  <div className="datatable position-relative">
                                    {isSettingsDDLoading && 
                                      <div className={`tableLoader_overlay`}>
                                        <div className="tableLoader_box">
                                          <div className="tableLoader_box-in">
                                            <Loader />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    <Table
                                      checkValidFields={checkValidFields}
                                      ref={childRef}
                                      setAddUserInfo={setAddUserInfo}
                                      onNewUser={onNewUser}
                                      data={userData}
                                      ddlInfo={ddlInfo}
                                      dropdownvalues={dropDownValues}
                                      setDeletedUser={setDeletedUser}
                                      selectedForDeletion={selectedForDeletion}
                                      createType={createGroup}
                                      setTotalUsersAdded={setTotalUsersAdded}
                                      submitCount={submitCount}
                                      isSubmitted={isSubmitted}
                                      userRole={props.userRole}
                                      actionOnUser={actionOnUser}
                                      edit={props.edit}
                                      onUserAddedOnClick={handleUserAddedOnClick}
                                      key={userTableKey}
                                      setIsupdateUser={setIsupdateUser}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="d-flex justify-content-between align-items-center mt-5">
                            <div>
                              {true && (
                                <button
                                  type="button"
                                  onClick={deleteSelected}
                                  hidden={user?.userRole === "User" ? true : false}
                                  className="btn btn-primary btn-big btn-std-width"
                                  disabled={isSettingsDDLoading}
                                >Delete Selected</button>
                              )}
                            </div>

                                  <button
                                    // disabled={!userRole}
                                    type="button"
                                    className={`btn btn-big-width btn-big btn-primary ${isSetSaveLoaderInit ? 'processing' : ''}`}
                                    disabled={isSettingsDDLoading||isSetSaveLoaderInit}
                                    hidden={user?.userRole === "User" ? true : false}
                                    // onClick={handleSubmit(onSubmit)}
                                    onClick={()=>processCreate()}
                                  >
                                    Save &amp; Continue
                                  </button>
                          </div>
                      </>
                    </form>
                  </div>
            </div>
          </>
        )}
      </Popup>

      <CreatedUsersAction open={isWarnModalOpen} 
      data={newUserList}
      dropdownvalues={dropDownValues}
      setDeletedUser={setDeletedUser}
      edit={props.edit}
      selectedForDeletion={selectedForDeletion}
      createType={createGroup}
      createfile = {"create"}
      setTotalUsersAdded={setTotalUsersAdded}
      createGroupSubmitCount={submitCount}
      onModalClose={handleModalClose}
      onConfirmModalClose={handleConfirmModalClose}
      />

      {groupNotificationsKey !== 0 && 
        <GroupNotifications open={openGroupNotifications} onGroupNotificationModalClose={handleGroupNotificationModalClose} onSetOpenGroupNotificationsKeyToZero={handleSetOpenGroupNotificationsKeyToZero} GroupId={props.GroupId} key={props.GroupId} groupNotificationsKey={groupNotificationsKey}></GroupNotifications>
      }
    </>
  );
};

export default TypeCreate;
