import React from 'react'
import { Checkbox } from '@material-ui/core'

function BiddingPortalNoifications() {
  const biddingNotification = [
		{ label: "Bidding list req. for approval" },
		{ label: "Bidding list rejected by the admin" },
		{ label: "Bidding list edited by the admin" },
		{ label: "Bidding list edited the group Access" },
		{ label: "Bidding list approved by the admin" },
		{ label: "Bidding list edited the user access" },
		{ label: "Bidding list sent to the customer" },
		{ label: "Bidding list edited to the customer" },
		{ label: "Bidding list submitted to the customer" },
		{ label: "Planner accepted the bid" },
		{ label: "Planner rejected the bid" },
	]
  return (
    <>
			<div style={{display: "flex"}}>
				<div className="table_design " style={{ border: "1px solid #595757" }}>
					<div className="table sticky table-two-col data-table-two display nowrap scrollclass cells-width-auto" style={{ height: "400px" }}>
						<div className="header ">
							<div className="tr ">
								<div className="th" >
									<div className="th-in " style={{ width: "380px" }}>Notification</div>
								</div>
								<div className="th">
									<div className="th-in" style={{ width: "150px" }}>Email</div>
								</div>
								<div className="th">
									<div className="th-in" style={{ width: "150px" }}>Bell Icon</div>
								</div>
								<div className="th">
									<div className="th-in" style={{ width: "150px" }}>Admin</div>
								</div>
								<div className="th">
									<div className="th-in" style={{ width: "150px" }}>Super Admin</div>
								</div>
							</div>
						</div>
						{biddingNotification.map((ele, key) => {
							return (
								<div className="tr">
									<div className="td ">
										<div className="td-in " style={{ width: "400px" }}>{ele.label}</div>
									</div>
									<div className="td ">
										<div className="td-in "><Checkbox
											// checked={isChecked(data)}
											// className="list-checkbox"
											style={{padding:"0px"}}
											// onClick={() => onCheckBoxCLick(data)}
										/></div>
									</div>
									<div className="td ">
										<div className="td-in "><Checkbox
											// checked={isChecked(data)}
											// className="list-checkbox"
											style={{padding:"0px"}}
											onClick={(e)=>console.log(e)}
										/></div>
									</div>
									<div className="td ">
										<div className="td-in "><Checkbox
											// checked={isChecked(data)}
											// className="list-checkbox"
											style={{padding:"0px"}}
											onClick={(e)=>console.log(e)}
										/></div>
									</div>
									<div className="td ">
										<div className="td-in "><Checkbox
											// checked={isChecked(data)}
											// className="list-checkbox"
											style={{padding:"0px"}}
											onClick={(e)=>console.log(e)}
										/></div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			<div>
				<button className='btn btn-slim btn-primary '>Save and Close</button>
			</div>
		</>
  )
}

export default BiddingPortalNoifications