import React, { useState, useEffect, useContext } from "react";
import Popup from "reactjs-popup";
import { ToastContainer, toast } from "react-toastify";
import { getNotificationSettings } from "../../../Services/userManagementService";
import AdminNotification from '../myNotifications/common/AdminNotification';
import { UserContext } from "../../Application";

const GroupNotifications = (props) => {
    const [data,setData] = useState(null)
    const user = useContext(UserContext);

    useEffect(() => {
        let payload_obj = {
          GroupId: props.GroupId,
          email: user.email,
          notificationType: "ADMIN_NOTIFICATIONS",
        }
        getNotificationSettings(payload_obj).then(response => setData(response));
      }, []);
  return (
    <Popup
        className="size-lg fluid-height"
        open={props.open}
        closeOnDocumentClick
        nested
        modal
        onClose={() => props.onSetOpenGroupNotificationsKeyToZero()}
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />
          <div className="popup-header modal-header-raised d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Edit Group Notifications</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => props.onGroupNotificationModalClose()}
            ></button>
          </div>

          <div className="content modal-content-raised">
            <div className="scroll-content" >
                <AdminNotification data={data} notificationType={"ADMIN_NOTIFICATIONS"} onGroupNotificationModalClose={props.onGroupNotificationModalClose} groupNotification={true} GroupId={props.GroupId} />
            </div>
          </div>
        </>
      </Popup>
  );
}

export default GroupNotifications;

