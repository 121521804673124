import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import DownloadIcon from "../../Assets/img/download.svg";
import PlusIcon from "../../Assets/img/Plus.svg";
import LeftCaretIcon from "../../Assets/img/left_caret.svg";
import {
  CentralDealManagementDetails,
  downloadCentralDealManagement,
  addPulishStatus,
  setPublishStatus,
  UpdateDealManagementCellData,
  AddToCentralDealBiddingList,
  CentralDealManagementSKUDetails,
  downloadSkuCentralDealManagement
} from "../../Services/CentralDealManagementDetailServices";
import { getBiddingListName } from "../../Services/BiddingPortalService";
import Toast from "../../../src/Common/toast";
import { notify } from "../../../src/Common/toast";
import { COLUMNS } from "./common/Column";
import { PerPageRecordNumber } from "../../../src/utility/constants";
import TableGrid from "../../Views/WasteRiskDetailsTable/TableGrid";
import OpenIcon from "../../Assets/img/open.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Loader from "../../Common/loader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CreateNewListPopup from "../../Common/CreateNewListPopup";
import DisplayListPopup from "../../Common/DisplayListPopup";
import { UserAccessContext, UserContext } from "../Application";
import { setDropdownvaluesGlobal } from "../../utility/helper";
 
var fileSaver = require("file-saver");

export const StatusContext = React.createContext({});
const CentralDealManagementDetailsTable = (props) => {
  const [filterText,setFilterText]=useState('')
  const [filterKey,setFilterKey]=useState('')
  const user = useContext(UserContext);
  const history = useHistory();
const [tabKey, setTabKey] = useState((props?.heading || history.location.state?.dealId) ?"ALL":"unused");
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageInd, setPageInd] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [SortBy, setSortBy] = useState(
    props.dealList?.length > 0 ? "dealTypeId" : "businessGroupId"
  );
  const [SortType, setSortType] = useState("ASC");
  const [listCreatorLoading, setListCreatorLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [toastifyPosition] = useState("top-center");
  const [selection, setSelection] = useState([]);
  const [sortBySelection, setSortBySelection] = useState("Days Remaining");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [publishCount, setPublishCount] = useState(0);
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [status, setStatus] = useState([]);

  //Create list Item
  const [createListItem, setCreateListItem] = useState([]);
  const [createNewListModal, setCreateNewListModal] = useState(false);
  const [displayListModal, setDisplayListModal] = useState(false);
  const [inputName, setInputName] = useState("");
  const [isNewListSaved, setIsNewListSaved] = useState(false);
  const [biddingList, setBiddingList] = useState([]);
  const [biddingGroupIdList, setBiddingGroupIdList] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
   const [adjustPageGridHt,setAdjustPageGridHt] = useState(null);
   const [refObj, setRefObj] = useState([]);
   const [state, setState] = useState("Loading")

   const [hasColUpdated, setHasColUpdated] = useState(false);
   const [selectedCol, setSelectedCol] = useState([]);



   const userAccess = useContext(UserAccessContext)
  const fetchIdRef = React.useRef(0);

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
  };
  const updateCellData = (row, column, value) => {
    const rowId = row.original.id;
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);
    setClickedIndex(null);
    setClickedCell(null);

    UpdateDealManagementCellData({
      data: [
        {
          materialno: row.original.materialno,
          key_mrdr_plant: row.original.key_mrdr_plant,
          countryId: row.original.countryId,
          country: row.original.country,
          expiry_date:
            column.id === "expiry_date"
              ? value
                ? moment(value).format("DD-MM-YYYY")
                : row.original.expiry_date
              : row.original.expiry_date,
          pack_type:
            column.id === "pack_type"
              ? value
                ? value
                : row.original.pack_type
              : row.original.pack_type,
          language_on_pack:
            column.id === "language_on_pack"
              ? value
                ? value
                : row.original.language_on_pack
              : row.original.language_on_pack,
          production_plant_number:
            column.id === "production_plant_number"
              ? value
                ? value
                : row.original.production_plant_number
              : row.original.production_plant_number,
          production_country_name:
            column.id === "production_country_name"
              ? value
                ? value
                : row.original.production_country_name
              : row.original.production_country_name,
          agreed_quantity_in_zun:
            column.id === "agreed_quantity_in_zun"
              ? value
                ? value
                : row.original.agreed_quantity_in_zun
              : row.original.agreed_quantity_in_zun,
          status:
            column.id === "status"
              ? value
                ? value
                : row.original.status
              : row.original.status,
          agreed_price_per_zun_in_eur:
            column.id === "agreed_price_per_zun_in_eur"
              ? value
                ? value
                : row.original.agreed_price_per_zun_in_eur
              : row.original.agreed_price_per_zun_in_eur,
        },
      ],
    })
      .then((response) => {
        if (response.message === "Sucessfully Updated") {
          notify({
            message: "Data edited successfully!",
            type: "success",
          });
        } else {
          notify({
            message: "Something Went Wrong!",
            type: "error",
          });
        }
      }, 100)
      .catch((error) => {
        console.log("error: ", error);
        notify({
          message: "No Response From Server. Please Try Again Later",
          type: "error",
        });
      });
  };
  useEffect(() => {
    refObj?.forEach((key) => {
      if (key[Object.keys(key)[0]].current) {
        if (key[Object.keys(key)[0]].current.state)
          key[Object.keys(key)[0]].current.state.value = "";
        else {
          key[Object.keys(key)[0]].current.value = "";
        }
      }
    });
    setFilterKey('');
    setFilterText('')
  }, [props.dropDownValues]);
  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };
  const filterData = (value, keyColumn) => {
    setFilterKey(keyColumn);
    setFilterText(value);
    getGridData({
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country ,userAccess?.fgSLOBdata?.data?.countryId),//props.dropDownValues?.country ?? [],
      categoryId:setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.fgSLOBdata?.data?.categoryId),// props.dropDownValues?.category ?? [],
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup ,userAccess?.fgSLOBdata?.data?.businessGroupId),//props.dropDownValues?.businessGroup ?? [],
      mcoId:setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.fgSLOBdata?.data?.mcoId),//props.dropDownValues?.mco ?? [],
      brandId:setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.fgSLOBdata?.data?.brandId),//props.dropDownValues?.brandId ?? [],
      page,
      per_page: itemPerPage,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      datefilter: keyColumn === "expiry_date"? value : null,
      columnFilter: keyColumn,
      columnFilterValue: keyColumn === "expiry_date" ? null : value,
      currentTabSelection:tabKey
    });
  };
  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    status,
    filterData
  };

  const addToPublish = (values) => {
    let data = [];
    selection.forEach((val) => {
      data.push({
        materialno: val.materialno,
        status: values,
      });
    });
    addPulishStatus({ data: data })
      .then((response) => {
        setSelection([]);
        getGridData({
          brandId:setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.fgSLOBdata?.data?.brandId), //props.dropDownValues?.brand ?? [],
          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country ,userAccess?.fgSLOBdata?.data?.countryId),//props.dropDownValues?.country ?? [],
          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.fgSLOBdata?.data?.categoryId),//props.dropDownValues?.category ?? [],
          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup ,userAccess?.fgSLOBdata?.data?.businessGroupId),//props.dropDownValues?.businessGroup ?? [],
          dealTypeId:
            props.dealList?.length > 0
              ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
              : props.dropDownValues?.dealType ?? [],
          mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.fgSLOBdata?.data?.mcoId),//props.dropDownValues?.mco ?? [],
          page,
          per_page: itemPerPage,
          sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
        });
        notify({
          message: `Sucessfully Added to Publish List`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish List failed`,
          type: "error",
        });
      });
  };

  const gotoPublish = (values) => {
    if (!publishCount) {
      notify({
        message: `There are no actions in publish list`,
        type: "warn",
      });
      return;
    }
    setPublishStatus()
      .then((response) => {
        getGridData({
          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),//props.dropDownValues?.country ?? [],
          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),// props.dropDownValues?.category ?? [],
          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.businessGroupId),//props.dropDownValues?.businessGroup ?? [],
          mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),//props.dropDownValues?.mco ?? [],
          brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),//props.dropDownValues?.brandId ?? [],
          dealTypeId:
            props.dealList?.length > 0
              ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
              : props.dropDownValues?.dealType ?? [],
          materialNumberId: props.dropDownValues?.materialNumber ?? [],
          plantId: props.dropDownValues?.plant ?? [],
          residualStatusId: props.dropDownValues?.residualStatus ?? [],
          page,
          per_page: itemPerPage,
          sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
        });
        setSelection([]);
        notify({
          message: `Sucessfully Updated`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish status failed`,
          type: "error",
        });
      });
  };

  const getGridData = useCallback((payload) => {
    setState('Loading');
    if(!payload.currentTabSelection){
     payload.currentTabSelection= tabKey?.toLowerCase();
    }
    if (props.heading || history.location.state?.dealId) {
      CentralDealManagementDetails(payload).then((response) => {
        if (response) {
          setGridInfo(response.data);
          setActions(response.actions);
          setTotalCount(response.totalCount);
          setBiddingGroupIdList(response.bidding_group_list);
          setTotalRecordCount(
            response.count ? response.count : response.data?.length
          );
          setPublishCount(response.published_count ?? 0);
          setStatus(response.status);
          setTimeout(() => { setState('Completed'); }, 200)
        }
      }).catch(()=>{setState('Completed');});
    } else {
      CentralDealManagementSKUDetails(payload).then((response) => {
        if (response) {
          setGridInfo(response.data);
          setActions(response.actions);
          setTotalCount(response.totalCount);
          setBiddingGroupIdList(response.bidding_group_list);
          setTotalRecordCount(
            response.count ? response.count : response.data?.length
          );
          setPublishCount(response.published_count ?? 0);
          setStatus(response.status);
          setTimeout(() => { setState('Completed'); }, 200)
        }
      }).catch(()=>{setState('Completed');});
    }
  }, []);

  const onActionClick = (val) => {
    if (selection.length === 0) {
      notify({
        message: `Please select one record!`,
        type: "warn",
      });
      return;
    } else {
      addToPublish(val);
    }
  };

  const downloadData = (payload) => {
    if(!props?.heading && !history.location.state?.dealId){
      payload.currentTabSelection=tabKey;
      downloadSkuCentralDealManagement(payload).then((response) => {
      });
    }
    else{
    downloadCentralDealManagement(payload).then((response) => {
    });}
  };

  const handleDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      notify({
        message: `Downloading the File. Please wait...`,
        type: "info",
      });
      let payload = "dealType,materialno,material_description,ean_zun,business_group,plants_sp_matl_status,plant_code,country,residual_status,mco,category,brand,l12_spf_gmrdr,l13_spfv_gmrdr,no_of_days_stock_pending_polaris,standard_price,min_floor_price_per_zun_for_central_deal_in_eur,tpm_provisions_eur,total_stock_zun,total_stock_eur,wk13_wk26_residual_zun,wk13_wk26_residual_eur,blockedStockZun,blockedStockEur,wk13_wk26_Res_BlockedStockZun,wk13_wk26_Res_BlockedStockEur,batch_code,manufacturing_date,previous_month_deal_type,comment_field,previous_month_comment_field,local_deal_completion_month,previous_month_local_deal_completion_date,local_deal_quantity_in_zun,local_deal_in_eur,central_deal_quantity_in_zun,central_deal_in_eur,remaining_quantity_in_zun,remaining_stock_in_eur,language_on_pack,production_country_name,expiry_date,pack_type,bom"

      // {

        // columnsSelected : columnsVisibilitySelected,
        // selectedAttributes:
        //  "dealType,materialno,material_description,ean_zun,business_group,plants_sp_matl_status,plant_code,country,residual_status,mco,category,brand,l12_spf_gmrdr,l13_spfv_gmrdr,no_of_days_stock_pending_polaris,standard_price,min_floor_price_per_zun_for_central_deal_in_eur,tpm_provisions_eur,total_stock_zun,total_stock_eur,wk13_wk26_residual_zun,wk13_wk26_residual_eur,blockedStockZun,blockedStockEur,wk13_wk26_Res_BlockedStockZun,wk13_wk26_Res_BlockedStockEur,batch_code,manufacturing_date,previous_month_deal_type,comment_field,previous_month_comment_field,local_deal_completion_month,previous_month_local_deal_completion_date,local_deal_quantity_in_zun,local_deal_in_eur,central_deal_quantity_in_zun,central_deal_in_eur,remaining_quantity_in_zun,remaining_stock_in_eur,language_on_pack,production_country_name,expiry_date,pack_type,bom",
        //"dealType,materialno,material_description,plant_code,country,ean_zun,business_group,plants_sp_matl_status,residual_status,mco,category,brand,l12_spf_gmrdr,l13_spfv_gmrdr,no_of_days_stock_pending_polaris,standard_price,min_floor_price_per_zun_for_central_deal_in_eur,tpm_provisions_eur,total_stock_zun,total_stock_eur,wk13_wk26_residual_zun,wk13_wk26_residual_eur,blockedStockZun,blockedStockEur,wk13_wk26_Res_BlockedStockZun,wk13_wk26_Res_BlockedStockEur,batch_code,manufacturing_date,previous_month_deal_type,previous_month_comment_field,previous_month_local_deal_completion_date,local_deal_quantity_in_zun,local_deal_in_eur,central_deal_in_eur,remaining_quantity_in_zun,remaining_stock_in_eur,pack_type,bom,comment_field,local_deal_completion_month,central_deal_quantity_in_zun,language_on_pack,production_country_name,expiry_date"
      // }
      if (hasColUpdated) {
  
        const updatedSelectedCol = selectedCol.filter(item => item?.trim() != "add_customer" && item?.trim() != "view_history")
        console.log(selectedCol)
        const editableArray = ["dealTypeId", "comment_field", "local_deal_completion_month", "central_deal_quantity_in_zun", "language_on_pack", "production_country_name", "expiry_date"]
        let array1 = [];
        let array2 = [];
        updatedSelectedCol.map(item => {
          if (editableArray.includes(item)) {
            array2.push(item)
          } else {
            array1.push(item)
          }
        })
  
        let combinedArray = [...array1, ...array2]
        let FixedColumns = ["dealTypeId", "materialno", "material_description", "plant_code", "country"]
        combinedArray = Array.from(new Set(FixedColumns.concat(combinedArray)));
  
        const dealtypeIndex = combinedArray.findIndex(i => i == "dealTypeId")
        if (dealtypeIndex != -1) {
          combinedArray[dealtypeIndex] = "dealType";
        }
        console.log("alllll", combinedArray)
  
        payload = combinedArray.toString()
      }
      
      downloadData((!props?.heading && !history.location.state?.dealId)?{
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId) ,//props.dropDownValues?.brand ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.country ?? [],
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.category ?? [],
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.businessGroupId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.businessGroup ?? [],
        dealTypeId:
          props.dealList?.length > 0
            ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
            : props.dropDownValues?.dealType ?? [],
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.mco ?? [],
        filename: "waste_risk_details",
        sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
        selectedAttributes:payload,
        currentTabSelection:tabKey
      }:{
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId) ,//props.dropDownValues?.brand ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.country ?? [],
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.category ?? [],
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.businessGroupId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.businessGroup ?? [],
        dealTypeId:
          props.dealList?.length > 0
            ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
            : props.dropDownValues?.dealType ?? [],
        materialNumberId: props.dropDownValues?.materialNumber ?? [],
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId) ,//props.dropDownValues?.brand ?? [],props.dropDownValues?.mco ?? [],
        plantId: props.dropDownValues?.plant ?? [],
        residualStatusId: props.dropDownValues?.residualStatus ?? [],
        filename: "waste_risk_details",
        sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
        complianceSorting: SortType,
        selectedAttributes:payload,
        searchText: searchText ?? "",
        responseType: "arraybuffer",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dealList,
    props.dropDownValues?.brand,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.dealType,
    props.dropDownValues?.materialNumber,
    props.dropDownValues?.mco,
    props.dropDownValues?.plant,
    props.dropDownValues?.residualStatus,
    hasColUpdated,
  ]);

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };
  const handleSearch = (val) => {
  };
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  useEffect(() => {
    const payLoadData={
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country ,userAccess?.fgSLOBdata?.data?.countryId),//props.dropDownValues?.country ?? [],
      categoryId:setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.fgSLOBdata?.data?.categoryId),// props.dropDownValues?.category ?? [],
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup ,userAccess?.fgSLOBdata?.data?.businessGroupId),//props.dropDownValues?.businessGroup ?? [],
      mcoId:setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.fgSLOBdata?.data?.mcoId),//props.dropDownValues?.mco ?? [],
      brandId:setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.fgSLOBdata?.data?.brandId),//props.dropDownValues?.brandId ?? [],
      dealTypeId: history.location.state?.dealId
        ? [history.location.state?.dealId ?? 0]
        : props.dropDownValues?.dealType ?? [],
      page,
      per_page: itemPerPage,
      sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
      complianceSorting: SortType,
      currentTabSelection: tabKey?.toLowerCase(),
      searchText: searchText ?? "",
    };
    if(props.dropDownValues)
    getGridData(filterKey
    ? {
        ...payLoadData,
        datefilter: filterKey === "expiry_date" ? filterText : null,
        columnFilter: filterKey,
        columnFilterValue: filterKey === "expiry_date" ? null : filterText,
      }
    : payLoadData);
  }, [
    SortBy,
    SortType,
    getGridData,
    itemPerPage,
    page,
    props.dropDownValues,
  ]);
  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(20);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);
  const handleSelectAllCheckBox = (e) => {
    setIsSelectAll(e.target.checked);
    setIsCheck(gridInfo.map((item) => item.id));

    if (isSelectAll) {
      setIsCheck([]);
      setSelection(gridInfo);
    } else {
      setSelection([]);
    }
  };
  const handleCheckBox = (e, cellInfo) => {
    const { value, checked } = e.target;

    setIsCheck([...isCheck, value]);
    if (!checked) {
      setSelection(selection.filter((item) => item.id !== value));
      setIsCheck(isCheck.filter((item) => item !== value));
    } else {
      setSelection([...selection, cellInfo.row.original]);
    }
  };

  //Create New List
  const toggleCreateNewListModal = () => {
    console.log("Hiiii");
    setIsNewListSaved(false);
    if (createListItem.length > 0) {
      setCreateNewListModal(!createNewListModal);
    }
  };

  const toggleWarnMessageModal = () => {
    notify({
      message: `Please select sku's to create a list`,
      type: "warn",
    });
  };
  const toggleDisplayListModal = (listName) => {
    setSelectedList(listName);
    setDisplayListModal(!displayListModal);
  };

  const BiddingListName = useCallback((payload) => {
    getBiddingListName(payload, true).then((response) => {
      setBiddingList(response.bidding_list);
      props.setBiddingList(response);
    });
  }, []);

  const handleCreateNewList = () => {
    setListCreatorLoading(true);
    const materialno = createListItem.map((item) => item.original.materialno);
    // const wasteRiskDetailsID= createListItem.map((item) => item.original.id);
    const wasteRiskDetailsID= createListItem.map((item) => item.original.id);
    const key_mrdr_plant = createListItem.map(
      (item) => item.original.key_mrdr_plant
    );
    const payload = {
      biddingListName: inputName,
      vendorName: user.firstName,
      vendorEmail: user.email,
      customerName: "",
      customerEmail: "",
      materialno,
      wasteRiskDetailsID,
      key_mrdr_plant,
      biddingGroupId: props.dealList[0]?.id
        ? props.dealList[0]?.id
        : history.location.state?.dealId
        ? history.location.state?.dealId
        : 1,
      BiddingStatus: "",
    };

    AddToCentralDealBiddingList(payload)
      .then((response) => {
        setListCreatorLoading(false);

        

        if (response.error) {
          notify({
            message: response.message,
            type: "error",
          });
          return;
        }
        if (response.message) {
          ///let btn_create_new_list = document.querySelector(".btn-create-new-list-success-close");
          
          setTimeout(() => {
            document.querySelector(".btn-create-new-list-success-close").click();

            let bidding_bar2 = document.querySelector(".content-minimized-overlay");
            let bidding_bar3 = document.querySelector(".content-maximized");
          
            if(bidding_bar2 !== null) {
              bidding_bar2.classList.add("highlight");
              bidding_bar3.classList.add("highlight");
            }

            setTimeout(() => {
              if(bidding_bar2 !== null) {
                bidding_bar2.classList.remove("highlight");
                bidding_bar3.classList.remove("highlight");
              }
              
            }, 3600);
          }, 900);

          

          setIsNewListSaved(true);
          BiddingListName({ vendorEmail: user.email });
        }
        props.onNewListCreation(inputName);
      })
      .catch((response) => {
        setListCreatorLoading(false);
      });
  };

  const OpenRecentCreatedList = () => {
    //UserBiddingListName();
    setSelectedList(inputName);
    setCreateNewListModal(false);
    setDisplayListModal(true);
    setIsNewListSaved(false);
  };

  const updateOfferPrice = (payload) => {
    setDisplayListModal(!displayListModal);
    notify({
      message: "Data updated successfully!",
      type: "success",
    });
  };

  useEffect(() => {
    if(user.email)
    BiddingListName({ vendorEmail: user.email });
  }, [BiddingListName, user.email]);

  return (
    <StatusContext.Provider value={status}>
      <div className="main-body">
        <Toast position={toastifyPosition} />
        <div className="">
          {createNewListModal && (
            <CreateNewListPopup
              {...{
                inputName,
                setInputName,
                handleCreateNewList,
                isNewListSaved,
                OpenRecentCreatedList,
                show: createNewListModal,
                onHide: toggleCreateNewListModal,
                from: "dealmanagement",
                isLoading: listCreatorLoading,
              }}
            />
          )}
          {displayListModal && (
            <DisplayListPopup
              {...{
                selectedList,
                updateOfferPrice,
                //handleEditList,
                show: displayListModal,
                onHide: toggleDisplayListModal,
                from: "dealmanagement",
                clickedFrom: "dealmanagement",
              }}
            />
          )}

          <div className="d-flex row justify-content-between mt-3 mb-2">
            <div className="col-md-9">
              <div className="mb-3">
                <div>
                  {props?.heading !== undefined && (
                    <button
                      type="button"
                      className="btn-slim btn-published bg-red"
                      onClick={() => {
                        history.push("/dealcalendar");
                      }}
                    >
                      <img src={LeftCaretIcon} alt="" /> Go to Deal Calendar
                    </button>
                  )}
                  {(tabKey === "blank" || tabKey === "ALL" || tabKey === "unused") && (
                    <button
                      type="button"
                      className={`btn-slim btn-published  ${
                        props?.heading !== undefined ? "mx-4" : ""
                      } ${selection.length ? "bg-red" : ""}`}
                      onClick={
                        createListItem.length > 0
                          ? toggleCreateNewListModal
                          : toggleWarnMessageModal
                      }
                    >
                      <img src={PlusIcon} alt="" /> Create a List for Bidding
                      Portal
                    </button>
                  )}
                </div>
                {(props.dealList?.length > 0 || props.heading) && (
                  <div className="breadcrumb">
                    <p>
                      <span>Central Deal Calendar</span> &#62;{" "}
                      <span>{props.dealList?.[0]?.value || props.heading}</span>{" "}
                      &#62; <span>Central Deal Management</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="table-actions p-0 page-el">
                <button
                  type="button"
                  className="btn-slim btn-download "
                  onClick={handleDownload}
                  style={{backgroundColor:"yellow"}}
                >
                  <img src={DownloadIcon} alt="" />
                  Download
                </button>
              </div>
            </div>
          </div>
          {/* <!--Action buttons End--> */}

          <div className="">
            <div
              className={`body-container  body-container-table-with-pagination central_deal_management_page ${
                props?.heading === undefined ? "no-heading" : 0
              }`}
            >
              {/* <!--Search Table Data Start--> */}
              <div className="d-block nav-tabs-wrap ">
                <div className="pull-left">
                  {props?.heading !== undefined && (
                    <div className="table-filters mr-3">
                      <div className="text-white">
                        <h5>{props?.heading}</h5>
                      </div>
                    </div>
                  )}
                </div>

                {!props?.heading && !history.location.state?.dealId && (
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => {
                      setTabKey(k);
                      setGridInfo(["Loading"]);
                      setTimeout(() => {
                        const payLoadData = {
                          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),//props.dropDownValues?.country ?? [],
                          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),// props.dropDownValues?.category ?? [],
                          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.businessGroupId),//props.dropDownValues?.businessGroup ?? [],
                          mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),//props.dropDownValues?.mco ?? [],
                          brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),//props.dropDownValues?.brandId ?? [],

                          dealTypeId: history.location.state?.dealId
                            ? [history.location.state?.dealId ?? 0]
                            : props.dropDownValues?.dealType ?? [],
                          page,
                          per_page: itemPerPage,
                          sortby:
                            props.dealList?.length > 0 ? "dealTypeId" : SortBy,
                          complianceSorting: SortType,
                          currentTabSelection: k?.toLowerCase(),
                          searchText: searchText ?? "",
                        };
                        if (props.dropDownValues)
                          getGridData(
                            filterKey
                              ? {
                                  ...payLoadData,
                                  datefilter:
                                    filterKey === "expiry_date"
                                      ? filterText
                                      : null,
                                  columnFilter: filterKey,
                                  columnFilterValue:
                                    filterKey === "expiry_date"
                                      ? null
                                      : filterText,
                                }
                              : payLoadData
                          );
                        return;
                      }, 50);
                    }}
                    className="mb-3 "
                  >
                    <Tab eventKey="unused" title="Yet to sent">
                      {tabKey === "unused" && (
                        <div>
                          {gridInfo[0] != "Loading" && (
                            <>
                              {gridInfo && (
                                <TableGrid
                                  state={state}
                                  pageInd={pageInd}
                                  setPageInd={setPageInd}
                                  columns={COLUMNS}
                                  data={gridInfo}
                                  SortBy={SortBy}
                                  SortType={SortType}
                                  setSortBy={setSortBy}
                                  setSortType={setSortType}
                                  fetchData={fetchData}
                                  pageCount={pageCount}
                                  totalRecordCount={totalRecordCount}
                                  pageSizeNo={itemPerPage}
                                  handleItemPerPage={handleItemPerPage}
                                  handlePaginationReset={handlePaginationReset}
                                  handleSearch={handleSearch}
                                  setSelection={setSelection}
                                  selection={selection}
                                  toggleSelection={toggleSelection}
                                  actions={actions}
                                  onActionClick={onActionClick}
                                  searchText={searchText}
                                  setSearchText={setSearchText}
                                  getGridData={getGridData}
                                  {...editCellProps}
                                  props={props}
                                  isSelectAll={isSelectAll}
                                  isNewListSaved={isNewListSaved}
                                  setIsSelectAll={setIsSelectAll}
                                  toggleExpandCollapse={
                                    props.toggleExpandCollapse
                                  }
                                  isExpand={props.isExpand}
                                  handleCheckBox={handleCheckBox}
                                  handleSelectAllCheckBox={
                                    handleSelectAllCheckBox
                                  }
                                  isCheck={isCheck}
                                  isNotRequiredTableCoumnSort={true}
                                  isNoActions
                                  setCreateListItem={setCreateListItem}
                                  totalCount={totalCount}
                                  adjustPageGridHt={adjustPageGridHt}
                                  page={"deal"}
                                  heading={props?.heading}
                                  isBarOnHover
                                  refObj={refObj}
                                  setRefObj={setRefObj}
                                  tabKey={tabKey}
                                  setHasColUpdated={setHasColUpdated}
                                  setSelectedCol={setSelectedCol}

                                />
                              )}
                            </>
                          )}

                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="reserved" title="In Progress">
                      {/* {key === 'Layer_3' && <LayerThreeTable />} */}
                      {tabKey === "reserved" && (
                        <div>
                          {gridInfo[0] != "Loading" && (
                            <>
                              {gridInfo && (
                                <TableGrid
                                  state={state}
                                  pageInd={pageInd}
                                  setPageInd={setPageInd}
                                  columns={COLUMNS}
                                  data={gridInfo}
                                  SortBy={SortBy}
                                  SortType={SortType}
                                  setSortBy={setSortBy}
                                  setSortType={setSortType}
                                  fetchData={fetchData}
                                  pageCount={pageCount}
                                  totalRecordCount={totalRecordCount}
                                  pageSizeNo={itemPerPage}
                                  handleItemPerPage={handleItemPerPage}
                                  handlePaginationReset={handlePaginationReset}
                                  handleSearch={handleSearch}
                                  setSelection={setSelection}
                                  selection={selection}
                                  toggleSelection={toggleSelection}
                                  actions={actions}
                                  onActionClick={onActionClick}
                                  searchText={searchText}
                                  setSearchText={setSearchText}
                                  getGridData={getGridData}
                                  {...editCellProps}
                                  props={props}
                                  isSelectAll={isSelectAll}
                                  setIsSelectAll={setIsSelectAll}
                                  toggleExpandCollapse={
                                    props.toggleExpandCollapse
                                  }
                                  isExpand={props.isExpand}
                                  handleCheckBox={handleCheckBox}
                                  handleSelectAllCheckBox={
                                    handleSelectAllCheckBox
                                  }
                                  isCheck={isCheck}
                                  isNotRequiredTableCoumnSort={true}
                                  isNoActions
                                  totalCount={totalCount}
                                  adjustPageGridHt={adjustPageGridHt}
                                  page={"deal"}
                                  isBarOnHover
                                  refObj={refObj}
                                  setRefObj={setRefObj}
                                  tabKey={tabKey}
                                  setHasColUpdated={setHasColUpdated}
                                  setSelectedCol={setSelectedCol}

                                />
                              )}
                            </>
                          )}
                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="accepted" title="Accepted">
                      {tabKey === "accepted" && (
                        <div>
                          {gridInfo[0] != "Loading" && (
                            <>
                              {gridInfo && (
                                <TableGrid
                                  pageInd={pageInd}
                                  setPageInd={setPageInd}
                                  state={state}
                                  columns={COLUMNS}
                                  data={gridInfo}
                                  SortBy={SortBy}
                                  SortType={SortType}
                                  setSortBy={setSortBy}
                                  setSortType={setSortType}
                                  fetchData={fetchData}
                                  pageCount={pageCount}
                                  totalRecordCount={totalRecordCount}
                                  pageSizeNo={itemPerPage}
                                  handleItemPerPage={handleItemPerPage}
                                  handlePaginationReset={handlePaginationReset}
                                  handleSearch={handleSearch}
                                  setSelection={setSelection}
                                  selection={selection}
                                  toggleSelection={toggleSelection}
                                  actions={actions}
                                  onActionClick={onActionClick}
                                  searchText={searchText}
                                  setSearchText={setSearchText}
                                  getGridData={getGridData}
                                  {...editCellProps}
                                  props={props}
                                  isSelectAll={isSelectAll}
                                  setIsSelectAll={setIsSelectAll}
                                  toggleExpandCollapse={
                                    props.toggleExpandCollapse
                                  }
                                  isExpand={props.isExpand}
                                  handleCheckBox={handleCheckBox}
                                  handleSelectAllCheckBox={
                                    handleSelectAllCheckBox
                                  }
                                  isNotRequiredTableCoumnSort={true}
                                  isCheck={isCheck}
                                  isNoActions
                                  totalCount={totalCount}
                                  adjustPageGridHt={adjustPageGridHt}
                                  page={"deal"}
                                  isBarOnHover
                                  refObj={refObj}
                                  setRefObj={setRefObj}
                                  tabKey={tabKey}
                                  setHasColUpdated={setHasColUpdated}
                                  setSelectedCol={setSelectedCol}

                                />
                              )}
                            </>
                          )}
                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                )}
                {tabKey === "ALL" && (
                  <div>
                    {gridInfo[0] != "Loading" && (
                      <>
                        {gridInfo && (
                          <TableGrid
                            pageInd={pageInd}
                            setPageInd={setPageInd}
                            columns={COLUMNS}
                            state={state}
                            data={gridInfo}
                            SortBy={SortBy}
                            SortType={SortType}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                            fetchData={fetchData}
                            pageCount={pageCount}
                            totalRecordCount={totalRecordCount}
                            pageSizeNo={itemPerPage}
                            handleItemPerPage={handleItemPerPage}
                            handlePaginationReset={handlePaginationReset}
                            handleSearch={handleSearch}
                            setSelection={setSelection}
                            selection={selection}
                            toggleSelection={toggleSelection}
                            actions={actions}
                            onActionClick={onActionClick}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            getGridData={getGridData}
                            {...editCellProps}
                            props={props}
                            isSelectAll={isSelectAll}
                            isNewListSaved={isNewListSaved}
                            setIsSelectAll={setIsSelectAll}
                            toggleExpandCollapse={props.toggleExpandCollapse}
                            isExpand={props.isExpand}
                            handleCheckBox={handleCheckBox}
                            handleSelectAllCheckBox={handleSelectAllCheckBox}
                            isCheck={isCheck}
                            isNotRequiredTableCoumnSort={true}
                            isNoActions
                            setCreateListItem={setCreateListItem}
                            totalCount={totalCount}
                            adjustPageGridHt={adjustPageGridHt}
                            page={"deal"}
                            heading={props?.heading}
                            refObj={refObj}
                            setRefObj={setRefObj}
                            tabKey={tabKey}
                            setHasColUpdated={setHasColUpdated}
                            setSelectedCol={setSelectedCol}

                            isBarOnHover
                          />
                        )}
                      </>
                    )}

                    {gridInfo[0] === "Loading" && <Loader />}
                  </div>
                )}
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </StatusContext.Provider>
  );
};
export default CentralDealManagementDetailsTable;
