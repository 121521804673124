import { setDropdownvaluesGlobal } from "../../utility/helper";

export const LEGEND_COLOR = "#FFFFFF";
export const LABEL_COLOR = "#CCCCCC";

export function chunkString(str, maxWidth) {
  const sections = [];
  if (!str) return sections;
  const words = str.split(" ");
  let builder = "";

  for (const word of words) {
    if (word.length > maxWidth) {
      sections.push(builder.trim());
      builder = "";
      sections.push(word.trim());
      continue;
    }

    let temp = `${builder} ${word}`;
    if (temp.length > maxWidth) {
      sections.push(builder.trim());
      builder = word;
      continue;
    }

    builder = temp;
  }
  sections.push(builder.trim());

  return sections;
}

export const payloadWithFilters = (filters,userAccess) => {
  return {
    brandId: setDropdownvaluesGlobal(filters?.brand, userAccess?.fgSLOBdata?.data?.brandId),
    countryId: setDropdownvaluesGlobal(filters?.country, userAccess?.fgSLOBdata?.data?.countryId),
    categoryId: setDropdownvaluesGlobal(filters?.category, userAccess?.fgSLOBdata?.data?.categoryId),
    businessGroupId: setDropdownvaluesGlobal(filters?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
    mcoId: setDropdownvaluesGlobal(filters?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
    dealTypeId: filters?.dealType ?? [],
    materialNumberId: filters?.materialNumber ?? [],
    plantId: filters?.plant ?? [],
    residualStatusId: filters?.residualStatus ?? [],
  };
};

export const COLUMNS = [
  {
    Header: " ",
    accessor: "mco_no_group",
    columns: [{ Header: "MCO", accessor: "mco", show: true }],
  },
  {
    Header: "BW Residual EUR",
    accessor: "bw_residual_eur",
    columns: [
      {
        Header: "Assigned to Local Deal",
        accessor: "assigned_to_local_deal",
        show: true,
        Cell: (cellObj) => {
          const { assigned_to_local_deal } = cellObj.cell.row.original;
          let value = assigned_to_local_deal ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Natural Sell Off",
        accessor: "natural_sell_off",
        show: true,
        Cell: (cellObj) => {
          const { natural_sell_off } = cellObj.cell.row.original;
          let value = natural_sell_off ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Donate",
        accessor: "donate",
        show: true,
        Cell: (cellObj) => {
          const { donate } = cellObj.cell.row.original;
          let value = donate ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Scrap",
        accessor: "destroy",
        show: true,
        Cell: (cellObj) => {
          const { destroy } = cellObj.cell.row.original;
          let value = destroy ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Eligible for Central Deal",
        accessor: "eligible_for_central_deal",
        show: true,
        Cell: (cellObj) => {
          const { eligible_for_central_deal } = cellObj.cell.row.original;
          let value = eligible_for_central_deal ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Assigned to Central Deal TB",
        accessor: "assigned_to_central_deal_top_brands",
        show: true,
        Cell: (cellObj) => {
          const { assigned_to_central_deal_top_brands } =
            cellObj.cell.row.original;
          let value = assigned_to_central_deal_top_brands ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Central Deal Completed",
        accessor: "central_deal_completed",
        show: true,
        Cell: (cellObj) => {
          const { central_deal_completed } = cellObj.cell.row.original;
          let value = central_deal_completed ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Central Deal Rejected",
        accessor: "central_deal_rejected",
        show: true,
        Cell: (cellObj) => {
          const { central_deal_rejected } = cellObj.cell.row.original;
          let value = central_deal_rejected ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
      {
        Header: "Blank",
        accessor: "blank",
        show: true,
        Cell: (cellObj) => {
          const { blank } = cellObj.cell.row.original;
          let value = blank ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
    ],
  },
  {
    Header: "13wk + 26wk Residual EUR",
    accessor: "tpm_provisions_eur",
    columns: [
      {
        Header: "Total",
        accessor: "total_tpm_provisions_eur",
        show: true,
        Cell: (cellObj) => {
          const { total_tpm_provisions_eur } = cellObj.cell.row.original;
          let value = total_tpm_provisions_eur ?? 0;
          return (
            <div>
              {value / 1000 > 1
                ? parseInt(value / 1000)
                : (value / 1000).toFixed(2) > 0
                  ? (value / 1000).toFixed(2)
                  : Math.floor(value / 1000)}
            </div>
          );
        },
      },
    ],
  },
];

export const stackBarChartOptions = {
  plugins: {
    legend: {
      display: true,
      align: "start",
      labels: {
        pointStyle: "circle",
        usePointStyle: true,
        padding: 20,
        color: LEGEND_COLOR,
        font: {
          size: 10,
          family: "unilevershilling",
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  aspectRatio: 1.1,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: LABEL_COLOR,
        font: {
          size: 10,
          family: "unilevershilling",
        },
        callback(value) {
          return chunkString(this.getLabelForValue(value), 10);
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(255, 255, 255, 0.1)",
      },
      border: {
        display: false,
      },
      ticks: {
        color: LABEL_COLOR,
        font: {
          family: "unilevershilling",
        },
      },
    },
  },
};
export const stackBarChartOptions2 = {
  plugins: {
    legend: {
      display: true,
      align: "start",
      labels: {
        pointStyle: "circle",
        usePointStyle: true,
        padding: 20,
        color: LEGEND_COLOR,
        font: {
          size: 10,
          family: "unilevershilling",
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  responsive: true,
  aspectRatio: 1.1,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0, // Set the maximum rotation angle to 0 degrees (horizontal)
        minRotation: 0,
        maxTicksLimit: 15,
        color: LABEL_COLOR,
        font: {
          size: 10,
          family: "unilevershilling",
        },
        callback(value) {
          return chunkString(this.getLabelForValue(value), 10);
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(255, 255, 255, 0.1)",
      },
      border: {
        display: false,
      },
      ticks: {
        color: LABEL_COLOR,
        font: {
          family: "unilevershilling",
        },
      },
    },
  },
};
