import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UnileverLogoIcon from "../../Assets/img/unileverlogo.svg";
import RiskVisibilityImage from "../../Assets/img/Risk_visibility.svg";
import ResidualStatusImage from "../../Assets/img/residual_status.svg";
import DealCalendarImage from "../../Assets/img/deal_calendar.svg";
import DealManagementImage from "../../Assets/img/deal_management.svg";
import BenefitTrackerImage from "../../Assets/img/benefit_tracker.svg";
import AppDrawerIcon from "../../Assets/img/icon-app-drawer.svg";
import { UserCheckContext } from "../../App";
// import { getUserPermissionDetails } from "../../Services/userManagementService";
import { UserAccessContext, UserContext } from "../../Components/Application";

const SideMenu = () => {
  const isInternalUser = useContext(UserCheckContext)

  // con = useContext(UserCheckContext)
  // const [userAccess,setUserAccess]= useState({})
  const userAccess = useContext(UserAccessContext)
  const user = useContext(UserContext)
  var kkl = 1;
  var www;

  const ell = () => {
    return document.querySelector(".left_sidemenu");
  }


  const handleMouseEnterMainMenu = () => {
    const el = ell();
    if(www) clearInterval(www);
    console.log("mouseover >> stopped",kkl)
    el.classList.add('open');
    kkl = null;


    // setTimeout(() => {
    //   if(www) clearInterval(www);
    // }, 500);
    // setTimeout(() => {
    //   if(www) clearInterval(www);
    // }, 1200);
    // setTimeout(() => {
    //   if(www) clearInterval(www);
    // }, 1700);

  }


  const handleMouseLeaveMainMenu = () => {
    console.log("jjj called");
    const el = ell()

    kkl = 1;
    console.log("mouseout >> stopped",kkl)
    www = setInterval(() => {
      if(kkl > 2) {
        kkl = null;
        clearInterval(www);
        console.log("interval >> stopped",kkl)
        el.classList.remove('open')
      } else {
        kkl += 1;
        console.log("interval >>",kkl)
      }
    }, 150);

  }

  const location = useLocation();

  const handleDealManagementClick = () => {
    if (location.pathname.includes("dealmanagement")) {
      window.location.reload();
    }
  };

  return (
    <div className="left_sidemenu" onMouseLeave={(e) => handleMouseLeaveMainMenu()}  onMouseEnter={(e) => handleMouseEnterMainMenu()} >
      <Link to="/">
        <div className="logo-unilever-div"><img src={UnileverLogoIcon} /></div>
        </Link>
      <ul>
        {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom")  && isInternalUser &&(user.userRole === "SuperAdmin" || (userAccess?.fgSLOBdata?.data?.toolId === "1" && userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("1"))) && (
            <li
              className={
                location.pathname?.includes("risksummary") ||
                  location.pathname === "/"
                  ? "activemenu"
                  : ""
              } 
              
              onMouseEnter={(e) => handleMouseEnterMainMenu()} 
            >
              <Link to="/risksummary">
                <div className="ico"><img src={RiskVisibilityImage} alt="" /></div>
                <div className="txt">Waste Risk Summary</div>
              </Link>
            </li>
          )}
        {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom")  && isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.fgSLOBdata?.data?.toolId === "1" && userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("2"))) && (
            <li
              className={
                location.pathname?.includes("riskdetails") ? "activemenu" : ""
              } 
              onMouseEnter={(e) => handleMouseEnterMainMenu()} 
            >
              <Link to="/riskdetails">
                <div className="ico"><img src={ResidualStatusImage} alt="" /></div>
                <div className="txt">Waste Risk Details</div>
              </Link>
            </li>
          )}

        {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom")  && isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.fgSLOBdata?.data?.toolId === "1" && (userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("3") || userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("5")))) && (
            <li className={location.pathname?.includes("dealcalendar") ? "activemenu" : ""} 
              onMouseEnter={(e) => handleMouseEnterMainMenu()} 
            >
              <Link to="/dealcalendar">
                <div className="ico"><img src={DealCalendarImage} alt="" /></div>
                <div className="txt">Central Deal Calendar</div>
              </Link>
            </li>
          )}
        {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom")  &&isInternalUser&& (( user.userRole === "SuperAdmin" || (userAccess?.fgSLOBdata?.data?.toolId === "1" && userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("4") || userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("5")))) && (
            <li
              className={
                location.pathname?.includes("dealmanagement")
                  ? "activemenu"
                  : ""
              } 
              
              onMouseEnter={(e) => handleMouseEnterMainMenu()} 
            >
              <Link to="/dealmanagement" onClick={handleDealManagementClick}>
                <div className="ico"><img src={DealManagementImage} alt="" /></div>
                <div className="txt">Central Deal Management</div>
              </Link>
            </li>
          )
        }

        {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom") && (!isInternalUser || (isInternalUser && user.userRole === 'Customer'))  && (
            <li
              className={location.pathname?.includes("biddingportal") ? "activemenu" : ""} 
              onMouseEnter={(e) => handleMouseEnterMainMenu()} 
            >
              <Link to="/biddingportal">
                <div className="ico"><img src={BenefitTrackerImage} alt="" /></div>
                <div className="txt">Bidding Portal</div>
              </Link>
            </li>
          )}
        {/* {!location.pathname?.includes("inflow") &&
          !location.pathname?.includes("pom")  && isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.fgSLOBdata?.data?.toolId === "1" && userAccess?.fgSLOBdata?.data?.tabViewSettingId?.includes("5"))) && (
            <li
              className={
                location.pathname?.includes("biddingportal-internal") ? "activemenu" : ""
              } 
              
              onMouseEnter={(e) => handleMouseEnterMainMenu()}  
            >
              <Link to="/biddingportal-internal">
                <div className="ico"><img src={BenefitTrackerImage} alt="" /></div>
                <div className="txt">Bidding Notification</div>
              </Link>
            </li>
          )} */}
        {location.pathname?.includes("inflow")  && isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.inflowdata?.data?.toolId === "2" && userAccess?.inflowdata?.data?.tabViewSettingId?.includes("2"))) && (
          <li
            className={
              location.pathname === "/inflowSummary" ? "activemenu" : ""
            } 
            
            onMouseEnter={(e) => handleMouseEnterMainMenu()} 
          >
            <Link to="/inflowSummary">
              <div className="ico"><img src={RiskVisibilityImage} alt="" /></div>
              <div className="txt">Summary</div>
            </Link>
          </li>
        )}
        {location.pathname?.includes("inflow")  && isInternalUser && ( user.userRole === "SuperAdmin" || (userAccess?.inflowdata?.data?.toolId === "2" && userAccess?.inflowdata?.data?.tabViewSettingId?.includes("1"))) && (
          <li
            className={
              location.pathname === "/inflow" ? "activemenu" : ""
            } 
            
            onMouseEnter={(e) => handleMouseEnterMainMenu()} 
          >
            <Link to="/inflow">
              <div className="ico"><img src={AppDrawerIcon} alt="" /></div>
              <div className="txt">Risk Visibility </div>
            </Link>
          </li>
        )}

        {location.pathname?.includes("pom")  &&  isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.pomdata?.data?.toolId === "3" && userAccess?.pomdata?.data?.tabViewSettingId?.includes("2"))) && (
          <li
            className={
              location.pathname === "/pomsummary" ? "activemenu" : ""
            } 
            
            onMouseEnter={(e) => handleMouseEnterMainMenu()} 
          >
            <Link to="/pomsummary">
              <div className="ico"><img src={RiskVisibilityImage} alt="" /></div>
              <div className="txt">Summary</div>
            </Link>
          </li>
        )}
        {location.pathname?.includes("pom")  && isInternalUser &&( user.userRole === "SuperAdmin" || (userAccess?.pomdata?.data?.toolId === "3" && userAccess?.pomdata?.data?.tabViewSettingId?.includes("1"))) && (
          <li
            className={location.pathname === "/pom" ? "activemenu" : ""} 
            
            onMouseEnter={(e) => handleMouseEnterMainMenu()} 
          >
            <Link to="/pom">
              <div className="ico"><img src={AppDrawerIcon} alt="" /></div>
              <div className="txt">Risk Details</div>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};
export default SideMenu;
