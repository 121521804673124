import React, { useMemo, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiUnfoldMoreHorizontal } from "@mdi/js";

import { useBlockLayout, usePagination, useTable } from "react-table";
import ReactTablePagination from "../../Common/react-table-pagination";

const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  setSortBy,
  SortType,
  setSortType,
  ...props
}) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  const INITIAL_HIDDEN_COLUMNS = columns
    .filter((column) => !column.show)
    .map((column) => column.accessor);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        },
      },
      useBlockLayout
    );

  const handleSorting = (column) => {
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
  };

  return (
    <>
      <div className="table_design mt-2">
        <table
          cellPadding={"0"}
          cellSpacing={"0"}
          className="data-table-two display nowrap scrollclass"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={`${column.id}`}>
                    <div className="th-in">
                      {column.render("Header")}
                      <span className="sort-ico">
                        <Icon
                          path={mdiUnfoldMoreHorizontal}
                          className="sortColumn"
                          size="16px"
                          onClick={() => handleSorting(column)}
                        />
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`${cell.column.id} ${
                          cell.column.id !== "mco" ? "text-right" : ""
                        } `}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default React.memo(TableGrid);
