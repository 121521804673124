import { EditCell, DateRangeColumnFilter } from "../../../Views/WasteRiskDetailsTable/CellAction";

const statusObj = {
  "Blank": "deal_status_text bg-blank",
  "Rejected": "deal_status_text bg-red",
  "Accepted": "deal_status_text bg-green text-black",
};

export const COLUMNS = [
  {
    Header: "Material Number ",
    accessor: "materialno",
    show: true,
    sticky: true
  },
  {
    Header: "Material Description",
    accessor: "material_description",
    show: true,
  },
  {
    Header: "Plant Code", 
    accessor: "plant_code", 
    show: true 
  },
  {
    Header: "SKU Status",
    accessor: "view_status",
    show: true,
    editable: false,
    body_class: "text-center",
    size: 100
  },
  {
    Header: "Plant-sp. matl status",
    accessor: "plants_sp_matl_status",
    show: true,
  },
  {
    Header: "S Cat GMRDR",
    accessor: "business_group",
    show: true,

  },
  { Header: "MCO", accessor: "mco", show: true, },
  { Header: "Country Name", accessor: "country", show: true, },
  {
    Header: "L3 Small C GMRDR",
    accessor: "category",
    show: true,
  },

  { Header: "L10 Brand GMRDR", accessor: "brand", show: true, width: "200px" },
  {
    Header: " L12 SPF GMRDR",
    accessor: "l12_spf_gmrdr",
    show: true,
  },
  {
    Header: " L13 SPFV GMRDR",
    accessor: "l13_spfv_gmrdr",
    show: true,
  },
  {
    Header: "Standard Price per ZUN in EUR",
    accessor: "standard_price",
    show: true,
  },
  // {
  //   Header: "Deal Status",
  //   accessor: "deal_status",
  //   show: true,
  //   editable: false,

  //   Cell: (cellObj) => {
  //     return (
  //       <span className={`text-white border-radius-std ${statusObj[cellObj.value]}`} >{cellObj.value}</span>
  //     );
  //   },
  // },
  {
    Header: "Central deal quantity in ZUNs",
    accessor: "central_deal_quantity_in_zun",
    show: true,
  },
  // {
  //   Header: "% Variance to Floor Price",
  //   accessor: "per_variance_to_floor_price",
  //   show: true,
  //   Cell: (cellObj) => {
  //     return (
  //       <span className={`text-white border-radius-std ${statusObj[cellObj.value]}`} >{cellObj.value}</span>
  //     );
  //   },
  // },

  {
    Header: "Pack Type",
    accessor: "pack_type",
    show: true,
  },
  { Header: "BoMs", accessor: "bom", show: true },
  {
    Header: "Language on pack",
    accessor: "language_on_pack",
    show: true,
  },
  {
    Header: "Central Deal Status Type",
    accessor: "dealtype",
    show: true,
  },
  {
    Header: "Production country name",
    accessor: "production_country_name",
    show: true,
  },
  // {
  //   Header: "Agreed Quantity in ZUN",
  //   accessor: "agreed_quantity_in_zun",
  //   show: true,
  // },
  // {
  //   Header: "COGS (Cost of Goods Sold) EUR",
  //   accessor: "cogs",
  //   show: true,
  // },
  // {
  //   Header: "Agreed Price per ZUN in EUR",
  //   accessor: "agreed_price_per_zun_in_eur",
  //   show: true,
  // },
  // {
  //   Header: "Sales Value EUR",
  //   accessor: "sales_value_eur",
  //   show: true,
  // },
  // {
  //   Header: "Loss Value EUR",
  //   accessor: "loss_value_eur",
  //   show: true,
  // },
  // {
  //   Header: "Margin%",
  //   accessor: "margin_percentage",
  //   show: true,
  // },
  // {
  //   Header: "Net COGS (removing prov.) EUR",
  //   accessor: "net_cogs_removing_prov_eur",
  //   show: true,
  // },
  // {
  //   Header: "Net Margin%",
  //   accessor: "net_margin_percentage",
  //   show: true,

  // },
  {
    Header: "SPF code (needed for the Floor Price)",
    accessor: "spf_code",
    show: true,
  },
  {
    Header: "Min Floor price per ZUN for Central Deal in EUR (numbers only)",
    accessor: "min_floor_price_per_zun_for_central_deal_in_eur",
    show: true,
    class: "td-negotation-phase-with-customers"
  },
  {
    Header: "TP exworks DC",
    accessor: "tp_exworks_dc",
    show: true,
  },
  
  {
    Header: "ZUN per case",
    accessor: "zun_per_case2",
    show: true,
  },
  {
    Header: "ZUN per pallet",
    accessor: "zun_per_pallet2",
    show: true,
  },
  { Header: "Country Code", accessor: "country_code", show: true },
  { Header: "City", accessor: "city", show: true, width: "200px" },
  {
    Header: "Full Address",
    accessor: "full_address",
    show: true,
  },
  {
    Header: "Post Code",
    accessor: "post_code",
    show: true,
  },
  { Header: "EAN ZUN", accessor: "ean_zun", show: true, class: "td-additional-product-data" },
  { Header: "EAN Carton", accessor: "ean_carton", show: true },
  { Header: "Deal Pallets ZUN", accessor: "deal_pallet_zun", show: true },

  {
    Header: "Expiry Date",
    accessor: "expiry_date",
    show: true,
    editable: false,
    Filter: DateRangeColumnFilter,
  },
  {
    Header: "TPM file - Provisions in EUR",
    accessor: "tpm_provisions_eur",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
    header_class: "fc-14w-data",
  },
];
