import React, { useState, useEffect, useCallback } from "react";
import Popup from "reactjs-popup";
import Grid from "@material-ui/core/Grid";
import { ToastContainer, toast } from "react-toastify";
import Table from "../Table/ViewCustomersTable";
import viewIcon from "../../../Assets/img/icon-view.svg";
import { getCustomers } from "../../../Services/userManagementService";
const ViewCustomers = (props) => {
  const [customersData, setCustomersData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState([]);
  const getCustomersList = useCallback((ss)=>{
    setIsTableLoading(true);
    getCustomers(ss).then((success)=>{
      setCustomersData(success);
      console.log("customersData - view customers",success);
      setIsTableLoading(false);
    })
  },[])
  useEffect(()=> {
    (props.displayedAsNotification != undefined) ? getCustomersList(props.displayedAsNotification) : getCustomersList();
  },[props.isCustomersPopupOpen])
    return (
      <>
        <Popup
          className="glossary-add-edit-popup size-lg"
          open={props.isCustomersPopupOpen}
          closeOnDocumentClick={false}
          // trigger={
          //   <button type="button" className={`btn-slim btn-blue`} disabled={props.disabled}>
          //     <img src={viewIcon} style={{height:"18px", marginRight:"12px"}} />
          //     View Customers
          //   </button>
          // }
          modal
          nested
          onOpen={()=> {
            setTimeout(() => {
              document.querySelector(".table-design-user-modal").scrollLeft = 0;
            }, 300);
          }}
        >
          {(close) => (
            <>
              <div className="popup-header d-flex align-items-center justify-content-between">
                <h5 className="mt-2">
                  View Customer{props.displayedAsNotification ? "" : "s"}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => props.handleCustomerPopupOpen(false)}
                ></button>
              </div>
              <div className="scroll-content position-relative">
                <div className={`row row-user-settings`}>
                  <div className="col-md-12">
                    <div className="datatable">
                      <Table
                        data={customersData}
                        isTableLoading={isTableLoading}
                        displayedAsNotification={props.displayedAsNotification}
                        editIndCustomer={(val) => {
                          props.editIndCustomer(val);
                        }}
                        copyIndCustomer={(val) => {
                          props.copyIndCustomer(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Popup>
      </>
    );
}


export default ViewCustomers;
        