import React, { useCallback, useContext, useEffect, useState } from "react";
import ULModal from "../../Common/Modal";
import Loader from "../../Common/loader";
import {
  getBomDetails,
  saveBomDetails,
} from "../../Services/WasteRiskDetailServices";
import { notify } from "../../Common/toast";
import { BlockInvalidChar } from "../../utility/helper";

const BomDetails = (props) => {
  let [bomData, setBomData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getBomData = useCallback((payload) => {
    setIsLoading(true);
    getBomDetails(payload)
      .then((response) => {
        if (response.bomDetails?.length)
          setBomData([...response.bomDetails]);
          setIsLoading(false);
      })
      .catch(() => {
        notify({
          type: "error",
          message: "Something went wrong",
        });
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (props.show)
      getBomData({
        materialno: props.record.materialno,
        plantCode: props.record.plantCodeId,
      });
  }, [getBomData, props.record, props.show]);

  const handleCellChange = (value, prop, index) => {
    bomData[index][prop] = value;
    setBomData([...bomData]);
  };

  const handleUpdateCustomers = (bom) => {
    setIsLoading(true);
    saveBomDetails({
      plantCode: props.record.plant_code,
      materialno: props.record.materialno,
      componentMaterialNumber: bom.ComponentMaterialNumber,
      bomRefId:bom.bomRefId
    })
      .then((response) => {
        setIsLoading(false);
        if (response) {
          notify({ type: "success", message: "Submitted successfully" });
          props.onHide();
          props.reLoad();
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  return (
    <ULModal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      isheader="true"
      className={`page-modal`}
    >
     <div className="row">
        <div className="col-md-12">
          <div className="d-flex  flex-column justify-content-between h-100">
            <div className="d-flex flex-column justify-content-between flex-basis-100">
              <div>
                {isLoading && (
                  <>
                    <div className={`tableLoader_overlay`}>
                      <div className="tableLoader_box">
                        <div className="tableLoader_box-in">
                          <Loader />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="table_design d-flex">
                <div  className={`table sticky ${(bomData?.length <= 0) ? 'no-data' : ''}`} >
                  <div className="header">
                    <div className="tr">
                      <div className="th" style={{width:"190px"}} > <div className="th-in">
                                  <span className="th-text" title="Component Material Number"  >Component Material Number</span></div></div>
                      <div className="th" style={{width:"220px"}}> <div className="th-in">
                                  <span className="th-text" title="Component Material Description"  >Component Material Description</span></div></div>
                      <div className="th"> <div className="th-in">
                                  <span className="th-text" title="Quantity"  >Quantity</span></div></div>
                      <div className="th"> <div className="th-in">
                                  <span className="th-text" title="EAN-ZUN"  >EAN-ZUN</span></div></div>
                    </div>
                  </div>
                  {(!isLoading && bomData?.length <= 0) &&  (
                    <div className="body">
                      <div className="tr w-100">
                        <div role="cell" className="td">
                            <span className="td-in text-center">No Data</span>
                        </div>
                      </div>
                    </div>
                  )}
                   {(!isLoading && bomData?.length > 0) &&  (
                      <div className="body">
                        {bomData.map((bom, index) => (
                          <div className="tr" key={index}>
                            <div className="td"  style={{width:"190px"}}>
                              <span className="td-in" title={bom.ComponentMaterialNumber}>
                                {/* <input
                                   style={{width:"200px"}}
                                  type="number"
                                  min={0}
                                  className="bg-black "
                                  value={bom.ComponentMaterialNumber}
                                  onChange={(e) => {
                                    if (e.key === ".") {
                                      e.preventDefault();
                                    }
                                    handleCellChange(
                                      e.target.value,
                                      "ComponentMaterialNumber",
                                      index
                                    );
                                  }}
                                  onBlur={() => {
                                    if(bom.ComponentMaterialNumber){
                                    handleUpdateCustomers(bom);}
                                    else{
                                      notify({
                                        type:'error',
                                        message:'Component Material Number should not be empty'
                                      })
                                    }
                                  }}
                                /> */}
                                {bom.ComponentMaterialNumber}
                              </span>
                            </div>
                    <div className="td" style={{width:"220px"}} >
                              <span className="td-in" title = {bom.ComponentMaterialDescription}>
                              {bom.ComponentMaterialDescription}
                              </span>
                            </div>
                            <div className="td" title={bom.Quantity}>{bom.Quantity}</div>
                            {bom.EAN_ZUN && (
                            <div className="td" title={Math.round(bom.EAN_ZUN)}>
                              {Math.round(bom.EAN_ZUN)}
                            </div>
                          )}
                          {!bom.EAN_ZUN && (
                            <div className="td" title={""}></div>
                          )}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </ULModal>
  );
};

export default BomDetails;
