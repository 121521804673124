import React, {useEffect, useState, } from "react";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  useResizeColumns
} from "react-table";
import { useSticky } from "react-table-sticky";
import ReactTablePagination from "../../../Common/react-table-pagination";
import ULDropdown from "../../../Common/Dropdown";
import ClickIcon from "../../../Assets/img/clickicon.svg";
import { Checkbox } from "./Checkbox";
import Loader from "../../../Common/loader";
import resizeIcon from "../../../Assets/img/icon-resize.svg"
import ColumnHeaderDesc from "../../../Common/ColumnHeaderDesc";
import { tableToolTip } from "../../../utility/constants";
import { OverlayTrigger } from "react-bootstrap";

const PersonalCareTableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  status,
  dealMonths,
  packTypeList,
  updateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SummaryTableHandleDownload_ref,
  SortBy,
  setSortBy,
  SortType,
  setSortType,
  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  actions,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  toggleExpandCollapse,
  handleCheckBox,
  handleSelectAllCheckBox,
  isCheck,
  isNotRequiredTableCoumnSort,
  setCreateListItem,
  isNewListSaved,
  publishLoading,
  totalCount,
  setPageInd,
  pageInd,
  state,
  biddingApprovalStatus,
  isSuperiorEditingBiddingList,
  isSuperAdminEdited,
  ...props
}) => {

  const columns = React.useMemo(() => tableColumns, [tableColumns]);
  const data = React.useMemo(
    () => tableData.map((r) => ({ ...r })),
    [tableData]
  );
  const [isExpand, setIsExpand] = useState(false);
  const [loadertable, setLoaderTable] = React.useState(false);
  const manualPageSize = [5, 10, 20, 30];
  const INITIAL_HIDDEN_COLUMNS = columns
    .filter((column) => !column.show)
    .map((column) => column.accessor);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    allColumns,
    toggleHideColumn,
    toggleHideAllColumns,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      handleClickEditCell,
      clickedIndex,
      clickedCell,
      updateCellData,
      isSuperiorEditingBiddingList,
      isSuperAdminEdited,
      status,
      dealMonths,
      packTypeList,
      biddingApprovalStatus,
      handleCheckBox,
      handleSelectAllCheckBox,
      selection,
      isCheck,
      manualPagination: true,
      pageCount: Math.ceil(totalRecordCount / pageSizeNo),
      initialState: {
        pageIndex: pageInd,
        pageSize: pageSizeNo,
        hiddenColumns: INITIAL_HIDDEN_COLUMNS,
      },
    },
    usePagination,
    useRowSelect,
    useBlockLayout,
    useResizeColumns,
    useSticky,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // {
        //   id: "selection",
        //   Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //     <Checkbox {...getToggleAllPageRowsSelectedProps()} />
        //   ),
        //   Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        //   sticky: "left",
        // },
        ...columns,
      ]);
    }
  );

  const [val, setVal] = React.useState([
    "all",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]);

  //column customization code start here
  const selectAllOption = { value: "all", label: "Select all" };
  const filterColumns = allColumns
    ? allColumns
      .filter((item) => item.sticky !== "left")
      .map((column) => ({
        value: column.id,
        label: column.Header,
        sticky: column.sticky
      }))
    : [];

  const handleSelect = (selected, action) => {
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      setVal([
        "all",
        ...columns
          .filter((item) => item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      toggleHideAllColumns(false);
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      setVal(columns.filter((item) => item.sticky)
        .map((item) => item.accessor));
      columns.filter((item) => !item.sticky)
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
    } else {
      setVal(
        action.action === "select-option" && selected?.length === columns.length
          ? [{ value: "all", label: "Select all" }, ...selected].map(
            (item) => item.value
          )
          : selected.filter((i) => i.value !== "all").map((item) => item.value)
      );
      if (action.action === "select-option" && selected.length === 1) {
        toggleHideColumn("selection");
      }
    }

    if (action.action !== "pop-value") {
      if (action.action === "clear") {
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        toggleHideColumn(action.option.value);
      }
    }
  };

  React.useEffect(() => {
    if (!isNewListSaved) setSelection(selectedFlatRows);
    else {
      setSelection([]);
    }
  }, [selectedFlatRows, isNewListSaved, setSelection]);

  React.useEffect(() => {
    if (setCreateListItem && !isNewListSaved) {
      setCreateListItem(selectedFlatRows);
    } else if (isNewListSaved) {
      selectedFlatRows.forEach((key, index) => {
        key.toggleRowSelected();
      });
    }
  }, [selectedFlatRows, setCreateListItem, isNewListSaved]);

  React.useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleSorting = (column) => {
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
  };

  let selectionValues = {
    "3wk + 26wk Residual ZUNs": "wk13_wk26_residual_zun",
    "13wk + 26wk Residual EUR": "wk13_wk26_residual_eur",
    "Central deal quantity in ZUNs": "central_deal_quantity_in_zun",
    "Central deal in EUR": "central_deal_in_eur",
    "Remaining quantity in ZUN": "remaining_quantity_in_zun",
    "Remaining stock in EUR": "remaining_stock_in_eur",
    "Expiry date": "expiry_date",
  };

  const handleNxtPages = () => {
    setLoaderTable(true);
    nextPage();
    setTimeout(() => setLoaderTable(false), 1000);
  };
  const handlePrevPages = () => {
    setLoaderTable(true);
    previousPage();
    setTimeout(() => setLoaderTable(false), 1000);
  };

  const handleGotoPages = (i) => {
    setLoaderTable(true);
    gotoPage(i);
    setTimeout(() => setLoaderTable(false), 1000);
  };


  useEffect(()=>{
    setIsExpand(props.isExpand);
  },[props.isExpand])

  return (
    <>
      <div className="table-filters mt-3 d-flex align-items-center">
        {val.length !== 0 && (
          <>
            <div className="d-inline-block">
              <span className="row-click-link">
                {!props.isExternal && (
                  <>
                    {props.offerType == 'sent' && (
                      <>
                        {!props.isOfferSentInputDisabled && (
                          <>
                            {biddingApprovalStatus &&
                              <>
                                {props.isSuperAdmin && biddingApprovalStatus != 'approved' && (
                                  <div>
                                    <img src={ClickIcon} alt="" />
                                    "Click 'Edit' to edit the cell value"
                                  </div>
                                )}
                              </>
                            }
                            {!props.isSuperAdmin && !biddingApprovalStatus &&
                              <span>
                                <img src={ClickIcon} alt="" />
                                "Edit the highlighted cells, to get this approved"
                              </span>
                            }

                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {(!props.isSuperAdmin) && (biddingApprovalStatus != 'approved' || biddingApprovalStatus != 'rejected') && ((!props.biddingStatus.includes('Yet') && !props.biddingStatus.includes('Planner') && !props.biddingStatus.includes('Await')) || (props.biddingStatus.includes('Customer'))) && (
                  <span>
                    <img src={ClickIcon} alt="" />
                    "Click 'Counter Offer' to edit the cell value/Upload file"
                  </span>
                )}
              </span>
              <span className="row-click-link ml-2">
                <img src={resizeIcon} alt="" />&nbsp;
                Doubleclick &amp; move the header column borders to resize
              </span>
            </div>
          </>
        )}
        <div className="ml-3 d-inline-block flex align-items-center justify-content-between">
          <ul className="table-form flex align-items-center">
            {!props.isNoColumnSelection && (
              <li className="pr-0">
                <div className="flex align-items-center">
                  <label className="d-inline-block mr-2">Columns:</label>
                  <ULDropdown
                    name="custom_column_filter"
                    value={val}
                    options={[selectAllOption, ...filterColumns]}
                    //options={filterColumns}
                    onChange={handleSelect}
                    isMulti={true}
                    isClearable={false}
                    placeholder={"Search Column"}
                    className="react-select-container inp-std-width"
                    popup
                  />
                </div>
              </li>
            )}

          </ul>
        </div>
      </div>
      {/* <!--Search Table Data End--> */}
      <div className="clear"></div>
      {val.length !== 0 && (
        <>
          <div className="position-relative">
            <div className={`table_design mt-2 ${(data.length <= 0) ? 'no-data' : ''}`} tabIndex={1} style={{ maxHeight: "auto" }}>
              <div
                {...getTableProps()}
                className={`table sticky ${(data.length <= 0) ? 'no-data' : ''}`}
              >
                <div className="header">
                  {headerGroups.map((headerGroup, index) => {
                    var col_pos_left = 0;
                    return (
                      <div
                        key={index}
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr"
                      >
                        {headerGroup.headers.map((column, index) => {

                          //console.log("index",index);
                          // if ((column.id === "selection")) {
                          //   col_pos_left = 0;
                          // }
                          if ((column.id === "material_description")) {
                            // col_pos_left = ((column.width + 40)) - ((column.width > 160) ? 160 : column.width);
                            col_pos_left = 0;
                          }
                          else if ((column.id !== "selection")) {
                            col_pos_left += (column.width > 160) ? column.width : 160;
                          }
                          return (
                            <div
                              key={index}
                              {...column.getHeaderProps()}
                              style={{
                                width:
                                  column.id === "selection"
                                    ? "40px"
                                    : (column.width > 160) ? column.width : "160px",
                                minWidth:
                                  column.id === "selection"
                                    ? "40px"
                                    : (column.width > 160) ? column.width : "160px",
                                maxWidth:
                                  column.id === "selection"
                                    ? "40px"
                                    : (column.width > 160) ? column.width : "160px",
                                left: (column.id !== "material_description") ? `${col_pos_left - ((column.width < 160) ? 160 : column.width) + 160}px` : 0
                              }}
                              className={`
                                    th ${column.id}  
                                    sort_${SortBy} 
                                    sticky-${column.sticky} 
                                    ${column.id === "wk13_wk26_residual_zun"
                                  ? "text-right"
                                  : ""
                                } 
                                  ${[
                                  "residual_status",
                                  "dealtype",
                                  "previous_month_deal_type",
                                  "comment_field",
                                  "previous_month_comment_field",
                                  "previous_month_comment_field",
                                  "local_deal_completion_date",
                                  "local_deal_quantity_in_zun",
                                  "central_deal_quantity_in_zun",
                                  "remaining_quantity_in_zun",
                                  "local_deal_in_eur",
                                  "central_deal_in_eur",
                                  "remaining_stock_in_eur",
                                  "pack_type",
                                  "bom",
                                  "language_on_pack",
                                  "production_plant_number",
                                  "production_country_name"
                                ].includes(column.id)
                                  ? "td-nomination"
                                  : ""
                                }
                                  ${["country_code", "city", "full_address", "post_code"].includes(column.id)
                                  ? "td-plant-data"
                                  : ""
                                }
                                  ${["ean_zun", "ean_carton", "zun_per_case", "zun_per_pallet", "deal_pallet_zun"].includes(column.id)
                                  ? "td-additional-product-data"
                                  : ""
                                }
                                  ${[
                                  "agreed_quantity_in_zun",
                                  "price_per_zun_in_eur",
                                  "cogs",
                                  "agreed_price_per_zun_in_eur",
                                  "sales_value_eur",
                                  "loss_value_eur",
                                  "margin_percentage",
                                  "net_cogs_removing_prov_eur",
                                  "net_margin_percentage",
                                  "spf_code",
                                  "min_floor_price_per_zun_for_central_deal_in_eur",
                                  "tp_exworks_dc"
                                ].includes(column.id)
                                  ? "td-negotation-phase-with-customers"
                                  : ""
                                }
                                  ${[
                                  "quantity_available",
                                  "stock_is_located_in_country",
                                  "stock_is_produced_in_country",
                                  "ean_zun2",
                                  "ean_carton2",
                                  "zun_per_case2",
                                  "zun_per_pallet2"
                                ].includes(column.id)
                                  ? "td-bidding-portal"
                                  : ""
                                }
                                    `}
                            >
                              <div
                                {...column.getResizerProps()}
                                className={`resizer ${column.isResizing
                                  ? "isResizing"
                                  : column.width
                                  }`}
                              />
                              <div className="th-in">
                                <OverlayTrigger
                                  overlay={tableToolTip(ColumnHeaderDesc("fgslob", column.Header))}
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  delay={{ show: 200, hide: 0 }}
                                >
                                  <span
                                    className="th-text" >
                                    <div className="header_col_title_wrap">
                                      <div className="header_col_title">
                                        {column.render("Header")}
                                      </div>
                                      {column.id !== "selection" && (
                                        <span
                                          className="sort-ico"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>
                                            {SortType === "ASC" && column.id === SortBy ? (
                                              <Icon
                                                path={mdiMenuDown}
                                                size="1.5rem"
                                                className="sortColumn"
                                                onClick={() => handleSorting(column)}
                                              />
                                            ) : (
                                              <Icon
                                                path={mdiMenuUp}
                                                size="1.5rem"
                                                className="sortColumn"
                                                onClick={() => handleSorting(column)}
                                              />
                                            )}
                                          </span>
                                        </span>
                                      )}
                                    </div>

                                  </span>
                                </OverlayTrigger>
                                {column.id !== "selection" && (
                                  <span
                                    title="Doubleclick to resize column"
                                    className={`col-resize-handle resizer 
                                     ${column.isResizing ? "isResizing" : ""
                                      }  ${column.width < 150 ? "minimum" : ""}`}
                                    {...column.getResizerProps()}
                                  ></span>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>

                {!page.length && (loadertable || publishLoading) && (
                  <Loader />
                )}

                {!page.length && 
                  <div className="body w-100">
                    <div className="tr w-100">
                      <div role="cell" className="td  w-100">
                        <span className="td-in text-center">No Data</span>
                      </div>
                    </div>
                  </div>
                }
                
                {page.length && 
                  <div
                    key={state}
                    {...getTableBodyProps()}
                    className={`body  ${page.length <= 0 ? "no-data" : ""}`}
                  >
                    {(page.length > 0 &&
                      page.map((row, index) => {
                        var body_col_pos_left = 0;
                        prepareRow(row);
                        return (
                          <div key={index} {...row.getRowProps()} className="tr">
                            {row.cells.map((cell, index) => {
                              // if ((cell.column.id === "selection")) {
                              //   body_col_pos_left = 0;
                              // }
                              if ((cell.column.id === "material_description")) {
                                body_col_pos_left = 0;
                                // body_col_pos_left = ((cell.column.width + 40)) - ((cell.column.width > 160) ? 160 : cell.column.width);
                              }
                              else if ((cell.column.id !== "selection")) {
                                body_col_pos_left += (cell.column.width > 160) ? cell.column.width : 160;
                              }

                              return (
                                <div
                                  key={index}
                                  {...cell.getCellProps()}
                                  style={{
                                    width:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    minWidth:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    maxWidth:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    left: (cell.column.id !== "material_description") ? `${body_col_pos_left - ((cell.column.width < 160) ? 160 : cell.column.width) + 160}px` : 0
                                  }}
                                  className={`
                                  td  ${cell.column.id} sticky-${cell.column.sticky} sort_${SortBy}
                                    td-editable-${cell.column.editable}`}
                                >
                                  <span
                                    className="td-in"
                                    title={cell.value}
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {props.isOfferToSend && props.isOfferSentInputDisabled && (
                                      <>
                                        {(cell.column.id == 'quantity_to_offer_bid' || cell.column.id == 'vendor_bid_price' || cell.column.id == 'minimum_acceptance_price' || cell.column.id == 'vendorComments') &&
                                          <div>{cell.value}</div>
                                        }
                                        {(cell.column.id != 'quantity_to_offer_bid' && cell.column.id != 'vendor_bid_price' && cell.column.id != 'minimum_acceptance_price' && cell.column.id != 'vendorComments') &&
                                          <div>{cell.render("Cell")}</div>
                                        }
                                      </>
                                    )}
                                    {props.isOfferToSend && !props.isOfferSentInputDisabled && (
                                      <div>{cell.render("Cell")}</div>
                                    )}
                                    {!props.isOfferToSend && (
                                      <div>{cell.render("Cell")}</div>  
                                    )}
                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        );
                      }))}
                  </div>
                }
              </div>
            </div>
          </div>
          {totalRecordCount > 0 && (
            <ReactTablePagination
              page={page}
              gotoPage={handleGotoPages}
              previousPage={handlePrevPages}
              nextPage={handleNxtPages}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              manualPageSize={manualPageSize}
              dataLength={totalRecordCount}
              handleItemPerPage={handleItemPerPage}
              handlePaginationReset={handlePaginationReset}
              pageSizeNo={pageSizeNo}
              totalCount={totalCount}
              setPageIndex={setPageInd}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(PersonalCareTableGrid);