import React, { useState } from 'react'


export function GlossaryNewCell(cellObj) {
    const { column, row, value } = cellObj.cell;
    const { original: { actionsList } } = row;
    console.log(cellObj)
    console.log(value)

    const [val, setVal] = useState(value);
    // console.log(val)
    const [isSelection, setIsSelection] = useState(false);

    const handleCellChange = (e) => {
        // if (e.target.value) {
            console.log(e.target.value)
        setIsSelection(true);
        setVal(e.target.value);

    };
    return (
        <>
            <select
                className="btn-slim w-100 select-glossary"
                style={{backgroundColor:"#1e232f",color:'white'}}
                placeholder='hai'
                title='dropdown'
                onChange={handleCellChange}
                onBlur={() => cellObj.updateCellData(row, column, val)}
            >
                <option key={"select"}   >
                    Select Kpi Names
                </option>
                {value?.filter(i=>i!==null).map(i=>{
                    return(
                        <option key={i.header}>
                    {i.header}
                </option>
                    )
                })}
            </select>
        </>
    )
}

function GlossaryEditCell(cellObj) {
    const { column, row, value } = cellObj.cell;
    const { original: { actionsList } } = row;
    console.log(cellObj)


    const [val, setVal] = useState(value);
    // console.log(val)
    const [isSelection, setIsSelection] = useState(false);

    const handleCellChange = (e) => {
        // if (e.target.value) {
        setIsSelection(true);
        setVal(e.target.value);

    };

    return (
        <>
            {column.id === "column_name" && (
                // <div className="edit-cell">
                <textarea
                    id="auto-expanding-textarea"
                    style={{ width: "100%", resize: "none", border: "none", height: "auto" }} 
                    type="text"
                    value={val === null ? "" : val}
                    onChange={handleCellChange}
                    placeholder='Enter Column Name'
                    onBlur={() => cellObj.updateCellData(row, column, val)}
                />

                // </div>
            )}
            {column.id === "description" && (
                <div className="edit-cell">
                    <textarea
                        id="auto-expanding-textarea"
                        style={{ width: "100%", resize: "none", border: "none", height: "auto" }}
                        type="text"
                        value={val === null ? "" : val}
                        onChange={handleCellChange}
                        placeholder='Enter Description'
                        onBlur={() => cellObj.updateCellData(row, column, val)}
                    />
                </div>
            )}
            {column.Header === "Delete" &&
                <div className="edit-cell">
                    <textarea
                        id="auto-expanding-textarea"
                        style={{ width: "100%", resize: "none", border: "none", height: "auto" }}
                        type="text"
                        value={val === null ? "" : val}
                        onChange={handleCellChange}
                        className='input-area w-100'
                        placeholder='Enter Description'
                        onBlur={() => cellObj.updateCellData(row, column, val)}
                    />
                </div>
            }
        </>
    )
}

export default GlossaryEditCell