import React from "react";
import ULButton from "../button";
import Loader from "../loader";
import Modal from "react-bootstrap/Modal";

const StatusPopup = (props) => {
  return (
    <>
      <Modal size="sm" centered show={props.show}>
        <Modal.Header
          closeButton
          onHide={() => props.onHide(false)}
        ></Modal.Header>
        <div
          className=" d-flex align-items-center justify-content-center"
          style={{ minHeight: "100px" }}
        >
          {(props.isNewListSaved && (
            <div className="text-center">
              <p>{props.inputName}</p>
              <p>Successfully Submitted</p>
              <ULButton
                type="button"
                className="d-inline-block btn btn-primary btn-create-new-list-success-close"
                label={`Close`}
                onClick={() => props.onHide(false)}
              />
            </div>
          )) || (
            <div className="text-center">
              <div>
                <Loader></Loader>
              </div>
            </div>
          )}
        </div>
        <Modal.Footer className="justify-content-center border-0">
          <div
            className="d-flex justify-content-center"
            style={{ gap: "15px" }}
          >
            <ULButton
              type="button"
              className="btn btn-primary btn-slim d-inline-block"
              label="Refresh"
              onClick={() => props.onRefreshClick()}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StatusPopup;
