import React from 'react'
import Header from '../../../Common/header'
import { Tab, Tabs } from 'react-bootstrap'
import AssignOwner from '../common/AssignOwner'
import ListUpdate from '../common/ListUpdate'
import Glossery from '../common/Glossery'
import UserNotifications from './common/UserNotifications'
import AdminNotification from './common/AdminNotification'
import BiddingPortalNotifications from './common/BiddingPortalNotifications'

function MyNotifications() {

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="container-fluid page-user-management pl-0 pr-0">
      <div className="main_header align-baseline">
        <Header isNotProduction={!isProd()} />
      </div>
      <div className={`main-content-row`}>
        <div className="side-menu left_sidemenu">
          {/* <SideMenu /> */}
        </div>
        <div className="main-div-content full-screen-cover">
          {/* <div class="page-body-top-content">
            <Crtracker />
          </div> */}
          <div className="row h-100">
            <div className="col-md-12 h-100 pr-0 ">
              <div className="body-container panel-border-round h-100">
                <div>
                  <Tabs
                    id="controlled-tab-example"
                    className=" pull-right"
                    defaultActiveKey={"Admin"}
                  >
                    <Tab eventKey="Admin" title="Admin">
                      <div className="notification-tabs">
                        <BiddingPortalNotifications />
                      </div>
                    </Tab>
                    <Tab eventKey="Bidding Portal" title="Bidding Portal">
                      <div className="notification-tabs">
                        <BiddingPortalNotifications />
                      </div>
                    </Tab>
                  </Tabs>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyNotifications