import React, { useState, StrictMode, useEffect, useContext } from "react";
import Header from '../../../Common/header';
import SideMenu from '../../../Views/common/SideMenu';
import StatusPomSummaryTable from "./StatusPomSummaryTable";
import Dropdown from "../../../Views/common/Dropdown";
import { getUserPreferences, saveUserPreferences } from "../../../Services/PomService";
import { UserContext } from "../../Application";

 
const POMSummary = () => {
    const [dropDownValues, setDropDownValues] = useState();
    const [filterOptions, setFilterOptions] = useState();
    const [reload, setReload] = useState(false);
    const user = useContext(UserContext)

    const [reeload,setreeload] = useState("false")

    let isProd = () => {
      if (window.location.href.includes('-p-')) {
        return true
      }
      else if (window.location.href.includes('localhost')) {
        return true
      } else {
        return false
      }
    }
    const [ asddd,setasddd] =useState(false)
    useEffect(()=>{
      const dummy={alert:[],
        businessGroup:[],
        category:[],
        country:[],
        foodSolution:[],
        forecastFamily:[],
        mrpc:[],
        nextStep:[],
          plant:[],
        reporting_family:[],
      }
      getUserPreferences(user.email).then(res=>{
        if(res.data[0]){
          setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
        }    
        else{
          const payload = {
            userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
            userRole:user.userRole,
            userEmail:user.email,
            userPreference:{ preferenceData:dummy,
              selectedColumns:["all"]
            }}
          saveUserPreferences(payload).then((res)=>{setreeload(!reeload)})
          window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:{},selectedColumns:['all']}))
        }  
      }
    )
    },[user])
    
    useEffect(()=>{
      getUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }         
      }
    )
    },[reeload])
     
    return (
      <>
        <div className="container-fluid pl-0 tablegrid-height-fluid">
          <div className="main_header align-baseline">
            <Header isNotProduction={!isProd()} />
          </div>
          <div className="main-content-row">
            <div className="side-menu">
              <SideMenu />
            </div>
            <div className="main-div-content">
              <div className="mb-3">
              {asddd &&<Dropdown
                list={[
                  "Business Group",
                  "Country",
                  "Plant Code",
                  "Category",
                  "Alert Type",
                  "Next Step",
                  "Food Solution Indicator",
                  "MRP controller"
                ]}
                onChange={(value) => {
                    setDropDownValues({ ...value });
                }}
                setDropDownValues={setDropDownValues}
                reload={reload}
                setReload={setReload}
                setFilterOptions={setFilterOptions}
              />}
              </div>
              <StatusPomSummaryTable
                dropDownValues={dropDownValues}
                filterOptions={filterOptions}
                // chartData={data?.data}
              />
            </div>
          </div>
        </div>
      </>
    );
};

export default POMSummary;