import  { useContext, useEffect, useRef, useState } from "react";
import { BlockInvalidChar } from "../../utility/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "../../App.css";
import BomDetails from "./BomDetails";
import { UserAccessContext, UserContext } from "../../Components/Application";
import ViewHistory from "./ViewHistory";

export const DateRangeColumnFilter = (cellObj) => {
  const {
    column: {  id },
  } = cellObj;
  const ref = useRef(null)
  const userAccess = useContext(UserAccessContext)
  const options = [
    { key: "lesser-than-0", value: "lesser than 0" },
    { key: "0-12wks", value: "0 to 12 weeks" },
    { key: "12-24wks", value: "12 to 24 weeks" },
    { key: "greater-than-24wks", value: "greater than 24 weeks" },
  ];
  useEffect(() => {
    let obj = {};
    obj[id] = ref;
    cellObj?.refObj.push(obj);
    cellObj?.setRefObj([...cellObj?.refObj]);
  }, [id]);
  // Render a multi-select box
  return (
    <select
      disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
      className="input-edit-mode btn-slim"
      ref={ref}
      onChange={(e) => {
        cellObj.filterData(e.target.value || undefined, id, true);
        if (e.target.value) {
          cellObj.refObj?.forEach((key) => {
            if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
              // if (key[Object.keys(key)[0]].current.state)
              //   key[Object.keys(key)[0]].current.state.value = "";
              // else {
              //   key[Object.keys(key)[0]].current.value = "";
              // }
            }
          });
        }
      }}

    >
      <option value="">Select</option>
      {options.map((option, i) => (
        <option key={i} value={option.key}>
          {option.value}
        </option>
      ))}
    </select>
  );
}

export const RiskDetailsEditCell = (cellObj) => {
  const { column, row, value, dealMonths } = cellObj.cell;
  const [isAddViewModalOpen, setAddViewModalOpen] = useState(false);
  const userAccess = useContext(UserAccessContext)
  const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);

  const [val, setVal] = useState(column.id === "expiry_date" ? "" : value);
  const [isSelection, setIsSelection] = useState(false);

  const handleCellChange = (e) => {
    if (e.target) {
      setVal(e.target.value);
      if (
        column.id === "local_deal_completion_month" ||
        column.id === "pack_type" ||
        column.id === "dealTypeId"
      ) {
        cellObj.updateCellData(row, column, e.target.value);
      }
    } else {
      setVal(e);
    }
  };

  const NumberField = () => {
    return (
      <input
        disabled={userAccess?.data[0]?.accessId =="1" ? true : false}
        type="number"
        min={0}
        autoFocus
        value={isSelection ? val : value}
        onChange={(e) => {
          setIsSelection(true);
  
          if (
            (column.id !== "local_deal_quantity_in_zun" &&
              column.id !== "central_deal_quantity_in_zun") ||
            ((column.id == "central_deal_quantity_in_zun" ||
              column.id == "local_deal_quantity_in_zun") &&
              ((row.original.wk13_wk26_Res_BlockedStockZun ?? 0) -
                (column.id == "local_deal_quantity_in_zun" ? (e.target.value ?? 0) : (row.original.local_deal_quantity_in_zun ?? 0)) -
                (column.id == "central_deal_quantity_in_zun" ? (e.target.value ?? 0) : (row.original.central_deal_quantity_in_zun ?? 0))) >=
              0)
          ) {
            handleCellChange(e);
          } 
  
          handleCellChange(e);
        }}
        onBlur={(e) => {
          if (
            (column.id !== "local_deal_quantity_in_zun" &&
              column.id !== "central_deal_quantity_in_zun") ||
            ((column.id == "central_deal_quantity_in_zun" ||
              column.id == "local_deal_quantity_in_zun") &&
              ((row.original.wk13_wk26_Res_BlockedStockZun ?? 0) -
                (column.id == "local_deal_quantity_in_zun" ? (e.target.value ?? 0) : (row.original.local_deal_quantity_in_zun ?? 0)) -
                (column.id == "central_deal_quantity_in_zun" ? (e.target.value ?? 0) : (row.original.central_deal_quantity_in_zun ?? 0))) >=
              0)
          ) {
            cellObj.updateCellData(row, column, e.target.value ?? 0);
          }
  
          cellObj.updateCellData(row, column, e.target.value ?? 0);
        }}
        onKeyDown={BlockInvalidChar}
      />
    );
  };
  

  const TextField = () => {
    return (
      <input
        disabled={userAccess?.data[0]?.accessId =="1" ? true : false}
        type="text"
        autoFocus
        value={val === null ? " " : val}
        onChange={handleCellChange}
        onBlur={() => cellObj.updateCellData(row, column, val)}
      />
    );
  };

  const DateSelection = () => {
    return (
      <DatePicker
        disabled={userAccess?.data[0]?.accessId =="1" ? true : false}
        portalId="root-portal"
        dateFormat="dd-MM-yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        autoFocus
        onBlur={() => cellObj.updateCellData(row, column, val, true)}
        onSelect={handleCellChange} //when day is clicked
        onChange={(date) => cellObj.updateCellData(row, column, date)} //only when value has changed
      />
    );
  };
  //
  const DealStatus = () => {
      console.log(cellObj)
    return (
      <select
        disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={(e) => {
          setIsSelection(true);
          handleCellChange(e);
        }}
        onBlur={(e) => {
          setIsSelection(true);
          cellObj.updateCellData(row, column, e.target.value, true);
        }}
        autoFocus
        value={isSelection ? val : val || value}
      >
        <option value="10" selected>
          Select
        </option>
        {cellObj.actions?.map((item) => {
          return <option value={item.id}>{item.action_name}</option>;
        })}
      </select>
    );
  };

  const StatusSelection = () => {
    return (
      <select
        disabled={userAccess?.data[0]?.accessId =="1" ? true : false}
        className="input-edit-mode width-100"
        onChange={(e) => {
          setIsSelection(true);
          handleCellChange(e);
        }}
        onBlur={(e) => {
          setIsSelection(true);
          cellObj.updateCellData(row, column, e.target.value, true);
        }}
        autoFocus
        value={isSelection ? val : val || value}
      >
        <option value="" selected>
          Select
        </option>
        {cellObj.dealMonths?.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };
  const PackTypeSelection = () => {
    console.log(cellObj.packTypeList)
    return (
      <select
        disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={(e) => {
          setIsSelection(true);
          handleCellChange(e);
        }}
        onBlur={(e) => {
          setIsSelection(true);
          cellObj.updateCellData(row, column, e.target.value, true);
        }}
        autoFocus
        value={isSelection ? val : val || value}
      >
        <option value="" selected>
          Select
        </option>
        {cellObj.packTypeList?.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };

  const customerAddition = () => {
    return <button type="button">sd</button>;
  };

  let FieldType;
  switch (column.id) {
    case "dealTypeId":
      FieldType = DealStatus;
      break;
    case "central_deal_quantity_in_zun":
      FieldType = NumberField;
      break;
    case "local_deal_quantity_in_zun":
      FieldType = NumberField;
      break;
    case "wk13_wk26_residual_zun":
      FieldType = NumberField;
      break;
    case "expiry_date":
      FieldType = DateSelection;
      break;
    case "view_history":
      FieldType = customerAddition;
      break;
    case "local_deal_completion_month":
      FieldType = StatusSelection;
      break;
    case "pack_type":
      FieldType = PackTypeSelection;
      break;
    default:
      FieldType = TextField;
  }
  const user = useContext(UserContext);
  return (
    <>
      {column.id === "bom" && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => setAddViewModalOpen(true)}
            title="View Bom"
          >
            View Bom
          </button>
          <BomDetails
            key={isAddViewModalOpen}
            show={isAddViewModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setAddViewModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
          />

        </>
      )}
      {column.id === "view_history" && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => setViewHistoryModalOpen(true)}
          >
            View History
          </button>
          {viewHistoryModalOpen &&<ViewHistory
            key={viewHistoryModalOpen}
            show={viewHistoryModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setViewHistoryModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
            materialNumberId={cellObj.cell.row.original.materialNumberId}
            countryId = {cellObj.cell.row.original.countryId}
            plantCodeId={cellObj.cell.row.original.plantCodeId}
            email={user.email}
            cellactions={cellObj.actions}
          />}

        </>
      )}
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="edit-cell">{FieldType()}</div>
        )) || (column.id !== "bom" &&
          <div
            className="readonly"
            title={
              value
                ? column.id === "dealTypeId"
                  ? cellObj.actions?.filter((i) => i.id == value)[0]
                    ?.action_name || ""
                  : value
                : "Click to edit cell value"
            }
            onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
            style={{ width: "100%", height: "100%" }}
          >
            {/* {val === "" ? value : val} */}
            {column.id === "expiry_date" && val
              ? Object.prototype.toString.call(val) === "[object Date]"
                ? moment(val).format("DD-MM-YYYY")
                : (column.id === "dealTypeId"
                  ? cellObj.actions?.filter((i) => i.id == value)[0]?.action_name || "" : column.id === "local_deal_completion_month" ||
                    column.id === "pack_type" || column.id == "central_deal_quantity_in_zun" ||
                    column.id == "local_deal_quantity_in_zun"
                    ? isSelection
                      ? val
                      : val
                    : val)
              : (column.id === "expiry_date" ? value : (column.id === "dealTypeId"
                ? cellObj.actions?.filter((i) => i.id == value)[0]?.action_name || "" : column.id === "local_deal_completion_month" ||
                  column.id === "pack_type" || column.id == "central_deal_quantity_in_zun" ||
                  column.id == "local_deal_quantity_in_zun"
                  ? isSelection
                    ? val
                    : val
                  : val))}
          </div>
        )}
    </>
  );
};

export const EditCell = (cellObj) => {
  const userAccess = useContext(UserAccessContext)

  const statusObj = {
    Accepted: "bg-teal",
    Rejected: "bg-red",
    Blank: "bg-blank",
  };
  const { column, row, value } = cellObj.cell;
  const [val, setVal] = useState(value);

  const handleCellChange = (e) => {
    if (e.target) setVal(e.target.value);
    else {
      setVal(e);
    }
  };

  const NumberField = () => {
    return (
      <div className="edit-cell">
        <input
          disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
          type="number"
          className="input-edit-mode"
          value={val === null ? " " : val}
          onChange={handleCellChange}
          onBlur={() => cellObj.updateCellData(row, column, val)}
          onKeyDown={BlockInvalidChar}
        />
      </div>
    );
  };

  const DateSelection = (cellObj) => {
    return (
      <DatePicker
        disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
        portalId="root-portal"
        dateFormat="dd-MM-yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        onChange={(date) => handleCellChange(date)}
        onBlur={(date) => cellObj.updateCellData(row, column, val)}
      />
    );
  };
  const StatusSelection = () => {
    return (
      <select
        disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        value={val}
        onBlur={() => cellObj.updateCellData(row, column, val)}
      >
        {cellObj.status.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };
  const TextField = () => {
    return (
      <div className="edit-cell">
        <input
          disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
          type="text"
          className="input-edit-mode"
          value={val === null ? " " : val}
          onChange={handleCellChange}
          onBlur={() => cellObj.updateCellData(row, column, val)}
        />
      </div>
    );
  };

  return (
    <>
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="td-active">
            {column.id === "central_deal_quantity_in_zun"
              ? NumberField()
              : column.id === "expiry_date"
                ? DateSelection(cellObj.cell)
                : column.id === "dealTypeId"
                  ? StatusSelection()
                  : TextField()}
          </div>
        )) ||
        (column.id === "deal_status" ? (
          <button
            type="button"
            className={`btn cursor-default btn-slim ${statusObj[cellObj.value]
              }`}
          >
            {cellObj.value}
          </button>
        ) : (
          <>
            <div
              className="readonly flex align-items-center"
              title={value ? value : "Click to edit cell value"}
              onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
              style={{ width: "100%", height: "100%" }}
            >
              {column.id === "expiry_date" && val
                ? Object.prototype.toString.call(val) === "[object Date]"
                  ? moment(val).format("DD-MM-YYYY")
                  : val
                : val}
            </div>
          </>
        ))}
    </>
  );
};


export const DecimalToNumRound = (cellObj) => {

  const { column, row, value } = cellObj.cell;

  const finalValue = value ? typeof (value) == 'string' ? (isNaN(value) ? value : parseInt(value)) : Math.round(value) : "0";

  return (
    <span title={finalValue}>
      {finalValue}
    </span>
  );
};
