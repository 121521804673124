import React, { useState, useEffect, useRef, useContext } from "react";
import Popup from "reactjs-popup";
import Grid from "@material-ui/core/Grid";
import PlusIcon from "../../../Assets/img/Plus.svg";
import Multiselect from "../../../Views/common/user-Management/Multiselect";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../../Application";
import { useForm, useWatch } from "react-hook-form";
import {
  regex_email,
  regex_letters_only,
  regex_number_only,
} from "../../../utility/constants";
import {
  createNotification,
  updateApprovalStatus,
  getUserInfo,
} from "../../../Services/CommonServices";
import {
  createCustomer,
  getCustDetails,
  updateCustomer,
} from "../../../Services/userManagementService";

const AddCustomers = (props) => {
  const user = useContext(UserContext);
  const userName = useRef(null);
  const ccPopup = useRef(null);
  const proceedRef = useRef(null);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    setValue,
    watch,
    reset,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      isValid,
      isSubmitted,
      submitCount,
    },
  } = useForm();
  const customerEmailTxt = watch("customerEmail", "");
  const [userApiData, setUserApiData] = useState([]);
  const [bg, setBg] = useState([]);
  const [mco, setMco] = useState([]);
  const [country, setCountry] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCustomerCreationModalOpen, setCustomerCreationModalOpen] =
    useState(false);
  const [myAccessInfo, setMyAccessInfo] = useState({});
  const [comName, setComName] = useState(" ");
  const [isUserAgreed, setUserAgreed] = useState(false);
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [isSettingsDDLoading, setSettingsDDLoading] = useState(false);
  const [
    isSetSaveLoaderInit__createCustomer,
    setSaveLoaderInit__createCustomer,
  ] = useState(false);
  const [
    isSetSaveLoaderInit__updateCustomer,
    setSaveLoaderInit__updateCustomer,
  ] = useState(false);

  const handleMultiSelectChange = (obj) => {
    let { businessGroup, mco, country } = obj.data;
    if (obj.current == "businessGroup") {
      if (businessGroup != undefined) {
        if (!businessGroup.length) {
          setValue("a_bg", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      console.log("businessGroup", businessGroup);
      setBg(businessGroup);
    }
    if (obj.current == "mco") {
      if (mco != undefined) {
        if (!mco.length) {
          setValue("a_mco", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      setMco(mco);
    }
    if (obj.current == "country") {
      if (country != undefined) {
        if (!country.length) {
          setValue("a_country", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue("a_country", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setCountry(country);
    }
  };

  const get_user_data = () => {
    getUserInfo(user.email).then((resp) => {
      setUserApiData(resp.data[0]);
      console.log("user & UserApiData", user, resp.data[0]);
    });
  };

  const closeDropDownFunc = () => {
    setCloseDropDown({
      key: Math.random(1000),
    });
  };

  useEffect(() => {
    if (user.email)
      setUserApiData(user);
  }, [user]);
  const getCustomerDetails = (val) => {
    let propMapper = { name: "customerName", email: "customerEmail" };
    getCustDetails(val)
      .then((res) => {
        if (res.data) {
          myAccessInfo["bussinessGroupId"] =
            res.data[0]?.permission.businessGroup?.map((i) => i.id ?? i);
          setValue(
            "a_bg",
            res.data[0]?.permission.businessGroup?.map((i) => i.id ?? i),
            { shouldValidate: true, shouldDirty: true }
          );
          setBg(res.data[0]?.permission.businessGroup?.map((i) => i));
          myAccessInfo["countryId"] = res.data[0]?.permission.country?.map(
            (i) => i.id ?? i
          );
          setValue(
            "a_country",
            res.data[0]?.permission.country?.map((i) => i.id ?? i),
            { shouldValidate: true, shouldDirty: true }
          );
          setCountry(res.data[0]?.permission.country?.map((i) => i));
          myAccessInfo["mcoId"] = res.data[0]?.permission.mco?.map(
            (i) => i.id ?? i
          );
          setValue(
            "a_mco",
            res.data[0]?.permission.mco?.map((i) => i.id ?? i),
            { shouldValidate: true, shouldDirty: true }
          );
          setMco(res.data[0]?.permission.mco?.map((i) => i));
          setMyAccessInfo({ ...myAccessInfo });
          Object.entries(res.data[0]).forEach(([name, value]) =>
            setValue(propMapper[name] ?? name, value)
          );
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (props.customerEmail) {
      if (!props.isCopyCustomer && props.isEditCustomer) {
        setModalOpen(true);
        getCustomerDetails(props.customerEmail);
      } else {
        setCustomerCreationModalOpen(true);
      }
    }
  }, [props.customerEmail]);

  const getCompanyName = () => {
    let email = customerEmailTxt.toString();
    console.log("email", email);
    if (email != "" || email != undefined) {
      let bb = email?.includes("@") ? email?.split("@") : email;
      if (bb != "" || bb != undefined) {
        let cnm2 = bb[1]?.includes(".") ? bb[1]?.split(".") : email;
        if (cnm2 != "" || cnm2 != undefined) {
          setComName(cnm2[0]);
        }
      }
    }
  };

  const get_form_val = () => {
    return getValues([
      "customerName",
      "contactPerson",
      "contactNumber",
      "customerEmail",
    ]);
  };

  const create_customer = () => {
    var create_customer_values = get_form_val();
    var f1 = create_customer_values[0];
    var f2 = create_customer_values[1];
    var f3 = create_customer_values[2];
    var f4 = create_customer_values[3];
    var f5 = comName;
    console.log("create_customer_values", create_customer_values);
    
    // --- PAYLOAD ------
    let customer_creation_payload = {
      name: f1,
      contactPerson: f2,
      contactNumber: f3,
      email: f4,
      createdBy: user.email,
      SupervisorEmail: user.email,
      status: "approved",
      companyName: f5,
      RoleId: 4,
      UserRole: "Customer",
      SuperVisorRoleId:
        user.userRole == "Admin"
          ? 2
          : user.userRole == "SuperAdmin"
          ? 1
          : user.userRole == "user"
          ? 3
          : 4,
      SuperVisorRole: user.userRole,
      SupervisorFirstName: user.firstName,
      SupervisorLastName: user.lastName,
    };

    setSaveLoaderInit__createCustomer(true);
    // Customer Creation Request --
    createCustomer(customer_creation_payload)
      .then((response) => {
        if (response.request.status != 200) {
          // Customer Creation error --
          let sccs = (
            <div className="tosterIcon">
              <div>{response?.response?.data?.message}</div>
            </div>
          );
          toast.error(sccs, {
            containerId: "A",
          });
        } else {
          setSaveLoaderInit__createCustomer(false);
          props.setIsEditCustomer(false);
          props.setIsCopyCustomer(false);
          props.setCustomerEmail("");
          setMyAccessInfo({});
          // Customer Creation Request success --
          var objectId = response?.data?.customer?.id;
          {
            // Customer created directly, whitout creating 'Notification', from backend --
            console.log("customer Response Handled", response);
            clearErrors();
            reset();
            setCustomerCreationModalOpen(false);
            setModalOpen(false);
            let sccs = "";
            if (response?.data?.customer?.status == "approved") {
              sccs = (
                <div className="tosterIcon">
                  <div>Customer created successfully.</div>
                </div>
              );
            } else if (response?.data?.customer?.status == "pending") {
              sccs = (
                <div className="tosterIcon">
                  <div>Customer creation is pending</div>
                </div>
              );
            } else {
              sccs = (
                <div className="tosterIcon">
                  <div>Error while creating customer.</div>
                </div>
              );
            }
            toast.success(sccs, {
              containerId: "A",
            });
          }
          return;
        }
      })
      .catch((err) => {
        // Customer Creation Request Error --
        console.log("error", err);
        let errMssgDefault = `Error occured, while customer creation`;
        let errMssg = err.message ?? errMssgDefault;
        let sccs = (
          <div className="tosterIcon">
            <div>{errMssg}</div>
          </div>
        );
        toast.error(sccs, {
          containerId: "A",
        });
      });
  };

  const edit_customer = () => {
    var edit_customer_values = get_form_val();
    var f1 = edit_customer_values[0];
    var f2 = edit_customer_values[1];
    var f3 = edit_customer_values[2];
    var f4 = edit_customer_values[3];
    var f5 = comName;

    let permission = {
      businessGroup: bg?.filter((i) => i.id !== "all"),
      mco: mco?.filter((i) => i.id !== "all"),
      country: country?.filter((i) => i.id !== "all"),
    };
    // --- PAYLOAD ------
    let customer_update_payload = {
      customerName: f1,
      contactPerson: f2,
      contactNumber: f3,
      customerEmail: f4,
      permission: permission,
      updatedBy: user.email,
    };

    setSaveLoaderInit__updateCustomer(true);
    // Customer Creation Request --
    updateCustomer(customer_update_payload)
      .then((response) => {
        if (response.request.status != 200) {
          // Customer Creation error --
          let sccs = (
            <div className="tosterIcon">
              <div>{response?.response?.data?.message}</div>
            </div>
          );
          toast.error(sccs, {
            containerId: "A",
          });
        } else {
          setSaveLoaderInit__updateCustomer(false);
          props.setIsEditCustomer(false);
          props.setIsCopyCustomer(false);
          props.setCustomerEmail("");
          setMyAccessInfo({});
          let sccs = (
            <div className="tosterIcon">
              <div>Customer updated successfully</div>
            </div>
          );
          toast.success(sccs, {
            containerId: "A",
          });
          setModalOpen(false);
          return;
        }
      })
      .catch((err) => {
        // Customer Creation Request Error --
        console.log("error", err);
        let errMssgDefault = `Error occured, while customer creation`;
        let errMssg = err.message ?? errMssgDefault;
        let sccs = (
          <div className="tosterIcon">
            <div>{errMssg}</div>
          </div>
        );
        toast.error(sccs, {
          containerId: "A",
        });
      });
  };
  const onSubmit = (data) => {
    //ddValidationCheck();
    if (isValid && !props.customerEmail) {
      create_customer();
    } else {
      if (props.isEditCustomer && isValid) {
        edit_customer();
      } else if (props.isCopyCustomer && isValid) {
        create_customer();
      }
    }
  };

  const onValid = () => {
    if (isValid) {
      create_customer();
    }
  };

  useEffect(() => {
    getCompanyName();
  }, [customerEmailTxt]);

  const checkUserAgree = () => {
    console.log("proceedRef", proceedRef.current.checked);
    setUserAgreed(proceedRef.current.checked);
  };

  const handleSettingsDDLoading = (o) => {
    setSettingsDDLoading(o);
  };

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />

      <button
        type="button"
        className={`btn-slim btn-blue`}
        onClick={() => setCustomerCreationModalOpen((o) => !o)}
      >
        <img src={PlusIcon} /> {"Create Customer"}
      </button>

      <Popup
        className="full-screen-cover size-md"
        open={isModalOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        modal
        onOpen={() => {
          setValue("custCompanyName", "");
          clearErrors();
          reset();
        }}
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />
          <div className="inside-overlay" onMouseDown={closeDropDownFunc}></div>
          <div
            id="ccp"
            ref={ccPopup}
            className="popup-header modal-header-raised d-flex align-items-center justify-content-between"
            onMouseDown={closeDropDownFunc}
          >
            <h5 className="mt-2">
              {" "}
              {props.isEditCustomer ? `Edit  Customer` : `Create Customer`}{" "}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                props.setCustomerEmail("");
                props.setIsEditCustomer(false);
                props.setIsCopyCustomer(false);
                setModalOpen(false);
                setMyAccessInfo({});
              }}
            ></button>
          </div>

          <div className="content h-100 modal-content-raised">
            <div className="scroll-content">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="h-100 d-flex flex-column justify-content-between"
                onMouseDown={closeDropDownFunc}
              >
                <div className="filterform_bg ddn-fluid">
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <div className="ddn">
                        <div>
                          <label>Customer Name</label>
                        </div>
                        <div>
                          <input
                            ref={userName}
                            type="text"
                            className="input-edit-mode w-100"
                            onMouseDown={closeDropDownFunc}
                            {...register("customerName", {
                              required: {
                                value: true,
                                message: "Customer Name is required.",
                              },
                              pattern: {
                                value: regex_letters_only,
                                message: "Customer Name is Invalid.",
                              },
                            })}
                          />
                        </div>
                        <div className="error-mssg">
                          {errors.customerName && (
                            <p key={errors.customerName.message}>
                              {errors.customerName.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="ddn">
                        <div>
                          <label>Contact Person</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="input-edit-mode w-100"
                            onMouseDown={closeDropDownFunc}
                            {...register("contactPerson", {
                              required: {
                                value: true,
                                message: "Contact Person is required.",
                              },
                              pattern: {
                                value: regex_letters_only,
                                message: "Contact Person is Invalid.",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="error-mssg">
                        {errors.contactPerson && (
                          <p key={errors.contactPerson.message}>
                            {errors.contactPerson.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="ddn">
                        <div>
                          <label>Contact Number</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="input-edit-mode w-100"
                            onMouseDown={closeDropDownFunc}
                            {...register("contactNumber", {
                              required: {
                                value: true,
                                message: "Contact Number is required.",
                              },
                              pattern: {
                                value: regex_number_only,
                                message: "Contact Number is Invalid.",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div className="error-mssg">
                        {errors.contactNumber && (
                          <p key={errors.contactNumber.message}>
                            {errors.contactNumber.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="ddn">
                        <div>
                          <label>Email</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="input-edit-mode w-100"
                            onMouseDown={closeDropDownFunc}
                            {...register("customerEmail", {
                              required: {
                                value: true,
                                message: "Email is required.",
                              },
                              pattern: {
                                value: regex_email,
                                message: "Email is Invalid.",
                              },
                            })}
                          />
                        </div>
                        <div id="error_email" className="error-mssg"></div>
                      </div>
                      <div className="error-mssg">
                        {errors.customerEmail && (
                          <p key={errors.customerEmail.message}>
                            {errors.customerEmail.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="ddn">
                        <div>
                          <label>Customer Company Name</label>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="input-edit-mode w-100 text-capitalize"
                            onMouseDown={closeDropDownFunc}
                            value={comName != null ? comName : ""}
                            {...register("custCompanyName")}
                          />
                        </div>
                      </div>
                      <div className="error-mssg">
                        {errors.custCompanyName && (
                          <p key={errors.custCompanyName.message}>
                            {errors.custCompanyName.message}
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-5">
                  <div></div>
                  <button
                    type="submit"
                    className={`btn btn-primary btn-std-width ${
                      isSetSaveLoaderInit__createCustomer ||
                      isSetSaveLoaderInit__updateCustomer
                        ? "processing"
                        : ""
                    }
                      `}
                  >
                    {props.isEditCustomer ? `Save & Continue` : `Create`}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      </Popup>
      <Popup
        className="size-sm fluid-height"
        open={isCustomerCreationModalOpen}
        closeOnDocumentClick={false}
        modal
        onOpen={() => {
          setUserAgreed(false);
        }}
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />
          <div className="popup-header  d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Customer Creation Guidelines</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                props.setCustomerEmail("");
                props.setIsEditCustomer(false);
                props.setIsCopyCustomer(false);
                setCustomerCreationModalOpen(false);
                setMyAccessInfo({});
              }}
            ></button>
          </div>
          <div className="content">
            <div className="scroll-content">
              <p>
                {" "}
                To include a new customer, you must click on the provided link
                and register them in the Unilever system. This action is
                mandatory, and failure to complete this step will result in the
                bidding list not being shared with the customer. Kindly mark the
                checkbox once you have finished the registration process.
              </p>
              <a
                className="text-blue"
                href="https://unilever.sharepoint.com/:w:/r/sites/PricingFactory561/Shared%20Documents/Customer%20Guidelines.docx?d=w1d77bb1e1dea4acf9b309c7a6d519b1c&csf=1&web=1&e=tuanDv"
                target="_blank"
                title="Open document in new tab"
              >
                Follow Customer Guidelines before adding customer
              </a>
              <div>
                <label className="d-flex align-items-center mt-3 cursor-pointer">
                  <div className="tablecheckbox mr-2">
                    <input
                      type="checkbox"
                      ref={proceedRef}
                      title="Toggle All Current Page Rows Selected"
                      className="cursor-pointer"
                      onChange={() => {
                        checkUserAgree();
                      }}
                    />
                    <div className="Checkbox-visible"></div>
                  </div>
                  <p className="m-0 note big">
                    I have read the above document and agree with it.
                  </p>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div></div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  title={"Proceed to customer creation"}
                  disabled={!isUserAgreed}
                  onClick={() => {
                    setModalOpen(true);
                    setCustomerCreationModalOpen(false);
                    if (props.isCopyCustomer) {
                      setTimeout(() => {
                        getCustomerDetails(props.customerEmail);
                      }, 100);
                    }
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
};
export default AddCustomers;
