import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import warningIcon from "../../Assets/Images/warning_icon.svg";

export const notify = ({ message, type, toastId }) => {
  switch (type) {
    case "warn":
      toast.warn(
        <div className="tosterIcon">
          {" "}
          {/* <img src={warningIcon} alt="" /> */} {message}
        </div>
      );
      break;
    case "success":
      toast.success(<div className="tosterIcon"> {message}</div>);
      break;
    case "error":
      toast.error(<div className="tosterIcon"> {message}</div>, {
        toastId: toastId,
      });
      break;
    case "info":
      toast.info(<div className="tosterIcon"> {message}</div>);
      break;
    default:
      toast(<div className="tosterIcon"> {message}</div>);
      break;
  }
};

const Toast = (props) => {
  return (
    <ToastContainer
      position={
        (props.position === undefined && "top-center") || props.position
      }
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Toast;
