export const COLUMNS = {
  assigned_to_central_deal_top_brands: {
    name: "Assigned to  Central Deal Top Brands",
    class: "yellow",
  },
  assigned_to_local_deal: {
    name: "Assigned to local Deal",
    class: "blue",
  },
  assigned_to_central_deal_ui: {
    name: "Assigned to Central Deal - UI",
    class: "blue_green",
  },
  central_deal_completed: { name: "Central Deal Completed", class: "purple" },
  central_deal_rejected: { name: "Central Deal Rejected", class: "pink" },
  eligible_for_central_deal: {
    name: "Eligible for Central Deal",
    class: "green",
  },
  scrap: {
    name: "Scrap",
    class: "green",
  },
  natural_sell_off: {
    name: "Natural Sell off",
    class: "green",
  },
   
};
