import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
//import axios from "axios";
import axiosService from "../../utils/apiService";
//import axios from '../../utility/axiosInstance'
import apiEndPoints from "../../utils/apiEndPoints";
import ULDropdown from "../../Common/Dropdown";
import Loadersml from "../../Common/loadersml";
import { ArrayToObject } from "../../utility/helper";
import { useLocation } from "react-router-dom";
import { DropDownContext, UserAccessContext, UserContext, UserSavePreference } from "../../Components/Application";
import { getUserPermissionDetails } from "../../Services/userManagementService";
import Confirm from "./Confirm";
// import Confirm from "./Confirm";
const Dropdown = (props) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownInfo, setDropDownInfo] = useState([]);
  // const [userAccess,setUserAccess]= useState({})
  const userAccess = useContext(UserAccessContext)
  const user = useContext(UserContext)
  const userpreference = useContext(UserSavePreference)
  const [isConfirmOpened, openConfirm] = useState(false);
  const [dropdownPreference,setDropdownPreference] = useState(JSON.parse(window.sessionStorage.getItem("preferences"))?.preferenceData);
  // useEffect(()=>{
  //   getUserPermissionDetails({"UserEmail":"access@test.com"}).then(response=>setUserAccess(response.data.data))
  // },[])
  // const [selection, setSelection] = useState({});
  const { setDropDownValues: setSelection, dropDownValues: selection } = useContext(DropDownContext);
  console.log(selection)

  useEffect(()=>{
    if (userAccess) {
      if(props.dropDownPreference){
        getDropDownsData(props.dropDownPreference);
      }
      // props.setReload?.(false)
    }
  },[userAccess])
  
  useEffect(()=>{
    console.log(dropdownPreference)
    props.setDropDownValues(getRemoveAll({...dropdownPreference}))
    setSelection(dropdownPreference)
    getDropDownsData(getRemoveAll({...dropdownPreference}))
  },[dropdownPreference])
  useEffect(() => {
    if (props.reload) {
      if(props.publishdropdownReload){
        getDropDownsData(selection)
      }
      else if(JSON.parse(window.sessionStorage.getItem("preferences")).preferenceData){
        getDropDownsData(JSON.parse(window.sessionStorage.getItem("preferences")).preferenceData)
      }else{
        getDropDownsData();
      }
      
      props.setReload?.(false)
    }
  }, [props.reload,userAccess]);
  const setDropdownvaluesGlobal=(data1,data2)=>{
    if(data1){
      if(data1.length===0){
        return(data2)
      }
      else{
        return(data1)
      }
    }
    else{return(data2)}

 }
 

  const getDropDownsData = async (data) => {
    console.log(data)
    props.setDropDownPreference?.(data)
 
    setIsLoading(true)
    let obj = location.pathname.includes("inflow")
      ? {
        brandId: setDropdownvaluesGlobal(data?.brand ,userAccess?.inflowdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(data?.country ,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(data?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(data?.businessGroup ,userAccess?.inflowdata?.data?.bussinessGroupId),
        actiontypeId: data?.actiontype ?? [],
        plantCodeId: data?.plant ?? [],
        riskTypeId: data?.riskType ?? [],
        riskLevelId:data?.riskLevel ??[],
        forecastbiasL12Id:data?.forecastbiasL12w ??[],
        forecastbiasL4Id:data?.forecastbiasL4w ??[],
        foodSolutionIndicatorId: data?.fsIndicator ?? [],
        forecastFamilyId:data?.forecastfamily??[],
        // Forecast family", "Food solution

      }
      : location.pathname.includes("pom")
        ? {
          plantCodeId: data?.plant ?? [],
          countryId:setDropdownvaluesGlobal( data?.country ,userAccess?.pomdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(data?.category ,userAccess?.pomdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(data?.businessGroup ,userAccess?.pomdata?.data?.bussinessGroupId),
          alertTypeId: data?.alert ?? [],
          ownerId: data?.owner ?? [],
          nextStepId: data?.nextStep?.filter((i)=>i!=="111") ?? [],
          foodSolutionIndicatorId: data?.foodSolution ?? [],
          mrpControllerId: data?.mrpc ?? [],
          forecastFamilyId:data?.forecastFamily??[],
          reportingFamilyId: data?.reporting_family??[]

        }
        : {
          brandId: setDropdownvaluesGlobal(data?.brand ,userAccess?.fgSLOBdata?.data?.brandId),
          countryId: setDropdownvaluesGlobal(data?.country ,userAccess?.fgSLOBdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(data?.category ,userAccess?.fgSLOBdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(data?.businessGroup ,userAccess?.fgSLOBdata?.data?.bussinessGroupId),
          dealTypeId: data?.dealType ?? [],
          materialNumberId: data?.materialNumber ?? [],
          mcoId: setDropdownvaluesGlobal(data?.mco ,userAccess?.fgSLOBdata?.data?.mcoId),
          plant_code: data?.plant ?? [],
          residualStatusId: data?.residualStatus ?? [],
          languageOnPack:
          data?.languageOnPack ?? [],
        };
    const resolt = await axiosService.post(
      location.pathname.includes("inflow")
        ? apiEndPoints.inflowGlobalFilters
        : location.pathname.includes("pom")
          ? apiEndPoints.pomGlobalFilters
          : apiEndPoints.globalFilters,
      obj ?? {}
    );
    setIsLoading(false)
    const result =resolt?.data?.data
    // userAccess-Managment 
    
    // console.log(result)   
    console.log(userAccess) 
    let dumbg
    let updatedpermissionFilters
    if( location.pathname.includes("risksummary")||location.pathname.includes("riskdetails")||location.pathname.includes("dealcalendar")||location.pathname.includes("dealmanagement")){//!userAccess.fgSLOBdata.data.length
      console.log( result.businessGroup.filter(i=>userAccess?.fgSLOBdata.data.bussinessGroupId?.includes(i.key)))
      dumbg =  result.businessGroup.filter(i=>userAccess?.fgSLOBdata.data.bussinessGroupId?.includes(i.key))
      console.log(dumbg)
      updatedpermissionFilters ={
      ...result,
      brand:result.brand?.filter(i=>userAccess?.fgSLOBdata.data.brandId?.includes(i.key)),
      category:result.category?.filter(i=>userAccess?.fgSLOBdata.data.categoryId?.includes(i.key)),
      businessGroup:dumbg,
      mco:result.mco?.filter(i=>userAccess?.fgSLOBdata.data.mcoId?.includes(i.key)),
      country:result.country?.filter(i=>userAccess?.fgSLOBdata.data.countryId?.includes(i.key))
    }
    console.log(updatedpermissionFilters)
    }
    if( location.pathname.includes("inflowSummary")||location.pathname.includes("inflow")){//!userAccess.fgSLOBdata.data.length
      console.log( result.businessGroup.filter(i=>userAccess?.inflowdata.data.bussinessGroupId?.includes(i.key)))
      dumbg =  result.businessGroup.filter(i=>userAccess?.inflowdata.data.bussinessGroupId?.includes(i.key))
      console.log(dumbg)
      updatedpermissionFilters ={
      ...result,
      brand:result.brand?.filter(i=>userAccess?.inflowdata.data.brandId?.includes(i.key)),
      category:result.category?.filter(i=>userAccess?.inflowdata.data.categoryId?.includes(i.key)),
      businessGroup:dumbg,
      mco:result.mco?.filter(i=>userAccess?.inflowdata.data.mcoId?.includes(i.key)),
      country:result.country?.filter(i=>userAccess?.inflowdata.data.countryId?.includes(i.key))
    }
    console.log(updatedpermissionFilters)
    }
    if( location.pathname.includes("pomsummary")||location.pathname.includes("pom")){//!userAccess.fgSLOBdata.data.length
      console.log(userAccess,"userAccess")
      console.log( result.businessGroup.filter(i=>userAccess?.pomdata.data.bussinessGroupId?.includes(i.key)))
      dumbg =  result.businessGroup.filter(i=>userAccess?.pomdata.data.bussinessGroupId?.includes(i.key))
      console.log(dumbg)
      updatedpermissionFilters ={
      ...result,
      // brand:result.brand?.filter(i=>userAccess?.pomdata.data.brandId?.includes(i.key)),
      category:result.category?.filter(i=>userAccess?.pomdata.data.categoryId?.includes(i.key)),
      businessGroup:dumbg,
      // mco:result.mco?.filter(i=>userAccess?.pomdata.data.mcoId?.includes(i.key)),
      country:result.country?.filter(i=>userAccess?.pomdata.data.countryId?.includes(i.key))
    }
    console.log(updatedpermissionFilters)
    }
    // let dumbg = result.businessGroup.filter(i=>userAccess?.bussinessGroupId?.includes(i.key))
    // console.log(dumbg)
    // const updatedpermissionFilters ={
    //   ...result,
    //   businessGroup:dumbg,
    //   mco:result.mco?.filter(i=>userAccess?.mcoId?.includes(i.key)),
    //   country:result.country?.filter(i=>userAccess?.countryId?.includes(i.key))
    // }
    // console.log(updatedpermissionFilters)
    setDropDownInfo(user?.userRole==="SuperAdmin"?result:updatedpermissionFilters);
    // setDropDownInfo(result);
    // console.log("RESULT >>> ", resolt);
    props.setDealList?.(resolt?.data?.data.dealType);
     props.setFilterOptions && props.setFilterOptions(updatedpermissionFilters);//resolt?.data?.data
    // props.setFilterOptions && props.setFilterOptions(result);
  };

  const changeSelection = (prop, value) => {
    selection[prop] = [];
    if (value) {
      selection[prop] = selection[prop] ?? [];
      selection[prop].push(value);
      setSelection({ ...selection });
    } else {
      selection[prop] = [];
      setSelection({ ...selection });
    }
    props.onChange?.(selection);
  };
  const handleSavePreference=()=>{
    openConfirm(true)
    props.handleSavePreference()
  }
  
  useEffect(()=>{console.log(props.dropDownPreference)},[props.dropDownPreference])
  const getRemoveAll = (selectedFilterData) => {
    for (var key in selectedFilterData) {
      selectedFilterData[key] = selectedFilterData[key].filter(
        (i) => i !== "all"
      );
    }
    console.log(selectedFilterData)
    return selectedFilterData;
  };
  const handleChange =
    (name, options = null) =>
      (selected, action) => {
        console.log(name,options,selected,action)
        let selectedFilterData;
        if (action?.option?.value === "all") {
          //Not use now
          if (action.action === "select-option") {
            selectedFilterData = {
              ...selection,
              [name]: [...options.map((item) => item.value), "all"],
            };
            setSelection(selectedFilterData);
           
            setDropdownPreference((prev)=>{return({...prev,...selectedFilterData})})
            props.setDropDownValues(getRemoveAll({ ...selectedFilterData }));
            getDropDownsData(getRemoveAll({ ...selectedFilterData }));
          } else {
            //deselect-option
            selectedFilterData = {
              ...selection,
              [name]: [],
            };
            setSelection(selectedFilterData);
            setDropdownPreference((prev)=>{return({...prev,...selectedFilterData})})
            props.setDropDownValues(getRemoveAll({ ...selectedFilterData }));
            getDropDownsData(getRemoveAll({ ...selectedFilterData }));
          }
        } else {
          selectedFilterData = {
            ...selection,
            [name]:
              action.action !== "select-option"
                ? selected.map((item) => item.value).filter((i) => i !== "all")
                : selected.length === options.length
                  ? ["all"].concat(selected.map((item) => item.value))
                  : selected.map((item) => item.value),
          };
          console.log(selectedFilterData)
          console.log({...dropdownPreference,...selectedFilterData})
          setDropdownPreference((prev)=>{return({...prev,...selectedFilterData})})
          setSelection(selectedFilterData);
          props.setDropDownValues(getRemoveAll({ ...selectedFilterData }));
          getDropDownsData(getRemoveAll({ ...selectedFilterData }));
        }
      };
      
  const removeSelection = (name, values) => {
    
    const selectedFilterData = {
      ...selection,
      [name]: [],
    };
    console.log(selectedFilterData)
    setDropdownPreference(selectedFilterData)
    setSelection(selectedFilterData);
    props.setDropDownValues(getRemoveAll({ ...selectedFilterData }));
    getDropDownsData(getRemoveAll({ ...selectedFilterData }));
  };
  useEffect(()=>{
    console.log(dropdownPreference)
    // console.log(props.dropDownValues)
  },[dropdownPreference])
  /* const handleRadioChange = (name) => (selected) => {
    let upselectedSelectedFilter = Object.entries(selection).map((item) => {
      if (item[0] === name) {
        item[1] = [selected.value];
      }
      return item;
    });

    let upselectedselectedFilterObj = ArrayToObject(upselectedSelectedFilter);

    setSelection(upselectedselectedFilterObj);
    props.setDropDownValues(upselectedselectedFilterObj);
  }; */

  const businessGroup = _.has(dropdownInfo, "businessGroup")
    ? dropdownInfo["businessGroup"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => location.pathname.includes("pom") ? (element.label !== "POSM" && element.label !== "NA") : element)
    : [];

  const mco = _.has(dropdownInfo, "mco")
    ? dropdownInfo["mco"]?.map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const country = _.has(dropdownInfo, "country")
    ? dropdownInfo["country"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const category = _.has(dropdownInfo, "category")
    ? dropdownInfo["category"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null)
    : [];

  const brand = _.has(dropdownInfo, "brand")
    ? dropdownInfo["brand"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const nextStep = _.has(dropdownInfo, "nextStep") ? dropdownInfo["nextStep"].map((item) => ({
    value: item.key===""?"111":item.key,
    label: item.value==="BLANK"?"Blank":item.value,
  })).filter((element) => {
    return (
      element.value !== null &&
      // element.value !== "" &&
      element.label !== null &&
      element.label !== ""
    );
  })
    : [];
    // console.log(nextStep)

    const foodSolution = _.has(dropdownInfo, "foodSolution") ? dropdownInfo["foodSolution"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    })
      : [];

      const fsIndicator = _.has(dropdownInfo, "fsIndicator") ? dropdownInfo["fsIndicator"].map((item) => ({
        value: item.key,
        label: item.value,
      })).filter((element) => {
        return (
          element.value !== null &&
          element.value !== "" &&
          element.label !== null &&
          element.label !== ""
        );
      })
        : [];

  const actiontype = _.has(dropdownInfo, "actiontype") ? dropdownInfo["actiontype"].map((item) => ({
    value: item.key,
    label: item.value,
  })).filter((element) => {
    return (
      element.value !== null &&
      element.value !== "" &&
      element.label !== null &&
      element.label !== ""
    );
  })
    : [];

    const riskLevel = _.has(dropdownInfo, "riskLevel") ? dropdownInfo["riskLevel"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const riskType = _.has(dropdownInfo, "riskType") ? dropdownInfo["riskType"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const forecastbiasL12w = _.has(dropdownInfo, "forecastbiasL12w") ? dropdownInfo["forecastbiasL12w"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const forecastbiasL4w = _.has(dropdownInfo, "forecastbiasL4w") ? dropdownInfo["forecastbiasL4w"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const forecastFamily = _.has(dropdownInfo, "forecastFamily") ? dropdownInfo["forecastFamily"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const forecastfamily = _.has(dropdownInfo, "forecastfamily") ? dropdownInfo["forecastfamily"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];

    const reporting_family = _.has(dropdownInfo, "reporting_family") ? dropdownInfo["reporting_family"].map((item) => ({
      value: item.key,
      label: item.value,
    })).filter((element) => {
      return (
        element.value !== null &&
        element.value !== "" &&
        element.label !== null &&
        element.label !== ""
      );
    }): [];
      

  const residualStatus = _.has(dropdownInfo, "residualStatus")
    ? dropdownInfo["residualStatus"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];
    const owner = _.has(dropdownInfo, "owner")
    ? dropdownInfo["owner"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];


  const plant = _.has(dropdownInfo, "plantCode")
    ? dropdownInfo["plantCode"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];
  const alert = _.has(dropdownInfo, "alertType")
    ? dropdownInfo["alertType"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const materialNumber = _.has(dropdownInfo, "materialNumber")
    ? dropdownInfo["materialNumber"].map((item) => ({
      value: item.key,
      label: item.value,
    }))
    : [];

  const dealType = _.has(dropdownInfo, "dealType")
    ? dropdownInfo["dealType"]
      .map((item) => ({
        value: item.key,
        label: item.value==""?"BLANK":item.value,
      }))
      .filter((element) => element.value !== null)
    : [];

  const languageOnPack = _.has(dropdownInfo, "languageOnPack")
    ? dropdownInfo["languageOnPack"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null && element.value !== "")
    : [];

  const mrpc = _.has(dropdownInfo, "mrpController")
    ? dropdownInfo["mrpController"]
      .map((item) => ({
        value: item.key,
        label: item.value,
      }))
      .filter((element) => element.value !== null && element.value !== "")
    : [];
// useEffect(()=>{
//   getDropDownsData(JSON.parse(window.sessionStorage.getItem("preferences")).preferenceData)
// },[])
const [clearr,setclearr] = useState(false)
 const clearFilters=()=>{
  setclearr(true)
  setSelection([])
  getDropDownsData()
  setDropdownPreference({
    alert:[],
  businessGroup:[],
  category:[],
  country:[],
  foodSolution:[],
  forecastFamily:[],
  mrpc:[],
  nextStep:[],
   plant:[],
  reporting_family:[],
  })
  props.setDropDownValues({
    alert:[],
  businessGroup:[],
  category:[],
  country:[],
  foodSolution:[],
  forecastFamily:[],
  mrpc:[],
  nextStep:[],
   plant:[],
  reporting_family:[],
  })

}
  const getValue = (selection, values,dddp) => {
  //  console.log( JSON.parse(window.sessionStorage.getItem("preferences")))
    // console.log(selection,values,dddp)
    const funcc = (dat1,dat2)=>{
      // console.log( dat1,dat2)
      if(dat1[dat2]){
        // console.log(dat1[dat2])
        return(dat1[dat2])
      }
      else{
        // console.log([])
        return([])
      }
      
    }
    let newSelection
    // console.log(clearr)
    if(clearr){
      // console.log()
      // setSelection([])
      newSelection = [];
      // setclearr(false)
    }
    else{
      newSelection = selection?.length>0?[]:funcc(dropdownPreference,dddp);
    }
    // if(dropdownPreference.length>0)
    // let newSelection = selection?.length>0?[]:funcc(dropdownPreference,dddp);
    // let newSelection = [];
    selection.filter((val) => {
      values.forEach((value) => {
        if (value.value == val) {
          newSelection.push(val);
        }
      });
    });
    return newSelection?.length === values.length
      ? ['all'].concat(newSelection)
      : newSelection;
  };

  const handleSetCloseConfirm = (state) => {
    console.log("handleSetCloseConfirm",state)
    openConfirm(state)
  }

   

  const getOptions = (ddlInfo, prop) => {
    console.log(prop,ddlInfo, _.uniq(ddlInfo[prop]))
    return _.has(ddlInfo, prop)
      ? _.uniq(ddlInfo[prop])
          .map((item) => ({
            value: prop==="nextStep"? item.key===""?"111":item.key:item.key,
            label:
              prop === "dealType"
                ? item.value == ""
                  ? "BLANK"
                  : item.value
                : item.value,
          }))
          .filter((element) => {
            return prop === "dealType"
              ? element.value !== null &&
                  element.value !== "" &&
                  element.label !== null
              : element.value !== null &&
                  element.value !== "" &&
                  element.label !== null &&
                  element.label !== "";
          })
      : [];
  };

  React.useEffect(() => {
    // props.setDropDownValues(getRemoveAll({ ...selection }));
    if(JSON.parse(window.sessionStorage.getItem("preferences")).preferenceData){
      getDropDownsData(JSON.parse(window.sessionStorage.getItem("preferences")).preferenceData)
    }else{
      getDropDownsData(getRemoveAll({ ...selection }));
    }

  }, []) 

  useEffect(()=>{
console.log(props.dropDownValues)
  },[props.dropDownValues])
  return (
    <>
      <div className="main-content main-body mb-2">
        <div className="row filterform_bg">
          {/* <div className="flex align-items-end justify-content-between"> */}
            <Grid container spacing={2} className="filter-row">
              {(!props.list?.length ||
                props.list?.includes("Business Group")) && (
                  <Grid item>
                    <div>
                      <label className="d-flex">
                        Business Group {isLoading && <Loadersml />}
                      </label>
                    </div>
                    {/* <select
                    onChange={(ev) => {
                      changeSelection("businessGroup", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.businessGroup?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                    <ULDropdown
                      name="businessGroup"
                      defaultValue={['3','5']}
                      value={getValue(
                        selection?.businessGroup ? selection?.businessGroup : [],
                        businessGroup,"businessGroup"

                      )}
                      options={getOptions(dropdownInfo, "businessGroup").filter(
                        (element) =>
                          location.pathname.includes("pom")
                            ? element.label !== "POSM" && element.label !== "NA"
                            : element
                      )}
                      onChange={handleChange("businessGroup", businessGroup)}
                      isMulti={true}
                      placeholder={"All"}
                      isOpen={true}
                      className="react-select-container"
                      removeSelection={removeSelection}
                      isLoading={isLoading}
                    />
                  </Grid>
                )}
                 {props.list?.includes("Food Solution Indicator") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Food Solution Indicator {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="foodSolution"
                    value={getValue(
                      selection?.foodSolution ? selection?.foodSolution : [],
                      foodSolution ,"foodSolution"
                    )}
                    options={getOptions(dropdownInfo, "foodSolution")}
                    onChange={handleChange("foodSolution", foodSolution)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("FoodSolutionIndicator") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Food Solution Indicator {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="fsIndicator"
                    value={getValue(
                      selection?.fsIndicator ? selection?.fsIndicator : [],
                      fsIndicator,"fsIndicator" 
                    )}
                    options={getOptions(dropdownInfo, "fsIndicator")}
                    onChange={handleChange("fsIndicator", fsIndicator)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {(!props.list?.length || props.list?.includes("MCO")) && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      MCO {isLoading && <Loadersml />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("mco", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.mco?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="mco"
                    value={getValue(selection?.mco ? selection?.mco : [], mco,"mco")}
                    options={getOptions(dropdownInfo, "mco")}
                    onChange={handleChange("mco", mco)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {(!props.list?.length || props.list?.includes("Country")) && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Country {isLoading && <Loadersml />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("country", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.country?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="country"
                    value={getValue(
                      selection?.country ? selection?.country : [],
                      country,"country"
                    )}
                    options={getOptions(dropdownInfo, "country")}
                    onChange={handleChange("country", country)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Plant Code") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Plant Code {isLoading && <Loadersml />}
                    </label>
                  </div>
                  <ULDropdown
                    name="plant"
                    value={getValue(
                      selection?.plant ? selection?.plant : [],
                      plant,"plant"
                    )}
                    options={getOptions(dropdownInfo, "plantCode")}
                    onChange={handleChange("plant", plant)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {(!props.list?.length || props.list?.includes("Category")) && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Category {isLoading && <Loadersml />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("category", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.category?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="category"
                    value={getValue(
                      selection?.category ? selection?.category : [],
                      category,"category"
                    )}
                    options={getOptions(dropdownInfo, "category")}
                    onChange={handleChange("category", category)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {(!props.list?.length || props.list?.includes("Brand")) && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Brand {isLoading && <Loadersml />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("brand", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.brand?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="brand"
                    value={getValue(
                      selection?.brand ? selection?.brand : [],
                      brand,"brand"
                    )}
                    options={getOptions(dropdownInfo, "brand")}
                    onChange={handleChange("brand", brand)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {(!props.list?.length ||
                props.list?.includes("Residual Status")) && (
                  <Grid item>
                    <div>
                      <label className="d-flex">
                        Residual Status {isLoading && <Loadersml />}
                      </label>
                    </div>
                    {/* <select
                    onChange={(ev) => {
                      changeSelection("residualStatus", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.residualStatus?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                    <ULDropdown
                      name="residualStatus"
                      value={getValue(
                        selection?.residualStatus
                          ? selection?.residualStatus
                          : [],
                        residualStatus,"residualStatus"
                      )}
                      options={getOptions(dropdownInfo, "residualStatus")}
                      onChange={handleChange("residualStatus", residualStatus)}
                      isMulti={true}
                      placeholder={"All"}
                      className="react-select-container"
                      removeSelection={removeSelection}
                      isLoading={isLoading}
                    />
                  </Grid>
                )}
                {(!props.list?.length ||
                props.list?.includes("Owner")) && (
                  <Grid item>
                    <div>
                      <label className="d-flex">
                        Owner {isLoading && <Loadersml />}
                      </label>
                    </div>
                    {/* <select
                    onChange={(ev) => {
                      changeSelection("residualStatus", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.residualStatus?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                    <ULDropdown
                      name="owner"
                      value={getValue(
                        selection?.owner
                          ? selection?.owner
                          : [],
                        owner,"owner"
                      )}
                      options={getOptions(dropdownInfo, "owner")}
                      onChange={handleChange("owner", owner)}
                      isMulti={true}
                      placeholder={"All"}
                      className="react-select-container"
                      removeSelection={removeSelection}
                      isLoading={isLoading}
                    />
                  </Grid>
                )}

              {/* {(!props.list?.length ||
                props.list?.includes("Material Number")) && (
                <Grid item>
                  <div>
                    <label>Material Number</label>
                  </div>
                  <ULDropdown
                    name="materialNumber"
                    value={
                      selection?.materialNumber ? selection?.materialNumber : []
                    }
                    options={materialNumber}
                    onChange={handleChange("materialNumber", materialNumber)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                  />
                </Grid>
              )} */}

              {(!props.list?.length || props.list?.includes("Deal Type")) && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Deal Type {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("dealType", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.dealType?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="dealType"
                    value={getValue(
                      selection?.dealType ? selection?.dealType : [],
                      dealType,"dealType"
                    )}
                    options={getOptions(dropdownInfo, "dealType")}
                    onChange={handleChange("dealType", dealType)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {/* <Grid item>
                <div>
                  <label>Business unit</label>
                </div>
                <select
                  onChange={(ev) => {
                    changeSelection("businessUnit", ev.target.value);
                  }}
                >
                  <option value="">Select one</option>
                  <option value="vegetable">Vegetable</option>
                  <option value="meat">Meat</option>
                </select>
              </Grid>

              <Grid item>
                <div>
                  <label>Filter Name</label>
                </div>
                <select
                  onChange={(ev) => {
                    changeSelection("filterName", ev.target.value);
                  }}
                >
                  <option value="">Select one</option>
                  <option value="vegetable">Vegetable</option>
                  <option value="meat">Meat</option>
                </select>
              </Grid> */}
              {props.list?.includes("Stock per Country") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Stock per Country{" "}
                      {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  {/* <select
                    onChange={(ev) => {
                      changeSelection("country", ev.target.value);
                    }}
                  >
                    <option value="">Select one</option>
                    {dropdownInfo?.country?.map((item) => {
                      return <option value={item.key}>{item.value}</option>;
                    })}
                  </select> */}
                  <ULDropdown
                    name="country"
                    value={getValue(
                      selection?.country ? selection?.country : [],
                      country,"country"
                    )}
                    options={getOptions(dropdownInfo, "country")}
                    onChange={handleChange("country", country)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {props.list?.includes("Alert Type") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Alert Type {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  <ULDropdown
                    name="alert"
                    value={getValue(
                      selection?.alert ? selection?.alert : [],
                      alert,"alert"
                    )}
                    options={getOptions(dropdownInfo, "alertType")}
                    onChange={handleChange("alert", alert)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {props.list?.includes("Next Step") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Next Step {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="nextStep"
                    value={getValue(
                      selection?.nextStep ? selection?.nextStep : [],
                      nextStep,"nextStep"
                    )}
                    options={getOptions(dropdownInfo, "nextStep")}
                    onChange={handleChange("nextStep", nextStep)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {props.list?.includes("Action Type") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                     Action Type {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="actiontype"
                    value={getValue(
                      selection?.actiontype ? selection?.actiontype : [],
                      actiontype,"actiontype" 
                    )}
                    options={getOptions(dropdownInfo, "actiontype")}
                    onChange={handleChange("actiontype", actiontype)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Risk Type") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                     Risk Type {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="riskType"
                    value={getValue(
                      selection?.riskType ? selection?.riskType : [],
                      riskType,"riskType" 
                    )}
                    options={getOptions(dropdownInfo, "riskType")}
                    onChange={handleChange("riskType", riskType)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("DOH vs ISL Risk Level") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    DOH vs ISL Risk Level {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="riskLevel"
                    value={getValue(
                      selection?.riskLevel ? selection?.riskLevel : [],
                      riskLevel,"riskLevel" 
                    )}
                    options={getOptions(dropdownInfo, "riskLevel")}
                    onChange={handleChange("riskLevel", riskLevel)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Forecast Bias L12") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Forecast Bias L12 {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="forecastbiasL12w"
                    value={getValue(
                      selection?.forecastbiasL12w ? selection?.forecastbiasL12w : [],
                      forecastbiasL12w,"forecastbiasL12w" 
                    )}
                    options={getOptions(dropdownInfo, "forecastbiasL12w")}
                    onChange={handleChange("forecastbiasL12w", forecastbiasL12w)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Forecast Bias L4") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Forecast Bias L4 {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="forecastbiasL4w"
                    value={getValue(
                      selection?.forecastbiasL4w ? selection?.forecastbiasL4w : [],
                      forecastbiasL4w,"forecastbiasL4w" 
                    )}
                    options={getOptions(dropdownInfo, "forecastbiasL4w")}
                    onChange={handleChange("forecastbiasL4w", riskLevel)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}

              {props.list?.includes("Forecast Family") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Forecast Family {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="forecastFamily"
                    value={getValue(
                      selection?.forecastFamily ? selection?.forecastFamily : [],
                      forecastFamily,"forecastFamily" 
                    )}
                    options={getOptions(dropdownInfo, "forecastFamily")}
                    onChange={handleChange("forecastFamily", forecastFamily)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Forecast family") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                    Forecast Family {isLoading && <Loadersml />}
                    </label>
                  </div>

                  <ULDropdown
                    name="forecastfamily"
                    value={getValue(
                      selection?.forecastfamily ? selection?.forecastfamily : [],
                      forecastfamily,"forecastfamily" 
                    )}
                    options={getOptions(dropdownInfo, "forecastfamily")}
                    onChange={handleChange("forecastfamily", forecastfamily)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              

              {props.list?.includes("Language on Pack") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Language on Pack{" "}
                      {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  <ULDropdown
                    name="languageOnPack"
                    value={getValue(
                      selection?.languageOnPack
                        ? selection?.languageOnPack
                        : [],
                      languageOnPack,"languageOnPack"
                    )}
                    options={getOptions(dropdownInfo, "languageOnPack")}
                    onChange={handleChange("languageOnPack", languageOnPack)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("MRP controller") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      MRP Controller{" "}
                      {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  <ULDropdown
                    name="mrpc"
                    value={getValue(
                      selection?.mrpc
                        ? selection?.mrpc
                        : [],
                      mrpc,"mrpc"
                    )}
                    options={getOptions(dropdownInfo, "mrpController")}
                    onChange={handleChange("mrpc", mrpc)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
              {props.list?.includes("Reporting Family") && (
                <Grid item>
                  <div>
                    <label className="d-flex">
                      Reporting Family{" "}
                      {isLoading && <Loadersml className="ml-2" />}
                    </label>
                  </div>
                  <ULDropdown
                    name="reporting_family"
                    value={getValue(
                      selection?.reporting_family
                        ? selection?.reporting_family
                        : [],
                      reporting_family,"reporting_family"
                    )}
                    options={getOptions(dropdownInfo, "reporting_family")}
                    onChange={handleChange("reporting_family", reporting_family)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                  />
                </Grid>
              )}
            </Grid>
            
           {props.showPrefe && <div style={{textAlign:"right",marginTop:"-27px"}}>
            <button type="button" className="btn  btn-slim btn-primary" style={{width:"fit-content",}} onClick={clearFilters}>Clear Filters</button>

            <button title="Saves the filters and columns selected as default " type="button" className="btn  btn-slim btn-secondary" style={{width:"fit-content",marginLeft:"15px"}} onClick={handleSavePreference}>Save Preference</button>
            </div>}
          {/* </div> */}
        </div>
      </div>
       <Confirm key={isConfirmOpened.length} openConfirm={isConfirmOpened} setCloseConfirm={handleSetCloseConfirm} />
    </>
  );
};

export default Dropdown;
