/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useContext } from "react";
import TypeCreate from "./TypeCreate";
import SearchIcon from "../../../Assets/img/search.svg";
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import { getUserGroups } from "../../../Services/userManagementService";
import { UserContext } from "../../Application";
import Loader from "../../../Common/loader";
const Users = () => {
  const searchKeyword = useRef(null);
  const [searchVisible, showSearch] = useState(false);
  const [mainUsersData, setMainUserData] = useState([]);
  const [builtDemoUsers, setBuiltDemoUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const user = useContext(UserContext);

  useEffect(() => {
    if(user.email)
    getUserGroups({
      UserEmail: user.email,
      UserRoleId: user.userRole==="SuperAdmin"?1:user.userRole==="Admin"?2:3,
      searchText: ""
    }).then(response => setMainUserData(response?.data))
  }, [user.email])

  useEffect(() => {
    console.log("asasdasdas",mainUsersData.filter(i=>i.CreatedBy===user.email).filter(i=>i.GroupName===null));
    setFilteredData(mainUsersData.filter(i=>((i.permissionUpdatedBy && i.permissionUpdatedBy.search(user.email) >= 0) || (user.email === i.CreatedBy) )).filter(i=>i.GroupName===null))
  }, [mainUsersData])

  useEffect(() => {
    //console.log(mainUsersData)
    setBuiltDemoUsers(
      (filteredData.map((r,i) => {
        if(r.UserFirstName !== '' || r.UserLastName !== '') {
          return (<TypeCreate keys={i} createType="User" createTypeLock={true} title="Edit this User" edit={true} name={r.UserFirstName} lastname={r.UserLastName} email={r.UserEmail} data={mainUsersData}/>)
        }
      }
        
      ))
    )
  }, [filteredData]);


  const clearSearchClose = () => {
    searchKeyword.current.value = '';
    columnsListWrap();
    showSearch(false)
  }

  const columnsListWrap = () => {
    if (searchKeyword.current.value !== '' && searchKeyword.current.value !== undefined) {
      setFilteredData(mainUsersData.filter(e => e.UserFirstName.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase())));
    } else {
      setFilteredData(mainUsersData);
    }
    //console.log("on search",searchKeyword.current.value, userData);
  }

  return (
    <>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <h3 className="heading mt-2">My Users</h3>
          <p className="text-sm text-grey">Click to Edit or <a className="link" onClick={() => showSearch(true)}>Search</a></p>

          {searchVisible &&
            <>
              <div className="search-bar d-flex align-items-center">
                <span className="search-wrap-out w-100">
                  <span className="search-wrap w-100">
                    <img src={SearchIcon} className="filter-search" alt="" />
                    <input
                      type="text"
                      placeholder="Search Users"
                      className="btn-slim search-input bg-black w-100 on-hover-dull-blue-bg"
                      ref={searchKeyword}
                    />
                    <a className="filter-clear" onClick={() => clearSearchClose()}><img src={ClearIcon} alt="" /></a>
                  </span>
                  <input
                    type="button"
                    value="Search"
                    className="btn-slim search-btn"
                    onClick={() => columnsListWrap()}
                  />
                </span>
              </div>
            </>
          }

          <div className="list-wrap" key={3223}>
            <div className="list">
              {filteredData.length?builtDemoUsers:mainUsersData.length?<p  className="mt-5" style={{textAlign:"center"}}>No Data</p>:<Loader hideText={true} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;