import React from "react";

const ComingSoon = () => {
  return (
    <>
      <div className=" main-content">
        <div className="row">
          <div className="col-md-11 offset-md-1 pb-3 pt-1">
            <p
              style={{
                color: "#fff",
                fontSize: "25px",
                textAlign: "center",
                marginTop: "20%",
              }}
            >
              Coming Soon
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
