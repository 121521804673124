import React, { useState, useEffect, useContext } from "react";
import { Redirect, useHistory } from "react-router-dom";
import {
  MsalAuthenticationTemplate,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { acquireAccessToken, loginRequest } from "./authConfig";
import { verifyUserAccess, getLoggedUserInfo } from "../Services/CommonServices";
import axios from "axios";
import AppRoutes from "../Views/common/AppRoutes";
import Popup from "reactjs-popup";
import Header from "../Common/header";
import { UserCheckContext } from "../App";
import { getUserPreferences } from "../Services/PomService";
import { getInflowUserPreferences } from "../Services/InflowRiskVisibilityService";
import { getUserPermissionDetails } from "../Services/userManagementService";
export const UserContext = React.createContext({});
export const DropDownContext = React.createContext({});
export const UserAccessContext = React.createContext({});
export const UserSavePreference = React.createContext({});
export const SelectedRows = React.createContext({});


const ApplicationView = (props) => {
  //SSO Code Start Here
  const { instance, accounts } = useMsal();
  const [user, setUser] = useState({});
  const [useInfo, setUserInfo] = useState({});
  const account = useAccount(accounts[0] || {});
  const [isValidUser, setIsValidUser] = useState("");
  const [dropDownValues, setDropDownValues] = useState({});
  const history = useHistory();
  const [userAccess, setUserAccess] = useState()
  const [showModal, setShowModal] = useState(false);
  const [userSavePreference, setUserSavePreference]= useState({category:[]})
  const [selectedRows, SetSelectedRows]= useState()
  const isInternalUser = useContext(UserCheckContext)
  const [dummyset,setDummySet] = useState()
  const authRequest = {
    ...loginRequest,
  };
  const storageAccessToken = sessionStorage.getItem("storageAccessToken");
  useEffect(()=>{
    if(user.email)
      getUserPermissionDetails({"UserEmail":user.email}).then(response=> {
        setUserAccess(response)
      })
  }, [user])

  const isVerifyUserAccess = () => {
    verifyUserAccess()
      .then((response) => {
        console.log('access verification')
        // let userDetails = response.data[0] ?? [];
        // let accountHolder = { ...account };
        // accountHolder["firstName"] = userDetails.UserFirstName;
        // accountHolder["lastName"] = userDetails.UserLastName;;
        // accountHolder["email"] = account.username;
        // accountHolder["superVisorRoleId"] = userDetails.SuperVisorRoleId;
        // accountHolder["supervisorEmail"] = userDetails.SupervisorEmail;
        // accountHolder["superVisorRole"] = userDetails.SuperVisorRole;
        // accountHolder["supervisorFirstName"] = userDetails.SupervisorFirstName;
        // accountHolder["supervisorLastName"] = userDetails.SupervisorLastName;
        // accountHolder["userRole"] = userDetails.UserRole;
        // setUser({ ...accountHolder });
        // setUserInfo({ ...accountHolder });
        // setIsValidUser(true);
        // localStorage.setItem(
        //   "StorageAuthorizedUserFilters",
        //   JSON.stringify(response)
        // );
      })
      .catch((err) => console.log("Error: ", err));
  };
  const getUserInfo = (email) => {
    getLoggedUserInfo(email)
      .then((response) => {
        let userDetails = response.data[0] ?? [];
        let accountHolder = { ...account };
        accountHolder["firstName"] = userDetails.UserFirstName;
        accountHolder["lastName"] = userDetails.UserLastName;
        accountHolder["email"] = userDetails.UserEmail || account.username;
        accountHolder["superVisorRoleId"] = userDetails.SuperVisorRoleId;
        accountHolder["supervisorEmail"] = userDetails.SupervisorEmail;
        accountHolder["superVisorRole"] = userDetails.SuperVisorRole;
        accountHolder["supervisorFirstName"] = userDetails.SupervisorFirstName;
        accountHolder["supervisorLastName"] = userDetails.SupervisorLastName;
        accountHolder["userRole"] = userDetails.UserRole;
        accountHolder.email = account.username || useInfo.email
        setUser({ ...accountHolder });
        setUserInfo({ ...accountHolder });
        setIsValidUser(true);
        localStorage.setItem(
          "StorageAuthorizedUserFilters",
          JSON.stringify(response)
        );
      })
      .catch(() => { });
  };

  const performRedirect = () => {
    if (isValidUser === false) {
      return (
        <Redirect to="/invalid-user" state={{ email: account.username }} />
      );
    }
  };
  // useEffect(()=>{
  //   getUserPreferences("praneeth.madhavapeddi@unilever.com").then(res=>{
  //     // setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)
  //     window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
  //   }
  // )
  // },[])

  useEffect(() => {
    localStorage.setItem("userDetails", JSON.stringify(account));
  }, [user]);

  useEffect(() => {
    history.listen((location, action) => {
      if (location.pathname === "/") {
        setDropDownValues({});
      }
    });
  }, [history]);

  useEffect(() => {
    if (storageAccessToken === null || Object.keys(useInfo)?.length) {
      if (account) {
        if (Object.keys(useInfo)?.length) {
          account["firstName"] = useInfo.firstName;
          account["lastName"] = useInfo.lastName;;
          account["email"] = account.username;
          account["superVisorRoleId"] = useInfo.superVisorRoleId;
          account["supervisorEmail"] = useInfo.supervisorEmail;
          account["superVisorRole"] = useInfo.superVisorRole;
          account["supervisorFirstName"] = useInfo.supervisorFirstName;
          account["supervisorLastName"] = useInfo.supervisorLastName;
          account["userRole"] = useInfo.userRole;
          setUser({ ...account });
          props.setUserDetails({ ...account });
        }
        if (!Object.keys(useInfo)?.length)
          acquireAccessToken(instance).then((accessToken) => {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
            sessionStorage.setItem("storageAccessToken", accessToken);
            isVerifyUserAccess();
            getUserInfo(account.username)
          });
      }
    } else {
      getUserInfo(account.username)
    }
  }, [account, instance, storageAccessToken, useInfo]);

  const closeProduction = () => {
    setShowModal(false);
    window.sessionStorage.setItem("productionPopup", true);
  };

  useEffect(() => {
    if (
      !window.sessionStorage.getItem("productionPopup") &&
      Object.keys(user)?.length &&
      user.username?.includes("@unilever")
    ) {
      setShowModal(true);
    }
  }, [window.sessionStorage.getItem("productionPopup"), user]);

  const saveUserPreference=(data)=>{
    setUserSavePreference(data)
  }

  const saveSelectedRows=(data)=>{
    SetSelectedRows(data)
  }
  return (
    <>
      <DropDownContext.Provider value={{ dropDownValues, setDropDownValues }}>
        <UserContext.Provider value={user}>
        <UserSavePreference.Provider value={{userSavePreference,saveUserPreference}}>
        <SelectedRows.Provider value={{selectedRows,saveSelectedRows}}>
          <UserAccessContext.Provider value={userAccess}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={authRequest}
            >
              {() => (
                <>
                  {!window.location.href.includes("-p-") &&
                    !window.location.href.includes(
                      "https://polarisbusinesswaste.unilever.com"
                    ) &&
                    (
                      <>
                        <Popup
                          className="size-sm fluid-height"
                          open={showModal}
                          closeOnDocumentClick={false}
                          modal
                        >
                          <>
                            <div className="popup-header d-flex align-items-center justify-content-between">
                              <h5 className="mt-2">
                                Polaris-Business Waste Analytics
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={() => {
                                  closeProduction();
                                }}
                              ></button>
                            </div>

                            <div className="content">
                              <div className="scroll-content">
                                <p>
                                  You're currently in the Development Environment,
                                  which is NOT for operational use.
                                </p>
                                <a
                                  className="text-blue"
                                  href="https://polarisbusinesswaste.unilever.com/"
                                  target="_blank"
                                  title="Open document in new tab"
                                >
                                  Click here to go to the Production Environment
                                </a>
                              </div>
                            </div>
                          </>
                        </Popup>
                      </>

                    )}
                  {(!showModal ||
                    window.location.href.includes("-p-") ||
                    window.location.href.includes(
                      "https://polarisbusinesswaste.unilever.com"
                    )) && <AppRoutes />}
                </>
              )}
            </MsalAuthenticationTemplate>
            {/* <AppRoutes /> */}
            
          </UserAccessContext.Provider>
          </SelectedRows.Provider>
          </UserSavePreference.Provider>
        </UserContext.Provider>
      </DropDownContext.Provider>
    </>
  );
};

export default ApplicationView;
