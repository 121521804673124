import React, { useState, useEffect, useCallback, useContext } from "react";
import "chart.js/auto";
import { BarChart } from "../../../Common/charts/BarChart";
import { getDealStatusSummary } from "../../../Services/WasteRiskSummaryService";
import {
  payloadWithFilters,
  chunkString,
  LABEL_COLOR,
  LEGEND_COLOR,
} from "../helpers";
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const DealStatusXChart = ({ dropDownValues, selectedMco }) => {
  const [chartData, setChartData] = useState([]);
  const [currentMcoLength, setCurrentMcoLength] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const userAccess = useContext(UserAccessContext)
  
  function toPascalCase(str) {
    return str
    .split(/\s+/) // Split the string at spaces (or multiple spaces)
    .map(word => {
      if (word.length === 0) return ''; // Handle multiple spaces between words
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
  }
  useEffect(() => {
    let filters = selectedMco?{ ...dropDownValues, mco: [selectedMco] }:dropDownValues;
    setCurrentMcoLength(dropDownValues?.mco?.length ?? 0);
    if(dropDownValues||selectedMco){
    getChartData(payloadWithFilters(filters,userAccess));
    }
  }, [selectedMco, dropDownValues]);

  const getChartData = useCallback((filters) => {
    const payload = {
      ...filters,
      viewBy: "dealType",
    };
    getDealStatusSummary(payload)
      .then((response) => {
        setChartData(response?.WasteRiskDetailsResult);
      })
      .finally(() => setIsLoading(false));
  });
  const commaify = (value) => {
    var result = ("" + value).replace(/^(-?\d+)(\d{3})/, "$1,$2");
    return value == result ? result : commaify(result);
  };
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.x !== null) {
              label += (context.parsed.y/1000000).toFixed(2)
              // commaify((context.parsed.x/1000000)>1?Math.round(context.parsed.x/1000000):(context.parsed.x/1000000).toFixed(2)) +`M`;
            }
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "end",
        align: "start",
        offset: -20,
        color: LEGEND_COLOR,
        font: {
          size: 10,
          family: "unilevershilling",
        },
        formatter: (value) => `€ ${(value / 1000000).toFixed(2)>0?(value / 1000000).toFixed(2):0}M`,

      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
          callback(value) {
            return chunkString(this.getLabelForValue(value), 10);
          },
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          drawOnChartArea: true,
        },
        border: {
          display: false,
        },
        ticks: {
          stepSize: 50000000,
          callback: (value) => `€ ${(value / 1000000).toFixed()}M`,
          color: LABEL_COLOR,
          font: {
            family: "unilevershilling",
          },
        },
      },
    },
  };

  const dealStatusXData = {
    labels: chartData?.map((data) => toPascalCase(data.dealType)),
    datasets: [
      {
        label: "€",
        data: chartData?.map((data) => Number(data.cogs).toFixed(2)),
        backgroundColor: "rgb(84, 168, 216)",
        barThickness: 50,
      },
    ],
  };

  if (isLoading) return <Loader hideText={true} />;

  return <BarChart options={options} data={dealStatusXData} />;

  
};

export default DealStatusXChart;
