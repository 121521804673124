import React, { useState, useEffect, useRef, useContext } from "react";
import Popup from "reactjs-popup";
import Settings from "./Settings";
import UserAdd from "../common/UserAdd";
import PlusIcon from "../../../Assets/img/Plus.svg";
import Table from "../Table/TypeCreateTable";
import { ToastContainer, toast } from "react-toastify";
import {
  createGroupUser,
  updateGroupUser,
  updateMyGroupUsers,
} from "../../../Services/userManagementService";
import { notify } from "../../../Common/toast";
import { v4 } from "uuid";
import _, { forEach } from "lodash";
import { UserAccessContext, UserContext } from "../../Application";
import { useForm, useWatch, useFormState } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { regex_email, regex_letters_only } from "../../../utility/constants";

const CreatedUsersAction = (props) => {

  return (
    <Popup
        className="size-md fluid-height"
        open={props.open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        nested
        modal
        
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />
          <div className="popup-header modal-header-raised d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Created Users Action</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => props.onModalClose()}
            ></button>
          </div>

          <div className="content modal-content-raised">
            <div className="scroll-content" >
              <p>After closing the '{props.edit ? 'Edit' : 'Create a'} {props.createType ? 'Group' : 'User'}' modal, the entered user data as follows, will be lost :</p>
                <Table
                    isShowingUserDataUnsaved={true}
                    data={props.data}
                    hideSearch={true}
                    dropdownvalues={props.dropDownValues}
                    setDeletedUser={props.setDeletedUser}
                    selectedForDeletion={props.selectedForDeletion}
                    key={props.userTableKey}
                    createType={props.createGroup}
                    setTotalUsersAdded={props.setTotalUsersAdded}
                    createGroupSubmitCount={props.submitCount}
                    userRole={props.userRole}
                />
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div></div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  title="Confirm & close modal"
                  onClick={() => {
                    props.onConfirmModalClose();
                    // setTypeCreateModalOpen(false);
                    // setTotalUsersAdded([]);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Popup>
  );
}

export default CreatedUsersAction;

