import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
// import Offer from "../../Components/CentralDealManagement/common/Offer";
import { VendorBiddingList } from "../../Services/CentralDealManagementDetailServices";
import Offer from "../../Components/CentralDealManagement/common/Offer";
import Loadersml from "../loadersml";
import _ from "lodash";
import { createBiddingNotifications, updateBiddingNotification } from "../../Services/BiddingPortalService";
import { createNotification, getNotifications, updateApprovalStatus, getUserInfo } from "../../Services/CommonServices";
import moment from "moment";
import NotificationIcon from "../../Assets/img/notification.svg";
import UserIcon from "../../Assets/img/icon-user.svg";
import ThumbUpIcon from "../../Assets/img/icon-thumb-up.svg";
import CancelIconRed from "../../Assets/img/icon-cancel-red.svg";
import BiddingIcon from "../../Assets/img/icon-bidding.svg";
import { useAccount, useMsal } from "@azure/msal-react";
import { UserContext } from "../../Components/Application";
import { ToastContainer, toast } from "react-toastify";
import ViewCustomers from "../../Components/UserManagement/common/ViewCustomers";
import Notify1 from "../../Assets/notifications/notify1.mp3";
const Notification = (props) => {
const user = useContext(UserContext);
const [openNotify, setOpenNotify] = useState(false);
const [isNotificationsLoading, loadNotifications] = useState(false);
const [notificationData, setNotificationData] = useState([]);
const [notificationArray, setNotificationArray] = useState([]);
const [unreadNotificationArray, setUnreadNotificationArray] = useState([]);
const [unreadNotifyCount, setUnreadNotifyCount] = useState(0);
const [notifyCount, setNotifyCount] = useState(0);
const [isCustomersPopupOpen, setCustomersPopupOpen]=useState(false);
const [isBiddingPopupOpen, setBiddingPopupOpen]=useState(false);
const [biddingObject, setBiddingObject] = useState({});
const [showMore, setShowMore] = useState(false);
const[userApiData, setUserApiData]=useState([]);
const [areNotificationsLoading, setNotificationsLoading] = useState(false);
const { accounts } = useMsal();
const account = useAccount(accounts[0] || {});
const[notifyPopupPayload, setNotifyPopupPayload] = useState({});
const [isOfferModalOpen, setOfferModalOpen] = useState(false);
const audioPlayer = useRef(null);
const { name, username } = account || {};
const [notifyAgoTime, setNotifyAgoTime] = useState([]);
    const playAudio = () => {
      audioPlayer.current.play();
    }
    useEffect(() => {
      // get_user_data();
      if (user.email) {
        setUserApiData({ ...user });
      }
    }, [user]);

    // const getBiddingList = (bln,blid) => {
    //   let payload_biddng = {
    //     "biddingListName": bln,
    //      "biddingListID": blid
    //   };
    //   VendorBiddingList(payload_biddng, props.isExternal, false).then((res2) => {
    //     let bs = res2.biddingListDetail[0].BiddingStatus;
    //     return bs;
    //   })
    // }

    const getNotifictionData = useCallback((payload) => {
        // let test_notifys = [
        //     {
        //         "notificationID": "5C67658A-8108-4AA1-A206-F2FE732A2CDC",
        //         "biddingListID": "3C861CD3-2C39-4153-B9F1-4B7E3FDF0147",
        //         "biddingListName": "test20",
        //         "objectId": null,
        //         "objectType": null,
        //         "fromName": "Bandaru, Aswini",
        //         "toName": "sumit.nirgude@covalenseglobal.com",
        //         "fromEmail": null,
        //         "toEmail": null,
        //         "notification": "Aswini.Bandaru@unilever.com accepted swaa bidding list",
        //         "action": "accepted",
        //         "status": true,
        //         "isEmailSent": null,
        //         "isEmailSentDate": null,
        //         "createdBy": "Bandaru, Aswini",
        //         "createdDate": "2023-07-30T06:32:22.053Z",
        //         "isApproved": null
        //     },
        //     {
        //         "notificationID": "5C67658A-8108-4AA1-A206-F2FE732A2CDC",
        //         "biddingListID": "3C861CD3-2C39-4153-B9F1-4B7E3FDF0147",
        //         "biddingListName": "test20",
        //         "objectId": null,
        //         "objectType": null,
        //         "fromName": "Bandaru, Aswini",
        //         "toName": "sumit.nirgude@covalenseglobal.com",
        //         "fromEmail": null,
        //         "toEmail": null,
        //         "notification": "Aswini.Bandaru@unilever.com accepted KOP bidding list",
        //         "action": "accepted",
        //         "status": true,
        //         "isEmailSent": null,
        //         "isEmailSentDate": null,
        //         "createdBy": "Bandaru, Aswini",
        //         "createdDate": "2023-07-31T06:32:22.053Z",
        //         "isApproved": null
        //     },
        //     {
        //         "notificationID": "5C67658A-8108-4AA1-A206-F2FE732A2CDC",
        //         "biddingListID": "3C861CD3-2C39-4153-B9F1-4B7E3FDF0147",
        //         "biddingListName": "test20",
        //         "objectId": null,
        //         "objectType": null,
        //         "fromName": "Bandaru, Aswini",
        //         "toName": "sumit.nirgude@covalenseglobal.com",
        //         "fromEmail": null,
        //         "toEmail": null,
        //         "notification": "Aswini.Bandaru@unilever.com accepted MJQ bidding list",
        //         "action": "accepted",
        //         "status": true,
        //         "isEmailSent": null,
        //         "isEmailSentDate": null,
        //         "createdBy": "Bandaru, Aswini",
        //         "createdDate": "2023-08-1T06:32:22.053Z",
        //         "isApproved": null
        //     }
        // ];
        setNotificationsLoading(true);
        var sortNotifys = [];
        var allNotifications = [];
        getNotifications(payload).then((response)=>{
            if (response.biddingNotifications.length > 0 || response.customerNotifications.length > 0) {
                // if (response.length) {
                let resp_bidding = [];
                let resp_customer = [];
                response.biddingNotifications.map(r => {
                      r.type = 'bidding';
                      // if(r.biddingListID !== null){
                      let payload_biddng = {
                        "biddingListName": r.biddingListName,
                       "biddingListID": r.biddingListID
                      };
                    VendorBiddingList(payload_biddng, props.isExternal, false).then((res2) => {
                      r.biddingStatus = res2.biddingListDetail[0]?.BiddingStatus;
                    })
                  // }
                    // r.biddingStatus = getBiddingList(r.biddingListName, r.biddingListID);
                });
                response.customerNotifications.map(r => {
                    r.type = 'customer';
                });
                allNotifications = [...response.biddingNotifications, ...response.customerNotifications];
                sortNotifys = _.sortBy(allNotifications, ((o) => {
                    if(o != undefined) {
                      let isNotifyToday = moment().isSame(o.createdDate, 'day');
                      if(isNotifyToday) {
                        o.agoTime = moment(o.createdDate, "YYYY-MM-DD HH:mm:ss.SSS").fromNow(true);
                        o.agoTime = o.agoTime.replace('a day', '1 day');
                        //o.agoTime = o.agoTime.replace(/(\d+) minutes?/, '$1 Mins').replace(/(\d+) hours?/, '$1 Hrs');
                        //notifyTime.push(notifyTimeT);
                      } else {
                        o.agoTime = moment(o.createdDate).format("DD-MM-YYYY");
                        //notifyTime.push(notifyTimeT);
                      }
                      Date.parse(o.createdDate)
                    }
                }));

                setNotificationArray(sortNotifys);
                setUnreadNotifysCount(sortNotifys);
                if(sortNotifys.length) {
                    // playAudio();
                    setNotificationData(
                      sortNotifys.map((n) => (
                        <li
                          className={`read-${n.status} ${
                            n.isApproved == null
                              ? "has-approval"
                              : n.isApproved != null
                              ? "action-taken"
                              : ""
                          }`}
                          key={n.notificationID}
                        >
                          <div className="row-1">
                            <div className="content-wrap">
                              <div className="icon">
                                {n.type == "customer" && (
                                  <img
                                    src={UserIcon}
                                    title="Customer Notification"
                                  />
                                )}
                                {n.type == "bidding" && (
                                  <img
                                    src={BiddingIcon}
                                    title="Bidding Notification"
                                  />
                                )}
                              </div>
                              <div className="content">
                                {n.notification}
                                <div>
                                  <a
                                    className="d-inline-block link mt-1"
                                    onClick={() => {
                                      if (n.type == "customer") {
                                        setNotifyPopupPayload({
                                          customerEmail: n.customerEmail,
                                        });
                                        setCustomersPopupOpen(true);
                                      } else if (n.type == "bidding") {
                                        sessionStorage.setItem('bidding_list_opened', JSON.stringify({ id : n?.biddingListID, status : n?.biddingStatus, detail : n?.detail }));
                                        getBiddingListDetails(n);
                                      }
                                      setOpenNotify(false);
                                    }}
                                  >
                                    {n.notification.includes("wishes") &&
                                      n.type == "customer" && (
                                        <>
                                          <span>View Customer details</span>
                                        </>
                                      )}

                                      {/* 
                                        Scenarios :
                                        -----------
                                        wishes : to Superior, from User
                                        list has been : to User, from Superior
                                        has sent : to Customer, from User
                                        updated " : to User, from Customer
                                        updated " : to Customer, from User


                                        // ---------***-------- //


                                        Simplified messages for above Scenarios :
                                        -----------------------------------------
                                        User wishes,
                                        SuperAdmin accepted, SuperAdmin rejected, SuperAdmin updated,
                                        User sent,
                                        Customer accepted, Customer rejected, Customer updated, Customer counter-offered
                                        User accepted, User rejected, User updated, User counter-offered

                                      */}
                                      {n.notification.includes("wishes") &&
                                        n.type == "bidding" && (
                                          <>
                                            <span>View, Confirm or Reject Bidding list details</span>
                                          </>
                                        )}
                                        {/* n.type == "bidding" && */}
                                      {(!n.notification.includes("wishes")) && 
                                         n.notificationType !== "ADMIN_NOTIFICATIONS" && (
                                          <>
                                            <span>View Bidding list details</span>
                                          </>
                                        )}
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="date">
                              {n.agoTime}
                            </div>
                          </div>
                          {/* {(n.notification.includes('wishes')) && ( */}
                          <div className="row-2 mt-1">
                            {/* <div>
                                        {(n.type == 'customer') && <img src={UserIcon}  title="Customer Notification" />}
                                        {(n.type == 'bidding') && <img src={BiddingIcon} title="Bidding Notification" />}
                                    </div> */}
                            {n.notification.includes("wishes") &&
                              n.isApproved == null && (
                                <>
                                  {/* author : Sumit */}
                                  {/* next func */}
                                  {n.type != 'bidding' && (
                                    <div>
                                      <button
                                        type="submit"
                                        className="btn btn-outline btn-primary btn-outline-primary btn-compact"
                                        onClick={() =>
                                          updateApproval_notification(
                                            n.type == "customer"
                                              ? n.objectId
                                              : n.biddingListID,
                                            n.type == "bidding"
                                              ? n.biddingListName
                                              : null,
                                            n.notificationID,
                                            n.type,
                                            user.email,
                                            n.fromName,
                                            n.type == "customer"
                                              ? n.fromEmail
                                              : n.createdBy,
                                            0,
                                            n
                                          )
                                        }
                                      >
                                        Reject
                                      </button>
                                      <button
                                        type="submit"
                                        className="btn ml-1 btn-outline btn-secondary btn-outline-secondary btn-compact "
                                        onClick={() =>
                                          updateApproval_notification(
                                            n.type == "customer"
                                              ? n.objectId
                                              : n.biddingListID,
                                            n.type == "bidding"
                                              ? n.biddingListName
                                              : null,
                                            n.notificationID,
                                            n.type,
                                            user.email,
                                            n.fromName,
                                            n.type == "customer"
                                              ? n.fromEmail
                                              : n.createdBy,
                                            1,
                                            n
                                          )
                                        }
                                      >
                                        Approve
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                           
                                <div className="w-100 d-flex align-items-center justify-content-between">
                                  {n.isApproved != null && (
                                    <div className={`past-status-text is-approved-${n.isApproved} mr-2`}>
                                        {n.isApproved == 1 ? <img src={ThumbUpIcon} /> : <img src={CancelIconRed} />}
                                        {/* <span></span> */}
                                        <p> You have {n.isApproved == 1 ? "approved" : "rejected"} this.</p>
                                    </div>
                                  )}
                                  {n.type == 'bidding' && n.notification.includes("accepted") &&<div>
                                    <div className={`past-status-text is-approved-true mr-2`}>
                                     
                                        <img src={ThumbUpIcon} />
                                        {/* <span></span> */}
                                        <p>This has been accepted </p>
                                     
                                    </div>
                                  </div>}
                                  {n.type == 'bidding' && n.notification.includes("rejected") &&<div>
                                    <div className={`past-status-text is-approved-false mr-2`}>
                                      <img src={CancelIconRed} />
                                      <p>This has been rejected</p>
                                    </div>
                                  </div>}
                                  {n.notification.includes("wishes") && (
                                    <div>
                                        {n.type != 'bidding' && (
                                        <div>
                                          <div className="btn btn-slim bg-grey-dark btn-compact btn-grey-dark  cursor-default ">
                                            {" "}
                                            Reject
                                          </div>
                                          <div className="btn ml-1 btn-slim bg-grey-dark btn-compact btn-grey-dark cursor-default ">
                                            {" "}
                                            Approve
                                          </div>
                                        </div>
                                        )}
                                    </div>
                                  )}
                                </div>
                              
                            {!n.notification.includes("wishes") && <div></div>}
                          </div>
                          {/* )} */}
                        </li>
                      ))
                    );
                } 
                else {
                    setNotificationData (
                        <li className={`no-notifications read-true`} key={'nonotification'} >
                            <div className="content">No Notifications</div>
                        </li>
                    );
                }
                setNotificationsLoading(false);
            }
            setNotificationsLoading(false);
        }).catch((response)=>{
            setNotificationsLoading(false);
        })
    }, []);

    
    useEffect(() => {
        if(user.email)
            getNotifictionData(user.email);
    }, [user.email]);

    const checkNotificationD = () => {
    }

    const setUnreadNotifysCount = (sortNotifys) => {
        let unreadNotifys = sortNotifys.filter(r => !r.status);
        setUnreadNotificationArray(unreadNotifys);
        setUnreadNotifyCount(unreadNotifys.length);
        setNotifyCount(sortNotifys.length);
    }

    const updateApproval_notification = (
      object_biddingList_ID,
      BiddingListName,
      notificationID,
      notificationType,
      updateBy,
      fromName,
      fromEmail,
      approvalStatus,
      notificationDetails
    ) => {
      let approvalStatusText = approvalStatus == 1 ? "accepted" : "rejected";
      let payload = {
        notificationID: notificationID,
        //objectId : (notificationType == 'bidding') ? null : object_biddingList_ID,
        notificationType: notificationType,
        updatedBy: updateBy,
        isApproved: approvalStatus,
        biddingListID: object_biddingList_ID,
      };
      if (notificationType == "customer") {
        payload.objectId = object_biddingList_ID;
      } else if (notificationType == "bidding") {
        payload.objectId = null;
        payload.biddingListID = object_biddingList_ID;
      }
      updateApprovalStatus(payload)
        .then((resp) => {
          if (notificationType == "customer") {
            createNotification({
              objectId: object_biddingList_ID,
              objectType: "customer",
              fromName: `${user.firstName} ${user.lastName}`,
              toName: fromName,
              toEmail: fromEmail,
              notification: `Customer has been ${approvalStatusText} by ${user.firstName}`,
              status: 0,
              createdBy: user.email,
            });
            let actionTaken = `You have ${approvalStatusText} the user as customer`;
            let sccs = (
              <div className="tosterIcon">
                <div>{actionTaken}</div>
              </div>
            );
            toast.success(sccs, {
              containerId: "A",
            });
          } else if (notificationType == "bidding") {
            createBiddingNotifications({
              biddingListID: object_biddingList_ID,
              biddingListName: BiddingListName,
              fromName: `${user.firstName} ${user.lastName}`,
              // toName: fromName,
              // toEmail: fromEmail,
              fromEmail: user.email,
              toName: fromEmail,
              notification: `"${BiddingListName}" bidding list has been ${approvalStatusText} by ${user.firstName} ${user.lastName} (${user.email})`,
              status: 0,
              action: `${approvalStatusText}`,
              createdBy: user.email,
            });
            let actionTaken = `You have ${approvalStatusText} the Bidding list`;
            let sccs = (
              <div className="tosterIcon">
                <div>{actionTaken}</div>
              </div>
            );
            toast.success(sccs, {
              containerId: "A",
            });
          }
          getNotifictionData(user.email);
        })
        .catch((error) => {
          // if(notificationType == 'customer') {
          let actionError = `Some error occured`;
          let sccs = (
            <div className="tosterIcon">
              <div>{actionError}</div>
            </div>
          );
          toast.success(sccs, {
            containerId: "A",
          });
          // } else if(notificationType == 'bidding') {
          //     let actionError = `Some error occured`;
          //     let sccs = <div className="tosterIcon"><div>{actionError}</div></div>
          //     toast.success(sccs, {
          //         containerId: "A",
          //     });
          // }
        });
    };

    const readAllNotifications = () => {
        unreadNotificationArray.map((r,i) => {
            let obj = {  notificationID: r.notificationID, status : 1, notificationType : r.type};
            updateBiddingNotification(obj).then((response) => {
                let unreadCount = unreadNotificationArray.length-1;
                if(i == unreadCount){
                    getNotifictionData(user.email);
                }
            })
        });
    };

    const openNotifications = () => {
        setOpenNotify(true);
        getNotifictionData(user.email);
        //readAllNotifications();
    }

    const closeModal = () => {
        setOpenNotify(false);
        readAllNotifications();
       // setUnreadNotifysCount(sortNotifys);
    };

    const get_user_data = () => {
      getUserInfo(user.email).then((resp)=>{
        setUserApiData(resp.data[0]);
      })
    }
  
    const handleCustomerPopupOpen = (customerPopupStatus) => {
      setCustomersPopupOpen(customerPopupStatus);
    }

    const getBiddingListDetails = useCallback((payload) => {
        var payloadObj = {
          biddingListID: payload.biddingListID,
          biddingListName: payload.biddingListName,
        };
        VendorBiddingList(payloadObj, false, false).then((res2) => {
            res2.biddingListDetail[0].biddingListID = res2.biddingListDetail[0].BiddingListID;
            res2.biddingListDetail[0].biddingListName = res2.biddingListDetail[0].BiddingListName;
            res2.biddingListDetail[0].biddingGroupID = res2.biddingListDetail[0].BiddingGroupID;
            setBiddingObject({ data : res2.biddingListDetail[0], whole_data : payload, detail : payload.detail });
            setBiddingPopupOpen(true);
        })
    },[]);

    const handleBiddingPopupControl = (popupStatus) => {
      setBiddingPopupOpen(popupStatus);
    }

    const isUserSuperior = () => {
      var isUserSuperiorVar = false;
      if(user.userRole == "SuperAdmin") {
        isUserSuperiorVar = true;
      } else {
        if(user.supervisorEmail !== null || user.supervisorEmail !== undefined || user.supervisorEmail !== "") {
          isUserSuperiorVar = false;
        }
      }
      return isUserSuperiorVar;
    }
    console.log(user.userRole, "user role")
    return (
        <>
         <ToastContainer
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_CENTER}
          autoClose={2000}
        />
        <a onClick={() => openNotifications()} key={notificationArray.length} className="cursor-pointer position-relative notification-link">
          <img src={NotificationIcon} alt="Notify" title="Notifications" />
          {unreadNotifyCount > 0 && (
            <div className="badge">{unreadNotifyCount}</div>
          )}
        </a>
        
        <Popup className={`notification notifications-loading-${areNotificationsLoading || notificationData.length <= 0}`}
            position="top right"
            arrow={true}
            open={openNotify} 
            onClose={closeModal}
            modal
            tooltip
        >
            {close => (
                <>
                    <audio ref={audioPlayer} src={Notify1} />
                    <div className={`notification-scroll-container showMore-${showMore}  notifications-loading-${areNotificationsLoading || notificationData.length <= 0}`} 
                    // onClick={checkNotificationD} 
                    >
                        {areNotificationsLoading && (
                            <div className="notifications-loading note text-center"><Loadersml mp0={true} />Notifications Loading</div>
                        )}
                        {(!areNotificationsLoading && notificationData.length > 0) && (
                            <ul>
                                {notificationData}
                            </ul>
                        )}
                        {(!areNotificationsLoading && notificationData.length <= 0) && (
                            <>
                            <p className="m-0" style={{fontSize : 0}}>{ setTimeout(() => {setOpenNotify(false)}, 5000)}</p>
                            <div className="note text-center">You dont have any notifications.</div>
                            </>
                        )}
                        {(!areNotificationsLoading && (notificationData == undefined || notificationData == null)) && (
                            <div className="note text-center">Notifications are NULL or Undefined</div>
                        )}

                        {(showMore && notificationData.length > 5) && <button className="btn btn-primary btn-slim w-100" onClick={() => setShowMore(false)} disabled={isNotificationsLoading}> 
                            {isNotificationsLoading &&
                                <div className="d-flex align-items-center justify-content-center"><Loadersml />&nbsp;&nbsp; <span className="text-black">Loading more notifications</span></div>
                            }
                            {!isNotificationsLoading &&
                                ('Load more')
                            }
                        </button>}
                    </div>
                </>
            )}
        </Popup>
        {Object.keys(biddingObject).length > 0 && <Offer
          show={isBiddingPopupOpen}
          onHide={() => {
            setBiddingPopupOpen(false);
            setOfferModalOpen(false);
          }}
          biddingObject={biddingObject.data}
          biddingWholeObject={biddingObject.whole_data}
          detail={biddingObject.detail}
          biddingDataObject={biddingObject.data}
          setBiddingPopupOpen={(val) => {
            setBiddingPopupOpen(val);
          }}
          setOfferModalOpen={(val) => {
            setOfferModalOpen(val);
          }}
          onBiddingPopupControl={handleBiddingPopupControl}
          key={biddingObject.data.id}
          isExternal={(!user.userRole || (user.userRole === undefined || user.userRole === "Customer")) ? true : false}
          displayedAsNotification={isUserSuperior()}
          isNoEditList
        />}
        <ViewCustomers  isCustomersPopupOpen={isCustomersPopupOpen} key={`customers-popup-opened-${isCustomersPopupOpen}`} handleCustomerPopupOpen={handleCustomerPopupOpen} displayedAsNotification={notifyPopupPayload.customerEmail} />
        </>
    );
}

export default Notification;