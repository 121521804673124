import React, { useCallback, useEffect, useState } from "react";
//import DownloadIcon from "../../../Assets/img/download.svg";
//import ClickIcon from "../../../Assets/img/clickicon.svg";
//import InfoIcon from "../../../Assets/img/info.svg";
import OpenIcon from "../../../Assets/img/open.svg";
import CloseIcon from "../../../Assets/img/close.png";
import SearchIcon from "../../../Assets/img/search.svg";
import RefreshIcon from "../../../Assets/img/refresh.svg";
import PinchIcon from "../../../Assets/img/icon-pinch.svg";
import EditIcon from "../../../Assets/img/edit.svg";
// import DoveImg from "../../../Assets/img/dove.png";
// import VaselineImg from "../../../Assets/img/vaseline.png";
// import SureImg from "../../../Assets/img/sure.png";
// import LinxImg from "../../../Assets/img/lynx.png";
// import DoveSoapImg from "../../../Assets/img/dove-soap.png";
import Glossary from "../../../Common/glossary";
//import axios from "axios";
//import fileSaver from "file-saver";
import { ViewUserBiddingList } from "../../../Services/BiddingPortalService";
import TableGrid from "./TableGrid";
import { COLUMNS } from "./Columns";
import { PerPageRecordNumber } from "../../../utility/constants";
import ULButton from "../../button";
import { notify } from "../../../Common/toast";

const ListTable = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [SortBy, setSortBy] = useState("material_description");
  const [SortType, setSortType] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [totalMinBidPrice, setTotalMinBidPrice] = useState(null);
  const [offerPrice, setOfferPrice] = useState(null);

  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);

  const fetchIdRef = React.useRef(0);

  const viewUserBiddingList = useCallback((payload) => {
    ViewUserBiddingList(payload).then((response) => {
      setData(response.data);
      setCount(response.count);
      setTotalMinBidPrice(response.total_min_bid_price);
      setOfferPrice(response.listed_offer_price);
    });
  }, []);

  useEffect(() => {
    viewUserBiddingList({
      bidding_name: props.selectedList,
      SortBy,
      SortType,
      searchText,
    });
  }, [SortBy, SortType, props.selectedList, searchText, viewUserBiddingList]);

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(20);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
    //setPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };

  const handleSearch = (val) => {
    //setPage(0);
    setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      //Enter Key
      //setPage(0);
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      //Back Space
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  //Edit cell code start here
  /* const handleInlineEditChanges = (val) => {
    setRowInlineEditChanges(val);
  }; */

  const updateCellData = (row, column, value) => {
    const rowId = row.original.id;

    //const FinalValue = Number(value);

    //const PercentageIncrease = IncreasePercentage(FinalValue, StartingValue);
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);

    /* if (FinalValue !== 0) {
      handleInlineEditChanges([
        ...rowInlineEditChanges,
        { rowID: rowId, changedValue: value },
      ]);
    } */
    /* 
    handleInlineEditChanges([
      ...rowInlineEditChanges,
      { rowID: rowId, changedValue: value },
    ]); */

    setClickedIndex(null);
    setClickedCell(null);

    /*  UpdateWasteRiskCellData({
      id: rowId,
      referenceID: row.original.materialno,
      key: column.id,
      value,
    })
      .then((response) => {
        if (response.message === "Successfully Initiated the record") {
          notify({
            message: "Data updated successfully!",
            type: "success",
          });
        } else {
          notify({
            message: "Something Went Wrong!",
            type: "error",
          });
        }
      }, 100)
      .catch((error) => {
        console.log("error: ", error);
        notify({
          message: "No Response From Server. Please Try Again Later",
          type: "error",
        });
      }); */
  };

  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
  };
  //Edit cell code end here

  const handleSubmit = () => {
    if (offerPrice > totalMinBidPrice) {
      props.updateOfferPrice({
        bidding_list_name: props.selectedList,
        offer_price: offerPrice,
      });
    } else {
      notify({
        message: "Offer price should be greater than bid price!",
        type: "warn",
      });
    }
  };

  return (
    <>
      <div className="biddingtable">
        <div className="body-container ">
          
            <div className="">
              {props.selectedList}
              <div className="pull-right flex align-items-center justify-content-between">
                <div>
                  <span className="search-wrap">
                    <img src={SearchIcon} className="filter-search" alt="" />
                    <input
                      type="text"
                      placeholder="SKU/Description"
                      className="btn-slim search-input"
                      onChange={(e) => {
                        setSearchText(e.target.value ?? "");
                      }}
                      value={searchText}
                    />
                    <input
                      type="button"
                      value="Search"
                      className="btn-slim search-btn"
                    />
                  </span>
                </div>
                <div className="ml-2 flex align-items-center">
                  <div className="table-filters">
                    <span className="marginset">
                    <Glossary category="fgSlob" />
                    </span>
                    <span className="marginset">
                      <span
                        className="info_btn"
                        onClick={() => setSearchText("")}
                      >
                        <img src={RefreshIcon} alt="Refresh" />
                      </span>
                    </span>
                    {/* <span className="marginset">
                      <span
                        className="info_btn"
                        onClick={""}
                        title={
                          props.isExpand
                            ? "Minimise Datatable"
                            : "Maximise Datatable"
                        }
                      >
                        <img
                          src={props.isExpand ? PinchIcon : OpenIcon}
                          title={
                            props.isExpand
                              ? "Minimise Datatable"
                              : "Maximise Datatable"
                          }
                          alt=""
                        />
                      </span>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>

            <div className=" table_design bidtable mt-3">
              <TableGrid
                columns={COLUMNS}
                data={data}
                SortBy={SortBy}
                SortType={SortType}
                setSortBy={setSortBy}
                setSortType={setSortType}
                fetchData={fetchData}
                pageCount={pageCount}
                totalRecordCount={totalRecordCount}
                pageSizeNo={itemPerPage}
                // loading={loading}
                handleItemPerPage={handleItemPerPage}
                handlePaginationReset={handlePaginationReset}
                handleSearch={handleSearch}
                handleEnterSearch={handleEnterSearch}
                handleSearchReset={handleSearchReset}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                //searchText={searchText}
                //setSearchText={setSearchText}
                {...editCellProps}
                props={props}
                toggleExpandCollapse={props.toggleExpandCollapse}
                isExpand={props.isExpand}
              />
            </div>

            <div className="flex align-items-center justify-content-between mt-3">
              <div className="">
                <button
                  type="button"
                  className="btn-slim btn-secondary"
                  onClick={() =>
                    props.handleEditList({
                      bidding_name: props.selectedList,
                      SortBy,
                      SortType,
                    })
                  }
                >
                  <img src={EditIcon} alt="EditIcon" />
                  &nbsp;&nbsp;Edit List
                </button>
                {/* <ULButton
                  type="button"
                  className="btn btn-secondary btn-slim"
                  label="Edit List"
                  onClick={() =>
                    props.handleEditList({
                      bidding_name: props.selectedList,
                      SortBy,
                      SortType,
                    })}
                /> */}
              </div>
              <div className="">
                Number Of SKUs: &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <strong>{count}</strong>
              </div>
              <div className="">
                Total Min Bid Price: &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <strong>{totalMinBidPrice}</strong>
              </div>
              <div className="">
                Offer Price: &nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type="number"
                  name="offer_price"
                  value={offerPrice}
                  className="ml-1 input-edit-mode"
                  onChange={(e) => setOfferPrice(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-5">
              <div className="pull-right ">
                <ULButton
                  type="button"
                  className="btn btn-primary btn-big  btn-std-width-lg"
                  label="Submit"
                  onClick={handleSubmit}
                />
              </div>
            </div>
         
        </div>
      </div>
    </>
  );
};

export default ListTable;
