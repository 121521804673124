import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import DownloadIcon from "../../../Assets/img/download.svg";
import UploadIcon from "../../../Assets/img/upload.svg";
import Loader from "../../../Common/loader";
import { v4 } from 'uuid';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import {
  PomDetails,
  downloadPomDetails,
  excelFileUpload,
  addPulishStatus,
  setPublishStatus,
  updatePomCellData,
  PendingActionsCount,
  downloadPomDBDetails,
  downloadPomZANDetails,
  downloadPomSFDetails,
  downloadPomScreenDetails,
  checkFileStatus,
  downloadPomCustomerPomDetails,
  getDownloadBatchCodes,
  bulkUpdatePomCellData
} from "../../../Services/PomService";
import { notify } from "../../../../src/Common/toast";
import { COLUMNS } from "./Columns";
import { PerPageRecordNumber } from "../../../../src/utility/constants";
import TableGrid from "./TableGrid";
import moment from "moment";
import StatusPopup from "../../../Common/StatusPopup/statusPopup";
import { UserAccessContext, UserContext } from "../../Application";
import { PayloadWithFilters } from "../PomSummary/helpers";
var fileSaver = require("file-saver");
export const MonthList = React.createContext({});
const PomTable = (props) => {
  const [cuColumns, setCuColumns] = useState([])
  const [zunColumns, setZunColumns] = useState([])
  const [caseColumn, setCaseColumn] = useState();
  const [tabKey, setTabKey] = useState("ZUN");
  const [filterText, setFilterText] = useState('')
  const [filterKey, setFilterKey] = useState('')
  const fileUploadInput = useRef(null);
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isFileStatusDone, setIsFileStatusDone] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [isShowFilePopup, setIsShowFilePopup] = useState(false);
  const [adviceList, setAdviceList] = useState([]);
  const [nextStepList, setNextStepList] = useState([]);
  const [state, setState] = useState("Loading");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [SortBy, setSortBy] = useState("bbd");
  const [SortType, setSortType] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [dealStatus, setDealStatus] = useState([]);
  const [selection, setSelection] = useState([]);
  const [selectedCol, setSelectedCol] = useState([]);
  const [hasColUpdated, setHasColUpdated] = useState(false);
  const [pageInd, setPageInd] = useState(0);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [publishCount, setPublishCount] = useState(0);
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [dealMonths, setDealMonths] = useState([]);
  const [publishLoading, setPublishLoading] = useState(false);
  const [refObj, setRefObj] = useState([]);
  const user = useContext(UserContext);
  const userAccess = useContext(UserAccessContext)
  const fetchIdRef = React.useRef(0);
  const [downloadClicked, setDownloadClicked] = useState(0);
  const [columnSearch, SetColumnSearch] = useState({});
  const [rowDownloadId, setRowDownloadIds] = useState([])
  const [clearColumnSearch, setColumnClearSearch] = useState(false)
  const [isSetSaveLoaderInit__publish, setSaveLoaderInit__publish] = useState(false);


  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
  };
  const setDropdownvaluesGlobal = (data1, data2) => {
    if (data1) {
      if (data1.length === 0) {
        return (data2)
      }
      else {
        return (data1)
      }
    }
    else { return (data2) }

  }

  useEffect(() => {
    if (tabKey) {
      if (tabKey == "ZUN") setZunColumns(COLUMNS.filter((i) => !i.isCUColumn && !i.isCasesColumn));
      else if (tabKey == "CU") {
        setCuColumns(COLUMNS.filter((i) => !i.isZUNColumn && !i.isCasesColumn));
      }
      else if(tabKey == "CASES"){
        setCaseColumn(COLUMNS.filter((i) => !i.isZUNColumn && !i.isCUColumn ).filter(i=>i.isCasesColumnz!=="isCasesColumnz"))
      }
      setFilterKey('');
      setFilterText('');
      setSearchText('');
      setSearchValue('');
      setGridInfo(["Loading"]);
    }

  }, [tabKey]);

  const addToPublish = (values) => {
    let data = [];
    selection.forEach((val) => {
      data.push({
        mrdr_id: val.original.mrdr_id,
        country: val.original.country,
        countryId: val.original.countryId,
        statusId: val.original.status_id,
        plantCode: val.original.plantCodeId,
        bbd: val.original.bbd,
        status: values,
        descriptionId: val.original.descriptionId,
        hah_date: val.original.hah_date,
      });
    });
    addPulishStatus({ data: data })
      .then((response) => {
        setIsTableLoading(true)
        setSelection([]);
        setTimeout(() => {
          getPendingActionsCount();
        }, 200);
        props?.loadDropdownsData();
        //  console.log("asssssssssssssss",props.dropDownValues)
        getGridData({
          alertTypeId: props.dropDownValues?.alert ?? [],
          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
          plant_code: props.dropDownValues?.plant ?? [],
          nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
          ownerId: props.dropDownValues?.owner ?? [],
          foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
          mrpControllerId: props.dropDownValues?.mrpc ?? [],
          forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
          page,
          columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
          per_page: itemPerPage,
          sortby: SortBy,
          complianceSorting: SortType,
          searchText: searchText ? searchText.trim() : "",
        });
        notify({
          message: `Sucessfully Added to Publish List`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish List failed`,
          type: "error",
        });
      });
  };
  const MINUTE_MS = 5000;
  const fileUpdateChecker = (type) => {
    checkFileStatus({ uniqueTaskId: uniqueId, "task": type, "module": "POM" })
      .then((response) => {
        if (response.taskstatus === "completed") {
          setIsFileProcessed(false);
          setIsShowFilePopup(false)
          setIsFileStatusDone(false)
          if (
            type === "upload"
          ) {
            setIsTableLoading(true)
            setIsShowFilePopup(false)
            setTimeout(() => {
              getPendingActionsCount();
            }, 5000);

            notify({
              message: "File Upload Successfully!",
              type: "success",
            });
          } else {
            setIsShowFilePopup(false)
            setIsFileStatusDone(false)
            setIsFileProcessed(false)
          }
        }
      })
      .catch(() => {
        setIsFileProcessed(false);
        setIsFileStatusDone(false);
        setIsShowFilePopup(false);
      });
  };
  useEffect(() => {
    if (isFileProcessed || isFileStatusDone) {
      const interval = setInterval(() => {
        if (isFileProcessed) {
          fileUpdateChecker("download");
        } else if (isFileStatusDone) {
          fileUpdateChecker("upload");
        }
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [fileUpdateChecker, isFileProcessed, isFileStatusDone]);

  const gotoPublish = (values) => {
    if (!publishCount) {
      setIsLoading(false);
      notify({
        message: `There are no actions in publish list`,
        type: "warn",
      });
      return;
    }
    setSaveLoaderInit__publish(true);
    setPublishStatus({
      UserEmail: user.email,
      typeOfMeasure: tabKey == "ZUN" ? "ZUN" :tabKey === "CASES"?"CASES": "CU",
    })
      .then((response) => {
        setSaveLoaderInit__publish(false);
        // props?.loadDropdownsData();
        setIsTableLoading(true);
        setTimeout(
          () => {
            setIsLoading(false);
            setIsTableLoading(true);
            getPendingActionsCount();
          },
          publishCount > 500 ? 10000 : 5000
        );
        setTimeout(() => {
          setIsTableLoading(true);
          getGridData({
            alertTypeId: props.dropDownValues?.alert ?? [],
            countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
            categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
            businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
            plant_code: props.dropDownValues?.plant ?? [],
            nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
            ownerId: props.dropDownValues?.owner ?? [],
            foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
            mrpControllerId: props.dropDownValues?.mrpc ?? [],
            forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
            columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
            page,
            per_page: itemPerPage,
            sortby: SortBy,
            complianceSorting: SortType,
            searchText: searchText ? searchText.trim() : "",
          })
          
           props?.loadDropdownsData();
        },5000);

        setSelection([]);
        notify({
          message: `Sucessfully Updated`,
          type: "success",
        });
      })
      .catch(() => {
        setSaveLoaderInit__publish(false);
        setIsLoading(false);
        notify({
          message: `Adding Publish status failed`,
          type: "error",
        });
      });
    props?.loadDropdownsData();
  };

  const toggleWarnMessageModal = () => {
    notify({
      message: `Please select sku's`,
      type: "warn",
    });
  };

  const getGridData = useCallback((payload, check) => {
    setState("Loading");
    const searchValue = payload?.columnFilterValue ? payload?.columnFilterValue : "";
    let params = "";
    if (searchValue?.trim()?.length > 0) {
      if (searchValue?.includes(",")) {
        params = "?param=commaSeparated"
      } else {
        if (searchValue?.includes(" ")) {
          params = "?param=spaceSeparated"
        }
      }
    }

    PomDetails(payload, params)
      .then((response) => {
        setTimeout(() => {
          setState("Completed");
        }, 200);
        // console.log(props.dropDownValues?.nextStep?.includes("111"))
        // console.log(check)
        let data = check ? response.data.filter((i) => i.nextStep === null) : response.data
        setGridInfo([...data]);
        setActions(response.actions);
        setAdviceList(response.advice);
        setNextStepList(response.nextStep);
        setTotalRecordCount(response.count);
        setTotalCount(response.totalCount);
        setDealStatus(response.dealStatus);
      })
      .catch(() => {
        setState("Completed");
      });
    console.log("two ", props.dropDownValues)
  }, [props.dropDownValues]);

  const getPendingActionsCount = useCallback((payload) => {
    PendingActionsCount(payload).then((response) => {
      setPublishCount(response.pendingActionsCount);
      setTimeout(() => { setIsTableLoading(false) }, 150)

    }).catch(() => {
      setIsTableLoading(false)
    });
  }, []);

  const onActionClick = (val) => {
    if (selection.length === 0) {
      notify({
        message: `Please select one record!`,
        type: "warn",
      });
      return;
    } else {
      addToPublish(val);
    }
  };

  const downloadData = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.reportingFamilyId = props.dropDownValues?.reporting_family ?? []
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomDetails(payload, tabKey).then((response) => {
    });
  };
  const downloaCustomerdData = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomCustomerPomDetails(payload).then((response) => {

    });
  };
  // const handleDownload = useCallback((columnsVisibilitySelected) => {

  console.log(selectedCol, "selectedCol======>")


  console.log(props.dropDownValues)
  useEffect(() => {
    console.log(rowDownloadId)
  }, [rowDownloadId])

  const handleDownload = useCallback(() => {
    let payload = {
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
      plant_code: props.dropDownValues?.plant ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      ownerId: props.dropDownValues?.owner ?? [],
      nextStepId: props.dropDownValues?.nextStep ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      filename: "pom_details",
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      referenceID: rowDownloadId.map((i => i.original.id))
      // columnsSelected : columnsVisibilitySelected,
    }
    if (hasColUpdated) {
      const updatedSelectedCol = selectedCol.filter(item => item?.trim() != "add_customer" && item?.trim() != "view_history")
      console.log(selectedCol)
      const editableArray = ["floorpr", "qty_to_offer", "qtyToOfferCs", "owner", "nextStepId", "note", "m_niv_cs", "advice", "cap", "sales_note", "discount", "price_per_cu", "price_per_case"]
      let array1 = ["customerRefId"];
      let array2 = [];
      updatedSelectedCol.map(item => {
        if (editableArray.includes(item)) {
          array2.push(item)
        } else {
          array1.push(item)
        }
      })
      let combinedArray = [...array1, ...array2]
      let FixedColumns = ["mrpd", "description", "mrdr_id", "plant_code", "status_id", "bbd", "hah_date", "fictive_ssl_date"]
      combinedArray = Array.from(new Set(FixedColumns.concat(combinedArray)));

      const nextStepIndex = combinedArray.findIndex(i => i == "nextStepId")
      if (nextStepIndex != -1) {
        combinedArray[nextStepIndex] = "nextStep";
      }

      payload = {
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        nextStepId: props.dropDownValues?.nextStep ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
        filename: "pom_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
        selectedAttributes: combinedArray.toString(),
        referenceID: rowDownloadId.map((i => i.original.id))
      }
    }

    if (gridInfo.length > 0) {
      downloadData(payload);
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
    selectedCol,
    hasColUpdated,
    rowDownloadId,
  ]);

  const handleDownloadCustomerDetails = useCallback(() => {
    if (gridInfo.length > 0) {
      downloaCustomerdData({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        nextStepId: props.dropDownValues?.nextStep ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        filename: "pom_customer_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);


  const downloadDataBase = (payload) => {
    console.log(payload)
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomDBDetails(payload).then((response) => {
    });
  };
  const handleDataBaseDownload = useCallback(() => {
    console.log(gridInfo)
    if (gridInfo.length > 0) {
      console.log(props.dropDownValues)
      downloadDataBase({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        ownerId: props.dropDownValues?.owner ?? [],
        plant_code: props.dropDownValues?.plant ?? [],
        filename: "DataBase_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);
  const downloadBatchCode = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    getDownloadBatchCodes(payload, tabKey).then((response) => {
    });
  };

  const downloadBatchCodeDetails = () => {

    downloadBatchCode({
      plantCode: [],
      materialno: [],
      countryCode: [],
      reasonCode: [],
      shortShelfLife: [],
      filename: "Batch_Code_details",
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
    })
  }

  const downloadSaleOffer = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomSFDetails(payload, tabKey).then((response) => {
    });
  };
  const handleSaleOfferDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      downloadSaleOffer({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        nextStepId: props.dropDownValues?.nextStep ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        filename: "Internal_sales_info_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);

  const downloadZan = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomZANDetails(payload, tabKey).then((response) => {
    });
  };
  const handleZanDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      downloadZan({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        nextStepId: props.dropDownValues?.nextStep ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        filename: "Pallet_info_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);

  const downloadScreen = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadPomScreenDetails(payload).then((response) => {
    });
  };
  const handleScreenDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      downloadScreen({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        nextStepId: props.dropDownValues?.nextStep ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        filename: "Screen_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);

  const handleFileOpen = () => {
    fileUploadInput.current.click();
  };

  const uploadFileHandler = (event) => {
    const files = event.target.files[0];
    if (files) {
      let formData = new FormData();
      const fileType = files.name.indexOf(".xls") > 0;
      const fileSize = files.size / 1024 / 1024;
      const allowedFileSize = 10;

      if (fileType && fileSize < allowedFileSize) {
        formData.append("file", files);
        formData.append("UserEmail", user.email);
        formData.append("typeOfMeasure", tabKey == 'ZUN' ? "ZUN" :tabKey === 'CASES'?"CASES": "CU");
        setIsShowFilePopup(true)
        setIsFileProcessed(false)
        excelFileUpload(formData)
          .then((response) => {
            if (response?.data?.message == "SUCCESSFULLY_UPLOADED") {
              setIsFileStatusDone(true)
              setUniqueId(response.data.uniqueTaskId)
            }
          })
          .catch((error) => {
            setIsShowFilePopup(false)
            setIsFileProcessed(false)
            setIsFileStatusDone(false)
            notify({
              message: error?.message || error,
              type: "error",
            });
          });
      } else if (fileSize > allowedFileSize) {
        notify({
          message: `File size exceeds ${allowedFileSize} MB`,
          type: "error",
        });
        files.value = null;
      } else {
        notify({
          message: "File format should be Excel only",
          type: "error",
        });
        files.value = null;
      }
    }
  };

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
    //setPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };
  useEffect(() => {
    console.log(selectedCol)
  }, [selectedCol])

  const handleSearch = (val) => {

    setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };

  const fetchData = useCallback(
    ({ pageSize, pageInd }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageInd + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(100);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  useEffect(() => {
    getPendingActionsCount();
  }, [getPendingActionsCount]);

  useEffect(() => {
    console.log(userAccess)
    if (props.dropDownValues && userAccess) {
      console.log("one ", props.dropDownValues)
      // SetColumnSearch({})
      console.log(props.dropDownValues?.nextStep?.includes("111"))
      const payLoadData = {
        reportingFamilyId: props.dropDownValues?.reporting_family ?? [],
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution.filter(i => i !== "all") ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        columnFilter: {},
        page,
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      };
      getGridData(filterKey
        ? ({
          ...payLoadData,
          islbbdFilter: (filterKey === "ssl_percent" || filterKey === "expiry_percent") ? filterText : null,
          datefilter: (filterKey === "hah_date" || filterKey === "bbd") ? filterText : null,
          // columnFilter: filterKey,
          // columnFilterValue: (filterKey === "hah_date" || filterKey === "expiry_percent" || filterKey === "ssl_percent" || filterKey === "bbd") ? null : filterText,
        })
        : payLoadData, props.dropDownValues?.nextStep?.includes("111"));
    }
  }, [getGridData, props.dropDownValues, clearColumnSearch]);
  useEffect(() => {

    if (props.dropDownValues) {
      console.log("one ", props.dropDownValues)
      // SetColumnSearch({})
      console.log(props.dropDownValues?.nextStep?.includes("111"))
      const payLoadData = {
        reportingFamilyId: props.dropDownValues?.reporting_family ?? [],
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
        plant_code: props.dropDownValues?.plant ?? [],
        nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
        ownerId: props.dropDownValues?.owner ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.foodSolution.filter(i => i !== "all") ?? [],
        mrpControllerId: props.dropDownValues?.mrpc ?? [],
        forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
        columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
        page,
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
      };
      getGridData(filterKey
        ? ({
          ...payLoadData,
          islbbdFilter: (filterKey === "ssl_percent" || filterKey === "expiry_percent") ? filterText : null,
          datefilter: (filterKey === "hah_date" || filterKey === "bbd") ? filterText : null,
          // columnFilter: filterKey,
          // columnFilterValue: (filterKey === "hah_date" || filterKey === "expiry_percent" || filterKey === "ssl_percent" || filterKey === "bbd") ? null : filterText,
        })
        : payLoadData, props.dropDownValues?.nextStep?.includes("111"));
    }
  }, [SortBy, SortType, itemPerPage, page]);

  const [bulkUpdateCellPayload, setbulkUpdateCellPayload] = useState([])
  const [bulksearcRemove, setBulkSearchRemomve] = useState(false)
  const bulkUpdateCellData = (row, column, value, key) => {
    setBulkSearchRemomve(!bulksearcRemove)
    console.log(row, column, value)
    if (key) {
      let dummy = []
      row.map((i) => {
        let payload = {
          UserEmail: user.email,
          id: i.original.id,
          referenceID: i.original.mrdr_id,
          plantCode: i.original.plantCodeId,
          statusId: i.original.status_id,
          bbd: i.original.bbd,
          hah_date: i.original.hah_date,
          customerRefId: i.original.customerRefId,
          descriptionId: i.original.description,
          fictive_ssl_date: i.original.fictive_ssl_date,
          nextStep: value,
          nextStepId: key,
        }
        dummy = [...dummy, payload]
        setbulkUpdateCellPayload((prev) => [...prev, payload])
        console.log("sasasas", dummy, PayloadWithFilters)
      })
      bulkUpdatePomCellData({ data: dummy })
        .then(response => {
          row.map((i) => {
            gridInfo?.forEach((val) => {
              if (val.id == i.original.id) {
                val[column.id] = value;
              }
            });
            setGridInfo([...gridInfo]);
          })
          if (response.message === "Successfully Initiated the record") {
            setTimeout(() => {
              getPendingActionsCount();
            }, 150);

            notify({
              message: " Successfully added to Publish List. Click Publish to save all changes",
              type: "success",
            });
          } else {
            notify({
              message: "Something Went Wrong!",
              type: "error",
            });
          }

        }, 100)
        .catch((error) => {
          console.log("error: ", error);
          notify({
            message: "No Response From Server. Please Try Again Later",
            type: "error",
          });
        });
    } else {
      let dummy = []
      row.map((i) => {
        let payload = {
          UserEmail: user.email,
          id: i.original.id,
          referenceID: i.original.mrdr_id,
          plantCode: i.original.plantCodeId,
          statusId: i.original.status_id,
          bbd: i.original.bbd,
          hah_date: i.original.hah_date,
          customerRefId: i.original.customerRefId,
          descriptionId: i.original.description,
          fictive_ssl_date: i.original.fictive_ssl_date,
          owner: value.split(",")
        }
        dummy = [...dummy, payload]
        setbulkUpdateCellPayload((prev) => [...prev, payload])
        console.log("sasasas", dummy, PayloadWithFilters)
      })
      bulkUpdatePomCellData({ data: dummy })
        .then(response => {
          row.map((i) => {
            gridInfo?.forEach((val) => {
              if (val.id == i.original.id) {
                val[column.id] = value;
              }
            });
            setGridInfo([...gridInfo]);
          })
          if (response.message === "Successfully Initiated the record") {
            setTimeout(() => {
              getPendingActionsCount();
            }, 150);

            notify({
              message: " Successfully added to Publish List. Click Publish to save all changes",
              type: "success",
            });
          } else {
            notify({
              message: "Something Went Wrong!",
              type: "error",
            });
          }

        }, 100)
        .catch((error) => {
          console.log("error: ", error);
          notify({
            message: "No Response From Server. Please Try Again Later",
            type: "error",
          });
        });
    }
  }
  useEffect(() => { console.log(bulkUpdateCellPayload) }, [bulkUpdateCellPayload])

  const updateCellData = (row, column, value) => {
    console.log(row, column, value)
    const rowId = row.original.id;
    const updatedValue =
      column.id === "expiry_date" ? moment(value).format("DD-MM-YYYY") : value;
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);
    setClickedIndex(null);
    setClickedCell(null);
    if (
      (value && row.original[column.id] !== value) ||
      (!row.original[column.id] && value) ||
      (!value && row.original[column.id])
    ) {
      row.original[column.id] =
        column.id === "expiry_date"
          ? moment(value).format("DD-MM-YYYY")
          : value;
      setIsTableLoading(true)
      updatePomCellData({
        id: rowId,
        referenceID: row.original.mrdr_id,
        country: row.original.country,
        statusId: row.original.status_id,
        plantCode: row.original.plantCodeId,
        descriptionId: row.original.descriptionId,
        bbd: row.original.bbd==="Invalid date"?"":row.original.bbd,
        key: column.id == 'nextStepId' ? 'nextStep' : column.id,
        hah_date: row.original.hah_date,
        countryId: row.original.countryId,
        customerRefId: row.original.customerRefId,
        total_qty: row.original.total_qty,
        totalQtyCs: row.original.totalQtyCs,
        UserEmail: user.email,
        total_left:
          column.id === "qty_to_offer"
            ? (row.original.qty_to_offer ?? 0) - (row.original.total_qty ?? 0) >
              0
              ? (row.original.qty_to_offer ?? 0) - (row.original.total_qty ?? 0)
              : 0
            : row.original.total_left,
        totalLeftCs:
          column.id === "qtyToOfferCs"
            ? (row.original.qtyToOfferCs ?? 0) -
              (row.original.totalQtyCs ?? 0) >
              0
              ? (row.original.qtyToOfferCs ?? 0) -
              (row.original.totalQtyCs ?? 0)
              : 0
            : row.original.totalLeftCs,
        updatedValue: column.id == 'nextStepId' ? nextStepList?.length && nextStepList.find(i => i.k == updatedValue)?.v : updatedValue,
        nextStepId: column.id == 'nextStepId' ? updatedValue : '',
        fictive_ssl_date: row.original.fictive_ssl_date,
      })
        .then((response) => {
          gridInfo?.forEach((val) => {
            if (val.id == rowId) {
              val[column.id] = updatedValue;
            }
          });
          setGridInfo([...gridInfo]);
          if (response.message === "Successfully Initiated the record") {
            setTimeout(() => {
              getPendingActionsCount();
            }, 150);

            notify({
              message: " Successfully added to Publish List. Click Publish to save all changes",
              type: "success",
            });
          } else {
            notify({
              message: "Something Went Wrong!",
              type: "error",
            });
          }
        }, 100)
        .catch((error) => {
          console.log("error: ", error);
          notify({
            message: "No Response From Server. Please Try Again Later",
            type: "error",
          });
        });
    }
  };
  useEffect(() => {
    refObj?.forEach((key) => {
      // console.log(key)
      if (key[Object.keys(key)[0]].current) {
        if (key[Object.keys(key)[0]].current.state)
          key[Object.keys(key)[0]].current.state.value = "";
        else {
          console.log(key, key[Object.keys(key)[0]].current?.value)
          key[Object.keys(key)[0]].current.value = "";
          console.log(key, key[Object.keys(key)[0]].current?.value)
        }
      }
    });
    setFilterKey('');
    setFilterText('')
  }, [props.dropDownValues, searchText, clearColumnSearch, bulksearcRemove]);
  const filterData = (value, key, isDateSelection) => {
    // setPage(1)    
    setPageInd(0);
    const columnSearchAdd = {
      key: key,
      value: value,
    }
    if (filterKey === '') {
      console.log(filterKey)
      SetColumnSearch({ [columnSearchAdd.key]: columnSearchAdd.value ? columnSearchAdd.value : "" })
    } else {
      SetColumnSearch(prev => {
        const result = { [columnSearchAdd.key]: columnSearchAdd.value ? columnSearchAdd.value : "" }
        return { ...prev, ...result }
      })
    }
    setFilterKey(key);
    setFilterText(value);
    console.log(columnSearchAdd)
    console.log(key, value)

  };
  useEffect(() => {
    console.log(pageInd)
  }, [pageInd])

  useEffect(() => {
    let dateFilter, islbbdFilter;
    console.log(pageInd)
    // setPageInd(0);
    // setPage(1);

    if ("bbd" in columnSearch) {
      dateFilter = columnSearch["bbd"]
    }
    if ("hah_date" in columnSearch) {
      dateFilter = columnSearch["hah_date"]
    }
    if ("expiry_percent" in columnSearch) {
      islbbdFilter = columnSearch["expiry_percent"]
    } else if ("ssl_percent" in columnSearch) {
      islbbdFilter = columnSearch["ssl_percent"]
    }

    getGridData({
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
      plant_code: props.dropDownValues?.plant ?? [],
      nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
      ownerId: props.dropDownValues?.owner ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      page: 1,
      per_page: itemPerPage,
      sortby: SortBy,// isDateSelection ? key :
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      datefilter: dateFilter,
      islbbdFilter: islbbdFilter,
      columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
      // columnFilterValue: columnSearch === "expiry_percent" || columnSearch === "ssl_percent" || columnSearch === "hah_date" || columnSearch === "bbd" ? null : value,
    });
  }, [columnSearch])

  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };
  const reLoad = () => {
    getGridData({
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
      plant_code: props.dropDownValues?.plant ?? [],
      nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
      ownerId: props.dropDownValues?.owner ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      page,
      per_page: itemPerPage,
      sortby: SortBy,
      columnFilter: {},
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
    });
  };

  // const handleColumnsSelected = (e) => {
  //   let columnVisibilitySelected = JSON.parse(localStorage.getItem('columnVisibilitySelected'));
  //   let columnVisibilitySelected_without_all = columnVisibilitySelected.filter(j => j !== 'all');

  //   setTimeout(() => {
  //     if(downloadClicked !== 0){
  //         handleDownload(columnVisibilitySelected_without_all);}
  //   }, 1000);
  // }

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    bulkUpdateCellData,
    adviceList,
    actions,
    nextStepList,
    filterData,
    reLoad,
    refObj
  };

  return (
    <MonthList.Provider value={dealMonths}>
      <div className="main-body">
        <div className="row">
          <div className="">
            <div className="table-actions">
              <button
                type="button"
                disabled={isLoading || isSetSaveLoaderInit__publish||userAccess?.pomdata?.data?.accessId === "1" ? true : false}
                // disabled={isLoading || isSetSaveLoaderInit__publish}
                className={`btn btn-slim 
                  ${publishCount === 0
                    ? 'btn-published'
                    : 'btn-download btn-published btn-published-with-badge cursor-pointer'} ${isSetSaveLoaderInit__publish ? 'processing' : ''}`}
                onClick={() => {
                  setIsTableLoading(true)
                  setPublishLoading(true);
                  setIsLoading(true);
                  setTimeout(() => {
                    gotoPublish();
                    setPublishLoading(false);
                  }, 50);
                  setTimeout(() => {
                    setPublishLoading(false);
                  }, 5000);
                }}
              >
                Publish
                {publishCount !== 0 && (
                  <span className="badge badge-light">{publishCount}</span>
                )}
              </button>
              <button
                type="file"
                onClick={handleFileOpen}
                className="btn-slim btn-download"
                style={{ backgroundColor: "yellow" }}
                disabled={userAccess?.pomdata?.data?.accessId === "1"? true : false}
              >
                <img src={UploadIcon} alt="" /> Upload
              </button>
              <input
                type="file"
                name="file"
                id="input_uploadFileHandler"
                ref={fileUploadInput}
                onChange={uploadFileHandler}
                className="btn-download d-none"
              />
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={handleDataBaseDownload}
                title="A complete download."
              >
                <img src={DownloadIcon} alt="" />
                Database
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={downloadBatchCodeDetails}
                title="Shows Batch Codes."
              >
                <img src={DownloadIcon} alt="" />
                Batch Code
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={handleSaleOfferDownload}
                title="Shows additional sales offer data, under which EAN codes and the cost price."
              >
                <img src={DownloadIcon} alt="" />
                Internal Sales Info
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={handleZanDownload}
                title="Shows additional sales offer data, under which pallet info (to manage full truck loads)."
              >
                <img src={DownloadIcon} alt="" />
                Pallet Info
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                // onClick={()=>{
                //   setDownloadClicked(Math.random(300));
                // }}
                onClick={handleDownload}
                title="Downloads all data based on filters selected."
                style={{ backgroundColor: "yellow" }}
              >
                <img src={DownloadIcon} alt="" />
                Download
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={handleDownloadCustomerDetails}
                title="Shows the customer details with regards to the sales offer status."
              >
                <img src={DownloadIcon} alt="" />
                Customer Details
              </button>
            </div>
          </div>
          <StatusPopup
            onRefreshClick={() => {
              fileUpdateChecker(isFileProcessed ? "download" : "upload");
            }}
            show={isShowFilePopup}
            onHide={(value) => {
              setIsShowFilePopup(value);
            }}
          ></StatusPopup>
          <div>
            <div className="body-container  body-container-table-with-pagination waste_risk_details_page ">
              <div className="d-block nav-tabs-wrap nav-tabs-wrap-compact-spacing ">


                <Tabs
                  id="controlled-tab-example"
                  activeKey={tabKey}
                  onSelect={(k) => {
                    setDownloadClicked(0)
                    if (k != tabKey) {
                      setFilterKey("");
                      setFilterText("");
                      fileUploadInput?.current?.reset?.();
                    }
                    setTabKey(k);
                    setSearchText('')
                    setGridInfo(["Loading"]);
                    setTimeout(() => {
                      if (props.dropDownValues) {
                        const payLoadData = {
                          alertTypeId: props.dropDownValues?.alert ?? [],
                          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
                          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
                          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
                          plant_code: props.dropDownValues?.plant ?? [],
                          nextStepId: props.dropDownValues?.nextStep?.filter((i) => i !== "111") ?? [],
                          ownerId: props.dropDownValues?.owner ?? [],
                          foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
                          mrpControllerId: props.dropDownValues?.mrpc ?? [],
                          forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
                          page,
                          columnFilter: {},
                          per_page: itemPerPage,
                          sortby: SortBy,
                          complianceSorting: SortType,
                          searchText: "",
                        };
                        getGridData(filterKey
                          ? {
                            ...payLoadData,
                            islbbdFilter: (filterKey === "ssl_percent" || filterKey === "expiry_percent") ? filterText : null,
                            datefilter: (filterKey === "hah_date" || filterKey === "bbd") ? filterText : null,
                            // columnFilter: filterKey,
                            // columnFilterValue: (filterKey === "hah_date" ||filterKey === "expiry_percent"||filterKey === "ssl_percent" ||filterKey === "bbd") ? null : filterText,
                          }
                          : payLoadData);
                      }
                      return;
                    }, 50);
                  }}
                  //     transform: translateY(-38px);
                  className="mb-3 "
                >
                  <Tab eventKey="ZUN" title="ZUN">
                    {tabKey === "ZUN" && (
                      <div>
                        {gridInfo[0] !== "Loading" && (
                          <>

                            {gridInfo && (
                              <TableGrid
                                state={state}
                                columns={zunColumns}
                                pageInd={pageInd}
                                setPageInd={setPageInd}
                                data={gridInfo}
                                SortBy={SortBy}
                                publishLoading={publishLoading}
                                SortType={SortType}
                                setSortBy={setSortBy}
                                setSortType={setSortType}
                                fetchData={fetchData}
                                pageCount={pageCount}
                                totalRecordCount={totalRecordCount}
                                pageSizeNo={itemPerPage}
                                handleItemPerPage={handleItemPerPage}
                                handlePaginationReset={handlePaginationReset}
                                handleSearch={handleSearch}
                                handleEnterSearch={handleEnterSearch}
                                handleSearchReset={handleSearchReset}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setSelection={setSelection}
                                selection={selection}
                                toggleSelection={toggleSelection}
                                actions={actions}
                                onActionClick={onActionClick}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                getGridData={getGridData}
                                {...editCellProps}
                                props={props}
                                setIsSelectAll={setIsSelectAll}
                                toggleExpandCollapse={props.toggleExpandCollapse}
                                isExpand={props.isExpand}
                                toggleWarnMessageModal={toggleWarnMessageModal}
                                totalCount={totalCount}
                                dealStatus={dealStatus}
                                dropDownValues={props.dropDownValues}
                                isTableLoading={isTableLoading}
                                refObj={refObj}
                                setRefObj={setRefObj}
                                tabKey={tabKey}
                                SetColumnSelected={props.SetColumnSelected}
                                setSelectedCol={setSelectedCol}
                                setHasColUpdated={setHasColUpdated}
                                setRowDownloadIds={setRowDownloadIds}
                                rowDownloadId={rowDownloadId}
                                setColumnClearSearch={setColumnClearSearch}
                                clearColumnSearch={clearColumnSearch}
                              // downloadClicked={downloadClicked}
                              // handleColumnsSelected={handleColumnsSelected}
                              />
                            )}
                          </>
                        )}
                        {gridInfo[0] === "Loading" && <Loader />}
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="CU" title="CU">
                    {/* {key === 'Layer_3' && <LayerThreeTable />} */}
                    {tabKey === "CU" && (
                      <div>
                        {gridInfo[0] !== "Loading" && (
                          <>
                            {gridInfo && (
                              <TableGrid
                                state={state}
                                columns={cuColumns}
                                pageInd={pageInd}
                                setPageInd={setPageInd}
                                data={gridInfo}
                                SortBy={SortBy}
                                publishLoading={publishLoading}
                                SortType={SortType}
                                setSortBy={setSortBy}
                                setSortType={setSortType}
                                fetchData={fetchData}
                                pageCount={pageCount}
                                totalRecordCount={totalRecordCount}
                                pageSizeNo={itemPerPage}
                                // loading={loading}
                                handleItemPerPage={handleItemPerPage}
                                handlePaginationReset={handlePaginationReset}
                                handleSearch={handleSearch}
                                handleEnterSearch={handleEnterSearch}
                                handleSearchReset={handleSearchReset}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setSelection={setSelection}
                                selection={selection}
                                toggleSelection={toggleSelection}
                                actions={actions}
                                onActionClick={onActionClick}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                getGridData={getGridData}
                                {...editCellProps}
                                props={props}
                                setIsSelectAll={setIsSelectAll}
                                toggleExpandCollapse={props.toggleExpandCollapse}
                                isExpand={props.isExpand}
                                toggleWarnMessageModal={toggleWarnMessageModal}
                                totalCount={totalCount}
                                dealStatus={dealStatus}
                                dropDownValues={props.dropDownValues}
                                isTableLoading={isTableLoading}
                                refObj={refObj}
                                setRefObj={setRefObj}
                                tabKey={tabKey}
                                SetColumnSelected={props.SetColumnSelected}
                                setSelectedCol={setSelectedCol}
                                setHasColUpdated={setHasColUpdated}
                                setRowDownloadIds={setRowDownloadIds}
                                rowDownloadId={rowDownloadId}
                                setColumnClearSearch={setColumnClearSearch}
                                clearColumnSearch={clearColumnSearch}
                              // downloadClicked={downloadClicked}
                              // handleColumnsSelected={handleColumnsSelected}
                              />
                            )}
                          </>
                        )}
                        {gridInfo[0] === "Loading" && <Loader />}
                      </div>
                    )}
                  </Tab>
                  <Tab eventKey="CASES" title="CASES">
                    {/* {key === 'Layer_3' && <LayerThreeTable />} */}
                    {tabKey === "CASES" && (
                      <div>
                        {gridInfo[0] !== "Loading" && (
                          <>
                            {gridInfo && (
                              <TableGrid
                                state={state}
                                columns={caseColumn}
                                pageInd={pageInd}
                                setPageInd={setPageInd}
                                data={gridInfo}
                                SortBy={SortBy}
                                publishLoading={publishLoading}
                                SortType={SortType}
                                setSortBy={setSortBy}
                                setSortType={setSortType}
                                fetchData={fetchData}
                                pageCount={pageCount}
                                totalRecordCount={totalRecordCount}
                                pageSizeNo={itemPerPage}
                                // loading={loading}
                                handleItemPerPage={handleItemPerPage}
                                handlePaginationReset={handlePaginationReset}
                                handleSearch={handleSearch}
                                handleEnterSearch={handleEnterSearch}
                                handleSearchReset={handleSearchReset}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setSelection={setSelection}
                                selection={selection}
                                toggleSelection={toggleSelection}
                                actions={actions}
                                onActionClick={onActionClick}
                                searchText={searchText}
                                setSearchText={setSearchText}
                                getGridData={getGridData}
                                {...editCellProps}
                                props={props}
                                setIsSelectAll={setIsSelectAll}
                                toggleExpandCollapse={props.toggleExpandCollapse}
                                isExpand={props.isExpand}
                                toggleWarnMessageModal={toggleWarnMessageModal}
                                totalCount={totalCount}
                                dealStatus={dealStatus}
                                dropDownValues={props.dropDownValues}
                                isTableLoading={isTableLoading}
                                refObj={refObj}
                                setRefObj={setRefObj}
                                tabKey={tabKey}
                                SetColumnSelected={props.SetColumnSelected}
                                setSelectedCol={setSelectedCol}
                                setHasColUpdated={setHasColUpdated}
                                setRowDownloadIds={setRowDownloadIds}
                                rowDownloadId={rowDownloadId}
                                setColumnClearSearch={setColumnClearSearch}
                                clearColumnSearch={clearColumnSearch}
                              // downloadClicked={downloadClicked}
                              // handleColumnsSelected={handleColumnsSelected}
                              />
                            )}
                          </>
                        )}
                        {gridInfo[0] === "Loading" && <Loader />}
                      </div>
                    )}
                  </Tab>
                </Tabs>

              </div>
            </div>
          </div>
        </div>
      </div>
    </MonthList.Provider>
  );
};
export default PomTable;