import React, { useState } from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LegendMarginPlugin } from '../../InflowRiskVisibility/plugins';
import Dropdown from '../../../Views/common/Dropdown';
import MitigationActionBarGraph from './MitigationActionBarGraph';
import NegitiveBiasBarChart from './NegitiveBiasBarChart';
import InflowModelCharts from './InflowModelCharts';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);

function Summary1(props) {
    const [dropDownValues, setDropDownValues] = useState();
    const [filterOptions, setFilterOptions] = useState();
    const [reload, setReload] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [chartType, setChartType] = useState("");
    const handleClose = () => setShowModal(false);

    const openModel = (charttype) => {
        setChartType(charttype)
        setShowModal(true)
    }
    return (
        <>
            <Dropdown
                list={[
                    "Business Group",
                    "FoodSolutionIndicator",
                    "Country",
                    "Plant Code",
                    "Category",
                    "Brand",
                    "Action Type",
                    "Risk Type",
                    "DOH vs ISL Risk Level"

                ]}
                onChange={(value) => {
                    setDropDownValues({ ...value });
                }}
                setDropDownValues={setDropDownValues}
                reload={reload}
                setReload={setReload}
                setFilterOptions={setFilterOptions}
            />
            <br></br>
            <div className="page-body-top-content">
                <div className="d-block nav-tabs-wrap ">
                    <ul className="nav nav nav-tabs bg-waste bg-waste-margin right30">
                        <li className="nav-item">
                            <button
                                type="button"
                                className="nav-link "
                                data-bs-toggle="tab"
                                data-bs-target="#Summary-View"
                                href="##"
                                onClick={() => props.setScreenView(1)}
                            >
                                Risk
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                className="nav-link active"
                                data-bs-toggle="tab"
                                data-bs-target="#Table-View"
                                href="##"
                                onClick={() => props.setScreenView(2)}
                            >
                                Forecast Bias 
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main-body pom-summary-body">
                <div className="row">
                    <div className="col-md-6  mt-4 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3> Mitigation Actions</h3>
                                    <p>Count of material codes</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("MitigationActionBarGraph")}>
                                    <MitigationActionBarGraph className="chartbox" dropdownValues={dropDownValues} />
                                </diV>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6  mt-4 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3> Negative Bias</h3>
                                    <p>Count of material codes</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("NegitiveBiasBarChart")}>
                                    <NegitiveBiasBarChart className="chartbox" dropdownValues={dropDownValues} />
                                </diV>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <InflowModelCharts showModal={showModal} handleClose={handleClose} chartType={chartType} dropDownValues={dropDownValues} />
        </>

    )

}

export default Summary1