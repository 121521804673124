import { LegendMarginPlugin } from "../PomSummary/plugins";
import "chart.js/auto";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Popup from 'reactjs-popup'
import ULModal from '../../../Common/Modal';
import NextStepChart from './NextStepChart';
// import PerStatusIdDescription from "./PerStatusIdDescription";
import PastWeekLineChart from "./PastWeekLineChart";
import ComingWeekLineChart from "./ComingWeekLineChart";
import ObsoletesChart from "./ObsoletesChart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);



function Modelcharts(props) {

    const displayType = "popupDisplay"
    const modalSize = props.chartType === "ObsoletesValueSalesMitigation" ? "lg" : "xl";

    return (
        <div >
            <ULModal
                show={props.showModal}
                onHide={props.handleClose}
                size={modalSize}
                isheader="true"
                className={`page-modal`}
                heading={props.chartType === "NextStepChart" ? "Obsoletes value - Glidepath" :  props.chartType === "ObsoletesValueSalesMitigation" ? "Obsoletes Value - Sales Mitigation %" :  props.chartType === "PastWeekLineChart" ? "Obsoletes value - weekly snapshot" : "Obsoletes value - future projection"}
                subheading={props.chartType === "PastWeekLineChart" ? "(historic)" : props.chartType === "ComingWeekLineChart" ? "(today's POM data)" : ""}
                subheadingInSameLine={true}
            >   
                {props.chartType === "NextStepChart" && <div >
                    <div className='smallgraphbg'>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <NextStepChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {/* {props.chartType === "PerStatusIdDescription" && <div >
                    <div className="smallgraphbg">
                        <div className="graphtitle">
                            <h3>Status ID Description</h3>
                        </div>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <PerStatusIdDescription dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>} */}


                {props.chartType === "ObsoletesValueSalesMitigation" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ObsoletesChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {props.chartType === "PastWeekLineChart" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <PastWeekLineChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}
                
                {props.chartType === "ComingWeekLineChart" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <ComingWeekLineChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

            </ULModal>
            
        </div>
    )
}

export default Modelcharts