import React, { useCallback, useContext, useEffect, useState } from "react";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import { withRouter } from "react-router";
import CentralDealManagementDetailsTable from ".";
import EditBiddingList from "./EditBiddingList";
import Grid from "@material-ui/core/Grid";
import Dropdown from "../../Views/common/Dropdown";
import { useHistory } from "react-router-dom";
import BiddingListBar from "./common/BiddingListBar";
import { getBiddingListName } from "../../Services/BiddingPortalService";
import { UserContext } from "../Application";
import { setbiddingStatusClass, setbiddingStatusGroup } from "../../utility/constants";
import Crtracker from "../../Common/crtracker/Crtracker";
import _ from "lodash";
const CentralDealManagementDetails = (props) => {
  const user = useContext(UserContext);
  const [dropDownValues, setDropDownValues] = useState();
  const [dealList, setDealList] = useState([]);
  const history = useHistory();
  const [isExpand, setIsExpand] = useState(false);
  const [isSidebarExpand, setSidebarExpand] = useState(false);
  const [biddingList, setBiddingList] = useState({});
  const [isEditBiddingList, setIsEditBiddingList] = useState(false);
  const [editedBiddingListData, setEditedBiddingListData] = useState({});
  const [isHandleToggle, setHandleToggle] = useState(null);
  const [newListName, setNewListName] = useState("");
  const [editBuddingListDealType,setEditBuddingListDealType] =useState()
  var interval;
  // const [isNewListCreated, setNewListCreated] = useState(false);
  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };

  const handleSidebarExpand = (sidebarExpandStatus) => {
    setSidebarExpand(sidebarExpandStatus);
  };

  // const handleNewListCreation = (isNewListCreated) => {
  //   console.log("handleSidebarExpand", isNewListCreated);
  //   setNewListCreated(isNewListCreated);
  // };

  const BiddingListName = useCallback((payload) => {
    getBiddingListName(payload, true).then((response) => {
      var bd = (response.bidding_list ?? response);
      Object.keys(response.bidding_list ? response.bidding_list : (response ? response : {}))?.map(
        (key, index) => {
          if (
            bd[key] !== "number" &&
            bd[key]?.length > 0
          ) {
            bd[key]["sg"] = [];
            bd[key]["sg"]["a"] = [];
            bd[key]["sg"]["b"] = [];
            bd[key]["sg"]["c"] = [];
            bd[key]["sg"]["d"] = [];
            bd[key].map((w, id) => {
              w.detail = {
                BiddingStatus: bd[key][id]
                  ?.BiddingStatus,
                biddingStatusClass: "pending",
              };

              let biddingStatus = bd[key][id]
                ?.BiddingStatus;
              let biddingStatusM = biddingStatus.toLowerCase();
              let biddingStatusClass = setbiddingStatusClass(biddingStatusM);
              w.detail.biddingStatusClass = biddingStatusClass;
              let biddingStatusGroup = setbiddingStatusGroup(biddingStatusM);
              w.StatusGroup = biddingStatusGroup;
              // console.log("bidding-list-arr",bd,w)
              bd[key]["sg"][biddingStatusGroup]?.push(w);
            });
          }
        }
      );
      setBiddingList({ ...bd });
    });

    // Object.keys(props.biddingList ?? {})?.map((key, index) => {
    //   if(props.biddingList[key] !== "number" && props.biddingList[key]?.length > 0) {
    //       props.biddingList[key].sorted = _.orderBy(props.biddingList[key], ['StatusGroup'], ['desc']);
    //   }
    // });
  }, []);

  // useEffect(()=>{
  //   BiddingListName({ vendorEmail: user.email || user.username });
  // },[])

  const handleToggle = (dd) => {
    console.log("handleToggle", dd);
    setHandleToggle(dd);
  }

  const handleCreatedBiddingList = (dd) => {
    setNewListName('');
    // setHandleToggle(false);
    console.log("New List Name", dd);
    setNewListName(dd);
    // setHandleToggle(true);
    setIsExpand(true);
  }

  useEffect(() => {
    if ((user.email || user.username) && (history.location.state?.heading ||
      history.location.state?.dealId))
      BiddingListName({ vendorEmail: user.email || user.username });
  }, [isHandleToggle, !isHandleToggle, newListName, newListName.length > 0])

  useEffect(() => {
    if (interval) clearInterval(interval);
    console.log("mmm", biddingList)
    if ((user.email || user.username) && (history.location.state?.heading ||
      history.location.state?.dealId)) {
      interval = setInterval(() => {
        BiddingListName({ vendorEmail: user.email || user.username });
      }, 12000); //set your time here. repeat every 5 seconds
      return () => clearInterval(interval);
    }
  }, [user])

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className={`page-central-deal-management container-fluid tablegrid-height-fluid  pl-0 pr-0 page-has-sidebar-${history.location.state?.heading !== undefined || history.location.state?.dealId !== undefined}`}>
        <div className="main_header align-baseline page-el">
          <Header isNotProduction={!isProd()} />
        </div>
        <div
          className={`main-content-row ${isExpand ? "table-expanded" : ""} ${isSidebarExpand ? "sidebar-expanded" : ""
            }`}
        >
          <div className="side-menu">
            <SideMenu />
          </div>
          <div className="main-div-content main-div-content-sidebar">
            {/* <div class="page-body-top-content">
              <Crtracker />
            </div> */}
            {!isExpand && (
              <div className="page-el w-100">
                <Dropdown
                  list={["Business Group", "MCO", "Country", "Category", "Brand"]}
                  setDealList={(val) => {
                    setDealList([...val]);
                  }}
                  setDropDownValues={(value) => {
                    setDropDownValues({ ...value });
                  }}
                />
              </div>
            )}

            {/* <Grid /> */}

            {(isEditBiddingList && (
              <EditBiddingList
                dropDownValues={dropDownValues}
                toggleExpandCollapse={toggleExpandCollapse}
                setBiddingList={setBiddingList}
                isExpand={isExpand}
                dealList={[
                  ...dealList.filter(
                    (i) => i.key === history.location.state?.dealId
                  ),
                ]}
                dealId={editBuddingListDealType}
                heading={history.location.state?.heading}
                editedBiddingListData={editedBiddingListData}
                setIsEditBiddingList={setIsEditBiddingList}
                onToggle={handleToggle}
              />
            )) || (
                <CentralDealManagementDetailsTable
                  dropDownValues={dropDownValues}
                  toggleExpandCollapse={toggleExpandCollapse}
                  setBiddingList={setBiddingList}
                  isExpand={isExpand}
                  // onNewListCreation={handleNewListCreation}
                  dealList={[
                    ...dealList.filter(
                      (i) => i.key === history.location.state?.dealId
                    ),
                  ]}
                  dealId={history.location.state?.dealId}
                  heading={history.location.state?.heading}
                  onNewListCreation={handleCreatedBiddingList}
                // onCreatedBiddingList={handleCreatedBiddingList}
                />
              )}
          </div>
          {(history.location.state?.heading ||
            history.location.state?.dealId) && (
              <div id="sidebar">
                {/* Bidding list side bar right side */}
                <BiddingListBar
                  setEditBuddingListDealType={setEditBuddingListDealType}
                  onSidebarExpand={handleSidebarExpand}
                  newListName={newListName}
                  biddingList={biddingList}
                  reload={() => {
                    BiddingListName({ vendorEmail: user.email || user.username });
                  }}
                  setIsEditBiddingList={setIsEditBiddingList}
                  setEditedBiddingListData={setEditedBiddingListData}
                  isForVendor={true}
                  onToggle={handleToggle}
                />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default withRouter(CentralDealManagementDetails);
