import axios from "axios";
import { API } from "../backend";
// export const saveRecord = async (payload, isUser) => {
//   try {
//     let response = await axios.post(`${API}/store/createGroupUser`, payload);
//     return response;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };
export const getGroupUser = async (payload, isUser) => {
  try {
    let response = await axios.get(`${API}/store/saveType`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const createGroupUser = async (payload, isUser) => {
  try {
    let response = await axios.post(`${API}/store/createGroupUser`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const updateGroupUser = async (payload, isUser) => {
  try {
    let response = await axios.put(`${API}/store/updateGroupUser`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const createCustomer = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/createCustomer`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
    return err;
  }
};

// export const viewCustomers = async () => {
//   try {
//     let response = await axios.post(`${API}/store/viewCustomers`, payload);
//     return response;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };

export const getCustomers = async (email) => {
  try {
    var response;
    (email != undefined) ? 
    response = await axios.get(`${API}/store/getCustomers?email=${email}`) : 
    response = await axios.get(`${API}/store/getCustomers`)
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const getUserMyAccess = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/getMyAccessDetails`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getUserGroups = async (params) =>{
  try{
    const response = await axios.get(`${API}/store/getmyGroupUsers`,{params});
    return response.data;
  }catch(err){
    console.log("Error: ", err)
  }
}

export const getNotificationSettings = async (params) =>{
  try{
    let notificationsAPI = `${API}/settings/notificationsSetting?email=${params.email}&notificationType=${params.notificationType}`;

    let notificationsGroupAPI = `${API}/settings/notificationsSetting?email=${params.email}&notificationType=${params.notificationType}&GroupId=${params.GroupId}`;
    
    const response = await axios.get( params.GroupId ? notificationsGroupAPI : notificationsAPI);
    return response.data;
  }catch(err){
    console.log("Error: ", err)
  }
}

export const updateNotificationSettings = async (payload) =>{
  try{
    const response = await axios.put(`${API}/settings/updateNotificationsSetting`,payload); 
    return response.data;
  }catch(err){
    console.log("Error: ", err)
  }
}

export const updateMyGroupUsers = async (payload) =>{
  try{
    const response = await axios.put(`${API}/store/updatemyGroupUsers`,payload); 
    return response.data;
  }catch(err){
    console.log("Error: ", err)
  }
}

export const getUserPermissionDetails = async(payload) => {
  try{
    const response = await axios.post(`${API}/store/getMyAccessDetails`,payload)
    return response.data
  }
  catch(err){
    console.log("Error: ",err)
  }
}
export const deleteSavedCustomer = async (customerEmail) => {
  try {
    const response = await axios.delete(`${API}/store/deleteCustomer/${customerEmail}`);
   // ,{params:{customerEmail:customerEmail}}
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getCustDetails = async (payload) => {
  try {
    let response = await axios.get(`${API}/store/getCustomerById`,{params:{customerEmail:payload}});
    return response;
  } catch (err) {
    console.log("Error: ", err);
    return err;
  }
};

export const updateCustomer = async (payload) => {
  try {
    let response = await axios.put(`${API}/store/updateCustomer`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
    return err;
  }
}
