import { Button } from 'react-bootstrap';
import React from 'react';

const ULButton = (props) => {
  return (
    <Button {...props}>
      {props.label}
      {props.icon && (
        <i className={props.icon}></i>
      )}
      {props.imgicon && (
        <img src={props.imgicon} alt="" title="" className="btnImgIcon" />
      )}
    </Button>
  );
};

export default ULButton;
