import { ImagePlaceHolder, EditCell } from "./CellAction"; //CellCheckBox
export const COLUMNS = [
  {
    Header: "SKU/Description",
    accessor: "material_description",
    show: true,
    editable: false,
    Cell: ImagePlaceHolder,
  },
  {
    Header: "MCO",
    accessor: "mco",
    show: true,
    editable: false,
  },
  {
    Header: "Expiry Date",
    accessor: "expiry_date",
    show: true,
    editable: false,
  },
  {
    Header: "Quantity Available",
    accessor: "quantity_available",
    show: true,
    editable: false,
  },
  {
    Header: "Offer Price",
    accessor: "offer_price",
    show: true,
    editable: true,
    Cell: EditCell,
  },
];
