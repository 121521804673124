import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

import { withRouter } from "react-router";
import "./Landing.scss";
import Dropdown from "./common/Dropdown";
//import Table from "./common/Table";
import SideMenu from "./common/SideMenu";
import Header from "../Common/header";
import Crtracker from "../Common/crtracker/Crtracker";
import WasteRiskDetailsTable from "./WasteRiskDetailsTable";
import { getWasteRiskDetailUpdatedDate, getfgslobUserPreferences, savefgslobUserPreferences } from "../Services/WasteRiskDetailServices";
import { UserContext } from "../Components/Application";

const Landing = (props) => {
  const [dropDownValues, setDropDownValues] = useState();
  const [isExpand, setIsExpand] = useState(false);
  const [updateTime, setUpdatedTime] = useState("");
  const [reload, setReload] = useState(false)

  const [dropDownPreference,setDropDownPreference] = useState();
  const [userSavePreferenceData, setUserSavePreferenceData]=useState()
  const [columnSelected,SetColumnSelected]= useState()
  const user = useContext(UserContext)
  const [reeload,setreeload] = useState(false)

  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };
  const getUpdateDate = async () => {
    try {
      const updateDatedata = await getWasteRiskDetailUpdatedDate();
      setUpdatedTime(updateDatedata.update_date); // Assuming the key for update_date is "update_date" in the response data
    } catch (error) {
      console.error("Error while fetching update_date:", error);
    }
  };
  const handleSavePreference =()=>{
    const data={
      preferenceData:dropDownPreference?dropDownPreference:{alert:[],
        businessGroup:[],
        category:[],
        country:[],
        foodSolution:[],
        forecastFamily:[],
        mrpc:[],
        nextStep:[],
        plant:[],
        reporting_family:[],},
        selectedColumns:columnSelected?columnSelected:window.sessionStorage.getItem("preferences")?JSON.parse(window.sessionStorage.getItem("preferences")).selectedColumns:["all"]
      }
    const payload = {
      userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
      userRole:user.userRole,
      userEmail:user.email,
      userPreference:data
    }
    console.log(payload)
    savefgslobUserPreferences(payload).then((res)=>console.log(res))

  }
  const [ asddd,setasddd] =useState(false)
  
  useEffect(()=>{
    const dummy={alert:[],
      businessGroup:[],
      category:[],
      country:[],
      foodSolution:[],
      forecastFamily:[],
      mrpc:[],
      nextStep:[],
      plant:[],
      reporting_family:[],}
    getfgslobUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }    
      else{
        const payload = {
          userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
          userRole:user.userRole,
          userEmail:user.email,
          userPreference:{ preferenceData:dummy,
            selectedColumns:["all"]
          }}          
        console.log(payload)
        savefgslobUserPreferences(payload).then((res)=>{setreeload(!reeload)})
        console.log(dummy)
        window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:dummy,selectedColumns:['all']}))
      }  
      
    }
  )
  },[user])

  useEffect(()=>{
    getfgslobUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }     
    }
  )
  },[reeload])

  useEffect(() => {
    getUpdateDate();
  }, []);
  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost:3000')) {
      return true
    } else {
      return false
    }
  }
  return (
    <div className="container-fluid tablegrid-height-fluid pl-0 pr-0">
      <div className="main_header align-baseline page-el">
        <Header isNotProduction={!isProd()}  updateTime={updateTime} updateTimeText={'Update Date: '} />
      </div>
      <div className={`main-content-row ${isExpand ? "table-expanded" : ""}`}>
        <div className="side-menu">
          <SideMenu />
        </div>
        <div className="main-div-content">
          {/* <div class="page-body-top-content">
            {updateTime && <div class="main-update-date">
              <p className="m-0">Update Date: {updateTime}</p>
            </div>}
            <div className="d-inline-block ml-3">
              <Crtracker />
            </div>
          </div> */}
          {!isExpand && (
            <div className="page-el">
              {asddd && <Dropdown
                list={["Business Group", "MCO", "Country", "Category", "Brand", "Residual Status", "Deal Type", "Plant Code", "Language on Pack"]}
                onChange={(value) => {
                  setDropDownValues({ ...value });
                }}
                setDropDownValues={setDropDownValues}
                reload={reload}
                setReload={setReload}
                setDropDownPreference={setDropDownPreference}
                handleSavePreference={handleSavePreference}
                userSavePreferenceData={userSavePreferenceData}
                showPrefe={true}
              />}

            </div>
          )}

          <Grid />
          {/* <Table dropDownValues={dropDownValues} /> */}
          {asddd &&<WasteRiskDetailsTable
            dropDownValues={dropDownValues}
            isExpand={isExpand} qq
            toggleExpandCollapse={toggleExpandCollapse}
            loadDropdownsData={() => {
              setReload(true)
            }}
            SetColumnSelected={SetColumnSelected}
          />}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Landing);