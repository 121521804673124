import React, { useRef, useState } from "react";
import NextStepChart from "./NextStepChart";
// import PerStatusIdDescription from "./PerStatusIdDescription";
import PastWeekLineChart from "./PastWeekLineChart";
import DealStatusXChart from "../../InflowRiskVisibility/common/DealStatusXChart";
import DealStatusYChart from "../../InflowRiskVisibility/common/DealStatusYChart";
import ComingWeekLineChart from "./ComingWeekLineChart";
import Modelcharts from "./Modalcharts";
import SummaryTable from "../../InflowRiskVisibility/common/SummaryTable";
import ObsoletesChart from "./ObsoletesChart";
import { values } from "lodash";
import PomDealStatusYChart from "./PomDealStatusYChart";
import PomDealStatusXChart from "./PomDealStatusXChart";

const StatusPomSummaryTable = ({ dropDownValues, filterOptions }) => {
  const [showModal, setShowModal] = useState(false);
  const [chartType, setChartType] = useState("");
  const [selectedCountry, setCountry] = useState();
  const handleClose = () => setShowModal(false);
  const openModel = (charttype) => {
    setChartType(charttype);
    setShowModal(true);
  };

  let getNextStepData = (val) => {
    console.log("chartdata -->", val);
  };

  const handleCountrySelection = (mcoValue) => {
    console.log(mcoValue, filterOptions);
    const countryKey = filterOptions?.country.find(
      (m) => m.value === mcoValue
    )?.key;
    console.log(countryKey);
    setCountry(countryKey);
  };

  return (
    <div className="main-body pom-summary-body">
      <div className="graphtable">
        <div className="row">
          <div className="col-md-6">
            <div className="graphtitle">
              <h3>Action Plan Summary</h3>
              <p>Current Values, in Million Euros</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-block nav-tabs-wrap ">
              <ul className="nav nav nav-tabs bg-waste bg-waste-margin right30">
                <li className="nav-item">
                  <button
                    type="button"
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#Summary-View"
                    href="##"
                  >
                    Summary
                  </button>
                </li>
                {/* <li className="nav-item">
										<button
											type="button"
											className="nav-link"
											data-bs-toggle="tab"
											data-bs-target="#Table-View"
											href="##"
										>
											Table
										</button>
									</li> */}
              </ul>
            </div>
          </div>

          <div className="tab-content mt-2" id="tabContent">
            <div
              className="tab-pane  active"
              id="Summary-View"
              role="tabpanel"
              aria-labelledby="Summary-View"
            >
              <div className="row risk-summary-1">
                <div className="col-md-4 linegraph">
                  {/* <ComingWeekLineChart dropDownValues={dropDownValues} type={"dealStatusVertical"}/> */}
                  <PomDealStatusYChart
                    dropDownValues={dropDownValues}
                    selectedCountry={selectedCountry}
                    setCountry={handleCountrySelection}
                  />
                </div>
                <div className="col-md-8 ps-4">
                  {/* <ComingWeekLineChart
                                        dropDownValues={dropDownValues}
                                        type={"dealStatusHorizontal"}
                                    /> */}
                  <PomDealStatusXChart
                    dropDownValues={dropDownValues}
                    selectedCountry={selectedCountry}
                  />
                </div>
              </div>
            </div>

            {/* <div
								className="tab-pane "
								id="Table-View"
								role="tabpanel"
								aria-labelledby="Table-View"
							>
								<div className="row">
									<div className="col-md-12">
										<SummaryTable dropDownValues={dropDownValues} />
									</div>
								</div>
							</div> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 mt-4 mb-4">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3 className="text-center">
                Obsoletes value - weekly snapshot <p>(historic)</p>
              </h3>
              <diV
                className="chartbox"
                title="Click to Maximise"
                onClick={() => openModel("PastWeekLineChart")}
                style={{ cursor: "pointer", height: "250px", width: "100%" }}
              >
                <PastWeekLineChart dropDownValues={dropDownValues} />
              </diV>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-4 mb-4">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3 className="text-center">
                Obsoletes value - future projection <p>(today's POM data)</p>
              </h3>
              <diV
                className="chartbox"
                title="Click to Maximise"
                onClick={() => openModel("ComingWeekLineChart")}
                style={{ cursor: "pointer", height: "250px", width: "100%" }}
              >
                <ComingWeekLineChart dropDownValues={dropDownValues} />
              </diV>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6  mb-3">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3 className="text-center"> Obsoletes value - Glidepath</h3>
              <diV
                className="chartbox"
                title="Click to Maximise"
                onClick={() => openModel("NextStepChart")}
                style={{ cursor: "pointer", height: "400px", width: "100%" }}
              >
                {/* <ComingWeekLineChart dropDownValues={dropDownValues} /> */}
                <NextStepChart dropDownValues={dropDownValues} />
              </diV>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3 className="text-center">
                {" "}
                Obsoletes Value - Sales Mitigation %
              </h3>
              <diV
                className="chartbox"
                title="Click to Maximise"
                onClick={() => openModel("ObsoletesValueSalesMitigation")}
                style={{ cursor: "pointer", height: "400px", width: "100%" }}
              >
                <ObsoletesChart dropDownValues={dropDownValues} />
              </diV>
            </div>
          </div>
        </div>
      </div>

      <Modelcharts
        dropDownValues={dropDownValues}
        showModal={showModal}
        handleClose={handleClose}
        chartType={chartType}
      />
    </div>
  );
};

export default StatusPomSummaryTable;
