import React from "react";
import ULModal from "../Modal";
import DisplayListPopupBody from "./DisplayListPopupBody";

const DisplayListPopup = ({
  show,
  onHide,
  selectedList,
  updateOfferPrice,
  handleEditList,
  from,
  clickedFrom,
}) => {
  //const heading = selectedList;
  return (
    <ULModal
      show={show}
      onHide={onHide}
      size="lg"
      isheader="true"
      className={`page-modal ${
        from === "biddingportal" ? "myListPopup" : "biddingListPopup"
      }`}
    >
      <DisplayListPopupBody
        {...{
          onHide,
          selectedList,
          updateOfferPrice,
          handleEditList,
          from,
          clickedFrom,
        }}
      />
    </ULModal>
  );
};

export default DisplayListPopup;
