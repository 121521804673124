import ResidualProvisionsChart from './ResidualProvisionsChart'
import ResidualPendingChart from './ResidualPendingChart'
import ExpectedCompletionChart from './ExpectedCompletionChart'

import { LegendMarginPlugin } from "../plugins";
import "chart.js/auto";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Popup from 'reactjs-popup'
import ULModal from '../../../Common/Modal';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);



function Modelcharts(props) {

    const displayType = "popupDisplay"

    return (
        <div >
            <ULModal
                show={props.showModal}
                onHide={props.handleClose}
                size="lg"
                isheader="true"
                className={`page-modal`}
            >
                {props.chartType === "ResidualProvisionsChart" && <div >
                    <div className='smallgraphbg'>
                        <div className="graphtitle" >
                            <h3>Residual stock value vs Provision value in € </h3>
                            <p>Current Values, in Million Euros</p>
                        </div>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ResidualProvisionsChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {props.chartType === "ResidualPendingChart" && <div >
                    <div className="smallgraphbg">
                        <div className="graphtitle">
                            <h3>Number of days residuals are pending in Polaris </h3>
                            <p># of Days</p>
                        </div>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ResidualPendingChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {props.chartType === "ExpectedCompletionChart" && <div >
                    <div className="smallgraphbg">
                        <div className="graphtitle">
                            <h3>
                                Expected deal completion month for residuals Assigned to Local Deal{" "}
                            </h3>
                        </div>
                        <div className="chartbox" style={{ height: "350px" }}>
                            <ExpectedCompletionChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

            </ULModal>
            {/* <Popup
                className="top-spacing-for-close glossary table-scroll size-md"
                open={props.showModal}
                onClose={props.handleClose}
                modal
                nested
            >
                <button type="button" className="btn-close" aria-label="Close" onClick={props.handleClose}></button>
                {props.chartType === "ResidualProvisionsChart" && <div >
                    <div className='smallgraphbg'>
                        <div className="graphtitle" >
                            <h3>Residual stock value vs Provision value in € </h3>
                            <p>Current Values, in Million Euros</p>
                        </div>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ResidualProvisionsChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {props.chartType === "ResidualPendingChart" && <div >
                    <div className="smallgraphbg">
                        <div className="graphtitle">
                            <h3>Number of days residuals are pending in Polaris </h3>
                            <p># of Days</p>
                        </div>
                        <div className="chartbox" style={{  height: "350px" }} >
                            <ResidualPendingChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}

                {props.chartType === "ExpectedCompletionChart" && <div >
                    <div className="smallgraphbg">
                        <div className="graphtitle">
                            <h3>
                                Expected deal completion month for residuals Assigned to Local Deal{" "}
                            </h3>
                        </div>
                        <div className="chartbox" style={{  height: "350px" }}>
                            <ExpectedCompletionChart dropDownValues={props.dropDownValues} displayType={displayType} />
                        </div>
                    </div>
                </div>}
            </Popup> */}
        </div>
    )
}

export default Modelcharts