import { useEffect, useState } from "react";
import DealStatusXChart from "./DealStatusXChart";
import DealStatusYChart from "./DealStatusYChart";
import ResidualProvisionsChart from "./ResidualProvisionsChart";
import ResidualPendingChart from "./ResidualPendingChart";
import ExpectedCompletionChart from "./ExpectedCompletionChart";
import SummaryTable from "./SummaryTable";
import { LegendMarginPlugin } from "../plugins";
import "chart.js/auto";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Modelcharts from "./Modelcharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  LegendMarginPlugin
);

const StatusSummaryTable = ({ dropDownValues, filterOptions }) => {
  const [selectedMco, setMco] = useState();
  const [showModal, setShowModal] = useState(false);
  const[chartType, setChartType] = useState("");
  const handleClose = () => setShowModal(false);
  const openModel=(charttype) =>{
    setChartType(charttype)
    setShowModal(true)
  }

  useEffect(() => {
    if (dropDownValues?.mco?.length >= 0) {
      setMco();
    }
  }, [dropDownValues]);

  const handleMcoSelection = (mcoValue) => {
    const mcoKey = filterOptions?.mco.find((m) => m.value === mcoValue)?.key;
    setMco(mcoKey);
  };

  return (
    <div className="main-body">
      <div className="dealstatus">
        <div className="graphtable">
          <div className="row">
            <div className="col-md-6">
              <div className="graphtitle">
                <h3>Deal Status Summary </h3>
                <p>Current Values, in Million Euros</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-block nav-tabs-wrap ">
                <ul className="nav nav nav-tabs bg-waste bg-waste-margin right30">
                  <li className="nav-item">
                    <button
                      type="button"
                      className="nav-link active"
                      data-bs-toggle="tab"
                      data-bs-target="#Summary-View"
                      href="##"
                    >
                      Summary
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      type="button"
                      className="nav-link"
                      data-bs-toggle="tab"
                      data-bs-target="#Table-View"
                      href="##"
                    >
                      Table
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tab-content mt-2" id="tabContent">
              <div
                className="tab-pane  active"
                id="Summary-View"
                role="tabpanel"
                aria-labelledby="Summary-View"
              >
                <div className="row risk-summary-1" style={{ minHeight: "200px"}}>
                  <div className="col-md-4 linegraph">
                    <div className="chartbox" style={{ height: "100%"}}>
                        <DealStatusYChart
                          dropDownValues={dropDownValues}
                          selectedMco={selectedMco}
                          setMco={handleMcoSelection}
                        />
                    </div>
                  </div>
                  <div className="col-md-8 ps-4">
                    <div className="chartbox" style={{ height: "100%"}}>
                        <DealStatusXChart
                          dropDownValues={dropDownValues}
                          selectedMco={selectedMco}
                        />
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane "
                id="Table-View"
                role="tabpanel"
                aria-labelledby="Table-View"
              >
                <div className="row">
                  <div className="col-md-12">
                    <SummaryTable dropDownValues={dropDownValues} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row risk-summary-2">
        <div className="col-md-4 mt-4 mb-4">
          <div className="smallgraphbg">
            <div className="graphtitle" >
              <h3>Residual stock value vs Provision value in € </h3>
              <p>Current Values, in Million Euros</p>
            </div>
            <div className="chartbox" onClick={()=>openModel("ResidualProvisionsChart")} style={{cursor:"pointer"}}>
              <ResidualProvisionsChart  className="chartbox" dropDownValues={dropDownValues} />
            </div>
          </div>
        </div>

        <div className="col-md-4  mt-4 mb-4">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3>Number of days residuals are pending in Polaris </h3>
              <p># of Days</p>
            </div>
            <div className="chartbox" onClick={()=>openModel("ResidualPendingChart")} style={{cursor:"pointer"}}>
              <ResidualPendingChart dropDownValues={dropDownValues} />
            </div>
          </div>
        </div>

        <div className="col-md-4 mt-4 mb-4">
          <div className="smallgraphbg">
            <div className="graphtitle">
              <h3>
              Expected deal completion month for residuals Assigned to Local Deal{" "}
              </h3>
            </div>
            <div className="chartbox" onClick={()=>openModel("ExpectedCompletionChart")} style={{cursor:"pointer"}}>
              <ExpectedCompletionChart  dropDownValues={dropDownValues} />
            </div>
          </div>
        </div>
        {/* model start  */}
        <Modelcharts showModal={showModal} handleClose={handleClose} chartType={chartType} dropDownValues={dropDownValues}/>

      </div>
    </div>
  );
};

export default StatusSummaryTable;
