import axios from "axios";
const axiosService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND || "",
});

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    throw error;
  }
);

export default axiosService;
