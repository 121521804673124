import React from "react";
import ULButton from "../../Common/button";

const CreateNewListPopupBody = (props) => {
  return (
    <>
      {(props.isNewListSaved && (
        <div className="text-center">
          <p>{props.inputName}</p>
          <p>Successfully Submitted</p>
          <ULButton
            type="button"
            className="btn btn-primary btn-big width-auto btn-create-new-list-success-close"
            label={`Close`}
            onClick={() => props.onHide()}
          />
        </div>
      )) || (
        <div className="">
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter New List Name"
              className="inputbox inputborder saveScenName"
              onChange={(e) => {
                props.setInputName(e.target.value);
              }}
            />
          </div>
          <div className="graphtitle">
            <p>*Note: Created list is available in Right Bidding List Pane</p>
          </div>
          <ULButton
            type="button"
            disabled={!props.inputName || props.isLoading}
            className="btn btn-primary btn-medium"
            label="Save"
            onClick={() => props.handleCreateNewList()}
          />
        </div>
      )}
    </>
  );
};

export default CreateNewListPopupBody;
