import React, { useMemo, useEffect } from "react"; //useState
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { useBlockLayout, usePagination, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
// import { Styles } from "./TableStyles";
import ReactTablePagination from "../../../Common/react-table-pagination";
import Loader from "../../../Common/loader";
import ColumnHeaderDesc from "../../../Common/ColumnHeaderDesc";
import { tableToolTip } from "../../../utility/constants";
import { OverlayTrigger } from "react-bootstrap";
const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  updateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SortBy,
  setSortBy,
  SortType,
  setSortType,

  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  actions,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  toggleExpandCollapse,
  //setCreateListItem,
  ...props
}) => {
  //console.log("selection: ", selection);

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const manualPageSize = [5, 10, 20, 30];
  const INITIAL_HIDDEN_COLUMNS = columns
    .filter((column) => !column.show)
    .map((column) => column.accessor);

  /* function handleExpand() {
    setFirstContainer("inactive");
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  function handleCollapse() {
    setFirstContainer("active");
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  } */

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    //visibleColumns,
    allColumns,
    toggleHideColumn,
    prepareRow,
    //setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      handleClickEditCell,
      clickedIndex,
      clickedCell,
      updateCellData,
      isSelectAll,
      selection,
      manualPagination: true,
      pageCount: Math.ceil(totalRecordCount / pageSizeNo),
      initialState: {
        pageIndex: 0,
        pageSize: pageSizeNo,
        hiddenColumns: INITIAL_HIDDEN_COLUMNS,
      },
    },
    usePagination,
    useBlockLayout,
    useSticky
  );

  /* const [val, setVal] = useState([
    "*",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]); */

  //column customization code start here
  //const selectAllOption = { value: "*", label: "Select All" };
  /* const filterColumns = allColumns
    ? allColumns
        .filter((item) => item.sticky !== "left")
        .map((column) => ({
          value: column.id,
          label: column.Header,
        }))
    : []; */

  /* const handleSelect = (selected, action) => {
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      //select all
      setVal([
        "*",
        ...columns
          .filter((item) => item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      columns
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      //deselect all
      setVal([]);
      columns
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
    } else {
      setVal(selected.map((item) => item.value));
    }

    //Add and remove columns from the table
    if (action.action !== "pop-value") {
      //while clear data from the input
      if (action.action === "clear") {
        //setAppendColumn(action.removedValues.map((item) => item.value));
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        //setAppendColumn([...appendColumn, action.option.value]);
        toggleHideColumn(action.option.value);
      }
    }
  }; */

  /*   useEffect(() => {
    setCreateListItem(selectedFlatRows);
  }, [selectedFlatRows, setCreateListItem]); */

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleSorting = (column) => {
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
  };

  return (
    <>
      <div className="">
        {/* <Styles> */}
          <div
            {...getTableProps()}
            className="table sticky scroll data-table-two display nowrap scrollclass"
          >
            <div className="header">
              {headerGroups.map((headerGroup, index) => (
                <div
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  className="tr"
                >
                  {headerGroup.headers.map((column, index) => (
                    <div
                      key={index}
                      {...column.getHeaderProps()}
                      style={{ width : column.id === "selection" ? "40px" :  (column.width) ? column.width : "150px", minWidth : column.id === "selection" ? "40px" :  (column.width) ? column.width : "150px" }}
                      className={`th  ${column.id}  `}
                    >
                      <div className="th-in">
                      <OverlayTrigger
                        overlay={tableToolTip(ColumnHeaderDesc("fgslob",column.Header))} 
                        trigger={["hover","focus"]}
                        placement="top"
                        delay={{ show: 200, hide: 0 }}
                      >
                          <span className="th-text" >{column.render("Header")}</span>
                      </OverlayTrigger>
                        {column.id !== "selection" && (
                          <span
                            className="sort-ico"
                            style={{ cursor: "pointer" }}
                          >
                              <span>
                                {SortType === "ASC" && column.id === SortBy ? (
                                  <Icon
                                    path={mdiMenuUp}
                                    size="1.5rem"
                                    className="sortColumn"
                                    onClick={() => handleSorting(column)}
                                  />
                                ) : SortType === "DESC" &&
                                  column.id === SortBy ? (
                                  <Icon
                                    path={mdiMenuDown}
                                    size="1.5rem"
                                    className="sortColumn"
                                    onClick={() => handleSorting(column)}
                                  />
                                ) : (
                                  <Icon
                                    path={mdiMenuUp}
                                    size="1.5rem"
                                    className="sortColumn"
                                    onClick={() => {
                                      if (column.id !== "icon_container") {
                                        setSortType("DESC");
                                        setSortBy(column.id);
                                      }
                                    }}
                                  />
                                )}
                              </span>
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div {...getTableBodyProps()} className="body">
              {(page.length > 0 &&
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <div key={index} {...row.getRowProps()} className="tr">
                      {row.cells.map((cell, index) => (
                        <div
                          key={index}
                          {...cell.getCellProps()}
                          style={{ width : cell.column.id === "selection" ? "40px" :  (cell.column.width) ? cell.column.width : "150px", minWidth : cell.column.id === "selection" ? "40px" :  (cell.column.width) ? cell.column.width : "150px" }}
                          className={`td  ${cell.column.id}  td-editable-${cell.column.editable} `}
                        
                        >
                          <span className="td-in" title={cell.value} key={index}
                              {...cell.getCellProps()} >
                                {cell.render("Cell")}
                            </span>
                     
                        </div>
                      ))}
                    </div>
                  );
                })) || (
                <p className="no-data-msg">
                  <Loader />
                </p>
              )}
            </div>
          </div>
        {/* </Styles> */}
      </div>
      {totalRecordCount > 0 && (
        <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          manualPageSize={manualPageSize}
          dataLength={totalRecordCount}
          handleItemPerPage={handleItemPerPage}
          handlePaginationReset={handlePaginationReset}
          pageSizeNo={pageSizeNo}
          setIsSelectAll={setIsSelectAll}
          //setSelection={setSelection}
        />
      )}
    </>
  );
};

export default React.memo(TableGrid);
