import React, { Component, createContext } from "react";
import Store from "./Store";
import "./App.css";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import ApplicationView from "./Components/Application";
 export const UserCheckContext=createContext()

class App extends Component {
  state = {
    isInternalUser: true,
    userDetails: null,
  };
  setUserDetails = (val) => {
    this.setState({
      ...this.state,
      userDetails: val,
      isInternalUser: val?.username?.includes("@unilever"),
    });
  };
  render() {
    const { pca } = this.props;
    return (
      <UserCheckContext.Provider value={this.state.isInternalUser}>
        <MsalProvider instance={pca}>
          <BrowserRouter>
            <div className="App">
              <Store>
                <ApplicationView setUserDetails={this.setUserDetails} userDetails={this.state.userDetails}/>
              </Store>
            </div>
          </BrowserRouter>
        </MsalProvider>
      </UserCheckContext.Provider>
    );
  }
}

export default App;
