
import React, { useContext, useEffect, useState } from 'react'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LegendMarginPlugin } from '../../InflowRiskVisibility/plugins';
import Dropdown from '../../../Views/common/Dropdown';
import MitigationActionBarGraph from './MitigationActionBarGraph';
import NegitiveBiasBarChart from './NegitiveBiasBarChart';
import ForecastBiasL4WBarGraph from './ForecastBiasL4WBarGraph';
import ForecastBiasL12WBarGraph from './ForecastBiasL12WBarGraph';
import RiskTypeGraph from './RiskTypeGraph';
import RiskLevelBarGraph from './RiskLevelBarGraph';
import axiosService from '../../../utils/apiService';
import apiEndPoints from '../../../utils/apiEndPoints';
import InflowModelCharts from './InflowModelCharts';
import { getInflowUserPreferences, saveInflowUserPreferences } from '../../../Services/InflowRiskVisibilityService';
import { UserContext } from '../../Application';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);


function Summary2(props) {
    const [dropDownValues, setDropDownValues] = useState();
    const [filterOptions, setFilterOptions] = useState();
    const [reload, setReload] = useState(false);
    const [filterValues,setFiltersValues]= useState();
    // useEffect(()=>{
    //     getDropDownsData()
    // },[])
    // const getDropDownsData = async () => {        
    //     const resolt = await axiosService.post(apiEndPoints.inflowGlobalFilters, {});
    //     const result =resolt?.data?.data
    //     console.log(result)
    //     setFiltersValues(result)
    // }
    const [showModal, setShowModal] = useState(false);
    const [chartType, setChartType] = useState("");
    const handleClose = () => setShowModal(false);
    const user = useContext(UserContext)
    const [reeload,setreeload] = useState(false)

    const openModel = (charttype) => {
        setChartType(charttype)
        setShowModal(true)
    }
        const [ asddd,setasddd] =useState(false)
        useEffect(()=>{
            const dummy = {
                actiontype:[],
                brand:[],
                forecastbiasL4w:[],
                forecastbiasL12w:[],
                forecastfamily:[],
                fsIndicator:[],
                riskLevel:[],
                riskType:[],
                businessGroup:[],
                category:[],
                country:[],
                 plant:[]
            }    
        
            getInflowUserPreferences(user.email).then(res=>{
            if(res.data[0]){
                setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)       
            window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
			setasddd(true)
            }    
            else{
                const payload = {
                    userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
                    userRole:user.userRole,
                    userEmail:user.email,
                    userPreference:{ preferenceData:dummy,
                      selectedColumns:["all"]
                    }}          
                  console.log(payload)
                  saveInflowUserPreferences(payload).then((res)=>{setreeload(!reeload)})
                  console.log(dummy)
                window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:{},selectedColumns:["all"]}))
            }  
            
            }
        )
        },[user])
        useEffect(()=>{
            getInflowUserPreferences(user.email).then(res=>{
            if(res.data[0]){
              setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
              // saveUserPreference(JSON.parse(res.data[0].UserPreference))
              window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
              setasddd(true)
            }       
          }
        )
        },[reeload])
        console.log("aaa",dropDownValues,filterValues)
    return (
        <>
            {asddd &&<Dropdown
                list={[
                    "Business Group",
                    "FoodSolutionIndicator",
                    "Country",
                    "Plant Code",
                    "Category",
                    "Brand"
                ]}
                onChange={(value) => {
                    setDropDownValues({ ...value });
                }}
                setDropDownValues={setDropDownValues}
                reload={reload}
                setReload={setReload}
                setFilterOptions={setFilterOptions}
            />}
            <br></br>
            <div className="page-body-top-content">
                <div className="d-block nav-tabs-wrap ">
                    <ul className="nav nav nav-tabs bg-waste bg-waste-margin right30">
                        <li className="nav-item">
                            <button
                                type="button"
                                className="nav-link active "
                                data-bs-toggle="tab"
                                data-bs-target="#Summary-View"
                                href="##"
                                onClick={() => props.setScreenView(1)}
                            >
                                Risk

                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                className="nav-link "
                                data-bs-toggle="tab"
                                data-bs-target="#Table-View"
                                href="##"
                                onClick={() => props.setScreenView(2)}
                            >
                                Forecast Bias
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="main-body pom-summary-body">
                <div className="row">
                    <div className="col-md-6  mt-4 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3> DoH vs ISL Risk Level </h3>
                                    <p>(Count of material codes)</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("RiskLevelBarGraph")}>
                                    <RiskLevelBarGraph className="chartbox" dropdownValues={dropDownValues} filterValues={filterOptions} />
                                </diV>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6  mt-4 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3> Risk Type</h3>
                                    <p>(Count of material codes)</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("RiskTypeGraph")}>
                                    <RiskTypeGraph className="chartbox" dropdownValues={dropDownValues} filterValues={filterOptions}/>
                                </diV>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3>Forecast Bias L4W</h3>
                                    <p>(Count of material codes)</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("ForecastBiasL4WBarGraph")}>
                                    <ForecastBiasL4WBarGraph className="chartbox" dropdownValues={dropDownValues} filterValues={filterOptions}/>
                                </diV>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="smallgraphbg">
                            <div className="graphtitle">
                                <div style={{ textAlign: "center" }}>
                                    <h3> Forecast Bias L12W</h3>
                                    <p>(Count of material codes)</p>
                                </div>
                                <diV className="chartbox" onClick={() => openModel("ForecastBiasL12WBarGraph")}>
                                    <ForecastBiasL12WBarGraph className="chartbox" dropdownValues={dropDownValues}filterValues={filterOptions} />
                                </diV>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <InflowModelCharts showModal={showModal} handleClose={handleClose} chartType={chartType} dropDownValues={dropDownValues} filterValues={filterOptions}/>

        </>

    )
}

export default Summary2