export const COLUMNS = [
  {
    Header: "Customer Name",
    accessor: "name",
    show: true,
    editable: false,
  },
  {
    Header: "Contact Person",
    accessor: "contactPerson",
    show: true,
    editable: false,
  },
  {
    Header: "Contact Number",
    accessor: "contactNumber",
    show: true,
    editable: false,
  },
  {
    Header: "Email",
    accessor: "email",
    show: true,
    editable: false,
  },
  {
    Header: "Business Groups",
    accessor: "businessGroups",
    show: true,
    editable: false,
  },
  {
    Header: "Country",
    accessor: "country",
    show: true,
    editable: false,
  },
  {
    Header: "MCO",
    accessor: "mco",
    show: true,
    editable: false,
  },
  {
    Header: "Created By",
    accessor: "createdBy",
    show: true,
    editable: false,
  },
  {
    Header: "Approved By",
    accessor: "approvedBy",
    show: true,
    editable: false,
  },
  {
    Header: "Created Date",
    accessor: "createdDate",
    show: true,
    editable: false,
  },
  {
    Header: "Status",
    accessor: "status",
    show: true,
    editable: false,
  },
  {
    Header: "Actions",
    accessor: "actions",
    show: true,
    editable: false,
  },
];