import React, { useMemo, useEffect, useState, useRef, useContext } from "react";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { pageTopElHt, tableToolTip } from "../../utility/constants";
import ClearIcon from "../../Assets/img/icon-cancel-white.svg";
import { DebounceInput } from "react-debounce-input";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { getWasteRiskDetailColumnCount } from "../../Services/WasteRiskDetailServices";
import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  useFilters,
  useResizeColumns,
} from "react-table";
import { useSticky } from "react-table-sticky";
import ReactTablePagination from "../../Common/react-table-pagination";
import ULDropdown from "../../Common/Dropdown";
import OpenIcon from "../../Assets/img/open.svg";
import SearchIcon from "../../Assets/img/search.svg";
import resizeIcon from "../../Assets/img/icon-resize.svg";
import ClickIcon from "../../Assets/img/clickicon.svg";
import PinchIcon from "../../Assets/img/icon-pinch.svg";
import Glossary from "../../Common/glossary";
import { Checkbox } from "./Checkbox";
import Loader from "../../Common/loader";
import Loadersml from "../../Common/loadersml";
import ColumnHeaderDesc from "../../Common/ColumnHeaderDesc";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BarChart } from "../../Common/charts/BarChart";
import { stackBarChartOptions } from "../../Components/InflowRiskVisibility/helpers";
import { setDropdownvaluesGlobal } from "../../utility/helper";
import { UserAccessContext } from "../../Components/Application";


const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  status,
  dealMonths,
  packTypeList,
  updateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SummaryTableHandleDownload_ref,
  SortBy,
  setSortBy,
  SortType,
  setSortType,
  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  actions,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  toggleExpandCollapse,
  handleCheckBox,
  handleSelectAllCheckBox,
  isCheck,
  isNotRequiredTableCoumnSort,
  setCreateListItem,
  isNewListSaved,
  publishLoading,
  totalCount,
  setPageInd,
  pageInd,
  state,
  filterData,
  refObj,
  setRefObj,
  setSelectedCol,
  setHasColUpdated,
  skipFilter = false,
  hideFilter = false,
  isExpand,
  ...props
}) => {

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(
    () =>
      tableData.map((obj) => {

        return {
          ...obj,
          // remaining_quantity_in_zun:
          //   (obj.wk13_wk26_Res_BlockedStockZun ?? 0) -
          //   (obj.local_deal_quantity_in_zun ?? 0) -
          //   (obj.central_deal_quantity_in_zun ?? 0),

          remaining_stock_in_eur: obj.remaining_stock_in_eur
            ?
            (
              (((obj.wk13_wk26_Res_BlockedStockZun ?? 0) -
                (obj.local_deal_quantity_in_zun ?? 0) -
                (obj.central_deal_quantity_in_zun ?? 0)) ?? 0) * (obj.standard_price ?? 0)
            ).toFixed(2)
            : ""

        };
      }),
    [tableData]
  );
  const [isTableLoader, setTableLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [freezedColumns, setFreezedColumns] = useState("");
  const [freezedColumnsWt, setFreezedColumnsWt] = useState([]);
  const [pageTableHeight, setPageTableHeight] = useState();
  const manualPageSize = [5, 10, 20, 30];
  const LEGEND_COLOR = "#FFFFFF";
  const LABEL_COLOR = "#CCCCCC";
  const userAccess = useContext(UserAccessContext)
  var bd = document.getElementsByTagName("body");
  const [selectedColumnss, setSelectedColumns] = useState(JSON.parse(window.sessionStorage.getItem("preferences")).selectedColumns)
  let INITIAL_HIDDEN_COLUMNS = !selectedColumnss?.includes("all") ? columns
    .filter((column) => !selectedColumnss?.includes(column.accessor))
    .map((column) => column.accessor) : columns
      .filter((column) => !column.show)
      .map((column) => column.accessor);
  useEffect(() => {
    window.addEventListener("resize", tableHt);
  });




  const chunkString = (str, maxWidth) => {
    const sections = [];
    if (!str) return sections;
    const words = str.split(" ");
    let builder = "";

    for (const word of words) {
      if (word.length > maxWidth) {
        sections.push(builder.trim());
        builder = "";
        sections.push(word.trim());
        continue;
      }

      let temp = `${builder} ${word}`;
      if (temp.length > maxWidth) {
        sections.push(builder.trim());
        builder = word;
        continue;
      }

      builder = temp;
    }
    sections.push(builder.trim());

    return sections;
  };

  const getOptions = () => {
    return {
      labels: ["SKU status"],
      datasets: [
        {
          label: "Quantity Available",
          data: [rowRecord?.quantity_available],
          backgroundColor: "#00B0F0",
          barThickness: 10,
        },
        {
          label: "Reserved Quantity",
          data: [rowRecord?.reserved_quantity],
          backgroundColor: "#92D050",
          barThickness: 10,
        },
        {
          label: "Accepted Quantity",
          data: [rowRecord?.accepted_quantity],
          backgroundColor: "#00B050",
          barThickness: 10,
        },
      ],
    };
  };
  const tableHt = (isTableContracted) => {
    let spaceDiff = props.page == "deal" ? 80 : 0;
    let nn = pageTopElHt(isTableContracted);
    let nnf = 180 + spaceDiff + (props.isExpand ? 0 : 0);
    setPageTableHeight(nn - nnf);
  };
  useEffect(() => {
    tableHt(true);
  }, []);

  const pluginq = {
    beforeDraw: (chart) => {
      const ctx = chart.canvas.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "lightGreen";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };

  var tooltip = (
    <Tooltip id="tooltip" style={{ width: "370px" }}>
      <>
        <BarChart
          options={{
            plugins: {
              legend: {
                display: true,
                align: "start",
                labels: {
                  pointStyle: "circle",
                  usePointStyle: true,
                  padding: 20,
                  color: LEGEND_COLOR,
                  font: {
                    size: 8,
                    family: "unilevershilling",
                  },
                },
              },
              datalabels: {
                display: false,
              },
            },
            responsive: false,
            scales: {
              x: {
                stacked: true,
                grid: {
                  display: false,
                },
                ticks: {
                  color: LABEL_COLOR,
                  font: {
                    size: 8,
                    family: "unilevershilling",
                  },
                  callback(value) {
                    return chunkString(this.getLabelForValue(value), 10);
                  },
                },
              },
              y: {
                stacked: true,
                grid: {
                  color: "rgba(255, 255, 255, 0.1)",
                },
                border: {
                  display: false,
                },
                ticks: {
                  color: LABEL_COLOR,
                  font: {
                    family: "unilevershilling",
                  },
                },
              },
            },
            indexAxis: "y",
            responsive: true,
          }}
          data={getOptions()}
          plugins={pluginq}
        ></BarChart>
        <div className="d-flex flex-column justify-content-between mt-3 mb-2 sku-status-table flex-basis-100">
          <div className="position-relative">
            <div className="table_design d-flex">
              <div className="table sticky cells-width-auto table-three-col">
                <div className="header w-100">
                  <div className="tr">
                    <div className="th">
                      <div className="th-in">
                        <span className="th-text">
                          Quantity Availabe
                        </span>
                      </div>
                    </div>
                    <div className="th cell-border-bottom cell-border-bottom-green">
                      <div className="th-in">
                        <span className="th-text" title='Material Number'>Reserved Quantity</span>
                      </div>
                    </div>
                    <div className="th cell-border-bottom cell-border-bottom-dark-green">
                      <div className="th-in">
                        <span className="th-text" title='Material Description'>Accepted Quantity</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body w-100">
                  <div className="tr" >
                    <div
                      className="td cell-border-bottom cell-border-bottom-blue"
                    >
                      <span
                        className="td-in"
                      >
                        {rowRecord.quantity_available}
                      </span>
                    </div>
                    <div
                      className="td cell-border-bottom cell-border-bottom-green"
                    >
                      <span
                        className="td-in"
                      >
                        {rowRecord.reserved_quantity}
                      </span>
                    </div>
                    <div
                      className="td cell-border-bottom cell-border-bottom-dark-green"
                    ><span
                      className="td-in"
                    >
                        {rowRecord.accepted_quantity}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    </Tooltip>
  );

  function DefaultColumnFilter(cellObj) {
    var [searchFilterLoader, setSearchFilterLoader] = useState(false);
    const ref = useRef(null);
    const { id, filterValue, setFilter } = cellObj.column;
    const [value, setValue] = useState("");
    const [isSearchClicked, setIsSearchClicked] = useState(false);

    const [searchTextColumn, setSearchTesxtColumn] = useState("")

    const debouncedChangeHandler = (e) => {
      if (e.key === "Enter") {
        setSearchFilterLoader(true)
        setValue(searchTextColumn);
        handleToggle(e);
      }
    }

    const handleToggle = (e) => {
      cellObj.filterData(e.target.value, id);
      setTableLoader(true);
      setTimeout(() => {
        setSearchFilterLoader(false);
      }, 500);
    };

    var searchCol = (e) => {
      filterData(e.target.value, id);
      setSearchFilterLoader(false);
    };

    useEffect(() => {
      let obj = {};
      obj[id] = ref;
      refObj.push(obj);
      setRefObj([...refObj]);
    }, [id, refObj]);



    return (
      <>
        {!hideFilter && (
          <div className="input-edit-mode input-with-loader btn-slim">
            <DebounceInput
              id={id}
              key={id}
              value={value}
              ref={ref}
              onKeyUp={(ev) => {
                if (ev.target.value) {
                  refObj.forEach((key) => {
                    if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
                      // if (key[Object.keys(key)[0]].current.state)
                      //   key[Object.keys(key)[0]].current.state.value = "";
                      // else {
                      //   key[Object.keys(key)[0]].current.value = "";
                      // }
                    }
                  });
                }
              }}
              debounceTimeout={300}
              onChange={(event) => setSearchTesxtColumn(event.target.value)}
              onKeyDown={debouncedChangeHandler}
            />

            {searchFilterLoader && (
              <span>
                <Loadersml />
              </span>
            )}
          </div>
        )}
      </>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 400,
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    allColumns,
    toggleHideColumn,
    toggleHideAllColumns,
    prepareRow,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    !skipFilter
      ? {
        columns,
        data,
        defaultColumn,
        handleClickEditCell,
        clickedIndex,
        clickedCell,
        updateCellData,
        filterData,
        status,
        dealMonths,
        actions,
        refObj,
        setRefObj,
        onActionClick,
        packTypeList,
        handleCheckBox,
        handleSelectAllCheckBox,
        selection,
        isCheck,
        manualPagination: true,
        pageCount: Math.ceil(totalRecordCount / pageSizeNo),
        initialState: {
          pageIndex: pageInd,
          pageSize: pageSizeNo,
          hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        },
      }
      : {
        columns,
        data,
        handleClickEditCell,
        clickedIndex,
        clickedCell,
        updateCellData,
        status,
        dealMonths,
        packTypeList,
        refObj,
        setRefObj,
        actions,
        onActionClick,
        handleCheckBox,
        handleSelectAllCheckBox,
        selection,
        isCheck,
        manualPagination: true,
        pageCount: Math.ceil(totalRecordCount / pageSizeNo),
        initialState: {
          pageIndex: pageInd,
          pageSize: pageSizeNo,
          hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        },
      },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      hooks?.visibleColumns?.push((columns) => [

        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          sticky: "left",
        },
        ...columns,
      ]);
    }
  );

  const [val, setVal] = useState([
    "all",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]);

  useEffect(() => {
    setSelectedCol?.([
      ...columns
        .filter((column) => selectedColumnss?.includes(column.accessor))
        .map((column) => column.accessor)
    ])
    return () => {
      setHasColUpdated?.(false)
    }
  }, [])

  //column customization code start here
  const selectAllOption = { value: "all", label: "Select all" };
  const filterColumns = allColumns ? allColumns
    .filter((item) => item.sticky !== "left")
    .map((column) => ({
      value: column.id,
      label: column.Header,
      sticky: column.sticky,
    }))
    : [];
  useEffect(() => {
    const a = ['dealTypeId', 'materialno', 'material_description', 'business_group']
    setVal(a)
    columns.filter((i) => a.includes(i.accessor)).forEach(i => toggleHideColumn(i))
    // ['dealTypeId', 'materialno', 'material_description', 'business_group'].forEach((item) => toggleHideColumn(item))
  }, [])

  useEffect(() => {
    if (selectedColumnss?.includes("all")) {
      setVal([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
      setSelectedCol?.([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
    } else {
      setVal(selectedColumnss)
    }
    setHasColUpdated?.(true)
  }, [selectedColumnss])

  const handleSelect = async (selected, action) => {
    props.SetColumnSelected?.(selected.map(i => i.value))
    setSelectedCol?.(selected.map(i => i.value))
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      setVal([
        "all",
        ...columns
          .filter((item) => item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      setHasColUpdated?.(false)
      // setTimeout(() => {
      //props.handleColumnsSelected(['all']);
      console.log("action.option.value-------", action.option.value, action.action)
      //}, 400);
      toggleHideAllColumns(false);
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      var vv = await columns.filter((item) => item.sticky).map((item) => item.accessor)
      setVal(
        vv
      );
      //setTimeout(() => {
      //  props.handleColumnsSelected([...val]);
      setHasColUpdated?.(true)
      console.log("action.option.value-------", action.option.value, action.action)
      //}, 400);
      columns
        .filter((item) => !item.sticky)
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
    } else {
      var cc = await action.action === "select-option" && selected?.length === columns.length
        ? [{ value: "all", label: "Select all" }, ...selected].map(
          (item) => item.value
        )
        : selected.filter((i) => i.value !== "all").map((item) => item.value)
      setVal(
        cc
      );
      //setTimeout(() => {
      //  props.handleColumnsSelected([...val]);     
      console.log("action.option.value-------", action.option.value, action.action)
      /// }, 400);
      if (action.action === "select-option" && selected.length === 1) {
        toggleHideColumn("selection");
      }
    }

    //Add and remove columns from the table
    if (action.action !== "pop-value") {
      //while clear data from the input
      if (action.action === "clear") {
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        toggleHideColumn(action.option.value);
      }
    }
  };

  useEffect(() => {
    if (!isNewListSaved) {
      setSelection(selectedFlatRows);
      props.setRowDownloadIds?.(selectedFlatRows);
    }
    else {
      setSelection([]);
    }
  }, [selectedFlatRows, isNewListSaved, setSelection]);

  useEffect(() => {
    if (setCreateListItem && !isNewListSaved) {
      setCreateListItem(selectedFlatRows);
    } else if (isNewListSaved) {
      selectedFlatRows.forEach((key, index) => {
        key.toggleRowSelected();
      });
    }
  }, [selectedFlatRows, setCreateListItem, isNewListSaved]);

  useEffect(() => {
    setTableLoader(true);
  }, [props.dropDownValues]);

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleSorting = (column) => {
    setTableLoader(true);
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
    setTimeout(() => setTableLoader(false), 1000);
  };

  let selectionValues = {
    "3wk + 26wk Residual ZUNs": "wk13_wk26_residual_zun",
    "13wk + 26wk Residual EUR": "wk13_wk26_residual_eur",
    "Central deal quantity in ZUNs": "central_deal_quantity_in_zun",
    "Central deal in EUR": "central_deal_in_eur",
    "Remaining quantity in ZUN": "remaining_quantity_in_zun",
    "Remaining stock in EUR": "remaining_stock_in_eur",
    "Expiry date": "expiry_date",
  };

  const handleNxtPages = () => {
    setTableLoader(true);
    nextPage();
  };
  const handlePrevPages = () => {
    setTableLoader(true);
    previousPage();
  };

  const handleGotoPages = (i) => {
    setTableLoader(true);
    gotoPage(i);
  };

  useEffect(() => {
    setTableLoader(false);
  }, [data]);

  const clearSearchClose = () => {
    setTableLoader(true);
    setSearchText("");
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
      dealTypeId: props.dropDownValues?.dealType ?? [],
      materialNumberId:
        props.dropDownValues?.materialNumber ?? [],
      plant_code: props.dropDownValues?.plant ?? [],
      residualStatusId:
        props.dropDownValues?.residualStatus ?? [],
      languageOnPack:
        props.dropDownValues?.languageOnPack ?? [],
      page: 1,
      columnFilter: {},
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: "",
      currentTabSelection: props.tabKey
    });
  };

  useEffect(() => {
    if (props.adjustPageGridHt != null) {
      console.log("props.isExpand", isExpand, props.adjustPageGridHt);
      toggleExpandCollapse();
      setTimeout(() => {
        tableHt(isExpand);
      }, 400);
    }
  }, [props.adjustPageGridHt]);

  const colHeaderDescExists = (v) => {
    if (v.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const toFloorNumber = (val) => {
    if (val !== null) {
      let valv = Number(val);
      return Math.floor(valv);
    }
  }

  const handelSearchButton = () => {
    setTableLoader(true);
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
      dealTypeId: props.dropDownValues?.dealType ?? [],
      materialNumberId:
        props.dropDownValues?.materialNumber ?? [],
      plant_code: props.dropDownValues?.plant ?? [],
      residualStatusId:
        props.dropDownValues?.residualStatus ?? [],
      languageOnPack:
        props.dropDownValues?.languageOnPack ?? [],
      page: 1,
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ?? "",
      currentTabSelection: props.tabKey
    });
  }

  const handleKeyDown = (event) => {
    console.log(event)
    if (event.key === 'Enter') {
      handelSearchButton();
    }
  };

  // useEffect(()=>{
  //   console.log("handleColumnsSelected~~~~~~~~~~",val);
  //   localStorage.setItem('columnVisibilitySelected',JSON.stringify(val));
  //   setTimeout(() => {
  //     props.handleColumnsSelected();
  //   }, 400);
  // },[props.downloadClicked])

  const [selectedRow, setSelectedRow] = useState()

  return (
    <>
      {/* <!--Search Table Data Start--> */}
      {!hideFilter && (
        <div className="table-filters">
          <div className="pull-left flex align-items-center justify-content-between">
            <ul className="table-form flex align-items-center">
              {!props.isNoColumnSelection && (
                <li className="pr-0">
                  <div className="flex align-items-center">
                    <label className="d-inline-block mx-2">Columns</label>
                    <ULDropdown
                      name="custom_column_filter"
                      value={val}
                      options={[selectAllOption, ...filterColumns]}
                      //options={filterColumns}
                      onChange={handleSelect}
                      isMulti={true}
                      isClearable={false}
                      placeholder={"Search Column"}
                      className="react-select-container inp-std-width"
                    />
                  </div>
                </li>
              )}
              {!props.isNoColumnSelection && (
                <li className="pl-2">
                  <label>
                    {val.filter((i) => i === "all")?.length
                      ? val.length - 1
                      : val.length}{" "}
                    selected
                  </label>
                </li>
              )}
              {!isNotRequiredTableCoumnSort && (
                <li>
                  <label>Table Sort Column </label>
                  <select
                    style={{ width: "220px" }}
                    onChange={(event) => {
                      setTableLoader(true);
                      setSortBy(
                        event.target.value !== ""
                          ? selectionValues[event.target.value]
                          : ""
                      );
                      getGridData({
                        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
                        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
                        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
                        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
                        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
                        dealTypeId: props.dropDownValues?.dealType ?? [],
                        materialNumberId:
                          props.dropDownValues?.materialNumber ?? [],
                        plantId: props.dropDownValues?.plant ?? [],
                        residualStatusId:
                          props.dropDownValues?.residualStatus ?? [],
                        languageOnPack:
                          props.dropDownValues?.languageOnPack ?? [],
                        page,
                        per_page: pageSizeNo,
                        sortby: SortBy,
                        complianceSorting: SortType,
                        searchText: searchText ?? "",
                        currentTabSelection: props.tabKey
                      });
                      setTimeout(() => setTableLoader(false), 1000);
                    }}
                  >
                    <option key={"wk13_wk26_residual_zun"}>
                      13wk + 26wk Residual ZUNs
                    </option>
                    <option key={"wk13_wk26_residual_eur"}>
                      13wk + 26wk Residual EUR
                    </option>
                    <option key={"central_deal_quantity_in_zun"}>
                      Central deal quantity in ZUNs
                    </option>
                    <option key={"central_deal_in_eur"}>
                      Central deal in EUR
                    </option>
                    <option key={"remaining_quantity_in_zun"}>
                      Remaining quantity in ZUN
                    </option>
                    <option key={"remaining_stock_in_eur"}>
                      Remaining stock in EUR
                    </option>
                    <option key={"expiry_date"}>Expiry date</option>
                  </select>
                </li>
              )}
            </ul>
          </div>

          {!props.isNoSearch && (
            <div className="pull-right flex align-items-center justify-content-between">
              <span className="search-wrap-out w-100">
                <span className="search-wrap">
                  <img src={SearchIcon} className="filter-search" alt="" />
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value ?? "");
                    }}
                    placeholder="Material Number/ Material Description"
                    title="Material Number/ Material Description"
                    className="btn-slim search-input"
                    onKeyDown={handleKeyDown}
                  />
                  {searchText != "" && (
                    <a
                      className="filter-clear"
                      onClick={() => clearSearchClose()}
                    >
                      <img src={ClearIcon} alt="" />
                    </a>
                  )}
                </span>
                <input
                  type="button"
                  value="Search"
                  className="btn-slim search-btn"
                  onClick={() => {
                    setTableLoader(true);
                    getGridData({
                      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
                      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
                      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
                      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
                      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
                      dealTypeId: props.dropDownValues?.dealType ?? [],
                      materialNumberId:
                        props.dropDownValues?.materialNumber ?? [],
                      plant_code: props.dropDownValues?.plant ?? [],
                      residualStatusId:
                        props.dropDownValues?.residualStatus ?? [],
                      page: 1,
                      per_page: pageSizeNo,
                      columnFilter: {},
                      sortby: SortBy,
                      complianceSorting: SortType,
                      searchText: searchText ?? "",
                      currentTabSelection: props.tabKey
                    });
                  }}
                />
              </span>
              <span className="marginset">
                <Glossary category="fgSlob" />
              </span>
              <span className="marginset">
                <span
                  className="info_btn"
                  title={
                    props.isExpand ? "Minimise Datatable" : "Maximise Datatable"
                  }
                  onClick={() => {
                    toggleExpandCollapse();
                    setTimeout(() => {
                      tableHt(props.isExpand);
                    }, 400);
                  }}
                >
                  <img src={isExpand ? PinchIcon : OpenIcon} alt="Open" />
                </span>
              </span>
            </div>
          )}
          <div className="clear"></div>
          {val.length !== 0 && (
            <>
              <div className="pull-left mt-2">
                <span className="row-click-link">
                  <img src={ClickIcon} alt="" />
                  Click the highlighted cells to edit
                </span>
                &nbsp;&nbsp;
                <span className="row-click-link ml-2">
                  <img src={resizeIcon} alt="" />
                  &nbsp; Doubleclick &amp; move the header column borders to
                  resize
                </span>
              </div>
              {/* {!props.isNoActions && ( */}
              <div className="pull-right mt-2">
                <button className="btn btn-slim btn-primary" onClick={() => { props.setColumnClearSearch(!props.clearColumnSearch) }}>Clear Search</button>
              </div>
              {/* )} */}
            </>
          )}
        </div>
      )}
      {/* <!--Search Table Data End--> */}
      <div className="clear"></div>

      {val.length !== 0 && (
        <>
          <div
            className="position-relative"
          // onscroll={() => console.log("table - scrolled")}
          >
            {(isTableLoader || publishLoading) && (
              <>
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="table_design mt-2" style={{ maxHeight: "auto" }}>
              <div
                {...getTableProps()}
                className={`table sticky ${page.length <= 0 ? "no-data" : ""
                  } ${freezedColumns} `}
              >
                <div className="header">
                  {headerGroups.map((headerGroup, index) => {
                    var col_pos_left = 0;
                    return (
                      <div
                        key={index}
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr"
                      >
                        {headerGroup.headers.map((column, index) => {
                          if (column.id === "selection") {
                            col_pos_left = 0;
                          } else if (column.id === "dealTypeId") {
                            col_pos_left =
                              column.width +
                              40 -
                              (column.width > 150 ? 150 : column.width);
                          } else if (column.id !== "selection") {
                            col_pos_left +=
                              column.width > 150 ? column.width : 150;
                          }
                          return (
                            <div
                              key={index}
                              {...column.getHeaderProps()}
                              style={{
                                width:
                                  column.id === "selection"
                                    ? "40px" : column.size ? column.size
                                      : column.width > 150
                                        ? column.width
                                        : "150px",
                                minWidth:
                                  column.id === "selection"
                                    ? "40px" : column.size ? column.size
                                      : column.width > 150
                                        ? column.width
                                        : "150px",
                                maxWidth:
                                  column.id === "selection"
                                    ? "40px" : column.size ? column.size
                                      : column.width > 150
                                        ? column.width
                                        : "150px",
                                left:
                                  column.id !== "dealTypeId"
                                    ? `${col_pos_left -
                                    (column.width < 150
                                      ? 150
                                      : column.width) +
                                    150
                                    }px`
                                    : "40px",
                              }}
                              className={`
                          th ${column.id} th-filter ${column.header_class} --- ${column.size} ---
                          sort_${SortBy} 
                          ${column.class} 
                          sticky-${column.sticky} 
                          ${column.id === "wk13_wk26_residual_zun"
                                  ? "text-right"
                                  : ""
                                } 
                          ${["residual_status",
                                  "dealTypeId",
                                  "previous_month_deal_type",
                                  "comment_field",
                                  "previous_month_comment_field",
                                  "previous_month_comment_field",
                                  "local_deal_completion_date",
                                  "local_deal_quantity_in_zun",
                                  "central_deal_quantity_in_zun",
                                  "remaining_quantity_in_zun",
                                  "local_deal_in_eur",
                                  "central_deal_in_eur",
                                  "remaining_stock_in_eur",
                                  "pack_type",
                                  "bom",
                                  "language_on_pack",
                                  "production_plant_number",
                                  "production_country_name"
                                ].includes(column.id)
                                  ? "td-nomination"
                                  : ""
                                }
                                ${["country_code", "city", "full_address", "post_code"].includes(column.id)
                                  ? "fc-14w-data"
                                  : ""
                                }
                              ${["ean_carton", "zun_per_case", "zun_per_pallet", "deal_pallet_zun"].includes(column.id)
                                  ? "td-additional-product-data"
                                  : ""
                                }
                                ${["agreed_quantity_in_zun",
                                  "cogs",
                                  "agreed_price_per_zun_in_eur",
                                  "sales_value_eur",
                                  "loss_value_eur",
                                  "margin_percentage",
                                  "net_cogs_removing_prov_eur",
                                  "net_margin_percentage",
                                  "spf_code",
                                  "tp_exworks_dc",
                                ].includes(column.id)
                                  ? "td-negotation-phase-with-customers"
                                  : ""
                                }

                                ${["quantity_available",
                                  "stock_is_located_in_country",
                                  "stock_is_produced_in_country",
                                  "ean_zun2"].includes(column.id)
                                  ? "td-bidding-portal"
                                  : ""
                                }
                                  ${props.page === "deal" &&
                                  (column.id === "zun_per_case2" ||
                                    column.id === "zun_per_pallet2")
                                  ? "td-bidding-portal"
                                  : ""
                                }
                          `}
                            >
                              <div
                                {...column.getResizerProps()}
                                className={`resizer ${column.isResizing
                                  ? "isResizing"
                                  : column.width
                                  }`}
                              />

                              <div className="th-in">
                                <OverlayTrigger
                                  overlay={tableToolTip(ColumnHeaderDesc("fgslob", column.Header))}
                                  trigger={["hover", "focus"]}
                                  placement="top"
                                  delay={{ show: 200, hide: 0 }}
                                >
                                  <span
                                    className="th-text"
                                  >
                                    <div className="header_col_title_wrap">
                                      <div className="header_col_title">
                                        {column.render("Header")}
                                      </div>

                                      {column.id !== "selection" && (
                                        <span
                                          className="sort-ico"
                                          style={{ cursor: "pointer" }}
                                        >
                                          <span>
                                            {SortType === "ASC" &&
                                              column.id === SortBy ? (
                                              <Icon
                                                path={mdiMenuDown}
                                                size="1.5rem"
                                                className="sortColumn"
                                                onClick={() =>
                                                  handleSorting(column)
                                                }
                                              />
                                            ) : (
                                              <Icon
                                                path={mdiMenuUp}
                                                size="1.5rem"
                                                className="sortColumn"
                                                onClick={() =>
                                                  handleSorting(column)
                                                }
                                              />
                                            )}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    {!skipFilter && (
                                      <div>
                                        {column.canFilter && column.id !== "view_status" ? (
                                          column.render("Filter") ?? null
                                        ) : (
                                          <div className="btn-slim transparent"></div>
                                        )}
                                      </div>
                                    )}
                                  </span>
                                </OverlayTrigger>
                                {column.id !== "selection" && (
                                  <span
                                    title="Doubleclick to resize column"
                                    className={`col-resize-handle resizer 
                                     ${column.isResizing ? "isResizing" : ""
                                      }  ${column.width < 150 ? "minimum" : ""}`}
                                    {...column.getResizerProps()}
                                  ></span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>

                {page.length <= 0 && (
                  <div className="body">
                    <div className="tr w-100">
                      <div role="cell" className="td">
                        <span className="td-in text-center">No Data</span>
                      </div>
                    </div>
                  </div>
                )}

                <div key={state} {...getTableBodyProps()} className={`body`}>
                  {page.length > 0 &&
                    page.map((row, index) => {
                      var body_col_pos_left = 0;
                      prepareRow(row);
                      return (
                        <div key={index} {...row.getRowProps()} className="tr">
                          {row.cells.map((cell, index) => {
                            if (cell.column.id === "selection") {
                              body_col_pos_left = 0;
                            } else if (cell.column.id === "dealTypeId") {
                              body_col_pos_left =
                                cell.column.width +
                                40 -
                                (cell.column.width > 150
                                  ? 150
                                  : cell.column.width);
                            } else if (cell.column.id !== "selection") {
                              body_col_pos_left +=
                                cell.column.width > 150
                                  ? cell.column.width
                                  : 150;
                            }
                            return (
                              <div
                                key={index}
                                onClick={() => setSelectedRow(cell.row.id)}
                                {...cell.getCellProps()}
                                style={{
                                  width:
                                    cell.column.id === "selection"
                                      ? "40px" : cell.column.size ? cell.column.size
                                        : cell.column.width > 150
                                          ? cell.column.width
                                          : "150px",
                                  minWidth:
                                    cell.column.id === "selection"
                                      ? "40px" : cell.column.size ? cell.column.size
                                        : cell.column.width > 150
                                          ? cell.column.width
                                          : "150px",
                                  maxWidth:
                                    cell.column.id === "selection"
                                      ? "40px" : cell.column.size ? cell.column.size
                                        : cell.column.width > 150
                                          ? cell.column.width
                                          : "150px",
                                  left:
                                    cell.column.id !== "dealTypeId"
                                      ? `${body_col_pos_left -
                                      (cell.column.width < 150
                                        ? 150
                                        : cell.column.width) +
                                      150
                                      }px`
                                      : "40px",
                                }}
                                className={`
                              td  ${cell.column.id} sort_${SortBy} ${cell.column.body_class} 
                              td-editable-${cell.column.editable} sticky-${cell.column.sticky}
                              ${selectedRow === cell.row.id ? cell.column.editable ? "table-highlight" : "table-highlight" : ""}
                              `}
                              >
                                {props.isBarOnHover && (
                                  <span
                                    className="td-in"
                                    title={cell.value}
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.column.id == "view_status" && (
                                      <OverlayTrigger
                                        overlay={tooltip}
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        delay={{ show: 600, hide: 0 }}
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-slim btn-secondary cursor-default"
                                          onMouseOverCapture={() => {
                                            setRowRecord(row.original);
                                            setPopUp(true);
                                          }}
                                        >
                                          View
                                        </button>
                                      </OverlayTrigger>
                                    )}

                                    {cell.column.round_off_values_no_decimal && !cell.column.integer_values &&
                                      Math.round(cell.value)
                                    }
                                    {(cell.column.id === 'ean_zun' || cell.column.id === 'ean_carton' || cell.column.id === 'bom') && toFloorNumber(cell.value)}

                                    {(cell.column.id !== 'ean_zun' && cell.column.id !== 'ean_carton' && cell.column.id !== 'bom') && !cell.column.round_off_values_no_decimal && !cell.column.integer_values &&
                                      cell.render("Cell")
                                    }
                                    {cell.column.integer_values &&
                                      <span>{toFloorNumber(cell.value)}</span>
                                    }
                                  </span>
                                )}

                                {!props.isBarOnHover && (
                                  <span
                                    className="td-in"
                                    title={cell.value}
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.column.round_off_values_no_decimal && !cell.column.integer_values &&
                                      Math.round(cell.value)}
                                    {!cell.column.round_off_values_no_decimal && !cell.column.integer_values &&
                                      cell.render("Cell")}
                                    {cell.column.integer_values &&
                                      <span>{toFloorNumber(cell.value)}</span>
                                    }
                                  </span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {totalRecordCount > 0 && (
              <ReactTablePagination
                page={page}
                gotoPage={handleGotoPages}
                previousPage={handlePrevPages}
                nextPage={handleNxtPages}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                manualPageSize={manualPageSize}
                dataLength={totalRecordCount}
                handleItemPerPage={handleItemPerPage}
                handlePaginationReset={handlePaginationReset}
                pageSizeNo={pageSizeNo}
                totalCount={totalCount}
                setPageIndex={setPageInd}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(TableGrid);
