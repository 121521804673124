import React, { useContext, useEffect, useState, useRef } from "react";
import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  useSortBy,
} from "react-table";
import { Checkbox } from "./Checkbox";
import { useSticky } from "react-table-sticky";
import moment from "moment";
import TrashIcon from "../../../Assets/img/icon-trash-white.svg";
import { UserContext } from "../../Application";


const TableGrid = ({
  columns,
  data,
  setSelection,
  selection,
  onActionClick,
  toggleSelection,
  handleCheckBox,
  handleSelectAllCheckBox,
  cells_width_auto,
  hasRowSelection,
  display,
  displayedAsNotification,
  updateCellData,
  tableType,
  editIndCustomer,
  deleteIndCustomer,
  copyIndCustomer,
  table_type,
  ddlInfo,
  newUserInfo,
  ...props
}) => {
  const user = useContext(UserContext);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows } =
    useTable(
      {
        columns,
        data,
        selection,
        handleCheckBox,
        handleSelectAllCheckBox,
        toggleSelection,
        updateCellData,
      },
      useSortBy,
      usePagination,
      useRowSelect,
      useBlockLayout,
      useSticky,
      (hooks) => {
        if (user?.userRole !== "User")
          if (hasRowSelection) {
            hooks.visibleColumns.push((columns) =>  !props.isShowingUserDataUnsaved ? [
               {
                id: "selection",
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                  <Checkbox {...getToggleAllPageRowsSelectedProps()} />
                ),
                Cell: ({ row }) => (
                  <Checkbox {...row.getToggleRowSelectedProps()} />
                ),
                sticky: "left",
              } ,
              ...columns,
            ] :  [...columns] );
          }
      }
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const firstPageRows = rows.slice(0, 20);
  const firstPageRows = rows;
  useEffect(() => {
    if (display !== "controlAdminGroups")
      setSelection(selectedFlatRows);
  }, [selectedFlatRows]);

  const qqq = useRef(null);

  const ooo = (el) => {
    console.log("left pos",el.target);
    el.target.addEventListener('keydown', e => {
      console.log("keydown",e)
      if (e.key === 'ArrowLeft') {
        console.log("left pos",el.target.scrollLeft)
          //el.target.scrollLeft = -30
      } 
      // else if (e.key === 'ArrowRight') {
      //     scrollAmount = 30
      // }
    }, true);
  }

  // useEffect(()=>{
  //   setTimeout(() => {
  //     qqq.current.addEventListener('key', e => {
  //       console.log("keydown",e)
  //     });
  //   }, 2000);
  // },[])

  const ooo2 = (el) => {
    console.log("pos",el.target.scrollTop,el.target.scrollLeft);
    // rrr.current.addEventListener('keydown', e => {
    //   console.log("keydown",e)
    //   if (e.key === 'ArrowLeft') {
    //     console.log("left pos",el.target.scrollLeft)
    //       //el.target.scrollLeft = -30
    //   } 
    //   // else if (e.key === 'ArrowRight') {
    //   //     scrollAmount = 30
    //   // }
    // }, true);
  }

  useEffect(()=>{
    let tdu = document.querySelector(".table-design-user-modal");
    tdu.scrollLeft = 0;

    // if(props.actionOnUser === 'add') {
    //   tdu.scrollTop = 990000;

    //   // setTimeout(() => {
    //   //   let allRows = document.querySelectorAll(".table-design-user-modal .body .tr");
    //   //   if(allRows.length) {
    //   //     let lastRow = allRows[allRows.length - 1];
    //   //     lastRow.classList.add('highlight_once');
    //   //   }
    //   // }, 600);

    // } else {
    //   tdu.scrollTop = 0;
    // }

    
  },[data])




  // useEffect(()=>{
  //   console.log("props.isViewCustomers",props.isViewCustomers);
  // },[props]);

  // --- style={(tableType == 'viewCustomers') ? {maxHeight : '209px'} : 'auto'}
  return (
    <>
      <div className="position-relative" >
        <div className={`table_design ${!props.isShowingUserDataUnsaved ? 'table-design-user-modal' : ''} mt-2 w-100`} tabIndex="1" >
          <div
            {...getTableProps()}
            className={`table sticky table-two-col ${table_type ? table_type : ''} data-table-two display nowrap scrollclass ${(data.length <= 0) ? 'no-data' : ''} ${cells_width_auto ? "cells-evenly-spaced" : ""
              }`}
          >
            
            <div className={`header ${cells_width_auto ? "w-100" : ""}`}>
              {headerGroups.map((headerGroup) => (
                <div className="tr">
                  {headerGroup.headers.map((column) => (
                    <div className={`th ${column.id}`}
                      style={{
                        width: `${column.id === "column_name" ? "300px" : ""}`,
                        width: `${column.id === "description" ? "400px" : ""}`,
                        width: `${column.id === "selection" ? "160px" : ""}`
                      }}
                    >

                      {column.id === "selection" &&
                        <div className="th-in content-center">
                          <img src={TrashIcon} width={'20px'} title="Delete" />
                        </div>
                      }
                      {column.id !== "selection" && (
                        <div className="th-in">
                          {column.render("Header")}
                        </div>
                      )}

                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>

            {data.length <= 0 && (
              <div className="body w-100">
                <div className="tr w-100">
                  <div role="cell" className="td  w-100">
                    <span className="td-in text-center">No Data</span>
                  </div>
                </div>
              </div>
            )}

            {data.length > 0 && (
              <div className={`body ${cells_width_auto ? "w-100" : ""}`} {...getTableBodyProps()}  >
                {firstPageRows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <div className="tr" key={i}>
                      {row.cells.map((cell,index) => {
                        var bsArr = cell.row.original.permission?.businessGroup?.map(w => (w?.Name !== undefined) ? <div className="td-in" title={w?.Name} >{w?.Name}</div> : '');
                        var mcoArr = cell.row.original.permission?.mco?.map(w => (w?.Name !== undefined) ? <div className="td-in" title={w?.Name}>{w?.Name}</div> : '');
                        var countryArr = cell.row.original.permission?.country?.map(w => (w?.Name !== undefined) ? <div className="td-in" title={w?.Name}>{w?.Name}</div> : '');
                        return (
                          <div key={index} className={`td ${cell.column.id} ${cell.column.class}  align-items-start`} style={{
                            width: `${cell.column.id === "column_name" ? "300px" : ""}`,
                            width: `${cell.column.id === "description" ? "400px" : ""}`,
                            width: `${cell.column.id === "selection" ? "160px" : ""}`
                          }}>


                            <div className={`td-in w-100 ${(cell.column.id === 'selection') ? 'content-center' : ''}`} title={cell.value}  >
                              {(cell.column.id != 'createdDate'&& cell.column.id != 'tool'&& cell.column.id != 'category'&& cell.column.id != 'tabViewSetting'&& cell.column.id != 'brand'
                               && cell.column.id != 'businessGroup' && cell.column.id != 'bussiness_group' && cell.column.id != 'total_members' && cell.column.id != 'mco' && cell.column.id != 'country' && cell.column.id != 'actions') && (
                                cell.render("Cell")
                              )}
                              {(cell.column.id == 'createdDate') && (
                                moment(cell.value).format('DD-MM-YYYY')
                              )}
                              {(cell.column.id == 'businessGroups') && (
                                <div className="cell-list">{bsArr}</div>
                              )}
                              {(cell.column.id == 'businessGroup') && (
                                <div className="cell-list ">{cell.row.original?.businessGroup?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).businessGroup.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'mco') && (
                                <div className="cell-list">{mcoArr}</div>
                              )}
                              {(cell.column.id == 'tool') && (
                                <div className="cell-list ">{cell.row.original?.tool?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).tool.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'tabViewSetting') && (
                                <div className="cell-list ">{cell.row.original?.tabViewSetting?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).tabViewSetting.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'category') && (
                                <div className="cell-list ">{cell.row.original?.category?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).category.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'brand') && (
                                <div className="cell-list ">{cell.row.original?.brand?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).brand?.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {!countryArr && (cell.column.id == 'country') && (
                                <div className="cell-list ">{cell.row.original?.country?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).country.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'country') && (
                                <div className="cell-list">{countryArr}</div>
                              )}
                              {!mcoArr && (cell.column.id == 'mco') && (
                                <div className="cell-list ">{cell.row.original?.mco?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{JSON.parse(row.original?.ddlInfo).mco?.filter(i=>i.key===w).map(i=>i.value)}</div> : '')}</div>
                              )}
                              {!bsArr && (cell.column.id == 'bussiness_group') && (
                                <div className="cell-list">{cell.row.original?.bussiness_group?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{w}</div> : '')}</div>
                              )}
                              {(cell.column.id == 'total_members') && (
                                <div className="cell-list td-in">{cell.row.original?.total_members?.map(w => (w !== undefined) ? <div className="td-in" title={w}>{w}</div> : '')}</div>
                              )}


                              {(!displayedAsNotification && cell.column.id == 'actions') && (
                                <>
                                  <div className="d-flex flex-column align-items-center justify-content-center">
                                    <button className="btn btn-outline-secondary btn-slim w-100 btn-short mr-2 mt-1 mb-1"
                                      onClick={() => {
                                        editIndCustomer(cell.row.original.email)
                                      }}>Edit</button>
                                    <button className="btn btn-outline-blue btn-slim w-100 btn-short mr-2 mt-1 mb-1"
                                      onClick={() => {
                                        copyIndCustomer(cell.row.original.email)
                                      }}>Copy</button>
                                    <button className="btn btn-outline-primary btn-slim w-100 btn-short mr-2 mt-1 mb-1" onClick={() => {
                                      deleteIndCustomer(cell.row.original.email)
                                    }}>Delete</button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableGrid;
