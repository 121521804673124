import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";

import { withRouter } from "react-router";
import Dropdown from "../../Views/common/Dropdown";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import Crtracker from "../../Common/crtracker/Crtracker";
import PomTable from "./PomTable";
import { getPomUpdatedDate, getUserPreferences, saveUserPreferences } from '../../Services/PomService';
import { UserContext, UserSavePreference } from "../Application";
const PomDetails = (props) => {
  const [dropDownValues, setDropDownValues] = useState();
  const [isExpand, setIsExpand] = useState(false);
  const [updateTime, setUpdatedTime] = useState('');
  const [reload, setReload] = useState(false)
  const [dropDownPreference,setDropDownPreference] = useState();
  const [columnSelected,SetColumnSelected]= useState()
  const {userSavePreference,saveUserPreference} = useContext(UserSavePreference)
  const [userSavePreferenceData, setUserSavePreferenceData]=useState()
  const [publishdropdownReload, setPublishdropdownReload]=useState(false)
  const user = useContext(UserContext)

  const [reeload,setreeload] = useState(false)
  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };
  useEffect(()=>{
    console.log(user)
  },[user])
  useEffect(()=>{
    console.log(dropDownPreference)
    console.log(columnSelected)
  },[dropDownPreference,columnSelected])

  // const handledropdownpreferense=(data)=>{
  //   console.log(data)
  // }

  const handleSavePreference =()=>{
    const data={
      preferenceData:dropDownPreference?dropDownPreference:{alert:[],
        businessGroup:[],
        category:[],
        country:[],
        foodSolution:[],
        forecastFamily:[],
        mrpc:[],
        nextStep:[],
         plant:[],
        reporting_family:[],},
        selectedColumns:columnSelected?columnSelected:window.sessionStorage.getItem("preferences")?JSON.parse(window.sessionStorage.getItem("preferences")).selectedColumns:["all"]
      }
    const payload = {
      userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
      userRole:user.userRole,
      userEmail:user.email,
      userPreference:data
    }
    console.log(payload)
    saveUserPreferences(payload).then((res)=>console.log(res))

  }
  useEffect(()=>{
    console.log(userSavePreference)
  },[userSavePreference])
  const [ asddd,setasddd] =useState(false)

  useEffect(()=>{
    const dummy={alert:[],
      businessGroup:[],
      category:[],
      country:[],
      foodSolution:[],
      forecastFamily:[],
      mrpc:[],
      nextStep:[],
       plant:[],
      reporting_family:[],
    }
    getUserPreferences(user.email).then(res=>{
        console.log(res.data[0])

      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)
      // saveUserPreference(JSON.parse(res.data[0].UserPreference))
      window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
      setasddd(true)
      }    
      else{
        const payload = {
          userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
          userRole:user.userRole,
          userEmail:user.email,
          userPreference:{ preferenceData:dummy,
            selectedColumns:["all"]
          }}
        console.log(payload)
        saveUserPreferences(payload).then((res)=>{setreeload(!reeload)})
        console.log(dummy)
        window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:{},selectedColumns:['all']}))
      }  
      
    }
  )
  },[user])

  useEffect(()=>{
      getUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }         
    }
  )
  },[reeload])

  const getUpdateDate = async () => {
    try {
      const updateDatedata = await getPomUpdatedDate();
      setUpdatedTime(updateDatedata.update_date); // Assuming the key for update_date is "update_date" in the response data
    } catch (error) {
      console.error("Error while fetching update_date:", error);
    }
  }

  useEffect(() => {
    getUpdateDate()
  }, [])
  useEffect(() => {
    console.log(dropDownValues)
  }, [dropDownValues])

  
  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }
  return (
     
        <div className="container-fluid tablegrid-height-fluid pl-0 pr-0">
          <div className="main_header align-baseline page-el">
          <Header isNotProduction={!isProd()} updateTime={updateTime} updateTimeText={'Update Date: '} />
          </div>

          <div className={`main-content-row ${isExpand ? "table-expanded" : ""}`}>
            <div className="side-menu">
              <SideMenu />
            </div>
            <div className="main-div-content">
                {/* <div class="page-body-top-content">
                  <div class="main-update-date">
                    <p className="m-0">Update Date: {updateTime}</p>
                  </div>
                  <div className="d-inline-block ml-3">
                    <Crtracker />
                  </div>
                </div> */}
                
              {!isExpand && (
                <div className="page-el">
                  {asddd && <Dropdown
                    list={[
                      "Business Group",
                      "Country",
                      "Food Solution Indicator",
                      "Plant Code",
                      "Category",
                      "Alert Type",
                      "Next Step",
                      "MRP controller",
                      "Forecast Family",
                      "Reporting Family",
                      "Owner",
                    ]}
                    onChange={(value) => {
                      setDropDownValues({ ...value });
                    }}
                    dropDownPreference={dropDownPreference}
                    setDropDownValues={setDropDownValues}
                    reload={reload}
                    setReload={setReload}
                    publishdropdownReload={publishdropdownReload}
                    setDropDownPreference={setDropDownPreference}
                    handleSavePreference={handleSavePreference}
                    userSavePreferenceData={userSavePreferenceData}
                    showPrefe = {true}
                  />}
                </div>
              )}

              {asddd &&<PomTable
                dropDownValues={dropDownValues}
                isExpand={isExpand}
                toggleExpandCollapse={toggleExpandCollapse}
                loadDropdownsData={() => {
                  setReload(true)
                  setPublishdropdownReload(true)
                }}
                columnSelected={columnSelected}
                SetColumnSelected={SetColumnSelected}
              />}
            </div>
          </div>
        </div>
      
  );
};

export default withRouter(PomDetails);