import { useContext } from "react";
import { setDropdownvaluesGlobal } from "../../../utility/helper";
import { UserAccessContext } from "../../Application";

export const LEGEND_COLOR = "#FFFFFF";
export const LABEL_COLOR = "#CCCCCC";

export function chunkString(str, maxWidth) {
  const sections = [];
  if (!str) return sections;
  const words = str.split(" ");
  let builder = "";

  for (const word of words) {
    if (word.length > maxWidth) {
      sections.push(builder.trim());
      builder = "";
      sections.push(word.trim());
      continue;
    }

    let temp = `${builder} ${word}`;
    if (temp.length > maxWidth) {
      sections.push(builder.trim());
      builder = word;
      continue;
    }

    builder = temp;
  }
  sections.push(builder.trim());

  return sections;
}

export const PayloadWithFilters = (filters,userAccess) => {


  return {

    countryId:setDropdownvaluesGlobal( filters?.country, userAccess?.pomdata?.data?.countryId),
    categoryId: setDropdownvaluesGlobal( filters?.category, userAccess?.pomdata?.data?.categoryId),
    businessGroupId: setDropdownvaluesGlobal( filters?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
    alertTypeId: filters?.alert ?? [],
    plant_code: filters?.plant ?? [],
    nextStepId: filters?.nextStep ?? [],
    foodSolutionIndicatorId: filters?.foodSolution ?? [],
    mrpControllerId: filters?.mrpc ?? [],

  };
};
