import { useState, useContext } from "react";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import BiddingPortalExternalTable from "../BiddingPortalExternal/BiddingPortal/BiddingPortalTable";
import { UserContext } from "../Application";
const BiddingPortal = () => {
  const user = useContext(UserContext);
  const [dropDownValues, setDropDownValues] = useState();
  const [isExpand, setIsExpand] = useState(false);
  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };
  let isProd = () => {
    if (window.location.href.includes("-p-")) {
      return true;
    } else if (window.location.href.includes("localhost")) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="container-fluid pl-0 pr-0">
      <div className="main_header align-baseline">
        <Header isNotProduction={!isProd()} />
      </div>
      <div className={`main-content-row ${isExpand ? "table-expanded" : ""}`}>
        <div className="side-menu">
          <SideMenu />
        </div>
        <div className="main-div-content">
          <BiddingPortalExternalTable />
        </div>
      </div>
    </div>
  );
};

export default BiddingPortal;
