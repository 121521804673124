import React, { useMemo, useEffect, useState, useRef } from "react";
import _ from "lodash";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { pageTopElHt, tableToolTip } from "../../utility/constants";
import { OverlayTrigger } from "react-bootstrap";
import ClearIcon from "../../Assets/img/icon-cancel-white.svg";
import { DebounceInput } from "react-debounce-input";

import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  useFilters,
  useResizeColumns,
} from "react-table";
import { useSticky } from "react-table-sticky";
// import { Styles } from "./TableStyles";
// import ULButton from "../../../../../common/button";
// import Ic_Download from "../../../../../assets/images/Ic_Download.svg";
import ReactTablePagination from "../../Common/react-table-pagination";
import ULDropdown from "../../Common/Dropdown";
import OpenIcon from "../../Assets/img/open.svg";
import SearchIcon from "../../Assets/img/search.svg";
import resizeIcon from "../../Assets/img/icon-resize.svg";
import ClickIcon from "../../Assets/img/clickicon.svg";
//import closeIcon from "../../Assets/img/close.png";
import PinchIcon from "../../Assets/img/icon-pinch.svg";
import Glossary from "../../Common/glossary";
import { Checkbox } from "./Checkbox";
import Loader from "../../Common/loader";
import Loadersml from "../../Common/loadersml";
import ColumnHeaderDesc from "../../Common/ColumnHeaderDesc";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BarChart } from "../../Common/charts/BarChart";
import { stackBarChartOptions } from "../../Components/InflowRiskVisibility/helpers";
import { arrayToKeyObject } from "../../utility/helper";
const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  status,
  dealMonths,
  packTypeList,
  updateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SummaryTableHandleDownload_ref,
  SortBy,
  setSortBy,
  SortType,
  setSortType,
  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  actions,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  //setSortBySelection,
  toggleExpandCollapse,
  handleCheckBox,
  handleSelectAllCheckBox,
  isCheck,
  isNotRequiredTableCoumnSort,
  setCreateListItem,
  isNewListSaved,
  publishLoading,
  totalCount,
  setPageInd,
  pageInd,
  state,
  filterData,
  refObj,
  setRefObj,
  skipFilter = false,
  hideFilter = false,
  isExpand,
  existingBiddingListData,
  selectedRowIds,
  ...props
}) => {
  //{props?.heading !== undefined

  const columns = useMemo(() => tableColumns, [tableColumns]);
  // const dataCopy = tableData.map( r => ({...r, remaining_quantity_in_zun: r.wk13_wk26_residual_zun}))
  // const data = useMemo(() => tableData.map( r => ({...r, remaining_quantity_in_zun: r.wk13_wk26_residual_zun})), [tableData]);
  const data = useMemo(
    () =>
      tableData.map((obj) => {
        return {
          ...obj,
          remaining_quantity_in_zun:
            (obj.total_stock_zun ?? 0) -
            (obj.local_deal_quantity_in_zun ?? 0) -
            (obj.central_deal_quantity_in_zun ?? 0),
          remaining_stock_in_eur: (
            (((obj.total_stock_zun ?? 0) -
            (obj.local_deal_quantity_in_zun ?? 0) -
            (obj.central_deal_quantity_in_zun ?? 0)) ?? 0) * (obj.standard_price ?? 0)
          ).toFixed(2),
        };
      }),
    [tableData]
  );

  // const [loadertable, setLoadertable] = React.useState(false)
  const [isTableLoader, setTableLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [rowRecord, setRowRecord] = useState({});
  const [freezedColumns, setFreezedColumns] = useState("");
  const [pageTableHeight, setPageTableHeight] = useState();
  const manualPageSize = [5, 10, 20, 30];
  const INITIAL_HIDDEN_COLUMNS = columns
    .filter((column) => !column.show)
    .map((column) => column.accessor);

  // --- Resize - DataTable
  useEffect(() => {
    window.addEventListener("resize", tableHt);
  });

  const getOptions = () => {
    return {
      labels: ["SKU status"],
      datasets: [
        {
          label: "Quantity Available",
          data: [rowRecord?.quantity_available],
          backgroundColor: "#00B0F0",
          barThickness: 50,
        },
        {
          label: "Reserved Quantity",
          data: rowRecord?.reserved_quantity,
          backgroundColor: "#92D050",
          barThickness: 50,
        },
        {
          label: "Accepted Quantity",
          data: [rowRecord?.accepted_quantity],
          backgroundColor: "#00B050",
          barThickness: 50,
        },
      ],
    };
  };
  const tableHt = (isTableContracted) => {
    let spaceDiff = props.page == "deal" ? 80 : 0;
    let nn = pageTopElHt(isTableContracted);
    //console.log("Waste Risk details --- setPageTableHeight", nn);
    let nnf = 180 + spaceDiff + (props.isExpand ? 0 : 0);
    setPageTableHeight(nn - nnf);
  };
  useEffect(() => {
    tableHt(true);
  }, []);

  function DefaultColumnFilter(cellObj) {
    var [searchFilterLoader, setSearchFilterLoader] = useState(false);
    const ref = useRef(null);
    const { id, filterValue, setFilter } = cellObj.column;
    const [value, setValue] = useState("");
    const [isSearchClicked, setIsSearchClicked] = useState(false);

    const debouncedChangeHandler = (e) => {
      setSearchFilterLoader(true);
      setValue(e.target.value);
      handleToggle(e);
    };

    const handleToggle = (e) => {
      cellObj.filterData(e.target.value, id);
      setTableLoader(true);
      setTimeout(() => {
        setSearchFilterLoader(false);
      }, 500);
    };

    var searchCol = (e) => {
      //  //setTimeout(() => {

      //  _.debounce(func, [wait=0], [options={}])
      filterData(e.target.value, id);
      // console.log("showSearchFilterLoader", searchFilterLoader);
      setSearchFilterLoader(false);
      // }, 300);
    };
    useEffect(() => {
      let obj = {};
      obj[id] = ref;
      refObj.push(obj);
      setRefObj([...refObj]);
    }, [id, refObj]);

    return (
      <>
        {!hideFilter && (
          <div className="input-edit-mode input-with-loader btn-slim">
         <DebounceInput
            id={id}
            key={id}
            value={value}
            ref={ref}
            onKeyUp={(ev) => {
              if (ev.target.value) {
                refObj.forEach((key) => {
                  if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
                    if (key[Object.keys(key)[0]].current.state)
                      key[Object.keys(key)[0]].current.state.value = "";
                    else {
                      key[Object.keys(key)[0]].current.value = "";
                    }
                  }
                });
              }
            }}
            // onBlur={() => {
            //   setTimeout(()=>{
            //     if(value){
            //       setValue("");
            //       filterData("", id);
            //       setTableLoader(true);
            //     }
            //   },300)
            // }}
            debounceTimeout={300}
            onChange={(event) => debouncedChangeHandler(event)}
          />

            {searchFilterLoader && (
              <span>
                <Loadersml />
              </span>
            )}
          </div>
        )}
      </>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 400,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    //visibleColumns,
    allColumns,
    toggleHideColumn,
    toggleHideAllColumns,
    prepareRow,
    //setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      handleClickEditCell,
      clickedIndex,
      clickedCell,
      updateCellData,
      filterData,
      status,
      dealMonths,
      packTypeList,
      actions,
      refObj,
      setRefObj,
      onActionClick,
      handleCheckBox,
      handleSelectAllCheckBox,
      selection,
      isCheck,
      manualPagination: true,
      pageCount: Math.ceil(totalRecordCount / pageSizeNo),
      initialState: {
        pageIndex: pageInd,
        pageSize: pageSizeNo,
        hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        selectedRowIds,
      },
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      hooks?.visibleColumns?.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          sticky: "left",
        },
        ...columns,
      ]);
    }
  );

  /* const [val, setVal] = useState([
    "*",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]); */

  const [val, setVal] = useState([
    "all",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]);

  //column customization code start here
  const selectAllOption = { value: "all", label: "Select all" };
  const filterColumns = allColumns
    ? allColumns
        .filter((item) => item.sticky !== "left")
        .map((column) => ({
          value: column.id,
          label: column.Header,
          sticky: column.sticky,
        }))
    : [];

  const handleSelect = (selected, action) => {
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      //select all
      setVal([
        "all",
        ...columns
          .filter((item) => item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      // columns
      //   .map((item) => item.accessor)
      //   .forEach((item) => toggleHideColumn(item));
      toggleHideAllColumns(false);
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      //deselect all
      // setVal([]);
      setVal(
        columns.filter((item) => item.sticky).map((item) => item.accessor)
      );
      columns
        .filter((item) => !item.sticky)
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
      // toggleHideAllColumns(true);
      // columns
      //   .map((item) => item.accessor)
      //   .forEach((item) => toggleHideColumn(item));
      // toggleHideAllColumns(true);
    } else {
      setVal(
        action.action === "select-option" && selected?.length === columns.length
          ? [{ value: "all", label: "Select all" }, ...selected].map(
              (item) => item.value
            )
          : selected.filter((i) => i.value !== "all").map((item) => item.value)
      );
      if (action.action === "select-option" && selected.length === 1) {
        toggleHideColumn("selection");
      }
      // setVal(selected.map((item) => item.value));
    }

    //Add and remove columns from the table
    if (action.action !== "pop-value") {
      //while clear data from the input
      if (action.action === "clear") {
        //setAppendColumn(action.removedValues.map((item) => item.value));
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        //setAppendColumn([...appendColumn, action.option.value]);
        toggleHideColumn(action.option.value);
      }
    }
  };

  useEffect(() => {
    if (!isNewListSaved) {
      setSelection(selectedFlatRows);
    } else {
      setSelection([]);
    }
  }, [selectedFlatRows, isNewListSaved, setSelection]);

  useEffect(() => {
    if (setCreateListItem && !isNewListSaved) {
      setCreateListItem(selectedFlatRows);
    } else if (isNewListSaved) {
      selectedFlatRows.forEach((key, index) => {
        key.toggleRowSelected();
      });
    }
  }, [selectedFlatRows, setCreateListItem, isNewListSaved]);

  useEffect(() => {
    setTableLoader(true);
  }, [props.dropDownValues]);


  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleSorting = (column) => {
    setTableLoader(true);
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
    setTimeout(() => setTableLoader(false), 1000);
  };

  let selectionValues = {
    "3wk + 26wk Residual ZUNs": "wk13_wk26_residual_zun",
    "13wk + 26wk Residual EUR": "wk13_wk26_residual_eur",
    "Central deal quantity in ZUNs": "central_deal_quantity_in_zun",
    "Central deal in EUR": "central_deal_in_eur",
    "Remaining quantity in ZUN": "remaining_quantity_in_zun",
    "Remaining stock in EUR": "remaining_stock_in_eur",
    "Expiry date": "expiry_date",
  };

  const handleNxtPages = () => {
    setTableLoader(true);
    nextPage();
    // setTimeout(()=> setTableLoader(false), 1000)
  };
  const handlePrevPages = () => {
    setTableLoader(true);
    previousPage();
    // setTimeout(()=> setTableLoader(false), 1000)
  };

  const handleGotoPages = (i) => {
    setTableLoader(true);
    gotoPage(i);
    // setTimeout(()=> setTableLoader(false), 1000)
  };

  useEffect(() => {
    setTableLoader(false);
  }, [data]);

  const clearSearchClose = () => {
    setTableLoader(true);
    setSearchText("");
    getGridData({
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: props.dropDownValues?.country ?? [],
      categoryId: props.dropDownValues?.category ?? [],
      businessGroupId: props.dropDownValues?.businessGroup ?? [],
      plant_code: props.dropDownValues?.plant ?? [],
      dealTypeId: props.dropDownValues?.dealType ?? ["2"],
      nextStepId: props.dropDownValues?.nextStep ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      page: 1,
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: "",
    });
  };

  // const processHeaderDesc = (column) => {
  //   let headerTooltip = ``;
  //   headerDesc.map(e => {
  //     if(column == e.column) {
  //       headerTooltip = `\n \n${e.desc}`;
  //       return;
  //     }
  //   });
  //   return `${column}${headerTooltip}`;
  // }

  // const s2 = (column) => {
  //   let ws;
  //   headerDesc.map(e => {
  //     if(column == e.column) {
  //       ws = e.desc;
  //     };
  //   });
  //   console.log("ws",ws);
  // }
  // console.log("Water risk filter", [selectAllOption, ...filterColumns])

  useEffect(() => {
    if (props.adjustPageGridHt != null) {
      console.log("props.isExpand", isExpand, props.adjustPageGridHt);
      toggleExpandCollapse();
      setTimeout(() => {
        tableHt(isExpand);
      }, 400);
    }
  }, [props.adjustPageGridHt]);

  return (
    <>
      {/* <!--Search Table Data Start--> */}
      {!hideFilter && (
        <div className="table-filters">
          <div className="pull-left flex align-items-center justify-content-between">
            <ul className="table-form flex align-items-center">
              {!props.isNoColumnSelection && (
                <li className="pr-0">
                  <div className="flex align-items-center">
                    <label className="d-inline-block mx-2">Columns</label>
                    <ULDropdown
                      name="custom_column_filter"
                      value={val}
                      options={[selectAllOption, ...filterColumns]}
                      //options={filterColumns}
                      onChange={handleSelect}
                      isMulti={true}
                      isClearable={false}
                      placeholder={"Search Column"}
                      className="react-select-container inp-std-width"
                    />
                  </div>
                </li>
              )}
              {!props.isNoColumnSelection && (
                <li className="pl-2">
                  <label>
                    {val.filter((i) => i === "all")?.length
                      ? val.length - 1
                      : val.length}{" "}
                    selected
                  </label>
                </li>
              )}
              {!isNotRequiredTableCoumnSort && (
                <li>
                  <label>Table Sort Column </label>
                  <select
                    style={{ width: "220px" }}
                    onChange={(event) => {
                      setTableLoader(true);
                      setSortBy(
                        event.target.value !== ""
                          ? selectionValues[event.target.value]
                          : ""
                      );
                      getGridData({
                        brandId: props.dropDownValues?.brand ?? [],
                        countryId: props.dropDownValues?.country ?? [],
                        categoryId: props.dropDownValues?.category ?? [],
                        businessGroupId:
                          props.dropDownValues?.businessGroup ?? [],
                        dealTypeId: props.dropDownValues?.dealType ?? [],
                        materialNumberId:
                          props.dropDownValues?.materialNumber ?? [],
                        mcoId: props.dropDownValues?.mco ?? [],
                        plantId: props.dropDownValues?.plant ?? [],
                        residualStatusId:
                          props.dropDownValues?.residualStatus ?? [],
                        languageOnPack:
                          props.dropDownValues?.languageOnPack ?? [],
                        page,
                        per_page: pageSizeNo,
                        sortby: SortBy,
                        complianceSorting: SortType,
                        searchText: searchText ?? "",
                      });
                      setTimeout(() => setTableLoader(false), 1000);
                    }}
                  >
                    <option key={"wk13_wk26_residual_zun"}>
                      13wk + 26wk Residual ZUNs
                    </option>
                    <option key={"wk13_wk26_residual_eur"}>
                      13wk + 26wk Residual EUR
                    </option>
                    <option key={"central_deal_quantity_in_zun"}>
                      Central deal quantity in ZUNs
                    </option>
                    <option key={"central_deal_in_eur"}>
                      Central deal in EUR
                    </option>
                    <option key={"remaining_quantity_in_zun"}>
                      Remaining quantity in ZUN
                    </option>
                    <option key={"remaining_stock_in_eur"}>
                      Remaining stock in EUR
                    </option>
                    <option key={"expiry_date"}>Expiry date</option>
                  </select>
                </li>
              )}
            </ul>
          </div>

          {!props.isNoSearch && (
            <div className="pull-right flex align-items-center justify-content-between">
              <span className="search-wrap-out w-100">
                <span className="search-wrap">
                  <img src={SearchIcon} className="filter-search" alt="" />
                  <input
                    type="text"
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value ?? "");
                    }}
                    // placeholder="Planner ID / SKU Code / Plant Code"
                    placeholder="Material Number/ Material Description"
                    title="Material Number/ Material Description"
                    className="btn-slim search-input"
                  />
                  {searchText != "" && (
                    <a
                      className="filter-clear"
                      onClick={() => clearSearchClose()}
                    >
                      <img src={ClearIcon} alt="" />
                    </a>
                  )}
                </span>
                <input
                  type="button"
                  value="Search"
                  className="btn-slim search-btn"
                  onClick={() => {
                    setTableLoader(true);
                    getGridData({
                      brandId: props.dropDownValues?.brand ?? [],
                      countryId: props.dropDownValues?.country ?? [],
                      categoryId: props.dropDownValues?.category ?? [],
                      businessGroupId:
                        props.dropDownValues?.businessGroup ?? [],
                      dealTypeId: props.dropDownValues?.dealType ?? [],
                      materialNumberId:
                        props.dropDownValues?.materialNumber ?? [],
                      mcoId: props.dropDownValues?.mco ?? [],
                      plant_code: props.dropDownValues?.plant ?? [],
                      residualStatusId:
                        props.dropDownValues?.residualStatus ?? [],
                      page: 1,
                      per_page: pageSizeNo,
                      //  sortby: "businessGroupId",
                      //  complianceSorting: "ASC",
                      sortby: SortBy,
                      complianceSorting: SortType,
                      searchText: searchText ?? "",
                    });
                  }}
                />
              </span>
              <span className="marginset">
                <Glossary category="fgSlob" />
              </span>
              <span className="marginset">
                <span
                  className="info_btn"
                  title={
                    props.isExpand ? "Minimise Datatable" : "Maximise Datatable"
                  }
                  onClick={() => {
                    toggleExpandCollapse();
                    setTimeout(() => {
                      tableHt(props.isExpand);
                    }, 400);
                  }}
                >
                  <img src={isExpand ? PinchIcon : OpenIcon} alt="Open" />
                </span>
              </span>
            </div>
          )}
          <div className="clear"></div>
          {val.length !== 0 && (
            <>
              <div className="pull-left mt-2">
                <span className="row-click-link">
                  <img src={ClickIcon} alt="" />
                  Click the highlighted cells to edit
                </span>
                &nbsp;&nbsp;
                <span className="row-click-link ml-2">
                  <img src={resizeIcon} alt="" />
                  &nbsp; Doubleclick &amp; move the header column borders to
                  resize
                </span>
              </div>
              {!props.isNoActions && (
                <div className="pull-right mt-2">
                  {/* <div className="btn-group">
                    <button
                      type="button"
                      className={`dropdown-toggle btn-published${selection.length > 0 ? " active-btn" : ""
                        }`}
                      id="defaultDropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                      data-bs-toggle={`${selection.length > 0 ? "dropdown" : ""}`}
                      onClick={() => {
                        if (!selection.length) {
                          props.toggleWarnMessageModal();
                        }
                      }}
                    >
                      Actions
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="defaultDropdown"
                    >
                      {actions.map((i, index) => (
                        <li key={index}>
                          <p
                            className="dropdown-item"
                            value={i.action_name}
                            onClick={

                              selection?.length
                                ? (e) => {
                                  onActionClick(i.action_name);
                                }
                                : () => { }
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {i.action_name}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div> */}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {/* <!--Search Table Data End--> */}
      <div className="clear"></div>

      {val.length !== 0 && (
        <>
          <div className="position-relative">
            {(isTableLoader || publishLoading) && (
              <>
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div
              className="table_design mt-2"
              style={{ maxHeight: "auto" }}>
              {/* <Styles> */}
              <div
                {...getTableProps()}
                className={`table sticky ${
                  page.length <= 0 ? "no-data" : ""
                } ${freezedColumns} `}
              >
                <div className="header">
                  {headerGroups.map((headerGroup, index) => {
                    var col_pos_left = 0;
                    return (
                      <div
                        key={index}
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr"
                      >
                        {headerGroup.headers.map((column, index) => {
                          if (column.id === "selection") {
                            col_pos_left = 0;
                          } else if (column.id === "dealTypeId") {
                            col_pos_left =
                              column.width +
                              40 -
                              (column.width > 150 ? 150 : column.width);
                          } else if (column.id !== "selection") {
                            col_pos_left +=
                              column.width > 150 ? column.width : 150;
                          }
                          return (
                            <div
                              key={index}
                              {...column.getHeaderProps()}
                              style={{
                                width:
                                  column.id === "selection"
                                    ? "40px"
                                    : column.width > 150
                                    ? column.width
                                    : "150px",
                                minWidth:
                                  column.id === "selection"
                                    ? "40px"
                                    : column.width > 150
                                    ? column.width
                                    : "150px",
                                maxWidth:
                                  column.id === "selection"
                                    ? "40px"
                                    : column.width > 150
                                    ? column.width
                                    : "150px",
                                left:
                                  column.id !== "dealTypeId"
                                    ? `${
                                        col_pos_left -
                                        (column.width < 150
                                          ? 150
                                          : column.width) +
                                        150
                                      }px`
                                    : "40px",
                              }}
                              className={`
                          th ${column.id} th-filter ${column.header_class} 
                          sort_${SortBy} 
                          ${column.class} 
                          sticky-${column.sticky} 
                          ${
                            column.id === "wk13_wk26_residual_zun"
                              ? "text-right"
                              : ""
                          } 
                            ${
                              column.id === "residual_status" ||
                              column.id === "dealTypeId" ||
                              column.id === "previous_month_deal_type" ||
                              column.id === "comment_field" ||
                              column.id === "previous_month_comment_field" ||
                              column.id === "previous_month_comment_field" ||
                              column.id === "local_deal_completion_date" ||
                              column.id === "local_deal_quantity_in_zun" ||
                              column.id === "central_deal_quantity_in_zun" ||
                              column.id === "remaining_quantity_in_zun" ||
                              column.id === "local_deal_in_eur" ||
                              column.id === "central_deal_in_eur" ||
                              column.id === "remaining_stock_in_eur" ||
                              column.id === "pack_type" ||
                              column.id === "bom" ||
                              column.id === "language_on_pack" ||
                              column.id === "production_plant_number" ||
                              column.id === "production_country_name"
                                ? "td-nomination"
                                : ""
                            }

                            ${
                              column.id === "country_code" ||
                              column.id === "city" ||
                              column.id === "full_address" ||
                              column.id === "post_code"
                                ? "fc-14w-data "
                                : ""
                            }

                            ${
                              column.id === "ean_carton" ||
                              column.id === "zun_per_case" ||
                              column.id === "zun_per_pallet" ||
                              column.id === "deal_pallet_zun"
                                ? "td-additional-product-data"
                                : ""
                            }


                            ${
                              column.id === "agreed_quantity_in_zun" ||
                              column.id === "cogs" ||
                              column.id === "agreed_price_per_zun_in_eur" ||
                              column.id === "sales_value_eur" ||
                              column.id === "loss_value_eur" ||
                              column.id === "margin_percentage" ||
                              column.id === "net_cogs_removing_prov_eur" ||
                              column.id === "net_margin_percentage" ||
                              column.id === "spf_code" ||
                              column.id === "tp_exworks_dc"
                                ? "td-negotation-phase-with-customers"
                                : ""
                            }


                            ${
                              column.id === "quantity_available" ||
                              column.id === "stock_is_located_in_country" ||
                              column.id === "stock_is_produced_in_country" ||
                              column.id === "ean_zun2"
                                ? // ||
                                  // column.id === "ean_carton2" ||
                                  // column.id === "zun_per_case2" ||
                                  // column.id === "zun_per_pallet2"
                                  "td-bidding-portal"
                                : ""
                            }
                                  ${
                                    props.page === "deal" &&
                                    (column.id === "zun_per_case2" ||
                                      column.id === "zun_per_pallet2")
                                      ? "td-bidding-portal"
                                      : ""
                                  }
                          `}
                            >
                              <div
                                {...column.getResizerProps()}
                                className={`resizer ${
                                  column.isResizing
                                    ? "isResizing"
                                    : column.width
                                }`}
                              />

                              <div className="th-in">
                                <OverlayTrigger
                                  overlay={tableToolTip(ColumnHeaderDesc("fgslob",column.Header))} 
                                  trigger={["hover","focus"]}
                                  placement="top"
                                  delay={{ show: 200, hide: 0 }}
                                >
                                    <span
                                      className="th-text" 
                                    >
                                      <div className="header_col_title_wrap">
                                        <div className="header_col_title">
                                          {column.render("Header")}
                                        </div>

                                        {column.id !== "selection" && (
                                          <span
                                            className="sort-ico"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <span>
                                              {SortType === "ASC" &&
                                              column.id === SortBy ? (
                                                <Icon
                                                  path={mdiMenuDown}
                                                  size="1.5rem"
                                                  className="sortColumn"
                                                  onClick={() =>
                                                    handleSorting(column)
                                                  }
                                                />
                                              ) : (
                                                <Icon
                                                  path={mdiMenuUp}
                                                  size="1.5rem"
                                                  className="sortColumn"
                                                  onClick={() =>
                                                    handleSorting(column)
                                                  }
                                                />
                                              )}
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                      {!skipFilter && (
                                        <div>
                                          {column.canFilter && column.id !== "add_customer" ? (
                                            column.render("Filter") ?? null
                                          ) : (
                                            <div className="btn-slim transparent"></div>
                                          )}
                                        </div>
                                      )}
                                    </span>
                                </OverlayTrigger>
                                {column.id !== "selection" && (
                                  <span
                                    title="Doubleclick to resize column"
                                    className={`col-resize-handle resizer 
                                     ${
                                       column.isResizing ? "isResizing" : ""
                                     }  ${column.width < 150 ? "minimum" : ""}`}
                                    {...column.getResizerProps()}
                                  ></span>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
                {page.length <= 0 && (
                  <div className="body">
                    <div className="tr w-100">
                      <div role="cell" className="td">
                        <span className="td-in text-center">No Data</span>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  key={state}
                  {...getTableBodyProps()}
                  className={`body  ${page.length <= 0 ? "no-data" : ""}`}
                >
                  {page.length > 0 &&
                    page.map((row, index) => {
                      var body_col_pos_left = 0;
                      prepareRow(row);
                      return (
                        <div key={index} {...row.getRowProps()} className="tr">
                          {row.cells.map((cell, index) => {
                            if (cell.column.id === "selection") {
                              body_col_pos_left = 0;
                            } else if (cell.column.id === "dealTypeId") {
                              body_col_pos_left =
                                cell.column.width +
                                40 -
                                (cell.column.width > 150
                                  ? 150
                                  : cell.column.width);
                            } else if (cell.column.id !== "selection") {
                              body_col_pos_left +=
                                cell.column.width > 150
                                  ? cell.column.width
                                  : 150;
                            }
                            return (
                              <div
                                key={index}
                                {...cell.getCellProps()}
                                style={{
                                  width:
                                    cell.column.id === "selection"
                                      ? "40px"
                                      : cell.column.width > 150
                                      ? cell.column.width
                                      : "150px",
                                  minWidth:
                                    cell.column.id === "selection"
                                      ? "40px"
                                      : cell.column.width > 150
                                      ? cell.column.width
                                      : "150px",
                                  maxWidth:
                                    cell.column.id === "selection"
                                      ? "40px"
                                      : cell.column.width > 150
                                      ? cell.column.width
                                      : "150px",
                                  left:
                                    cell.column.id !== "dealTypeId"
                                      ? `${
                                          body_col_pos_left -
                                          (cell.column.width < 150
                                            ? 150
                                            : cell.column.width) +
                                          150
                                        }px`
                                      : "40px",
                                }}
                                className={`
                              td  ${cell.column.id} sort_${SortBy} ${cell.column.body_class} 
                              td-editable-${cell.column.editable} sticky-${cell.column.sticky}
                              `}
                              >
                                {props.isBarOnHover && (
                                  <span
                                    className="td-in"
                                    title={cell.value}
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.column.id == "view_status" && (
                                      <button
                                        type="button"
                                        className="btn btn-slim btn-secondary"
                                        onMouseDown={() => {
                                          setRowRecord(row.original);
                                          setPopUp(true);
                                        }}
                                      >
                                        View
                                      </button>
                                    )}

                                    {cell.column.round_off_values_no_decimal &&
                                      Math.round(cell.value)}
                                    {!cell.column.round_off_values_no_decimal &&
                                      cell.render("Cell")}
                                    {/* {
                                      <span>
                                        jjj
                                      </span>
                                    } */}
                                  </span>
                                )}

                                {!props.isBarOnHover && (
                                  <span
                                    className="td-in"
                                    title={cell.value}
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {cell.column.round_off_values_no_decimal &&
                                      Math.round(cell.value)}
                                    {!cell.column.round_off_values_no_decimal &&
                                      cell.render("Cell")}
                                  </span>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
              {/* </Styles> */}
            </div>

            {totalRecordCount > 0 && (
              <ReactTablePagination
                page={page}
                gotoPage={handleGotoPages}
                previousPage={handlePrevPages}
                nextPage={handleNxtPages}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                manualPageSize={manualPageSize}
                dataLength={totalRecordCount}
                handleItemPerPage={handleItemPerPage}
                handlePaginationReset={handlePaginationReset}
                pageSizeNo={pageSizeNo}
                totalCount={totalCount}
                setPageIndex={setPageInd}

                //setSelection={setSelection}
              />
            )}
          </div>
        </>
      )}
      <Popup
        className="top-spacing-for-close glossary table-scroll size-sm fluid-screen-cover"
        open={popUp}
        onClose={() => setPopUp(false)}
        modal
        nested
      >
        {(close) => (
          <>
            <div
              id="ccp"
              className="popup-header d-flex align-items-center justify-content-between"
            >
              <h5 className="mt-2">
                {rowRecord.materialNumberId} : {rowRecord.material_description}{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setPopUp(false)}
              ></button>
            </div>

            <div className="content">
              <div className="scroll-content">
                <div className="smallgraphbg">
                  <div style={{ height: "200px", width: "100%" }}>
                    <BarChart
                      options={{
                        ...stackBarChartOptions,
                        indexAxis: "y",
                        responsive: true,
                        maintainAspectRatio: false,
                      }}
                      data={getOptions()}
                    ></BarChart>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    </>
  );
};

export default React.memo(TableGrid);
