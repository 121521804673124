import React from "react";

const Loadersml = (props) => {
  return (
    <div>
        <div className={`lds-ring-sml ${(props.mp0) ? 'p-0 m-0' : ''} ${(props.p0) ? 'p-0' : 'pl-2'} ${(props.m0) ? 'm-0' : 'ml-1'}`} title="Loading...">
          <div className={`${(props.mp0) ? 'p-0 m-0' : ''}`} ></div>
          <div className={`${(props.mp0) ? 'p-0 m-0' : ''}`} ></div>
          <div className={`${(props.mp0) ? 'p-0 m-0' : ''}`} ></div>
          <div className={`${(props.mp0) ? 'p-0 m-0' : ''}`} ></div>
        </div>
    </div>
  );
};
export default Loadersml;