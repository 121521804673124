import { RiskDetailsEditCell } from "../../Inflow/InflowRiskVisibilityTable/CellAction";
import GlossaryEditCell, { GlossaryNewCell } from "./CellActionGlossary";

export const COLUMNS = [
    {
        Header: "Column Name",
        accessor: "column_name",
        show: true,
        editable: false,
        // class: "items-center-imp"
        // Cell:GlossaryEditCell
    },
    {
        Header: "Description",
        accessor: "description",
        show: true,
        editable: true,
        Cell:GlossaryEditCell,
        // class: "items-center-imp"
    },
    {
        Header: "Delete",
        accessor: "selection",
        Cell: ({ row }) => (
            <input type="checkbox" {...row.getToggleRowSelectedProps()} />
            ),
        // class: "text-center items-center-imp"
        class: "text-center"
    },

];
export const COLUMNS_ADD= [
    {
        Header: "Column Name",
        accessor: "column_name",
        show: true,
        editable: true,
        Cell:GlossaryNewCell
    },
    {
        Header: "Description",
        accessor: "description",
        show: true,
        editable: true,
        Cell:GlossaryEditCell
    },
    

];