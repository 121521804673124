import React, { useState, useEffect, useRef, useContext } from "react";
import Popup from "reactjs-popup";
import Settings from "./Settings";
import UserAdd from "../common/UserAdd";
import PlusIcon from "../../../Assets/img/Plus.svg";
import Table from "../Table/TypeCreateTable";
import { ToastContainer, toast } from "react-toastify";
import {
  createGroupUser,
  updateGroupUser,
  updateMyGroupUsers,
} from "../../../Services/userManagementService";
import { notify } from "../../../Common/toast";
import { v4 } from "uuid";
import _, { forEach } from "lodash";
import { UserAccessContext, UserContext } from "../../Application";
import { useForm, useWatch, useFormState, SubmitHandler } from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import { regex_email, regex_letters_only } from "../../../utility/constants";
import CreatedUsersAction from "./CreatedUsersAction";
import GroupNotifications from "./GroupNotifications";
import { getNotificationSettings } from "../../../Services/userManagementService";
import Loader from "../../../Common/loader";

const CreateGroupOrUser = (props) => {
  const [userRole, setUserRole] = useState("");
  const [userGroupSelect, setUserGroupSelect] = useState(false);
  const [createType, setCreateType] = useState("User");
  const [createTypeLock, setCreateTypeLock] = useState(true);
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);
  const selectUserGroup = (val) => {
    if (val == "") {
      setUserRole("");
      setUserGroupSelect(true);
    } else {
      setUserRole(val);
      setUserGroupSelect(false);
    }
  };
  const user = useContext(UserContext);
  const groupName = useRef(null);
  const ref = useRef("");
  const childRef = useRef(null);
  const [dropDownValues, setDropDownValues] = useState({ tool: ['1'] });
  
  // dropDownValues.tool[0] = '1';
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    resetField,
    setValue,
    watch,
    reset,
    trigger,
    formState: {
      errors,
      isDirty,
      dirtyFields,
      isValid,
      isSubmitted,
      submitCount,
    },
  } = useForm({
    defaultValues: {
      groupNameInp: props.edit
        ? createType === "Group"
          ? props.name
          : ""
        : "",
      userNameInp: props.edit ? (createType === "User" ? props.name : "") : "",
      userEmailInp: props.edit
        ? createType === "User"
          ? props.email
          : ""
        : "",
      a_tabViewSettings: props.edit
        ? createType === "Group"
          ? dropDownValues.tabViewSetting
          : ""
        : "",
      a_access: props.edit
        ? createType === "Group"
          ? dropDownValues.access
          : ""
        : "",
      a_country: props.edit
        ? createType === "Group"
          ? dropDownValues.country
          : ""
        : "",
      a_mco: props.edit
        ? createType === "Group"
          ? dropDownValues.mco
          : ""
        : "",
      a_bg: props.edit
        ? createType === "Group"
          ? dropDownValues.businessGroup
          : ""
        : "",
      a_category: props.edit
        ? createType === "Group"
          ? dropDownValues.category
          : ""
        : "",
      a_brand: props.edit
        ? createType === "Group"
          ? dropDownValues.brand
          : ""
        : "",
    },
  });

  const groupNameTxt = watch("groupNameInp", false);
  const [ddlInfo, setDdlInfo] = useState([]);

  const [createGroup, setCreateGroup] = useState(false);
  const [selectedForDeletion, setSelectedForDeletion] = useState(false);
  const [initEditGroupName, setInitEditGroupName] = useState("");
  const [totalUsersAdded, setTotalUsersAdded] = useState([]);
  const [isUserAddFormInvalid, setUserAddFormInvalid] = useState(true);
  // const [isTypeCreateModalOpen, setTypeCreateModalOpen] = useState(true);
  const [isWarnModalOpen, setWarnModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tool, setTool] = useState([]);
  const [bg, setBg] = useState([]);
  const [mco, setMco] = useState([]);
  const [country, setCountry] = useState([]);
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);
  const [tabViewSettings, setTabViewSettings] = useState([]);
  const [myAccessValidation, setMyAccessValidation] = useState([]);
  const [groupNameHandler, setGroupNameHandler] = useState([]);
  const [deletedUser, setDeletedUser] = useState([]);
  const [ddlDisable, setDdlDisable] = useState(false)
  const userAccess = useContext(UserAccessContext);
  const [addUserInfo, setAddUserInfo] =useState();
  const [openGroupNotifications, setOpenGroupNotifications] = useState(false);
  const [groupNotificationsKey, setOpenGroupNotificationsKey] = useState(0);
  const [isSettingsDDLoading, setSettingsDDLoading] = useState(false);
  const [newGroupID, setNewGroupID] = useState('');
  const [userTableKey, updateUserTableKey] = useState(0);
  const [actionOnUser, setActionOnUser] = useState(null);
  const [userAddError, setUserAddError] = useState(false);
  const [formSubmitCount, setFormSubmitCount] = useState(0);
  const userDataRowDelector = document.querySelectorAll(".table-design-user-modal .body .tr");
  const userDataLength = userDataRowDelector.length;

  let userDataDb;

  // useEffect(()=>{
  //     console.log("FORM submitted",isSubmitted,submitCount, isValid)
  // },[isSubmitted]);
  

  const editModelOpen = () => {
    // setTypeCreateModalOpen(true);
    let dropDownValuesDb;
    if (createType === "Group") {
      if (props.edit) {
        userDataDb = props.data
          ?.map(
            ({ Id, UserEmail, UserFirstName, UserLastName, GroupName }) => ({
              UserEmail,
              Id,
              UserFirstName,
              UserLastName,
              GroupName,
            })
          )
          .filter((e) => e.GroupName === props.name)
          .map((i, id) => ({
            id: id + 1,
            user_name: i.UserFirstName,
            email: i.UserEmail,
          }));
        dropDownValuesDb = [
          ...new Set(
            props.data
              .map(({ GroupId, GroupName, Permission }) => ({
                GroupId,
                GroupName,
                Permission,
              }))
              .filter((e) => e.GroupId != null)
              .map(({ Permission }) => ({ Permission }))
              .map(JSON.stringify)
          ),
        ].map(JSON.parse);
        let dropDownData = JSON.parse(dropDownValuesDb[0].Permission);
      
        dropDownData["myaccess"].toolId = [dropDownData["myaccess"]?.toolId];
        let dummy = {
          tool: dropDownData["myaccess"].toolId,
          businessGroup: dropDownData["myaccess"].bussinessGroupId,
          mco: dropDownData["myaccess"].mcoId,
          country: dropDownData["myaccess"].countryId,
          access: dropDownData["myaccess"].accessId,
          tabViewSettings: dropDownData["myaccess"].tabViewSettingId,
          brand: dropDownData["myaccess"].brandId,
          category: dropDownData["myaccess"].categoryId,
        };
        setDropDownValues(dummy);
      }
    } else {
      let userDatass = props.data.filter((i) => i.UserEmail === props.email)[0];
      let dropDownData = JSON.parse(userDatass.Permission);
      dropDownData["myaccess"].toolId = [dropDownData["myaccess"]?.toolId];
      let dummy = {
        tool: dropDownData["myaccess"].toolId,
        businessGroup: dropDownData["myaccess"].bussinessGroupId,
        mco: dropDownData["myaccess"].mcoId,
        country: dropDownData["myaccess"].countryId,
        access: dropDownData["myaccess"].accessId,
        tabViewSettings: dropDownData["myaccess"].tabViewSettingId,
        brand: dropDownData["myaccess"].brandId,
        category: dropDownData["myaccess"].categoryId,
      };
      setDropDownValues(dummy);
    }
    setTotalUsersAdded(userDataDb);
  };
  useEffect(() => {
    let dummy = {
      tool: [],
      businessGroup: [],
      mco: [],
      country: [],
      access: ['2'],
      tabViewSettings: [],
      brand: [],
      category: [],
    };
    setDropDownValues(dummy);
    //console.log("hai")

   
  }, [])

  const checkValidFields = ()=>{
    let error =false;
    let formwatch = watch();
    console.log("formwatch",formwatch)
    console.log("errors",errors)
    handleSubmit(onError)
    setUserAddError(true);
    if(getValues("a_bg")==="" || getValues('a_bg')===undefined || getValues('a_bg')===null){
      // alert(getValues('a_bg'))
      setError("a_bg",{
        type:"manual",
        message:"Business Group Is Required"
      })
      error = true
    }
    if(getValues("a_brand")==="" || getValues('a_brand')===undefined || getValues('a_brand')===null){
      setError("a_brand",{
        type:"manual",
        message:"Brand Is Required"
      })
      error = true
    }
    if(getValues("a_category")==="" || getValues('a_category')===undefined || getValues('a_category')===null){
      setError("a_category",{
        type:"manual",
        message:"Category Is Required"
      })
      error = true
    }
    if(getValues("a_country")==="" || getValues('a_country')===undefined || getValues('a_country')===null){
      setError("a_country",{
        type:"manual",
        message:"Country Is Required"
      })
      error = true
    }
    if(getValues("a_tabViewSettings")==="" || getValues('a_tabViewSettings')===undefined || getValues('a_tabViewSettings')===null){
      setError("a_tabViewSettings",{
        type:"manual",
        message:"Tab View Settings field Is Required"
      })
      error = true
    }
    if((getValues("a_mco")===""  || getValues('a_mco')===undefined || getValues('a_mco')===null) && dropDownValues.tool[0]==="1"){
      setError("a_mco",{
        type:"manual",
        message:"MCO Is Required"
      })
      error = true
    }

    return error
    
  }

  useEffect(() => {
    setInitEditGroupName(props.name);
  }, [props.name]);

  const checkFilled = (val) => {
    if (createTypeLock) {
    } else {
      if (val == "") {
        setCreateGroup(false);
      } else {
        setCreateGroup(true);
      }
    }
  };

  const closeDropDownFunc = () => {
    //setTimeout(() => {
      setCloseDropDown({
        key : Math.random(1000)
      });
    //}, 400);
  }


  

  useEffect(() => {
    let gp_name = groupNameTxt.toString();
    checkFilled(gp_name);
  }, [groupNameTxt]);

  const createTypeCheck = () => {
    if (createType == "Group") {
      setCreateGroup(true);
      setGroupNameHandler(
        register("groupNameInp", {
          required: {
            value: true,
            message: "Group Name is required.",
          },
          maxLength: {
            value: 50,
            message: "Group Name is too long.",
          },
        })
      );
    }
  };

  const clearAllSettingsError = () => {
      setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true });
      setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true });
      setValue("a_country", "sl", {shouldValidate: true,shouldDirty: true});
      setValue("a_access", "sl", {shouldValidate: true,shouldDirty: true});
      setValue("a_brand", "sl", {shouldValidate: true,shouldDirty: true});
      setValue("a_category", "sl", {shouldValidate: true,shouldDirty: true});
      setValue("a_tabViewSettings", "sl", {shouldValidate: true,shouldDirty: true});
  }

  const scroll_to_bottom = () => {
    if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 9933333;
  }


  const processCreate = () => {
    
    if (createGroup) {
      var gp = getValues("groupNameInp");
      if(gp === '') {
       
          setError("groupNameInp",{
            type:"manual",
            message:"Group Name is Required"
          });
          document.querySelector(".modal-content-raised").scrollTop = 0;
         // error = true
        
        toast.error(<div className="tosterIcon">Please check the errors, in the above form.</div>, {
          containerId: "ToastifyModalContainer",
        });
        return;
      }
    }

    if (!createGroup) {
      var singleUserName = getValues(["userNameInp"]);
      var singleUserEmail = getValues(["userEmailInp"]);
    }

    setIsLoading(true);
    const tabPer = [];
// console.log(ddlInfo)
    dropDownValues.tabViewSettings
      ?.filter((i) => i !== "all")
      ?.forEach((val) => {
        let obj = {
          screenName: ddlInfo["tabViewSetting"]?.filter((i) => i.key === val)[0]
            ?.value,
          tool: ddlInfo["tool"].filter(
            (i) => i.key === dropDownValues.tool?.[0]
          )[0]?.value,
          isRead: dropDownValues.access?.includes("1"),
          isWrite: dropDownValues.access?.includes("2"),
          isAllAccess: dropDownValues.access?.length > 1,
        };
        tabPer.push(obj);
      });
    let userDataToSave = {
      RoleId: userRole === "SuperAdmin" ? 1 : userRole === "Admin" ? 2 : 3,
      GroupId: props.edit
        ? createType === "Group"
          ? props.keys
          : ""
        : gp
          ? newGroupID
          : "",
      SuperVisorRoleId: user.superVisorRoleId ?? 1,
      // SupervisorName: user.username,
      SupervisorEmail: user.supervisorEmail,
      UserRole: userRole,
      SuperVisorRole: user.superVisorRole,
      SupervisorFirstName: user.supervisorFirstName,
      SupervisorLastName: user.supervisorLastName,
      GroupName: createGroup ? gp : "",
      CreatedBy: user.email,
      param: createGroup ? "" : "User",
      Users: true
        ? userData([], true)
        : [
          {
            UserName: singleUserName[0] ?? singleUserName,
            UserEmail: singleUserEmail[0] ?? singleUserEmail,
            UserLastName: "",
            UserFirstName: singleUserName[0] ?? singleUserName,
            accessId: dropDownValues.access ?? ["1"],
            PermissionsType:parseInt(dropDownValues.tool[0]),
            Permissions: JSON.stringify({myaccess:{
              toolId: dropDownValues.tool?.length > 0 ? dropDownValues?.tool[0] : [1],
              bussinessGroupId: dropDownValues?.businessGroup ?? [],
              mcoId: dropDownValues?.mco ?? [],
              countryId: dropDownValues?.country ?? [],
              accessId: dropDownValues?.access[0] ?? 1,
              tabViewSettingId: dropDownValues?.tabViewSettings ?? [],
              categoryId: dropDownValues?.category ?? [],
              brandId: dropDownValues?.brand ?? [],
              ddlInfo:JSON.stringify(ddlInfo)
            }}),
            UserRole: userRole,
            UserRoleId:userRole === "SuperAdmin" ? 1 : userRole === "Admin" ? 2 : 3,
          },
        ],
      InflowPermission: null,
      PomPermission: null,
      Permission: {
        tools: {
          isFgSlob:
            dropDownValues.tool?.includes("1") ||
              (!dropDownValues.tool?.includes("2") &&
                !dropDownValues.tool?.includes("3"))
              ? true
              : false,
          isInflow: dropDownValues.tool?.includes("2") ? true : false,
          isPom: dropDownValues.tool?.includes("3") ? true : false,
          isAllPageSetting: false,
        },
        filterSettings: {
          businessGroup: dropDownValues.businessGroup,
          businessGroupAllStatus:
            dropDownValues.businessGroup?.length ===
            ddlInfo["businessGroup"]?.length,
          Country: dropDownValues.country,
          countryAllStatus:
            dropDownValues.country?.length === ddlInfo["country"]?.length,
          mco: dropDownValues.mco,
          mcoStatus: dropDownValues.mco?.length === ddlInfo["mco"]?.length,
          brand: dropDownValues.brand,
          brandStatus:
            dropDownValues.brand?.length === ddlInfo["brand"]?.length,
          category: dropDownValues.category,
          categoryStatus:
            dropDownValues.category?.length === ddlInfo["category"]?.length,
        },
        tabSettings: tabPer,
        myaccess: {
          toolId:
            dropDownValues.tool?.length > 0 ? dropDownValues.tool[0] : [1],
          bussinessGroupId: dropDownValues.businessGroup ?? [],
          mcoId: dropDownValues.mco ?? [],
          countryId: dropDownValues.country ?? [],
          accessId: dropDownValues.access ?? [],
          tabViewSettingId: dropDownValues.tabViewSettings ?? [],
          categoryId: dropDownValues.category ?? [],
          brandId: dropDownValues.brand ?? [],
        },
      },
    };
    if (props.edit) {
      if (createType === "Group") {
        let maineditedDate = {
          GroupId: userDataToSave.GroupId,
          updateData: [],
        };
        let dummy = userData([], true).map((i) => ({
          UserRole: userDataToSave.UserRole,
          SuperVisorRoleId: userDataToSave.SuperVisorRoleId,
          SupervisorFirstName: userDataToSave.SupervisorFirstName,
          SupervisorLastName: userDataToSave.SupervisorLastName,
          SupervisorEmail: userDataToSave.SupervisorEmail,
          GroupName: userDataToSave.GroupName,
          GroupId: userDataToSave.GroupId,
          UserRoleId: userDataToSave.RoleId,
          CreatedBy: userDataToSave.CreatedBy,
          Permission: userDataToSave.Permission,
          UserEmail: i.UserEmail,
          UserFirstName: i.UserFirstName,
          UserLastName: i.UserLastName,
        }));
        maineditedDate.updateData = dummy;
        let deletedfinal = [];
        if (deletedUser.length > 0) {
          const uniqueValues = new Set(dummy.map((obj) => obj.UserEmail));
          const uniqueValues1 = new Set(
            props.data?.map((obj) => obj.UserEmail)
          );
          deletedUser
            ?.filter((i) => !uniqueValues.has(i.email))
            .filter((i) => uniqueValues1.has(i.email))
            .map((i) => {
              let deletedfinals = [
                {
                  ...dummy[0],
                  UserEmail: i.email,
                  UserFirstName: i.user_name,
                  GroupId: null,
                  GroupName: null,
                },
              ];
              return (deletedfinal = [...deletedfinal, ...deletedfinals]);
            });
          setDeletedUser([]);
        }
        maineditedDate.updateData = [
          ...maineditedDate.updateData,
          ...deletedfinal,
        ];
        let dummypermissions = maineditedDate.updateData[0].Permission
        // console.log(dummypermissions)
        if (dummypermissions?.myaccess?.toolId === "2") {
          maineditedDate.updateData[0].InflowPermission = dummypermissions
          maineditedDate.updateData[0].PomPermission = null
          maineditedDate.updateData[0].Permission = null
        }
        if (dummypermissions?.myaccess.toolId === "3") {
          maineditedDate.updateData[0].InflowPermission = null
          maineditedDate.updateData[0].PomPermission = dummypermissions
          maineditedDate.updateData[0].Permission = null
        }
        setSaveLoaderInit(true)
        scroll_to_bottom();
        updateMyGroupUsers(maineditedDate).then((response) => {
          setIsLoading(false);
          setCreateGroup(false);
          ref.current?.close();
          if (response) {
            setSaveLoaderInit(false)
            notify({ type: "success", message: "Updated  successfully" });
            clearErrors();
            reset();
            // props.onHide();
          window.location.reload();
          }
        }).catch(()=>setSaveLoaderInit(false))
      } else {
        let dummy = userDataToSave.Users.map((i) => ({
          Permission: JSON.stringify(userDataToSave.Permission),
          Users: userDataToSave.Users,
          // UserEmail:i.UserEmail,
          // UserFirstName:i.UserFirstName,
          // UserLastName:i.UserLastName,
          UpdateBy: user.email,
        }));   
        setSaveLoaderInit(true)
        scroll_to_bottom();
        updateGroupUser(dummy[0]).then((response) => {
          if (response) {
            setSaveLoaderInit(false)
            notify({ type: "success", message: "Updated  successfully" });
            clearErrors();
            reset();
            // props.onHide();
          }
          setIsLoading(false);
          setCreateGroup(false);
          ref.current?.close();
          window.location.reload();
        }).catch(() => setSaveLoaderInit(false));
      }
    } else {
      let dummypermissions = userDataToSave.Permission
      if (dummypermissions.myaccess.toolId === "2") {
        userDataToSave.InflowPermission = dummypermissions;
        userDataToSave.Permission = null
        userDataToSave.PomPermission = null
      }
      if (dummypermissions.myaccess.toolId === "3") {
        userDataToSave.PomPermission = dummypermissions;
        userDataToSave.Permission = null
        userDataToSave.InflowPermission = null
      }
      setSaveLoaderInit(true)
      scroll_to_bottom();
     console.log("Userdatatosave",userDataToSave)
      createGroupUser(userDataToSave)
        .then((response) => {
          setIsLoading(false);
          setCreateGroup(false);
          ref.current?.close();
          if (response) {
            setSaveLoaderInit(false);
            notify({ type: "success", message: "Submitted successfully" });
            clearErrors();
            reset();
            props.onHide(false);
            window.location.reload();
          }
          window.location.reload();
        })
        .catch(() => {
          setSaveLoaderInit(false);
          setIsLoading(false);
        });
    }
  };

  const mapidstoValues = (ids, values) => {
    const mappedValues = ids.map(toolValue => {
      const mappedvalue = values.find(group => group.key === toolValue.toString());
      return mappedvalue ? mappedvalue.value : null;
    })
// console.log("mappedvallues", mappedValues)
    return mappedValues
  }
const [newUserInfo,setNewUserInfo] =useState([])
  useEffect(()=>{
// console.log("addUserInfo",addUserInfo)
    setNewUserInfo((prev)=>[...prev,addUserInfo])
  },[addUserInfo])
  useEffect(()=>{
// console.log("newUserInfo",newUserInfo)
    // setTotalUsersAdded(newUserInfo)
    // setNewUserInfo((prev)=>[...prev,addUserInfo])
  },[newUserInfo])

  const getUniqueData =(data)=>{
// console.log("veryNewData","saasa",data,addUserInfo)
    // console.log("veryNewData","saasasssa",data,data.filter(i=>i.email))

    const newdata = data.filter(i=>{
//  console.log("testdata",i,data)
      if(i.email!== data[data.length-1].email){
        return true
      }
      else if(i.email === data[data.length-1].email){
        if(i.tool[0] === data[data.length-1].tool[0]){
          if(i.id === data[data.length-1].id){
            return true
          }
          else{
            return false
          }
        }else{
          return true
        }
      }
    })
// const newdata = data.filter(i=>i.email!==addUserInfo.email && i.tool!==addUserInfo.tool && i.id < addUserInfo.id)
   console.log("veryNewData",newdata)
    setTotalUsersAdded(newdata)
// return (newdata)
  }

  const onNewUser = (users) => {
// console.log("totalusers", users, ddlInfo)
    getUniqueData(users)
// let alluser = users.map((user)=>{
    //   return{
    //     tool:mapidstoValues(user.dropdownvalues["tool"],ddlInfo["tool"]),
    //     businessGroup:mapidstoValues(user.dropdownvalues["businessGroup"],ddlInfo["businessGroup"]),
    //     brand:mapidstoValues(user.dropdownvalues["brand"],ddlInfo["brand"]),
    //     category:mapidstoValues(user.dropdownvalues["category"],ddlInfo["category"]),
    //     country:mapidstoValues(user.dropdownvalues["country"],ddlInfo["country"]),
    //     mco:mapidstoValues(user.dropdownvalues["mco"],ddlInfo["mco"]),
    //     tabViewSetting:mapidstoValues(user.dropdownvalues["tabViewSettings"],ddlInfo["tabViewSetting"]),
    //     user_role:user.user_role,
    //     user_name:user.user_name,
    //     user_access:user.user_access,
    //     email:user.email,
    //     id:user.id
    //   }
    // })
  //  console.log("allusers",getUniqueData(users))
    // setTotalUsersAdded(getUniqueData(users));

    // if(newUserInfo.length) {
    //     setTimeout(() => {
    //     setActionOnUser('add');
    //     toast.error(<div className="tosterIcon">User Added.</div>, {
    //       containerId: "A",
    //     });
    //   }, 400);
    // }

  };
  useState(()=>{
console.log("hellooo",totalUsersAdded)
  },[totalUsersAdded])

  const userData = (data, isSave) => {
 console.log("qazwsxedc", totalUsersAdded)
    let dummy = totalUsersAdded
    return isSave
      ? totalUsersAdded?.map((val) => {
        return {
          ...{
            UserName: val.user_name[0] ?? val.user_name,
            UserEmail: val.email,
            UserFirstName: val.user_name ?? val.user_name,
            UserLastName: "",
            PermissionsType:parseInt(val.tool[0]),
            Permissions: JSON.stringify({myaccess:{
              toolId: val.tool?.length > 0 ? val.tool[0] : [1],
              bussinessGroupId: val.businessGroup ?? [],
              mcoId: val.mco ?? [],
              countryId: val.country ?? [],
              accessId: val.user_access ?? [],
              tabViewSettingId: val.tabViewSetting ?? [],
              categoryId: val.category ?? [],
              brandId: val.brand ?? [],
              ddlInfo:val.ddlInfo
            }}),
            UserRole: val.user_role,
            UserRoleId:val.user_role === "SuperAdmin" ? 1 : val.user_role === "Admin" ? 2 : 3,
            accessId: val.user_access ? val.user_access : "2"
          },
        };
      })
      : totalUsersAdded
  };

  // tool:user.tool.map(i=>i.value),
  // businessGroup:user.businessGroup.map(i=>i.value),
  // brand:user.brand.map(i=>i.value),
  // category:user.category.map(i=>i.value),
  // country:user.country.map(i=>i.value),
  // mco:user.mco.map(i=>i.value),
  // tabViewSetting:user.tabViewSetting.map(i=>i.value),
  // user_role:user.user_role,
  // user_name:user.user_name,
  // user_access:user.user_access,
  // email:user.email,
  // id:user.id 


  
  // useEffect(()=>{
  //   document.getElementById("settingsBlock").scrollIntoView(false)
  // },[!isValid]);


  // useEffect(() => {
  //   //if(!userData.length && props.submitCount > 0) {
  //     toast.error(<div className="tosterIcon">Please add atleast one user, to the group.</div>, {
  //       containerId: "A",
  //     });
  //     console.log(submitCount, isSubmitted);
  //   //}
  // },[submitCount > 0 && !document.querySelectorAll(".table-design-user-modal .body .tr").length]);
  
  
  useEffect(() => {

    if(actionOnUser === "add") {
        console.log("User Added",actionOnUser)
        if(document.querySelector(".table-design-user-modal") !== null) {
          document.querySelector(".table-design-user-modal").scrollLeft = 0;
          document.querySelector(".table-design-user-modal").scrollTop = 9935666;
          //document.querySelector(".table-design-user-modal").scrollIntoView({behavior: 'smooth'})
        }
        if(document.querySelector(".modal-content-raised") !== null ) {
          document.querySelector(".modal-content-raised").scrollTop = 9933333;
          //document.querySelector(".modal-content-raised").scrollIntoView({behavior: 'smooth'})
        }

        let selectedUserType = getValues("a_user_type");
  
        let allRows = document.querySelectorAll(".table-design-user-modal .body .tr");
        if(allRows.length) {
          if(selectedUserType === "SuperAdmin") {
           // if(allRows.length > 1){
            let lastRow1 = allRows[allRows.length - 1];
            lastRow1.classList.add('highlight_once');
          //}
            //if(allRows.length > 2){
            let lastRow2 = allRows[allRows.length - 2];
            lastRow2.classList.add('highlight_once');
          //}
            //if(allRows.length > 3){
            let lastRow3 = allRows[allRows.length - 3];
            lastRow3.classList.add('highlight_once');
          //}
          } else {
            let lastRow = allRows[allRows.length - 1];
            lastRow.classList.add('highlight_once');
          }
        }
  
    } else if(actionOnUser === "delete") {
        console.log("User Deleted",actionOnUser)
        if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 9933333;
  
      } else if(actionOnUser === null) {
      console.log("User Interaction Nothing",actionOnUser)
        if(document.querySelector(".table-design-user-modal") !== null) {
          document.querySelector(".table-design-user-modal").scrollLeft = 0;
          document.querySelector(".table-design-user-modal").scrollTop = 0;
        }
        if(document.querySelector(".modal-content-raised") !== null ) document.querySelector(".modal-content-raised").scrollTop = 0;
    }
  
  },[document.querySelectorAll(".table-design-user-modal .body .tr").length]);


  const handleUserAddFormStatus = (q) => {
    setUserAddFormInvalid(q);
  };

  const deleteUser = () => {
    setSelectedForDeletion(true);
  };

  const emptyUserDataError = () => {
    if(!totalUsersAdded.length) {
      toast.error(<div className="tosterIcon">Please add atleast one user{createGroup ? ', in the group' : ''}.</div>, {
        containerId: "ToastifyModalContainer",
      });
      //console.log(submitCount, isSubmitted);
    }
  }

  const onError = (errors, e) => {
    console.log("Form Error", userDataLength, errors, e);
    emptyUserDataError();
  }

  const onSubmit = (data) => {
    console.log("FORM submitted",isSubmitted,submitCount, isValid,data)
// console.log("hai")

  //  setFormSubmitCount(Math.random * 3444);
    if (isValid || userRole == "SuperAdmin") {

      if (totalUsersAdded.length > 0) {
        processCreate()
      } else {
        emptyUserDataError();
      }

      // if (createGroup) {
      //   if (totalUsersAdded.length > 0) {
      //     processCreate()
      //   } else {
      //     emptyUserDataError();
      //   }
      // } else {
      //   processCreate();
      // }
    }
  };

  const get_form_val = () => {
    return getValues(["groupNameInp"]);
  };
  useEffect(() => {
    if (createType == "Group") {
      if (groupNameHandler?.length === 0) {
        setGroupNameHandler(
          register("groupNameInp", {
            required: {
              value: true,
              message: "Group Name is required.",
            },
            maxLength: {
              value: 50,
              message: "Group Name is too long.",
            },
          })
        );
      }   
    }
  }, [createType]);
  useEffect(() => {
    let obj = {
      data: {
        tl: dropDownValues.tool,
        businessGroup: dropDownValues.businessGroup,
        mco: dropDownValues.mco,
        country: dropDownValues.country,
        access: dropDownValues.access,
        brand :dropDownValues.brand,
        category: dropDownValues.category,
        tabViewSettings: dropDownValues.tabViewSettings,
      },
    };
    handleMultiSelectChange(obj);
    console.log("obj",obj)
  }, [dropDownValues]);

  

  const handleMultiSelectChange = (obj) => {
    console.log("obj","as",obj)
    let { businessGroup, mco, country, access, tabViewSettings, category, brand, toolId } = obj.data;
    console.log("tool---",obj.data);

    if(obj.data.hasOwnProperty('tl')) {
      clearAllSettingsError()
    }
    // if ((obj.current == "businessGroup" || props.edit) && userRole != "SuperAdmin") {
      if (businessGroup != undefined) {
        console.log(businessGroup)
        if (!businessGroup.length>0) {
          setValue("a_bg", "",{ shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_bg", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      setBg(businessGroup);
    // }
    // if ((obj.current == "mco" || props.edit) && userRole != "SuperAdmin") {
      if (dropDownValues.tool[0] === '1' && mco != undefined) {
        if (!mco.length) {
          setValue("a_mco", "", { shouldValidate: true, shouldDirty: true });
        } else {
          setValue("a_mco", "sl", { shouldValidate: true, shouldDirty: true });
        }
      }
      setMco(mco);
    // }
    // if ((obj.current == "country" || props.edit) && userRole != "SuperAdmin") {
      if (country != undefined) {
        if (!country.length) {
          setValue("a_country", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue("a_country", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }
      setCountry(country);
    // }
    if (((obj.current == "access" || props.edit) && createType === "Group") && userRole != "SuperAdmin") {
      if (access != undefined) {
        if (!access.length) {
          setValue("a_access", "", { shouldValidate: false, shouldDirty: true });
        } else {
          if (access[0] != "") {
            setValue("a_access", "sl", {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue("a_access", "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
      setMyAccessValidation(access);
    }
    // if (((obj.current == "brand" || props.edit) && tool[0] != 3) && userRole != "SuperAdmin") {
      if (brand != undefined) {
        if (!brand.length>0) {
          setValue("a_brand", "", { shouldValidate: true, shouldDirty: true });
        } else {
          if (brand[0] != "") {
            setValue("a_brand", "sl", {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue("a_brand", "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
      setBrand(brand);
    // }
    // if ((obj.current == "category" || props.edit) && userRole != "SuperAdmin") {
      if (category != undefined) {
        if (!category.length>0) {
          setValue("a_category", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          if (category[0] != "") {
            setValue("a_category", "sl", {
              shouldValidate: true,
              shouldDirty: true,
            });
          } else {
            setValue("a_category", "", {
              shouldValidate: true,
              shouldDirty: true,
            });
          }
        }
      }
      setCategory(category);
    // }
    // if ((obj.current == "tabViewSettings" || props.edit) && userRole != "SuperAdmin") {
      if (tabViewSettings != undefined) {
        if (!tabViewSettings.length) {
          setValue("a_tabViewSettings", "", {
            shouldValidate: true,
            shouldDirty: true,
          });
        } else {
          setValue("a_tabViewSettings", "sl", {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }

      // if(obj.data.hasOwnProperty('tl')) {
      //   clearAllSettingsError()
      // }
      setTabViewSettings(tabViewSettings);
    // }
  };

  const handleModalClose = () => {
    setWarnModalOpen(false);
  }
  const handleConfirmModalClose = () => {
    setWarnModalOpen(false);
    props.onGroupUserModalClose();
    setTotalUsersAdded([]);
    setFormSubmitCount(0);
  }

  const handleGroupNotificationModalClose = () => {
    setOpenGroupNotifications(false);
  }

  useEffect(()=>{
    setNewGroupID(v4())
  },[props.open])


  const handleSettingsDDLoading = (o) => {
    setSettingsDDLoading(o);
  }
  const HandletoolReset = (o) => {
//   resetField("a_bg", { keepError: false });
  //   resetField("a_mco", { keepError: false });
  //   resetField("a_country", { keepError: false });
  //   resetField("a_category", { keepError: false });
  //   resetField("a_brand", { keepError: false });
  //   resetField("a_tabViewSettings", { keepError: false });
  //  clearErrors(["a_bg","a_mco","a_country","a_category","a_brand","a_tabViewSettings"])
  }

  const deleteSelected = () => {
    // updateUserTableKey(Math.random(333));
    setActionOnUser('delete');
    childRef.current.onActionClick();
  }

  const handleUserAddedOnClick = (userone) => {
    console.log("handleUserAddedOnClick",userone,user);
    setActionOnUser('add');

    let selectedUserType = getValues("a_user_type");
    if(((selectedUserType === "" && user?.userRole==="SuperAdmin") || selectedUserType === "SuperAdmin") && createType!=="Group") {
      toast.success(<div className="tosterIcon">SuperAdmin is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    } else if(((selectedUserType === "" && user?.userRole==="Admin") || selectedUserType === "Admin" ) || createType==="Group") {
      toast.success(<div className="tosterIcon">Admin is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    } else {
      toast.success(<div className="tosterIcon">User is added</div>, {
        containerId: "ToastifyModalContainer",
      });
    }
  }

  const handleSetFormSubmitCounttoZero = () => {
    setFormSubmitCount(0);
  }

  return (
    <>
      <Popup
        className="window-modal create-edit-user"
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        open={props.open}
        lockScroll={false}
        modal
        onClose={() => {
          clearErrors();
          reset();
          setActionOnUser(null);
          setFormSubmitCount(0);
        }}
        onOpen={() => {
          setFormSubmitCount(0);
          setActionOnUser(null);
          createTypeCheck();
          
          document.querySelector(".modal-content-raised").scrollTop = 0;
          setTimeout(() => {
            // document.querySelector(".table-design-user-modal").scrollLeft = 0;
            // document.querySelector(".modal-content-raised").scrollTop = 0;
            // clearErrors();
            // reset();
            setDropDownValues({ tool: ['1'] })
          }, 1200);
        }}
      >
        {(close) => (
          <>
            <ToastContainer
              enableMultiContainer
              containerId={"ToastifyModalContainer"}
              position={toast.POSITION.TOP_CENTER}
              autoClose={2000}
            />
            

            <div className="popup-header  modal-header-raised d-flex align-items-center justify-content-between"
              // onMouseDown={closeDropDownFunc}
            >
              <h5 className="mt-2">
                {" "}
                {props.edit
                  ? `Edit ${props.name}`
                  : `Create a ${createGroup ? "Group" : "User"}`}{" "}
              </h5>
              <div>
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={() => totalUsersAdded.length ? setWarnModalOpen(true) :  props.onGroupUserModalClose() }
                  ></button>
              </div>
            </div>

            <div tabIndex="0" className="content  full-screen-modal-content modal-content-raised 1" 
            // onMouseDown={closeDropDownFunc}
            >
              
              <div style={{ position: 'relative', zIndex:989}}>
                  <div className="col-md-12">
                    <div className="filterform_bg d-flex align-items-center justify-content-between row">
                      <div className="col-md-5">
                        <div>
                          <div className="ddn width-100 max-width-100">
                            <div>
                              <label>Type of Creation</label>
                            </div>
                            <select
                              className="width-100 btn btn-dropdown btn-sm text-left btn btn-primary"
                              defaultValue={"User"}
                              // onMouseDown={closeDropDownFunc}
                              onChange={(e) => {
                                setCreateType(e.target.value ?? "");
                                if (e.target.value == "User") {
                                  setCreateType("User");
                                  setCreateTypeLock(true);
                                  setCreateGroup(false);
                                } else {
                                  setCreateType("Group");
                                  setCreateTypeLock(false);
                                  setCreateGroup(true);
                                }
                              }}
                            >
                              {/* <option value="">Select</option> */}
                              <option value="User" >User</option>
                              <option value="Group">Group</option>

                            </select>
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="filterform_bg d-flex align-items-center justify-content-between pb-0 row">
                      <div className="col-md-5">
                        <h6 className="title mb-0">User Selection</h6>
                        <div className="">
                          { createType !== "Group" &&<div className="mb-4 ddn width-100 max-width-100">
                            <div >
                              <label>Type of user</label>
                            </div>
                            <select
                              className="width-100 btn mb-1 btn-dropdown btn-sm text-left btn btn-primary"
                              // onMouseDown={closeDropDownFunc}
                              {...register("a_user_type", {
                                required: {
                                  value: false
                                },
                              })}

                              onChange={(e) => {
                                selectUserGroup(e.target.value ?? "");
                                if (e.target.value == "SuperAdmin") {
                                  setDdlDisable(true);

                                } else {
                                  setDdlDisable(false);
                                }
                              }}
                            >
                              <option value="">Select one</option>
                              {(
                                user.userRole == "SuperAdmin") && (
                                  <option value="SuperAdmin">Super Admin</option>
                                )}
                              {(user.userRole == "Admin" ||
                                user.userRole == "SuperAdmin") && (
                                  <option value="Admin">Admin</option>
                                )}

                              <option value="User">User</option>
                            </select>
                          </div>}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>

                  <div className="scroll-content" 
                  // onMouseDown={closeDropDownFunc}
                  style={{ marginRight : "15px"}}>
                    <form>
                      
                      <>
                          <div>
                            {createType == "Group" && !createTypeLock && (
                              <div className="row row-group-name mb-2">
                                <div className="col-md-5">
                                  <div className="filterform_bg pt-0">
                                    <div className="ddn width-auto">
                                      <div>
                                        <label>Group Name</label>
                                      </div>
                                      <input
                                        type="text"
                                        // onMouseDown={closeDropDownFunc}
                                        className="text-left w-100"
                                        {...groupNameHandler}
                                      />
                                    </div>
                                    <div className="error-mssg">
                                      {errors.groupNameInp && (
                                        <p key={errors.groupNameInp.message}>
                                          {errors.groupNameInp.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="filterform_bg pt-0">
                                    <div className="ddn width-auto">
                                      <div>
                                        <label></label>
                                      </div>
                                      {!createGroup && (
                                        <p className="note" style={{ display: "inline"}}>
                                          (If you specify 'Group name', then you would
                                          be creating group)
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div className="row mb-3 row-user-settings"  style={{ position : 'relative' , zIndex : '335'}} >
                              <div className="col-md-12"  id="settingsBlock">
                                <h6 className="title">
                                  {createGroup ? "Group" : "User"} Settings
                                </h6>
                                <Settings
                                
                                  setDropDownValues={(values) => {
                                    setDropDownValues({ ...values });
                                  }}
                                  handleMultiSelectChange={handleMultiSelectChange}
                                  setDdlInfo={setDdlInfo}
                                  errorMssg={errors}
                                  myAccessInfo={dropDownValues}
                                  ddlDisable={ddlDisable}
                                  createType={createType}
                                  // onCloseDropDown={closeDropDown}
                                  submitCount={submitCount}
                                  userAddError={userAddError}
                                  handleSettingsDDLoading={handleSettingsDDLoading}
                                  dropDownInModal={true}
                                  // HandletoolReset={HandletoolReset}
                                />
                              
                                {userRole !== "SuperAdmin" && <> <input
                                  type="text"
                                  className="dom-hidden"
                                // {
                                //   ...register("a_tool", {
                                //     required: {
                                //       value: true,
                                //       message: "Tool is required."
                                //     },
                                //   })
                                // }
                                />
                                  <input
                                    type="text"
                                    className="dom-hidden"
                                    {...register("a_bg", {
                                      required: {
                                        value: true,
                                        message: "Business Group is required.",
                                      },
                                    })}
                                  />
                                  {/* {console.log("dropDownValues",dropDownValues)} */}
                                  {!dropDownValues.tool.length && 
                                      <input
                                        type="text"
                                        className="dom-hidden"
                                        {...register("a_mco", {
                                          required: {
                                            value: userAccess?.fgSLOBdata?.data?.toolId === "1" ? true : false,
                                            message: "MCO is required.",
                                          },
                                        })}
                                      />
                                  }
                                  <input
                                    type="text"
                                    className="dom-hidden"
                                    {...register("a_country", {
                                      required: {
                                        value: true,
                                        message: "Country is required.",
                                      },
                                    })}
                                  />
                                  <input
                                    type="text"
                                    className="dom-hidden"
                                    {...register("a_category", {
                                      required: {
                                        value: true,
                                        message: "Category is required.",
                                      },
                                    })}
                                  />
                                  <input
                                  type="text"
                                  className="dom-hidden"
                                  {...register("a_brand", {
                                    required: {
                                      value:  true,
                                      message: "Brand is required.",
                                    },
                                  })}
                                />
                                  {/* <input
                                  type="text"
                                  className="dom-hidden"
                                  {...register("a_access", {
                                    required: {
                                      value: true,
                                      message: "Access is required.",
                                    },
                                  })}
                                /> */}
                                  <input
                                    type="text"
                                    className="dom-hidden"
                                    {...register("a_tabViewSettings", {
                                      required: {
                                        value: true,
                                        message: "Tab View Settings is required.",
                                      },
                                    })}
                                  />
                                </>}
                              </div>
                            </div>

                            {false && (
                              <div className="row mb-1 row-user-settings"   style={{ position : 'relative' , zIndex : '331'}} >
                                <div className="col-md-12">
                                  <div className="filterform_bg ddn-fluid w-100">
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <div className="ddn">
                                          <div>
                                            <label>User Name</label>
                                          </div>
                                          <input
                                            className="bg-black"
                                            type="text"
                                            // onMouseDown={closeDropDownFunc}
                                            {...register("userNameInp", {
                                              required: {
                                                value: true,
                                                message: "User Name is required.",
                                              },
                                              pattern: {
                                                value: regex_letters_only,
                                                message: "User Name is Invalid.",
                                              },
                                            })}
                                          />
                                        </div>
                                        <div className="error-mssg">
                                          {errors.userNameInp && (
                                            <p key={errors.userNameInp.message}>
                                              {errors.userNameInp.message}
                                            </p>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <div className="ddn">
                                          <div>
                                            <label>Email ID</label>
                                          </div>
                                          <input
                                            className="bg-black"
                                            type="email"
                                            // onMouseDown={closeDropDownFunc}
                                            {...register("userEmailInp", {
                                              required: {
                                                value: true,
                                                message: "Email is required.",
                                              },
                                              pattern: {
                                                value: regex_email,
                                                message: "Email is Invalid.",
                                              },
                                            })}
                                          />
                                        </div>
                                        <div className="error-mssg">
                                          {errors.userEmailInp && (
                                            <p key={errors.userEmailInp.message}>
                                              {errors.userEmailInp.message}
                                            </p>
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )}
          
                            { (
                              <div className={`row mb-5 row-user-settings`}  style={{ position : 'relative' , zIndex : '332'}} >
                                <div className="col-md-12">
                                <div className="datatable position-relative">
                                        
                                    <Table
                                      checkValidFields={checkValidFields}
                                      newUserInfo={newUserInfo}
                                      setAddUserInfo={setAddUserInfo}
                                      ddlInfo={ddlInfo}
                                      dropdownvalues={dropDownValues}
                                      ref={childRef}
                                      onNewUser={onNewUser}
                                      data={userData}
                                      setDeletedUser={setDeletedUser}
                                      selectedForDeletion={selectedForDeletion}
                                      // key={Math.random(1 * 3422)}
                                      createType={createGroup}
                                      createfile = {"create"}
                                      setTotalUsersAdded={setTotalUsersAdded}
                                      submitCount={submitCount}
                                      isSubmitted={isSubmitted}
                                      isValid={isValid}
                                      userRole={userRole}
                                      actionOnUser={actionOnUser}
                                      onUserAddedOnClick={handleUserAddedOnClick}
                                      key={userTableKey}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="d-flex justify-content-between align-items-center mt-5">
                            <div>
                              { (
                                <button
                                  type="button"
                                  onClick={deleteSelected}
                                  className="btn btn-primary btn-big btn-std-width"
                                  disabled={isSettingsDDLoading}
                                >
                                  Delete Selected
                                </button>
                              )}
                            </div>

                            { totalUsersAdded.length === 0 && 
                                  <button
                                    // disabled={!userRole}
                                    type="button"
                                    className="btn btn-big-width btn-big btn-primary"
                                    disabled={isSettingsDDLoading}
                                    onClick={handleSubmit(onSubmit,onError)}
                                  >
                                    Save &amp; Continue
                                  </button>
                            }
                            
                            { totalUsersAdded.length > 0 &&
                                  <button
                                    type="button"
                                    className={`btn btn-big-width btn-big btn-primary ${isSetSaveLoaderInit ? 'processing' : ''}`}
                                    onClick={processCreate}
                                    disabled={isSetSaveLoaderInit}
                                    >
                                      Save &amp; Continue
                                  </button>
                    
                            }

                          </div>
                      </>
                    </form>
                  </div>
              </div>
            </div>
          </>
        )}
      </Popup>

      <CreatedUsersAction open={isWarnModalOpen} 
      data={totalUsersAdded}
      dropdownvalues={dropDownValues}
      setDeletedUser={setDeletedUser}
      selectedForDeletion={selectedForDeletion}
      // key={Math.random(1 * 3422)}
      createType={createGroup}
      createfile = {"create"}
      setTotalUsersAdded={setTotalUsersAdded}
      createGroupSubmitCount={submitCount}
      userRole={userRole}
      onModalClose={handleModalClose}
      onConfirmModalClose={handleConfirmModalClose}
      />

{/* <Popup
        className="size-xs fluid-height"
        open={isWarnModalOpen}
        closeOnDocumentClick
        nested
        modal
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />

          <div className="popup-header modal-header-raised d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Created Users Action</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setWarnModalOpen(false)}
            ></button>
          </div>

          <div className="content modal-content-raised">
            <div className="scroll-content" >
              <p>
                You will lose all the entered users, with closing the 'Create
                Group' modal.
              </p>
              <div className="d-flex justify-content-between align-items-center mt-5">
                <div></div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  title="Confirm & close create group modal"
                  onClick={() => {
                    setWarnModalOpen(false);
                    setTypeCreateModalOpen(false);
                    setTotalUsersAdded([]);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </>
      </Popup> */}
    </>
  );
};

export default CreateGroupOrUser;
