import React, {
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import UserAdd from "../../common/UserAdd";
import TableGrid from "../TableGrid";
import { COLUMNS, columns_unsaved } from "../ColumnsTypeCreate";
import SearchIcon from "../../../../Assets/img/search.svg";
import ClearIcon from "../../../../Assets/img/icon-cancel-white.svg";
import { ToastContainer, toast } from "react-toastify";
import { UserContext } from "../../../Application";
import axiosService from "../../../../utils/apiService";
import apiEndPoints from "../../../../utils/apiEndPoints";
import Grid from "@material-ui/core/Grid";
import SuperAdminPermissions from "../../common/CreateSuperAdminPermissions";

const Table = forwardRef((props, ref) => {
  const searchKeyword = useRef(null);
  const [selection, setSelection] = useState([]);
  const [createGroup, setCreateGroup] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userDataContainer, setUserDataContainer] = useState([]);
  const [isUserSerched, setIsUserSerched] = useState(false);
  const user = useContext(UserContext);
  useEffect(() => {
    setCreateGroup(props.createType);
  }, [props.data,props.createType]);

useEffect(() => {
  setUserData(props.data);
  setUserDataContainer(props.data);
}, [props.data ]);


  useEffect(() => {
    if (props.selectedForDeletion) {
    }
  }, [props.selectedForDeletion]);

  useEffect(()=>{
},[props.dropdownvalues])
  const updateCellData = (row, column, value, isBlur) => {
    const rowId = row.original.id;
    userDataContainer?.forEach((val) => {
      console.log("newtestt",row,value,val)
      if (val.id == rowId) {
        if(value==="SuperAdmin" ||value==="Admin"||value==="User"){
          val["user_role"] = value;
        }else{
          if(val.tool[0]===row.original.tool[0]){
            val["user_access"] = value;
          }
        }
      }
      console.log("userContaindser",userDataContainer )
      // props.setTotalUsersAdded([...userDataContainer])
      setUserDataContainer([...userDataContainer]);
    });
  };
    

  const editCellProps = {
    updateCellData,

  };

  const handleNewUser = (newUser) => {
    let isSettingsInValid = props.checkValidFields();
    if (isSettingsInValid) {
      toast.error(<div className="tosterIcon">Please check the errors, in the above form.</div>, {
        containerId: "A",
      });
    } else {

      // console.log("New User - ", newUser,userData);
      let isEmailExists = userData.some((d) => d.email.includes(newUser.email) && d.tool[0].includes(newUser.tool[0]));
      if (isEmailExists) {
        props.setIsupdateUser?.(true)
        // toast.error(<div className="tosterIcon"> Email already exists</div>, {
        //   containerId: "A",
        // });
        // return true;
      }
      let bgg = userData;
      console.log("props.userRole",newUser,props.userRole)
      newUser.user_role = createGroup ? "Admin" : props.userRole===""?user?.userRole:props.userRole;
      newUser.user_access = "2";
      let SuperAdminUser = [];
      if (newUser.user_role === "SuperAdmin") {
        for (let i = 0; i < 3; i++) {
          let x =
          {
            ...newUser,
            tool: SuperAdminPermissions[i].Permissions.myaccess.tool,
            brand: SuperAdminPermissions[i].Permissions.myaccess.brand,
            businessGroup: SuperAdminPermissions[i].Permissions.myaccess.businessGroup,
            category: SuperAdminPermissions[i].Permissions.myaccess.category,
            mco: SuperAdminPermissions[i].Permissions.myaccess.mco,
            tabViewSetting: SuperAdminPermissions[i].Permissions.myaccess.tabViewSetting,
            ddlInfo: SuperAdminPermissions[i].Permissions.myaccess.ddlInfo,
            country: SuperAdminPermissions[i].Permissions.myaccess.country
          }
          SuperAdminUser = [...SuperAdminUser, x]
        }
      }
      let nhj = SuperAdminUser.length > 0 ? [...SuperAdminUser] : [{ id: bgg.length > 0 ? bgg[bgg.length - 1].id + 1 : 1, ...newUser }];
      setUserData([...bgg, ...nhj]);
      setUserDataContainer([...bgg, ...nhj]);
      props.onNewUser([...bgg, ...nhj]);
      props.onUserAddedOnClick(newUser);
      if (searchKeyword.length) searchKeyword.current.value = '';
    }
  };

  const clearSearchClose = () => {
    searchKeyword.current.value = "";
    columnsListWrap();
  };

  const setKeywordFunc = () => {
    columnsListWrap();
  };

  const columnsListWrap = () => {
    var columnsFiltered = [""];
    var columnsFiltered2 = [""];
    var qq = [];
    var dw = [];
    if (
      searchKeyword.current.value != "" &&
      searchKeyword.current.value != undefined
    ) {
      columnsFiltered = userData.filter((e) =>
        e.email
          .toString()
          .toLowerCase()
          .includes(searchKeyword.current.value.toString().toLowerCase())
      );
      columnsFiltered2 = userData.filter((e) =>
        e.user_name
          .toString()
          .toLowerCase()
          .includes(searchKeyword.current.value.toString().toLowerCase())
      );
      qq = [...columnsFiltered, ...columnsFiltered2];
      dw = [...new Set(qq)];
    } else {
      dw = userData;
    }
    setUserDataContainer(dw);
    setIsUserSerched(true);
  };

  useImperativeHandle(ref, () => ({
    onActionClick() {
      if (selection.length === 0) {
        toast.error(<div className="tosterIcon">Select users to delete</div>, {
          containerId: "A",
        });
        return;
      } else {
        let useContainer = userDataContainer.filter(
          (i) => !(selection?.map((i) => i.original.email).includes(i.email) && selection?.map((i) => i.original.tool[0]).includes(i.tool[0]))
        );
        let deletedUser =userDataContainer.filter(
          (i) => (selection?.map((i) => i.original.email).includes(i.email) && selection?.map((i) => i.original.tool[0]).includes(i.tool[0]))
        );
        props.setDeletedUser(prev=>[...prev,...deletedUser])
        setUserDataContainer([...useContainer]);
        props.setTotalUsersAdded?.([...useContainer]);
        toast.success(<div className="tosterIcon">{props.userRole} deleted</div>, {
          containerId: "ToastifyModalContainer",
        });
      }
    },
  }));

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    //setIsSelectAll(selection.length === pageSizeNo);
    // setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);

   console.log("selection", selection);
  };

  // useEffect(() => {
  //   if(!userData.length && props.submitCount > 0) {
  //     toast.error(<div className="tosterIcon">Please add atleast one user, to the group.</div>, {
  //       containerId: "A",
  //     });
  //     console.log(props.submitCount, props.isSubmitted, userData.length);
  //   }
  // },[props]);
 
  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />
      <div className="mb-3">
        {(createGroup || props.createfile ==="create") && 
        <div className="row mb-1 row-user-settings row-user-manage" style={{ position : 'relative' , zIndex : '335'}}  >
            <div className="col-md-12">
                <UserAdd hidden={user?.userRole==="User"?true:false} handleCloseDropDown={props.handleCloseDropDown}
                dropdownvalues={props.dropdownvalues}
                setAddUserInfo={props.setAddUserInfo}
                ddlInfo={props.ddlInfo}
                onNewUser={handleNewUser}
                //onUserAddedOnClick={onUserAddedOnClick}
                createType={createGroup} />
            </div>
        </div>
        }
        {/* <div className="error-mssg">
            {(props.submitCount > 0 && userData.length <= 0) && (
              <p className="mt-4 mb-0" key={231}>Please add atleast one user, to the group.</p>
            )}
        </div> */}
        {userData.length > 0 && !props.hideSearch && (
          <div className={`w-100 flex align-items-center justify-content-end`} style={{position : 'relative' , zIndex : '334', marginTop: '-45px'}} >
             <Grid container spacing={2} className="flex justify-content-end">
                <Grid item xs={3}>
                  <div>
                    <div
                      className="search-bar d-flex align-items-center">
                      <span className="search-wrap-out w-100">
                        <span className="search-wrap w-100">
                          <img src={SearchIcon} className="filter-search" alt="" />
                          <input
                            type="text"
                            placeholder="Email ID / User Name"
                            className="btn-slim search-input bg-black w-100"
                            ref={searchKeyword}
                            // hidden={user?.userRole==="User"?true:false}
                          />
                          <a
                            className="filter-clear"
                            onClick={() => clearSearchClose()}
                          >
                            <img src={ClearIcon} alt="" />
                          </a>
                        </span>
                        <input
                          type="button"
                          value="Search"
                          className="btn-slim search-btn" 
                          onClick={() => setKeywordFunc()}
                        />
                      </span>
                    </div>
                  </div>
                  </Grid>
              </Grid>
          </div>
        )}
      </div>
      {console.log("data",props.newUserInfo)}
      <TableGrid
        newUserInfo={props.newUserInfo}
        // setAddUserInfo={props.setAddUserInfo}
        ddlInfo={props.ddlInfo}
        data={userDataContainer}
        key={userDataContainer.length}
        hasRowSelection={true}
        selection={selection}
        setSelection={setSelection}
        toggleSelection={toggleSelection}
        isShowingUserDataUnsaved={props.isShowingUserDataUnsaved}
        columns={props.isShowingUserDataUnsaved ? columns_unsaved : COLUMNS}
        cells_width_auto={true}
        actionOnUser={props.actionOnUser}
        edit={props.edit}
        
        {...editCellProps}
      ></TableGrid>
    </>
  );
});

export default Table;
