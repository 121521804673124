import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import "chart.js/auto";
import { BarChart } from "../../../Common/charts/BarChart";
import { getPendingResidualDaysData } from "../../../Services/WasteRiskSummaryService";
import { payloadWithFilters, stackBarChartOptions, stackBarChartOptions2 } from "../helpers";
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const ResidualPendingChart = ({ dropDownValues ,displayType}) => {
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userAccess = useContext(UserAccessContext)


  useEffect(() => {
    if(dropDownValues){
      getChartData(payloadWithFilters(dropDownValues,userAccess));
     }
  }, [dropDownValues]);

  const getChartData = useCallback((payload) => {
    getPendingResidualDaysData(payload)
      .then((response) => {
        setChartData(response.data);
      })
      .finally(() => setIsLoading(false));
  });

  const data = {
    labels: chartData?.map((data) => data.mco),
    datasets: [
      {
        label: "<30",
        data: chartData?.map((data) => data["less_than_30_days"]),
        backgroundColor: "rgb(92,188,169)",
      },
      {
        label: "30-60",
        data: chartData?.map((data) => data["30_to_60_days"]),
        backgroundColor: "rgb(84,168,216)",
      },
      {
        label: "60-90",
        data: chartData?.map((data) => data["60_to_90_days"]),
        backgroundColor: "rgb(132,108,227)",
      },
      {
        label: "90-120",
        data: chartData?.map((data) => data["90_to_120_days"]),
        backgroundColor: "rgb(239,163,58)",
      },
      {
        label: "120-150",
        data: chartData?.map((data) => data["120_to_150_days"]),
        backgroundColor: "rgb(235,120,167)",
      },
      {
        label: "150-180",
        data: chartData?.map((data) => data["150_to_180_days"]),
        backgroundColor: "rgb(232, 96, 86)",
      },
      {
        label: ">180",
        data: chartData?.map((data) => data["greater_than_180_days"]),
        backgroundColor: "rgb(154, 37, 37)",
      },
    ],
  };

  if (isLoading) return <Loader hideText={true} />;

  return <BarChart options={displayType==="popupDisplay"?stackBarChartOptions2:stackBarChartOptions} data={data} />;
};

export default ResidualPendingChart;
