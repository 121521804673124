import React from "react";

const Loader = (props) => {
  return (
    <div>
      <div className="loader py-5 flex align-items-center justify-content-center">
        {!props.hideText && (
          <p className="d-inline-block m-0 p-0 text-white">Loading</p>
        )}
        <div className={`lds-ring ${!props.hideText ? 'ml-2' : ''}`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
