import React, { useContext, useState } from "react";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import UserPanel from "./common/UserPanel";
import Groups from "./common/Groups";
import Notifications from "./common/Notifications";
import Users from "./common/Users";
import Crtracker from "../../Common/crtracker/Crtracker";
import { Link } from "react-router-dom";
import { UserContext } from "../Application";
const UserManagementDetails = (props) => {
  const [dropDownValues, setDropDownValues] = useState();
  const [dealList, setDealList] = useState([]);
  const history = useHistory();
  const [refresh, setRefresh] = useState(true)
  const user = useContext(UserContext)

  const reload = (value) => {
    if (!value) {
      setRefresh(value);
    }
  }

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }


  return (
    <>
      <div className="container-fluid page-user-management pl-0 pr-0">
        <div className="main_header align-baseline">
          <Header isNotProduction={!isProd()} />
        </div>
        <div className={`main-content-row`}>
          <div className="side-menu left_sidemenu">
            {/* <SideMenu /> */}
          </div>
          <div className="main-div-content panel-custom full-screen-cover">
            {/* <div class="page-body-top-content">
          <Crtracker />
        </div> */}
            <div className="row h-100">
              <div className="col-md-9 h-100 pr-0 ">
                <div className="body-container panel-border-round h-100">
                  <UserPanel reload={reload} />
                </div>
              </div>
              <div className="col-md-3 h-100">
                <div className="group-container panel-border-round position-relative body-container mb-2 half-screen-height">
                  <Groups key={refresh} />
                </div>
                {!(user?.userRole === "User") && <div className="user-container panel-border-round position-relative body-container half-screen-height" >
                  <Users key={refresh} />
                </div>}
                {/* {!(user?.userRole === "User") && <div className="notifications-container panel-border-round position-relative body-container mt-2 " style={{ cursor: "pointer" }}>
                  <Notifications />
                  
                  <Link to={'/my-Notifications'} style={{ color: 'white', textDecoration: "none" }}>My Notifications</Link>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(UserManagementDetails);
