import { API } from "../backend";
import axios from "axios";
import { notify } from "../../src/Common/toast";
var fileSaver = require("file-saver");

export const WasteRiskDetails = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getWasteRiskDetails`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const addPulishStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/addToPublishActions`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const setPublishStatus = async (payload) => {
  try {
    const response = await axios.put(`${API}/store/updatePublishActions`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getConfirmData = async (payload) => {
  try {
    const response = await axios.get(`${API}/store/getConfirmdata`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const downloadWasteRiskDetails = async (payload) => {
  axios.post(`${API}/store/downloadWasteRiskDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "waste_risk_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const checkFileStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getTaskProfileUpdate`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

  //Need to call `uploadWasteRiskDetails`
  export const excelFileUpload = async (formData) => {
    try {
      let response = await axios.post(
        `${API}/store/uploadWasteRiskDetails?simulationId=123`,
        formData
      );
      return response;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const UpdateWasteRiskCellData = async (payload) => {
    try {
      let response = await axios.put(`${API}/store/updateWasteRiskKPI`, payload);
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const PendingActionsCount = async (payload) => {
    try {
      const response = await axios.get(
        `${API}/store/getWasteRiskPendingActionsCount`,
        payload
      );
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const getBomDetails = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/displayBomDetails`,
        payload
      );
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  export const saveBomDetails = async (payload) => {
    try {
      const response = await axios.put(
        `${API}/store/updateBomDetails`,
        payload
      );
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const getWasteRiskLogs = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/logs/waste-risk  `,
        payload
      );
      console.log("Data", response.data);
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const getWasteRiskEditedDates  = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/logs/get-edited-dates  `,
        payload
      );
      console.log("Data", response.data);
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  
  export const getWasteRiskPublishedDates  = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/logs/get-published-dates  `,
        payload
      );
      console.log("Data", response.data);
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  export const downloadBomDetailsData = async (payload) => {
    axios.post(`${API}/store/downloadBomDetails`, payload, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'arraybuffer',
    }
    ).then((response) => {
      var blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fileSaver.saveAs(blob, "bom_details.xlsx");
      notify({
        message: `Download Completed.`,
        type: "success",
      });
      return response;
    });
  }
  
  export const getWasteRiskDetailUpdatedDate = async (payload) => {
    try {
      const response = await axios.get(
        `${API}/store/getWasteRiskUpdatedDate`,
        payload
      );
      console.log("Data", response.data);
      return response.data;
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  export const savefgslobUserPreferences = async (payload) => {
    try {
      let response = await axios.post(`${API}/store/savefgslobUserPreferences`, payload);
      return response;
    } catch (err) {
      console.log("Error: ", err);
    }
  };
  
  export const getfgslobUserPreferences = async (payload) => {
    try {
      let response = await axios.get(`${API}/store/getfgslobUserPreferences?userEmail=${payload}`);
      return response;
    } catch (err) {
      console.log("Error: ", err);
    }
  };