import React, { useCallback, forwardRef, useImperativeHandle, useEffect, useState, useRef } from "react";
import TableGrid from "../TableGrid";
import { COLUMNS } from "../ColumnsViewCustomers";
import SearchIcon from "../../../../Assets/img/search.svg";
import ClearIcon from "../../../../Assets/img/icon-cancel-white.svg";
import Loader from "../../../../Common/loader";
import { ToastContainer, toast } from "react-toastify";
import Popup from "reactjs-popup";
import { deleteSavedCustomer } from "../../../../Services/userManagementService";
import { notify } from "../../../../Common/toast";
const Table = forwardRef((props, ref) => {
  const searchKeyword = useRef(null);
  const [selection, setSelection] = useState([]);
  const[createGroup, setCreateGroup] = useState(false);
  const[userData, setUserData]=useState([]);
  const[userDataContainer, setUserDataContainer]=useState([]);
  const [isDeleteConfirmation,setIsDeleteConfirmation]=useState(false)
  const [customerEmail,setCustomerEmail]=useState('');
  const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);


  useEffect(()=>{
    setUserData(props.data);
    setUserDataContainer(props.data);
   // setCreateGroup(props.createType);
  },[props]);

  useEffect(()=>{
    console.log("all props >> ", props);
  },[props]);

  useEffect(()=>{
    if(props.selectedForDeletion){
    console.log("props.selectedForDeletion",props.selectedForDeletion);}
  },[props]);

  const clearSearchClose = () => {
    searchKeyword.current.value = '';
    columnsListWrap();
  }

  const deleteCustomer = () => {
    setSaveLoaderInit(true);
    deleteSavedCustomer(customerEmail)
      .then((res) => {
        setSaveLoaderInit(false);
        let newUserDate = userDataContainer.filter(
          (i) => i.email !== customerEmail
        );
        setUserDataContainer([...newUserDate]);
        // notify({ type: "error", message: res.message });
        toast.success(<div className="tosterIcon"> {res.message}</div>,{
          containerId: "A",
        });
      })
      .catch((err) => {
        // notify({
        //   type: "error",
        //   message: err.message || "Something went wrong",
        // });
        setSaveLoaderInit(false);
        toast.error(<div className="tosterIcon"> {err.message || "Something went wrong"}</div>,{
          containerId: "A",
        });
      });
  };
  const setKeywordFunc = () => {
    columnsListWrap();
  }

  const columnsListWrap = () => {
    var columnsFiltered = [''];
    var columnsFiltered2 = [''];
    var qq = [];
    var dw = [];
    if(searchKeyword.current.value != '' && searchKeyword.current.value != undefined) {
      columnsFiltered = userData.filter(e => e.email.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase()));
      columnsFiltered2 = userData.filter(e => e.name.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase()));
      qq = [...columnsFiltered, ...columnsFiltered2];
      dw = [...new Set(qq)];
    } else {
      dw = userData;
    }
    console.log("on search",searchKeyword.current.value, userData);
    setUserDataContainer(dw);   
  }

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    //setIsSelectAll(selection.length === pageSizeNo);
    // setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
    console.log("selection",selection);
  };

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />

      {props.isTableLoading && (
        <>
          <div className={`tableLoader_overlay`}>
            <div className="tableLoader_box">
              <div className="tableLoader_box-in">
                <Loader />
              </div>
            </div>
          </div>
          <div style={{ height: "120px" }}></div>
        </>
      )}

      {!props.isTableLoading && (
        <>
          {!props.displayedAsNotification && (
            <div className="d-flex align-items-center justify-content-between mb-3">
              {userData.length > 0 && (
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div></div>
                  <div
                    className="search-bar d-flex align-items-center"
                    style={{ width: "290px" }}
                  >
                    <span className="search-wrap-out w-100">
                      <span className="search-wrap w-100">
                        <img
                          src={SearchIcon}
                          className="filter-search"
                          alt=""
                        />
                        <input
                          type="text"
                          placeholder="Customer Name / Email ID"
                          className="btn-slim search-input bg-black w-100"
                          ref={searchKeyword}
                        />
                        <a
                          className="filter-clear"
                          onClick={() => clearSearchClose()}
                        >
                          <img src={ClearIcon} alt="" />
                        </a>
                      </span>
                      <input
                        type="button"
                        value="Search"
                        className="btn-slim search-btn"
                        onClick={() => setKeywordFunc()}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
          <TableGrid
            data={userDataContainer}
            key={userDataContainer.length}
            columns={COLUMNS}
            selection={selection}
            setSelection={setSelection}
            tableType={"viewCustomers"}
            actions={true}
            displayedAsNotification={props.displayedAsNotification}
            toggleSelection={toggleSelection}
            editIndCustomer={(val)=>{
             props.editIndCustomer?.(val)
            }}
            copyIndCustomer={(val)=>{
              props.copyIndCustomer?.(val)
             }}
            deleteIndCustomer={(val)=>{
              setCustomerEmail(val)
              setIsDeleteConfirmation(true)
            }}
          ></TableGrid>
        </>
      )}
      <Popup
        className="size-sm fluid-height"
        open={isDeleteConfirmation}
        closeOnDocumentClick
        modal
      >
        <>
          <ToastContainer
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_CENTER}
            autoClose={2000}
          />

          <div className="popup-header d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Delete Customer</h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setIsDeleteConfirmation(false)}
            ></button>
          </div>

          <div className="content">
            <div className="scroll-content">
              <p> Are you sure ,Do you want to delete customer</p>
              <div className="d-block text-right mt-5">
                <button
                  type="button"
                  className={`btn btn-secondary d-inline-block  ${isSetSaveLoaderInit ? 'processing' : ''}
                  `}
                  title={"Proceed to customer creation"}
                  disabled={isSetSaveLoaderInit}
                  onClick={() => {
                    setIsDeleteConfirmation(false)
                    deleteCustomer();
                  }}
                >
                  Proceed
                </button>
              </div>
            </div>
          </div>
        </>
      </Popup>
    </>
  );
});

export default Table;
