import React, { useContext, useEffect, useState } from 'react'
import "chart.js/auto";
import { Chart } from 'react-chartjs-2'

import Loader from "../../../Common/loader"; 
import { LABEL_COLOR, LEGEND_COLOR, chunkString } from '../../InflowRiskVisibility/helpers';
import { GetInflowForcastChart } from '../../../Services/InflowSummaryService';
import { setDropdownvaluesGlobal } from '../../../utility/helper';
import { UserAccessContext } from '../../Application';
function NegitiveBiasBarChart(props) {
    const [resData,setResData] = useState();
    const userAccess = useContext(UserAccessContext)

    useEffect(()=>{
        let payload={
          brandId: setDropdownvaluesGlobal(props.dropdownValues?.brand, userAccess?.inflowdata?.data?.brandId),
          countryId: setDropdownvaluesGlobal(props.dropdownValues?.country, userAccess?.inflowdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(props.dropdownValues?.category, userAccess?.inflowdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(props.dropdownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
          
            // businessGroupId:props.dropdownValues?.businessGroup??[],
            actionTypeId:props.dropdownValues?.actiontype??[],
            // brandId:props.dropdownValues?.brand??[],
            // countryId:props.dropdownValues?.country??[],
            // categoryId:props.dropdownValues?.category??[],
            foodSolutionIndicatorId: props.dropdownValues?.fsIndicator??[],
            plantCodeId:props.dropdownValues?.plant??[],
            riskTypeId:props.dropdownValues?.riskType??[],
            riskLevelId:props.dropdownValues?.riskLevel??[],
        }
        if(props.dropdownValues)
        GetInflowForcastChart(payload).then(response=>setResData(response))
    },[props.dropdownValues])

    const options = {
        
        elements: {
          point: {
            radius: 2,
          },
        },
        plugins: {
          legend: {
            display: false,
            position: "top",
            align: "center",
            labels: {
              pointStyle: "circle",
              usePointStyle: true,
              padding: 20,
              color: LEGEND_COLOR,
              font: {
                size: 10,
                family: "unilevershilling",
              },
            },
          },
        },
        responsive: true,
        barThickness:60,
        // width:300,
        height:300,
        // aspectRatio: 1.1,
        scales: {
          x: {
            gird: {
              display: false,
            },
            ticks: {
              maxRotation:0 ,// Set the maximum rotation angle to 0 degrees (horizontal)
              minRotation: 0,
              maxTicksLimit:0,
              align:"left",
              color: LABEL_COLOR,
              font: {
                size: 10,
                family: "unilevershilling",
              },
              callback(value) {
                return chunkString(this.getLabelForValue(value), 10);
              },
            },
          },
          y: {
            gird: {
              display: false,
            },
            display: true,
            ticks:{
              // stepSize: 10000,
            //   callback: (value) => `€ ${(value / 1000000).toFixed()}M`,
              color: LABEL_COLOR,
              font: {
                family: "unilevershilling",
              },
            }
          },
        },
      };
    const labels=["High negative bias L12W","Negative bias L12W","High negative bias L4W","Negative bias L4W"]
    const data = {
        labels,
        datasets: [
          {
            type: "bar",
            // label: "Residual Stock in €",
            data: [
                resData?.filter(i=>i.forecast_bias_l12w==="High Negative Bias").length,
                resData?.filter(i=>i.forecast_bias_l12w==="Negative Bias").length,
                resData?.filter(i=>i.forecast_bias_l4w==="High Negative Bias").length,
                resData?.filter(i=>i.forecast_bias_l4w==="Negative Bias").length
            ],
            backgroundColor: [
                'rgb(232, 96, 86)', 
                'rgb(232, 96, 86)', 
                'rgb(143, 202, 235)', 
                'rgb(143, 202, 235)', 
              ],
            datalabels: {
              display:false,
              align: "top",
              anchor: "end",
              offset: -3,
              color: LABEL_COLOR,
              font: {
                size: 10,
                family: "unilevershilling",
              },
            },
          },
        ],
      };
      console.log(resData?.filter(i=>i.actionTypeId==="6"))
  return <Chart type="bar" data={data} options={options} />
}

export default NegitiveBiasBarChart