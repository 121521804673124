import React from "react";
import Pagination from "react-bootstrap/Pagination";
//import ULButton from "../button";

const ReactTablePagination = ({
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  manualPageSize,
  handleItemPerPage,
  handlePaginationReset,
  pageSizeNo,
  //setIsSelectAll,
  //setSelection,
  dataLength,
  totalCount,
  setPageIndex,
}) => {
  // const arrayPageIndex =
  //   pageIndex - 2 < 0
  //     ? pageOptions.slice(0, pageIndex + 3)
  //     : pageOptions.slice(pageIndex - 2, pageIndex + 3);

      const arrayPageIndex = pageOptions.slice(pageIndex, pageIndex + 3)
       

  return (
    <>
      <div className="paginationContainer">
        {" "}
        {<div className="paginationInfo">
        {totalCount &&  `Showing ${dataLength} of ${totalCount} records`} {" "}
        </div>}
        <Pagination className="pagination" dir="ltr">
          <div className="pagination">
            <Pagination.First
              onClick={() => {
                //setIsSelectAll(false);
                //setSelection([]);
                gotoPage(0);
                setPageIndex?.(0)
              }}
              disabled={pageIndex === 0 ?true:false}
              className={!canPreviousPage ? "disabled-button" : ""}
            />
            <Pagination.Prev
              onClick={() => {
                //setIsSelectAll(false);
                //setSelection([]);
                previousPage();
                setPageIndex?.(pageIndex-1)
              }}
              disabled={pageIndex === 0 ?true:false}
              className={!canPreviousPage ? "disabled-button" : ""}
            />
            {arrayPageIndex.map((i) => (
              <Pagination.Item
                key={i}
                type="button"
                active={pageIndex === i}
                onClick={() => {
                  gotoPage(i);
                  setPageIndex?.(i)
                  //setIsSelectAll(false);
                  //setSelection([]);
                }}
              >
                {i + 1}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={() => {
                //setIsSelectAll(false);
                //setSelection([]);
                nextPage();
                setPageIndex?.(pageIndex+1)
              }}
              disabled={pageIndex === pageOptions.length-1 ?true:false}
              className={!canNextPage ? "disabled-button" : ""}
            />
            <Pagination.Last
              onClick={() => {
                //setIsSelectAll(false);
                //setSelection([]);
                gotoPage(pageOptions.length - 1);
                setPageIndex?.(pageOptions.length - 1)
              }}
              disabled={pageIndex === pageOptions.length-1 ?true:false}
              className={!canNextPage ? "disabled-button" : ""}
            />
            {/* {manualPageSize.length > 1 && (
          <div className="record-number-container">
            <select
              onChange={(e) => handleItemPerPage(e.target.value, gotoPage)}
              value={pageSizeNo}
            >
              {manualPageSize.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        )} */}

            <label>Items per page</label>
            {/* <ULButton
          className="paginationRefresh"
          onClick={() => handlePaginationReset(gotoPage)}
          icon="mdi mdi-refresh"
        /> */}
          </div>
        </Pagination>
      </div>
    </>
  );
};

export default ReactTablePagination;
