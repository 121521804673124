import { API } from "../backend";
import axios from "axios";
import { notify } from "../../src/Common/toast";
var fileSaver = require("file-saver");

export const PomDetails = async (payload, param = "") => {
  try {
    const response = await axios.post(`${API}/store/getPomDetails`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const addPulishStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/addToPomPublishActions`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const setPublishStatus = async (payload) => {
  try {
    const response = await axios.put(`${API}/store/updatePomPublishActions`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const downloadPomDetails = async (payload,tabKey) => {
  axios.post(`${API}/store/${tabKey=='ZUN'?`downloadPomDetails`:`downloadPomCsDetails`}`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const downloadPomCustomerPomDetails = async (payload) => {
  axios.post(`${API}/store/downloadPomCustomerDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_customer_details");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const downloadPomDBDetails = async (payload) => {
  axios.post(`${API}/store/downloadPomDBDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_db_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}
export const getDownloadBatchCodes = async(payload,tabKey)=>{
  axios.post(`${API}/store/${tabKey=='ZUN'?`downloadBatchCode`:`downloadBatchCodeCs`}`,payload,{
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_Batch_Code_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}


export const downloadPomSFDetails = async (payload,tabKey) => {
  axios.post(`${API}/store/${tabKey=='ZUN'?`downloadPomSFDetails`:`downloadPomCsSFDetails`}`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_internal_sales_info_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const downloadPomZANDetails = async (payload,tabKey) => {
  axios.post(`${API}/store/${tabKey=='ZUN'?`downloadPomZANDetails`:`downloadPomCsZANDetails`}`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_pallet_info_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const downloadPomScreenDetails = async (payload) => {
  axios.post(`${API}/store/downloadPomScreenDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "pom_screen_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const excelFileUpload = async (formData) => {
  try {
    let response = await axios.post(`${API}/store/uploadPomDetails`, formData);
    return response;
  } catch (err) {
  throw new Error(err?.response?.data?.message || 'Something went wrong')
   
  }
};

export const updatePomCellData = async (payload) => {
  try {
    let response = await axios.put(`${API}/store/updatePomKPI`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const bulkUpdatePomCellData = async (payload) => {
  try {
    let response = await axios.put(`${API}/store/bulkUpdatePomKPIs`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const PendingActionsCount = async (payload) => {
  try {
    const response = await axios.get(
      `${API}/store/getPomPendingActionsCount`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getCustomerDeatils = async (payload) => {
  try {
    const response = await axios.post(`${API}/store/getCustomersPOM`, 
     payload,
      );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const saveCustomer = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/addCustomersPOM`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const checkFileStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getTaskProfileUpdate`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getSkuBatchCode = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getSkuBatchCode`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getPomUpdatedDate = async (payload) => {
  try {
    const response = await axios.get(
      `${API}/store/getPomUpdatedDate  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getPomLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/pom  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getEditedDatesLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/get-edited-dates  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getPublishedDatesLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/get-published-dates  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const saveUserPreferences = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/saveUserPreferences`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getUserPreferences = async (payload) => {
  try {
    let response = await axios.get(`${API}/store/getUserPreferences?userEmail=${payload}`);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

