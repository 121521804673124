import React, { useEffect, useState, useContext } from 'react';
import ULModal from "../../Common/Modal";
import TableGrid from "./TableGrid";
import { COLUMNS } from './Columns';
import Loader from '../../Common/loader';
import { getConfirmData } from '../../Services/WasteRiskDetailServices';
import { UserContext } from "../../Components/Application";

import { forEach } from 'lodash';


const AddToPublishModal = (props) => {
    
    const [data, setData] = useState([]);
    const [publishLoading, setPublishLoading] = useState(false);
    const [isTableLoading, setTableLoading] = useState(false)
    const user = useContext(UserContext);
    const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
    useEffect(() => {
        if (props.show) {
            setTableLoading(true)
            getConfirmData().then(res => {
                console.log("res",res);
                const data = res || [];
                // const dataByReference = data.reduce((prev, curr) => {
                //     prev[curr.referenceID] = [...(prev[curr.referenceID] || []), curr]
                //     return prev;
                // }, {});
                // const allRows = Object.values(dataByReference).map(rows => {
                //     return rows.reduce((prev, curr) => {
                //         prev[curr.referenceKey] = curr.referenceValue
                //         return prev;
                //     }, {})
                // })

                console.log(data);

                setData(data);
                setTableLoading(false)
            })
        }

    }, [props.show]);

    console.log("::: AddToPublishModal :::", data);
   

    return (
        <ULModal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            isheader="true"
            heading={"Add to Publish List"}
            className={`page-modal publishPopup glossary-add-edit-popup-content`}
        >
            {(isTableLoading) && (
                <>
                    <div className={`tableLoader_overlay`}>
                        <div className="tableLoader_box">
                            <div className="tableLoader_box-in">
                                <Loader />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {(!isTableLoading) && (
              <>
                <div className="d-flex flex-column justify-content-between flex-basis-100">
                    <div className="position-relative">
                    
                    <div className="table_design d-flex">
                        <div className="table sticky scroll  data-table-two display nowrap scrollclass">
                            <div className="header">
                                <div className="tr">
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text">
                                            Deal Type
                                        </span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Material Number'>Material Number</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Material Description'>Material Description</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Comment Field'>Comment Field</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Local Deal Completion Month'>Local Deal Completion Month</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Local deal Quantity in ZUNs'>Local deal Quantity in ZUNs</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Central deal Quantity in ZUNs'>Central deal Quantity in ZUNs</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Pack Type'>Pack Type</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Language on Package'>Language on Package</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Production Country Name'>Production Country Name</span>
                                    </div>
                                </div>
                                <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='Expiry Date'>Expiry Date</span>
                                    </div>
                                </div>
                                {/* <div className="th">
                                    <div className="th-in">
                                        <span className="th-text" title='13wk + 26wk Residual ZUNs'>13wk + 26wk Residual ZUNs</span>
                                    </div>
                                </div> */}

                                </div>
                            </div>
                            <div className="body">
                                {data.map(row =>{

                                    const checkChange=(obg,targetvalue)=>{
                                        // console.log(obg)
                                        let asdf=obg.map(ele=>{
                                            // console.log(JSON.stringify(ele.referenceKey),JSON.stringify(targetvalue))
                                            if(ele.referenceKey===targetvalue){
                                                return ele.referenceValue?ele.referenceValue:" "
                                            }
                                            else{
                                                return
                                            }
                                        })
                                        // console.log(asdf.filter(i=>i!==undefined)[0])
                                        return asdf.filter(i=>i!==undefined)[0]
                                    }
                                    return (
                                      <div className="tr" key={row.id}>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(row.updatedObj, "dealTypeId")
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            // title={checkChange(
                                            //   row.updatedObj,
                                            //   "dealTypeId"
                                            // )}
                                            title={checkChange(row.updatedObj, "dealTypeId")
                                            ? props.actions?.filter(
                                                (i) =>
                                                  i.id ==
                                                  checkChange(
                                                    row.updatedObj,
                                                    "dealTypeId"
                                                  )
                                              )[0]?.action_name
                                            : props.actions?.filter(
                                                (i) =>
                                                  i.id == row.element.dealTypeId
                                              )[0]?.action_name}
                                          >
                                            {/* {console.log(props.actions)} */}
                                            {checkChange(row.updatedObj, "dealTypeId")
                                              ? props.actions?.filter(
                                                  (i) =>
                                                    i.id ==
                                                    checkChange(
                                                      row.updatedObj,
                                                      "dealTypeId"
                                                    )
                                                )[0]?.action_name
                                              : props.actions?.filter(
                                                  (i) =>
                                                    i.id == row.element.dealTypeId
                                                )[0]?.action_name}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "materialno"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "materialno"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "materialno"
                                                )
                                              : row.element.materialno}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "materialno"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "materialno"
                                                )
                                              : row.element.materialno}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "material_description"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title={row.element.material_description}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "material_description"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "material_description"
                                                )
                                              : row.element.material_description}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "comment_field"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "comment_field"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "comment_field"
                                                )
                                              : row.element.comment_field}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "comment_field"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "comment_field"
                                                )
                                              : row.element.comment_field}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "local_deal_completion_month"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "local_deal_completion_month"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "local_deal_completion_month"
                                                )
                                              : row.element
                                                  .local_deal_completion_month}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "local_deal_completion_month"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "local_deal_completion_month"
                                                )
                                              : row.element
                                                  .local_deal_completion_month}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "local_deal_quantity_in_zun"
                                            )
                                              ? {borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "local_deal_quantity_in_zun"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "local_deal_quantity_in_zun"
                                                )
                                              : row.element
                                                  .local_deal_quantity_in_zun}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "local_deal_quantity_in_zun"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "local_deal_quantity_in_zun"
                                                )
                                              : row.element
                                                  .local_deal_quantity_in_zun}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "central_deal_quantity_in_zun"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "central_deal_quantity_in_zun"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "central_deal_quantity_in_zun"
                                                )
                                              : row.element
                                                  .central_deal_quantity_in_zun}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "central_deal_quantity_in_zun"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "central_deal_quantity_in_zun"
                                                )
                                              : row.element
                                                  .central_deal_quantity_in_zun}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(row.updatedObj, "pack_type")
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "pack_type"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "pack_type"
                                                )
                                              : row.element.pack_type}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "pack_type"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "pack_type"
                                                )
                                              : row.element.pack_type}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "language_on_pack"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "language_on_pack"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "language_on_pack"
                                                )
                                              : row.element.language_on_pack}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "language_on_pack"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "language_on_pack"
                                                )
                                              : row.element.language_on_pack}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "production_country_name"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title= {checkChange(
                                              row.updatedObj,
                                              "production_country_name"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "production_country_name"
                                                )
                                              : row.element.production_country_name}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "production_country_name"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "production_country_name"
                                                )
                                              : row.element.production_country_name}
                                          </span>
                                        </div>
                                        <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "expiry_date"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title={checkChange(
                                              row.updatedObj,
                                              "expiry_date"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "expiry_date"
                                                )
                                              : row.element.expiry_date}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "expiry_date"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "expiry_date"
                                                )
                                              : row.element.expiry_date}
                                          </span>
                                        </div>
                                        {/* <div
                                          className="td"
                                          style={
                                            checkChange(
                                              row.updatedObj,
                                              "wk13_wk26_residual_zun"
                                            )
                                              ? { borderBottom: " 3px solid green" }
                                              : { borderBottom: "" }
                                          }
                                        >
                                          <span
                                            className="td-in"
                                            title={checkChange(
                                              row.updatedObj,
                                              "wk13_wk26_residual_zun"
                                            )}
                                          >
                                            {checkChange(
                                              row.updatedObj,
                                              "wk13_wk26_residual_zun"
                                            )
                                              ? checkChange(
                                                  row.updatedObj,
                                                  "wk13_wk26_residual_zun"
                                                )
                                              : row.element.wk13_wk26_residual_zun}
                                          </span>
                                        </div> */}
                                      </div>
                                    );})}
                                        
                            </div>

                            {/* <div className="delete-customers-row-wrap">
                    {customers.map((customer, index) => (
                      <div className="delete-customers-row">
                      <button type="button" className="btn btn-round btn-primary size-sm text-white" title="Remove Customer details" key={index} ><span className="btn-round-in">-</span></button>
                      </div>
                    ))}
                    </div> */}
                        </div>
                    </div>
                    </div>
                </div>

                
                <button className={`btn btn-primary d-inline-block pull-right mt-3  ${isSetSaveLoaderInit ? 'processing' : ''}`} disabled={isSetSaveLoaderInit} onClick={() => {
                  setSaveLoaderInit(true)
                // setShowPublish(true)
                // setPublishLoading(true)
                // setTableLoading(true);
                  const payload = {
                    UserEmail: user.email,
                  };
                  setTimeout(() => {
                    props.gotoPublish(payload);
                  // setPublishLoading(false);
                    setSaveLoaderInit(false)
                    props.onHide();
                  }, 50);
                  setTimeout(() => {
                  // setPublishLoading(false);
                    setSaveLoaderInit(false)
                  }, 500);
                }}>
                Confirm
            </button>
            </>
            )}


        </ULModal>
    )

};

export default AddToPublishModal;