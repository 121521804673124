import {CellActionGroup,  CellActionUserRole } from "./CellActionGroup";

export const columns_unsaved = [
  {
    Header: "Email ID",
    accessor: "email",
    show: true,
    editable: false,
  },
  {
    Header: "User Name",
    accessor: "user_name",
    show: true,
    editable: false,
  },
  {
    Header: "Tool Name",
    accessor: "tool",
    show: true,
    editable: false,
  }
  // ,{
  //   Header: "Business Group",
  //   accessor: "businessGroup",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "MCO",
  //   accessor: "mco",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "Country",
  //   accessor: "country",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "Category",
  //   accessor: "category",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "Brand",
  //   accessor: "brand",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "Tab View Settings",
  //   accessor: "tabViewSetting",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "User Role",
  //   accessor: "user_role",
  //   show: true,
  //   editable: false,

  // },
  // {
  //   Header: "User Access",
  //   accessor: "user_access",
  //   show: true,
  //   editable: false,

  // },
]

export const COLUMNS = [
  {
    Header: "Email ID",
    accessor: "email",
    show: true,
    editable: true,
  },
  {
    Header: "User Name",
    accessor: "user_name",
    show: true,
    editable: true,
  },
  {
    Header: "Tool Name",
    accessor: "tool",
    show: true,
    editable: true,
  },
  {
    Header: "Business Group",
    accessor: "businessGroup",
    show: true,
    editable: true,
  },
  {
    Header: "MCO",
    accessor: "mco",
    show: true,
    editable: true,
  },
  {
    Header: "Country",
    accessor: "country",
    show: true,
    editable: true,
  },
  {
    Header: "Category",
    accessor: "category",
    show: true,
    editable: true,
  },
  {
    Header: "Brand",
    accessor: "brand",
    show: true,
    editable: true,
  },
  {
    Header: "Tab View Settings",
    accessor: "tabViewSetting",
    show: true,
    editable: true,
  },
  {
    Header: "User Role",
    accessor: "user_role",
    show: true,
    editable: false,
    Cell: CellActionUserRole
    // Cell: (cellObj) => {
    //   return (
    //     <span className={`text-white border-radius-std`} >{cellObj.value=="SuperAdmin"?"Super Admin":cellObj.value}</span>
    //   );
    // },
  },
  {
    Header: "User Access",
    accessor: "user_access",
    show: true,
    editable: true,
    Cell: CellActionGroup,
  },
];
