import React from "react";
import Modal from "react-bootstrap/Modal";
import ULButton from "../button";

const ULModal = (props) => {
  return (
    <Modal
      //backdrop="static"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={true}
      shouldCloseOnOverlayClick={props.onlyCloseOnCloseButton ? false : true}
    >
      {props.isheader && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className={`${props.subheadingInSameLine ? 'subheading-in-same-line' : ''}`} >
            {props.heading}{props.subheading ? <div className="subheading"><p>{props.subheading}</p></div> : ''}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{props.children}</Modal.Body>
      {props.isFooter && (
        <Modal.Footer>
          <ULButton onClick={props.onHide} label="Close" />
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ULModal;
