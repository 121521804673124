import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import {
  chunkString,
  LABEL_COLOR,
  LEGEND_COLOR,
  PayloadWithFilters,
} from "../../Pom/PomSummary/helpers";
import {
  PomNextStepVsAbsQtySummary,
  getPomComingSSLVsAbsQtySummary,
} from "../../../Services/PomSummaryService";
import Loader from "../../../Common/loader";
import { mdiConsoleNetwork } from "@mdi/js";
import { UserAccessContext } from "../../Application";

const NextStepChart = ({ dropDownValues, getNextStepData, ...props }) => {
  const [chartData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userAccess = useContext(UserAccessContext)
  const getApiData = async (payload) => {
    try {
      const response = await PomNextStepVsAbsQtySummary(payload);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false); // Update loading state after fetching
    }
  };

  useEffect(() => {
    if (dropDownValues != undefined)
      getApiData(PayloadWithFilters(dropDownValues,userAccess));
  }, [dropDownValues]);

  const nextStepsRef = useRef();
  if (isLoading) {
    return <Loader />; // Display loader while data is loading
  }

  const options = {
    responsive: true,
    barThickness: 25,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 20, // Adjust this value to create enough space for x-axis labels below the chart
      },
    },
    plugins: {
      tooltip: {
        intersect: true,
        callbacks: {
          label: function (context) {
            console.log("context", context);
            let label = context.label;
            if (label) {
              label += ": ";
            }
            // if (context.parsed.x !== null) {
            //   label += (
            //     Math.abs(context.parsed.y - context.parsed.x) / 1000000
            //   ).toFixed(2);
            //   // commaify((context.parsed.x/1000000)>1?Math.round(context.parsed.x/1000000):(context.parsed.x/1000000).toFixed(2)) +`M`;
            // }
            if (context.parsed.x !== null) {
              label += (
                Math.abs(context.raw[1] - context.raw[0]) / 1000000
              ).toFixed(2);
            }
            return label;
					},
					filter: function(tooltipItem) {
						return true;
					}
        },
      },
      legend: {
        display: false,
        align: "start",
        labels: {
          pointStyle: "square",
          usePointStyle: false,
          padding: 20,
          color: LEGEND_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },

      datalabels: {
        anchor: "end",
        align: "end",
        display: false,
        color: LEGEND_COLOR,
        font: {
          size: 5,
          family: "unilevershilling",
        },
        formatter: (value) =>
          `€ ${
            (value / 1000000).toFixed(2) > 0 ? (value / 1000000).toFixed(2) : 0
          }M`,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          // display: true,
          color: LABEL_COLOR,
          font: {
            size: 9,
            family: "unilevershilling",
          },
          padding: 1,
          callback(value) {
            return chunkString(
              this.getLabelForValue(value),
              props.displayType ? 10 : 30
            );
          },
          maxRotation: props.displayType ? 0 : 30,
          minRotation: props.displayType ? 0 : 30,
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          drawOnChartArea: true,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: (value) => `€ ${(value / 1000000).toFixed(2)}M`,
          color: LABEL_COLOR,
          font: {
            family: "unilevershilling",
          },
        },
      },
    },
  };

	console.log(options, "optionssss")
  const COLORS = {
    NoAction: "rgb(255, 102, 0)",
    Destroy: "rgb(102, 204, 0)",
    Offer2Sales: "rgb(192,192,192)",
    Offer2Charity: "rgb(0,204,255)",
    Offer2Trader: "rgb(255,153,0)",
    Check4Risk: "rgb(0,0,128)",
    blank: "rgb(51, 102, 255)",
  };
  const backGroundColorData = (data) => {
    let f = data.length;
    return data.map((i, index) => {
      if (index === 0) {
        return "#16A5F8";
      } else if (index === f - 1) {
        return "#E65C00";
      } else {
        if (i.keyValue[1] > i.keyValue[0]) {
          return "red" ;//"#EF5891"
        } else {
          return "green";
        }
      }
    });
  };

  let removeFirstElement = (arr) => {
    var temp = [...arr];
    temp.shift();
    return temp;
  };

  const NextStepStatusData = {
    labels: chartData?.map((row) => row.keyName),
    datasets: [
      {
        data: chartData.map((data) => data.keyValue),
        backgroundColor: backGroundColorData(chartData), //.map(data => COLORS[data.keyName])
        customLabel: chartData[0].keyName,
        label: chartData[0].keyName,
      },
      ...removeFirstElement(chartData)?.map((row) => ({
        label: row.keyName,
        backgroundColor: [COLORS[row.keyName]],
      })),
    ],
  };

	console.log(NextStepStatusData, "NextStepStatusData")
  return (
    <Chart
      ref={nextStepsRef}
      type="bar"
      options={options}
      data={NextStepStatusData}
    />
  );
};

export default NextStepChart;
