import React, { useState , useEffect} from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import InfoIcon from "../../Assets/img/info.svg";
import { headerDesc, headerDescPOM, headerDescInflow} from "../../utility/constants";
import SearchIcon from "../../Assets/img/search.svg";
import RefreshIcon from "../../Assets/img/refresh.svg";
import { getFgslobGlossary, getInflowGlossary, getPOMGlossary } from "../../Services/UserManagementService2";

const Glossary = (props) => {
  const [searchText, setSearchText] = useState("");
  const [columnsList, setColumnsList] = useState([]);
  const [pageCategory,setPageCategory]= useState();
  const [data,setData] = useState([])
  const [datainflow,setDatainflow] = useState([])
  const [datapom,setDatapom] = useState([])

  let pageProp = props.category;
  
  // getFgslobGlossary().then(response => setData(response?.data?.glossaryData[0]))
  // getInflowGlossary().then(response => setDatainflow(response?.data?.glossaryData[0]))
  // getPOMGlossary().then(response => setDatapom(response?.data?.glossaryData[0]))
  useEffect(()=>{
    if(pageProp==="fgSlob"){
      getFgslobGlossary().then(response => setData(response?.data?.glossaryData[0]))
    }
    if(pageProp==="inflow"){
      getInflowGlossary().then(response => setDatainflow(response?.data?.glossaryData[0]))
    }
    if(pageProp==="pom"){
      getPOMGlossary().then(response => setDatapom(response?.data?.glossaryData[0]));
    }
  },[pageProp])
  useEffect(()=>{
    console.log(data)
    switch (pageProp) {
      case 'fgSlob':
        setPageCategory( data?.map(({column_name,description})=>({column_name,description})));
        break;
      case 'inflow':
        setPageCategory( datainflow?.map(({column_name,description})=>({column_name,description})));
        break;
      case 'pom':
        setPageCategory ( datapom?.map(({column_name,description})=>({column_name,description})));
        break;
        default:
          break;
    }
  },[data,datainflow,datapom])
  
  


  useEffect(() => {
    setSearchText("");
  columnsListWrap('');
  },[props.category,pageCategory]);

  const setKeywordFunc = () => {
    columnsListWrap(searchText);
  }

  const clearSearch = () => {
    setSearchText("");
    columnsListWrap('');
  }

  const columnsListWrap = (searchKeyword) => {
    var columnsFiltered = [''];
    if(searchKeyword != '' && searchKeyword != undefined) {
      columnsFiltered = pageCategory.filter(e => e.column_name.toString().toLowerCase().includes(searchKeyword.toString().toLowerCase()));
    } else {
      columnsFiltered = pageCategory;
    }
    console.log(pageCategory)

    if(columnsFiltered?.length) {

        setColumnsList(
          (
          columnsFiltered.map((f) => (
            <div key={f.column} role="row" className="tr">
              <div role="cell" className="td col-md-4">
                <span className="td-in">{f.column_name}</span>
              </div>
              <div role="cell" className="td col-md-8">
                <span className="td-in">{f.description}</span>
              </div>
            </div>
          )))
        );

      } else {
        setColumnsList(
            (<div key={2333} role="row" className="tr w-100">
                <div role="cell" className="td td-no-data w-100">
                    <span className="td-in">No Data</span>
                </div>
          </div>)
        );
      }
    //return columnsList;
  }

  return (
      <Popup className="top-spacing-for-close glossary table-scroll"
        trigger={
          <span className="info_btn cursor-pointer">
            <img src={InfoIcon} alt="Info" />
          </span>
        }
        onClose={clearSearch}
        modal
        nested
      >
         {close => (
            <>
              <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
                <div className="table-filters d-flex align-items-center justify-content-between">
                  <h5 className="mt-2">Glossary</h5>
                  <div className="d-flex align-items-center">
                    <span className="search-wrap">
                      <img src={SearchIcon} className="filter-search" alt="" />
                      <input
                        type="text"
                        placeholder="Column name"
                        className="btn-slim search-input"
                        onChange={(e) => {
                          setSearchText(e.target.value ?? "");
                        }}
                        value={searchText}
                      />
                      <input
                        type="button"
                        value="Search"
                        className="btn-slim search-btn"
                        onClick={()=>setKeywordFunc()}
                      />
                    </span>
                    <span className="marginset">
                      <span
                        className="info_btn"
                        onClick={() => clearSearch()}
                        title="Clear Search"
                      >
                        <img src={RefreshIcon} alt="" width={20} height={20} />
                      </span>
                    </span>
                  </div>
                </div>
                <div className="clear"></div>

                <div className="table_design mt-2">
                  <div role="table" className="table no-single-line cells-width-auto">
                    <div className="header width-100">
                      <div role="row" className="tr">
                        <div
                          role="columnheader"
                          data-sticky-last-left-td="true"
                          className="th col-md-4"
                        >
                          <div className="th-in">
                            <span className="th-text">Column</span>
                          </div>
                        </div>
                        <div
                          role="columnheader"
                          data-sticky-last-left-td="true"
                          className="th col-md-8"
                        >
                          <div className="th-in">
                            <span className="th-text">Description</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div role="rowgroup" className={`body width-100`}>
                      <div role="table" className="table cells-width-auto">
                        <div role="rowgroup" className="body  ">
                          {columnsList}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
            </>
         )}
      </Popup>
  );
};

export default Glossary;