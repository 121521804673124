import React, { useState, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import PlusIcon from "../../../Assets/img/Plus.svg";
import Settings from "./Settings";
import TypeCreate from "./TypeCreate";
import AddCustomers from "./AddCustomers";
import ViewCustomers from "./ViewCustomers";
import viewIcon from "../../../Assets/img/icon-view.svg";
import { getUserMyAccess } from "../../../Services/userManagementService";
import { UserAccessContext, UserContext } from "../../Application";
import AdminSettings from "./AdminSettings";
import axiosService from "../../../utils/apiService";
import apiEndPoints from "../../../utils/apiEndPoints";
import Notifications from "./Notifications";
import NotificationSettings from "./NotificationSettings";
const UserPanel = (props) => {
    const [tabKey, setTabKey] = useState("");
    const [userGroupSelect, setUserGroupSelect] = useState(false);
    const [userRole, setUserRole] = useState("");
    const [createType, setCreateType] = useState("Group")
    const [myAccessInfo, setMyAccessInfo] = useState({})
    const [isCustomersPopupOpen, setCustomersPopupOpen] = useState(false);
    const [customerEmail, setCustomerEmail] = useState("");
    const [isEditCustomer, setIsEditCustomer] = useState(false);
    const [isCopyCustomer, setIsCopyCustomer] = useState(false);
    const [dropDownValues, setDropDownValues] = useState()
    const user = useContext(UserContext)
    const userAccess = useContext(UserAccessContext)
    const [screendisplayOptions,setScreenDisplayOptions] =useState([])
    const [screenOptions, setScreenOption] = useState()
    const [country, setCountry] = useState()
    const [bussinessGroup, setBussinessGroup] = useState([])
    const [mco, setMco] = useState()
    const [category, setCategory] = useState()
    const [brand, setBrand] = useState()
    const [access, setAccess] = useState()
    const [tabViewSettings, setTabViewSettings] = useState()

    const getMyAccessDetails = () => {
			setMyAccessInfo(userAccess)
			// getUserMyAccess({ UserEmail: user.email }).then(res=>{
			//     setMyAccessInfo(res.data?.data || res.data)
			// }).catch(res=>{
			// })
    }
		
    useEffect(() => {
			// console.log(bussinessGroup)
    }, [bussinessGroup])

    useEffect(() => {
			// console.log(dropDownValues)
    }, [dropDownValues])
    
    const selectUserGroup = (val) => {
			if (val == "") {
				setUserRole("")
				setUserGroupSelect(true);
			} else {
				setUserRole(val)
				setUserGroupSelect(false);
			}
    }

    useEffect(() => {
			selectUserGroup("");
    }, [])

    useEffect(() => {
			if (Object.keys(user)?.length) getMyAccessDetails();
    }, [user]);

    const handleCustomerPopupOpen = (customerPopupStatus) => {
			setCustomersPopupOpen(customerPopupStatus);
    }

    const editIndCustomer = (val) => {
			setCustomerEmail(val);
    };
    const [dataa, setDataa] = useState()
    const getDropdownValues = async () => {
			// setIsLoading(true);
			let obj =
				screenOptions === "POM"
					? {
						toolId: [3],
						businessGroupId: [],
						countryId: [],
						businessUnitId: [],
						accessId: [],
						brandId:[],
						clusterId: [],
						tabViewSettingId: [],
						pageSettingId: [],
						categoryId: [],
					}
					: screenOptions === "Inflow" ? {
						toolId: [2],
						businessGroupId: [],
						countryId: [],
						businessUnitId: [],
						accessId: [],
						clusterId: [],
						mcoId: [],
						tabViewSettingId: [],
						pageSettingId: [],
						brandId: [],
						categoryId: [],
					} : {
						toolId: [1],
						businessGroupId: [],
						countryId: [],
						businessUnitId: [],
						accessId: [],
						clusterId: [],
						mcoId: [],
						tabViewSettingId: [],
						pageSettingId: [],
						brandId: [],
						categoryId: [],
					}
			const resolt = await axiosService.post(
				apiEndPoints.userManagementFilters,
				obj
			);
			setDataa(resolt?.data?.data)
    }
    let dummy = {
			tool: userAccess?.fgSLOBdata?.data?.toolId,
			businessGroup: userAccess?.fgSLOBdata?.data?.bussinessGroupId,
			mco: userAccess?.fgSLOBdata?.data?.mcoId,
			country: userAccess?.fgSLOBdata?.data?.countryId,
			access: userAccess?.data?.accessId,
			tabViewSettings: userAccess?.fgSLOBdata?.data?.tabViewSettingId,
			category: userAccess?.fgSLOBdata?.data?.categoryId,
			brand: userAccess?.fgSLOBdata?.data?.brandId,
    }
    // const [dummy,setDummy] = useState()
    const getValues = (data, data2) => {
			const filteredValues = data.filter(item => data2?.includes(item.key)).map(item => item.value);
			return (filteredValues)
    }
    useEffect(()=>{
			let dummyary=[]
			if(userAccess?.fgSLOBdata?.data?.length===undefined){
				dummyary.push("FG Slob")
			}
			if(userAccess?.inflowdata?.data?.length===undefined){
				dummyary.push("Inflow")
			}
			if(userAccess?.pomdata?.data?.length===undefined){
				dummyary.push("POM")
			}
			setScreenDisplayOptions([...dummyary])
    },[userAccess])

    useEffect(()=>{
			setScreenOption(screendisplayOptions[0])
    },[screendisplayOptions])

    useEffect(() => {
			if(userAccess && screenOptions!==undefined)
			getDropdownValues()
    }, [screenOptions,userAccess])

    useEffect(() => {
			setBussinessGroup()
			setMco()
			setCountry()
			setAccess()
			setTabViewSettings()
			setCategory()
			setBrand()
			if(dataa&&userAccess){
			if ((screenOptions === "FG Slob"||screendisplayOptions[0]==="FG Slob") && userAccess?.fgSLOBdata?.data?.length === undefined) {
				setBussinessGroup(getValues(dataa.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId))
				setMco(getValues(dataa.mco, userAccess?.fgSLOBdata?.data?.mcoId))
				setCountry(getValues(dataa.country, userAccess?.fgSLOBdata?.data?.countryId))
				setAccess(getValues(dataa.access, userAccess?.fgSLOBdata?.data?.accessId))
				setTabViewSettings(getValues(dataa.tabViewSetting, userAccess?.fgSLOBdata?.data?.tabViewSettingId))
				setCategory(getValues(dataa.category, userAccess?.fgSLOBdata?.data?.categoryId))
				setBrand(getValues(dataa.brand, userAccess?.fgSLOBdata?.data?.brandId))
			}
			if ((screenOptions === "Inflow"||screendisplayOptions[0]==="Inflow") && userAccess?.inflowdata?.data?.length === undefined) {
				setBussinessGroup(getValues(dataa.businessGroup, userAccess?.inflowdata?.data?.bussinessGroupId))
				setMco(getValues(dataa.mco, userAccess?.inflowdata?.data?.mcoId))
				setCountry(getValues(dataa.country, userAccess?.inflowdata?.data?.countryId))
				setAccess(getValues(dataa.access, userAccess?.data?.accessId))
				setTabViewSettings(getValues(dataa.tabViewSetting, userAccess?.inflowdata?.data?.tabViewSettingId))
				setCategory(getValues(dataa.category, userAccess?.inflowdata?.data?.categoryId))
				setBrand(getValues(dataa.brand, userAccess?.inflowdata?.data?.brandId))

			}
			if ((screenOptions === "POM"||screendisplayOptions[0]==="POM") && userAccess?.pomdata?.data?.length === undefined) {
				setBussinessGroup(getValues(dataa.businessGroup, userAccess?.pomdata?.data?.bussinessGroupId))
				setMco(getValues(dataa.mco, userAccess?.pomdata?.data?.mcoId))
				setCountry(getValues(dataa.country, userAccess?.pomdata?.data?.countryId))
				setAccess(getValues(dataa.access, userAccess?.pomdata?.data?.accessId))
				setTabViewSettings(getValues(dataa.tabViewSetting, userAccess?.pomdata?.data?.tabViewSettingId))
				setCategory(getValues(dataa.category, userAccess?.pomdata?.data?.categoryId))
				setBrand(getValues(dataa.brand, userAccess?.pomdata?.data?.brandId))
			}
    }
    }, [screenOptions, dataa,screendisplayOptions])

    return (
      <>
        <div className="row mb-5">
          <div className="col-md-12">
            <div className="d-block nav-tabs-wrap">
              <div className="pull-left">
                <h3 className="heading mt-2">
                  {user?.userRole === "SuperAdmin"
                    ? "Super Admin Panel to "
                    : user?.userRole === "Admin"
                    ? "Admin Panel to "
                    : "User Panel to "}{" "}
                  Control Settings
                </h3>
              </div>
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey={"settings"}
                className="mb-3"
                onSelect={(k) => {
                  setTabKey(k);
                }}
              >
                <Tab eventKey="settings" title="Settings">
                  <div className="row row-user-selection mb-5">
                    <div className="col-md-12">
                      <div className="filterform_bg d-flex align-items-center justify-content-between">
                        <div>
                          {(userAccess?.fgSLOBdata.data.length === undefined || user.userRole ==="SuperAdmin") && (
                            <div className="d-flex flex-column">
                              <div className="mb-4">
                                <label></label>
                              </div>
                              <AddCustomers
                                key={"AddCustomers"}
                                customerEmail={customerEmail}
                                setIsEditCustomer={setIsEditCustomer}
                                setIsCopyCustomer={setIsCopyCustomer}
                                isEditCustomer={isEditCustomer}
                                isCopyCustomer={isCopyCustomer}
                                setCustomerEmail={setCustomerEmail}
                              />
                              <div className="mb-3"></div>
                              <button
                                type="button"
                                className={`btn-slim btn-blue`}
                                onClick={() => setCustomersPopupOpen(true)}
                              >
                                <img
                                  src={viewIcon}
                                  style={{
                                    height: "18px",
                                    marginRight: "12px",
                                  }}
                                />
                                View Customers
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-access">
                    <div className="col-md-12">
                      <div className="filterform_bg">
                        <h5 className="title">
                          User Role :{"  "}
                          {user.userRole === "SuperAdmin"
                            ? " Super Admin"
                            : user.userRole}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row my-access">
                    <div className="col-md-12">
                      <div className="filterform_bg">
                        <h5 className="title">My Access</h5>
                        <div className=" filterform_bg">
                          <Grid container spacing={2}>
                            <Grid item xs={3}>
                              <label>Tool</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  defaultValue={"select"}
                                  onChange={(event) => {
                                    setScreenOption(event.target.value);
                                  }}
                                >
                                  {user.userRole === "SuperAdmin" && (
                                    <option>All</option>
                                  )}
                                  {user.userRole !== "SuperAdmin" &&
                                    screendisplayOptions?.map((i) => {
                                      return <option key={i}>{i}</option>;
                                    })}
                                </select>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <label>Business Group</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  onChange={()=>''}
                                  value={
                                    bussinessGroup?.length === 1
                                      ? bussinessGroup[0]
                                      : "select"
                                  }
                                >
                                  {user.userRole === "SuperAdmin" && (
                                    <option>All</option>
                                  )}
                                  {user.userRole !== "SuperAdmin" &&
                                    bussinessGroup?.length > 1 && (
                                      <option>Multiple Selections</option>
                                    )}
                                  {user.userRole !== "SuperAdmin" &&
                                    bussinessGroup?.map((i) => {
                                      return (
                                        <option disabled key={i}>
                                          {i}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </Grid>
                            {screenOptions === "FG Slob" && (
                              <Grid item xs={3}>
                                <label>MCO</label>
                                <div>
                                  <select
                                    className="btn-slim w-100"
                                    value={
                                      mco?.length === 1 ? mco[0] : "select"
                                    }
                                    onChange={()=>''}
                                  >
                                    {user.userRole === "SuperAdmin" && (
                                      <option>All</option>
                                    )}
                                    {user.userRole !== "SuperAdmin" &&
                                      mco?.length > 1 && (
                                        <option>Multiple Selections</option>
                                      )}
                                    {user.userRole !== "SuperAdmin" &&
                                      mco?.map((i) => {
                                        return (
                                          <option disabled key={i}>
                                            {i}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </Grid>
                            )}
                            <Grid item xs={3}>
                              <label>Country</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  value={
                                    country?.length === 1
                                      ? country[0]
                                      : "select"
                                  }
                                  onChange={()=>''}
                                >
                                  {user.userRole === "SuperAdmin" && (
                                    <option>All</option>
                                  )}
                                  {user.userRole !== "SuperAdmin" &&
                                    country?.length > 1 && (
                                      <option>Multiple Selections</option>
                                    )}
                                  {user.userRole !== "SuperAdmin" &&
                                    country?.map((i) => {
                                      return (
                                        <option disabled key={i}>
                                          {i}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <label>Category</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  value={
                                    category?.length === 1
                                      ? category[0]
                                      : "select"
                                  }
                                  onChange={()=>''}
                                >
                                  {user.userRole === "SuperAdmin" && (
                                    <option>All</option>
                                  )}
                                  {user.userRole !== "SuperAdmin" &&
                                    category?.length > 1 && (
                                      <option>Multiple Selections</option>
                                    )}
                                  {user.userRole !== "SuperAdmin" &&
                                    category?.map((i) => {
                                      return (
                                        <option disabled key={i}>
                                          {i}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </Grid>
                            {screenOptions !== "POM" && (
                              <Grid item xs={3}>
                                <label>Brand</label>
                                <div>
                                  <select
                                    className="btn-slim w-100"
                                    value={
                                      brand?.length === 1 ? brand[0] : "select"
                                    }
                                    onChange={()=>''}
                                  >
                                    {user.userRole === "SuperAdmin" && (
                                      <option>All</option>
                                    )}
                                    {user.userRole !== "SuperAdmin" &&
                                      brand?.length > 1 && (
                                        <option>Multiple Selections</option>
                                      )}
                                    {user.userRole !== "SuperAdmin" &&
                                      brand?.map((i) => {
                                        return (
                                          <option disabled key={i}>
                                            {i}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </Grid>
                            )}
                            <Grid item xs={3}>
                              <label>Access</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  onChange={(event) => {
                                    console.log(event.target.value);
                                  }}
                                >
                                  <option key={userAccess?.data[0].accessId}>
                                    {screenOptions==="FG Slob"
                                    ?
                                    userAccess?.fgSLOBdata.data.accessId==="1"?"Read":"Write"
                                    :screenOptions==="Inflow"?userAccess?.inflowdata.data.accessId==="1"?"Read":"Write"
                                    :userAccess?.pomdata.data.accessId==="1"?"Read":"Write"}
                                    {/* {userAccess?.data[0].accessId === 1
                                      ? "Read"
                                      : "Write"} */}
                                  </option>
                                </select>
                              </div>
                            </Grid>
                            <Grid item xs={3}>
                              <label>Tab View Settings</label>
                              <div>
                                <select
                                  className="btn-slim w-100"
                                  value={
                                    tabViewSettings?.length === 1
                                      ? tabViewSettings[0]
                                      : "select"
                                  }
                                  onChange={()=>''}
                                >
                                  {user.userRole === "SuperAdmin" && (
                                    <option>All</option>
                                  )}
                                  {user.userRole !== "SuperAdmin" &&
                                    tabViewSettings?.length > 1 && (
                                      <option>Multiple Selections</option>
                                    )}
                                  {user.userRole !== "SuperAdmin" &&
                                    tabViewSettings?.map((i) => {
                                      return (
                                        <option disabled key={i}>
                                          {i}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                {user.userRole !== "User" && (
                  <Tab eventKey="Admin Settings" title="Admin Settings">
                    <AdminSettings className="h-100" reload={props.reload} />
                  </Tab>
                )}
                {user.userRole !== "User" && (
                  <Tab eventKey="Notifications" title="Notifications">
                    <NotificationSettings />
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
        {isCustomersPopupOpen && (
          <ViewCustomers
            isCustomersPopupOpen={isCustomersPopupOpen}
            key={`customers-popup-opened-${isCustomersPopupOpen}`}
            handleCustomerPopupOpen={handleCustomerPopupOpen}
            editIndCustomer={(val) => {
              if (val) {
                setCustomersPopupOpen(false);
              }
              setIsEditCustomer(true);
              editIndCustomer(val);
            }}
            isEditCustomer={isEditCustomer}
            copyIndCustomer={(val) => {
              if (val) {
                setCustomersPopupOpen(false);
              }
              setIsCopyCustomer(true);
              editIndCustomer(val);
            }}
          />
        )}
      </>
    );
}

export default UserPanel;