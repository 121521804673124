import React, { useState, useRef, useCallback, useEffect } from "react";
import { Button } from "react-bootstrap";

import Select, { components, defaultTheme } from "react-select";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#1E232F",
    color: "#fff",
    border: "1px solid #fff",
    borderRadius: "0.25rem",
    fontSize: "11px",
  }),

  control: (base, state) => ({
    ...base,
    background: state.isFocused ? "#1E232F" : "#1E232F",
    color: state.isFocused ? "#fff" : "#fff",
    cursor: state.isDisabled ? "not-allowed" : "default",
    fontSize: "11px",
    border: "1px solid #fff",
    "&:hover": {
      borderColor: state.isFocused ? "#fff" : "#fff",
    },
  }),

  // For the options
  /* option: (styles, { isDisabled }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      //backgroundColor: isDisabled ? 'red' : 'blue',
      //color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  }, */
};


const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;

    //remove one count, if `select all` is checked
    const seletedValues = props?.selectProps?.value;
    const isSelectAll = seletedValues.find((item) => item.value === "*");

    const { length } = values;
    if (length === 1) {
      values = `${val(0)}`;
    } else {
      /* if (props.selectProps.name === "custom_column_filter") {
        values = "All";
      } else {
        values = `${isSelectAll ? length - 1 : length} selected`;
      } */
      // if(props.selectProps.name === "custom_column_filter")
      //  values = `${isSelectAll ? length - 1 : length} selected`;
      //  else
      values = `${
        seletedValues?.length === props.options?.length
          ? "All"
          : `Multiple selections`
      }`;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {/* {values?.key !== "placeholder" ? (
        <div className="tooltipValue">{values}</div>
      ) : (
        ""
      )} */}

      <div className="svalue"> {values}</div>
      <div className="sinput"> {input}</div>
    </components.ValueContainer>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div className="custom_checkbox">
          {props.label}
          <input
            type="checkbox"
            className=""
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
        </div>
      </components.Option>
    </>
  );
};

// const removeSelection = (e, value) => {
//   e.stopPropagation();
//   console.log("valllll",value);
//   value.length = 0;
//  // isOpen = false;
//   console.log("valllll",value.length);
//   console.log("remove checked options");
// }

const RadioOption = (props) => {
  return (
    <>
      <components.Option {...props}>
        <div className="custom_radio">
          {props.label}
          <input
            type="radio"
            name={props.name}
            className=""
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
        </div>
      </components.Option>
    </>
  );
};
const ULDropdown = ({
  placeholder,
  className,
  name,
  options,
  value,
  onChange,
  onMenuOpen,
  onMenuClose,
  isMulti,
  isClearable,
  removeSelection,
  dropDownInModal,
  isLoading,
  isMyAccess,popup,
  closeDD,
  handleDDOpen
}) => {
  /* if (name === "business_group") {
    console.log("value: ", value);
  } */
  const [inputValue, setInputValue] = useState("");
  const [currentlyOpenedDD, setCurrentlyOpenedDD] = useState("");
  const [menuIsOpen, setMenuIsOpen] = useState("");
  const onInputChange = (str, { action }) => {
    if (action === "set-value") return false;
    setInputValue(str);
  };

  let orderedNewAllOptions;

  (() => {
    if (
      name === "country" ||
      name === "retailer" ||
      name === "brand" ||
      name === "spfDesc"
    ) {
      // const selectAllObj = options.filter((option) => option.value === "all");

      // const nonSelectedOptions = options.filter(
      //   (option) => !value.find((op) => op === option.value)
      // );

      // const selectedOptions = options.filter((option) =>
      //   value.find((op) => op === option.value)
      // );

      // orderedNewAllOptions = selectAllObj.concat(
      //   selectedOptions
      //     .concat(nonSelectedOptions)
      //     .filter((item) => item.value !== "all")
      // );
      orderedNewAllOptions = options;
    } else {
      orderedNewAllOptions = options;
    }
  })();
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  // React.useEffect(() => {
  //   if (currentIndex !== name) setIsOpen(false);
  // }, [currentIndex, name]);'

  // useEffect(() => {
  //   setIsOpen(closeDD);
  // },[closeDD ? !closeDD : ''])


  useEffect(() => {
    window.addEventListener("click", function (e) {
      if(!isMyAccess && !popup){
      if (document.getElementById(name)?.contains(e.target)) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    });
    return window.removeEventListener("click", () => {});
  }, [isOpen]);

  return (
    <>
      <div className={`ddn ${isOpen ? "isOpen" : ""} `}>
        <Dropdown
          isOpen={isOpen}
          onClose={toggleOpen}
          setIsOpen={setIsOpen}
          name={name}
          
          target={
            <Button
              type="button"
              id={`dd_btn_${name}`}
              onClick={(e) => {
                if(!isMyAccess){
                toggleOpen();

                if (isOpen)
                  setTimeout(() => {
                    toggleOpen();
                  }, 140);
                }

                if(handleDDOpen) handleDDOpen(name)
              }}
              className={`btn btn-dropdown btn-sm text-left`}
              // title={
              //   value
              //     ? value?.filter(i=>i!=='all')?.length == 1 && options?.length
              //       ? (`${options?.filter((i) => i.value === value?.filter(i=>i!=='all')[0])[0]?.label}` || options[0].label)
              //       : value?.length - 1 === options?.length
              //       ? "All"
              //       : "Multiple Selection"
              //     : "All"
              // }
            >
              {" "}
              <span className={`text ${!value.length ? "text-grey" : ""}`}>
                {value
                  ? value?.filter((i) => i !== "all")?.length == 1 &&
                    options?.length
                    ? `${
                        options.filter(
                          (i) =>
                            i.value === value?.filter((i) => i !== "all")[0]
                        )[0]?.label
                      }` || options[0].label
                    : !value.length?"Select":
                     value?.length - 1 ===
                        (name === "custom_column_filter"
                          ? options.length - 1
                          : options.length) 
                    ? "All"
                    : "Multiple Selection"
                  : "All"}
              </span>
              <span className="d-flex">
                {value.length > 0 && name !== "custom_column_filter" && !isMyAccess && (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelection(name, []);
                    }}
                    title="Clear checked selection"
                    className="btn-slim-square btn-selection-remove"
                  >
                    <svg
                      className="svg-icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M961.535399 1023.99936c-16.38399 0-31.74398-6.143996-44.031972-18.431988L18.431988 106.495933c-24.575985-24.575985-24.575985-63.48796 0-88.063945s63.48796-24.575985 88.063945 0l899.071439 899.071439a61.823961 61.823961 0 0 1 0 88.063945 61.215962 61.215962 0 0 1-44.031973 18.431988zM62.463961 1023.99936c-16.38399 0-31.74398-6.143996-44.031973-18.431988a61.823961 61.823961 0 0 1 0-88.063945L917.503427 18.431988c24.575985-24.575985 63.48796-24.575985 88.063945 0s24.575985 63.48796 0 88.063945L106.495933 1005.567372A61.215962 61.215962 0 0 1 62.463961 1023.99936z"
                        fill="white"
                      />
                    </svg>
                  </span>
                )}
                <span className="btn-slim-square" title="Open dropdown" onClick={()=>{
                  if(handleDDOpen) handleDDOpen(name)
                }}>
                  <svg
                    data-name="Caret Down"
                    fill="white"
                    id="Layer_2"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs></defs>
                    <title />
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                  </svg>
                </span>
              </span>
            </Button>
          }
        >
          <Select
            autoFocus
            isOptionDisabled={(option) => isLoading || option?.sticky}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            menuIsOpen={!isMyAccess}
            
            onBlur={(d)=> {
              if(dropDownInModal){
                if(d !== null || d.relatedTarget !== null || d.relatedTarget.id !== null){
                  if(!d.relatedTarget.id.includes(name)) toggleOpen();
                }
              }
              //document.querySelector(`.row-user-settings #${name} button`).click();
            }}

            // onMenuOpen={()=>setMenuIsOpen(true)}
            // onMenuClose={()=>setMenuIsOpen(false)}
            tabSelectsValue={false}
            value={(isMulti &&
            name !== "custom_column_filter" &&
            options.length > 1
              ? [{ label: "Select all", value: "all" }].concat(options)
              : options
            ).filter((item) => value.indexOf(item.value) !== -1)}
            name={name}
            onChange={onChange}
            options={
              isMulti && name !== "custom_column_filter" && options.length > 1
                ? [{ label: "Select all", value: "all" }].concat(
                    orderedNewAllOptions
                  )
                : orderedNewAllOptions
            }
            isMulti={isMulti}
            hideSelectedOptions={false}
            closeMenuOnSelect={isMulti ? false : true}
            styles={customStyles}
            placeholder={"Search"}
            isClearable={false}
            className={className}
            blurInputOnSelect={false} //set by default, but to be sure
            onInputChange={onInputChange}
            inputValue={inputValue}
            classNamePrefix="upose-select"
            components={
              ((name === "bg" || name === "optimization_type") && {
                DropdownIndicator,
                ValueContainer,
                Option: (optionProps, index) => (
                  <RadioOption index={index} {...optionProps} name={name} />
                ),
              }) || {
                DropdownIndicator,
                ValueContainer,
                Option,
              }
            }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#1E232F",
                primary: "#1E232F",
              },
            })}
          />
        </Dropdown>
      </div>
    </>
  );
};

// styled components

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      className="ddEl" 
      css={{
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = (props) => (
  <div
    className="ddEl" 
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose, name }) => (
  <div css={{ position: "relative" }} id={name}>
    <div>{target}</div>
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => {
  const { colors } = defaultTheme;
  return (
    <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
      <Svg>
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="#999999"
          fillRule="evenodd"
        />
      </Svg>
    </div>
  );
};
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);

export default ULDropdown;
