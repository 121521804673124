import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import Grid from "@material-ui/core/Grid";
import axiosService from "../../../utils/apiService";
import apiEndPoints from "../../../utils/apiEndPoints";
import ULDropdown from "../../../Common/Dropdown";
import Loadersml from "../../../Common/loadersml";
import { useLocation } from "react-router-dom";
import { UserAccessContext, UserContext } from "../../../Components/Application";
const Multiselect = (props) => {
  const location = useLocation();
  const user=useContext(UserContext)
  const userAccess = useContext(UserAccessContext)
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownInfo, setDropDownInfo] = useState([]);
  const [selection, setSelection] = useState({ toolId: ["1"] });
  const [closeDD, setCloseDD] = useState({});
  // const [ds, setDs] = useState();
  // useEffect(() => {
  //   getDropDownsData();
  // }, []);

  

  useEffect(() => {
    console.log("props.closeDD >>>> ",props.closeDD);
  },[closeDD]);

  useEffect(() => {
    if(props.myAccessInfo?.tool?.length){
      console.log("asdfghjk",props.myAccessInfo)
    let keyObj = {
      tool: "tool",
      businessGroup: "businessGroup",
      mco: "mco",
      country: "country",
      access: "access",
      tabViewSettings: "tabViewSettings",
      brand:"brand",
      category:"category"
    };
    if (props.myAccessInfo && Object.keys(props.myAccessInfo)?.length) {
      let selectionData = { ...selection };
      Object.keys(props.myAccessInfo).forEach((key) => {
        selectionData[keyObj[key]] = props.myAccessInfo[key] ?? [];
      });
      console.log(selectionData)
      getDropDownsData(selectionData)
      setSelection({ ...selectionData });
    }}
    else if(!Object.keys(props.myAccessInfo)?.length){
      getDropDownsData();
    }
  }, [props.myAccessInfo?.tool?.length]);

  useEffect(() => {
    // getDropDownsData();
    console.log("asdfghjk",selection)
  }, [selection]);

  const getDropDownsData = async (data) => {
    console.log("dropdownData",data,dropdownInfo?.tool,data?.tool,data?.tool?.[0],data?.tool[0]==='1')
    console.log(data)
    setIsLoading(true);
    props.onSettingsDDLoading(true)
    let tooldata
    if(!props.isEdit){
    if(!dropdownInfo.tool){
      data.tool = userAccess.fgSLOBdata.data.length ===undefined?["1"]:userAccess.inflowdata.data.length ===undefined?["2"]:["3"]
      console.log("dropdownData",data.tool)
    }
    }
    let obj =
      data?.tool?.[0] == 3
        ? {
            toolId: data?.tool?.length > 0 ? data?.tool : [1],
            businessGroupId: data?.businessGroup ?? [],
            countryId: data?.country ?? [],
            businessUnitId: data?.businessUnit ?? [],
            accessId: data?.access ?? [],
            clusterId: data?.cluster ?? [],
            tabViewSettingId: data?.tabViewSettings ?? [],
            pageSettingId: data?.pageSettings ?? [],
            categoryId:data?.category??[],
            brandId:data?.brand ?? [],
          }
        : { 
            toolId:data?.tool?.length > 0 ? data?.tool : [1],
            businessGroupId: data?.businessGroup ?? [],
            countryId: data?.country ?? [],
            businessUnitId: data?.businessUnit ?? [],
            accessId: data?.access ?? [],
            clusterId: data?.cluster ?? [],
            mcoId: data?.mco ?? [],
            tabViewSettingId: data?.tabViewSettings ?? [],
            pageSettingId: data?.pageSettings ?? [],
            brandId:data?.brand ?? [],
            categoryId:data?.category??[]
          };
    const resolt = await axiosService.post(
      data?.tool?.[0] == 3
        ?  apiEndPoints.pomGlobalFilters 
        : data?.tool?.[0] == 2 
          ? apiEndPoints.inflowGlobalFilters
          : apiEndPoints.globalFilters,
      obj
    );
    const usersettingResult = await axiosService.post(apiEndPoints.userManagementFilters,obj)
    setIsLoading(false);
    props.onSettingsDDLoading(false);
    let userSettingsFilterData = {...resolt?.data?.data, tool:usersettingResult.data.data.tool,tabViewSetting
      :  usersettingResult.data.data.tabViewSetting,access:usersettingResult.data.data.access  }

    if(data?.tool[0] ==='1'){
      userSettingsFilterData={...userSettingsFilterData,
        brand:resolt?.data?.data.brand?.filter(i=>userAccess?.fgSLOBdata.data.brandId?.includes(i.key)),
        category:resolt?.data?.data.category?.filter(i=>userAccess?.fgSLOBdata.data.categoryId?.includes(i.key)),
        businessGroup:resolt?.data?.data.businessGroup.filter(i=>userAccess?.fgSLOBdata.data.bussinessGroupId?.includes(i.key)),
        mco:resolt?.data?.data?.mco?.filter(i=>userAccess?.fgSLOBdata.data.mcoId?.includes(i.key)),
        country:resolt?.data?.data.country?.filter(i=>userAccess?.fgSLOBdata.data.countryId?.includes(i.key)),
        tabViewSetting:usersettingResult.data.data.tabViewSetting.filter(i=>userAccess?.fgSLOBdata.data.tabViewSettingId?.includes(i.key))
      }
      console.log("permissionFilter",userSettingsFilterData)
    }
    if(data?.tool[0] ==='2'){
      userSettingsFilterData={...userSettingsFilterData,
        brand:resolt?.data?.data.brand?.filter(i=>userAccess?.inflowdata.data.brandId?.includes(i.key)),
        category:resolt?.data?.data.category?.filter(i=>userAccess?.inflowdata.data.categoryId?.includes(i.key)),
        businessGroup:resolt?.data?.data.businessGroup.filter(i=>userAccess?.inflowdata.data.bussinessGroupId?.includes(i.key)),
        mco:resolt?.data?.data?.mco?.filter(i=>userAccess?.inflowdata.data.mcoId?.includes(i.key)),
        country:resolt?.data?.data.country?.filter(i=>userAccess?.inflowdata.data.countryId?.includes(i.key)),
        tabViewSetting:usersettingResult.data.data.tabViewSetting.filter(i=>userAccess?.inflowdata.data.tabViewSettingId?.includes(i.key))
      }
    }
    if(data?.tool[0] ==='3'){
      userSettingsFilterData={...userSettingsFilterData,
        brand:resolt?.data?.data.brand?.filter(i=>userAccess?.pomdata.data.brandId?.includes(i.key)),
        category:resolt?.data?.data.category?.filter(i=>userAccess?.pomdata.data.categoryId?.includes(i.key)),
        businessGroup:resolt?.data?.data.businessGroup.filter(i=>userAccess?.pomdata.data.bussinessGroupId?.includes(i.key)),
        mco:resolt?.data?.data?.mco?.filter(i=>userAccess?.pomdata.data?.mcoId?.includes(i.key)),
        country:resolt?.data?.data.country?.filter(i=>userAccess?.pomdata.data.countryId?.includes(i.key)),
        tabViewSetting:usersettingResult.data.data.tabViewSetting.filter(i=>userAccess?.pomdata.data.tabViewSettingId?.includes(i.key))
      }
    }
    setDropDownInfo(userSettingsFilterData);
    console.log("dropdownData", userSettingsFilterData)
    // if()
    props.setDdlInfo?.(userSettingsFilterData);
    props.setFilterOptions && props.setFilterOptions?.(userSettingsFilterData);
  };
  useEffect(() => {
    if (props.ddlDisable && dropdownInfo?.tool?.length&& user.userRole === "SuperAdmin") {
      let keyObj = {
        tool: "tool",
        businessGroup: "businessGroup",
        mco: "mco",
        country: "country",
        access: "access",
        tabViewSetting: "tabViewSettings",
        brand: "brand",
        category: "category",
        pageSetting: "pageSetting",
      };
      if (dropdownInfo && Object.keys(dropdownInfo)?.length) {
        let selectionData = { ...selection };
        Object.keys(dropdownInfo).forEach((key) => {
          selectionData[keyObj[key]] = dropdownInfo[key]
            ? dropdownInfo[key].map((i) => i.key)
            : [];
          if (keyObj[key] == "access") {
            selectionData[keyObj[key]] = [2];
          }
        });
        setSelection({ ...selectionData });
        props.setDropDownValues?.(getRemoveAll({ ...selectionData }));
      }
    }
  }, [props.ddlDisable, dropdownInfo]);

  const getRemoveAll = (selectedFilterData) => {
    console.log(selectedFilterData)
    //console.log(selectedFilterData)

    for (var key in selectedFilterData) {
      console.log(selectedFilterData,key,selectedFilterData[key].length)
      if(Array.isArray(selectedFilterData[key])){
        selectedFilterData[key] = selectedFilterData[key]?.filter(
          (i) => i !== "all"
        );
      }
      
      
    }
    return selectedFilterData;
  };

  
  // const qqq = (a) => {
  //   //setAaa([...aaa, a]);
  //   console.log("bbbc children",a);
  //   var ree = ["category","mco","country","brand"];
  //   ree.map(h => {
  //     if (ree.includes(a)) {
  //       setIsOpen(true);
  //     } else {
  //       setIsOpen(false);
  //     }
  //   })
  // }


  useEffect(()=>{
    handleDDOpen()
  },[props.onCloseDropDown])

  const handleDDOpen = (val = null) => {
    setCloseDD({
      key : Math.random(1000),
      el : {
        name : val
      }
    });
  }

  const handleChange = (name, options = null) => (selected, action) => {
      console.log("checkname",name,options,selected,action)
      let selectedFilterData;
      let selectedFilterData_for_customers;
      if (action?.option?.value === "all") {
        //Not use now
        if (action.action === "select-option") {
          selectedFilterData = {
            ...selection,
            [name]: [...options.map((item) => item.value), "all"],
          };
          setSelection(selectedFilterData);
          
          props.setDropDownValues?.(getRemoveAll({ ...selectedFilterData }));
          if (!props.isCustomer) {
            getDropDownsData(getRemoveAll({ ...selectedFilterData }));
          }

          selectedFilterData_for_customers = {
            ...selection,
            [name]: [
              ...options.map((item) => ({ id: item.value, Name: item.label })),
              "all",
            ],
          };
          setTimeout(() => {
            onHandleAnyChange({ current: name, data : selectedFilterData_for_customers})
          }, 300)
        } else {
          //deselect-option
          selectedFilterData = {
            ...selection,
            [name]: [],
          };

          selectedFilterData_for_customers = {
            ...selection,
            [name]: [],
          };

          setSelection(selectedFilterData);
          setTimeout(() => {
            onHandleAnyChange({ current: name, data : selectedFilterData_for_customers});
          }, 300);
          props.setDropDownValues?.(getRemoveAll({ ...selectedFilterData }));
          if (!props.isCustomer) {
            getDropDownsData(getRemoveAll({ ...selectedFilterData }));
          }
        }
      } else {
        selectedFilterData = {
          ...selection,
          [name]:
            action.action !== "select-option"
              ? selected?.map((item) => item.value).filter((i) => i !== "all")
              : selected?.length === options.length
              ? ["all"].concat(selected?.map((item) => item.value))
              : selected?.map((item) => item.value),
        };

        selectedFilterData_for_customers = {
          ...selection,
          [name]:
            action.action !== "select-option"
              ? selected
                  ?.map((item) => ({ id: item.value, Name: item.label }))
                  .filter((i) => i !== "all")
              : selected?.length === options.length
              ? ["all"].concat(
                  selected?.map((item) => ({
                    id: item.value,
                    Name: item.label,
                  }))
                )
              : selected?.map((item) => ({ id: item.value, Name: item.label })),
        };

        setSelection(selectedFilterData);
        setTimeout(() => {
          onHandleAnyChange({ current: name, data : selectedFilterData_for_customers})
        }, 300);
        props.setDropDownValues?.(getRemoveAll({ ...selectedFilterData }));
        if (!props.isCustomer) {
          getDropDownsData(getRemoveAll({ ...selectedFilterData }));
        }
      }
    };

  const removeSelection = (name, values) => {
    const selectedFilterData = {
      ...selection,
      [name]: [],
    };
    setSelection(selectedFilterData);
    props.setDropDownValues?.(getRemoveAll({ ...selectedFilterData }));
    getDropDownsData(getRemoveAll({ ...selectedFilterData }));
  };
  const businessGroup = _.has(dropdownInfo, "businessGroup")
    ? dropdownInfo["businessGroup"]
        .map((item) => ({
          value: item.key,
          label: item.value,
        }))
        .filter((element) =>
          location.pathname.includes("pom")
            ? element.label !== "POSM" && element.label !== "NA"
            : element
        )
    : [];

  const mco = _.has(dropdownInfo, "mco")
    ? dropdownInfo["mco"]?.map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];

  const country = _.has(dropdownInfo, "country")
    ? dropdownInfo["country"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];
    const category = _.has(dropdownInfo, "category")
    ? dropdownInfo["category"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];
    const brand = _.has(dropdownInfo, "brand")
    ? dropdownInfo["brand"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];

  const access = _.has(dropdownInfo, "access")
    ? dropdownInfo["access"]
        .map((item) => ({
          value: item.key,
          label: item.value,
        }))
        .filter((element) => element.value !== null)
    : [];

  const tabViewSettings = _.has(dropdownInfo, "tabViewSetting")
    ? dropdownInfo["tabViewSetting"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];

  const pageSettings = _.has(dropdownInfo, "pageSetting")
    ? dropdownInfo["pageSetting"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];

  const tool = _.has(dropdownInfo, "tool")
    ? dropdownInfo["tool"].map((item) => ({
        value: item.key,
        label: item.value,
      }))
    : [];

  const getValue = (selection, values) => {
    let newSelection = [];
    console.log("dropdownData",selection)
    console.log("dropdownData",values)
    selection.filter((val) => {
      values.forEach((value) => {
        if (value.value == val) {
          newSelection.push(val);
        }
      });
    });
    // console.log(newSelection.length === values.length
    //   ? ["all"].concat(newSelection)
    //   : newSelection)
    return newSelection?.length === values?.length
      ? ["all"].concat(newSelection)
      : newSelection;
  };

  const getOptions = (ddlInfo, prop) => {
    return _.has(ddlInfo, prop)
      ? _.uniq(ddlInfo[prop])
          .filter((item)=>{
            if(prop === "tool"){
              if(item.value==="FG SLOB" && userAccess.fgSLOBdata.data.length===undefined){
                return true
              }else if(item.value==="INFLOW" && userAccess.inflowdata.data.length===undefined){
                return true
              }else if(item.value==="POM" && userAccess.pomdata.data.length===undefined){
                return true
              }else{
                return false
              }
            }
            else{ return true}
          })
          .map((item) => ({
            value: item.key,
            label: item.value,
          }))
          .filter((element) => {
            return (
              element.value !== null &&
              element.value !== "" &&
              element.label !== null &&
              element.label !== ""
            );
          })
      : [];
  };

  // useEffect(()=>{
  //   if(ds != undefined || ds != null || ds != ""){
  //     console.log("selection-----------",ds);
  //     props.onMultiSelectChange("ooo",ds);
  //   }
  // },[ds?.data]);

  const onHandleAnyChange = (obj) => {
    console.log("selection-----------",obj);
    props.onMultiSelectChange(obj);
  }

  return (
    <>
      {props.isSettings && (
        <div className="row filterform_bg ddn-fluid pt-0">
          <div className="">
          <Grid container spacing={2}>
            <Grid item xs={3}>
                <label>
                  Tool {isLoading && <Loadersml />}
                </label>

                <div className={`ddn`}>
                {user.userRole === "SuperAdmin" && props.isMyAccess && (
                  <ULDropdown
                    isMyAccess={props.isMyAccess || props.ddlDisable}
                    isUserManagementSelection
                    name="tool"
                    value={getValue(
                      dropdownInfo["tool"]?.map((i) => i.key) ?? [],
                      tool
                    )}
                    options={getOptions(dropdownInfo, "tool")}
                    onChange={handleChange("tool", tool)}
                    
                    placeholder={"select"}
                    isMulti={false}
                     className="btn-slim w-100"
                    // className="react-select-container w-100"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                    isNoAll
                    dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                  />
                )}
                {(!props.isMyAccess || user.userRole !== "SuperAdmin") && (
                    <select
                      className="btn-slim w-100"
                      value={selection?.tool ? selection?.tool : ""}
                      onMouseUp={handleDDOpen}
                      defaultValue={userAccess.fgSLOBdata.data.length ===undefined?'1':userAccess.inflowdata.data.length ===undefined?'2':'3'}
                      onChange={(event) => {
                      

                        let selectedFilterData = {
                          ...selection,
                          ["tool"]: [event.target.value],
                          ["businessGroup"]:[],
                          ["mco"]:[],
                          ["country"]:[],
                          ["tabViewSettings"]: [],
                          ["category"]:[],
                          ["brand"]:[]
                        };
                        setSelection(selectedFilterData);
                        props.setDropDownValues?.(
                          getRemoveAll({ ...selectedFilterData })
                        );
                        getDropDownsData(
                          getRemoveAll({ ...selectedFilterData })
                        );
                        //props.toolReset();
                      }}
                    >
                      {props.isMyAccess || props.ddlDisable && <option>
                        All
                        </option>}
                        {console.log(dropdownInfo,props.isMyAccess,userAccess,"dropdownInfo")}
                        
                      {getOptions(dropdownInfo, "tool").map((opt, i) => (
                        <option
                          key={i}
                          value={opt.value}
                          disabled={props.isMyAccess || props.ddlDisable}
                        >
                          {opt.label}
                        </option>
                      ))}
                    </select>
                  
                )}
                </div>
              </Grid>

              <Grid item xs={3}>
                
                  <label>
                    Business Group {isLoading && <Loadersml />}
                  </label>

                <div className="ddn">

                <ULDropdown
                  isMyAccess={props.isMyAccess || props.ddlDisable}
                  isUserManagementSelection
                  name="businessGroup"
                  value={getValue(
                    user.userRole === "SuperAdmin" && props.isMyAccess
                      ? dropdownInfo["businessGroup"]?.map((i) => i.key) ?? []
                      : selection?.businessGroup
                      ? selection?.businessGroup
                      : [],
                    businessGroup
                  )}
                  options={getOptions(dropdownInfo, "businessGroup").filter(
                    (element) =>
                      location.pathname.includes("pom")
                        ? element.label !== "POSM" && element.label !== "NA"
                        : element
                  )}
                  onChange={handleChange("businessGroup", businessGroup)}
                  isMulti={true}
                  placeholder={"Select"}
                  className="btn-slim w-100"
                  removeSelection={removeSelection}
                  isLoading={isLoading}
                  isOptionsDisabled={!selection.tool?.length}
                  closeDD={ closeDD?.el?.name !== 'businessGroup' ? false : true}
                  handleDDOpen={handleDDOpen}
                  dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                />
                {(props.submitCount > 0 || props.userAddError) && 
                  <div className="error-mssg">
                      {/* {props.errorMssg.a_bg} */}
                      {(props.errorMssg != undefined && props.errorMssg.a_bg != undefined) && props.errorMssg.a_bg.message}
                    </div>
                  }
                </div>
              </Grid>

              {(!selection.tool?.length ||
                (selection.tool?.length && selection.tool[0] != "3"&& selection.tool[0] != "2")) && (
                <Grid item xs={3}>
                  
                    <label>
                      MCO {isLoading && <Loadersml />}
                    </label>
                  <div>

                  <ULDropdown
                    isMyAccess={props.isMyAccess || props.ddlDisable}
                    isUserManagementSelection
                    name="mco"
                    value={getValue(
                      user.userRole === "SuperAdmin" && props.isMyAccess
                        ? dropdownInfo["mco"]?.map((i) => i.key) ?? []
                        : selection?.mco
                        ? selection?.mco
                        : [],
                      mco
                    )}
                    options={getOptions(dropdownInfo, "mco")}
                    onChange={handleChange("mco", mco)}
                    isMulti={true}
                    placeholder={"All"}
                    className="btn-slim w-100"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                    isOptionsDisabled={!selection.tool?.length}
                    dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                    // closeDD={ closeDD?.el?.name !== 'mco' ? false : true}
                    // handleDDOpen={handleDDOpen}
                  />
                  {(props.submitCount > 0 || props.userAddError) &&  
                  <div className="error-mssg">
                    {(props.errorMssg != undefined && props.errorMssg.a_mco != undefined) && props.errorMssg.a_mco.message}
                  </div>
                  }
                </div>
                </Grid>
              )}

              <Grid item xs={3}>
                
                  <label>
                    Country {isLoading && <Loadersml />}
                  </label>
                <div>

                <ULDropdown
                  isMyAccess={props.isMyAccess || props.ddlDisable}
                  isUserManagementSelection
                  name="country"
                  value={getValue(
                    user.userRole === "SuperAdmin" && props.isMyAccess
                      ? dropdownInfo["country"]?.map((i) => i.key) ?? []
                      : selection?.country
                      ? selection?.country
                      : [],
                    country
                  )}
                  options={getOptions(dropdownInfo, "country")}
                  onChange={handleChange("country", country)}
                  isMulti={true}
                  placeholder={"All"}
                  className="btn-slim w-100"
                  removeSelection={removeSelection}
                  isLoading={isLoading}
                  isOptionsDisabled={!selection.tool?.length}
                  dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                  // closeDD={closeDD?.el?.name !== 'country' ? "false" : ""}
                  // closeDD={ closeDD?.el?.name !== 'country' ? false : true}
                  // handleDDOpen={handleDDOpen}
                />
                {(props.submitCount > 0 || props.userAddError) &&  
                <div className="error-mssg">
                  {(props.errorMssg != undefined && props.errorMssg.a_country != undefined) && props.errorMssg.a_country.message}
                </div>
                }
                </div>
              </Grid>
              <Grid item xs={3}>
                
                  <label>
                    Category {isLoading && <Loadersml />}
                  </label>
                <div>

                <ULDropdown
                  isMyAccess={props.isMyAccess || props.ddlDisable}
                  isUserManagementSelection
                  name="category"
                  value={getValue(
                    selection?.category ? selection?.category : [],
                    category
                  )}
                  options={getOptions(dropdownInfo, "category")}
                  onChange={handleChange("category", category)}
                  isMulti={true}
                  placeholder={"All"}
                  className="btn-slim w-100"
                  removeSelection={removeSelection}
                  isLoading={isLoading}
                  isOptionsDisabled={!selection.tool?.length}
                  title={getValue(
                    selection?.category ? selection?.category : [],
                    category
                  )}
                  dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                  // closeDD={closeDD?.el?.name !== 'category' ? "false" : ""}
                  // closeDD={ closeDD?.el?.name !== 'category' ? false : true}
                  // handleDDOpen={handleDDOpen}
                />
                {(props.submitCount > 0 || props.userAddError) &&  
                <div className="error-mssg">
                  {props.errorMssg != undefined &&props.errorMssg.a_category != undefined && props.errorMssg.a_category.message}
                </div>
                }
                </div>
              </Grid>
              {/* //selection?.tool &&selection?.tool[0]!=3 && */}
              {
               <Grid item xs={3}>
                
                  <label>
                    Brand {isLoading && <Loadersml />}
                  </label>
                <div>

                <ULDropdown
                  isMyAccess={props.isMyAccess || props.ddlDisable}
                  isUserManagementSelection
                  name="brand"
                  value={getValue(
                    selection?.brand ? selection?.brand : [],
                    brand
                  )}
                  options={getOptions(dropdownInfo, "brand")}
                  onChange={handleChange("brand", brand)}
                  isMulti={true}
                  placeholder={"All"}
                  className="btn-slim w-100"
                  removeSelection={removeSelection}
                  isLoading={isLoading}
                  isOptionsDisabled={!selection.tool?.length}
                  title={getValue(
                    selection?.brand ? selection?.brand : [],
                    country
                  )}
                  
                  // closeDD={closeDD?.el?.name !== 'brand' ? "false" : ""}
                  closeDD={ closeDD?.el?.name !== 'brand' ? false : true}
                  handleDDOpen={handleDDOpen}
                  dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                />
                {(props.submitCount > 0 || props.userAddError) &&  
                <div className="error-mssg">
                  {props.errorMssg && props.errorMssg.a_brand != undefined && props.errorMssg.a_brand.message}
                </div>
                }
                </div>
              </Grid>}

              {false &&<Grid item xs={3}>
                <label className="d-flex">
                  Access {isLoading && <Loadersml />}
                </label>
                
                {/* <ULDropdown
                    isMyAccess={props.isMyAccess || props.ddlDisable}
                    isUserManagementSelection
                    name="access"
                    value={getValue(
                      selection?.access ? selection?.access : [],
                      access
                    )}
                    options={getOptions(dropdownInfo, "access")}
                    onChange={handleChange("access", access)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                    isOptionsDisabled={!selection.tool?.length}
                  /> */}
                <div className="ddn">
                  <select
                    className="btn-slim w-100"
                    value={
                      user.userRole === "SuperAdmin" && props.isMyAccess
                        ? "2"
                        : selection?.access
                        ? selection?.access[0]
                        : ""
                    }
                    onMouseUp={handleDDOpen}
                    onChange={(event) => {
                      let selectedFilterData = {
                        ...selection,
                        ["access"]: [event.target.value],
                      };
                      setSelection(selectedFilterData);
                      props.setDropDownValues?.(
                        getRemoveAll({ ...selectedFilterData })
                      );
                      getDropDownsData(getRemoveAll({ ...selectedFilterData }));
                      onHandleAnyChange({ current: 'access', data : selectedFilterData});
                      //handleChange("access",event.target.value);
                    }}
                  >
                    <option value="" disabled={props.isMyAccess || props.ddlDisable}>
                      Select
                    </option>
                    {getOptions(dropdownInfo, "access").map((opt, i) => (
                      <option
                        key={i}
                        value={opt.value}
                        disabled={props.isMyAccess|| props.ddlDisable}
                      >
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
                {(props.submitCount > 0 || props.userAddError) &&  
                <div className="error-mssg">
                  {(props.errorMssg != undefined && props.errorMssg.a_access != undefined) && props.errorMssg.a_access.message}
                </div>
                  }
              </Grid>}

              <Grid item xs={3}>
                
                <label>
                  Tab View Settings {isLoading && <Loadersml />}
                </label>
                <div>
                <ULDropdown
                  isMyAccess={props.isMyAccess || props.ddlDisable}
                  isUserManagementSelection
                  name="tabViewSettings"
                  value={getValue(
                    user.userRole === "SuperAdmin" && props.isMyAccess
                      ? dropdownInfo["tabViewSetting"]?.map((i) => i.key) ?? []
                      : selection?.tabViewSettings
                      ? selection?.tabViewSettings
                      : [],
                    tabViewSettings
                  )}
                  options={getOptions(dropdownInfo, "tabViewSetting")}
                  onChange={handleChange("tabViewSettings", tabViewSettings)}
                  isMulti={true}
                  placeholder={"All"}
                  className="react-select-container"
                  removeSelection={removeSelection}
                  isLoading={isLoading}
                  isOptionsDisabled={!selection.tool?.length}
                  closeDD={ closeDD?.el?.name !== 'tabViewSettings' ? false : true}
                  handleDDOpen={handleDDOpen}
                  dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
                />
                {(props.submitCount > 0 || props.userAddError) &&  
                 <div className="error-mssg">
                  {(props.errorMssg != undefined && props.errorMssg.a_tabViewSettings != undefined) && props.errorMssg.a_tabViewSettings.message}
                </div>
                }
                </div>
              </Grid>

              {/* <Grid item xs={3}>
                  <div>
                    <label className="d-flex">
                      Page Settings {isLoading && <Loadersml />}
                    </label>
                  </div>
                  <ULDropdown
                    isMyAccess={props.isMyAccess || props.ddlDisable}
                    isUserManagementSelection
                    name="pageSettings"
                    value={getValue(
                      selection?.pageSettings ? selection?.pageSettings : [],
                      pageSettings
                    )}
                    options={getOptions(dropdownInfo, "pageSetting")}
                    onChange={handleChange("pageSettings", pageSettings)}
                    isMulti={true}
                    placeholder={"All"}
                    className="react-select-container"
                    removeSelection={removeSelection}
                    isLoading={isLoading}
                    isOptionsDisabled={!selection.tool?.length}
                  />
                </Grid> */}
            </Grid>
          </div>
        </div>
      )}
      {props.isCustomer && (
        <>
          <Grid item xs={3}>
            
              <label>
                Business Group {isLoading && <Loadersml />}
              </label>
            <div>

            <ULDropdown
              isMyAccess={props.isMyAccess || props.ddlDisable}
              isUserManagementSelection
              name="businessGroup"
              value={getValue(
                selection?.businessGroup ? selection?.businessGroup : [],
                businessGroup
              )}
              options={getOptions(dropdownInfo, "businessGroup").filter(
                (element) =>
                  location.pathname.includes("pom")
                    ? element.label !== "POSM" && element.label !== "NA"
                    : element
              )}
              onChange={handleChange("businessGroup", businessGroup)}
              isMulti={true}
              placeholder={"All"}
              className="react-select-container"
              removeSelection={removeSelection}
              isLoading={isLoading}
              isOptionsDisabled={!selection.tool?.length}
              title={getValue(
                selection?.businessGroup ? selection?.businessGroup : [],
                businessGroup
              )}
              closeDD={ closeDD?.el?.name !== 'businessGroup' ? false : true}
              handleDDOpen={handleDDOpen}
              dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
            />
            {(props.submitCount > 0 || props.userAddError) &&  
            <div className="error-mssg">
              {(props.errorMssg.a_bg != undefined) && props.errorMssg.a_bg.message}
            </div>
            }
            </div>
          </Grid>

          <Grid item xs={3}>
            
              <label>MCO {isLoading && <Loadersml />}</label>
            <div>

            <ULDropdown
              isMyAccess={props.isMyAccess || props.ddlDisable}
              isUserManagementSelection
              name="mco"
              value={getValue(selection?.mco ? selection?.mco : [], mco)}
              options={getOptions(dropdownInfo, "mco")}
              onChange={handleChange("mco", mco)}
              isMulti={true}
              placeholder={"All"}
              className="react-select-container"
              removeSelection={removeSelection}
              isLoading={isLoading}
              isOptionsDisabled={!selection.tool?.length}
              title={mco}
              closeDD={ closeDD?.el?.name !== 'mco' ? false : true}
              handleDDOpen={handleDDOpen}
              dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
            />
            {(props.submitCount > 0 || props.userAddError) &&  
              <div className="error-mssg">
                {(props.errorMssg.a_mco != undefined) && props.errorMssg.a_mco.message}
              </div>
            }
            </div>
          </Grid>

          <Grid item xs={3}>
            
              <label>
                Country {isLoading && <Loadersml />}
              </label>
            <div>

            <ULDropdown
              isMyAccess={props.isMyAccess || props.ddlDisable}
              isUserManagementSelection
              name="country"
              value={getValue(
                selection?.country ? selection?.country : [],
                country
              )}
              options={getOptions(dropdownInfo, "country")}
              onChange={handleChange("country", country)}
              isMulti={true}
              placeholder={"All"}
              className="react-select-container"
              removeSelection={removeSelection}
              isLoading={isLoading}
              isOptionsDisabled={!selection.tool?.length}
              title={getValue(
                selection?.country ? selection?.country : [],
                country
              )}
              closeDD={ closeDD?.el?.name !== 'country' ? false : true}
              handleDDOpen={handleDDOpen}
              dropDownInModal={props.dropDownInModal ? props.dropDownInModal : false}
            />
            {(props.submitCount > 0 || props.userAddError) &&  
            <div className="error-mssg">
              {props.errorMssg != undefined &&(props.errorMssg.a_country != undefined) && props.errorMssg.a_country.message}
            </div>
            }
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

export default Multiselect;
