import React, { useMemo, useEffect, useState, useRef, useContext } from "react";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { pageTopElHt, tableToolTip } from "../../../utility/constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  useFilters,
  useResizeColumns
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Styles } from "./TableStyles";
import ReactTablePagination from "../../../../src/Common/react-table-pagination";
import ULDropdown from "../../../../src/Common/Dropdown";
import resizeIcon from "../../../Assets/img/icon-resize.svg"
import OpenIcon from "../../../../src/Assets/img/open.svg";
import SearchIcon from "../../../../src/Assets/img/search.svg";
import ClickIcon from "../../../../src/Assets/img/clickicon.svg";
import PinchIcon from "../../../../src/Assets/img/icon-pinch.svg";
import Glossary from "../../../../src/Common/glossary";
import { Checkbox } from "./Checkbox";
import Loader from "../../../Common/loader";
import ColumnHeaderDesc from "../../../Common/ColumnHeaderDesc";
import { DebounceInput } from "react-debounce-input";
import Loadersml from "../../../Common/loadersml";
import { setDropdownvaluesGlobal } from "../../../utility/helper";
import { UserAccessContext } from "../../Application";

const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  status,
  updateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SummaryTableHandleDownload_ref,
  SortBy,
  setSortBy,
  SortType,
  setSortType,
  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  actions,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  //setSortBySelection,
  toggleExpandCollapse,
  handleCheckBox,
  handleSelectAllCheckBox,
  isCheck,
  isNotRequiredTableCoumnSort,
  setCreateListItem,
  publishLoading,
  totalCount,
  setPageInd,
  pageInd,
  state,
  isLoading,
  skipFilter = false,
  filterData,
  refObj,
  setRefObj,
  hideFilter = false,
  dealMonths,
  packTypeList,
  setSelectedCol,
  setHasColUpdated,
  ...props
}) => {

  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);
  const [isTableLoader, setTableLoading] = useState(isLoading || false);
  const [pageTableHeight, setPageTableHeight] = useState();
  const manualPageSize = [5, 10, 20, 30];
  const [searhColumnText, setSearchColumnText] = useState(false);
  const userAccess = useContext(UserAccessContext)


  // --- Resize - DataTable 
  useEffect(() => {
    setTimeout(() => {
      window.addEventListener('resize', tableHt, props.isExpand);
    }, 200);
  });
  const tableHt = (isTableContracted) => {
    let nn = pageTopElHt(isTableContracted);
    setPageTableHeight(nn - 150);
  }
  useEffect(() => {
    tableHt(true);
  }, []);

  const [selectedColumnss, setSelectedColumns] = useState(JSON.parse(window.sessionStorage.getItem("preferences")).selectedColumns)
  let INITIAL_HIDDEN_COLUMNS = !selectedColumnss?.includes("all") ? columns
    .filter((column) => !selectedColumnss?.includes(column.accessor))
    .map((column) => column.accessor) : columns
      .filter((column) => !column.show)
      .map((column) => column.accessor);




  function DefaultColumnFilter(cellObj) {
    var [searchFilterLoader, setSearchFilterLoader] = useState(false);
    const ref = useRef(null);
    const { id, filterValue, setFilter } = cellObj.column;
    const [value, setValue] = useState("");
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [searchTextColumn, setSearchTesxtColumn] = useState("")

    const debouncedChangeHandler = (e) => {
      if (e.key === "Enter") {
        setSearchFilterLoader(true)
        setValue(searchTextColumn);
        handleToggle(e);
      }
    }

    const handleToggle = (e) => {
      cellObj.filterData(e.target.value, id);
      setTableLoading(true);
      setTimeout(() => {
        setSearchFilterLoader(false);
      }, 500);
    };

    var searchCol = (e) => {
      filterData(e.target.value, id);
      setSearchFilterLoader(false);
    };

    useEffect(() => {
      let obj = {};
      obj[id] = ref;
      refObj.push(obj);
      setRefObj([...refObj]);
    }, [id, refObj]);

    return (
      <>
        {!hideFilter && (
          <div className="input-edit-mode input-with-loader btn-slim">
            {/* {console.log(id,value,ref)} */}
            <DebounceInput
              id={id}
              key={id}
              value={value}
              ref={ref}
              onKeyUp={(ev) => {
                // console.log(ev,refObj)
                if (ev.target.value) {
                  refObj.forEach((key) => {
                    if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
                      // console.log(Object.keys(key)[0],key[Object.keys(key)[0]],id,key)
                      // if (key[Object.keys(key)[0]].current.state)
                      //   // key[Object.keys(key)[0]].current.state.value = "";
                      // else {
                      //   // key[Object.keys(key)[0]].current.value = "bfd";
                      // }
                    }
                  });
                }
              }}
              onChange={(event) => setSearchTesxtColumn(event.target.value)}
              onKeyDown={debouncedChangeHandler}
            />

            {searchFilterLoader && (
              <span>
                <Loadersml />
              </span>
            )}
          </div>
        )}
      </>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 150,
      width: 150,
      maxWidth: 400,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    //visibleColumns,
    allColumns,
    toggleHideColumn,
    toggleHideAllColumns,
    prepareRow,
    //setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable(
    !skipFilter
      ? {
        columns,
        data,
        defaultColumn,
        handleClickEditCell,
        clickedIndex,
        clickedCell,
        updateCellData,
        filterData,
        status,
        dealMonths,
        actions,
        refObj,
        setRefObj,
        onActionClick,
        packTypeList,
        handleCheckBox,
        handleSelectAllCheckBox,
        selection,
        isCheck,
        manualPagination: true,
        pageCount: Math.ceil(totalRecordCount / pageSizeNo),
        initialState: {
          pageIndex: pageInd,
          pageSize: pageSizeNo,
          hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        },
      }
      : {
        columns,
        data,
        handleClickEditCell,
        clickedIndex,
        clickedCell,
        updateCellData,
        status,
        dealMonths,
        packTypeList,
        refObj,
        setRefObj,
        actions,
        onActionClick,
        handleCheckBox,
        handleSelectAllCheckBox,
        selection,
        isCheck,
        manualPagination: true,
        pageCount: Math.ceil(totalRecordCount / pageSizeNo),
        initialState: {
          pageIndex: pageInd,
          pageSize: pageSizeNo,
          hiddenColumns: INITIAL_HIDDEN_COLUMNS,
        },
      },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [

        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
          sticky: "left",
        },
        ...columns,
      ]);
    }
  );


  const [val, setVal] = useState([
    "all",
    ...columns
      .filter((item) => item.sticky !== "left")
      .map((item) => item.accessor),
  ]);
  useEffect(() => {
    // setSelectedCol([
    //   ...columns
    //     .filter((item) => item.sticky !== "left" && item.show)
    //     .map((item) => item.accessor),
    // ])
    setSelectedCol([
      ...columns
        .filter((column) => selectedColumnss?.includes(column.accessor))
        .map((column) => column.accessor)
    ])


    return () => {
      setHasColUpdated(false)
    }
  }, [])

  const selectAllOption = { value: "all", label: "Select all" };
  const filterColumns = allColumns
    ? allColumns
      .filter((item) => item.sticky !== "left")
      .map((column) => ({
        value: column.id,
        label: column.Header,
        sticky: column.sticky
      }))
    : [];
  useEffect(() => {
    console.log(selectedColumnss)
    if (selectedColumnss?.includes("all")) {
      setVal([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
      setSelectedCol([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
    } else {
      setVal(selectedColumnss)
    }
    setHasColUpdated(true)
  }, [selectedColumnss])

  const handleSelect = (selected, action) => {
    props.SetColumnSelected(selected.map(i => i.value))
    setSelectedCol(selected.map(i => i.value))
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      //select all
      setVal([
        "all",
        ...columns
          .filter((item) => item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      setHasColUpdated(false)
      toggleHideAllColumns(false);
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      setVal(columns.filter((item) => item.sticky)
        .map((item) => item.accessor));
      setHasColUpdated(true)
      columns.filter((item) => !item.sticky)
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
    } else {
      setVal(
        action.action === "select-option" && selected?.length === columns.length
          ? [{ value: "all", label: "Select all" }, ...selected].map(
            (item) => item.value
          )
          : selected.filter((i) => i.value !== "all").map((item) => item.value)
      );
      if (action.action === "select-option" && selected.length === 1) {
        toggleHideColumn("selection");
      }
    }
    if (action.action !== "pop-value") {
      if (action.action === "clear") {
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        toggleHideColumn(action.option.value);
      }
    }
  };
  useEffect(() => {
    setSelection(selectedFlatRows);
    props.setRowDownloadIds(selectedFlatRows);
  }, [selectedFlatRows, setSelection]);

  useEffect(() => {
    if (setCreateListItem) {
      setCreateListItem(selectedFlatRows);
    }
  }, [selectedFlatRows, setCreateListItem]);
  useEffect(() => {
    setTableLoading(true);
  }, [props.dropDownValues])
  useEffect(() => {
    setTableLoading(isTableLoader);
  }, [isTableLoader]);
  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  const handleSorting = (column) => {
    setTableLoading(true);
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");
    }
  };

  let selectionValues = {
    "Forecast Bias": "fb_l4w",
    "Days on Hand": "max_doh",
    ISL: "isl",
    Brand: "brand",
    "Risk Type": "risk_type",
    "Risk Level": "risk_level",
  };

  const handleNextPage = () => {
    setTableLoading(true);
    nextPage();
  };

  const handlePreviousPage = () => {
    setTableLoading(true);
    previousPage();
  }

  const handlleGotoPage = (i) => {
    setTableLoading(true);
    gotoPage(i);
  };
  useEffect(() => {
    setTableLoading(false);
  }, [data])
  const clearSearchClose = () => {
    setTableLoading(true);
    setSearchText('');
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.inflowdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.inflowdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.inflowdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.inflowdata?.data?.mcoId),
      plantCodeId: props.dropDownValues?.plant ?? [],
      actionTypeId: props.dropDownValues?.actiontype ?? [],
      riskTypeId: props.dropDownValues?.riskType ?? [],
      riskLevelId: props.dropDownValues?.riskLevel ?? [],
      forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
      forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      columnFilter: {},
      page: 1,
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: "",
    });
  }
  const handelSearchButton = () => {
    console.log(props.dropDownValues)
    setTableLoading(true);
    setSearchColumnText(!searhColumnText);
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.inflowdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.inflowdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.inflowdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.inflowdata?.data?.mcoId),
      plantCodeId: props.dropDownValues?.plant ?? [],
      actionTypeId: props.dropDownValues?.actiontype ?? [],
      riskTypeId: props.dropDownValues?.riskType ?? [],
      riskLevelId: props.dropDownValues?.riskLevel ?? [],
      forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
      forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      columnFilter: {},
      page: 1,
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ?? "",
    });
  }
  const handleKeyDown = (event) => {
    console.log(event)
    if (event.key === 'Enter') {
      handelSearchButton();
    }
  };
  const [selectedRow, setSelectedRow] = useState()

  useEffect(() => {
    console.log("handleColumnsSelected~~~~~~~~~~", val);
    localStorage.setItem('columnVisibilitySelected', JSON.stringify(val));
    setTimeout(() => {
      props.handleColumnsSelected();
    }, 400);
  }, [props.downloadClicked])

  return (
    <>
      {/* <!--Search Table Data Start--> */}
      <div className="table-filters">
        <div className="pull-left flex align-items-center justify-content-between">
          <ul className="table-form flex align-items-center">
            {!props.isNoColumnSelection && (
              <li className="pr-0">
                <div className="flex align-items-center">
                  <label className="d-inline-block mx-2">Columns</label>
                  <ULDropdown
                    name="custom_column_filter"
                    value={val}
                    options={[selectAllOption, ...filterColumns]}
                    //options={filterColumns}
                    onChange={handleSelect}
                    isMulti={true}
                    isClearable={false}
                    placeholder={"Search Column"}
                    className="react-select-container inp-std-width"
                    popUp
                  />
                </div>
              </li>
            )}
            {!props.isNoColumnSelection && (
              <li className="pl-2">
                <label>
                  {val.filter((i) => i === "all")?.length
                    ? val.length - 1
                    : val.length} {" "}
                  selected
                </label>
              </li>
            )}
            {!isNotRequiredTableCoumnSort && (
              <li>
                <label>Table Sort Column </label>
                <select
                  onChange={(event) => {
                    setTableLoading(true);
                    setSortBy(
                      event.target.value !== ""
                        ? selectionValues[event.target.value]
                        : ""
                    );
                    getGridData({
                      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.inflowdata?.data?.brandId),
                      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.inflowdata?.data?.countryId),
                      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.inflowdata?.data?.categoryId),
                      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
                      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.inflowdata?.data?.mcoId),
                      plantCodeId: props.dropDownValues?.plant ?? [],
                      actionTypeId: props.dropDownValues?.actiontype ?? [],
                      riskTypeId: props.dropDownValues?.riskType ?? [],
                      riskLevelId: props.dropDownValues?.riskLevel ?? [],
                      forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
                      forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
                      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
                      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
                      page,
                      per_page: pageSizeNo,
                      sortby: SortBy,
                      complianceSorting: SortType,
                      searchText: searchText ?? "",
                    });
                    setTimeout(() => setTableLoading(false), 1000);
                  }}
                >
                  <option key={"fb_l4w"}>Forecast Bias</option>
                  <option key={"max_doh"}>Days on Hand</option>
                  <option key={"isl"}>ISL</option>
                  <option key={"brand"}>Brand</option>
                  <option key={"risk_type"}>Risk Type</option>
                  <option key={"risk_level"}>Risk Level</option>
                </select>
              </li>
            )}
          </ul>
        </div>
        {!props.isNoSearch && (
          <div className="pull-right flex align-items-center justify-content-between">
            <span className="search-wrap-out w-100">
              <span className="search-wrap">
                <img src={SearchIcon} className="filter-search" alt="" />
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    const inputValue = e.target.value.trim();
                    setSearchText(inputValue);
                  }}
                  placeholder="MRDR Description / MRDR"
                  className="btn-slim search-input"
                  onKeyDown={handleKeyDown}
                />
                {searchText != '' && (
                  <a className="filter-clear" onClick={() => clearSearchClose()}><img src={ClearIcon} alt="" /></a>
                )}
              </span>
              <input
                type="button"
                value="Search"
                className="btn-slim search-btn"
                onClick={handelSearchButton}
              />
            </span>
            <span className="marginset">
              <Glossary category="inflow" />
            </span>
            <span className="marginset">
              <span
                className="info_btn"
                onClick={() => {
                  toggleExpandCollapse();
                  setTimeout(() => {
                    tableHt(props.isExpand);
                  }, 400);
                }}
                title={
                  props.isExpand ? "Minimise Datatable" : "Maximise Datatable"
                }
              >
                <img src={props.isExpand ? PinchIcon : OpenIcon} alt="Open" />
              </span>
            </span>
          </div>
        )}
        <div className="clear"></div>
        {val.length !== 0 && (
          <>
            <div className="pull-left mt-2">
              <span className="row-click-link">
                <img src={ClickIcon} alt="" />
                Click the highlighted cells to edit
              </span>
              &nbsp;&nbsp;
              <span className="row-click-link ml-2">
                <img src={resizeIcon} alt="" />&nbsp;
                Doubleclick &amp; move the header column borders to resize
              </span>
            </div>
            <div className="pull-right mt-2">
              <button className="btn btn-slim btn-primary" onClick={() => { props.setColumnClearSearch(!props.clearColumnSearch) }}>Clear Search</button>
            </div>
          </>
        )}
      </div>
      <div className="clear"></div>
      {val.length !== 0 && (
        <>
          <div className="position-relative">
            {(isTableLoader || publishLoading) && (
              <>
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="table_design mt-2" style={{ maxHeight: "auto" }}>
              <Styles>
                <div
                  {...getTableProps()}
                  className={`table sticky ${(page.length <= 0) ? 'no-data' : ''}`}
                >
                  <div className="header">
                    {headerGroups.map((headerGroup, index) => {
                      var col_pos_left = 0;
                      return (
                        <div
                          key={index}
                          {...headerGroup.getHeaderGroupProps()}
                          className="tr"
                        >
                          {headerGroup.headers.map((column, index) => {

                            if ((column.id === "selection")) {
                              col_pos_left = 0;
                            }
                            else if ((column.id === "product_code")) {
                              col_pos_left = ((column.width + 40)) - ((column.width > 160) ? 160 : column.width);
                            }
                            else if ((column.id !== "selection")) {
                              col_pos_left += (column.width > 160) ? column.width : 160;
                            }

                            return (
                              <>
                                <div
                                  key={index}
                                  {...column.getHeaderProps()}
                                  style={{
                                    width:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    minWidth:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    maxWidth:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    left: (column.id !== "product_code") ? `${col_pos_left - ((column.width < 160) ? 160 : column.width) + 160}px` : "40px"
                                  }}
                                  className={`
                            th ${column.id}  sticky-${column.sticky}
                            sort_${SortBy} 
                            ${["product_code", "brand", "description"].includes(column.id)
                                      ? "c-nomination"
                                      : ""
                                    }
                              ${["risk_type",
                                      "risk_level",
                                      "stock_today",
                                      "expected_inbounds_po",
                                      "expected_inbounds_purRqs",
                                      "expected_inbounds",
                                      "expected_outbounds",
                                      "total_risk",
                                      "max_doh",
                                      "isl"
                                    ].includes(column.id)
                                      ? "c-risk-type-data"
                                      : ""
                                    }
                              ${["actuals_l4w",
                                      "fc_l4w",
                                      "abs_err_l4w",
                                      "fb_l4w",
                                      "fc_next_l4w",
                                      "fc_diagnostics_l4w",
                                      "fc_Key_drivers_l4w",
                                      "fc_Gaps_l4w",
                                      "fc_Bias_level_l4w",
                                      "forecast_bias_l4w",
                                      "forecast_bias_l12w",
                                      "week12_x_4_bias",
                                      "abs_err_l12w",
                                      "fc_l12w",
                                      "actuals_l12w"
                                    ].includes(column.id)
                                      ? "fc-14w-data"
                                      : ""
                                    }
                              ${["comment_last_week",
                                      "comment_ww",
                                      "owner_scol",
                                      "comment_scol",
                                      "fc_adjustment_needed",
                                      "owner_feo",
                                      "comment_feo",
                                      "actiontype"
                                    ].includes(column.id)
                                      ? "fc-12w-data"
                                      : ""
                                    }
                              ${column.id === "wk13_wk26_residual_zun"
                                      ? "text-right"
                                      : ""
                                    } 
                              ${["residual_status", "dealtype",
                                      "previous_month_deal_type",
                                      "comment_field",
                                      "previous_month_comment_field",
                                      "previous_month_comment_field",
                                      "local_deal_completion_date",
                                      "local_deal_quantity_in_zun",
                                      "central_deal_quantity_in_zun",
                                      "remaining_quantity_in_zun",
                                      "local_deal_in_eur",
                                      "central_deal_in_eur",
                                      "remaining_stock_in_eur",
                                      "pack_type",
                                      "bom",
                                      "language_on_pack",
                                      "production_plant_number",
                                      "production_country_name"

                                    ].includes(column.id)
                                      ? "td-nomination"
                                      : ""
                                    }

                                    ${["country_code", "city", "full_address", "post_code"].includes(column.id)
                                      ? "td-plant-data"
                                      : ""
                                    }
                                    ${["ean_zun", "ean_carton", "zun_per_case", "zun_per_pallet", "deal_pallet_zun"].includes(column.id)
                                      ? "td-additional-product-data"
                                      : ""
                                    }
                                    ${["agreed_quantity_in_zun",
                                      "cogs",
                                      "agreed_price_per_zun_in_eur",
                                      "sales_value_eur",
                                      "loss_value_eur",
                                      "margin_percentage",
                                      "net_cogs_removing_prov_eur",
                                      "net_margin_percentage",
                                      "spf_code",
                                      "min_floor_price_per_zun_for_central_deal_in_eur",
                                      "tp_exworks_dc"
                                    ].includes(column.id)
                                      ? "td-negotation-phase-with-customers"
                                      : ""
                                    }
                                    ${["quantity_available",
                                      "stock_is_located_in_country",
                                      "stock_is_produced_in_country",
                                      "ean_zun2",
                                      "ean_carton2",
                                      "zun_per_case2",
                                      "zun_per_pallet2",
                                    ].includes(column.id)
                                      ? "td-bidding-portal"
                                      : ""
                                    }
                            `}
                                >
                                  <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${column.isResizing ? "isResizing" : column.width
                                      }`}
                                  />

                                  <div className="th-in">
                                    <OverlayTrigger
                                      overlay={tableToolTip(ColumnHeaderDesc("inflow", column.Header))}
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      delay={{ show: 200, hide: 0 }}
                                    >
                                      <span className="th-text" >
                                        <div className="header_col_title_wrap">
                                          <div className="header_col_title">
                                            {column.render("Header")}
                                          </div>
                                          {column.id !== "selection" && (
                                            <span
                                              className="sort-ico"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <span>
                                                {SortType === "ASC" && column.id === SortBy ? (
                                                  <Icon
                                                    path={mdiMenuUp}
                                                    size="1.5rem"
                                                    className="sortColumn"
                                                    onClick={() => handleSorting(column)}
                                                  />
                                                ) : SortType === "DESC" &&
                                                  column.id === SortBy ? (
                                                  <Icon
                                                    path={mdiMenuDown}
                                                    size="1.5rem"
                                                    className="sortColumn"
                                                    onClick={() => handleSorting(column)}
                                                  />
                                                ) : (
                                                  <Icon
                                                    path={mdiMenuUp}
                                                    size="1.5rem"
                                                    className="sortColumn"
                                                    onClick={() => {
                                                      setTableLoading(true);
                                                      if (column.id !== "icon_container") {
                                                        setSortType("DESC");
                                                        setSortBy(column.id);
                                                      }
                                                    }}
                                                  />
                                                )}
                                              </span>
                                            </span>
                                          )}
                                        </div>
                                        {!skipFilter && (
                                          <div>
                                            {column.canFilter && column.id !== "view_status" ? (
                                              column.render("Filter") ?? null
                                            ) : (
                                              <div className="btn-slim transparent"></div>
                                            )}
                                          </div>
                                        )}
                                      </span>
                                    </OverlayTrigger>
                                    {column.id !== "selection" && (
                                      <span title="Doubleclick to resize column" className={` col-resize-handle resizer ${column.isResizing ? "isResizing" : ''
                                        }  ${(column.width < 160) ? 'minimum' : ''}`}  {...column.getResizerProps()}>
                                      </span>
                                    )}


                                  </div>

                                </div>

                              </>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>

                  {page.length <= 0 && (
                    <div className="body">
                      <div className="tr w-100">
                        <div role="cell" className="td">
                          <span className="td-in text-center">No Data</span>
                        </div>
                      </div>
                    </div>
                  )}

                  {(
                    <div key={state} {...getTableBodyProps()} className={`body`}>


                      {(page.length > 0 &&
                        page.map((row, index) => {
                          var body_col_pos_left = 0;
                          prepareRow(row);
                          return (
                            <div key={index} {...row.getRowProps()} className="tr">
                              {row.cells.map((cell, index) => {
                                if ((cell.column.id === "selection")) {
                                  body_col_pos_left = 0;
                                }
                                else if ((cell.column.id === "product_code")) {
                                  body_col_pos_left = ((cell.column.width + 40)) - ((cell.column.width > 160) ? 160 : cell.column.width);
                                }
                                else if ((cell.column.id !== "selection")) {
                                  body_col_pos_left += (cell.column.width > 160) ? cell.column.width : 160;
                                }

                                return (
                                  <div
                                    key={index}
                                    onClick={() => setSelectedRow(cell.row.id)}
                                    {...cell.getCellProps()}
                                    style={{
                                      width:
                                        cell.column.id === "selection"
                                          ? "40px"
                                          : (cell.column.width > 160) ? cell.column.width : "160px",
                                      minWidth:
                                        cell.column.id === "selection"
                                          ? "40px"
                                          : (cell.column.width > 160) ? cell.column.width : "160px",
                                      maxWidth:
                                        cell.column.id === "selection"
                                          ? "40px"
                                          : (cell.column.width > 160) ? cell.column.width : "160px",
                                      left: (cell.column.id !== "product_code") ? `${body_col_pos_left - ((cell.column.width < 160) ? 160 : cell.column.width) + 160}px` : "40px"
                                    }}
                                    className={`
                                    td  ${cell.column.id} sort_${SortBy} sticky-${cell.column.sticky} 
                                    td-editable-${cell.column.editable} ${selectedRow === cell.row.id ? cell.column.editable ? "table-highlight" : "table-highlight" : ""}
                                    `}
                                  >
                                    <span className="td-in" key={index} title={
                                      (cell.column.decimal_value
                                        ? parseFloat(cell.value).toFixed(2)
                                        : cell.column.round_off_values_no_decimal
                                          ? Math.round(cell.value)
                                          : cell.value) + (cell.column.show_percentage ? '%' : '') + (cell.column.show_precentage_symbol ? '%' : '')
                                    }
                                      {...cell.getCellProps()} >
                                      {cell.column.format_number &&
                                        Math.round(cell.value).toLocaleString('en-US', {
                                          maximumFractionDigits: 0,
                                          minimumFractionDigits: 0,
                                        })}
                                      {cell.column.round_off_values_no_decimal &&

                                        Math.round(cell.value)}
                                      {!cell.column.round_off_values_no_decimal && !cell.column.format_number &&
                                        cell.render("Cell")}
                                      {cell.value && cell.column.show_precentage_symbol ? '%' : ''}
                                      {cell.column.show_percentage ? '%' : ''}

                                      {/* {selectedRow===cell.row.id &&<span style={{backgroundColor:"rgba(255,255,255,0.1)"}}>
                                      </span>} */}

                                    </span>
                                  </div>
                                )
                              })}
                            </div>
                          );
                        }))}
                    </div>
                  )}
                </div>
              </Styles>
            </div>
          </div>
          {totalRecordCount > 0 && (
            <ReactTablePagination
              page={page}
              gotoPage={handlleGotoPage}
              previousPage={handlePreviousPage}
              nextPage={handleNextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              manualPageSize={manualPageSize}
              dataLength={totalRecordCount}
              handleItemPerPage={handleItemPerPage}
              handlePaginationReset={handlePaginationReset}
              pageSizeNo={pageSizeNo}
              totalCount={totalCount}
              setPageIndex={setPageInd}
            />
          )}
        </>
      )}
    </>
  );
};

export default React.memo(TableGrid);
