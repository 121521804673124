import React, { useContext, useEffect, useState } from 'react'
import "chart.js/auto";
import { Chart } from 'react-chartjs-2'

import Loader from "../../../Common/loader";
import { LABEL_COLOR, LEGEND_COLOR, chunkString } from '../../InflowRiskVisibility/helpers';
import { GetInflowForcastChart, GetInflowRisktypeWeekChart } from '../../../Services/InflowSummaryService';
import { setDropdownvaluesGlobal } from '../../../utility/helper';
import { UserAccessContext } from '../../Application';
function RiskTypeGraph(props) {
    const [resData, setResData] = useState();
    const userAccess = useContext(UserAccessContext)

    useEffect(()=>{
        // let payload = {
        //     brandId: setDropdownvaluesGlobal(props.filterValues?.brand
        //         .filter(item => props.dropdownValues?.brand?.includes(item.key))
        //         .map(i => i.value.replace("'","''")) ,props.filterValues?.brand
        //         .filter(item => userAccess?.inflowdata?.data?.brandId?.includes(item.key))
        //         .map(i => i.value.replace("'","''"))),
        //     countryId: setDropdownvaluesGlobal(props.filterValues?.country
        //         .filter(item => props.dropdownValues?.country?.includes(item.key))
        //         .map(i => i.value),props.filterValues?.country
        //         .filter(item => userAccess?.inflowdata?.data?.countryId?.includes(item.key))
        //         .map(i => i.value)),
        //     categoryId: setDropdownvaluesGlobal(props.filterValues?.category
        //         .filter(item => props.dropdownValues?.category?.includes(item.key))
        //         .map(i => i.value)  ,props.filterValues?.category
        //         .filter(item => userAccess?.inflowdata?.data?.categoryId?.includes(item.key))
        //         .map(i => i.value)),
        //     businessGroupId: setDropdownvaluesGlobal(props.filterValues?.businessGroup
        //         .filter(item => props.dropdownValues?.businessGroup?.includes(item.key))
        //         .map(i => i.value),props.filterValues?.businessGroup
        //         .filter(item => userAccess?.inflowdata?.data?.bussinessGroupId?.includes(item.key))
        //         .map(i => i.value) ),
        //     // businessGroupId: props.filterValues?.businessGroup
        //     //     .filter(item => props.dropdownValues?.businessGroup?.includes(item.key))
        //     //     .map(i => i.value) ?? [],
        //     foodSolutionIndicatorId: props.filterValues?.fsIndicator
        //         .filter(item => props.dropdownValues?.fsIndicator?.includes(item.key))
        //         .map(i => i.value) ?? [],
        //     // brandId: props.filterValues?.brand
        //     //     .filter(item => props.dropdownValues?.brand?.includes(item.key))
        //     //     .map(i => i.value) ?? [],
        //     // countryId: props.filterValues?.country
        //     //     .filter(item => props.dropdownValues?.country?.includes(item.key))
        //     //     .map(i => i.value) ?? [],
        //     // categoryId: props.filterValues?.category
        //     //     .filter(item => props.dropdownValues?.category?.includes(item.key))
        //     //     .map(i => i.value) ?? [],
        //     plantCodeId: props.filterValues?.plantCode
        //         .filter(item => props.dropdownValues?.plant?.includes(item.key))
        //         .map(i => i.value) ?? [],
        // }
        let payload ={
            brandId: setDropdownvaluesGlobal(props.filterValues?.brand
                .filter(item => props.dropdownValues?.brand?.includes(item.key))
                .map(i => i.value.replace("'","''")) ,userAccess?.inflowdata?.data?.brand.map(i => i.value.replace("'","''"))),
            countryId: setDropdownvaluesGlobal(props.filterValues?.country
                .filter(item => props.dropdownValues?.country?.includes(item.key))
                .map(i => i.value),userAccess?.inflowdata?.data?.country.map(i => i.value)),
            categoryId: setDropdownvaluesGlobal(props.filterValues?.category
                .filter(item => props.dropdownValues?.category?.includes(item.key))
                .map(i => i.value)  ,userAccess?.inflowdata?.data?.category.map(i => i.value)),
            businessGroupId: setDropdownvaluesGlobal(props.filterValues?.businessGroup
                .filter(item => props.dropdownValues?.businessGroup?.includes(item.key))
                .map(i => i.value),userAccess?.inflowdata?.data?.businessGroup.map(i => i.value) ),
            foodSolutionIndicatorId: props.filterValues?.fsIndicator
                .filter(item => props.dropdownValues?.fsIndicator?.includes(item.key))
                .map(i => i.value) ?? [],
            plantCodeId: props.filterValues?.plantCode
                .filter(item => props.dropdownValues?.plant?.includes(item.key))
                 .map(i => i.value) ?? [],
        }
        if(props.dropdownValues)
        GetInflowRisktypeWeekChart(payload).then(response=>setResData(response))
    },[props.dropdownValues])

    const options = {
        plugins: {
            legend: {
                display: true,
                align: "start",
                labels: {
                    pointStyle: "circle",
                    usePointStyle: true,
                    padding: 20,
                    color: LEGEND_COLOR,
                    font: {
                        size: 10,
                        family: "unilevershilling",
                    },
                },
            },
            datalabels: {
                display: false,
            },
        },
        responsive: true,
        barThickness:50,
        height: 300,
        // aspectRatio: 1.1,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    color: LABEL_COLOR,
                    font: {
                        size: 10,
                        family: "unilevershilling",
                    },
                    callback(value) {
                        return chunkString(this.getLabelForValue(value), 10);
                    },
                },
            },
            y: {
                stacked: true,
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                border: {
                    display: false,
                },
                ticks: {
                    color: LABEL_COLOR,
                    font: {
                        family: "unilevershilling",
                    },
                },
            },
        },
    };
    let currentDate = new Date();
    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    let weekNumber = Math.ceil(days / 7);
    let weeksArray = Array.from({ length: 4 + 1 }, (_, index) => {
        return (weekNumber-1 - index + 52) % 52 ||52;
    }).reverse();    
    const labels = ["W"+weeksArray[0], "W"+(weeksArray[1]), "W"+(weeksArray[2]), "W"+(weeksArray[3]), "W"+(weeksArray[4])]
    const data = {
        labels,
        datasets: [
            {
                label: "Moving",
                data: [
                    resData?.filter(i=>i.Risk_Type==="Moving"&& i.WeekNumber===weeksArray[0]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Moving"&& i.WeekNumber===weeksArray[1]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Moving"&& i.WeekNumber===weeksArray[2]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Moving"&& i.WeekNumber===weeksArray[3]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Moving"&& i.WeekNumber===weeksArray[4]).map(i=>i.count)[0]
                ],
                backgroundColor: "rgb(84,168,216)",
            },

            {
                label: "Slow moving ",
                data: [
                    resData?.filter(i=>i.Risk_Type==="Slow Moving"&& i.WeekNumber===weeksArray[0]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Slow Moving"&& i.WeekNumber===weeksArray[1]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Slow Moving"&& i.WeekNumber===weeksArray[2]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Slow Moving"&& i.WeekNumber===weeksArray[3]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Slow Moving"&& i.WeekNumber===weeksArray[4]).map(i=>i.count)[0]
                ],
                backgroundColor: "rgb(232, 96, 86)",
            },
            {
                label: "Non moving",
                data: [
                    resData?.filter(i=>i.Risk_Type==="Non Moving"&& i.WeekNumber===weeksArray[0]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Non Moving"&& i.WeekNumber===weeksArray[1]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Non Moving"&& i.WeekNumber===weeksArray[2]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Non Moving"&& i.WeekNumber===weeksArray[3]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Non Moving"&& i.WeekNumber===weeksArray[4]).map(i=>i.count)[0]
                ],
                backgroundColor: "rgb(83, 91, 110)",
            },
            {
                label: "Inventory Buildup",
                data: [
                    resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===weeksArray[0]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===weeksArray[1]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===weeksArray[2]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===weeksArray[3]).map(i=>i.count)[0],
                    resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===weeksArray[4]).map(i=>i.count)[0]
                ],
                backgroundColor: "rgb(239,163,58)",
            },
            // {
            //     label: "Inventory Buildup",
            //     data: [
            //         resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===startweekNumber).map(i=>i.count)[0],
            //         resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===startweekNumber+1).map(i=>i.count)[0],
            //         resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===startweekNumber+2).map(i=>i.count)[0],
            //         resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===startweekNumber+3).map(i=>i.count)[0],
            //         resData?.filter(i=>i.Risk_Type==="Inventory Buildup"&& i.WeekNumber===startweekNumber+4).map(i=>i.count)[0]
            //     ],
            //     backgroundColor: "rgb(92,188,169)",
            // }
        ],
    };
    return <Chart type="bar" data={data} options={options} />
}

export default RiskTypeGraph