import React, { useContext, useEffect, useState } from "react";
import { UserAccessContext, UserContext } from "../../Application";
import { getUserRoleDetails } from "../../../Services/UserManagementService2";

export function CellActionGroup(cellObj) {
  const { column, row, value } = cellObj.cell;
  const {
    original: { actionsList },
  } = row;

  const user = useContext(UserContext);
  const userAccess = useContext(UserAccessContext)


  const [val, setVal] = useState(value);

  const [isSelection, setIsSelection] = useState(false);

  const handleCellChange = (e) => {

    setIsSelection(true);
    setVal(e.target.value);
  };
  const [accessOptions,setAccessOptions]=useState([
    { key: "2", value: "Write" },
    { key: "1", value: "Read" },
  ])
  useEffect(()=>{
    if(cellObj.row.original.tool[0]==="1"){
      if(userAccess.fgSLOBdata?.data.length===undefined){
        if(userAccess.fgSLOBdata?.data.accessId==="1"){
          setAccessOptions([{ key: "1", value: "Read" },])
        }
      }
    }else if(cellObj.row.original.tool[0]==="2"){
      if(userAccess.inflowdata?.data.length===undefined){
        if(userAccess.inflowdata?.data.accessId==="1"){
          setAccessOptions([{ key: "1", value: "Read" },])
        }
      }
    }else if(cellObj.row.original.tool[0]==="3"){
      if(userAccess.pomdata?.data.length===undefined){
        if(userAccess.pomdata?.data.accessId==="1"){
          setAccessOptions([{ key: "1", value: "Read" },])
        }
      }
    }
    else{
      console.log("datatatatatat")
    }
  },[cellObj])
  return (
    <>
      <select
        className="input-edit-mode width-100"
        onChange={(e) => {
          setIsSelection(true);
          handleCellChange(e);
        }}
        onBlur={(e) => {
          setIsSelection(true);
          cellObj.updateCellData(row, column, e.target.value, true);
        }}
        autoFocus
        value={isSelection ? val : val || value}
        disabled={user.userRole === "User"}
      >
        {accessOptions?.map((item, i) => {
          return <option value={item.key} key={i}>{item.value}</option>;
        })}
      </select>
    </>
  );
}

export const CellActionUserRole = (cellObj) => {
  console.log("cellObjUser", cellObj)
  const { column, row, value } = cellObj.cell;
  const {
    original: { actionsList },
  } = row;
  const user = useContext(UserContext);
  const [val, setVal] = useState(value);
  const [isSelection, setIsSelection] = useState(false);
  const [userRole, setUserRole] = useState();

  const mainRoleValue = [
    { key: "SuperAdmin", value: "SuperAdmin" },
    { key: "Admin", value: "Admin" },
    { key: "User", value: "User" },
  ]
  const [fiteredRoles,setFilteredRoles] = useState([])

  const handleCellChange = (e) => {
    setIsSelection(true);
    setVal(e.target.value);
  };

  useEffect(() => {
    getUserRoleDetails({ "UserEmail": row.original.email }).then((res) => {
      console.log(res)
      if (res.data.data.length) {
        setUserRole(res.data.data[0].UserRole)
      }
      else {
        setUserRole("")
      }
    })
  }, [])
  useEffect(()=>{
    console.log("userRole",userRole)
    // if(userRole!==""){
    //   if(userRole ==="SuperAdmin"){
    //     setFilteredRoles(mainRoleValue.filter(i=>i.value === "SuperAdmin"))
    //   }
    //   else if(userRole ==="Admin"){
    //     setFilteredRoles(mainRoleValue.filter(i=>i.value !== "User"))
    //   }
    //   else{
    //     setFilteredRoles(mainRoleValue)
    //   }
    // }
    // else {
      if(user.userRole ==="SuperAdmin"){
        if(userRole!==""){
          if(userRole ==="SuperAdmin"){
            setFilteredRoles(mainRoleValue.filter(i=>i.value === "SuperAdmin"))
          }
          else if(userRole ==="Admin"){
            setFilteredRoles(mainRoleValue.filter(i=>i.value !== "User"))
          }
          else{
            setFilteredRoles(mainRoleValue)
          }
        }else{
          setFilteredRoles(mainRoleValue)
        }
      }
      else if(user.userRole ==="Admin"){
        if(userRole!==""){
          // if(userRole ==="SuperAdmin"){
          //   setFilteredRoles(mainRoleValue.filter(i=>i.value === "SuperAdmin"))
          // }
           if(userRole ==="Admin"){
            setFilteredRoles(mainRoleValue.filter(i=>i.value === "Admin"))
          }
          else{
            setFilteredRoles(mainRoleValue.filter(i=>i.value !== "SuperAdmin"))
          }
        }
        else{         
          setFilteredRoles(mainRoleValue.filter(i=>i.value !== "SuperAdmin"))
        }
      }
      else{
        setFilteredRoles(mainRoleValue.filter(i=>i.value === "User"))
      }
    // }
  },[userRole])

  return (
    <>
      <select
        className="input-edit-mode width-100"
        onChange={(e) => {
          setIsSelection(true);
          handleCellChange(e);
        }}
        onBlur={(e) => {
          setIsSelection(true);
          cellObj.updateCellData(row, column, e.target.value, true);
        }}
        autoFocus
        defaultValue={"Admin"}
        value={isSelection ? val : val || value}
        disabled={user.userRole === "User"}
      >
        {fiteredRoles?.map((item, i) => {
          return <option value={item.key} key={i}>{item.value}</option>;
        })}
      </select>
    </>
  );
}

