import { ImagePlaceHolder } from "./CellAction"; //CellCheckBox
export const COLUMNS = [
  {
    Header: "SKU/Description",
    accessor: "material_description",
    show: true,
    editable: false,
    Cell: ImagePlaceHolder,
  },
  {
    Header: "Qty Available",
    accessor: "remaining_quantity_in_zun",
    show: true,
    editable: false,
    
  },
  {
    Header: "Stock Location",
    accessor: "country",
    show: true,
    editable: false,
  },
  {
    Header: "Production Location",
    accessor: "production_country_name",
    show: true,
    editable: false,
  },
  {
    Header: "Ean ZUN",
    accessor: "ean_zun",
    show: true,
    editable: false,
  },
  {
    Header: "Ean Carton",
    accessor: "ean_carton",
    show: true,
    editable: false,
  },
  {
    Header: "ZUN per Case",
    accessor: "zun_per_case",
    show: true,
    editable: false,
  },
  {
    Header: "ZUN per Pallet",
    accessor: "zun_per_pallet",
    show: true,
    editable: false,
  },
  {
    Header: "Deal pallets ZUN",
    accessor: "deal_pallet_zun",
    show: true,
    editable: false,
  },
  {
    Header: "Language on pack",
    accessor: "language_on_pack", //English
    show: true,
    editable: false
  },
  {
    Header: "Pack type (including BoM list) ",
    accessor: "bom",
    show: true,
    editable: false
  },
];
