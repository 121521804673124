import React, { useContext, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Glossery from "./Glossery";
import { UserAccessContext, UserContext } from "../../Application";
import CreateGroupOrUser from "./CreateGroupOrUser";
import ListUpdate from "./ListUpdate";
import AssignOwner from "./AssignOwner";

function AdminSettings(props) {
  const [userDisplay, setUserDisplay] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [userGroupSelect, setUserGroupSelect] = useState(false);
  const user = useContext(UserContext);
  const [createType, setCreateType] = useState("Group");
  const userAccess = useContext(UserAccessContext)

  const selectUserGroup = (val) => {
    if (val == "") {
      setUserRole("");
      setUserGroupSelect(true);
    } else {
      setUserRole(val);
      setUserGroupSelect(false);
    }
  };

  const handleGroupUserModalClose = () => {
    setUserDisplay(false);
  }
  return (
    <div className="page-admin-settings h-100  position-relative">
      
      <button
        type="button"
        className={`btn-slim btn-create-user-group btn btn-secondary position-absolute right-0`}
        onClick={() =>setUserDisplay(true)}
      >
        { "Create a Group/ User" }
      </button>
 
      <div className="h-100">
        {/* {!userDisplay && ( */}
          <div>
            <Tabs
              id="controlled-tab-example"
              className="mt-3"
              defaultActiveKey={"Assign Owner"}
            >
              {(userAccess?.inflowdata?.data?.length===undefined||userAccess?.pomdata?.data?.length===undefined)&& <Tab eventKey="Assign Owner" title="Assign Owner">
                  <div className="page-assign-owner">
                    <AssignOwner  />
                  </div>
              </Tab>}
              
             { user?.userRole === "SuperAdmin"&& <Tab eventKey="List Update" title="List Update">
                
                  <div className="page-list-update">
                    <ListUpdate />
                  </div>
                
              </Tab>}
              <Tab eventKey="Glossary" title="Glossary">
                
                  <div className="page-list-glossary position-relative">
                    <Glossery />
                  </div>
                
              </Tab>
            </Tabs>
          </div>
      

       
        {userDisplay && (
          <div className="row row-user-selection mb-5">
            <div className="col-md-12">
              <div className="filterform_bg d-flex align-items-center justify-content-between mt-4">
                <div className="col-md-5">
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <CreateGroupOrUser open={userDisplay} key={userDisplay} onGroupUserModalClose={handleGroupUserModalClose} onHide={(val)=>{
                        props.reload(false);setUserDisplay(false)}}/>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminSettings;
