import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import RefressIcon from "../../Assets/img/refress.svg";
import UnileverLogoIcon from "../../Assets/img/unileverlogo.svg";
import HomeIcon from "../../Assets/img/icon-home.svg";
import CogIcon from "../../Assets/img/icon-cog.svg";
import UniopsIcon from "../../Assets/img/uniops.svg";
import Toast from "../../Common/toast";
import { Link, useLocation } from "react-router-dom";
import Notification from "../Notification";
import { getBiddingNotifications } from "../../Services/BiddingPortalService";
import { getNotifications } from "../../Services/CommonServices";
import { useAccount, useMsal } from "@azure/msal-react";
import { UserContext } from "../../Components/Application";
import Notify1 from "../../Assets/notifications/notify1.mp3";
import Crtracker from "../../Common/crtracker/Crtracker";
const Header = (props) => {
  const { accounts } = useMsal();
  const user = useContext(UserContext);
  const account = useAccount(accounts[0] || {});
  const { username } = account || {};
  const [isShowUser, setIsShowUser] = useState(false);
  const [isUserSet, setIsUser] = useState(true);
  const audioPlayer = useRef(null);
  const location = useLocation();
  const [rand, setRand] = useState(0);
  const [toastifyPosition] = useState("top-center");
  const [notificationArray, setNotificationArray] = useState([]);
  const [isUsersEmail, setUsersEmail] = useState({});
  const [notifyCount, setNotifyCount] = useState(0);
  useEffect(() => {
    setRand(Math.random(1));
    // setInterval(() => {
    //   if(user != undefined || user != null){
    //   let usq = user.email
    //   getNotifictionData({ vendorEmail: usq });}
    // }, 6000);
  }, [])
  useEffect(() => {
    if (user.userRole && user.userRole !== "Customer") {
      setIsShowUser(true)
    }
    else if (user.userRole === "Customer") {
      setIsShowUser(false)
      setIsUser(true);
    }
    else if(user.userRole===undefined){
      setIsShowUser(false)
    }
    else{
      setIsUser(true);
      setIsShowUser(false)
    }
    if (user.userRole) {
      setIsUser(true);
      setUsersEmail(user);
    }
  }, [user])
  const playAudio = () => {
    audioPlayer.current.play();
  }
  const getNotifictionData = useCallback((payload) => {
    getNotifications(payload).then((response) => {
      if (response.biddingNotifications.length > 0 || response.customerNotifications.length > 0) {
        playAudio();
        let mmn = [...response.biddingNotifications, ...response.customerNotifications];
        setNotificationArray(mmn);
        let unreadNotifys = notificationArray.filter(r => !r.status);
        setNotifyCount(unreadNotifys.length);
      }
    })
  }, []);

  // useEffect(() => {
  //   if(user.email)
  //     getNotifictionData({ vendorEmail: user.email });
  // }, [user.email]);

  const ff = () => {
    let gg = document.documentElement.scrollTop;
  }


  return (
    <>
      <audio ref={audioPlayer} src={Notify1} />
      <Toast position={toastifyPosition} />
      <div className={`main_header_in isNotProduction-${props?.isNotProduction}`}>
        <div className="flex align-items-center">
            <Link to="/">
              <div className="logo">
                <div className="header-logo-unilever">
                  <img src={UnileverLogoIcon} alt="" />
                </div>
                <div>
                  <img src={UniopsIcon} alt="" />
                </div>
              </div>
            </Link>
            <div className="header-title">
              <h3>
                {(location.pathname.length <= 1 && location.pathname?.includes("/")) &&
                  "BUSINESS WASTE"
                }
                {(location.pathname?.includes("risksummary")) &&
                  "BUSINESS WASTE: Waste Risk Summary"
                }
                {(location.pathname?.includes("riskdetails")) &&
                  "BUSINESS WASTE: Waste Risk Details"
                }
                {(location.pathname?.includes("dealmanagement")) &&
                  "BUSINESS WASTE: Central Deal Management"
                }
                {(location.pathname?.includes("dealcalendar")) &&
                  "BUSINESS WASTE: Central Deal Calendar"
                }
                {(location.pathname?.includes("biddingportal")) &&
                  "BUSINESS WASTE: Bidding Portal"
                }
                {(location.pathname?.includes("inflow")) &&
                  "BUSINESS WASTE: Inflow Risk Visibility"
                }
                {(location.pathname?.includes("pom")) &&
                  "BUSINESS WASTE: Proactive Obsolete Management"
                }
                {(location.pathname?.includes("user-management")) &&
                  "BUSINESS WASTE: User Management"
                }
              </h3>
            </div>
            {/* {props?.isNotProduction &&
              <div className="development-env">
                <p>You're currently in the Development Environment, which is NOT for operational use.</p>
              </div>
            } */}
        </div>
        <div className={`header-menu ${props.updateTime ? 'has-note-detail' : ''}`}>
          <ul className="list-inline_block ">
            <li>
              <Link to={user.userRole === "Customer" ? "/biddingportal" : "/home"}>
                <img src={HomeIcon} alt="Home" title="Home" />
              </Link>
            </li>
            <li>
              <a href="#"  style={{cursor:"pointer"}} onClick={() => window.location.reload()} title="Refresh">
                <img src={RefressIcon} alt="Notify" />
              </a>
            </li>
            {isUserSet &&
              <li>
                <Notification key={notifyCount} />
              </li>
            }
            {isShowUser && <li>
              <Link to="/user-management">
                <img src={CogIcon} alt="User Management" title="User Management" />
              </Link>
            </li>}

          </ul>
          {props.updateTime && (
            <div>
              <p className="m-0">{props.updateTimeText} &nbsp;{props.updateTime}</p>
            </div>
          )}
        </div>
      </div>
      {/* <Crtracker /> */}
    </>
  );
};

export default Header;
