import React from "react";
import { headerDesc, headerDescPOM, headerDescInflow } from "../../utility/constants";
const ColumnHeaderDesc = (pageType, column) => {
    let headerTooltip = ``;
    let pageCategory = [];
    
    switch (pageType) {
      case 'fgslob':
        pageCategory = headerDesc;
        break;
      case 'inflow':
        pageCategory = headerDescInflow;
        break;
      case 'pom':
        pageCategory = headerDescPOM;
        break;
    }

    pageCategory.map(e => {
      if(column.toString().toLowerCase() === e.column.toString().toLowerCase()) {
        headerTooltip = `:\n\n${e.desc}`;
        return;
      }
    });
    
    return `${column}${headerTooltip}`;
}

export default ColumnHeaderDesc;