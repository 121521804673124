import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import DownloadIcon from "../../Assets/img/download.svg";
import UploadIcon from "../../Assets/img/upload.svg";
import Loader from "../../Common/loader";
import { v4 } from 'uuid';
import StatusPopup from "../../Common/StatusPopup/statusPopup"
import {
  WasteRiskDetails,
  downloadWasteRiskDetails,
  checkFileStatus,
  excelFileUpload,
  addPulishStatus,
  setPublishStatus,
  UpdateWasteRiskCellData,
  PendingActionsCount,
  getConfirmData,
  downloadBomDetailsData
} from "../../Services/WasteRiskDetailServices";
import { notify } from "../../../src/Common/toast";
import { COLUMNS } from "./Columns";
import { PerPageRecordNumber } from "../../../src/utility/constants";
import TableGrid from "./TableGrid";
import moment from "moment";
import AddToPublishModal from './AddToPublishModal';
import { UserAccessContext, UserContext } from "../../Components/Application";

var fileSaver = require("file-saver");
export const MonthList = React.createContext({});
const WasteRiskDetailsTable = (props) => {
  const fileUploadInput = useRef(null);
  const [filterText, setFilterText] = useState('')
  const [filterKey, setFilterKey] = useState('')
  const [pageInd, setPageInd] = useState(0)
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [state, setState] = useState("Loading")
  const [isLoading, setIsLoading] = useState(false)
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [showPublish, setShowPublish] = useState(false);
  const [isFileStatusDone, setIsFileStatusDone] = useState(false);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [isFileBom, setIsFileBom] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [isShowFilePopup, setIsShowFilePopup] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [SortBy, setSortBy] = useState("wk13_wk26_residual_zun");
  const [SortType, setSortType] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [refObj, setRefObj] = useState([]);
  const user = useContext(UserContext);
  const [hasColUpdated, setHasColUpdated] = useState(false);
  const [selection, setSelection] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [publishCount, setPublishCount] = useState(0);
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [dealMonths, setDealMonths] = useState([])
  const [packTypeList, setPackTypeList] = useState([])
  const [publishLoading, setPublishLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [selectedCol, setSelectedCol] = useState([]);
  const userAccess = useContext(UserAccessContext)
  const fetchIdRef = React.useRef(0);
  const [columnsSelected, setColumnsSelected] = useState([]);
  const [downloadClicked, setDownloadClicked] = useState(0);
  const [rowDownloadId, setRowDownloadIds] = useState([])
  const [clearColumnSearch, setColumnClearSearch] = useState(false)

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
  };

  const setDropdownvaluesGlobal = (data1, data2) => {
    if (data1) {
      if (data1.length === 0) {
        return (data2)
      }
      else {
        return (data1)
      }
    }
    else { return (data2) }
  }

  const addToPublish = (values) => {
    let data = [];
    selection.forEach((val) => {
      data.push({
        materialno: val.original.materialNumberId,
        plantCode: val.original.plantCodeId,
        country: val.original.country,
        countryId: val.original.countryId,
        status: values,
        descriptionId: val.original.descriptionId
      });
    });
    addPulishStatus({ data: data })
      .then((response) => {
        setSelection([]);
        setIsPublished(true)
        setTimeout(() => {
          setIsPublished(false)
          getPendingActionsCount();
        }, 200);
        notify({
          message: `Sucessfully added to Publish List`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish List failed`,
          type: "error",
        });
      });
  };

  // const handleColumnsSelected = (e) => {
  //   let columnVisibilitySelected = JSON.parse(localStorage.getItem('columnVisibilitySelected'));
  //   let columnVisibilitySelected_without_all = columnVisibilitySelected.filter(j => j !== 'all');
  //   console.log("columnVisibilitySelected ************** ",columnVisibilitySelected_without_all);
  //   setColumnsSelected([...columnVisibilitySelected_without_all]);
  //   setTimeout(() => {
  //     if(downloadClicked !== 0){
  //     console.log("selected - columns are ",columnsSelected);
  //     handleDownload(columnVisibilitySelected_without_all);}
  //   }, 1000);

  // }

  const gotoPublish = (values, payload) => {
    if (!publishCount) {
      setIsLoading(false)
      notify({
        message: `There are no actions in publish list`,
        type: "warn",
      });
      return;
    }
    setPublishStatus({
      UserEmail: user.email
    })

      .then((response) => {
        // props?.loadDropdownsData()
        setTimeout(
          () => {
            setIsLoading(false)
            getPendingActionsCount();
          },
          publishCount > 500 ? 5000 : 200
        );
        setTimeout(() => {
          getGridData({
            brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
            countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
            categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
            businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
            mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
            dealTypeId: props.dropDownValues?.dealType ?? [],
            materialNumberId: props.dropDownValues?.materialNumber ?? [],
            plant_code: props.dropDownValues?.plant ?? [],
            residualStatusId: props.dropDownValues?.residualStatus ?? [],
            languageOnPack:
              props.dropDownValues?.languageOnPack ?? [],
            page,
            columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
            per_page: itemPerPage,
            sortby: SortBy,
            complianceSorting: SortType,
            searchText: searchText ?? "",
          });
        }, 1000);
        setSelection([]);
        notify({
          message: `Sucessfully Published`,
          type: "success",
        });
      })
      .catch(() => {
        setIsLoading(false)
        notify({
          message: `Adding Publish status failed`,
          type: "error",
        });
      });
  };

  const getGridData = useCallback((payload) => {
    setState('Loading');
    // if (payload.businessGroupId?.length === 0) {
    //   payload.businessGroupId = userAccess?.bussinessGroupId
    // }
    // if (payload.countryId?.length === 0) {
    //   payload.countryId = userAccess?.countryId
    // }
    // if (payload.mcoId?.length === 0) {
    //   payload.mcoId = userAccess?.mcoId
    // }
    console.log(payload)
    WasteRiskDetails(payload).then((response) => {
      console.log("response.actions", response.data)
      setGridInfo(response.data)
      setActions(response.actions.filter(val => val.action_name !== "Eligible for Central Deal - UI"));
      setTotalRecordCount(response.count);
      setTotalCount(response.totalCount)
      setDealMonths(response.user_local_deal_completion_month)
      setPackTypeList(response.user_pack_type)
      setTimeout(() => { setState('Completed'); }, 200)
    }).catch(() => {
      setState('Completed');
    });;
  }, []);
  useEffect(() => {
    console.log(gridInfo)
  }, [gridInfo])

  const getPendingActionsCount = useCallback((payload) => {
    PendingActionsCount(payload).then((response) => {
      setPublishCount(response.pendingActionsCount);
    });
  }, []);

  const onActionClick = (val) => {
    if (selection.length === 0) {
      notify({
        message: `Please select one record!`,
        type: "warn",
      });
      return;
    } else {
      addToPublish(val);
    }
  };

  const MINUTE_MS = 5000;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fileUpdateChecker = (type) => {
    checkFileStatus({ uniqueTaskId: uniqueId, "task": type, "module": isFileBom ? "BOM" : "FG-SLOB" })
      .then((response) => {
        if (response.taskstatus === "completed") {
          setIsFileProcessed(false);
          setIsFileBom(false)
          setIsShowFilePopup(false)
          setIsFileStatusDone(false)
          if (
            type === "upload"
          ) {
            setIsTableLoading(true)
            setIsShowFilePopup(false)
            setTimeout(() => {
              getPendingActionsCount();
            }, 5000);

            notify({
              message: "File Upload Successfully!",
              type: "success",
            });
          } else {
            setIsFileBom(false)
            setIsShowFilePopup(false)
            setIsFileStatusDone(false)
            setIsFileProcessed(false)

          }
        }
      })
      .catch(() => {
        setIsFileBom(false)
        setIsFileProcessed(false);
        setIsFileStatusDone(false);
        setIsShowFilePopup(false);
      });
  };
  useEffect(() => {
    if (isFileProcessed || isFileStatusDone) {
      const interval = setInterval(() => {
        if (isFileProcessed) {
          fileUpdateChecker("download");
        } else if (isFileStatusDone) {
          fileUpdateChecker("upload");
        }
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [fileUpdateChecker, isFileProcessed, isFileStatusDone]);

  const downloadData = (payload) => {

    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadWasteRiskDetails(payload).then((response) => {
    });
  };
  const downloadBomData = (payload) => {
    setIsFileBom(true)
    let newUniqueId = v4();
    setUniqueId(newUniqueId);
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true);
    setIsShowFilePopup(true);
    downloadBomDetailsData(payload).then((response) => { });
  };

  const handleDownload = useCallback(() => {
    let payload = {
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
      dealTypeId: props.dropDownValues?.dealType ?? [],
      materialNumberId: props.dropDownValues?.materialNumber ?? [],
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
      plant_code: props.dropDownValues?.plant ?? [],
      residualStatusId: props.dropDownValues?.residualStatus ?? [],
      languageOnPack:
        props.dropDownValues?.languageOnPack ?? [],
      filename: "waste_risk_details",
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ?? "",
      referenceID: rowDownloadId.map((i => i.original.id)),
      // columnsSelected : columnsVisibilitySelected,
      selectedAttributes: "dealType,materialno,material_description,ean_zun,business_group,plants_sp_matl_status,plant_code,country,residual_status,mco,category,brand,l12_spf_gmrdr,l13_spfv_gmrdr,no_of_days_stock_pending_polaris,standard_price,min_floor_price_per_zun_for_central_deal_in_eur,tpm_provisions_eur,total_stock_zun,total_stock_eur,wk13_wk26_residual_zun,wk13_wk26_residual_eur,blockedStockZun,blockedStockEur,wk13_wk26_Res_BlockedStockZun,wk13_wk26_Res_BlockedStockEur,batch_code,manufacturing_date,previous_month_deal_type,comment_field,previous_month_comment_field,local_deal_completion_month,previous_month_local_deal_completion_date,local_deal_quantity_in_zun,local_deal_in_eur,central_deal_quantity_in_zun,central_deal_in_eur,remaining_quantity_in_zun,remaining_stock_in_eur,language_on_pack,production_country_name,expiry_date,pack_type,bom",
      //"dealType,materialno,material_description,plant_code,country,ean_zun,business_group,plants_sp_matl_status,residual_status,mco,category,brand,l12_spf_gmrdr,l13_spfv_gmrdr,no_of_days_stock_pending_polaris,standard_price,min_floor_price_per_zun_for_central_deal_in_eur,tpm_provisions_eur,total_stock_zun,total_stock_eur,wk13_wk26_residual_zun,wk13_wk26_residual_eur,blockedStockZun,blockedStockEur,wk13_wk26_Res_BlockedStockZun,wk13_wk26_Res_BlockedStockEur,batch_code,manufacturing_date,previous_month_deal_type,previous_month_comment_field,previous_month_local_deal_completion_date,local_deal_quantity_in_zun,local_deal_in_eur,central_deal_in_eur,remaining_quantity_in_zun,remaining_stock_in_eur,pack_type,bom,comment_field,local_deal_completion_month,central_deal_quantity_in_zun,language_on_pack,production_country_name,expiry_date"
    }
    if (hasColUpdated) {

      const updatedSelectedCol = selectedCol.filter(item => item?.trim() != "add_customer" && item?.trim() != "view_history")
      console.log(selectedCol)
      const editableArray = ["dealTypeId", "comment_field", "local_deal_completion_month", "central_deal_quantity_in_zun", "language_on_pack", "production_country_name", "expiry_date"]
      let array1 = [];
      let array2 = [];
      updatedSelectedCol.map(item => {
        if (editableArray.includes(item)) {
          array2.push(item)
        } else {
          array1.push(item)
        }
      })

      let combinedArray = [...array1, ...array2]
      let FixedColumns = ["dealTypeId", "materialno", "material_description", "plant_code", "country"]
      combinedArray = Array.from(new Set(FixedColumns.concat(combinedArray)));

      const dealtypeIndex = combinedArray.findIndex(i => i == "dealTypeId")
      if (dealtypeIndex != -1) {
        combinedArray[dealtypeIndex] = "dealType";
      }
      console.log("alllll", combinedArray)

      payload = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
        materialNumberId: props.dropDownValues?.materialNumber ?? [],
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
        dealTypeId: props.dropDownValues?.dealType ?? [],
        plant_code: props.dropDownValues?.plant ?? [],
        residualStatusId: props.dropDownValues?.residualStatus ?? [],
        languageOnPack:
          props.dropDownValues?.languageOnPack ?? [],
        filename: "waste_risk_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
        selectedAttributes: combinedArray.toString(),
        referenceID: rowDownloadId.map((i => i.original.id)),
      }
    }

    if (gridInfo.length > 0) {
      downloadData(payload);
    } else {
    }
    // if (gridInfo.length > 0) {
    //   //console.log("columnsVisibilitySelected------------------",columnsVisibilitySelected)
    //   downloadData({
    //     brandId: props.dropDownValues?.brand ?? [],
    //     countryId: props.dropDownValues?.country ?? [],
    //     categoryId: props.dropDownValues?.category ?? [],
    //     businessGroupId: props.dropDownValues?.businessGroup ?? [],
    //     dealTypeId: props.dropDownValues?.dealType ?? [],
    //     materialNumberId: props.dropDownValues?.materialNumber ?? [],
    //     mcoId: props.dropDownValues?.mco ?? [],
    //     plant_code: props.dropDownValues?.plant ?? [],
    //     residualStatusId: props.dropDownValues?.residualStatus ?? [],
    //     languageOnPack:
    //       props.dropDownValues?.languageOnPack ?? [],
    //     filename: "waste_risk_details",
    //     sortby: SortBy,
    //     complianceSorting: SortType,
    //     searchText: searchText ?? "",
    //     // columnsSelected : columnsVisibilitySelected,
    //   });
    // } else {
    // }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.brand,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.dealType,
    props.dropDownValues?.materialNumber,
    props.dropDownValues?.mco,
    props.dropDownValues?.plant,
    props.dropDownValues?.residualStatus,
    selectedCol,
    hasColUpdated,
    rowDownloadId,
  ]);

  const handleBomDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      downloadBomData({
        materialno: "",
        plantCodeId: "",
        filename: "bom_details",
      });
    } else {
    }
  }, [
  ]);

  const handleFileOpen = () => {
    fileUploadInput.current.click();
  };

  const uploadFileHandler = (event) => {
    const files = event.target.files[0];
    if (files) {
      let formData = new FormData();
      const fileType = files.name.indexOf(".xls") > 0;
      const fileSize = files.size / 1024 / 1024;
      const allowedFileSize = 10;

      if (fileType && fileSize < allowedFileSize) {
        formData.append("file", files);
        formData.append("UserEmail", user.email);
        setIsShowFilePopup(true)
        setIsFileProcessed(false)
        excelFileUpload(formData)
          .then((response) => {
            if (response.data.message == "SUCCESSFULLY_UPLOADED") {
              setIsFileStatusDone(true)
              setUniqueId(response.data.uniqueTaskId)
              // setIsLoading(false)

            }
          })
          .catch((error) => {
            setIsShowFilePopup(false)
            setIsFileProcessed(false)
            setIsFileStatusDone(false)
            notify({
              message: error,
              type: "error",
            });
          });
      } else if (fileSize > allowedFileSize) {
        notify({
          message: `File size exceeds ${allowedFileSize} MB`,
          type: "error",
        });
        files.value = null;
      } else {
        notify({
          message: "File format should be Excel only",
          type: "error",
        });
        files.value = null;
      }
    }
  };

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };

  const handleSearch = (val) => {
    setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(PerPageRecordNumber);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  useEffect(() => {
    getPendingActionsCount();
  }, [getPendingActionsCount]);
  useEffect(() => {
    refObj?.forEach((key) => {
      if (key[Object.keys(key)[0]].current) {
        if (key[Object.keys(key)[0]].current.state)
          key[Object.keys(key)[0]].current.state.value = "";
        else {
          key[Object.keys(key)[0]].current.value = "";
        }
      }
    });
    setFilterKey('');
    setFilterText('')
  }, [props.dropDownValues, searchText, clearColumnSearch]);
  useEffect(() => {
    if (props.dropDownValues && userAccess) {
      let payLoadData = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
        dealTypeId: props.dropDownValues?.dealType ?? [],
        materialNumberId: props.dropDownValues?.materialNumber ?? [],
        plant_code: props.dropDownValues?.plant ?? [],
        residualStatusId: props.dropDownValues?.residualStatus ?? [],
        languageOnPack:
          props.dropDownValues?.languageOnPack ?? [],
        page: 1,
        columnFilter: {},
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
      }
      getGridData(
        filterKey
          ? {
            ...payLoadData,
            datefilter: filterKey === "expiry_date" ? filterText : null,
            // columnFilter: filterKey,
            // columnFilterValue: filterKey === "expiry_date" ? null : filterText,
          }
          : payLoadData
      );
    }
  }, [getGridData, props.dropDownValues, clearColumnSearch]);
  useEffect(() => {
    if (props.dropDownValues) {
      let payLoadData = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
        dealTypeId: props.dropDownValues?.dealType ?? [],
        materialNumberId: props.dropDownValues?.materialNumber ?? [],
        plant_code: props.dropDownValues?.plant ?? [],
        residualStatusId: props.dropDownValues?.residualStatus ?? [],
        languageOnPack:
          props.dropDownValues?.languageOnPack ?? [],
        page,
        columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
      }
      getGridData(
        filterKey
          ? {
            ...payLoadData,
            datefilter: filterKey === "expiry_date" ? filterText : null,
            // columnFilter: filterKey,
            // columnFilterValue: filterKey === "expiry_date" ? null : filterText,
          }
          : payLoadData
      );
    }
  }, [SortBy, SortType, itemPerPage, page]);
  const [columnSearch, SetColumnSearch] = useState({});

  const filterData = (value, key) => {

    setIsLoading(true)
    const columnSearchAdd = {
      key: key,
      value: value,
    }
    if (filterKey === '') {
      console.log(filterKey)
      SetColumnSearch({ [columnSearchAdd.key]: columnSearchAdd.value ? columnSearchAdd.value : "" })
    } else {
      SetColumnSearch(prev => {
        const result = { [columnSearchAdd.key]: columnSearchAdd.value ? columnSearchAdd.value : "" }
        return { ...prev, ...result }
      })
    }
    setFilterKey(key);
    setFilterText(value);

  };

  useEffect(() => {
    setPageInd(0)
    let dateFilter;
    if ("expiry_date" in columnSearch) {
      dateFilter = columnSearch["expiry_date"];
    }
    else {
      dateFilter = null
    }
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand, userAccess?.fgSLOBdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.fgSLOBdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.fgSLOBdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.fgSLOBdata?.data?.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco, userAccess?.fgSLOBdata?.data?.mcoId),
      dealTypeId: props.dropDownValues?.dealType ?? [],
      materialNumberId: props.dropDownValues?.materialNumber ?? [],
      plant_code: props.dropDownValues?.plant ?? [],
      residualStatusId: props.dropDownValues?.residualStatus ?? [],
      languageOnPack:
        props.dropDownValues?.languageOnPack ?? [],
      page,
      per_page: itemPerPage,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      datefilter: dateFilter,
      columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
      // columnFilterValue: key === "expiry_date" ? null : value,
    });
    setTimeout(() => setIsLoading(false), 1000)

  }, [columnSearch])
  const toggleWarnMessageModal = () => {
    notify({
      message: `Please select sku's`,
      type: "warn",
    });
  };
  const warnAddSkustoPublishList = () => {
    notify({
      message: `Please add sku's to publish list first`,
      type: "warn",
    });
  }
  const updateCellData = (row, column, value, isBlur) => {
    const rowId = row.original.id;
    const updatedValue =
      column.id === "expiry_date" ? moment(value).format("DD-MM-YYYY") : value?.trim();
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);
    setIsTableLoading(true)
    setClickedIndex(null);
    setClickedCell(null);
    if (
      ((value && row.original[column.id] !== value) ||
        (!row.original[column.id] && value) ||
        (!value && row.original[column.id]) || ((column.id == "central_deal_quantity_in_zun" ||
          column.id == "local_deal_quantity_in_zun") && row.original[column.id] && !value)) && !isBlur
    ) {
      setPublishLoading(true)
      gridInfo?.forEach((val) => {
        if (val.id == rowId) {
          val["remaining_quantity_in_zun"] =
            (
              // (val["wk13_wk26_Res_BlockedStockZun"] ?? 0) -
              // (column.id == "local_deal_quantity_in_zun"
              //   ? (value ?? 0)
              //   : (val["local_deal_quantity_in_zun"] ?? 0)) -
              (column.id == "central_deal_quantity_in_zun"
                ? (value ?? 0)
                : (val["central_deal_quantity_in_zun"] ?? 0)));
        }
      });
      setGridInfo([...gridInfo]);
      row.original[column.id] = column.id === "expiry_date" ? updatedValue : value?.trim();
      UpdateWasteRiskCellData({
        id: rowId,
        referenceID: row.original.materialNumberId,
        country: row.original.country,
        countryId: row.original.countryId,
        plantCode: row.original.plantCodeId,
        descriptionId: row.original.descriptionId,
        UserEmail: user.email,
        key: column.id,
        updatedValue,
      })
        .then((response) => {
          gridInfo?.forEach((val) => {
            if (val.id == rowId) {
              val[column.id] = updatedValue;
              val["remaining_quantity_in_zun"] =
                (
                  // (val["wk13_wk26_Res_BlockedStockZun"] ?? 0) -
                  // (column.id == "local_deal_quantity_in_zun"
                  //   ? (value ?? 0)
                  //   : (val["local_deal_quantity_in_zun"] ?? 0)) -
                  (column.id == "central_deal_quantity_in_zun"
                    ? (value ?? 0)
                    : (val["central_deal_quantity_in_zun"] ?? 0)));
              if (column.id === "local_deal_quantity_in_zun") {
                val["local_deal_in_eur"] =
                  ((val["standard_price"] ??
                    0) * (val["local_deal_quantity_in_zun"] ??
                      0)).toFixed(2);
              } else if (column.id === "central_deal_quantity_in_zun") {
                val["central_deal_in_eur"] =
                  ((val["standard_price"] ??
                    0) * (val["central_deal_quantity_in_zun"] ??
                      0)).toFixed(2);
              }
            }
          });
          setGridInfo([...gridInfo]);
          if (response.message === "Successfully Initiated the record") {
            setTimeout(() => {
              getPendingActionsCount();
            }, 150);
            notify({
              message: "Successfully added to Publish List. Click Publish to save all changes",
              type: "success",
            });
          } else {
            notify({
              message: "Something Went Wrong!",
              type: "error",
            });
          }
        }, 100)
        .catch((error) => {
          notify({
            message: "No Response From Server. Please Try Again Later",
            type: "error",
          });
        });
    }
    setTimeout(() => setPublishLoading(false), 1000);
  };

  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    dealMonths,
    filterData,
    packTypeList,
    actions,
    onActionClick
  };
  //Edit cell code end here

  return (
    <MonthList.Provider value={dealMonths}>
      <AddToPublishModal
        show={showPublish}
        onHide={() => setShowPublish(false)}
        gotoPublish={gotoPublish}
        actions={actions}
      />
      <div className="main-body">
        <div className="row">
          <div className="">
            <div className="table-actions page-el">
              <button
                type="button"
                disabled={isLoading || userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
                // disabled={isLoading}
                className={
                  publishCount === 0
                    ? `btn-slim btn-published `
                    : `btn-slim btn-download btn-published btn-published-with-badge cursor-pointer`
                }
                onClick={() => {
                  publishCount > 0
                    ? setShowPublish(true)
                    : warnAddSkustoPublishList();
                }}
              >
                Add to Publish List
                {publishCount > 0 && (
                  <span className="badge badge-light">{publishCount}</span>
                )}
              </button>
              <button
                type="file"
                onClick={handleFileOpen}
                className="btn-slim btn-download"
                style={{ backgroundColor: "yellow" }}
                disabled={userAccess?.fgSLOBdata.data?.accessId === "1" ? true : false}
              >
                <img src={UploadIcon} alt="" /> Upload
              </button>
              <input
                type="file"
                name="file"
                id="input_uploadFileHandler"
                ref={fileUploadInput}
                onChange={uploadFileHandler}
                className="btn-download d-none"
              />
              <button
                type="button"
                className="btn-slim btn-download"
                onClick={handleBomDownload}
              >
                <img src={DownloadIcon} alt="" />
                BOM Download
              </button>
              <button
                type="button"
                className="btn-slim btn-download"
                // onClick={()=>{
                //   setDownloadClicked(Math.random(300));
                // }}
                onClick={handleDownload}
                style={{ backgroundColor: "yellow" }}
              >
                <img src={DownloadIcon} alt="" />
                Download
              </button>
            </div>
          </div>
          <StatusPopup
            onRefreshClick={() => {
              fileUpdateChecker(isFileProcessed ? "download" : "upload");
            }}
            show={isShowFilePopup}
            onHide={(value) => {
              setIsShowFilePopup(value);
            }}
          ></StatusPopup>

          <div>
            <div className="body-container  body-container-table-with-pagination waste_risk_details_page ">
              {gridInfo[0] !== "Loading" && (
                <>
                  {gridInfo && (
                    <TableGrid
                      state={state}
                      pageInd={pageInd}
                      setPageInd={setPageInd}
                      columns={COLUMNS}
                      data={gridInfo}
                      SortBy={SortBy}
                      SortType={SortType}
                      setSortBy={setSortBy}
                      setSortType={setSortType}
                      fetchData={fetchData}
                      pageCount={pageCount}
                      totalRecordCount={totalRecordCount}
                      pageSizeNo={itemPerPage}
                      dropDownValues={props.dropDownValues}
                      handleItemPerPage={handleItemPerPage}
                      handlePaginationReset={handlePaginationReset}
                      handleSearch={handleSearch}
                      handleEnterSearch={handleEnterSearch}
                      handleSearchReset={handleSearchReset}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      setSelection={setSelection}
                      selection={selection}
                      publishLoading={publishLoading}
                      toggleSelection={toggleSelection}
                      actions={actions}
                      refObj={refObj}
                      setRefObj={setRefObj}
                      onActionClick={onActionClick}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      getGridData={getGridData}
                      {...editCellProps}
                      props={props}
                      setIsSelectAll={setIsSelectAll}
                      toggleExpandCollapse={props.toggleExpandCollapse}
                      isExpand={props.isExpand}
                      toggleWarnMessageModal={toggleWarnMessageModal}
                      totalCount={totalCount}
                      isNewListSaved={isPublished}
                      page={"risk"}
                      SetColumnSelected={props.SetColumnSelected}
                      setSelectedCol={setSelectedCol}
                      setHasColUpdated={setHasColUpdated}
                      setRowDownloadIds={setRowDownloadIds}
                      rowDownloadId={rowDownloadId}
                      setColumnClearSearch={setColumnClearSearch}
                      clearColumnSearch={clearColumnSearch}

                    // downloadClicked={downloadClicked}
                    // handleColumnsSelected={handleColumnsSelected}
                    />
                  )}
                </>
              )}
              {gridInfo[0] === "Loading" && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </MonthList.Provider>
  );
};
export default WasteRiskDetailsTable;