import { API } from "../backend";
import axios from "axios";

export const getDealStatusSummary = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getDealStatusSummary`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getResidualVsProvisionData = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getResidualVsProvisionData`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getPendingResidualDaysData = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getPendingResidualDaysData`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getResidualAssignedToLocalDealData = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getResidualAssignedToLocalDealData`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDealStatusSummaryTableData = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getDealStatusSummaryTableData`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};
