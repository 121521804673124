import axios from "axios";
import { API } from "../backend";
// export const saveRecord = async (payload, isUser) => {
//   try {
//     let response = await axios.post(`${API}/store/createGroupUser`, payload);
//     return response;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };
export const getPOMGlossary = async () => {
    try {
        let response = await axios.get(`${API}/store/getPOMGlossary`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getInflowGlossary = async () => {
    try {
        let response = await axios.get(`${API}/store/getInflowGlossary`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getFgslobGlossary = async () => {
    try {
        let response = await axios.get(`${API}/store/getFgslobGlossary`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const savePOMGlossary = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/savePOMGlossary`, payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const saveInflowGlossary = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/saveInflowGlossary`, payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const saveFgslobGlossary = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/saveFgslobGlossary`, payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};

export const getPackType = async () => {
    try {
        let response = await axios.get(`${API}/store/getPackType`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getActionType = async () => {
    try {
        let response = await axios.get(`${API}/store/getActionType`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getNextStep = async () => {
    try {
        let response = await axios.get(`${API}/store/getNextStep`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getAdvice = async () => {
    try {
        let response = await axios.get(`${API}/store/getAdvice`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getDealTypeList = async () => {
    try {
        let response = await axios.get(`${API}/store/getDealTypeList`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const getCalendarDealType = async () => {
    try {
        let response = await axios.get(`${API}/store/getCalendarDealType`);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};


export const updateCalendarDealType = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updateCalendarDealType`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const updatePackType = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updatePackType`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const updateDealType = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updateDealType`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const updateActionType = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updateActionType`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const updateNextStep = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updateNextStep`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const updateAdvice = async (payload) => {
    try {
        let response = await axios.post(`${API}/store/updateAdvice`,payload);
        return response;
    } catch (err) {
        console.log("Error: ", err);
    }
};
export const saveAssignedOwner = async (payload) => {
  try {
    let response = await axios.post(
      `${API}/store/createUpdateAssignOwner`,
      payload
    );
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const getUserRoleDetails = async (payload) => {
    try {
      let response = await axios.post(
        `${API}/store/getUserRoleDetails`,
        payload
      );
      return response;
    } catch (err) {
      console.log("Error: ", err);
    }
  };

export const getUserData = async (payload) => {
  try {
    console.log(payload,"payloaduserData")
    let response = await axios.get(
        // `${API}/store/getAssignedOwnerDetails`,payload

      `${API}/store/getAssignedOwnerDetails?toolId=${payload.screenoption}&&businessGroupId=[${payload.businessGroupId}]&&mcoId=[${payload.mcoId}]&&countryId=[${payload.countryId}]&&brandId=[${payload.brandId}]&&categoryId=[${payload.categoryId}]&&nextStepId=[${payload.nextStepId}]&&ownerType=${payload.ownerType}`,
      
    );
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};