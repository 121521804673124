import React, { useState, useRef, useEffect, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { UserContext } from "../../Application";

const UserAdd = (props) => {
    const user = useContext(UserContext);
    const userEmail = useRef(null);
    const userName = useRef(null);
    const userAddBtn = useRef(null);
    const form = useRef(null);
    const [isFormValid, updateFormValidation] = useState(true);
    const handleNewUserAdd = () => {
        console.log("propsDropdownValuesUserAdd", props.dropdownvalues,props.ddlInfo)
        let newUser = {
            // user_name: userName.current.value,
            // email: userEmail.current.value,
            // tool: props.ddlInfo?.tool.filter(i=>i.key===props.dropdownvalues?.tool[0]),
            // businessGroup: props.ddlInfo?.businessGroup,
            // brand: props.ddlInfo?.brand,
            // category: props.ddlInfo?.category,
            // country: props.ddlInfo?.country,
            // mco: props.ddlInfo?.mco,
            // tabViewSetting: props.ddlInfo?.tabViewSetting,
            user_name: userName.current.value,
            email: userEmail.current.value,
            tool: props.dropdownvalues?.tool.length>2? props.dropdownvalues?.tool.slice(0,1): props.dropdownvalues?.tool,
            businessGroup: props.dropdownvalues?.businessGroup,
            brand: props.dropdownvalues?.brand,
            category: props.dropdownvalues?.category,
            country: props.dropdownvalues?.country,
            mco: props.dropdownvalues?.mco,
            tabViewSetting: props.dropdownvalues?.tabViewSettings,
            ddlInfo:JSON.stringify(props.ddlInfo)
        };
        props.setAddUserInfo(newUser)
        console.log("newUser",newUser)
        props.onNewUser(newUser);
        userEmail.current.value = '';
        userName.current.value = '';
        updateFormValidation(true)
        // userAddBtn.current.disabled = true;
    }

    const checkFormValidation = (e,type) => {
        
        var u1 = userName.current.value;
        var u2 = userEmail.current.value;
        console.log(u1,u2)
        let formInputValidation = [];
        var isFormValidBoolean = false;
        const regex_email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (regex_email.test(u2) === false) {
            isFormValidBoolean = false;
            formInputValidation.push(isFormValidBoolean);
        } else {
            props.setUserEmail?.(u2)
            isFormValidBoolean = true;
            formInputValidation.push(isFormValidBoolean);
        }
        const regex_un = /^[A-Za-z\s]*$/i;
        if (regex_un.test(u1) === false) {
            isFormValidBoolean = false;
            formInputValidation.push(isFormValidBoolean);
        } else {
            props.setUserName?.(u1)
            isFormValidBoolean = true;
            formInputValidation.push(isFormValidBoolean);
        }
        setTimeout(() => {
            console.log(formInputValidation, formInputValidation.some(g => g == false))
            let isFormInvalid = formInputValidation.some(g => g === false);
            console.log(isFormInvalid)
            if(u1===""){
                updateFormValidation(true);
            }else{
                updateFormValidation(isFormInvalid);
            }
            
            // if (!props.createType) props.onUserAddForm(isFormInvalid);
        }, 200); 
    }


    return (
        <>
            <div className="filterform_bg ddn-fluid w-100">
                <form ref={form}>
                <Grid container spacing={2}
                        hidden={user?.userRole === "User" ? true : false}>
                         <Grid item xs={4}>
                            <div className="ddn">
                                <div><label>User Name</label></div>
                                <input ref={userName} onChange={(e) => checkFormValidation(e, 'user')} className="bg-black" type="text" value={props.name} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="ddn">
                                <div><label>Email ID</label></div>
                                <input ref={userEmail} onChange={(e) => checkFormValidation(e, 'email')} className="bg-black" type="email" value={props.email} />
                            </div>
                        </Grid>
                        {true &&
                         <Grid item xs={4}>
                                <div className="ddn">
                                    <div><label>&nbsp;</label></div>
                                    <button type="button" ref={userAddBtn} onClick={() => handleNewUserAdd("new user added")} className={`btn-slim btn-primary pl-2 pr-2`} disabled={isFormValid} title={(!isFormValid) ? 'User details entered are Valid' : 'User details entered are Invalid'}>
                                        <span>Add User</span>
                                    </button>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </form>
            </div>
        </>
    )
}

export default UserAdd;