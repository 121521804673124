import React, { createContext, useState } from "react";

export const Context = createContext();

const Store = ({ children }) => {
  const [stateVal, setStateVal] = useState({});
  return (
    <Context.Provider
      value={[
        stateVal,
        setStateVal
      ]}
    >
      {children}
    </Context.Provider>
  );
};

export default Store;
