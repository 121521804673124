import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

let authParams = {
  clientId: process.env.REACT_APP_CLIENT_ID_DEV,
  authority: process.env.REACT_APP_AUTHORITY_DEV,
  redirectUri: process.env.REACT_APP_REDIRECT_URI_DEV,
  postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URI_DEV,
};

if (process.env.NODE_ENV === 'production') {
  authParams = {
    clientId: process.env.REACT_APP_CLIENT_ID_PROD,
    authority: process.env.REACT_APP_AUTHORITY_PROD,
    redirectUri: process.env.REACT_APP_REDIRECT_URI_PROD,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URI_PROD,
  };
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: authParams,
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  const request = {
    scopes: [
      'api://74536a92-8ef4-4eeb-bbc2-3524fd45b175/BWA-App-Access',
      'User.Read',
      'GroupMember.Read.All',
    ],
    account: activeAccount || accounts[0],
  };

  const authResult = await msalInstance.acquireTokenSilent(request);
  return authResult.accessToken;
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};

// console.log(authParams, process.env);