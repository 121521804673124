export const BlockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

//converted Object.entries() array to object
export const ArrayToObject = (array) => {
  return Array.from(array).reduce(
    (acc, [key, value]) => Object.assign(acc, { [key]: value }),
    {}
  );
};
//Alternative: Lodash -> _.fromPairs

export const arrayToKeyObject = (arr) => {
  return arr.reduce((obj, element) => {
    obj[element] = true;
    return obj;
  }, {});
};

export const setDropdownvaluesGlobal = (data1, data2) => {
  if (data1) {
    if (data1.length === 0) {
      return (data2)
    }
    else {
      return (data1)
    }
  }
  else { return (data2) }
}