import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: global.config.uri,
});



// Request interceptors for API calls
axiosInstance.interceptors.request.use(
  (config) => {
    console.log("????")
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem(
      'storageAccessToken'
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

////..........ax
const clearCookiesAndSiteData = () => {
  // Clear cookies
  document.cookie.split(";").forEach((cookie) => {
    document.cookie = cookie
      .replace(/^ +/, "")
      .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
  });

  // Clear site data
  if (navigator.userAgent.includes("Firefox")) {
    // For Firefox
    window.sitedata.clear();
    window.location.reload(true)
  } else if (navigator.userAgent.includes("Chrome")) {
    // For Chrome and other Chromium-based browsers
    window.caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        window.caches.delete(cacheName);
      });
    });
    window.location.href = "/"
  }
};

// Call the clearCookiesAndSiteData function to delete cookies and site data for the current URL

axiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401) {
    
    originalRequest._retry = true;
    
    setTimeout(() => {
      clearCookiesAndSiteData();
      
    }, 1000);
  
  }
  return Promise.reject(error);
});


axiosInstance.all = axios.all;
axiosInstance.spread = axios.spread;

export default axiosInstance;
