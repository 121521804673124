import React, { useContext, useState } from "react";
import { BlockInvalidChar } from "../../../../src/utility/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { UserAccessContext, UserContext } from "../../Application";
import ViewHistory from "./ViewHistory";

export const RiskDetailsEditCell = (cellObj) => {
  const { column, row, value } = cellObj.cell;
  const { original: { actionsList } } = row;
  const [val, setVal] = useState(value);
  const [isSelection, setIsSelection] = useState(false);
  const [viewHistoryModalOpen, setViewHistoryModalOpen] = useState(false);
  const userAccess = useContext(UserAccessContext)
  const handleCellChange = (e) => {
    if (e.target) {
      setIsSelection(true);
      if (column.id === "fc_adjustment_needed" || column.id === "actiontype") {
        cellObj.updateCellData(row, column, e.target.value);
      }
      if (column.id === "actiontype") {

        var action = (actionsList || []).find(v => v.id == e.target.value)
        setVal((action || {}).action_name);
      } else {
        setVal(e.target.value)
      }
    } else {
      setVal(e);
    }
  };

  const NumberField = () => {
    return (
      <input
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        type="number"
        value={val === null ? " " : val}
        onChange={handleCellChange}
        onBlur={() => cellObj.updateCellData(row, column, val)}
        onKeyDown={BlockInvalidChar}
      />
    );
  };

  const TextField = () => {
    return (
      <input
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        autoFocus
        type="text"
        value={val === null ? " " : val}
        onChange={handleCellChange}
        onBlur={() => cellObj.updateCellData(row, column, val)}
      />
    );
  };

  const DateSelection = () => {
    return (
      <DatePicker
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        portalId="root-portal"
        dateFormat="dd/MM/yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        onSelect={handleCellChange} //when day is clicked
        onChange={(date) => cellObj.updateCellData(row, column, date)} //only when value has changed
      />
    );
  };

  const RiskSelection = () => {
    return (
      <select
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        autoFocus
        value={isSelection ? val : val || value}
      >
        <option value="" selected>
          Select
        </option>
        {[
          "No Risk",
          "Slow Moving",
          "High Stocks",
          "No Forecast",
          "Over Delivery"
        ].map(value => <option value={value}>
          {value}
        </option>)}


      </select>
    );
  };

  const fcAdjustment = () => {
    return (
      <select
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        autoFocus
        value={val ?? value}
      >
        <option value="" selected>
          Select
        </option>
        {[
          "Yes ",
          "No",

        ].map((value) => {
          return <option value={value}>{value}</option>;
        })}
      </select>
    );
  };

  const actionType = () => {
    return (
      <select
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        autoFocus
      >
        <option value="">
          Select
        </option>
        {(actionsList || []).map(({ action_name: value, id }) => {
          return <option key={id} value={id} selected={value === val}>{value}</option>;
        })}
      </select>
    );
  };

  const RiskLevelSelection = () => {
    return (
      <select
        disabled={userAccess.inflowdata.data.accessId === "1" ? true : false}
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        autoFocus
        value={isSelection ? val : val || value}
      >
        <option value="" selected>
          Select
        </option>
        {[
          "High",
          "Medium",
          "Low"
        ].map(value => <option value={value}>
          {value}
        </option>)}
      </select>
    );
  };
  const customerAddition = () => {
    return <button type="button">sd</button>;
  };
  const user = useContext(UserContext);

  let FieldType;
  switch (column.id) {
    case "central_deal_quantity_in_zun":
      FieldType = NumberField;
      break;
    case "expiry_date":
      FieldType = DateSelection;
      break;
    case "view_history":
      FieldType = customerAddition;
      break;
    case "fc_adjustment_needed":
      FieldType = fcAdjustment;
      break;
    case "actiontype":
      FieldType = actionType;
      break;
    default:
      FieldType = TextField;
  }

  return (
    <>
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="edit-cell">
            {FieldType()}
          </div>
        )) || (
          <div
            className="readonly"
            title={value ? value : 'Click to edit cell value'}
            onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
            style={{ width: "100%", height: "100%" }}
          >
            {/* {val === "" ? value : val} */}
            {column.id === "expiry_date" && val
              ? Object.prototype.toString.call(val) === "[object Date]"
                ? moment(val).format("DD/MM/YYYY")
                : isSelection
                  ? val
                  : val || value
              : val || value}
          </div>
        )}
      {column.id === "view_history" && (
        <>
          <button
            type="button"
            className="btn btn-secondary btn-slim text-center"
            onClick={() => setViewHistoryModalOpen(true)}
            title=" View History"
          >
            View History
          </button>
          {viewHistoryModalOpen && <ViewHistory
            key={viewHistoryModalOpen}
            show={viewHistoryModalOpen}
            rowdata={[cellObj.cell.row.allCells]}
            onHide={() => setViewHistoryModalOpen(false)}
            record={row.original}
            reLoad={cellObj.reLoad}
            materialno={cellObj.cell.row.original.materialno}
            countryId={cellObj.cell.row.original.countryId}
            plantCode={cellObj.cell.row.original.plantCode}
            email={user.email}
          />}

        </>
      )}
    </>
  );
};

export const EditCell = (cellObj) => {
  const statusObj = {
    Accepted: "bg-teal",
    Rejected: "bg-red",
    Blank: "bg-blank",
  };
  const { column, row, value } = cellObj.cell;
  console.log("::: EditCell :::", { column, row, value })
  const [val, setVal] = useState(value);


  const handleCellChange = (e) => {
    if (e.target) setVal(e.target.value);
    else {
      setVal(e);
    }
  };

  const NumberField = () => {
    return (
      <div className="edit-cell">
        <input
          type="number"
          className="input-edit-mode"
          value={val === null ? " " : val}
          onChange={handleCellChange}
          onBlur={() => cellObj.updateCellData(row, column, val)}
          onKeyDown={BlockInvalidChar}
        />
      </div>
    );
  };

  const DateSelection = (cellObj) => {
    return (
      <DatePicker
        portalId="root-portal"
        dateFormat="dd/MM/yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        onChange={(date) => handleCellChange(date)}
        onBlur={(date) => cellObj.updateCellData(row, column, val)}
      />
    );
  };
  const StatusSelection = () => {
    return (
      <select
        onChange={handleCellChange}
        value={val}
        onBlur={() => cellObj.updateCellData(row, column, val)}
      >
        {cellObj.status.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };

  const TextField = () => {
    return (
      <div className="edit-cell">

        <input
          type="text"
          className="input-edit-mode"
          value={val === null ? " " : val}
          onChange={handleCellChange}
          onBlur={() => cellObj.updateCellData(row, column, val)}
        />
      </div>
    );
  };

  return (
    <>
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="td-active">

            {column.id === "central_deal_quantity_in_zun"
              ? NumberField()
              : column.id === "expiry_date"
                ? DateSelection(cellObj.cell)
                : column.id === "dealtype"
                  ? StatusSelection()
                  : TextField()}
          </div>
        )) ||
        (column.id === "deal_status" ? (
          <button
            type="button"
            className={`btn cursor-default btn-slim ${statusObj[cellObj.value]
              }`}
          >
            {cellObj.value}
          </button>
        ) : (
          <>
            <div
              className="readonly flex align-items-center"
              title={value ? value : 'Click to edit cell value'}
              onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
              style={{ width: "100%", height: "100%" }}
            >
              {column.id === "expiry_date" && val
                ? Object.prototype.toString.call(val) === "[object Date]"
                  ? moment(val).format("DD/MM/YYYY")
                  : val || value
                : val || value}
            </div>
          </>
        ))}

    </>
  );
};

export const DecimalToNum = (cellObj) => {

  const { column, row, value } = cellObj.cell;

  return (
    <>
      {/* {value ? typeof(value) == 'string'? value  : Math.floor(value)  : ""} */}
      {value ? typeof (value) == 'string' ? (isNaN(value) ? value : parseInt(value)) : Math.floor(value) : ""}
    </>
  );
};