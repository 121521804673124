import React, { useState, useEffect, useContext } from "react";
// import { Checkbox } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
// import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getNotificationSettings } from "../../../Services/userManagementService";
import { Tab, Tabs } from 'react-bootstrap'
import { UserContext } from "../../Application";
import AdminNotification from '../myNotifications/common/AdminNotification'
 
const NotificationSettings = (props) => {
  const [data,setData] = useState(null)
  const user = useContext(UserContext);
  const [notificationType, setNotificationType] = useState("ADMIN_NOTIFICATIONS")
  const handleTabClick = (type) => {
    setNotificationType(type);
    console.log("handleTabClick",type)
  };
  useEffect(() => {
    getNotificationSettings({
      email: user.email,
      notificationType: notificationType
    }).then(response => setData(response))
  }, [notificationType, user]);
  useEffect(() => {
    getNotificationSettings({
      email: user.email,
      notificationType: notificationType
    }).then(response => setData(response))
  }, []);
 
  return (
    <>
      {/* <Popup
        className="notificationSettings size-md"
        trigger={
          <button
            type="button"
            className="btn btn-control btn-primary btn-slim w-100">
            My Notifications
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <> */}
            {/* <div className="popup-header align-items-center justify-content-between ">
              <h5 className="mt-2">Notification Settings </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={close}
              ></button>
            </div> */}
            <div className=" ddn-fluid">
              {data && <div className=" position-relative ">
                <Tabs
                  id="controlled-tab-example"
                  className=" pull-left-imp mt-3"
                  defaultActiveKey={"ADMIN_NOTIFICATIONS"}
                  onSelect={handleTabClick}
                >
                  <Tab eventKey="ADMIN_NOTIFICATIONS" title="Admin" onClick={() => handleTabClick("ADMIN_NOTIFICATIONS")} >
                    <div className="notification-tabs" >
                      {data && <AdminNotification data={data} notificationType={notificationType} />}
                    </div>
                  </Tab>
                  <Tab eventKey="BIDDING_PORTAL_NOTIFICATIONS" title="Bidding Portal" onClick={() => handleTabClick("BIDDING_PORTAL_NOTIFICATIONS")}>
                    <div className="notification-tabs" >
                      {data && <AdminNotification data={data} notificationType={notificationType} />}
                    </div>
                  </Tab>
                </Tabs>
              </div>}
            </div>
          {/* </>
        )}
      </Popup> */}
    </>
  );
};
 
export default NotificationSettings;
 