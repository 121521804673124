import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Checkbox } from "../../Table/Checkbox";
import { UserContext } from "../../../Application";
import { updateNotificationSettings } from "../../../../Services/userManagementService";

function AdminNotification(props) {
  const {notificationType, modalOpen, closeModal}= props;
  const [notificationData, setNotificationData] = useState(props?.data?.notificationSettings);
  const [editData, setEditData] = useState([]);
  const user = useContext(UserContext);
  const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);


  useEffect(()=>{
    setNotificationData(props?.data?.notificationSettings)
    setEditData([])
  },[props?.data?.notificationSettings])

  const handleNotification = () => {
    const payload = {
      notificationSettings: editData,
      email: user.email,
      notificationType: notificationType,
    };
    if(props.GroupId) {
      console.log("GroupId", props.GroupId);
      payload.GroupId = props.GroupId;
    }
    setSaveLoaderInit(true);
    updateNotificationSettings(payload).then((response) => {
      
      if (response) {
        setSaveLoaderInit(false);
        let actionTaken = `Notification settings updated successfully`;
        let sccs = (
          <div className="tosterIcon">
            <div>{actionTaken}</div>
          </div>
        );
        toast.success(sccs, {
          containerId: "A",
        });

        if(props.groupNotification) props.onGroupNotificationModalClose();
        // closeModal()
      }
    }).catch(()=>setSaveLoaderInit(false))
  };

  const handleClickCheckbox = (ele, field, el) => {
    setNotificationData(
      notificationData.map((obj) => {
        if (obj.id === ele.id) {
          for (const key of Object.keys(obj)) {
            if (field === key) {
              // console.log("field === key",field,key)
              if(key === "notificationMessage") {
                let mssgValue = el.target.value;
                console.log("field === key",field,mssgValue)
                obj[key] = mssgValue;
              } else {
                obj[key] = !obj[key];
              }
            }
          }
          let exists = editData.find((i) => i?.id === obj.id);
          if (exists) {
            setEditData(
              editData.map((i) => {
                return i.id === exists.id ? obj : i;
              })
            );
          } else {
            setEditData([...editData, obj]);
          }
          return obj;
        }
        return obj;
      })
    );
  };

  useEffect(()=>{
      console.log("editData",editData)
  })

  return (
    <>
      <div className="table_design w-100">
        <div className="table sticky ">
          <div className="header w-100">
            <div className="tr ">
              <div
                className="th"
                style={
                  notificationType === "ADMIN_NOTIFICATIONS"
                    ? { width: "40%", minWidth: "40%" }
                    : { width: "30%", minWidth: "30%" }
                }
              >
                <div className="th-in">
                  <span className="th-text">Notification</span>
                </div>
              </div>
              {notificationType === "ADMIN_NOTIFICATIONS" && (
                <>
                  <div
                    className="th"
                    style={{ width: "10%", minWidth: "10%" }}
                  >
                    <div className="th-in">
                      <span className="th-text">Email</span>
                    </div>
                  </div>
                  <div
                    className="th"
                    style={{ width: "10%", minWidth: "10%" }}
                  >
                    <div className="th-in">
                      <span className="th-text">Bell Icon</span>
                    </div>
                  </div>
                  <div className="th"  style={{ width: "40%", minWidth: "40%" }} >
                    <div className="th-in" style={{ width: "150px" }}>
                      <span className="th-text">Message</span>
                    </div>
                  </div>
                </>
              )}
              {notificationType === "BIDDING_PORTAL_NOTIFICATIONS" && (
                <>
                  <div
                    className="th"
                    style={{ width: "10%", minWidth: "10%" }}
                  >
                    <div className="th-in">
                      <span className="th-text">Email</span>
                    </div>
                  </div>
                  <div
                    className="th"
                    style={{ width: "10%", minWidth: "10%" }}
                  >
                    <div className="th-in">
                      <span className="th-text">Bell Icon</span>
                    </div>
                  </div>
                  <div className="th" style={{ width: "10%", minWidth: "10%" }}>
                    <div className="th-in">
                      <span className="th-text">Admin</span>
                    </div>
                  </div>
                  <div className="th" style={{ width: "14%", minWidth: "14%" }}>
                    <div className="th-in" style={{ width: "150px" }}>
                      <span className="th-text">Super Admin</span>{" "}
                    </div>
                  </div>
                  <div className="th"  style={{ width: "40%", minWidth: "40%" }} >
                    <div className="th-in" style={{ width: "150px" }}>
                      <span className="th-text">Message</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="body w-100">
            {notificationData !== undefined && notificationData?.map((ele, key) => {
              return (
                <div className="tr" key={key}>
                  <div
                    className="td "
                    style={
                      notificationType === "ADMIN_NOTIFICATIONS"
                        ? { width: "40%", minWidth: "40%" }
                        : { width: "30%", minWidth: "30%" }
                    }
                  >
                    <div className="td-in "  title={ele.notificationLabel}>{ele.notificationLabel}</div>
                  </div>
                  {notificationType === "ADMIN_NOTIFICATIONS" && (
                    <>
                      <div
                        className="td "
                        style={{ width: "10%", minWidth: "10%" }}
                      >
                        <div 
                          className="td-in w-100" 
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.emailSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "emailSetting")
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="td "
                        style={{ width: "10%", minWidth: "10%" }}
                      >
                        <div
                          className="td-in w-100"
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.bellIconSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "bellIconSetting")
                            }
                          />
                        </div>
                      </div>
                      <div className="td " style={{ width: "40%", minWidth: "40%" }}
                        >
                          <div className="td-in w-100">
                            {/* {ele.notificationMessage} */}
                            {/* <div className="redonly" >{"message"}</div> */}
                            <div className="edit-cell w-100">
                         
                            <textarea type="text" placeholder="Your message" 
                            className="w-100"
                            value={ele.notificationMessage != null ? ele.notificationMessage : ''}
                            name={`admin${key}`}
                            // id={`admin${key}`}
                            onChange={(e)=>handleClickCheckbox(ele, "notificationMessage", e)}
                            ></textarea>
                            {/* <input
                              type="text"
                              className="input-edit-mode"
                              value={ele.notificationMessage}
                              onChange={(e)=>handleClickCheckbox(ele, "notificationMessage", e)}
                            /> */}
                          </div>
                          </div>
                      </div>
                    </> 
                  )}
                  {notificationType === "BIDDING_PORTAL_NOTIFICATIONS" && (
                    <>
                      <div
                        className="td "
                        style={{ width: "10%", minWidth: "10%" }}
                      >
                        <div
                          className="td-in w-100"
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.emailSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "emailSetting")
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="td "
                        style={{ width: "10%", minWidth: "10%" }}
                      >
                        <div
                          className="td-in w-100"
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.bellIconSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "bellIconSetting")
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="td "
                        style={{ width: "10%", minWidth: "10%" }}
                      >
                        <div
                          className="td-in w-100"
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.notifyToAdminSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "notifyToAdminSetting")
                            }
                          />
                        </div>
                      </div>
                      <div
                        className="td "
                        style={{ width: "14%", minWidth: "14%" }}
                      >
                        <div
                          className="td-in w-100"
                          style={{ textAlign: "center" }}
                        >
                          <Checkbox
                            checked={ele.notifyToSuperAdminSetting}
                            className="list-checkbox"
                            onChange={(_) =>
                              handleClickCheckbox(ele, "notifyToSuperAdminSetting")
                            }
                          />
                        </div>
                      </div>
                      <div className="td " style={{ width: "40%", minWidth: "40%" }}
                        >
                          <div className="td-in w-100">
                            {/* {ele.notificationMessage} */}
                            {/* <div className="redonly" >{"message"}</div> */}
                            <div className="edit-cell w-100">
                            
                            <textarea type="text" placeholder="Your message" 
                            className="w-100"
                            // id={`biddingPortal${key}`}
                            name={`biddingPortal${key}`}
                            value={ele.notificationMessage != null ? ele.notificationMessage : ''}
                            onChange={(e)=>handleClickCheckbox(ele, "notificationMessage", e)}
                            ></textarea>
                            {/* <input
                              type="text"
                              className="input-edit-mode"
                              value={ele.notificationMessage}
                              onChange={()=>handleClickCheckbox(ele, "notificationMessage")}
                            /> */}
                          </div>
                          </div>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className={`d-flex justify-content-end align-items-center w-100 ${props.groupNotification ? 'mt-3' : ''}`}>
        <div className="btn-blocker">
          {isSetSaveLoaderInit && <div className="btn-blocker-overlay"></div>}
          <button
            className={`btn btn-primary btn-std-width ${isSetSaveLoaderInit ? 'processing' : ''} ${!props.groupNotification ? 'btn-big' : ''}`}
            onClick={handleNotification}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default AdminNotification;
