import React, { useState, useEffect, useContext } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Table from "../Table/ControlAdminsGroupTable";
import { getUserGroups, saveRecord } from "../../../Services/userManagementService";
import { UserContext } from "../../Application";

const ControlAdminsGroup = (props) => {
  const [createGroup, setCreateGroup] = useState(false);
  const [initEditGroupName, setInitEditGroupName] = useState("");
  const [tableData,setTableData] = useState([]);
  const [mainData,setMaindata] = useState([])
  const user = useContext(UserContext);
  const mcoData = [
    { key: '3', value: 'Benelux' },
    { key: '12', value: 'Czech Republic & Slovakia' },
    { key: '13', value: 'DACH' },
    { key: '10', value: 'France' },
    { key: '7', value: 'Greater Caribbean' },
    { key: '4', value: 'Hungary Group' },
    { key: '2', value: 'Italy' },
    { key: '6', value: 'Nordic' },
    { key: '5', value: 'Poland Baltics and Ukraine' },
    { key: '14', value: 'South Africa' },
    { key: '9', value: 'South Central Europe' },
    { key: '1', value: 'South East Europe' },
    { key: '11', value: 'Spain' },
    { key: '8', value: 'UK' }
  ];
  const businessGroup = [
    { key: '2', value: 'Beauty & Wellbeing' },
    { key: '3', value: 'Home Care' },
    { key: '1', value: 'Personal Care' },
  ];
  useEffect(() => {
    if(user.email)
    getUserGroups({
      UserEmail: user.email,
      UserRoleId: user.userRole==="SuperAdmin"?1:user.userRole==="Admin"?2:3,
      searchText: ""
    }).then(response => setMaindata(response.data))
  }, [user.email]);

  useEffect(() => {
    if (props.createType == "Group") {
      setCreateGroup(true);
    } else {
      setCreateGroup(false);
    }
  }, [props.createType]);

  useEffect(()=>{
    const groupData = [...new Set(mainData?.map(({ GroupId, GroupName ,Permission,UserRole,CreatedBy}) => ({
        GroupId,
        GroupName,
        Permission,
        UserRole,
        CreatedBy
      }))
      .filter(e => e.GroupId != null)
      .filter(e=>e.CreatedBy===user.email)
      .map(JSON.stringify))
    ].map(JSON.parse)
    const dummuy =  groupData.map((groupData)=>({
        group_name:groupData.GroupName,
        // group_type: groupData.UserRole,
        total_members:mainData.filter(i=>(i.GroupId===groupData.GroupId)).map((i)=>(i.UserFirstName+" "+i.UserLastName)),
        members_list:mainData.filter(i=>(i.GroupId===groupData.GroupId)).length,
        admin_name:groupData.CreatedBy,
        // mco:JSON.parse(groupData?.Permission)?.filterSettings?.mco.map(id => {
        //   const matchingItem = mcoData.find(item => item.key === id);
        //   return matchingItem ? matchingItem.value : "Unknown";}),
        // bussiness_group:JSON.parse(groupData.Permission)?.filterSettings?.businessGroup?.map(id => {
        //   const matchingItem = businessGroup.find(item => item.key === id);
        //   return matchingItem ? matchingItem.value : "Unknown";}),
      }))
      console.log(dummuy);
      setTableData(dummuy)
  },[mainData])

  useEffect(()=>{
    console.log(tableData)
  },[tableData])

  useEffect(() => {
    setInitEditGroupName(props.name);
  }, [props.name]);

  const checkFilled = (val) => {
    if (!props.createTypeLock) {
      if (val == "") {
        setCreateGroup(false);
      } else {
        setCreateGroup(true);
      }
    }
    console.log("checkFilled", val);
  };
  const saveType = () => {

  };
  return (
    <>
      <Popup
        className="glossary-add-edit-popup size-md-lg"
        trigger={
          <button
            type="button"
            className="btn btn-control btn-primary btn-slim w-100">
            Control Groups
          </button>
        }
        modal
        nested
      >
        {(close) => (
          <>
            <div className="popup-header d-flex align-items-center justify-content-between ">
              <h5 className="mt-2">Control Groups </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={close}
              ></button>
            </div>
            <div className="content">
              <div className="scroll-content position-relative">
                <div className={`row`}>
                  <div className="col-md-12">
                    <div className="datatable">
                      <Table data={tableData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Popup>
    </>
  );
};

export default ControlAdminsGroup;
