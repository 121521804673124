import React, { useState , useEffect} from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";


const Confirm = (props) => {
   useEffect(()=>{
    console.log(props.openConfirm)
   },[props.openConfirm])
    return (
        <>
             <Popup className="size-sm"
                arrow={true}
                open={props.openConfirm} 
                onClose={()=>props.setCloseConfirm(false)}
                modal
                tooltip
                nested
            >
                <div id="ccp" className="popup-header d-flex align-items-center justify-content-between">
                    <h5 className="mt-2">Preference Retention Alert</h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            props.setCloseConfirm(false)
                        }}
                    ></button>
                </div>
                <div className="content h-100">
                    <p>Your filter settings will be saved and applied automatically in future sessions.</p>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-5">
                  <div></div>
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    title={"Click to confirm"}
                    onClick={() => {
                        props.setCloseConfirm(false)
                    }}
                  >
                    Confirm
                  </button> */}
                </div>
            </Popup>
        </>
    )
}

export default Confirm;