import React, { useContext, useEffect, useState } from 'react'
import "chart.js/auto";
import { Chart } from 'react-chartjs-2'

import Loader from "../../../Common/loader";
import { LABEL_COLOR, LEGEND_COLOR, chunkString } from '../../InflowRiskVisibility/helpers';
import { GetInflowActionChart } from '../../../Services/InflowSummaryService';
import { setDropdownvaluesGlobal } from '../../../utility/helper';
import { UserAccessContext } from '../../Application';

function MitigationActionBarGraph(props) {
  const [resData, setResData] = useState();
  const userAccess = useContext(UserAccessContext)
  useEffect(() => {
    let payload = {
      brandId: setDropdownvaluesGlobal(props.dropdownValues?.brand, userAccess?.inflowdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropdownValues?.country, userAccess?.inflowdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropdownValues?.category, userAccess?.inflowdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropdownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
      // mcoId: setDropdownvaluesGlobal(props.dropdownValues?.mco, userAccess?.inflowdata?.data?.mcoId),

      // businessGroupId: props.dropdownValues?.businessGroup ?? [],
      actionTypeId: props.dropdownValues?.actiontype ?? [],
      // brandId: props.dropdownValues?.brand ?? [],
      // countryId: props.dropdownValues?.country ?? [],
      // categoryId: props.dropdownValues?.category ?? [],
      foodSolutionIndicatorId: props.dropdownValues?.fsIndicator ?? [],
      plantCodeId: props.dropdownValues?.plant ?? [],
      riskTypeId: props.dropdownValues?.riskType ?? [],
      riskLevelId: props.dropdownValues?.riskLevel ?? [],
    }
    if (props.dropdownValues)
      GetInflowActionChart(payload).then(response => setResData(response))
  }, [props.dropdownValues])

  const options = {

    elements: {
      point: {
        radius: 2,
      },
    },
    plugins: {
      tooltip: {
        intersect: false
      },
      legend: {
        display: false,
        position: "top",
        align: "center",
        labels: {
          pointStyle: "circle",
          usePointStyle: true,
          padding: 20,
          color: LEGEND_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },
    },
    responsive: true,
    barThickness: 40,
    // width:300,
    height: 300,
    // aspectRatio: 1.1,
    scales: {
      x: {
        gird: {
          display: false,
        },
        ticks: {
          maxRotation: 0,// Set the maximum rotation angle to 0 degrees (horizontal)
          minRotation: 0,
          maxTicksLimit: 0,
          align: "left",
          color: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
          callback(value) {
            return chunkString(this.getLabelForValue(value), 10);
          },
        },
      },
      y: {
        gird: {
          display: false,
        },
        display: true,
        ticks: {
          // stepSize: 10000,
          //   callback: (value) => `€ ${(value / 1000000).toFixed()}M`,
          color: LABEL_COLOR,
          font: {
            family: "unilevershilling",
          },
        }
      },
    },
  };
  const labels = ["Hard switch", "Soft switch", "Postpone production", "Cancel production", "Base adjustment", "Promo adjustment", "No adjustment", "Blank"]
  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        // label: "Residual Stock in €",
        backgroundColor: "rgb(143, 202, 235)",
        data: [
          resData?.filter(i => i.actiontype === "Hard Switch").length,
          resData?.filter(i => i.actiontype === "Soft Switch").length,
          resData?.filter(i => i.actiontype === "Postpone Production").length,
          resData?.filter(i => i.actiontype === "Cancel Production").length,
          resData?.filter(i => i.actiontype === "Base Adjustment").length,
          resData?.filter(i => i.actiontype === "Promo Adjustment").length,
          resData?.filter(i => i.actiontype === "No Adjustment").length,
          resData?.filter(i => (i.actiontype === "" || i.actiontype === null || i.actiontype === "blank")).length
        ],
        datalabels: {
          display: false,
          align: "top",
          anchor: "end",
          offset: -3,
          color: LABEL_COLOR,
          font: {
            size: 10,
            family: "unilevershilling",
          },
        },
      },
    ],
  };
  return <Chart type="bar" data={data} options={options} />
}

export default MitigationActionBarGraph