import React, { useCallback, useContext, useEffect, useState } from "react";
import ULModal from "../../../Common/Modal";
import { getSkuBatchCode } from "../../../Services/PomService";
import { notify } from "../../../Common/toast";
import Loader from "../../../Common/loader";

const AddView = (props) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getDetails = useCallback((payload) => {
    setIsLoading(true);
    getSkuBatchCode(payload)
      .then((response) => {
        if (response.batchCodeDetails)
          setData(response.batchCodeDetails);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        notify({
          type: "error",
          message: "Something went wrong",
        });
      });
  }, []);

  useEffect(() => {
    if (props.show)
      getDetails({
        plantCode: [props.record.plant_code],
        materialno: [props.record.mrdr_id],
        countryCode: [],
        reasonCode: [props.record.status_id],
        shortShelfLife: [props.record.hah_date],
      });
  }, [getDetails, props.record, props.show]);

  const codes = data.map((row) => ({batchCode: row.batchCode,obsoleteQuantity: row.obsoleteQuantity}));
   
  return (
    <ULModal
      show={props.show}
      onHide={props.onHide}
      size="md"
      isheader="true"
      heading={
        props.rowdata[0][0].column.Header.id == "id"
          ? props.rowdata[0].value
          : ""
      }
      className={`page-modal viewBatchCodePopup`}>
      <div className="table_design d-flex">
        <div className={`table sticky table-two-column cells-width-auto ${(codes.length <= 0) ? 'no-data' : ''}`}>
          <div className="header w-100">
            <div className="tr">
              <div className="th">Batch Code</div>
              <div className="th">Obsoletes quantity</div>
            </div>
          </div>
          {codes?.length && (
            <div className="body w-100">
              {codes.map((row) => (
                <div className="tr" key={row}>
                  <div className="td">{row.batchCode}</div>
                  <div className="td">{row.obsoleteQuantity}</div>
                </div>
              ))}
            </div>
          )}
          {!codes.length && !isLoading && (
            <div className="body">
            <div className="tr w-100">
              <div role="cell" className="td">
                <span className="td-in text-center">No Data</span>
              </div>
            </div>
          </div>
          )}
          {!codes.length && isLoading && (
            <div className="body">
            <div className="tr w-100">
              <div role="cell" className="td">
                <span className="td-in text-center">
                  <Loader hideText={true} />
                </span>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </ULModal>
  );
};

export default AddView;
