import { DecimalToNum, RiskDetailsEditCell } from "./CellAction"; //CellCheckBox
import ULDropdown from "../../../../src/Common/Dropdown";

 
export const COLUMNS = [
  {
    Header: "MRDR",
    accessor: "product_code",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "MRDR Description",
    accessor: "description",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "Brand",
    accessor: "brand",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "Plant Code",
    accessor :"plantCode",
    show: true,
    editable: false,
    // sticky: true,
  },
  {
    Header: "Country",
    accessor :"country",
    show: true,
    editable: false,
    // sticky: true,
  },
  {
    Header: "Category",
    accessor :"category",
    show: true,
    editable: false,
  },
  {
    Header: "Business Group",
    accessor :"business_group",
    show: true,
    editable: false,
  },
  {
    Header: "Risk Type",
    accessor: "risk_type",
    show: true,
    editable: false
  },
  {
    Header: "DOH vs ISL Risk Level",
    accessor: "risk_level",
    show: true,
    editable: false
  },
  {
    Header: "Stock Today",
    accessor: "stock_today",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "Expected Inbounds_PO",
    accessor: "expected_inbounds_po",
    show: true,
    editable: false,
    Cell: DecimalToNum,
  },
  {
    Header: "Expected Inbounds_PurRqs",
    accessor: "expected_inbounds_purRqs",
    show: true,
    editable: false,
    Cell: DecimalToNum,
  },
  {
    Header: "Expected Inbounds",
    accessor: "expected_inbounds",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "Expected Outbounds",
    accessor: "expected_outbounds",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "Stock Remaining",
    accessor: "total_risk",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "Max DoH",
    accessor: "max_doh",
    show: true,
    editable: false,
    
  },
  {
    Header: "ISL",
    accessor: "isl",
    show: true,
    editable: false,
    round_off_values_no_decimal: true
  },
  {
    Header: "Actuals L4W",
    accessor: "actuals_l4w",
    show: true,
    editable: false,
    format_number:false
    
  },
  {
    Header: "FC L4W",
    accessor: "fc_l4w",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "ABS ERR L4W",
    accessor: "abs_err_l4w",
    show: true,
    editable: false,
  },
  {
    Header: "Bias%_L4W",
    accessor: "fb_l4w",
    show: true,
    editable: false,
    show_precentage_symbol: true
  },
  {
    Header: "Forecast Bias L4W",
    accessor: "forecast_bias_l4w",
    show: true,
    editable: false,
  },
  {
    Header: "FC Next 4W",
    accessor: "fc_next_l4w",
    show: true,
    editable: false,
    format_number:false
  },
  // {
  //   Header: "FC Diagnostics L4W",
  //   accessor: "fc_diagnostics_l4w",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "FC Key Drivers L4W",
  //   accessor: "fc_Key_drivers_l4w",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "FC Key Gaps L4W",
  //   accessor: "fc_Gaps_l4w",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "FC Bias Level L4W %",
  //   accessor: "fc_Bias_level_l4w",
  //   show: true,
  //   editable: false,
  //   //Cell: RiskDetailsEditCell,
  // },
  // {
  //   Header: "Echo Action",
  //   accessor: "echo_action",
  //   show: true,
  //   editable: false,
  // },
  {
    Header: "Actuals L12W",
    accessor: "actuals_l12w",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "FC L12W",
    accessor: "fc_l12w",
    show: true,
    editable: false,
    format_number:false
  },
  {
    Header: "ABS ERR L12W",
    accessor: "abs_err_l12w",
    show: true,
    editable: false,
  },
  {
    Header: "Bias%_L12W",
    accessor: "week12_x_4_bias",
    show: true,
    editable: false,
    show_percentage:true,
    round_off_values_no_decimal: true
  },
  {
    Header: "Forecast Bias L12W",
    accessor: "forecast_bias_l12w",
    show: true,
    editable: false,
  },
  // {
  //   Header: "Comment Last week",
  //   accessor: "comment_last_week",
  //   show: true,
  //   editable: true,
  //   Cell: RiskDetailsEditCell,
  // },
  {
    Header: "Comment Waste Lead",
    accessor: "comment_ww",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Owner Short Term planner",
    accessor: "owner_scol",
    show: true,
    editable: true,
    Cell:(cellObj) => {
      return (
        <span
        >
          {cellObj.value.replace(/["\[\]]/g, '')}
        </span>
      );
    },
    // Cell: RiskDetailsEditCell,
  },
  
  
  {
    Header: "Comment Short Term Planner",
    accessor: "comment_scol",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "FC adjustment needed?",
    accessor: "fc_adjustment_needed",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Owner Demand Planner",
    accessor: "owner_feo",
    show: true,
    editable: true,
    Cell:(cellObj) => {
      return (
        <span
        >
          {cellObj.value.replace(/["\[\]]/g, '')}
        </span>
      );
    },
    // Cell: RiskDetailsEditCell,
  },
  {
    Header: "Comment Demand Planner",
    accessor: "comment_feo",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
 
  {
    Header: "Action Type",
    accessor :"actiontype",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Forecast Family",
    accessor: "forecast_family",
    show: true,
    editable: false,
  },
  {
    Header: "Food Solution Indicator",
    accessor: "foodSolutionIndicator",
    show: true,
    editable: false,
  },
  {
    Header: "EAN_CS",
    accessor: "EAN_CS",
    show: true,
    editable: false,
  },
  {
    Header: "MOQ",
    accessor: "MOQ",
    show: true,
    editable: false,
    Cell: DecimalToNum,
  },
  
  {
    Header: "Update date",
    accessor: "processedDate",
    show: true,
    editable: false,
  },
  {
    Header: "Version History",
    accessor: "view_history",
    show: true,
    editable: false,
    body_class: "text-center",
    Cell: RiskDetailsEditCell
  },

  
];

export const columnHeaderByKey = COLUMNS.reduce((prev, curr) => {
  prev[curr.accessor] = curr.Header;
  return prev;
}, {})
