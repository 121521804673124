import React, { useContext, useEffect, useRef, useState } from "react";
import { Chart, Pie } from "react-chartjs-2";
import { chunkString, LABEL_COLOR, LEGEND_COLOR, PayloadWithFilters } from "../../Pom/PomSummary/helpers";
import { getObsoletesSalesMigrationSummary } from "../../../Services/PomSummaryService"
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const ObsoletesChart = ({ dropDownValues }) => {

    const [chartData, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userAccess = useContext(UserAccessContext)


    const getApiData = async (payload) => {
        try {
            const response = await getObsoletesSalesMigrationSummary(payload);
            console.log("response", response);
            setData(response);
            // setData([
            //     {
            //         "keyName": "sales mitigation",
            //         "keyValue": 5677
            //     },
            //     {
            //         "keyName": "no sales mitigation",
            //         "keyValue": 43565
            //     }
            // ])
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); // Update loading state after fetching
        }
    };

    useEffect(() => {
        if (dropDownValues !== undefined) getApiData(PayloadWithFilters(dropDownValues,userAccess));
    }, [dropDownValues]);

    const options = {
        responsive: true,
        barThickness: 25,
        maintainAspectRatio: false,
        layout: {
            padding: {
                bottom: 20, // Adjust this value to create enough space for x-axis labels below the chart
            },

        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        console.log(":: context ::");
                        let label = context.label || "";
                        if (label) {
                            label += ": ";
                        }
                        if (context.parsed !== null) {
                            // label += (context.parsed / 10000000).toFixed(2) + "%";
                            var percentage = context.parsed / context.dataset.data.reduce((a, b) => +a + +b);
                            label +=(percentage * 100).toFixed(2) + "%"
                            // commaify((context.parsed.x/1000000)>1?Math.round(context.parsed.x/1000000):(context.parsed.x/1000000).toFixed(2)) +`M`;
                        }
                        return label;
                    },
                },
            },
            legend: {
                display: true,
                align: "center",

                labels: {
                    pointStyle: "square",
                    usePointStyle: false,
                    padding: 20,
                    color: LEGEND_COLOR,
                    font: {
                        size: 10,
                        family: "unilevershilling",
                    },

                },
            },

            datalabels: {
                // anchor: "end",
                // align: "end",
                display: true,
                color: "white",
                font: {
                    size: 15,
                    family: "unilevershilling",
                },
                formatter: (value, context, ctx) => {
                    console.log(value, context)
                    if (value !== 0.00) {
                        var percentage = value / context.dataset.data.reduce((a, b) => +a + +b)
                        return (percentage * (100)).toFixed() + '%'
                    } else {
                        return " "
                    }
                },

            },
        },

    };

    const COLORS = {
        'value - sales mitigation': '#16A5F8',
        'value - no sales mitigation': 'rgb(255, 102, 0)',
    }


    const pieChartData = {
        labels: chartData?.map((row) => row.keyName),
        datasets: [
            {
                data: chartData?.map((data) => data.keyValue),
                backgroundColor: chartData?.map((data) => COLORS[data.keyName]),
            },
        ],
    };

    if (isLoading) {
        return <Loader />; // Display loader while data is loading
    }

    return (
        <Pie

            data={pieChartData}
            options={options}

        />
    );
}

export default ObsoletesChart;
