import React, { useMemo, useEffect, useState, useCallback, useRef, useContext } from "react";
import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { pageTopElHt, tableToolTip } from "../../../utility/constants";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  useBlockLayout,
  usePagination,
  useTable,
  useRowSelect,
  ColumnResizeMode,
  useFilters,
  useResizeColumns,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { Styles } from "./TableStyles";
import ReactTablePagination from "../../../Common/react-table-pagination";
import ULDropdown from "../../../Common/Dropdown";
import { notify } from "../../../../src/Common/toast";
import OpenIcon from "../../../Assets/img/open.svg";
import SearchIcon from "../../../Assets/img/search.svg";
import ClickIcon from "../../../Assets/img/clickicon.svg";
import resizeIcon from "../../../Assets/img/icon-resize.svg";
import PinchIcon from "../../../Assets/img/icon-pinch.svg";
import Glossary from "../../../Common/glossary";
import { Checkbox } from "./Checkbox";
import Loader from "../../../Common/loader";
import Loadersml from "../../../Common/loadersml";
import ColumnHeaderDesc from "../../../Common/ColumnHeaderDesc";
import moment from "moment";
import { DebounceInput } from 'react-debounce-input';
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import { debounce } from "lodash";
import { SelectedRows, UserAccessContext } from "../../Application";
import { setDropdownvaluesGlobal } from "../../../utility/helper";

const TableGrid = ({
  data: tableData,
  columns: tableColumns,
  fetchData,
  totalRecordCount,
  pageSizeNo,
  handleClickEditCell,
  clickedIndex,
  clickedCell,
  status,
  dealMonths,
  adviceList,
  actions,
  nextStepList,
  updateCellData,
  bulkUpdateCellData,
  loading,
  handleItemPerPage,
  handlePaginationReset,
  handleSearch,
  handleEnterSearch,
  searchValue,
  setSearchValue,
  handleSearchReset,
  SummaryTableHandleDownload_ref,
  SortBy,
  setSortBy,
  SortType,
  setSortType,
  isSelectAll,
  setIsSelectAll,
  setSelection,
  selection,
  toggleSelection,
  onActionClick,
  searchText,
  setSearchText,
  getGridData,
  toggleExpandCollapse,
  handleCheckBox,
  handleSelectAllCheckBox,
  isCheck,
  isNotRequiredTableCoumnSort,
  setCreateListItem,
  publishLoading,
  totalCount,
  setPageInd,
  pageInd,
  state,
  filterData,
  reLoad,
  dealStatus,
  ColumnResizeMode,
  isTableLoading,
  refObj,
  setRefObj,
  tabKey,
  setSelectedCol,
  setHasColUpdated,
  ...props
}) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const [isTableLoader, setTableLoader] = useState(isTableLoading || false);
  const userAccess = useContext(UserAccessContext)

  const [scrollAble,setScrollAble] =useState(false);

  window.addEventListener('scroll', () => {
    const pageHeight = document.body.scrollHeight;
    const scrollPosition = window.scrollY + window.innerHeight;
  
    // Check if the user has scrolled to the bottom of the page
    if (scrollPosition >= pageHeight-3) {
      // Enable table scroll
      setScrollAble(true)
    } else {
      // Disable table scroll
      setScrollAble(false)
    }
  });

  const data = useMemo(() => {
    let total_qty_total = 0
    let totalSold = 0;
    return tableData.map(obj => {
      console.log(obj, obj.id === "" ? obj.totalQtyCs : "")
      const customerValues = Object.entries(obj)
        .filter(([key, value]) => key.includes("customer") && key !== "customerRefId")
      const data = Object.fromEntries(customerValues);
      var total = 0;
      let quantitySold = 0;
      for (let i = 0; i <= 10; i++) {
        var status = data[`customer${i}_status`];
        if (status && status !== "4") {
          total += +(data[tabKey == 'ZUN' ? `customer${i}_qty` : `customer${i}_qty_cs`])
        }
        if (status && status !== "4" && status !== "3") {
          total_qty_total += +(data[tabKey == 'ZUN' ? `customer${i}_qty` : `customer${i}_qty_cs`])
        }
        if (status && status == "3") {
          quantitySold += +(data[tabKey == 'ZUN' ? `customer${i}_qty` : `customer${i}_qty_cs`])
          totalSold += +(data[tabKey == 'ZUN' ? `customer${i}_qty` : `customer${i}_qty_cs`])
        }

      }

      let total_left_sum = obj.id === "" ? total_qty_total + totalSold : total;
      let total_left_sum_cs = obj.id === "" ? total_qty_total + totalSold : total;

      return {
        ...obj,
        total_left: obj.id !== "" ? ((tabKey == 'ZUN' ? obj.qty_to_offer : obj.qtyToOfferCs) || 0) - total_left_sum>=0?((tabKey == 'ZUN' ? obj.qty_to_offer : obj.qtyToOfferCs) || 0) - total_left_sum:0 : obj.total_left, 
        number_of_pallets: obj.cs_pal ? Math.round(((tabKey == 'ZUN' ? obj.qty_to_offer : obj.qtyToOfferCs) || 0) / (obj.cs_pal || 1)) : 0,
        total_qty: obj.id === "" ? (obj.total_qty === null ? 0 : obj.total_qty) : total - quantitySold,
        totalQtyCs: obj.id === "" ? (obj.totalQtyCs === null ? 0 : obj.totalQtyCs) : total - quantitySold,
        totalLeftCs: obj.id !== "" ? ((tabKey == 'ZUN' ? obj.qty_to_offer : obj.qtyToOfferCs) || 0) - total_left_sum_cs>=0?((tabKey == 'ZUN' ? obj.qty_to_offer : obj.qtyToOfferCs) || 0) - total_left_sum_cs:0 : obj.totalLeftCs,
        customer1_status:
          dealStatus?.filter((i) => i.id == obj[`customer1_status`])[0]
            ?.deal_status || "",
        customer2_status:
          dealStatus?.filter((i) => i.id == obj[`customer2_status`])[0]
            ?.deal_status || "",
        customer3_status:
          dealStatus?.filter((i) => i.id == obj[`customer3_status`])[0]
            ?.deal_status || "",
        customer4_status:
          dealStatus?.filter((i) => i.id == obj[`customer4_status`])[0]
            ?.deal_status || "",
        customer5_status:
          dealStatus?.filter((i) => i.id == obj[`customer5_status`])[0]
            ?.deal_status || "",
        customer6_status:
          dealStatus?.filter((i) => i.id == obj[`customer6_status`])[0]
            ?.deal_status || "",
        customer7_status:
          dealStatus?.filter((i) => i.id == obj[`customer7_status`])[0]
            ?.deal_status || "",
        customer8_status:
          dealStatus?.filter((i) => i.id == obj[`customer8_status`])[0]
            ?.deal_status || "",
        customer9_status:
          dealStatus?.filter((i) => i.id == obj[`customer9_status`])[0]
            ?.deal_status || "",
        customer10_status:
          dealStatus?.filter((i) => i.id == obj[`customer10_status`])[0]
            ?.deal_status || "",
      };
    })
  }, [tableData]);

  const [freezedColumns, setFreezedColumns] = useState('');
  const [freezedColumnsWt, setFreezedColumnsWt] = useState([]);
  const [pageTableHeight, setPageTableHeight] = useState();
  const manualPageSize = [5, 10, 20, 30];
  const [selectedColumnss, setSelectedColumns] = useState(JSON.parse(window.sessionStorage.getItem("preferences"))?.selectedColumns)
  let INITIAL_HIDDEN_COLUMNS = !selectedColumnss?.includes("all") ? columns
    .filter((column) => !selectedColumnss?.includes(column.accessor))
    .map((column) => column.accessor) : columns
      .filter((column) => !column.show)
      .map((column) => column.accessor);

  const { selectedRows, saveSelectedRows } = useContext(SelectedRows)
  const [wsxedcdata, setWsxedcdata] = useState(props.rowDownloadId)
  useEffect(() => {
    setWsxedcdata(props.rowDownloadId)
  }, [props.rowDownloadId])
  // --- Resize - DataTable 
  useEffect(() => {
    window.addEventListener('resize', tableHt);
  });
  const tableHt = (isTableContracted) => {
    let nn = pageTopElHt(isTableContracted);
    setPageTableHeight(nn);
  }
  useEffect(() => {
    tableHt(true);
  }, []);

  // const [bulkEditValue, setBulkEditValue] = useState()
  // const [bulkUpdateColumn, setBulkUpdateColumn] = useState()
  // const [bulkcellObj, setBulkbulkcellObj] = useState()
  // useEffect(() => {
  //   selectedFlatRows.map((m) => {
  //     bulkcellObj.updateCellData(m, bulkUpdateColumn, bulkEditValue)
  //   })
  //   console.log(selectedFlatRows)
  // }, [bulkEditValue])
  // useEffect(() => {
  //   // selectedFlatRows.map((m) => {
  //   //   bulkcellObj.updateCellData(m, bulkUpdateColumn, bulkEditValue)
  //   // })
  //   console.log(props.rowDownloadId)
  // }, [props.rowDownloadId])
  // console.log(props.rowDownloadId)

  // Define a default UI for filtering
  function DefaultColumnFilter(cellObj, selectedFlatRows) {
    const { selectedRows } = useContext(SelectedRows)
    // console.log(cellObj, selectedFlatRows)
    const [refObject, setRefObject] = useState({})
    var [searchFilterLoader, setSearchFilterLoader] = useState(false);
    const ref = useRef(null)
    const { id, filterValue, setFilter } = cellObj.column;
    const [value, setValue] = useState("");
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [searchTextColumn, setSearchTesxtColumn] = useState("");

    const debouncedChangeHandler = (e) => {
      console.log("haaiiiiiii")
      if (e.key === "Enter") {
        if (selectedRows?.length > 0 && cellObj.column.Header === "Owner") {
          cellObj.bulkUpdateCellData(selectedRows, cellObj.column, e.target.value)
          console.log(selectedRows)
        }
        else {
          setSearchFilterLoader(true)
          setValue(searchTextColumn);
          handleToggle(e.target.value);
        }
        // selectedFlatRows.map((x)=>{
        //   // cellObj.updateCellData(x,cellObj.column,e.target.value)
        // })
        // cellObj.updateCellData(selectedFlatRows[0],cellObj.column,e.target.value)
        // setSearchFilterLoader(true)
        // setValue(searchTextColumn);
        // handleToggle(e);
      }
    }

    const handleToggle = (e) => {
      cellObj.filterData(e, id);
      setTableLoader(true);
      setTimeout(() => {
        setSearchFilterLoader(false)
      }, 500)
    };

    var searchCol = (e) => {
      //  //setTimeout(() => {

      //  _.debounce(func, [wait=0], [options={}])
      filterData(e.target.value, id);
      setSearchFilterLoader(false);
      // }, 300);
    };
    useEffect(() => {
      let obj = {};
      obj[id] = ref;
      refObj.push(obj);
      setRefObj([...refObj])
    }, [id, refObj]);
    // useEffect(() => {
    //   let obj = {};
    //   obj[id] = ref;
    //   cellObj?.refObj.push(obj);
    //   cellObj?.setRefObj([...cellObj?.refObj]);
    // }, [id]);
    const handleCellChange = (e) => {
      console.log(selectedRows)

      if (selectedRows?.length > 0 && cellObj.column.Header === "Next step") {
        cellObj.bulkUpdateCellData(selectedRows, cellObj.column, e.target.value, cellObj.nextStepList.filter((i) => i.v === e.target.value).map((i) => i.k)[0])
        console.log(selectedRows)
      }
      else {
        setValue(e.target.value)
        console.log(cellObj.nextStepList.filter((i) => i.k === e.target.value).map((i) => i.v)[0])
        setSearchTesxtColumn(cellObj.nextStepList.filter((i) => i.v === e.target.value).map((i) => i.v)[0])

        setSearchFilterLoader(true)
        // setValue(searchTextColumn);
        handleToggle(cellObj.nextStepList.filter((i) => i.v === e.target.value).map((i) => i.v)[0]);


      }
      console.log(ref.current.value)

      console.log("e.targert -->1", e.target.value)
    };


    return (
      <>
        {cellObj.column.Header !== "Next step" ? <div className="input-edit-mode input-with-loader btn-slim">
          {<DebounceInput
            id={id}
            key={id}
            value={value}
            ref={ref}
            onKeyUp={(ev) => {
              if (ev.target.value) {
                refObj.forEach((key) => {
                  if (Object.keys(key)[0] !== id && key[Object.keys(key)[0]].current) {
                    // if (key[Object.keys(key)[0]].current.state)
                    //   key[Object.keys(key)[0]].current.state.value = "";
                    // else {
                    //   key[Object.keys(key)[0]].current.value = "";
                    // }
                  }
                });
              }
            }}
            debounceTimeout={300}
            onChange={(event) => setSearchTesxtColumn(event.target.value)}
            onKeyDown={debouncedChangeHandler}
          />}

          {searchFilterLoader && (
            <span>
              <Loadersml />
            </span>
          )}
        </div> :
          <div >
            <select
              id={id}
              key={id}
              // value={value}
              ref={ref}
              // disabled={userAccess.accessId[0] === "1" ? true : false}
              className="input-edit-mode btn-slim width-100"
              onChange={handleCellChange}
              autoFocus
              value={ref.current?.value ? null : ""}>
              <option value="" selected>
                Select
              </option>
              {cellObj.nextStepList.map((value) => {
                return <option value={value.v}>{value.v}</option>;
              })}
            </select>
          </div>}
      </>
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 160,
      width: 160,
      maxWidth: 400,
      // Let's set up our default Filter UI
      Filter: (cellObj) => DefaultColumnFilter(cellObj, props.rowDownloadId),
    }),
    [props.rowDownloadId]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    allColumns,
    toggleHideColumn,
    toggleHideAllColumns,
    prepareRow,
    selectedFlatRows,
    setAllFilters,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualFilters: true,
      handleClickEditCell,
      clickedIndex,
      clickedCell,
      updateCellData,
      bulkUpdateCellData,
      filterData,
      reLoad,
      refObj,
      setRefObj,
      status,
      dealMonths,
      adviceList,
      actions,
      nextStepList,
      tabKey,
      handleCheckBox,
      handleSelectAllCheckBox,
      selection,
      isCheck,
      manualPagination: true,
      pageCount: Math.ceil(totalRecordCount / pageSizeNo),
      initialState: {
        pageIndex: pageInd,
        pageSize: pageSizeNo,
        hiddenColumns: INITIAL_HIDDEN_COLUMNS,
      },
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect,
    useSticky,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <Checkbox {...getToggleAllPageRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox disabled={row.original.id === "" ? true : false}{...row.getToggleRowSelectedProps()} />,
          sticky: "left",
        },
        ...columns,
      ]);
    }
  );
  // useEffect(()=>{
  //   setPageIndex(pageInd)
  // },[pageInd])

  const [val, setVal] = useState([
    ...columns
      .filter((item) => item.sticky !== "left" && item.show)
      .map((item) => item.accessor),
  ]);

  useEffect(() => {
    // setSelectedCol([
    //   ...columns
    //     .filter((item) => item.sticky !== "left" && item.show)
    //     .map((item) => item.accessor),
    // ])
    setSelectedCol([
      ...columns
        .filter((column) => selectedColumnss?.includes(column.accessor))
        .map((column) => column.accessor)
    ])


    return () => {
      setHasColUpdated(false)
    }
  }, [])

  const selectAllOption = { value: "all", label: "Select all" };
  const filterColumns = allColumns
    ? allColumns
      .filter((item) => item.sticky !== "left")
      .map((column) => ({
        value: column.id,
        label: column.Header,
        sticky: column.sticky
      }))
    : [];
  useEffect(() => {
    console.log(selectedColumnss)
    if (selectedColumnss?.includes("all")) {
      setVal([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
      setSelectedCol([
        ...columns
          .filter((item) => item.sticky !== "left" && item.show)
          .map((item) => item.accessor),
      ])
    } else {
      setVal(selectedColumnss)
    }
    setHasColUpdated(true)
  }, [selectedColumnss])

  const handleSelect = (selected, action) => {
    props.SetColumnSelected(selected.map(i => i.value))
    // setSelectedCol(selected.map(i => i.value))
    console.log("selectedValues",action,selected.map(i => i.value))
    if (
      action.action === "select-option" &&
      action.option.value === selectAllOption.value
    ) {
      //select all
      let selectedValues = [
        "all",
        ...columns
          .filter((item) => !item.sticky !== "left")
          .map((item) => item.accessor),
      ]
      setVal(selectedValues);
      setSelectedCol([
        ...columns
          .filter((item) => !item.sticky !== "left")
          .map((item) => item.accessor),
      ]);
      setHasColUpdated(true)
      // columns
      //   .map((item) => item.accessor)
      //   .forEach((item) => toggleHideColumn(item));
      toggleHideAllColumns(false);
    } else if (
      action.action === "deselect-option" &&
      action.option.value === selectAllOption.value
    ) {
      //deselect all

      let selectedValues = columns.filter((item) => item.sticky).map((item) => item.accessor)
      setVal(selectedValues);
      setSelectedCol(selectedValues);
      setHasColUpdated(true)
      columns.filter((item) => !item.sticky)
        .map((item) => item.accessor)
        .forEach((item) => toggleHideColumn(item));
      // toggleHideAllColumns(true);
    } else {
      let selectedValues = action.action === "select-option" && selected?.length === columns.length
        ? [{ value: "all", label: "Select all" }, ...selected].map(
          (item) => item.value
        )
        : selected.filter((i) => i.value !== "all").map((item) => item.value)
      setVal(selectedValues);
      setSelectedCol(selectedValues)
      setHasColUpdated(true)
      // if (action.action === "select-option" && selected.length === 1) {
      //   toggleHideColumn("selection");
      //   columns.forEach(i=>{
      //     if(i.sticky){
      //       toggleHideColumn(i.accessor);
      //     }
      //   })
      // }
      // setVal(selected.map((item) => item.value));
    }

    //Add and remove columns from the table
    if (action.action !== "pop-value") {
      if (action.action === "clear") {
        columns
          .map((item) => item.accessor)
          .forEach((item) => toggleHideColumn(item));
      } else {
        toggleHideColumn(action.option.value);
      }
    }
  };

  useEffect(() => {
    console.log(selectedFlatRows)
    setSelection(selectedFlatRows);
    // setNews(selectedFlatRows);
    props.setRowDownloadIds(selectedFlatRows);
    saveSelectedRows(selectedFlatRows)
  }, [selectedFlatRows, setSelection]);

  useEffect(() => {
    if (setCreateListItem) {
      setCreateListItem(selectedFlatRows);
    }
  }, [selectedFlatRows, setCreateListItem]);

  useEffect(() => {
    setTableLoader(true);
  }, [props.dropDownValues])
  useEffect(() => {
    setTableLoader(isTableLoading);
  }, [isTableLoading]);
  useEffect(() => {
    fetchData({ pageInd, pageSize });
  }, [fetchData, pageInd, pageSize]);




  const handleSorting = (column) => {

    setTableLoader(true);
    if (column.id !== "icon_container") {
      setSortBy(column.id);
      setSortType((SortType === "ASC" && "DESC") || "ASC");

    }
    // setTimeout(() => setTableLoader(false), 1000);
  };

  let selectionValues = {
    Owner: "owner",
    "Best before Date": "bbd",
    "Short shelf life date": "hah_date",
    "Quantity available for sales offer": tabKey == 'ZUN' ? "qty_to_offer" : "qtyToOfferCs",
    "Quantity reserved for sales offer": "total_qty",
  };

  const handleNextPage = () => {
    setTableLoader(true);
    nextPage();
    // setTimeout(() => setTableLoader(false), 1000);
  };

  const handlePreviousPage = () => {
    setTableLoader(true);
    previousPage();
    // setTimeout(() => setTableLoader(false), 1000);
  };

  const handleGotoPage = (i) => {
    setTableLoader(true);
    gotoPage(i);
    // setTimeout(() => setTableLoader(false), 1000);
  };

  useEffect(() => {
    setTableLoader(false);
  }, [data])

  const clearSearchClose = () => {
    setTableLoader(true);
    setSearchText('');
    getGridData({
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
      plant_code: props.dropDownValues?.plant ?? [],
      nextStepId: props.dropDownValues?.nextStep ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      columnFilter: {},
      page: 1,
      per_page: pageSizeNo,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: "",
    });
  }
  const handelSearchButton = () => {
    setTableLoader(true);
    getGridData({
      alertTypeId: props.dropDownValues?.alert ?? [],
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
      plant_code: props.dropDownValues?.plant ?? [],
      nextStepId: props.dropDownValues?.nextStep ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
      mrpControllerId: props.dropDownValues?.mrpc ?? [],
      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
      page: 1,
      per_page: pageSizeNo,
      columnFilter: {},
      //  sortby: "businessGroupId",
      //  complianceSorting: "ASC",
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ?? "",
    });
  }
  const handleKeyDown = (event) => {
    console.log(event)
    if (event.key === 'Enter') {
      handelSearchButton();
    }
  };


  // useEffect(()=>{
  //   console.log("handleColumnsSelected~~~~~~~~~~",val);
  //   localStorage.setItem('columnVisibilitySelected',JSON.stringify(val));
  //   setTimeout(() => {
  //     props.handleColumnsSelected();
  //   }, 400);
  // },[props.downloadClicked])

  const [selectRow, setSelectedRow] = useState(null)
  useEffect(() => {
    console.log(selectRow)
  }, [selectRow])

  return (
    <>
      {/* <!--Search Table Data Start--> */}
      <div className="table-filters">
        <div className="pull-left flex align-items-center justify-content-between">
          <ul className="table-form flex align-items-center">
            {!props.isNoColumnSelection && (
              <li className="pr-0">
                <div className="flex align-items-center">
                  <label className="d-inline-block mx-2">Columns</label>
                  <ULDropdown
                    name="custom_column_filter"
                    value={val}
                    options={[selectAllOption, ...filterColumns]}
                    // options={filterColumns}
                    onChange={handleSelect}
                    isMulti={true}
                    isClearable={false}
                    placeholder={"Search Column"}
                    className="react-select-container inp-std-width"
                  />
                </div>
              </li>
            )}
            {!props.isNoColumnSelection && (
              <li className="pl-2">
                <label>
                  {val?.filter((i) => i === "all")?.length
                    ? val.length - 1
                    : val?.length}{" "}
                  selected
                </label>
              </li>
            )}
            {!isNotRequiredTableCoumnSort && (
              <li>
                <label>Table Sort Column </label>
                <select
                  onChange={(event) => {
                    setTableLoader(true);
                    setSortBy(
                      event.target.value !== ""
                        ? selectionValues[event.target.value]
                        : ""
                    );
                    getGridData({
                      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country, userAccess?.pomdata?.data?.countryId),
                      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category, userAccess?.pomdata?.data?.categoryId),
                      businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.pomdata?.data?.businessGroupId),
                      plant_code: props.dropDownValues?.plant ?? [],
                      nextStepId: props.dropDownValues?.nextStep ?? [],
                      foodSolutionIndicatorId: props.dropDownValues?.foodSolution ?? [],
                      mrpControllerId: props.dropDownValues?.mrpc ?? [],
                      forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
                      page,
                      per_page: pageSizeNo,
                      sortby: SortBy,
                      complianceSorting: SortType,
                      searchText: searchText ?? "",
                    });
                    setTimeout(() => setTableLoader(false), 1000);
                  }}
                >
                  <option key={"owner"}>Owner</option>
                  <option key={"bbd"} selected >Best before Date</option>
                  <option key={"hah_date"}>Short shelf life date</option>
                  <option key={tabKey == 'ZUN' ? "qty_to_offer" : "qtyToOfferCs"}>
                    Quantity available for sales offer
                  </option>
                  <option key={"total_qty"}>
                    Quantity reserved for sales offer
                  </option>
                </select>
              </li>
            )}
          </ul>
        </div>
        {!props.isNoSearch && (
          <div className="pull-right flex align-items-center justify-content-between mt--4 ">


            {/* <span className="search-wrap-out w-100">
              <span className="search-wrap w-100">
                <img src={SearchIcon} className="filter-search" alt="" />
                <input
                  type="text"
                  placeholder="Search Groups"
                  className="btn-slim search-input bg-black w-100"
                  ref={searchKeyword}
                />
                <a className="filter-clear" onClick={() => clearSearchClose()}><img src={ClearIcon}  alt="" /></a>
              </span>
              <input
                  type="button"
                  value="Go"
                  className="btn-slim search-btn"
                  onClick={()=>columnsListWrap()}
                />
            </span> */}


            <span className="search-wrap-out w-100">
              <span className="search-wrap">
                <img src={SearchIcon} className="filter-search" alt="" />
                <input
                  type="text"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value ?? "");
                  }}
                  placeholder="MRDR/ Size Pack"
                  className="btn-slim search-input"
                  onKeyDown={handleKeyDown}
                />
                {searchText != '' && (
                  <a className="filter-clear" onClick={() => clearSearchClose()}><img src={ClearIcon} alt="" /></a>
                )}
              </span>
              <input
                type="button"
                value="Search"
                className="btn-slim search-btn"
                onClick={() => handelSearchButton()}
              />

            </span>
            <span className="marginset">
              <Glossary category="pom" />
            </span>

            <span className="marginset">
              <span
                className="info_btn"
                title={
                  props.isExpand ? "Minimise Datatable" : "Maximise Datatable"
                }
                onClick={() => {
                  toggleExpandCollapse();
                  setTimeout(() => {
                    tableHt(props.isExpand);
                  }, 600);
                }}
              >
                <img src={props.isExpand ? PinchIcon : OpenIcon} alt="Open" />
              </span>
            </span>
          </div>
        )}
        <div className="clear"></div>
        {val?.length !== 0 && (
          <>
            <div className="pull-left mt-2">
              <span className="row-click-link mr-3">
                <img src={ClickIcon} alt="" />
                Click the highlighted cells to edit
              </span>
              &nbsp;&nbsp;
              <span className="row-click-link ml-2">
                <img src={resizeIcon} alt="" />&nbsp;
                Doubleclick &amp; move the header column borders to resize
              </span>
            </div>
            <div className="pull-right mt-2">
              <button className="btn btn-slim btn-primary" onClick={() => { props.setColumnClearSearch(!props.clearColumnSearch) }}>Clear Search</button>
            </div>
            {/* {!props.isNoActions && (
              <div className="pull-right mt-2">
                <div className="btn-group">
                  <button
                    type="button"
                    className={`dropdown-toggle btn-published${
                      selection.length > 0 ? " active-btn" : ""
                    }`}
                    id="defaultDropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                    data-bs-toggle={`${selection.length > 0 ? "dropdown" : ""}`}
                    onClick={() => {
                      if (!selection.length) {
                        props.toggleWarnMessageModal();
                      }
                    }}
                  >
                    Actions
                  </button>
                   <ul className="dropdown-menu" aria-labelledby="defaultDropdown">
                    {actions.map((i) => (
                      <li key={i.action_name}>
                        <p
                          className="dropdown-item"
                          value={i.action_name}
                          onClick={(e) => {
                            if (selectedFlatRows.length <=  2 || i.action_name !== "Offer2Charity") {
                              onActionClick(i.action_name);
                            } else {
                              notify({
                                message: "Cannot offer more than 50% to Charity",
                                type: "success",
                              });
                            }
                          }}
                          style={{ cursor: selectedFlatRows.length >  (totalRecordCount / 2) + 1  && i.action_name === "Offer2Charity" ? "not-allowed" : "pointer" }}
                        >
                          {i.action_name}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )} */}
          </>
        )}
      </div>
      {/* <!--Search Table Data End--> */}
      <div className="clear"></div>
      {val.length !== 0 && (
        <>
          <div className="position-relative">
            {(isTableLoader || publishLoading) && (
              <>
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="table_design custm-table_design mt-2" style={{overflowY:scrollAble?"scroll":"hidden", height:"85vh" }}>
              <Styles>
                <div
                  {...getTableProps()}
                  className={`table sticky ${(page.length <= 0) ? 'no-data' : ''}
                       ${freezedColumns} `}
                >
                  <div className="header">
                    {

                      headerGroups.map((headerGroup, index) => {
                        var col_pos_left = 0;
                        return (
                          <div
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                            className="tr"
                          >
                            {headerGroup.headers.map((column, index) => {
                              //console.log("index",index);
                              if ((column.id === "selection")) {
                                col_pos_left = 0;
                              }
                              else if ((column.id === "mrpd")) {
                                col_pos_left = ((column.width + 40)) - ((column.width > 160) ? 160 : column.width);
                              }
                              else if ((column.id !== "selection")) {
                                col_pos_left += (column.width > 160) ? column.width : 160;
                              }
                              return (
                                <div
                                  key={index}
                                  {...column.getHeaderProps()}

                                  style={{
                                    width:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    minWidth:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    maxWidth:
                                      column.id === "selection"
                                        ? "40px"
                                        : (column.width > 160) ? column.width : "160px",
                                    left: (column.id !== "mrpd") ? `${col_pos_left - ((column.width < 160) ? 160 : column.width) + 160}px` : "40px"
                                  }}

                                  className={`
                                th ${column.id} th-filter
                                sort_${SortBy} 
                                sticky-${column.sticky} 
                                ${column.id === "wk13_wk26_residual_zun"
                                      ? "text-right"
                                      : ""
                                    } 

                                    ${["residual_status",
                                      "dealtype",
                                      "previous_month_deal_type",
                                      "comment_field",
                                      "previous_month_comment_field",
                                      "previous_month_comment_field",
                                      "local_deal_completion_date",
                                      "local_deal_quantity_in_zun",
                                      "central_deal_quantity_in_zun",
                                      "remaining_quantity_in_zun",
                                      "local_deal_in_eur",
                                      "central_deal_in_eur",
                                      "remaining_stock_in_eur",
                                      "pack_type",
                                      "bom",
                                      "language_on_pack",
                                      "production_plant_number",
                                      "production_country_name"

                                    ].includes(column.id)
                                      ? "td-nomination"
                                      : ""
                                    }

                                    ${["country_code",
                                      "city",
                                      "full_address",
                                      "post_code",
                                    ].includes(column.id)
                                      ? "td-plant-data"
                                      : ""
                                    }

                                  ${["ean_zun",
                                      "ean_carton",
                                      "zun_per_case",
                                      "zun_per_pallet",
                                      "deal_pallet_zun",
                                    ].includes(column.id)
                                      ? "td-additional-product-data"
                                      : ""
                                    }   
                                  ${["agreed_quantity_in_zun",
                                      "cogs",
                                      "agreed_price_per_zun_in_eur",
                                      "sales_value_eur",
                                      "loss_value_eur",
                                      "margin_percentage",
                                      "net_cogs_removing_prov_eur",
                                      "net_margin_percentage",
                                      "spf_code",
                                      "min_floor_price_per_zun_for_central_deal_in_eur",
                                      "tp_exworks_dc"
                                    ].includes(column.id)
                                      ? "td-negotation-phase-with-customers"
                                      : ""
                                    }   
                                    ${["quantity_available",
                                      "stock_is_located_in_country",
                                      "stock_is_produced_in_country",
                                      "ean_zun2",
                                      "ean_carton2",
                                      "zun_per_case2",
                                      "zun_per_pallet2"
                                    ].includes(column.id)
                                      ? "td-bidding-portal"
                                      : ""
                                    }   
                                `}
                                >
                                  <div
                                    {...column.getResizerProps()}
                                    className={`resizer ${column.isResizing ? "isResizing" : column.width
                                      }`}
                                  />

                                  <div className="th-in">
                                    <OverlayTrigger
                                      overlay={tableToolTip(ColumnHeaderDesc("pom", column.Header))}
                                      trigger={["hover", "focus"]}
                                      placement="top"
                                      delay={{ show: 200, hide: 0 }}
                                    >
                                      <span className={`th-text ${column.header_col_visibility_visible ? 'visibility_visible' : ''}`}
                                      >

                                        <div className="header_col_title_wrap">
                                          <div className="header_col_title">
                                            {column.render("Header")}
                                          </div>
                                          {column.id !== "selection" && (
                                            <span
                                              className="sort-ico"
                                              style={{ cursor: "pointer" }}
                                            >
                                              {(column.id !== "add_customer" && column.id !== "batch_code") && (
                                                <>
                                                  {SortType == "ASC" &&
                                                    column.id === SortBy ? (
                                                    <Icon
                                                      path={mdiMenuUp}
                                                      size="1.5rem"
                                                      className="sortColumn"
                                                      onClick={() => handleSorting(column)}

                                                    />
                                                  ) : SortType === "DESC" &&
                                                    column.id === SortBy ? (
                                                    <Icon
                                                      path={mdiMenuDown}
                                                      size="1.5rem"
                                                      className="sortColumn"
                                                      onClick={() => handleSorting(column)}
                                                    />
                                                  ) : (
                                                    <Icon
                                                      path={mdiMenuUp}
                                                      size="1.5rem"
                                                      className="sortColumn"
                                                      onClick={() => {
                                                        setTableLoader(true);
                                                        if (column.id !== "icon_container") {
                                                          setSortType("DESC");
                                                          setSortBy(column.id);
                                                        }
                                                        // setTimeout(() => setTableLoader(false), 2000);
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )}

                                            </span>
                                          )}
                                        </div>

                                        <div>
                                          {(column.canFilter && column.id !== "add_customer" && column.id !== "batch_code")
                                            ? column.render("Filter") ?? null
                                            : (<div className="btn-slim transparent"></div>)}
                                        </div>
                                      </span>
                                    </OverlayTrigger>
                                    {column.id !== "selection" && (
                                      <span title="Doubleclick to resize column" className={` col-resize-handle resizer ${column.isResizing ? "isResizing" : ''
                                        }  ${(column.width < 160) ? 'minimum' : ''}`}  {...column.getResizerProps()}></span>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                  </div>

                  {page.length <= 0 && (
                    <div className="body">
                      <div className="tr w-100">
                        <div role="cell" className="td">
                          <span className="td-in text-center" style={{ color: "white" }}>No Data</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    key={state}
                    {...getTableBodyProps()}
                    className={`body  ${page.length <= 0 ? "no-data" : ""}`}
                  >

                    {(page.length > 0 &&
                      page.map((row, index) => {
                        var body_col_pos_left = 0;
                        prepareRow(row);
                        // console.log("ROWWWW >> ",row);
                        return (
                          <div
                            key={index}
                            {...row.getRowProps()}
                            className={`tr ${row.original.id === "" ?"sticky-true":""}`}
                            style={{ 
                              color: row.original.id === "" ? "black" : "" ,
                              position: row.original.id === "" ?"sticky":"",
                              bottom: row.original.id === "" ?"-1px":"",
                              zIndex: row.original.id === "" ?"4000":"",
                            }}
                          >
                            {row.cells.map((cell, index) => {
                              //console.log("index",index);
                              if ((cell.column.id === "selection")) {
                                body_col_pos_left = 0;
                              }
                              else if ((cell.column.id === "mrpd")) {
                                body_col_pos_left = ((cell.column.width + 40)) - ((cell.column.width > 160) ? 160 : cell.column.width);
                              }
                              else if ((cell.column.id !== "selection")) {
                                body_col_pos_left += (cell.column.width > 160) ? cell.column.width : 160;
                              }


                              return (
                                <div
                                  key={index}
                                  onClick={() => setSelectedRow(cell.row.original.id)}

                                  {...cell.getCellProps()}
                                  style={{
                                    // backgroundColor:cell.row.original.id===""?"#EF5891 !important":"",
                                    // color:row.original.id === "" ?"black !important"  : "" ,
                                    color: "black !important",
                                    width:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    minWidth:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    maxWidth:
                                      cell.column.id === "selection"
                                        ? "40px"
                                        : (cell.column.width > 160) ? cell.column.width : "160px",
                                    left: (cell.column.id !== "mrpd") ? `${body_col_pos_left - ((cell.column.width < 160) ? 160 : cell.column.width) + 160}px` : "40px"
                                  }}
                                  className={`
                                    td  ${cell.column.id} sticky-${cell.column.sticky} sort_${SortBy}
                                    ${cell.row.original.id === "" ? "bg-red lastRowStyle" : `td-editable-${cell.column.editable} ${cell.column.class}`}
                                    ${selectRow === "" && cell.row.original.id === "" ? "bg-red" : selectRow === cell.row.original.id ? cell.column.editable ? "table-highlight" : "table-highlight" : ""}
                                    ${cell.column.id === "bbd"
                                      ?

                                      moment(cell.row.original.bbd ?? new Date()).diff(
                                        new Date(), "months", true) < 0
                                        ? "blood-red"
                                        :
                                        moment(
                                          cell.row.original.bbd ?? new Date()
                                        ).diff(new Date(), "months", true) <= 1 &&
                                          moment(
                                            cell.row.original.bbd ?? new Date()
                                          ).diff(new Date(), "months", true) >= 0
                                          ? "thick-red"
                                          : moment(
                                            cell.row.original.bbd ?? new Date()
                                          ).diff(new Date(), "months", true) <= 2 &&
                                            moment(
                                              cell.row.original.bbd ?? new Date()
                                            ).diff(new Date(), "months", true) >= 0
                                            ? "dark-red"
                                            : moment(
                                              cell.row.original.bbd ?? new Date()
                                            ).diff(new Date(), "months", true) <= 3 &&
                                              moment(
                                                cell.row.original.bbd ?? new Date()
                                              ).diff(new Date(), "months", true) >= 0
                                              ? "normal-red"
                                              : ""
                                      : ""
                                    }
                                    
                                    ${cell.column.id === "hah_date" && !(cell.row.original.plant_code !=="C046" && cell.row.original.fictive_ssl_date ==="Yes")
                                      ?
                                      moment(cell.row.original.hah_date ?? new Date()).diff(
                                        new Date(), "months", true) < 0
                                        ? "blood-red"
                                        :

                                        moment(
                                          cell.row.original.hah_date ?? new Date()
                                        ).diff(new Date(), "months", true) <= 1 &&
                                          moment(
                                            cell.row.original.hah_date ?? new Date()
                                          ).diff(new Date(), "months", true) >= 0
                                          ? "thick-red"
                                          : moment(
                                            cell.row.original.hah_date ?? new Date()
                                          ).diff(new Date(), "months", true) <= 2 &&
                                            moment(
                                              cell.row.original.hah_date ?? new Date()
                                            ).diff(new Date(), "months", true) >= 0
                                            ? "dark-red"
                                            : moment(
                                              cell.row.original.hah_date ?? new Date()
                                            ).diff(new Date(), "months", true) <= 3 &&
                                              moment(
                                                cell.row.original.hah_date ?? new Date()
                                              ).diff(new Date(), "months", true) >= 0
                                              ? "normal-red"
                                              : ""
                                      : ""
                                    }

                                    `}
                                >
                                  <span
                                    className="td-in"
                                    title={
                                      (cell.column.decimal_value
                                        ? parseFloat(cell.value).toFixed(2)
                                        : cell.column.round_off_values_no_decimal
                                          ? Math.round(cell.value)
                                          : cell.value) + (cell.column.show_percentage && cell.value ? '%' : '')
                                    }
                                    key={index}
                                    {...cell.getCellProps()}
                                  >
                                    {/* {cell.column.decimal_value
                                      ? parseFloat(cell.value).toFixed(2)
                                      : cell.column.round_off_values_no_decimal
                                        ? Math.round(cell.value)
                                        :
                               
                                         cell.render("Cell")} */}

                                    {cell.column.decimal_value
                                      ? parseFloat(cell.value).toFixed(2)
                                      : cell.column.round_off_values_no_decimal
                                        ? Math.round(cell.value)
                                        :
                                        (cell.column.Header == "Best before date" || cell.column.Header == "Short shelf life date" ? cell.value : cell.render("Cell"))
                                    }



                                    {cell.column.show_euro_symbol && cell.value ? '€' : ''}
                                    {cell.column.show_percentage && cell.value ? '%' : ''}


                                  </span>
                                </div>
                              )
                            })}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </Styles>
            </div>
            {totalRecordCount > 0 && (
              <ReactTablePagination
                page={page}
                gotoPage={handleGotoPage}
                previousPage={handlePreviousPage}
                nextPage={handleNextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageInd}
                pageCount={pageCount}
                manualPageSize={manualPageSize}
                dataLength={totalRecordCount}
                handleItemPerPage={handleItemPerPage}
                handlePaginationReset={handlePaginationReset}
                pageSizeNo={pageSizeNo}
                totalCount={totalCount}
                setPageIndex={setPageInd}
              //setSelection={setSelection}
              />
            )}
          </div>

        </>
      )}
    </>
  );
};

export default React.memo(TableGrid);
