import React, { useCallback, useEffect, useState } from "react";
//import DownloadIcon from "../../../Assets/img/download.svg";
//import ClickIcon from "../../../Assets/img/clickicon.svg";
//import InfoIcon from "../../../Assets/img/info.svg";
// import OpenIcon from "../../../Assets/img/open.svg";
// import CloseIcon from "../../../Assets/img/close.png";
// import SearchIcon from "../../../Assets/img/search.svg";
// import RefreshIcon from "../../../Assets/img/refresh.svg";
// import DoveImg from "../../../Assets/img/dove.png";
// import VaselineImg from "../../../Assets/img/vaseline.png";
// import SureImg from "../../../Assets/img/sure.png";
// import LinxImg from "../../../Assets/img/lynx.png";
// import DoveSoapImg from "../../../Assets/img/dove-soap.png";
//import Infopopup from "../../../Common/info";
//import axios from "axios";
//import fileSaver from "file-saver";
//import { ViewUserBiddingList } from "../../../Services/BiddingPortalService";
import {
  ViewCentralDealBiddingList,
  UpdateCentralDealBiddingList,
} from "../../../Services/CentralDealManagementDetailServices";
import TableGrid from "./TableGrid";
import { COLUMNS } from "./Columns";
import { PerPageRecordNumber } from "../../../utility/constants";
import ULButton from "../../button";
//import EditIcon from "../../../Assets/img/edit.svg";
import { notify } from "../../../Common/toast";

const DealManagementListTable = (props) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [SortBy, setSortBy] = useState("material_description");
  const [SortType, setSortType] = useState("ASC");
  const [count, setCount] = useState(0);
  const [totalMinBidPrice, setTotalMinBidPrice] = useState(null);
  const [offerPrice, setOfferPrice] = useState(null);

  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);

  const fetchIdRef = React.useRef(0);

  const getCentralDealBiddingList = useCallback((payload) => {
    ViewCentralDealBiddingList(payload).then((response) => {
      console.log("View Central Deal Bidding List --> ",response.data);
      setData(response.data);
      setCount(response.count);
    });
  }, []);

  useEffect(() => {
    getCentralDealBiddingList({
      bidding_name: props.selectedList,
      SortBy,
      SortType,
    });
  }, [SortBy, SortType, props.selectedList, getCentralDealBiddingList]);

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(20);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
    //setPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };

  const handleSearch = (val) => {
    //setPage(0);
    setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      //Enter Key
      //setPage(0);
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      //Back Space
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  //Edit cell code start here
  /* const handleInlineEditChanges = (val) => {
    console.log("val: ", val);
    setRowInlineEditChanges(val);
  }; */

  const updateCellData = (row, column, value) => {
    const biddingId = row.original.biddingId;

    let index = rowInlineEditChanges.findIndex(
      (x) => x.biddingId === biddingId
    );
    index !== -1 && rowInlineEditChanges.splice(index, 1);

    setRowInlineEditChanges([
      ...rowInlineEditChanges,
      { biddingId: biddingId, offer_price: value },
    ]);

    setClickedIndex(null);
    setClickedCell(null);
  };

  const handleUpdateSubmit = () => {
    UpdateCentralDealBiddingList({
      data: rowInlineEditChanges,
    })
      .then((response) => {
        if (response.message === "Sucessfully Updated") {
          notify({
            message: "Data updated successfully!",
            type: "success",
          });
        } else {
          notify({
            message: "Something Went Wrong!",
            type: "error",
          });
        }
        props.onHide();
      }, 100)
      .catch((error) => {
        notify({
          message: "No Response From Server. Please Try Again Later",
          type: "error",
        });
        props.onHide();
      });
  };

  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
  };
  //Edit cell code end here

  return (
    <>
      <div className="biddingtable">
        <div className="body-container ">
        
            <div>{props.selectedList}</div>

            <div className="table_design bidtable mt-3">
              <TableGrid
                columns={COLUMNS}
                data={data}
                SortBy={SortBy}
                SortType={SortType}
                setSortBy={setSortBy}
                setSortType={setSortType}
                fetchData={fetchData}
                pageCount={pageCount}
                totalRecordCount={totalRecordCount}
                pageSizeNo={itemPerPage}
                // loading={loading}
                handleItemPerPage={handleItemPerPage}
                handlePaginationReset={handlePaginationReset}
                handleSearch={handleSearch}
                handleEnterSearch={handleEnterSearch}
                handleSearchReset={handleSearchReset}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                //searchText={searchText}
                //setSearchText={setSearchText}
                {...editCellProps}
                props={props}
                toggleExpandCollapse={props.toggleExpandCollapse}
                isExpand={props.isExpand}
                clickedFrom={props.clickedFrom}
              />
            </div>

            {/* <div className="flex align-items-center justify-content-between mt-3">
              <div className="">
                <button
                  type="button"
                  className="btn-slim btn-secondary"
                  onClick={() => props.handleEditList()}
                >
                  <img src={EditIcon} alt="EditIcon" />
                  &nbsp;&nbsp;Edit List
                </button>
              </div>
            </div> */}
            {props.clickedFrom !== "biddingList" && (
              <div className="mt-5">
                <div className="pull-right ">
                  <ULButton
                    type="button"
                    className="btn btn-primary btn-big  btn-std-width-lg"
                    label="Submit"
                    onClick={handleUpdateSubmit}
                  />
                </div>
              </div>
            )}
         
        </div>
      </div>
    </>
  );
};

export default DealManagementListTable;
