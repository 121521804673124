import React, { useContext, useEffect, useRef, useState } from "react";

import SearchIcon from "../../../Assets/img/search.svg";
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import ClickIcon from "../../../Assets/img/clickicon.svg";
import resizeIcon from "../../../Assets/img/icon-resize.svg";
import GlosseryTable from "../Table/GlosseryTable";
import {
  getFgslobGlossary,
  getInflowGlossary,
  getPOMGlossary,
} from "../../../Services/UserManagementService2";
import { notify } from "../../../Common/toast";
import { Grid } from "@material-ui/core";
import ULDropdown from "../../../Common/Dropdown";
import Dropdown from "../../../Views/common/Dropdown";
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const styleSelect = {
  width: "220px",
  backgroundColor: "#1e232f",
  border: "1px solid #7D7D7D",
  borderRadius: "3px",
  fontSize: "12px",
  fontWeight: "400",
  color: "#ffffff",
  height: "24px",
  padding: "0 6px",
  margin: "0 10px",
};

function Glossery() {
  const searchKeyword = useRef(null);
  const [tableShow, setTableShow] = useState(false);
  const [columnData, setColumnData] = useState();

  const [screenoption, setScreenOption] = useState("Select");
  const [glossaryOption, setGlossaryOtion] = useState("Select");
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false)
	const userAccess = useContext(UserAccessContext);

  const clearSearchClose = () => {
    searchKeyword.current.value = "";
    // columnsListWrap();
  };

  const handleCloseGlossaryModal = () => {
    setTableShow(false);
		setData([])
  };

  const handleClose = () => {
    setTableShow(false);
    setEdit(false);
    setData([]);
  };

  const coulumDataUnique = (data) => {
    let data1 = data?.glossaryData[0].map((item) => item.column_name);
    const filteredData1 = data?.columns
      .filter((i) => i !== null)
      .filter((item) => !data1.includes(item.header));
    return filteredData1;
    // let data2 = data.column.find()
  };
  const handleSaveAndNext = () => {
		setColumnData();
		setIsLoading(true)
    if (screenoption === "FG SLOB") {
      getFgslobGlossary().then((response) => {
        setData(response?.data?.glossaryData[0]);
				setIsLoading(false)
        setColumnData(coulumDataUnique(response?.data));
				if(glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)){
					notify({
						message: "Please add atleast one KPI!",
						type: "warning",
					});
				}
				setTableShow((glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)) ? false : true);
      });
    }
    if (screenoption === "INFLOW") {
      // setColumnData();
      getInflowGlossary().then((response) => {
        setData(response?.data?.glossaryData[0]);
				setIsLoading(false)
        setColumnData(coulumDataUnique(response?.data));
				if(glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)){
					notify({
						message: "Please add atleast one KPI!",
						type: "warning",
					});
				}
				setTableShow((glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)) ? false : true);
      });
    }
    if (screenoption === "POM") {
      // setColumnData();
      getPOMGlossary().then((response) => {
        setData(response?.data?.glossaryData[0]);
				setIsLoading(false)
        setColumnData(coulumDataUnique(response?.data));
				if(glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)){
					notify({
						message: "Please add atleast one KPI!",
						type: "warning",
					});
				}
				setTableShow((glossaryOption === "Edit Glossary" && (response?.data?.glossaryData[0] === undefined || response?.data?.glossaryData[0]?.length <= 0)) ? false : true);
      });
    }
    
    setEdit(false);
    // setEdit(!edit)
  };
  
  return (
    <>
			{isLoading && 
				(<div className={`tableLoader_overlay`}>
					<div className="tableLoader_box">
						<div className="tableLoader_box-in">
							<Loader />
						</div>
					</div>
				</div>)
			}
			{/* {

				(!isLoading && 
				<> */}
					<div>
						<div className="panel-custom-body">
							<h3 className="heading">Settings</h3>
							<div className=" filterform_bg">
								<Grid container spacing={2}>
									<Grid item xs={3}>
										<label>Tool</label>
										<div>
											<select
												className="btn-slim w-100"
												defaultValue={"Select"}
												onChange={(event) => {
													setScreenOption(event.target.value);
												}}
											>
												{" "}
												<option key={"Select"}>Select</option>
												{userAccess?.fgSLOBdata?.data.length===undefined &&<option key={"FbSlob"}>FG SLOB</option> }
												{userAccess?.inflowdata?.data.length===undefined &&<option key={"INFLOW"}>INFLOW</option> }
												{userAccess?.pomdata?.data.length===undefined &&<option key={"POM"}>POM</option> }
												
											</select>
										</div>
									</Grid>
									<Grid item xs={3}>
										<label>Page Selection</label>
										<div>
											<select
												className="btn-slim w-100"
												onChange={(event) => {
													console.log(event.target.value);
												}}
											>
												<option key={"All"}>All</option>
											</select>
										</div>
									</Grid>
									<Grid item xs={3}>
										<label>Glossary</label>
										<div>
											<select
												className="btn-slim w-100"
												defaultValue={"Select"}
												onChange={(event) => {
													setGlossaryOtion(event.target.value);
												}}
											>
												<option key={"Select"}>Select</option>
												<option key={"Add new KPI"}>Add new KPI</option>
												<option key={"Edit Glossary"}>Edit Glossary</option>
											</select>
										</div>
									</Grid>
								</Grid>
							</div>
						</div>
						<div className="">
							<button
								className="btn btn-big-width btn-big btn-secondary pull-right"
								onClick={handleSaveAndNext}
								disabled={screenoption === "Select" || glossaryOption === "Select"}
							>
								Save & Next
							</button>
						</div>
					</div>
					<GlosseryTable
						data={data}
						columnData={columnData}
						showModal={tableShow}
						setEdit={setEdit}
						handleClose={handleClose}
						screenoption={screenoption}
						onCloseGlossaryModal={handleCloseGlossaryModal}
						edit={edit}
						setGlossaryOtion={setGlossaryOtion}
						glossaryOption={glossaryOption}
					/>
					{/* </>
				)
			} */}
    </>
  );
}

export default Glossery;
