import React, { useState, useContext } from "react";
import { BlockInvalidChar } from "../../../../src/utility/helper";
import DatePicker from "react-datepicker";
import { UserContext } from "../../Application";
import "react-datepicker/dist/react-datepicker.css";
import { notify } from "../../../Common/toast";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Popup from "reactjs-popup";
import { buildCommentsTooltip , getCommentsLog} from "../../../utility/constants";

export const EditCell = (cellObj) => {
  const user = useContext(UserContext);
  const statusObj = {
    Accepted: "bg-teal",
    Rejected: "bg-red",
    Blank: "bg-blank",
  };
  const { column, row, value } = cellObj.cell;
  const [val, setVal] = useState(value);
  const [isCellEditable, setCellEditable] = useState(false);
  const aa = () => {
    const bidding_list_opened = JSON.parse(sessionStorage.getItem('bidding_list_opened') || {});
    if(bidding_list_opened.status.includes('Await') && user.userRole == 'SuperAdmin') {
      return true;
    } else {
      return false;
    }
  }

  const notifyMessage = (mssg) => {
    let sccs = <div className="tosterIcon"><div>{mssg}</div></div>
    toast.info(sccs, {
        containerId: "offerToast",
    });
  }
  
const processTitle = (v) => {
  var qq = aa();
  const gg = v ? v : (column.id == 'customer_bid_quantity' || column.id == 'customer_bid_price' || column.id == 'customerComments' || column.id == 'quantity_to_offer_bid' || column.id == 'vendor_bid_price') ? !qq ? 'Click "Counter Offer" to edit the cell value' : 'Click "Edit" to edit the highlighted cells' : "Click to edit cell value";
  return gg;
}

const processWarningMssg = () => {
  setCellEditable(false);
  var bidding_list_opened = JSON.parse(sessionStorage.getItem('bidding_list_opened') || {});
  var qq = aa();
  if(!bidding_list_opened.status.includes('Planner') && (column.id == 'customer_bid_quantity' || column.id == 'customer_bid_price' || column.id == 'customerComments' || column.id == 'quantity_to_offer_bid' || column.id == 'vendor_bid_price')) {
    let nm = !qq ? 'Click "Counter Offer" to edit the cell value' : 'Click "Edit" to edit the highlighted cells';
    notifyMessage(nm);
    setCellEditable(true);
  };
}

  const only2decimals = (e) => {
    let ssde = parseFloat(e.target.value).toFixed(2);
    setVal(ssde);
  }

  const handleCellChange = (e) => {
    // if (column.id === "minimum_acceptance_price" && column.id === "vendor_bid_price"){
    //   return 88888;
    //   this.v
    // }
    if (e.target) {
      //console.log("e.target - e.target.value",e.target,e.target.value);
      //if (column.id === "minimum_acceptance_price" && column.id === "vendor_bid_price"){
        // let jj = setTimeout(() => {
        //   let ssde = parseFloat(e.target.value).toFixed(2);
        //   console.log("---",ssde);
        //   setVal(ssde);
        // }, 2400);
       
      // } else {
      //   setVal(e.target.value);
      // }
      
      setVal(e.target.value);
    }
    else {
      setVal(e);
    }
  };

  const timr = (keypress) => {
    var cntr = 0;
    var nbh;
    if(keypress) {
      if(nbh) clearTimeout(nbh);
      nbh = setTimeout(() => {
        if(cntr > 3) {
          console.log("cntr > 3",cntr);
        } else {
          cntr++;
        }
        console.log("cntr",cntr);
      }, 3000);
    }
  }

  const isTypeNumeric = (e) => {
    var ASCIICode = (e.which) ? e.which : e.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        return false;
    }
    return true;
  }

  const NumberField = () => {
    return (
      <div className="edit-cell">
        {console.log(row.original)}
        <input
          type="number"
          disabled={cellObj.biddingApprovalStatus && cellObj.biddingApprovalStatus!=="pending"&& (!cellObj.isSuperAdminEdited)}
          className="input-edit-mode"
          min={0}
          value={val === null ? " " : val}
          pattern={(column.id === "minimum_acceptance_price" || column.id === "vendor_bid_price") ? "[0-9]+([\.,][0-9]+)?" : "[0-9]"}
          step={(column.id === "minimum_acceptance_price" || column.id === "vendor_bid_price") ? "0.01" : "1"}
          onChange={(e) => {
            if (column.id === "quantity_to_offer_bid") {
              if (
                row.original.vendor_bid_quantity &&
                parseInt(e.target.value??"0") <= parseInt(row.original.vendor_bid_quantity??"0")
              ) {
                handleCellChange(e);
              } else if (
                (!row.original.vendor_bid_quantity && e.target.value) ||  
                parseInt(e.target.value??"0") > parseInt(row.original.vendor_bid_quantity??"0")
              ) {
                e.preventDefault();
                let sccs = <div className="tosterIcon"><div>Quantity to offer should be lesser than Quantity available</div></div>
                toast.error(sccs, {
                  containerId: "A",
                });
              }
              else if(!e.target.value){
                handleCellChange(e)
              }
            } else {
              handleCellChange(e);
            }
          }}
          onBlur={(e) => {
            if (column.id === "minimum_acceptance_price" || column.id === "vendor_bid_price"){
              only2decimals(e);
            }
            cellObj.updateCellData(row, column, val, true,null,'',true);
          }}
          onKeyDown={(ev) => {
            if (column.id !== "minimum_acceptance_price" && column.id !== "vendor_bid_price"){
              if (ev.key === ".") {
                ev.preventDefault();
              }
              BlockInvalidChar(ev);
            }
            timr(true);
          }}
        />
      </div>
    );
  };

  const DateSelection = (cellObj) => {
    // this.myRef.current.classList.add('main-div')
    return (
      <DatePicker
        portalId="root-portal"
        dateFormat="dd/MM/yyyy"
        selected={val ? new Date(val) : null}
        value={val === null ? " " : val}
        onChange={(date) => handleCellChange(date)}
        onBlur={(date) => cellObj.updateCellData(row, column, val)}
        disabled={cellObj.biddingApprovalStatus && cellObj.biddingApprovalStatus!=="pending"&& (!cellObj.isSuperAdminEdited)}
      />
    );
  };
  
  const StatusSelection = () => {
    return (
      <select
        className="input-edit-mode width-100"
        onChange={handleCellChange}
        value={val}
        // onBlur={() => cellObj.updateCellData(row, column, val)}
        disabled={cellObj.biddingApprovalStatus && cellObj.biddingApprovalStatus!=="pending"&& (!cellObj.isSuperAdminEdited)}
      >
        {cellObj.status.map((item) => {
          return <option value={item.v}>{item.v}</option>;
        })}
      </select>
    );
  };

  const TextField = () => {
    return (
      <div className="edit-cell">
        <input
          type="text"
          className="input-edit-mode"
          value={val === null ? " " : val}
          onChange={handleCellChange}
           onBlur={() => cellObj.updateCellData(row, column, val,true)}
           disabled={cellObj.biddingApprovalStatus && cellObj.biddingApprovalStatus!=="pending" && (!cellObj.isSuperAdminEdited)}
        />
      </div>
    );
  };

  return (
    <>
      {((cellObj.isSuperiorEditingBiddingList) &&
        <div className="td-active">
          {column.id === "vendor_bid_quantity" ||
          column.id === "vendor_bid_price" ||
          column.id === "customer_bid_quantity" ||
          column.id === "customer_bid_price" ||
          column.id === "quantity_to_offer_bid" ||
          column.id === "minimum_acceptance_price"
            ? NumberField()
            : column.id === "expiry_date"
            ? DateSelection(cellObj.cell)
            : column.id === "dealtype"
            ? StatusSelection()
            : TextField()}
        </div>
      ) ||
        (column.id === "deal_status" ? (
          <button
            type="button"
            className={`btn cursor-default btn-slim ${
              statusObj[cellObj.value]
            }`}
          >
            {cellObj.value}
          </button>
        ) : (
          <>
            <div
              className="readonly A flex align-items-center"
              title={()=>processTitle()}
              onClick={()=>processWarningMssg()}
              style={{ width: "100%", height: "100%" }}
            >
              {val || value}
            </div>
          </>
        ))}
    </>
  );
};

export const CustomerEditCell = (cellObj) => {
  const user = useContext(UserContext);
  const { column, row, value } = cellObj.cell;
  const [val, setVal] = useState(value);
  const [showCommentsLog, setShowCommentsLog] = useState(false);
  const [isCellEditable, setCellEditable] = useState(false);
  const aa = () => {
    const bidding_list_opened = JSON.parse(sessionStorage.getItem('bidding_list_opened') || {});
    if(bidding_list_opened.status.includes('Await') && user.userRole == 'SuperAdmin') {
      return true;
    } else {
      return false;
    }
  }

  const only2decimals = (e) => {
    let ssde = parseFloat(e.target.value).toFixed(2);
    setVal(ssde);
  }
  const processTitle = (v) => {
    var qq = aa();
    const gg = v ? v : (column.id == 'vendorComments' || column.id == 'customer_bid_quantity' || column.id == 'customer_bid_price' || column.id == 'customerComments' || column.id == 'quantity_to_offer_bid' || column.id == 'vendor_bid_price') ? !qq ? 'Click "Counter Offer" to edit the cell value' : 'Click "Edit" to edit the highlighted cells' : "Click to edit cell value";
    return gg;
  }

  const processWarningMssg = () => {
    setCellEditable(false);
    var bidding_list_opened = JSON.parse(sessionStorage.getItem('bidding_list_opened') || {});
    var qq = aa();
    if(!bidding_list_opened.status.includes('Planner') && (column.id == 'customer_bid_quantity' || column.id == 'customer_bid_price' || column.id == 'customerComments' || column.id == 'quantity_to_offer_bid' || column.id == 'vendor_bid_price')) {
      let nm = !qq ? 'Click "Counter Offer" to edit the cell value' : 'Click "Edit" to edit the highlighted cells';
      notifyMessage(nm);
      setCellEditable(true);
    };
  }

  const handleCellChange = (e) => {
    if (e.target) {
      setVal(e.target.value);
    } else {
      setVal(e);
    }
    cellObj.updateCellData(row, column, val, true,true)
  };

  const CommentField = () => {
    return <textarea rows={2} placeholder="Comment here"></textarea>;
  };

  const NumberField = () => {
    return (
      <input
        type="number"
        className="input-edit-mode"
        min={0}
        value={val === null ? " " : val}
        onInput={(ev) => {
          if (ev.key === "." && column.id !== 'customer_bid_price') {
            ev.preventDefault();
          }
        }}
        onChange={(e) => {
          if(column.id === "customer_bid_quantity"){
            if(row.original.quantity_to_offer_bid && 
              !(parseInt(e.target.value??"0") > parseInt(row.original.quantity_to_offer_bid??"0"))
              ){
                handleCellChange(e);
              }
              else{
                e.preventDefault();
                let sccs = <div className="tosterIcon"><div>Bid Quantity should be lesser than Quantity to offer</div></div>
                toast.error(sccs, {
                  containerId: "A",
                });
              }
          }else if(column.id === "quantity_to_offer_bid"){
            if(row.original.quantity_to_offer_bid && 
              !(parseInt(e.target.value??"0") > parseInt(row.original.quantity_available??"0"))
              ){
                handleCellChange(e);
              }
              else{
                e.preventDefault();
                let sccs = <div className="tosterIcon"><div>Quantity to offer should be lesser than Available Quantity</div></div>
                toast.error(sccs, {
                  containerId: "A",
                });
              }
          }else{
            handleCellChange(e);
          }
          // if (column.id === "customer_bid_quantity" || column.id === "quantity_to_offer_bid") {
          //   if (
          //     row.original.quantity_to_offer_bid && 
          //     parseInt(e.target.value??"0") <= parseInt(row.original.vendor_bid_quantity??"0")
          //   ) {
          //     console.log("aaaaaaaaa",row.original.quantity_to_offer_bid,column.id)
          //     handleCellChange(e);
          //   } else if (
          //     (!row.original.quantity_to_offer_bid && e.target.value) ||
          //     parseInt(e.target.value??"0") > parseInt(row.original.quantity_to_offer_bid??"0")
          //   ) {
          //     console.log("eeeeeeee",row.original.quantity_to_offer_bid,column.id)
          //     e.preventDefault();
          //     let sccs = <div className="tosterIcon"><div>Bid Quantity should be lesser than Quantity to offer</div></div>
          //       toast.error(sccs, {
          //         containerId: "A",
          //       });
          //   }
          //   else if(!e.target.value){
          //     handleCellChange(e);
          //   }
          // } else {
          //   handleCellChange(e);
          // }
        }}
        onBlur={(e) => {
          if (column.id === "customer_bid_price"){
            only2decimals(e);
          }
          cellObj.updateCellData(row, column, e.target.value ?? 0, true, true);
        }}
        onKeyDown={(ev) => {
          if (ev.key === "." && column.id !== 'customer_bid_price') {
            ev.preventDefault();
          }
          BlockInvalidChar(ev);
        }}
      />
    );
  };

  const notifyMessage = (mssg) => {
    let sccs = <div className="tosterIcon"><div>{mssg}</div></div>
    toast.info(sccs, {
        containerId: "offerToast",
    });
  }

  const TextField = () => {
    return (
      <input
        type="text"
        value={val === null ? " " : val}
        onChange={handleCellChange}
         onBlur={() => cellObj.updateCellData(row, column, val, true,true)}
      />
    );
  };

  let FieldType;
  switch (column.id) {
    case "customer_bid_quantity":
      FieldType = NumberField;
      break;
    case "customer_bid_price":
      FieldType = NumberField;
      break;
    case "vendor_bid_price":
      FieldType = NumberField;
      break;
    case "vendor_bid_quantity":
      FieldType = NumberField;
      break;
    case "quantity_to_offer_bid":
      FieldType = NumberField;
      break;
    case "minimum_acceptance_price":
      FieldType = NumberField;
      break;
    default:
      FieldType = TextField;
  }

  let commentsLogLine =  getCommentsLog(cellObj);
  const titlecommentsLog = commentsLogLine.join("\n");
  const commentsLogLinePreview = commentsLogLine.filter((o,i)=>i<6)
  const checkCommentsCount = () => commentsLogLine.length > 5;

  const setShowCommentsLog_hide = () => {
    setShowCommentsLog((s) => !s);
    console.log("ShowCommentsLog------",showCommentsLog)
  }
  
  return (
    <>
      {(cellObj.clickedIndex && (
        <div className="edit-cell">{FieldType()}</div>
      )) || (
        <>
          {column.id !== "comments" && !cellObj.clickedIndex && 
            <div
              className={`readonly ${checkCommentsCount() ? 'cursor-pointer' : ''}`} style={{ cursor : isCellEditable ? 'pointer' : 'default', width: "100%", height: "100%" }}
              title={column.id !== "commentsLog" ? processTitle() : checkCommentsCount() ? 'Click to read all comments' : ''}
              onClick={()=>column.id !== "commentsLog" ? processWarningMssg() : checkCommentsCount() ? setShowCommentsLog(true) : ''}

            >

            {column.id === "commentsLog" && !cellObj.clickedIndex ? ( 
              <>
              <OverlayTrigger overlay={
                <Tooltip id="tooltip" style={{ width : "400px"}}>
                  <div className="content">{buildCommentsTooltip(commentsLogLinePreview)}</div>
                </Tooltip>} 
                trigger={["hover","focus"]}
                placement="left"
                delay={{ show: 200, hide: 0 }}
              >
                <span >{titlecommentsLog}</span>
              </OverlayTrigger>


              <Popup
                className="glossary-add-edit-popup commentsLogPopup size-md"
                open={showCommentsLog}
                closeOnDocumentClick={false}
                closeOnEscape={false}
                modal
                onOpen={()=>{
                  console.log("rooot",document.querySelector("#popup-root"))
                  document.querySelector("#popup-root").classList.add("comments-log")
                }}
                onClose={()=>{
                  document.querySelector("#popup-root").classList.remove("comments-log")
                }}
              >
                  <div
                      id="ccp"
                      className="popup-header modal-header-raised d-flex align-items-center justify-content-between"
                    >
                        <h5 className="mt-2">Comments Log</h5>
                        <button
                          type="button"
                          className="btn-close"
                          aria-label="Close"
                          onClick={() => {
                            setShowCommentsLog(false);
                          }}
                        ></button>
                      </div>

                        <div className="scroll-content">
                                <div className="d-block nav-tabs-wrap ">
                                  {buildCommentsTooltip(commentsLogLine)}
                                </div>
                        </div>
                </Popup>
          </>
            ) : (val || value)}
            </div>
          }

          {column.id === "comments" && !cellObj.clickedIndex && 
            <div>{CommentField()}</div>
          }

            
        </>
      )}
    </>
  );
};
