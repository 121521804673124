import { API } from "../backend";
import axios from "axios";
import { notify } from "../../src/Common/toast";
var fileSaver = require("file-saver");

export const InflowRiskVisibilityDetails = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getInflowRiskDetails`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const addPulishStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/addToInflowPublishActions`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const setPublishStatus = async (payload) => {
  try {
    const response = await axios.put(`${API}/store/updateInflowPublishActions`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const checkFileStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getTaskProfileUpdate`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const downloadInflowRiskDetails = async (payload) => {
  axios.post(`${API}/store/downloadInflowRiskDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "inflow_risk_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

//Need to call `uploadWasteRiskDetails`
export const excelFileUpload = async (formData) => {
  try {
    let response = await axios.post(
      `${API}/store/uploadInflowDetails`,
      formData
    );
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateWasteRiskCellData = async (payload) => {
  try {
    let response = await axios.put(`${API}/store/updateInflowRiskKPI`, payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const PendingActionsCount = async (payload) => {
  try {
    const response = await axios.get(
      `${API}/store/getInflowRiskPendingActionsCount`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const downloadInflowRiskDBDetails = async (payload) => {
  axios.post(`${API}/store/downloadInflowRiskDBDetails`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "Inflow_db_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const getInflowUpdatedDate = async (payload) => {
  try {
    const response = await axios.get(
      `${API}/store/getInflowUpdatedDate  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getInflowLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/inflow  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getInflowEditedDatesLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/get-edited-dates  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getInflowPublishedDatesLogs = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/logs/get-published-dates  `,
      payload
    );
    console.log("Data", response.data);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const saveInflowUserPreferences = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/saveInflowUserPreferences`, payload);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getInflowUserPreferences = async (payload) => {
  try {
    let response = await axios.get(`${API}/store/getInflowUserPreferences?userEmail=${payload}`);
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};
