import React, { useState, StrictMode, useEffect, useContext } from "react";
import SideMenu from "../../Views/common/SideMenu";
import Header from "../../Common/header";
import Dropdown from "../../Views/common/Dropdown";
import StatusSummaryTable from "./common/StatusSummaryTable";
import Crtracker from "../../Common/crtracker/Crtracker";
import { getfgslobUserPreferences, savefgslobUserPreferences } from "../../Services/WasteRiskDetailServices";
import { UserContext } from "../Application";
const Inflow = () => {
  const [dropDownValues, setDropDownValues] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const user = useContext(UserContext)
  const [reeload,setreeload] =useState(false)

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }
  const [ asddd,setasddd] =useState(false)

  useEffect(()=>{
    const dummy={alert:[],
      businessGroup:[],
      category:[],
      country:[],
      foodSolution:[],
      forecastFamily:[],
      mrpc:[],
      nextStep:[],
      plant:[],
      reporting_family:[],}
    
    getfgslobUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0].UserPreference).preferenceData)       
      window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
      setasddd(true)
      }    
      else{
        const payload = {
          userRoleId:user.userRole==="SuperAdmin"?"1":user.userRole==="Admin"?"2":"3",
          userRole:user.userRole,
          userEmail:user.email,
          userPreference:{ preferenceData:dummy,
            selectedColumns:["all"]
          }}          
        console.log(payload)
        savefgslobUserPreferences(payload).then((res)=>{setreeload(!reeload)})
        console.log(dummy)
        window.sessionStorage.setItem("preferences",JSON.stringify({preferenceData:{},selectedColumns:['all']}))
      }  
      
    }
  )
  },[user])
  useEffect(()=>{
    getfgslobUserPreferences(user.email).then(res=>{
      if(res.data[0]){
        setDropDownValues(JSON.parse(res.data[0]?.UserPreference).preferenceData)
        // saveUserPreference(JSON.parse(res.data[0].UserPreference))
        window.sessionStorage.setItem("preferences",(res.data[0].UserPreference))
        setasddd(true)
      }
    }
  )
  },[reeload])

  return (
    <>
      <div className="container-fluid pl-0">
        <div className="main_header align-baseline">
        <Header isNotProduction={!isProd()} />
        </div>
        <div className="main-content-row">
          <div className="side-menu">
            <SideMenu />
          </div>
          <div className="main-div-content">
          {/* <div class="page-body-top-content">
          <Crtracker />
        </div> */}
            {asddd && <Dropdown
              list={["Business Group", "MCO", "Country", "Category", "Brand", "Residual Status", "Deal Type", "Plant Code"]}
              setDropDownValues={(values) => setDropDownValues({ ...values })}
              setFilterOptions={setFilterOptions}
            />}
            <StatusSummaryTable
              dropDownValues={dropDownValues}
              filterOptions={filterOptions}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Inflow;
