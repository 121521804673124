import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import Offer from './common/Offer';
import { VendorBiddingList } from '../../Services/CentralDealManagementDetailServices';
import { UserContext } from '../Application';


function EmailRedirectLink() {
    const location = useLocation();
    const history = useHistory();
    const user = useContext(UserContext);
    const [biddingObject, setBiddingObject] = useState({});
    const [isBiddingPopupOpen, setBiddingPopupOpen]=useState(false);
    const [isOfferModalOpen, setOfferModalOpen] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const [paramBidingListId, setParamBiddingListId] = useState()
    const [paramBidingListName, setParamBiddingListName] = useState()

    console.log(queryParams.get('xya'))
    useEffect(() => {
        if (queryParams.get('biddingListId')) {
          setParamBiddingListId(queryParams.get('biddingListId'))
        }
        if (queryParams.get('biddingListName')) {
          setParamBiddingListName(queryParams.get('biddingListName'))
        }
        
      }, [])
      
    useEffect(()=>{
        console.log(user)
        let payload
        if(paramBidingListName && paramBidingListId){
          payload={
            id:paramBidingListId,
            name:paramBidingListName
          }
          getBiddingListDetails(payload)
        }
        
    },[user,paramBidingListName,paramBidingListId])

    const getBiddingListDetails = useCallback((payload) => {
        var payloadObj = {
          biddingListID: payload.id,//"19EAFA94-F82E-4B00-BAD7-9D8CBA79801A",//payload.biddingListID,
          biddingListName: payload.name,//"newdemotest1",//payload.biddingListName,
        };
        VendorBiddingList(payloadObj, false, false).then((res2) => {
            res2.biddingListDetail[0].biddingListID = res2.biddingListDetail[0].BiddingListID;
            res2.biddingListDetail[0].biddingListName = res2.biddingListDetail[0].BiddingListName;
            res2.biddingListDetail[0].biddingGroupID = res2.biddingListDetail[0].BiddingGroupID;
            setBiddingObject({ data : res2.biddingListDetail[0], whole_data : payload, detail : "assd" });
            setBiddingPopupOpen(true);
        })
    },[]);

    const handleBiddingPopupControl = (popupStatus) => {
        setBiddingPopupOpen(popupStatus);
      }

    const isUserSuperior = () => {
        var isUserSuperiorVar = false;
        if(user.userRole == "SuperAdmin") {
          isUserSuperiorVar = true;
        } else {
          if(user.supervisorEmail !== null || user.supervisorEmail !== undefined || user.supervisorEmail !== "") {
            isUserSuperiorVar = false;
          }
        }
        return isUserSuperiorVar;
      }

    return (
        <>
        
        {Object.keys(biddingObject).length > 0 &&  <Offer
          show={isBiddingPopupOpen}
          onHide={() => {
            setBiddingPopupOpen(false);
            setOfferModalOpen(false);
            if(user.userRole === "Admin" || user.userRole === "User" || user.userRole === "SuperAdmin"){
              window.location.replace('/dealcalendar')
            }
            else{
              window.location.replace('/biddingportal')
            }
          }}
          biddingObject={biddingObject.data}
          biddingWholeObject={biddingObject.whole_data}
          detail={biddingObject.detail}
          biddingDataObject={biddingObject.data}
          setBiddingPopupOpen={(val) => {
            setBiddingPopupOpen(val);
          }}
          setOfferModalOpen={(val) => {
            setOfferModalOpen(val);
          }}
          onBiddingPopupControl={handleBiddingPopupControl}
          key={biddingObject.data.id}
          isExternal={(!user.userRole || (user.userRole === undefined || user.userRole === "Customer")) ? true : false}
          displayedAsNotification={isUserSuperior()}
          isNoEditList
        />}
        </>
    )
}

export default EmailRedirectLink