import React, { StrictMode, useState } from 'react'
import Header from '../../../Common/header';
import SideMenu from '../../../Views/common/SideMenu';
import Crtracker from '../../../Common/crtracker/Crtracker';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LegendMarginPlugin } from '../../InflowRiskVisibility/plugins';
import Summary1 from './Summary1';
import Summary2 from './Summary2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);

function InflowSummary() {
    const [screeenView, setScreenView] = useState(1);
    let isProd = () => {
        if (window.location.href.includes('-p-')) {
            return true
        }
        else if (window.location.href.includes('localhost')) {
            return true
        } else {
            return false
        }
    }
    return (

        <div className="container-fluid pl-0 tablegrid-height-fluid">
            <div className="main_header align-baseline">
                <Header isNotProduction={!isProd()} />
            </div>
            <div className="main-content-row">
                <div className="side-menu">
                    <SideMenu />
                </div>
                <div className="main-div-content">
                    {/* <div class="page-body-top-content">
                        <Crtracker />
                    </div> */}
                    <div className="page-body-top-content">
                        <div className="d-block nav-tabs-wrap ">
                            <ul className="nav nav nav-tabs bg-waste bg-waste-margin right30">
                                <li className="nav-item">
                                    <button
                                        hidden={true}
                                        type="button"
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        data-bs-target="#Summary-View"
                                        href="##"
                                        onClick={() => setScreenView(1)}
                                    >
                                        Summary
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        type="button"
                                        hidden={true}
                                        className="nav-link "
                                        data-bs-toggle="tab"
                                        data-bs-target="#Table-View"
                                        href="##"
                                        onClick={() => setScreenView(2)}
                                    >
                                        Table
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/* sds */}
                    <div className="tab-content mt-2" id="tabContent" style={{ width: "100% " }}>
                        <div
                            className="tab-pane active"
                            id="Summary-View"
                            role="tabpanel"
                            aria-labelledby="Summary-View"
                        >
                            {screeenView === 1 && <Summary2 setScreenView={setScreenView} />}
                        </div>
                        <div
                            className="tab-pane "
                            id="Table-View"
                            role="tabpanel"
                            aria-labelledby="Table-View"
                        >
                            {screeenView === 2 && <Summary1 setScreenView={setScreenView} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InflowSummary