import React, { useEffect, useState } from "react";
import { BlockInvalidChar } from "../../../utility/helper";
import ImagePlaceholderImg from "../../../Assets/Images/img_placeholder.jpg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ULModal from "../../../Common/Modal";
import Loader from "../../../Common/loader";

export const EditCell = (cellObj) => {
  const { column, row, value } = cellObj.cell;
  const [val, setVal] = useState(value);
  const handleCellChange = (e) => {
    setVal(e.target.value);
  };

  return (
    <>
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && (
          <div className="edit-cell">
            <input
              type="Number"
              value={val}
              onChange={handleCellChange}
              onBlur={() => cellObj.updateCellData(row, column, val)}
              autoFocus={true}
              onKeyDown={BlockInvalidChar}
            />
          </div>
        )) || (
          <div
            className="readonly flex align-items-center"
            title={val ? val : 'Click to edit cell value'}
            onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
            style={{ width: "100%", height: "100%" }}
          >
            {val}
          </div>
        )}
    </>
  );
};

export const ImagePlaceHolder = (cellObj, cellInfo) => {
  const { value } = cellInfo;
  const [openConfirm, setOpenConfirm] = useState(false)
  const [errorImg, setErrorImg] = useState(false)
  const [fullImageUrl, setFullImageURL] = useState()

  const closeModal = () => {
    setOpenConfirm(false);
    setFullImageURL("");
  };

  useEffect(() => {
    if (openConfirm) {
      let getRoot = document.querySelector("#popup-root");
      getRoot?.classList?.add('popup-zindex-high');
    } else {
      let getRoot = document.querySelector("#popup-root");
      getRoot?.classList.forEach((i) => {
        if (i.includes("popup-zindex-high")) {
          getRoot?.classList.remove('popup-zindex-high');
        }
      })
    }
  }, [openConfirm])
  return (
    <>
      <div className="td-in-2-wrap">
        <div className="td-in-2-img A" style={{ float: "left", paddingRight: "5px" }}>
          {!errorImg ?
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip">
                  <div className="content">
                    <img src={cellObj.row.original.thumbnailURL} />
                  </div>
                </Tooltip>}
              trigger={["hover", "focus"]}
              placement="right"
              delay={{ show: 200, hide: 0 }}
            >
              <img
                src={cellObj.cell.row.original?.thumbnailURL ? cellObj.cell.row.original?.thumbnailURL : ImagePlaceholderImg}
                alt="Img"
                className={`img-fluid ${cellObj.cell.row.original?.thumbnailURL ? cellObj.cell.row.original?.thumbnailURL : 'no_image_added'}`}
                onClick={() => setOpenConfirm(true)}
                onError={() => setErrorImg(true)}
                style={{ backgroundColor: "white", width: "40px" }}
              />
            </OverlayTrigger>

            :
            <img
              src={ImagePlaceholderImg}
              onClick={() => setOpenConfirm(true)}
              alt="Img"
              className="img-fluid no_image_added"
              style={{ backgroundColor: "white", width: "40px" }}
            />
          }


          <ULModal
            show={openConfirm}
            onHide={() => setOpenConfirm(false)}
            className={"modal-sku-image"}
            isheader={true}
          >
            <div className="modal-content-image">
              {!errorImg ?
                <img
                  src={cellObj.cell.row.original?.fullURL ? cellObj.cell.row.original?.fullURL : ImagePlaceholderImg}
                  alt="Img"
                  className="img-fluid"
                  onError={() => setErrorImg(true)}
                  style={{ backgroundColor: "white", marginTop: "40px", height: "400px" }}
                /> :
                <img
                  src={ImagePlaceholderImg}
                  alt="Img"
                  className="img-fluid no_image_added"
                  style={{ backgroundColor: "white", marginTop: "40px", height: "400px" }}
                />
              }
              <div className="loader-wrap">
                <Loader />
              </div>
            </div>
          </ULModal>
        </div>
        <div className="td-in-2">{cellObj.cell.value}</div>
      </div>
    </>
  );
};
