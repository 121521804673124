import React, { useState, useEffect, useCallback, useContext } from "react";
import { getDealStatusSummaryTableData } from "../../../Services/WasteRiskSummaryService";
import TableGrid from "../../../Common/table/TableGrid";
import { payloadWithFilters, COLUMNS } from "../helpers";
import Loader from "../../../Common/loader";
import { UserAccessContext } from "../../Application";

const SummaryTable = ({ dropDownValues }) => {
  const [gridData, setGridData] = useState([]);
  const [SortBy, setSortBy] = useState("mco");
  const [SortType, setSortType] = useState("ASC");
  const [isLoading, setLoading] = useState(false);
  const userAccess = useContext(UserAccessContext)


  useEffect(() => {
    if(dropDownValues)
    getGridData(payloadWithFilters(dropDownValues,userAccess));
  }, [dropDownValues]);

  const getGridData = useCallback((payload) => {
    setLoading(true);
    getDealStatusSummaryTableData(payload)
    .then((response) => {
      setLoading(false);
      setGridData(response.data);
    }).catch(()=>setLoading(false))
  });

  return (
    <>
      {gridData?.length && (
        <TableGrid
          columns={COLUMNS}
          data={gridData}
          SortBy={SortBy}
          SortType={SortType}
          setSortBy={setSortBy}
          setSortType={setSortType}
        />
      )}
      {gridData.length === 0 && 
        <div className="flex-at-center" style={{height:"200px" }}>
            {isLoading && (
                <Loader />
            )}
            {!isLoading && (
              
                <p>No Data</p>
            )}
        </div>
      }
    </>
  );
};

export default SummaryTable;
