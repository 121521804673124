import React, { useEffect, useState } from "react";
import { BlockInvalidChar } from "../../../utility/helper";
import ImagePlaceholderImg from "../../../Assets/Images/img_placeholder.jpg";
// import Popup from "reactjs-popup";

// import ULModal from "../../Common/Modal";
// import Loader from "../../Common/loader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ULModal from "../../Modal";
import Loader from "../../loader";

export const EditCell = (cellObj) => {
  const { column, row, value } = cellObj.cell;
  const [val, setVal] = useState(value);

  const handleCellChange = (e) => {
    setVal(e.target.value);
  };


  const NumberField = () => {
    return (
      <>
        {(cellObj.clickedFrom !== "biddingList" && (
          <div className="edit-cell">
            <input
              type="number"
              value={val === null ? " " : val}
              onChange={handleCellChange}
              onBlur={() => cellObj.updateCellData(row, column, val)}
              onKeyDown={BlockInvalidChar}
            />
          </div>
        )) || (
            <div className="readonly" style={{ width: "100%", height: "100%" }}>
              {val}
            </div>
          )}
      </>
    );
  };

  /* const TextField = () => {
    return (
      <div className="edit-cell">
        <input
          type="text"
          value={val === null ? " " : val}
          onChange={handleCellChange}
          onBlur={() => cellObj.updateCellData(row, column, val)}
        />
      </div>
    );
  }; */

  return (
    <>
      {(cellObj.clickedIndex === row.index &&
        cellObj.clickedCell === column.id && <>{NumberField()}</>) || (
          <div
            className="readonly"
            onClick={() => cellObj.handleClickEditCell(cellObj.cell)}
            style={{ width: "100%", height: "100%" }}
          >
            {val}
          </div>
        )}
    </>
  );
};

export const ImagePlaceHolder = (cellObj, cellInfo) => {
  // const { value } = cellInfo;
  // const [openConfirm, setOpenConfirm] = useState(false)
  // return (
  //   <>
  //     <div className="td-in-2-wrap">
  //       <div className="td-in-2-img" style={{ float: "left", paddingRight: "5px" }}>
  //         <img
  //           src={cellObj.cell.row.original?.thumbnailURL?cellObj.cell.row.original?.thumbnailURL:ImagePlaceholder}
  //           alt="Img"
  //           className="img-fluid"
  //           onClick={() => setOpenConfirm(true)}
  //           style={{ width: "45px", height: "50px" }}
  //         />
  //         <Popup className="size-xs"
  //           style={{ width: "fit-content" }}
  //           arrow={true}
  //           open={openConfirm}
  //           onClose={() => setOpenConfirm(false)}
  //           modal
  //           tooltip
  //           nested
  //         >
  //           <div>
  //             <button
  //               type="button"
  //               className="btn-close"
  //               aria-label="Close"
  //               onClick={() => {
  //                 setOpenConfirm(false)
  //               }}
  //             ></button>


  //             <div className="content h-100">

  //               <img
  //                 src={cellObj.cell.row.original?.fullURL?cellObj.cell.row.original?.fullURL:ImagePlaceholder}
  //                 alt="Img"
  //                 className="img-fluid"
  //                 onClick={openConfirm}
  //                 style={{ marginTop: "40px", height: "400px" }}
  //               />
  //             </div>
  //           </div>
  //         </Popup>
  //       </div>
  //       <div className="td-in-2">{cellObj.cell.value}</div>
  //     </div>
  //   </>
    
  // );
  console.log(cellInfo, cellObj.cell)
  const { value } = cellInfo;
  const [openConfirm, setOpenConfirm] = useState(false)
  const [errorImg, setErrorImg] = useState(false)
  useEffect(()=>{
    if(openConfirm){
      let getRoot = document.querySelector("#popup-root");
      getRoot?.classList?.add('popup-zindex-high');
    } else {
      let getRoot = document.querySelector("#popup-root");
      getRoot?.classList.forEach((i)=>{
        if(i.includes("popup-zindex-high")){
          getRoot?.classList.remove('popup-zindex-high');
        }
      })
    }
  },[openConfirm])
  return (
    <>
      <div className="td-in-2-wrap">
        <div className="td-in-2-img A" style={{ float: "left", paddingRight: "5px" }}>
          {/* <img
            src={cellObj.cell.row.original?.thumbnailURL ? cellObj.cell.row.original?.thumbnailURL : ImagePlaceholder}
            alt="Img"
            className="img-fluid"
            onClick={() => setOpenConfirm(true)}
            style={{ width: "45px", height: "50px" }}
          /> */}
          {!errorImg ?
              <OverlayTrigger
              overlay={
                <Tooltip id="tooltip">
                <div className="content">
                  <img src={cellObj.row.original.thumbnailURL} />
                </div>
              </Tooltip>} 
              trigger={["hover","focus"]}
              placement="right"
              delay={{ show: 200, hide: 0 }}
            >
                <img
                  src={cellObj.cell.row.original?.thumbnailURL ? cellObj.cell.row.original?.thumbnailURL : ImagePlaceholderImg}
                  alt="Img"
                  className="img-fluid"
                  onClick={() => setOpenConfirm(true)}
                  onError={() => setErrorImg(true)}
                  style={{ backgroundColor:"white",width: "40px", }}
                />
            </OverlayTrigger>
             :
            <img
              src={ImagePlaceholderImg}
              alt="Img"
              className="img-fluid no_image_added"
              style={{ backgroundColor:"white",width: "40px" }}
            />
          }



          <ULModal
            show={openConfirm}
            onHide={()=>setOpenConfirm(false)}
            className={"modal-sku-image"}
            isheader={true}
          >
            <div className="modal-content-image">
                {!errorImg ?
                  <img
                    src={cellObj.cell.row.original?.fullURL ? cellObj.cell.row.original?.fullURL : ImagePlaceholderImg}
                    alt="Img"
                    className="img-fluid"
                    onError={() => setErrorImg(true)}
                    style={{backgroundColor:"white", marginTop: "40px", height: "400px" }}
                  /> :
                  <img
                    src={ImagePlaceholderImg}
                    alt="Img"
                    className="img-fluid no_image_added"
                    style={{backgroundColor:"white", marginTop: "40px", height: "400px" }}
                  />
                }
              <div className="loader-wrap">
                <Loader  />
              </div>
            </div>
          </ULModal>



          {/* <Popup className="size-xs"
          id="popupId124"
            style={{ width: "fit-content" }}
            arrow={true}
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            modal
            tooltip
            nested
          >
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => {
                  setOpenConfirm(false)
                }}
              ></button>


              <div className="content h-100">

                {!errorImg ?
                  <img
                    src={cellObj.cell.row.original?.fullURL ? cellObj.cell.row.original?.fullURL : ImagePlaceholder}
                    alt="Img"
                    className="img-fluid"
                    onError={() => setErrorImg(true)}
                    style={{backgroundColor:"white", marginTop: "40px", height: "400px" }}
                  /> :
                  <img
                    src={ImagePlaceholder}
                    alt="Img"
                    className="img-fluid"
                    style={{backgroundColor:"white", marginTop: "40px", height: "400px" }}
                  />
                }
              </div>
            </div>
          </Popup> */}
          
        </div>
        <div className="td-in-2">{cellObj.cell.value}</div>
      </div>
    </>
  );
};
