import React, { useEffect, useRef, useState } from "react";
import TableGrid from "../TableGrid";
import Popup from "reactjs-popup";
import {
  getFgslobGlossary,
  getInflowGlossary,
  getPOMGlossary,
  saveFgslobGlossary,
  saveInflowGlossary,
  savePOMGlossary,
} from "../../../../Services/UserManagementService2";
import { COLUMNS, COLUMNS_ADD } from "../ColumnsGlossery";

import SearchIcon from "../../../../Assets/img/search.svg";
import ClearIcon from "../../../../Assets/img/icon-cancel-white.svg";
import ClickIcon from "../../../../Assets/img/clickicon.svg";

import Loader from "../../../../Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function GlosseryTable(props) {
  const [data, setData] = useState();
  const searchKeyword = useRef(null);
  const [updateDataGlossary, setUpdateDataGlossary] = useState([]);
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [addGlossary, setAddGlossary] = useState({});
  const [editNewKpi, setEditNewKpi] = useState();
  const [selection, setSelections] = useState();
  const [isAddbuttonClicked, setIsAddbutoonClicked] = useState(false);
  const [isSetSaveLoaderInit, setSaveLoaderInit] = useState(false);

  const clearSearchClose = () => {
    searchKeyword.current.value = "";
    setData(props.data);
    // columnsListWrap();
  };
  
  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
  }, [selection]);
  
  useEffect(() => {
  }, [updateDataGlossary]);
  
  useEffect(() => {
    if (props.glossaryOption !== "Edit Glossary" || isAddbuttonClicked) {
      if (
        addGlossary.column_name !== undefined &&
        addGlossary.description !== undefined
      )
        setUpdateDataGlossary([addGlossary]);
    }
  }, [addGlossary]);

  const searchbutton = () => {
    setData(
      data.filter((i) =>
        i.column_name
          .toString()
          .toLowerCase()
          .includes(searchKeyword.current.value.toString().toLowerCase())
      )
    );
  };

  const updateCellData = (row, column, value) => {
    const rowId = row.original.id;
    let updatedValue = value;
    var obj = {};
    if (props.glossaryOption === "Edit Glossary" && !isAddbuttonClicked) {
      obj = {
        id: row?.original?.id,
        column_name:
          column.id === "column_name" ? updatedValue : row.original.column_name,
        description:
          column.id === "description" ? updatedValue : row.original.description,
      };
      setUpdateDataGlossary((prev) => [...prev, obj]);
    } else {
      if (column.id === "column_name") {
        setAddGlossary((prev) => {
          return { ...prev, column_name: updatedValue };
        });
      }
      if (column.id === "description") {
        setAddGlossary((prev) => {
          return { ...prev, description: updatedValue };
        });
      }
    }
  };

  const editCellProps = {
    updateCellData,
  };
  const updateGlossaryData = () => {
    setIsAddbutoonClicked(false);
    let dataa = data.map((objData) => {
      const matchingObjUpdate = updateDataGlossary.find(
        (objUpdate) => objUpdate.id === objData.id
      );

      if (matchingObjUpdate) {
        // If there's a matching id in updateDataGlossary, update objData with objUpdate's data
        return { ...objData, ...matchingObjUpdate };
      } else {
        // If there's no matching id in updateDataGlossary, keep objData as it is
        return objData;
      }
    });
    updateDataGlossary.forEach((objUpdate) => {
      if (!data.find((objData) => objData.id === objUpdate.id)) {
        dataa.push(objUpdate);
      }
    });
    dataa = dataa.filter(
      (aItem) => !selection.some((bItem) => bItem.original.id === aItem.id)
    );
    dataa = dataa.map(({ column_name, description }) => ({
      column_name,
      description,
    }));
    let dataq = {
      glossarydata: dataa.filter((obj) => Object.keys(obj).length > 0),
    };
    if (props.screenoption === "FG SLOB") {
      setSaveLoaderInit(true);
      saveFgslobGlossary(dataq)
        .then((response) => {
          setSaveLoaderInit(false);
          setUpdateDataGlossary([]);
        })
        .catch(() => setSaveLoaderInit(false));
      setAddGlossary({});

      let sccs = (
        <div className="tosterIcon">
          <div>Data updated successfully!</div>
        </div>
      );
      toast.success(sccs, {
        containerId: "ToastifyModalContainer",
      });

      props.handleClose();
    }
    if (props.screenoption === "INFLOW") {
      setSaveLoaderInit(true);
      saveInflowGlossary(dataq)
        .then((response) => {
          setSaveLoaderInit(false);
          setUpdateDataGlossary([]);
        })
        .catch(() => setSaveLoaderInit(false));
      setAddGlossary({});

      let sccs = (
        <div className="tosterIcon">
          <div>Data updated successfully!</div>
        </div>
      );
      toast.success(sccs, {
        containerId: "ToastifyModalContainer",
      });
      props.handleClose();
    }
    if (props.screenoption === "POM") {
      setSaveLoaderInit(true);
      savePOMGlossary(dataq)
        .then((response) => {
          setSaveLoaderInit(false);
          setUpdateDataGlossary([]);
        })
        .catch(() => setSaveLoaderInit(false));
      setAddGlossary({});

      let sccs = (
        <div className="tosterIcon">
          <div>Data updated successfully!</div>
        </div>
      );
      toast.success(sccs, {
        containerId: "ToastifyModalContainer",
      });
      props.handleClose();
    }
    // window.location.reload()
  };
  const [dummyData, setDummyData] = useState([
    {
      id: null,
      column_name: props.columnData,
      description: null,
    },
  ]);
  useEffect(() => {
    setDummyData((prevData) => [
      {
        ...prevData[0],
        column_name: props.columnData,
      },
    ]);
  }, [props.columnData]);
  
  return (
    <div>
      <Popup
        className="glossary-add-edit-popup size-md"
        open={props.showModal}
        closeOnDocumentClick={false}
        modal
        nested
      >
        {(close) => (
          <>
          <ToastContainer
          enableMultiContainer
          containerId={"ToastifyModalContainer"}
          position={toast.POSITION.TOP_CENTER}
          autoClose={2000}
        />
            <div className="popup-header d-flex align-items-center justify-content-between">
              <h5 className="mt-2">
                {props.glossaryOption === "Edit Glossary" && !props.edit
                  ? "Edit Glossary"
                  : "Add New KPI to Glossary"}
              </h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => props.onCloseGlossaryModal()}
              ></button>
            </div>
            <div className="content position-relative">
              {props.glossaryOption === "Edit Glossary" && data?.length < 0 && (
                <div className={`tableLoader_overlay`}>
                  <div className="tableLoader_box">
                    <div className="tableLoader_box-in">
                      <Loader />
                    </div>
                  </div>
                </div>
              )}

              {data?.length > 0 && props.glossaryOption === "Edit Glossary" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: `${
                        props.glossaryOption === "Edit Glossary"
                          ? "space-between"
                          : "flex-end"
                      }`,
                    }}
                  >
                    <button
                      hidden={
                        props.glossaryOption !== "Edit Glossary" || props.edit
                      }
                      type="button"
                      className={`btn-slim  ${"btn btn-secondary"}`}
                      onClick={() => {
                        props.setEdit(true);
                        setIsAddbutoonClicked(true);
                      }}
                    >
                      Add New
                    </button>
                    {props.glossaryOption === "Edit Glossary" &&
                      !props.edit && (
                        <div
                          className="search-bar d-flex align-items-center"
                          style={{ width: "290px" }}
                        >
                          <span className="search-wrap-out w-100">
                            <span className="search-wrap w-100">
                              <img
                                src={SearchIcon}
                                className="filter-search"
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder="Search KPI "
                                className="btn-slim search-input bg-black w-100"
                                ref={searchKeyword}
                              />
                              <a
                                className="filter-clear"
                                onClick={() => clearSearchClose()}
                              >
                                <img src={ClearIcon} alt="" />
                              </a>
                            </span>
                            <input
                              type="button"
                              value="Search"
                              className="btn-slim search-btn"
                              onClick={() => searchbutton()}
                            />
                          </span>
                        </div>
                      )}
                  </div>
                  <div
                    className="pull-left mt-2"
                    style={{ width: "600px", marginBottom: "10px" }}
                  >
                    <span className="row-click-link">
                      {" "}
                      <img src={ClickIcon} alt="" />
                      {props.glossaryOption === "Edit Glossary" && !props.edit
                        ? "Users can enter new names for existing columns by clicking on the cells and entering the required text"
                        : "Click the highlighted cell to edit"}
                    </span>
                  </div>
                </>
              )}
              {props.glossaryOption !== "Edit Glossary" &&
                (!props.columnData?.length > 0) && (
                  <div className={`tableLoader_overlay`}>
                    <div className="tableLoader_box">
                      <div className="tableLoader_box-in">
                        <Loader />
                      </div>
                    </div>
                  </div>
                )}
              {data?.length > 0 &&
                !props.edit &&
                props.glossaryOption === "Edit Glossary" && (
                  <TableGrid
                    data={data}
                    {...editCellProps}
                    columns={COLUMNS}
                    cells_width_auto={true}
                    setSelection={setSelections}
                    table_type={"table_blue_bg"}
                  ></TableGrid>
                )}
              {props.columnData?.length > 0 &&
                (props.edit || props.glossaryOption !== "Edit Glossary") && (
                  <div>
                    <TableGrid
                      data={dummyData}
                      {...editCellProps}
                      columns={COLUMNS_ADD}
                      cells_width_auto={true}
                      setSelection={setSelections}
                    ></TableGrid>
                  </div>
                )}
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {props.glossaryOption !== "Edit Glossary" && (
                  <button
                    className={`btn btn-big btn-std-width ${"btn btn-primary"} ${
                      isSetSaveLoaderInit ? "processing" : ""
                    }`}
                    disabled={
                      addGlossary.column_name === undefined ||
                      addGlossary.description === undefined ||
                      addGlossary.column_name === "" ||
                      addGlossary.description === "" ||
                      isSetSaveLoaderInit
                    }
                    onClick={updateGlossaryData}
                  >
                    Save Changes
                  </button>
                )}
                {props.glossaryOption === "Edit Glossary" ? (
                  (
                    <button
                      className={`btn btn-big btn-std-width  ${"btn btn-primary"}`}
                      onClick={updateGlossaryData}
                      disabled={isSetSaveLoaderInit}
                    >
                      Save Changes
                    </button>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        )}
      </Popup>
    </div>
  );
}

export default GlosseryTable;
