import React from "react";
import { PomEditCell, DateRangeColumnFilter, FilterPrescentageData, DecimalToNumWithPercentage, FilterPrescentageWithExpiry } from "./CellAction"; //CellCheckBox
import { DecimalToNum } from "../../Inflow/InflowRiskVisibilityTable/CellAction";

export const COLUMNS = [
  {
    Header: "MRP Description",
    accessor: "mrpd",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "MRDR description",
    accessor: "description",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "MRDR",
    accessor: "mrdr_id",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "Plant code",
    accessor: "plant_code",
    show: true,
    editable: false,
    // sticky: true,
  },
  {
    Header: "Status ID",
    accessor: "status_id",
    show: true,
    editable: false,
    // sticky: true,
  },
  {
    Header: "Best before date",
    accessor: "bbd",
    show: true,
    editable: false,
    // sticky: true,
    Cell: (cellObj) => {
      return (
        <span
          className={`text-white border-radius-std ${cellObj.value == "1900-01-01" ? "" : cellObj.value
            }`}
          title={`${cellObj.value == "1900-01-01" ? "" : cellObj.value}`}
        >
          {cellObj.value == "1900-01-01" ? "" : cellObj.value}
        </span>
      );
    },
    Filter: DateRangeColumnFilter,
  },

  {
    Header: "Short shelf life date",
    accessor: "hah_date",
    show: true,
    editable: false,
    // sticky: true,
    Cell: (cellObj) => {
      return (
        <span
          className={`text-white border-radius-std ${cellObj.value == "1900-01-01" ? "" : cellObj.value
            }`}
          title={`${cellObj.value == "1900-01-01" ? "" : cellObj.value}`}
        >
          {cellObj.value == "1900-01-01" ? "" : cellObj.value}
        </span>
      );
    },
    Filter: DateRangeColumnFilter,
  },

  {
    Header: "Fictive SSL Date",
    accessor: "fictive_ssl_date",
    show: true,
    editable: false,
    // sticky: true,
  },
  
  {
    Header: "Add/View Customer",
    accessor: "add_customer",
    show: true,
    editable: false,
    class: "text-center",
    Cell: PomEditCell,
    isCasesColumnz: "isCasesColumnz",
  },
  {
    Header: "Floor Price",
    accessor: "floorpr",
    show: true,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Cost price",
    accessor: "costprice",
    show: true,
    editable: false,
    // round_off_values_no_decimal: true,
    decimal_value: true,
    show_euro_symbol: true
  },
  {
    Header: "#CUs in 1 case",
    accessor: "cu_cs",
    show: true,
    editable: false,
    round_off_values_no_decimal: true

  },
  {
    Header: "Status ID Description",
    accessor: "statusid_description",
    show: true,
    editable: false,
  },
  {
    Header: "Stock quantity",
    accessor: "qty",
    show: true,
    editable: false,
    isZUNColumn: true
  },
  {
    Header: "Quantity available for sales offer",
    accessor: "qty_to_offer",
    show: true,
    editable: true,
    Cell: PomEditCell,
    isZUNColumn: true
  },
  {
    Header: "Quantity left for sales offer",
    accessor: "total_left",
    show: true,
    editable: false,
    isZUNColumn: true
  },
  {
    Header:
      "Quantity reserved for sales offer (=sum of quantity of deals with status Plan, Confirmed)",
    accessor: "total_qty",
    show: true,
    editable: false,
    isZUNColumn: true
  },
  {
    Header: "Stock quantity",
    accessor: "qtyCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  {
    Header: "Quantity available for sales offer",
    accessor: "qtyToOfferCases",
    show: true,
    editable: true,
    Cell: PomEditCell,
    isCasesColumn: true
  },
  {
    Header: "Quantity left for sales offer",
    accessor: "totalLeftCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  {
    Header:
      "Quantity reserved for sales offer (=sum of quantity of deals with status Plan, Confirmed)",
    accessor: "totalQtyCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  

  {
    Header: "Stock quantity",
    accessor: "qtyCs",
    show: true,
    editable: false,
    isCUColumn: true
  },
  {
    Header: "Quantity available for sales offer",
    accessor: "qtyToOfferCs",
    show: true,
    editable: true,
    Cell: PomEditCell,
    isCUColumn: true
  },
  {
    Header: "Quantity left for sales offer",
    accessor: "totalLeftCs",
    show: true,
    editable: false,
    isCUColumn: true
  },
  {
    Header:
      "Quantity reserved for sales offer (=sum of quantity of deals with status Plan, Confirmed)",
    accessor: "totalQtyCs",
    show: true,
    editable: false,
    isCUColumn: true
  },
  // {
  //   Header: "Action",
  //   accessor: "actionsId",
  //   show: true,
  //   editable: true,
  //   Cell: PomEditCell,
  // },
  // {
  //   Header: "Action History",
  //   accessor: "action_history",
  //   show: true,
  //   editable: true,
  //   Cell: PomEditCell,
  // },
  {
    Header: "Owner",
    accessor: "owner",
    show: true,
    editable: true,
    // Cell:(cellObj) => {
    //   return (
    //     <span
    //     >
    //       {cellObj.value.replace(/["\[\]]/g, '')}
    //     </span>
    //   );
    // },
    Cell: PomEditCell,
  },
  {
    Header: "Next step",
    accessor: "nextStepId",
    show: true,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Operations note",
    accessor: "note",
    show: true,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "MRP controller",
    accessor: "mrpc",
    show: false,
    editable: false,
  },
  {
    Header: "Category",
    accessor: "category",
    show: false,
    editable: false,
  },
  {
    Header: "Business Group",
    accessor :"business_group",
    show: false,
    editable: false,
  },
  {
    Header: "Country",
    accessor: "country",
    show: false,
    editable: false,
  },
  
  {
    Header: "Group",
    accessor: "sector",
    show: false,
    editable: false,
  },
  {
    Header: "Size Pack Form",
    accessor: "size_pack_form",
    show: false,
    editable: false,
  },
  {
    Header: "Plant material status",
    accessor: "mmsta",
    show: false,
    editable: false,
  },
  {
    Header: "Obsoletes quantity in CU",
    accessor: "obsolete_qty_cu",
    show: false,
    editable: false,
  },
  {
    Header: "Obsoletes quantity",
    accessor: "obsolete_qty",
    show: true,
    editable: false,
    isZUNColumn: true
  },
  {
    Header: "Obsoletes quantity",
    accessor: "obsoleteQuantityCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  {
    Header: "Obsoletes quantity",
    accessor: "obsoleteQuantityCs",
    show: true,
    editable: false,
    isCUColumn: true
  },
  {
    Header: "Obsoletes quantity in value",
    accessor: "price_at_risk",
    show: false,
    editable: false,
    Cell: (cellObj) => {
      return (
        <span
          className="border-radius-std"
          title={`${parseInt(cellObj.value ?? 0)}`}
        >
          {parseInt(cellObj.value ?? 0)}
          €
        </span>
      );
    },
  },
  {
    Header: "NIV",
    accessor: "m_niv_cs",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Alert type",
    accessor: "alert_type",
    show: false,
    editable: false,
  },
  {
    Header: "Advice",
    accessor: "advice",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "#Cases on 1 layer",
    accessor: "units_per_laag",
    show: false,
    editable: false,
  },
  {
    Header: "#Layers on 1 pallet",
    accessor: "lagen_per_pal",
    show: false,
    editable: false,
  },
  {
    Header: "Pallet type",
    accessor: "type_pal",
    show: false,
    editable: false,
    // Cell: PomEditCell,
  },
  {
    Header: "#Cases on 1 pallet",
    accessor: "cs_pal",
    show: false,
    editable: false,
  },
  {
    Header: "EAN code CU",
    accessor: "cu_ean",
    show: false,
    editable: false,
  },
  {
    Header: "EAN code case",
    accessor: "cs_ean",
    show: false,
    editable: false,
  },
  {
    Header: "EAN code pallet",
    accessor: "pal_ean",
    show: false,
    editable: false,
  },
  {
    Header: "Consumer Advise Price",
    accessor: "cap",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Sales note",
    accessor: "sales_note",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Batch code",
    accessor: "batch_code",
    show: true,
    editable: false,
    class: "text-center",
    Cell: PomEditCell,
  },
  {
    Header: "Number Of Batches",
    accessor: "number_of_batches",
    show: false,
    editable: false,
  },
  {
    Header: "Delta Obsolete Quantity",
    accessor: "delta_obsolete_quantity",
    show: false,
    editable: false,
    isZUNColumn: true
  },
  {
    Header: "Delta Obsolete Quantity",
    accessor: "deltaObsoleteQuantityCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  {
    Header: "Delta Obsolete Quantity",
    accessor: "deltaObsoleteQuantityCs",
    show: false,
    editable: false,
    isCUColumn: true
  },
  // {
  //   Header: "Update date",
  //   accessor: "update_date",
  //   show: false,
  //   editable: false,
  // },

  {
    Header: "Country of ownership",
    accessor: "country_of_ownership",
    show: false,
    editable: false,
  },
  {
    Header: "Discount",
    accessor: "discount",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Price/CU",
    accessor: "price_per_cu",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "Price/Case",
    accessor: "price_per_case",
    show: false,
    editable: true,
    Cell: PomEditCell,
  },
  {
    Header: "# of Pallets",
    accessor: "number_of_pallets",
    show: false,
  },
  {
    Header: "BBD % remaining",
    accessor: "expiry_percent",
    show: true,
    editable: false,
    show_percentage: true,
    header_col_visibility_visible: true,
    Filter: FilterPrescentageWithExpiry
  },
  {
    Header: "TPM Provision EUR",
    accessor: "tpm_provisions_eur",
    show: true,
    editable: false,
    show_euro_symbol: true
  },
  {
    Header: "Food Solution Indicator",
    accessor: "foodSolutionIndicator",
    show: true,
    editable: false,
  },
  {
    Header: "SSL % remaining",
    accessor: "ssl_percent",
    show: true,
    editable: false,
    show_percentage: true,
    header_col_visibility_visible: true,
    // Filter:FilterPrescentageData
    Filter: FilterPrescentageWithExpiry,
  },
  {
    Header: "No of days stock pending polaris",//"Timestamp (date added to POM)",
    accessor: "no_of_days_stock_pending_polaris",//"timestamp_added",
    show: false,
    editable: false,
    Filter: DateRangeColumnFilter,
  },
  {
    Header: "Communication Code",
    accessor: "communication_code",
    show: true,
    editable: false,
  },
  {
    Header: "Forecast Family Code",
    accessor: "forecast_family_code",
    show: true,
    editable: false,
  },
  {
    Header: "Reporting Family",
    accessor: "reportingfamily",
    show: true,
    editable: false,
  },
  {
    Header: "Delta 4wk",
    accessor: "delta_4w",
    show: true,
    editable: false,
    isZUNColumn: true
  },
  {
    Header: "Delta 4wk",
    accessor: "delta4wCases",
    show: true,
    editable: false,
    isCasesColumn: true
  },
  {
    Header: "Delta 4wk",
    accessor: "delta4wCs",
    show: true,
    editable: false,
    isCUColumn: true
  },
  {
    Header: "Brand",
    accessor: "brand",
    show: true,
    editable: false,
  },
  {
    Header: "BBD Percentage At Last Sale Date",
    accessor: "bbd_per_at_last_sale_date",
    show: true,
    editable: false,
    Cell: DecimalToNumWithPercentage,
  },
  {
    Header: "Residual Qty Nordics",
    accessor: "residual_qty_nordics",
    show: true,
    editable: false,
    Cell: DecimalToNum,
  },
  {
    Header: "Batch Code Details",
    accessor: "batch_codes_concat",
    show: true,
    editable: false,
    Cell: DecimalToNum,
  },
  {
    Header: "Version History",
    accessor: "view_history",
    show: true,
    editable: false,
    class: "text-center",
    Cell: PomEditCell,
  },
  {
    Header: "Production Date",
    accessor: "productionDate",
    show: true,
    editable: false,
    class: "text-center",
  },
];

export const columnHeaderByKey = COLUMNS.reduce((prev, curr) => {
  prev[curr.accessor] = curr.Header;
  return prev;
}, {})