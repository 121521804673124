import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../Application";
import { getBiddingListName } from "../../../../Services/BiddingPortalService";
import { notify } from "../../../../Common/toast";
import BiddingListBar from "../../../CentralDealManagement/common/BiddingListBar";
import { setbiddingStatusClass, setbiddingStatusGroup } from "../../../../utility/constants";

const BiddingPortalExternalTable = () => {
  const user = useContext(UserContext);
  //Create list Item
  const [biddingList, setBiddingList] = useState([]);
  //Edit list Items
  const [selectedListItemForEdit, setSelectedListItemForEdit] = useState([]);
  const [editListCount, setEditListCount] = useState([]);
  const [editTotalMinBidPrice, setEditTotalMinBidPrice] = useState(null);
  const [editOfferPrice, setEditOfferPrice] = useState(null);
  const [editBuddingListDealType,setEditBuddingListDealType] =useState()


  const BiddingListName = useCallback((payload) => {
    getBiddingListName(payload).then((response) => {
      Object.keys(response.bidding_list ? response.bidding_list : (response ? response : {}))?.map(
        (key) => {
          if (
            (response.bidding_list ?? response)[key] !== "number" &&
            (response.bidding_list ?? response)[key]?.length > 0
          ) {
            (response.bidding_list ?? response)[key]["sg"] = [];
            (response.bidding_list ?? response)[key]["sg"]["a"] = [];
            (response.bidding_list ?? response)[key]["sg"]["b"] = [];
            (response.bidding_list ?? response)[key]["sg"]["c"] = [];
            (response.bidding_list ?? response)[key]["sg"]["d"] = [];
            (response.bidding_list ?? response)[key].map((w, id) => {
              w.detail = {
                BiddingStatus: (response.bidding_list ?? response)[key][id]
                  ?.BiddingStatus,
                biddingStatusClass: "pending",
              };
             
              let biddingStatus = (response.bidding_list ?? response)[key][id]
                ?.BiddingStatus;
              let biddingStatusM = biddingStatus.toLowerCase();
              let biddingStatusClass = setbiddingStatusClass(biddingStatusM);
              w.detail.biddingStatusClass = biddingStatusClass;
              let biddingStatusGroup = setbiddingStatusGroup(biddingStatusM);
              w.StatusGroup = biddingStatusGroup;
              (response.bidding_list ?? response)[key]["sg"][biddingStatusGroup].push(w);
            });
          }
        }
        
      );
      setBiddingList({ ...(response.bidding_list ?? response) });
    });
  }, []);

  useEffect(() => {
    BiddingListName({ customerEmail: user.email || user.username});
    
  }, [BiddingListName,user.username]);

  //Create New List



  return (
    <>
      <div className={`biddingtable opp`}>
        <div className="row">
            <div className="col-md-12">
              {/* Bidding list individual when logged in as customer */}
              <BiddingListBar
                setEditBuddingListDealType={setEditBuddingListDealType}
                biddingList={biddingList}
                isExternal={true}
                reload={() => {
                  BiddingListName({
                    customerEmail: user.email || user.username,
                  });
                }}
              />
            </div>
            <div></div>
        </div>
      </div>
    </>
  );
};

export default BiddingPortalExternalTable;
