import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../Common/header";
import "./Style/LandingPage.scss";
import { UserCheckContext } from "../App";
import { UserAccessContext, UserContext } from "./Application";
import Popup from "reactjs-popup";
import Loader from "../Common/loader";

const LandingPage = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [isnotuser, setIsnotuser] = useState(false);
  const isInternalUser = useContext(UserCheckContext)
  const userAccess = useContext(UserAccessContext)
  const user = useContext(UserContext)
  if (!isInternalUser || user.userRole === 'Customer') {
    history.push('/biddingportal')
  }
  const closeProduction = () => {
    setShowModal(false);
    window.localStorage.setItem("productionPopup", true);
  };
  useEffect(() => {
    if (user.userRole === undefined) {
      setIsnotuser(true)
    }
  }, [user])
  useEffect(() => {
    if (user) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [user])

  let isProd = () => {
    if (window.location.href.includes('-p-')) {
      return true
    }
    else if (window.location.href.includes('localhost')) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && user.userRole !== undefined && (!showModal || window.location.href.includes('-p-')) && <div className="landing-container">
        <div className="main_header align-baseline">
          <Header isNotProduction={!isProd()} />
        </div>
        {(!showModal || window.location.href.includes('-p-')) &&
          <div className="mainContainer ">
            <div className="tile-container">
              {userAccess?.fgSLOBdata.data.length === undefined ? (
                <div className="tile-nav">
                  <Link
                    to={
                      isInternalUser
                        ? userAccess?.fgSLOBdata?.data.tabViewSettingId[
                          0
                        ] === "1"
                          ? "/risksummary"
                          : userAccess?.fgSLOBdata?.data.tabViewSettingId[
                            0
                          ] === "2"
                            ? "/riskdetails"
                            : userAccess?.fgSLOBdata?.data.tabViewSettingId[
                              0
                            ] === "3"
                              ? "/dealcalendar"
                              : userAccess?.fgSLOBdata?.data.tabViewSettingId[
                                0
                              ] === "4"
                                ? "/dealmanagement"
                                : userAccess?.fgSLOBdata?.data.tabViewSettingId[
                                  0
                                ] === "5"
                                  ? "/biddingportal-internal"
                                  : "/risksummary"
                        : "/risksummary"
                    }
                  >
                    <div className="tile-icon">FG SLOB</div>
                  </Link>
                </div>
              ) :
                isInternalUser && (user?.userRole === "SuperAdmin") && (
                  <div className="tile-nav">
                    <Link to="/risksummary">
                      <div className="tile-icon">FG SLOB</div>
                    </Link>
                  </div>
                )}
              {isInternalUser &&
                (userAccess?.inflowdata?.data.length === undefined ||
                  user?.userRole === "SuperAdmin") && (
                  <div className="tile-nav">
                    <Link to="/inflowSummary">
                      <div className="tile-icon">INFLOW</div>
                    </Link>
                  </div>
                )}
              {isInternalUser &&
                (userAccess?.pomdata.data.length === undefined ||
                  user?.userRole === "SuperAdmin") && (
                  <div className="tile-nav">
                    <Link
                      to={
                        userAccess?.pomdata?.data.length === undefined ?
                          userAccess?.pomdata?.data?.tabViewSettingId[
                            userAccess?.pomdata?.data?.tabViewSettingId?.length - 1
                          ] === "1"
                            ? "/pom"
                            : userAccess?.pomdata?.data?.tabViewSettingId[
                              userAccess?.pomdata?.data?.tabViewSettingId?.length - 1
                            ] === "2"
                              ? "/pomsummary"
                              : "/pomsummary"
                          : "/pomsummary"
                      }
                    >
                      <div className="tile-icon">POM</div>
                    </Link>
                  </div>
                )}
            </div>
          </div>}
      </div>}
      {!loading && user.name !== undefined && !user.userRole && user.userRole === undefined &&
        <Popup
          className="size-sm fluid-height"
          open={isnotuser}
          closeOnDocumentClick={false}
          modal
        >
          <>
            <div className="popup-header d-flex align-items-center justify-content-between">
              <h5 className="mt-2">Polaris-Business Waste Analytics</h5>
            </div>

            <div className="content">
              <div className="scroll-content">
                <p>Please reach out to your respective In-Market Business Waste SPOC, if you are facing access issues or have query related to Polaris Business Waste.</p>
              </div>
            </div>
          </>
        </Popup>
      }
      {!window.location.href.includes('-p-') && <Popup
        className="size-sm fluid-height"
        open={showModal}
        closeOnDocumentClick={false}
        modal
      >
        <>
          <div className="popup-header d-flex align-items-center justify-content-between">
            <h5 className="mt-2">Polaris-Business Waste Analytics</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                closeProduction()
              }}
            ></button>
          </div>

          <div className="content">
            <div className="scroll-content">
              <p>You're currently in the Development Environment, which is NOT for operational use.</p>
              <a
                className="text-blue"
                href="https://polarisbusinesswaste.unilever.com/"
                target="_blank"
                title="Open document in new tab"
              >
                Click here to go to the Production Environment
              </a>
            </div>
          </div>
        </>
      </Popup>
      }
    </>
  );
};

export default LandingPage;
