import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import ULModal from "../../../Common/Modal";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import SearchIcon from "../../../Assets/img/search.svg";
import DownloadIcon from "../../../Assets/img/download.svg";
import UploadIcon from "../../../Assets/img/upload.svg";
import PersonalCareTableGrid from "./PersonalCareTableGrid";
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import { PerPageRecordNumber, setbiddingStatusClass } from "../../../utility/constants";
import { getUserInfo, createNotification, updateApprovalStatus, updatedBySuperAdminBiddingList } from "../../../Services/CommonServices";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import OpenIcon from "../../../Assets/img/open.svg";
import PinchIcon from "../../../Assets/img/icon-pinch.svg";
import { v4 } from 'uuid';
import {
  UpdateOfferSentFields,
  VendorBiddingList,
  downloadVendorData,
  addToBiidingComments,
  getBiddingComments,
  getApprovedCustomers,
  getCustomerApproval,
  excelFileUpload,
  checkFileStatus,
  setPublishStatus
} from "../../../Services/CentralDealManagementDetailServices";
import { notify } from "../../../Common/toast";
import { COLUMNS, CUSTCOLUMNS, VENDORRECIEVDCOLUMNS } from "./VendorColumns";
import EditIcon from "../../../Assets/img/edit.svg";
import { UserContext } from "../../Application";
import { useAccount, useMsal } from "@azure/msal-react";
import {
  addToBiddingActions,
  createBiddingNotifications,
} from "../../../Services/BiddingPortalService";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../Common/loader";
import StatusPopup from "../../../Common/StatusPopup/statusPopup";
const Offer = ({ show, onHide, biddingObject, ...props }) => {
  const apprCustListRef = useRef(null);
  const fileUploadInput_sent = useRef(null);
  const fileUploadInput_received = useRef(null);
  const fileUploadInput_customer = useRef(null);
  const user = useContext(UserContext);
  const [comment, setComment] = useState("");
  const [isEditList, setIsEditList] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [pageInd, setPageInd] = useState(0);
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [approvedUserList, setApprovedUserList] = useState([]);
  const [approvedUserListOptions, setApprovedUserListOptions] = useState([]);
  const [uniqueId, setUniqueId] = useState("");
  const [state, setState] = useState("Loading");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [email, SetEmail] = useState(biddingObject.customerEmail || "");
  const [SortBy, setSortBy] = useState("material_description");
  const [SortType, setSortType] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [userApiData, setUserApiData] = useState([]);
  const [selection, setSelection] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [bidding_status_class, set_bidding_status_class] = useState('');
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [biddingListDetail, setBiddingListDetail] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [publishLoading, setPublishLoading] = useState(false);
  const [tab, setTab] = useState("offersent");
  const [isCounterOffer, setIsCounterOffer] = useState(false);
  const [isBiddingListApproved, setBiddingListApproved] = useState();
  const [aaa, setAaa] = useState(false);
  const [isSuperiorEditingBiddingList, setSuperiorEditingBiddingList] = useState(false);
  const [isColumnEdited, setIsColumnEdited] = useState(false)
  //------
  const [isShowFilePopup, setIsShowFilePopup] = useState(false);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [isFileStatusDone, setIsFileStatusDone] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isSetSaveLoaderInit__UpdateOfferSentFields, setSaveLoaderInit__UpdateOfferSentFields] = useState(false);
  const [isSetSaveLoaderInit__handleAcceptClick, setSaveLoaderInit__handleAcceptClick] = useState('' || false);
  //------
  const ooop = () => {
    let biddingStatus = biddingListDetail[0]?.BiddingStatus;
    if (biddingStatus != undefined) {
      let biddingStatusM = biddingStatus.toLowerCase();
      let biddingStatusClass = setbiddingStatusClass(biddingStatusM);
      set_bidding_status_class(biddingStatusClass);
    }
  }
  const toggleExpandCollapse = () => {
    setIsExpand(!isExpand);
  };
  
  useEffect(() => {
    if (props.biddingDataObject != undefined && props.detail == 'User wishes') {
      SetEmail(props.biddingDataObject.CustomerEmail);
      var { isApproved } = props.biddingWholeObject;
      setBiddingListApproved(isApproved);
    }
  }, [props]);

  useEffect(() => {
    let payload = {
      biddingGroupId: biddingObject.BiddingGroupID,
      biddingListName:
        biddingObject.biddingListName,
      biddingListID: biddingObject.biddingListID,
    };
    getBiddingComments(payload)
      .then((response) => {
        if(response && response.data[0]){
          setComment(response.data.slice(-1)[0].Comments)
        }else {
          setComment("")
        }
      }).catch((err) => {
        let sccs = <div className="tosterIcon"><div>Something went wrong</div></div>
        toast.error(sccs, {
          containerId: "A",
        });
      });
  }, [props.biddingDataObject])

  console.log(comment, "comment")
  const MINUTE_MS = 5000;
  const fileUpdateChecker = async(type) => {
    var task_status = [];
    var isUploadTaskCompleted = false;
    var isUploadTaskHasError = false;
    let module_name = '';
    if(props.isExternal) module_name = "CUSTOMER-BIDDING";
    if(!props.isExternal) module_name = "VENDOR-BIDDING";
    checkFileStatus({ uniqueTaskId: uniqueId, "task": type, "module": module_name })
      .then((response) => {
        response.taskDetailsAllStatus.map(r => {
          task_status.push(r.status);
        });
        
        // if (response.taskstatus === "completed") {
        //   setIsFileProcessed(false);
        //   setIsShowFilePopup(false)
        //   setIsFileStatusDone(false)
          if (response.taskDetails.task === "upload") {
           
            isUploadTaskCompleted = task_status.some(t => t === "completed");
            isUploadTaskHasError = task_status.some(t => t === "error");
            if(isUploadTaskCompleted){
              setIsFileProcessed(false);
              setIsShowFilePopup(false)
              setIsFileStatusDone(false)
              // setTimeout(() => {
              //   getPendingActionsCount();
              // }, 5000);

              let sccs = <div className="tosterIcon"><div>File Uploaded Successfully!</div></div>
              toast.success(sccs, {
                containerId: "A",
              });

              getGridData(
                {
                  biddingGroupId: biddingObject.biddingGroupId,
                  biddingListName: biddingObject.biddingListName,
                  biddingListID: biddingObject.biddingListID,
                  searchText: searchText ?? "",
                },
                props.isExternal,
                isEditList
              );
              return;
            } else if(isUploadTaskHasError) {
              setIsFileProcessed(false);
              setIsShowFilePopup(false)
              setIsFileStatusDone(false)
              // setTimeout(() => {
              //   getPendingActionsCount();
              // }, 5000);

              let sccs = <div className="tosterIcon"><div>File Upload Error!</div></div>
              toast.error(sccs, {
                containerId: "A",
              });
              return;
            }
           
          }  else {
            setIsShowFilePopup(false)
            setIsFileStatusDone(false)
            setIsFileProcessed(false)
          }
        //}
      })
      .catch(() => {
        setIsFileProcessed(false);
        setIsFileStatusDone(false);
        setIsShowFilePopup(false);
      });
  };
  useEffect(() => {
    if (isFileProcessed || isFileStatusDone) {
      const interval = setInterval(() => {
        if (isFileProcessed) {
          fileUpdateChecker("download");
        } else if (isFileStatusDone) {
          fileUpdateChecker("upload");
        }
      }, MINUTE_MS);

      return () => clearInterval(interval);
    }
  }, [fileUpdateChecker, isFileProcessed, isFileStatusDone]);

  const customStyles = {
    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#212529" : "#fff",
      backgroundColor: state.isSelected ? "#a0a0a0" : "#212529",

    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff !important",
      backgroundColor: "#212529",
      padding: "0px 4px",
      boxShadow: "none",
      borderColor: "#999999",
      width: "300px",
      height: "24px",
      minHeight: "24px",
      class: 'asad'
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles, class: 'asad', color: "#fff", height: "24px",
      minHeight: "24px"
    }),
  };
  const handleFileOpen_sent = () => {
    fileUploadInput_sent.current.value = null;
    fileUploadInput_sent.current.click();
  };
  const handleFileOpen_received = () => {
    fileUploadInput_received.current.value = null;
    fileUploadInput_received.current.click();
  };
  const handleFileOpen_customer = () => {
    fileUploadInput_customer.current.value = null;
    fileUploadInput_customer.current.click();
  };
  const handleDownload = useCallback((offerAction) => {
    if (gridInfo.length > 0) {
        let sccs = <div className="tosterIcon"><div>Downloading the File. Please wait...</div></div>
        toast.info(sccs, {
          containerId: "A",
      });
      downloadData(
        {
          filename: props.isExternal
            ? `customer_bidding_list`
            : `vendor_bidding_list_${offerAction}`,
          biddingGroupId:
          biddingObject.biddingGroupId || biddingObject.BiddingGroupID,
          biddingListID: biddingObject.biddingListID,
          sortby: SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
          responseType: "arraybuffer",
          offerAction: offerAction
        },
        props.isExternal
      )
    } else {
    }
  }, [SortBy, SortType, gridInfo.length, props.isExternal, searchText, tab]);

  const downloadData = (payload, isExternal) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadVendorData(payload, isExternal).then((response) => {
      setIsShowFilePopup(false);

     
    });
  };
  const updateCellData = (row, column, value, isInternal, isExternal, type, isVendorCell) => {
    if (!isInternal) {
      var dataPayload;
      var rowDdata = [];
      gridInfo.forEach((val) => {
        let obj =
          !isExternal || type === "counterOffer"
            ? {
              rowID: val.rowID,
              vendor_bid_quantity: val.vendor_bid_quantity,
              vendor_bid_price: val.vendor_bid_price,
              quantity_to_offer_bid: val.quantity_to_offer_bid,
              minimum_acceptance_price: val.minimum_acceptance_price,
              vendorComments: val.vendorComments,
              BiddingStatus: type === "counterOffer" ? "Planner counter-offered" : "List sent for customer",
              vendorName: user.firstName,
              wasteRiskId: val.wasteRiskId
            }
            : {
              rowID: val.rowID,
              customer_bid_quantity: val.customer_bid_quantity,
              customer_bid_price: val.customer_bid_price,
              customerComments: val.customerComments,
              BiddingStatus: "Customer counter-offered",
              customerName: user.firstName,
              wasteRiskId: val.wasteRiskId
            };

        if (isEditList ? selectionList.includes(obj.rowID) : true)
          rowDdata.push({ ...obj });
      });

      dataPayload = !isExternal
        ? {
          data: rowDdata,
          BiddingStatus:rowDdata[0].BiddingStatus==="List sent for customer"?"":rowDdata[0].BiddingStatus,
          biddingListName: biddingObject.biddingListName,
          biddingListID: biddingObject.biddingListID,
          biddingGroupId: biddingObject.biddingGroupId,
          customerEmail: email !== "" ? email : biddingObject.customerEmail,
          vendorRole: user.userRole,
          vendorOfferSent: biddingListDetail[0]?.VendorOfferSent ? 1 : 0,
          vendorAccepted: biddingListDetail[0]?.VendorAccepted ? 1 : 0,
          vendorRejected: biddingListDetail[0]?.VendorRejected ? 1 : 0,
          vendorCounterOffer: biddingListDetail[0]?.VendorCounterOffer
            ? 1
            : 0,
        }
        : !type
          ? {
            data: rowDdata,
            biddingListName: biddingObject.biddingListName,
            biddingListID: biddingObject.biddingListID,
            biddingGroupId: biddingObject.BiddingGroupId,
            customerEmail: userApiData?.username || biddingObject.customerEmail,
            customerName: userApiData?.name || "",
            customerAccepted: biddingListDetail[0]?.CustomerAccepted ? 1 : 0,
            customerRejected: biddingListDetail[0]?.CustomerRejected ? 1 : 0,
            customerCounterOffer:
              biddingListDetail[0]?.CustomerCounterOffer || isCounterOffer
                ? 1
                : 0,
            vendorOfferSent: biddingListDetail[0]?.VendorOfferSent ? 1 : 0,
            vendorAccepted: biddingListDetail[0]?.VendorAccepted ? 1 : 0,
            vendorRejected: biddingListDetail[0]?.VendorRejected ? 1 : 0,
            vendorCounterOffer: biddingListDetail[0]?.VendorCounterOffer
              ? 1
              : 0,
          }
          : {
            data: rowDdata,
            biddingListName: biddingObject.biddingListName,
            biddingListID: biddingObject.biddingListID,
            biddingGroupId: biddingObject.biddingGroupId,
            biddingStatus: (!isExternal || type === "counterOffer") ? "Planner counter-offered" : isExternal ? "Customer counter-offered" : "List sent for customer",
            customerEmail: biddingObject.customerEmail,
            customerName: userApiData.name || "",
          };
      let selectionList = selection?.map((row) => row.original.rowID);
      setSaveLoaderInit__UpdateOfferSentFields(true);
      UpdateOfferSentFields(dataPayload, isExternal)
        .then((response) => {
          setSaveLoaderInit__UpdateOfferSentFields(false);
          setIsColumnEdited(false)
          if (!isExternal && !isCounterOffer) {
            handleSubmit();
          }
          if (
            response.message === "Sucessfully Updated VendorBiddingList" ||
            response.message === "Sucessfully Updated CustomerBiddingList"
          ) {
            props.setOfferModalOpen?.(false);
            setIsEditList(false);
            setClickedIndex(null);
            setClickedCell(null);
            if (isCounterOffer) {
              handleAcceptClick(!isExternal, `${(isExternal) ? 'Customer' : 'User'}`, "counterOffer");
              setIsCounterOffer(false);
            } else {
              let sccs = <div className="tosterIcon"><div>Bidding list sent successfully</div></div>
              toast.success(sccs, {
                containerId: "A",
              });
            }
          } else {
              let sccs = <div className="tosterIcon"><div>Something Went Wrong!</div></div>
              toast.success(sccs, {
                containerId: "A",
            });
          }
        }, 100)
        .catch((error) => {
          setSaveLoaderInit__UpdateOfferSentFields(false);
          console.log("error: ", error);
          let sccs = <div className="tosterIcon"><div>No Response From Server. Please Try Again Later</div></div>
          toast.error(sccs, {
            containerId: "A",
          });
        });
    } else {
      const rowId = row.original.rowID;
      let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
      index !== -1 && rowInlineEditChanges.splice(index, 1);
      if (!isExternal) {
        setClickedIndex(null);
        setClickedCell(null);
      }
      setGridInfo((info) => {
        info?.forEach((val) => {
          if (val.rowID == rowId) {
            val[column.id] = value;
            if (row.original[column.id] !== value && isVendorCell) {
              setIsColumnEdited(true);
            }
          }
        });
        return info;
      });
    }
  };
  const getApproval = () => {
    let isEveryFieldValid = false;
    gridInfo.forEach((val) => {
      if (!val.quantity_to_offer_bid || !val.vendor_bid_price) {
        isEveryFieldValid = false;
      } else if (
        val.vendor_bid_quantity &&
        val.vendor_bid_price &&
        val.quantity_to_offer_bid
      ) {
        isEveryFieldValid = true;
      }
    });
    if (!isEveryFieldValid) {
      let sccs = <div className="tosterIcon"><div>Please fill Qty to Offer for bid and Bid Start price in each record</div></div>
      toast.warn(sccs, {
        containerId: "A",
      });
      return;
    }
    var BiddingListID = biddingObject.biddingListID;
    var BiddingListName = biddingObject.biddingListName;
    var rowDdata = [];
    gridInfo.forEach((val) => {
      var obj = {
        rowID: val.rowID,
        vendor_bid_quantity: val.vendor_bid_quantity,
        vendor_bid_price: val.vendor_bid_price,
        quantity_to_offer_bid: val.quantity_to_offer_bid,
        minimum_acceptance_price: val.minimum_acceptance_price,
        vendorComments: val.vendorComments,
        //BiddingStatus:type === "counterOffer"?"Planner counter-offered":"List sent for customer",
        vendorName: user.firstName,
        wasteRiskId: val.wasteRiskId
      };
      rowDdata.push({ ...obj });
    });
    let payload = {
      BiddingListID: BiddingListID,
      BiddingGroupID:
        biddingObject.biddingGroupId || biddingObject.BiddingGroupID,
      SupervisorEmail: userApiData.email,
      CustomerEmail: email,
      BiddingStatus: "Awaiting for approval/rejection"
    };
    getCustomerApproval(payload).then(response => {
      if (response.status != 200) {
        let sccs = <div className="tosterIcon"><div>{response.data.message}</div></div>
        toast.success(sccs, {
          containerId: "A",
        });
      } else {
        var obj2 = {
          data: rowDdata,
          biddingListName: biddingObject.biddingListName || biddingObject.BiddingListName,
          biddingListID: BiddingListID,
          biddingGroupId: biddingObject.biddingGroupId || biddingObject.BiddingGroupID,
          customerEmail: email,
        };
        UpdateOfferSentFields(obj2, false)
          .then((response) => {
            setIsColumnEdited(false)
            if (userApiData.userRole != "SuperAdmin") {
              setBiddingListDetail([
                ...[
                  { ...biddingListDetail[0], ApprovalStatus: "pending" },
                ],
              ]);
              setTimeout(() => {
                ooop();
              }, 400);

              createBiddingNotifications({
                biddingListID: BiddingListID,
                biddingListName: BiddingListName,
                fromName: `${user.firstName} ${user.lastName}`,
                toName: user.supervisorEmail,
                notification: `'${user.firstName} ${user.lastName} (${user.email})' wishes to send '${BiddingListName}' (bidding list) to '${email}'`,
                status: 0,
                action: "submit",
                createdBy: user.email,
                approvedBy: "",
                detail: "User wishes"
              })
                .then((success2) => {
                  let sccs = (
                    <div className="tosterIcon">
                      <div>Bidding List creation request submitted</div>
                    </div>
                  );
                  toast.success(sccs, {
                    containerId: "A",
                  });
                })
                .catch((err) => {
                  let sccs = (
                    <div className="tosterIcon">
                      <div>Error in bidding List creation request</div>
                    </div>
                  );
                  toast.success(sccs, {
                    containerId: "A",
                  });
                });
            } else {
               let sccs = (
                <div className="tosterIcon">
                  <div>{response.message}</div>
                </div>
              );
              toast.success(sccs, {
                containerId: "A",
              });
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            let sccs = (
              <div className="tosterIcon">
                <div>
                  <p>No Response From Server. Please Try Again Later</p>
                </div>
              </div>
            );
            toast.error(sccs, {
              containerId: "A",
            });
          });
      }
    }).catch((err) => {
      let errMssgDefault = `Error occured, while bidding list creation`;
      let errMssg = err.message ?? errMssgDefault
      let sccs = <div className="tosterIcon"><div>{errMssg}</div></div>
      toast.error(sccs, {
        containerId: "A",
      });
    })
  }
  const handleEditBiddingList = () => {
    setIsEditList(true);
    props.setIsEditBiddingList(true);
    props.setOfferModalOpen(false);
    props.setEditedBiddingListData(biddingObject);
  };
  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
  };
  const handleClickEditCell = (cell) => {
    if (cell) {
      setClickedIndex(cell.row.index);
      setClickedCell(cell.column.id);
    } else {
      setClickedIndex(1);
      // setClickedCell(4);
    }
  };

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    isSuperiorEditingBiddingList,
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };

  const handleSearch = (val) => {
    setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };
  const fetchIdRef = React.useRef(0);
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  const getGridData = useCallback((payload, isExternal, isEditList) => {
     let externalPayload = {
      biddingGroupId: biddingObject.BiddingGroupId,
      biddingListName: biddingObject.biddingListName,
      biddingListID: biddingObject.biddingListID,
      customerEmail: props.isExternal
        ? userApiData.username
        : biddingObject.CustomerEmail,
      sortby: SortBy,
      SortType: SortType,
      searchText: payload.searchText ?? "",
    };
    setState("Loading");
    VendorBiddingList(
      !isExternal ? payload : externalPayload,
      isExternal,
      isEditList
    )
      .then((response) => {
        setBiddingListDetail(response.biddingListDetail ?? []);
        setIsColumnEdited(false)
        SetEmail(
          email !== "" ? email : response.biddingListDetail[0]?.CustomerEmail
        );
        setGridInfo(response.data);
        setTotalRecordCount(response.count);
        setTimeout(() => {
          setState("Completed");
          setTimeout(() => {
            ooop();
          }, 400);
        }, 200);
      })
      .catch(() => {
        setState("Completed");
        setIsColumnEdited(false)
      });
  }, []);

  const selectApprCustomer = (selectedEmail) => {
    let custEmail = selectedEmail.value;
    SetEmail(custEmail);
  }

  const checkEmailSet = () => {
    console.log("customer selected is > ", email);
  }

  useEffect(() => {
    setIsColumnEdited(false)
     getGridData(
      {
        biddingGroupId: biddingObject.biddingGroupId,
        biddingListName: biddingObject.biddingListName,
        biddingListID: biddingObject.biddingListID,
      },
      props.isExternal,
      isEditList
    );
  }, [
    SortBy,
    SortType,
    biddingObject.biddingListID,
    biddingObject.biddingListName,
    biddingObject.biddingGroupId,
    show,
    getGridData,
    itemPerPage,
    page,
  ]);

  const getListOfApprovedCustomers = () => {
    getApprovedCustomers()
      .then((res) => {
        setApprovedUserList([...(res ?? [])]);
        var apprOpts = []
      })
      .catch((res) => { });
  };

  useEffect(() => {
    getListOfApprovedCustomers()
  }, [])
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const { name, username } = account || {};

  const handleSubmit = () => {
    const { biddingListID, biddingListName, CustomerEmail } = biddingObject;

    createBiddingNotifications({
      biddingListID,
      biddingListName,
      fromName: name,
      fromEmail: username,
      toEmail: CustomerEmail ? CustomerEmail : email,
      toName: CustomerEmail ? CustomerEmail : email,
      notification: `${username} has sent "${biddingListName}" bidding list`,
      action: "submit", //submit, accepted, rejected, counteroffered
      status: 0, //read/unread
      createdBy: name,
      detail: "User sent"
    }).then((success) => {
      onHide();
    });
  };
  const handleAcceptClick = (isVendor, userType, actionName) => {
    const { biddingListID, biddingListName, CustomerEmail, VendorEmail } =
      biddingObject;
    const { VendorCounterOffer, BiddingGroupID, CustomerCounterOffer } =
      biddingListDetail[0];
    let actionPayload = isVendor
      ? {
        biddingGroupId: BiddingGroupID,
        biddingListName: biddingListName,
        biddingListID: biddingListID,
        accepted: !actionName ? 1 : 0,
        rejected: actionName == "reject" ? 1 : 0,
        offerSent: 1,
        counterOffer: actionName == "counterOffer" ? 1 : VendorCounterOffer,
      }
      : {
        biddingGroupId: BiddingGroupID,
        biddingListName: biddingListName,
        biddingListID: biddingListID,
        accepted: !actionName ? 1 : 0,
        rejected: actionName == "reject" ? 1 : 0,
        offerSent: 1,
        counterOffer: actionName == "counterOffer" ? 1 : CustomerCounterOffer,
      };

    // if(actionName == "reject" || !actionName) {
    //   setSaveLoaderInit__handleAcceptClick('reject');
    //   addToBiddingActions({
    //     ...actionPayload,
    //     BiddingStatus: isVendor
    //       ? actionName == "reject"
    //         ? "Planner rejected"
    //         : "Planner accepted"
    //       : actionName == "reject"
    //         ? "Customer rejected"
    //         : "Customer accepted",
    //   }, isVendor)
    // } else {
    //   setSaveLoaderInit__handleAcceptClick('reject');
    //   addToBiddingActions({ ...actionPayload, BiddingStatus: isVendor ? "Planner counter-offered" : "Customer counter-offered" }, isVendor);
    // }

    let actionName_string = actionName.toString();

    setSaveLoaderInit__handleAcceptClick(actionName_string);

    addToBiddingActions(
      actionName == "reject" || !actionName
        ? {
          ...actionPayload,
          BiddingStatus: isVendor
            ? actionName == "reject"
              ? "Planner rejected"
              : "Planner accepted"
            : actionName == "reject"
              ? "Customer rejected"
              : "Customer accepted",
        }
        : { ...actionPayload, BiddingStatus: isVendor ? "Planner counter-offered" : "Customer counter-offered" },
      isVendor
    )
      .then(() => {
        var actionNameText = actionName === "reject" ? "rejected" : "accepted";
        setSaveLoaderInit__handleAcceptClick(`${actionName_string}_loaded`);
        if (!actionName) {
          props.setOfferModalOpen(false);
          createBiddingNotifications({
            biddingListID,
            biddingListName,
            fromName: name,
            fromEmail: username,
            toEmail: isVendor
            ? CustomerEmail || biddingListDetail[0]?.CustomerEmail
            : VendorEmail,
            toName: isVendor
              ? CustomerEmail || biddingListDetail[0]?.CustomerEmail
              : VendorEmail,
            notification: `${username} ${actionNameText} "${biddingListName}" bidding list`,
            action: "accepted", //submit, accepted, rejected, counteroffered
            status: 0, //read/unread
            createdBy: name,
            detail: `${userType} ${actionNameText}`
          }).then((success) => {
            onHide();
          });
        }
        if (actionName === "reject") {
          props.setOfferModalOpen(false);
          createBiddingNotifications({
            biddingListID,
            biddingListName,
            fromName: name,
            fromEmail: username,
            toEmail: CustomerEmail || biddingListDetail[0]?.CustomerEmail,
            toName: CustomerEmail || biddingListDetail[0]?.CustomerEmail,
            notification: `${username} rejected "${biddingListName}" bidding list`,
            action: "rejected", //submit, accepted, rejected, counteroffered
            status: 0, //read/unread
            createdBy: name,
            detail: `${userType} ${actionNameText}`
          }).then((success) => {
            onHide();
          });
        }
        if (actionName === "counterOffer") {
          createBiddingNotifications({
            biddingListID,
            biddingListName,
            fromName: name,
            toName: isVendor
              ? CustomerEmail || biddingListDetail[0]?.CustomerEmail
              : VendorEmail,
            toEmail: isVendor
            ? CustomerEmail || biddingListDetail[0]?.CustomerEmail
            : VendorEmail,
            fromEmail: username,
            notification: `${username} updated "${biddingListName}" bidding list`,
            action: "counteroffered", //submit, accepted, rejected, counteroffered
            status: 0, //read/unread
            createdBy: name,
            detail: `${userType} counter-offered`
          }).then((success) => {
            onHide();
          });
        }

        
        
        
        let action_name =  actionName === "reject"
              ? "Bidding list rejected successfully"
              : actionName === "counterOffer"
                ? "Bidding list updated successfully"
          : "Bidding list accepted successfully";

        let sccs = <div className="tosterIcon"><div>{action_name}</div></div>
        toast.success(sccs, {
          containerId: "A",
        });

      })
      .catch((err) => {
        console.log("accepted-error", err);

        let sccs = <div className="tosterIcon"><div>Something went wrong</div></div>
        toast.error(sccs, {
          containerId: "A",
        });

      });
  };

  useEffect(()=>{
      console.log("isSetSaveLoaderInit__handleAcceptClick",isSetSaveLoaderInit__handleAcceptClick)
  },[isSetSaveLoaderInit__handleAcceptClick])

  const get_user_data = () => {
    getUserInfo(user.email).then((resp) => {
      setUserApiData(resp.data[0]);
    })
  }

  const apprCmrsF = () => {
    var apprCmrs = [];
    approvedUserList.map(w => {
      apprCmrs.push({ value: w.email, label: w.email })
    });
    return apprCmrs;
  }

  const processOfferSentInputsEditable = () => {
    if (biddingListDetail[0]?.CustomerCounterOffer || (biddingListDetail[0]?.BiddingStatus.includes('Planner') || biddingListDetail[0]?.BiddingStatus.includes('Customer accepted'))) {
      return true;
    } else {
      return false;
    }
  }

  const processOfferReceivedInputsEditable = () => {
    if (biddingListDetail[0]?.BiddingStatus.includes('Planner')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      ooop();
    }, 400);
  }, [biddingListDetail[0]]);

  useEffect(() => {
    if (user.email)
      setUserApiData(user);
  }, [user])

  const buildHeader = () => {
    return (
      <>
        <div className="pull-left flex align-items-center">{biddingObject.biddingListName}
           {biddingListDetail[0]?.BiddingStatus && !biddingListDetail[0]?.BiddingStatus?.toLowerCase()?.includes('yet to sent') &&(
            <div className={`btn ml-3 btn-outline-secondary btn-slim ml-2 no-hover cursor-default status-outline-offer ${bidding_status_class}`}
            >{biddingListDetail[0]?.BiddingStatus}</div>
          )
          }
        </div>
      </>
    )
  }

  useEffect(() => {
    if(biddingListDetail[0]?.CustomerCounterOffer) {
      setTab("offerreceived");
      toast.info("Switched to 'Offer Received'", {
        containerId: "A",
      });
    } else {
      setTab("offersent");
    }
  }, [biddingListDetail[0]?.CustomerCounterOffer]);

  const updatedBySuperAdmin_biddingList = (biddingWholeObject, biddingDataObject, approvalStatus, isUpdate) => {

    var rowDdata = [];
    gridInfo.forEach((val) => {
      var obj = {
        rowID: val.rowID,
        vendor_bid_quantity: val.vendor_bid_quantity,
        vendor_bid_price: val.vendor_bid_price,
        quantity_to_offer_bid: val.quantity_to_offer_bid,
        minimum_acceptance_price: val.minimum_acceptance_price,
        vendorComments: val.vendorComments,
        vendorName: user.firstName,
        wasteRiskId: val.wasteRiskId,
      };
      rowDdata.push({ ...obj });
    });

    var { BiddingStatus, BiddingListID, VendorOfferSent, VendorAccepted, VendorRejected, VendorCounterOffer } = biddingDataObject;

    let approvalStatusText = (approvalStatus == 1) ? "accepted" : approvalStatus == null ? "pending" : "rejected";

    let payload = {
      data: rowDdata,
      BiddingStatus: BiddingStatus,
      biddingListID: BiddingListID,
      vendorOfferSent: VendorOfferSent,
      vendorAccepted: VendorAccepted,
      vendorRejected: approvalStatus === 0 ? true : VendorRejected,
      vendorCounterOffer: VendorCounterOffer,
      isSuperAdminEdited: isUpdate ? 1 : 0,
      ApprovalStatus: approvalStatus == 1 ? "accepted" : approvalStatus == null ? "pending" : "rejected"
    }

    updatedBySuperAdminBiddingList(payload)
      .then((resp) => {
        updateApproval_notification(biddingWholeObject, biddingDataObject, approvalStatus, isUpdate);
      }).catch((error) => {
         let actionError = `Some error occured`;
        let sccs = (
          <div className="tosterIcon">
            <div>{actionError}</div>
          </div>
        );
        toast.success(sccs, {
          containerId: "A",
        });
      });

  }

  const isEditListShowing = () => {
    return props.detail != 'User wishes' &&
                          !isEditList && !props.isNoEditList &&
                          !biddingListDetail[0]?.CustomerAccepted &&
                          !biddingListDetail[0]?.VendorAccepted &&
                          !biddingListDetail[0]?.VendorRejected &&
                          biddingListDetail[0]?.ApprovalStatus?.toLowerCase() !== "rejected";
  }

  const clearSearchClose = () => {
    // setTableLoader(true);
    getGridData({
      biddingGroupId:
      biddingObject.biddingGroupId,
      biddingListName:
      biddingObject.biddingListName,
      biddingListID: biddingObject.biddingListID,
      searchText: "",
    });
  };

  const updateApproval_notification = (biddingWholeObject, biddingDataObject, approvalStatus, isUpdate) => {

    var { biddingListID,
      biddingListName,
      notificationID,
      createdBy } = biddingWholeObject;

    var { UpdatedBy } = biddingDataObject;

    let approvalStatusText = (approvalStatus == 1) ? "accepted" : approvalStatus == null ? "pending" : "rejected";


    let payload = {
      notificationID: notificationID,
      notificationType: "bidding",
      updatedBy: UpdatedBy,
      isApproved: approvalStatus,
      biddingListID: biddingListID,
      objectId: null
    };
    updateApprovalStatus(payload)
      .then((resp) => {
        createBiddingNotifications({
          biddingListID: biddingListID,
          biddingListName: biddingListName,
          fromName: `${user.firstName} ${user.lastName}`,
          toName: createdBy,
          notification: `"${biddingListName}" bidding list has been ${(isUpdate) ? 'updated & approved' : approvalStatusText} by ${user.firstName} ${user.lastName} (${user.email})`,
          status: 0,
          action: `${approvalStatusText}`,
          createdBy: user.email,
          detail: `Superior ${(isUpdate) ? 'updated' : approvalStatusText}`
        });
        let actionTaken = `You have ${approvalStatusText} the Bidding list`;
        let sccs = (
          <div className="tosterIcon">
            <div>{actionTaken}</div>
          </div>
        );
        toast.success(sccs, {
          containerId: "A",
        });
      })
      .catch((error) => {
        let actionError = `Some error occured`;
        let sccs = (
          <div className="tosterIcon">
            <div>{actionError}</div>
          </div>
        );
        toast.success(sccs, {
          containerId: "A",
        });
      });
  };

  const afterOpenModal = () => {
    console.log("afterOpenModal", "afterOpenModal");
  }

  const fff = () => {
    var ff;
    if (((biddingListDetail[0]?.CustomerEmail || !email)) || ((biddingListDetail[0]?.ApprovalStatus?.toLowerCase() === "rejected" || biddingListDetail[0]?.ApprovalStatus?.toLowerCase() === "approved" || (biddingListDetail[0]?.ApprovalStatus?.toLowerCase() === "pending" && biddingListDetail[0]?.isSuperAdminEdited == null)) && biddingListDetail[0]?.ApprovalStatus)) {
      ff = true;
    } else {
      ff = false;
    }
    setAaa(ff);
  }

  useEffect(() => {
    setSuperiorEditingBiddingList(false);
  }, [!show]);

  const uploadFileHandler = (event, offerAction) => {
    const files = event.target.files[0];
    
    if (files) {
      let formData = new FormData();
      const fileType = files.name.indexOf(".xls") > 0;
      const fileSize = files.size / 1024 / 1024;
      const allowedFileSize = 10;

      if (fileType && fileSize < allowedFileSize) {
        formData.append("file", files);
        formData.append("biddingListID", biddingObject.biddingListID);
        if(!props.isExternal) formData.append("offerAction",offerAction);
        setIsFileStatusDone(true)
          setIsShowFilePopup(true)
          setIsFileProcessed(false)
          excelFileUpload(formData, props.isExternal)
          .then((response) => {
            if(response?.status === 400) {

              let sccs = <div className="tosterIcon"><div>{response?.data?.message}</div></div>
              toast.error(sccs, {
                containerId: "A",
              });
              
              setIsShowFilePopup(false)
              setIsFileProcessed(false)
              setIsFileStatusDone(false)
            } else {
              if (response?.data?.message == "SUCCESSFULLY_UPLOADED") {
                setIsFileStatusDone(true)
                setUniqueId(response.data.uniqueTaskId);
              }
            }
          })
          .catch((error) => {
            setIsShowFilePopup(false)
            setIsFileProcessed(false)
            setIsFileStatusDone(false)

            let sccs = <div className="tosterIcon"><div>{error?.message || error}</div></div>
            toast.error(sccs, {
              containerId: "A",
            });

          });
      } else if (fileSize > allowedFileSize) {

        let sccs = <div className="tosterIcon"><div>{`File size exceeds ${allowedFileSize} MB`}</div></div>
        toast.error(sccs, {
          containerId: "A",
        });

        
        files.value = null;
      } else {
        let sccs = <div className="tosterIcon"><div>File format should be Excel only</div></div>
        toast.error(sccs, {
          containerId: "A",
        });
        files.value = null;
      }
    }
  };

  return (
    <>
      
      <StatusPopup
        onRefreshClick={() => {
          fileUpdateChecker(isFileProcessed ? "download" : "upload");
        }}
        show={isShowFilePopup}
        onHide={(value) => {
          setIsShowFilePopup(value);
        }}
      ></StatusPopup>
      <ULModal
        show={show}
        onHide={onHide}
        size="lg"
        isheader={true}
        heading={buildHeader()}
        className={`page-modal offerPopup isCustomer-${props.isExternal} displayed-as-notification-${(props.detail == 'User wishes') ? 'true' : 'false'}`}>
        <div className="d-block nav-tabs-wrap ">
        <input
            type="file"
            name="file"
            id="input_uploadFileHandler"
            ref={fileUploadInput_sent}
            onChange={(e) => uploadFileHandler(e,'sent')}
            className="btn-download d-none"
        />
        <input
            type="file"
            name="file"
            id="input_uploadFileHandler"
            ref={fileUploadInput_received}
            onChange={(e) => uploadFileHandler(e,'received')}
            className="btn-download d-none"
        />
        <input
            type="file"
            name="file"
            id="input_uploadFileHandler"
            ref={fileUploadInput_customer}
            onChange={(e) => uploadFileHandler(e,'customer')}
            className="btn-download d-none"
        />
        <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />
          <Tabs
            id="offer-tabs"
            activeKey={tab}
            onSelect={(k) => {
              if (k === "offerreceived") {
                getGridData(
                  {
                    biddingGroupId: biddingObject.biddingGroupId,
                    biddingListName: biddingObject.biddingListName,
                    biddingListID: biddingObject.biddingListID,
                    searchText: searchText ?? "",
                  },
                  true,
                  false
                );
              }
              return setTab(k);
            }}
            className="mb-4"
          >
            {!props.isExternal && (
              <Tab eventKey="offersent" title="Offer Sent" >
                {tab === "offersent" && (
                  <>
                    <div className={`isEditListShowing-${isEditListShowing()}`}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ position: "relative", zIndex: 22222 }}
                        >
                          {props.detail != 'User wishes' && (
                            <div className="d-flex align-items-center">
                              <select
                                options={apprCmrsF()}
                                className="btn-slim"
                                title={email}
                                style={{ width: "160px" }}
                                value={
                                  biddingObject.CustomerEmail == "" ||
                                    !biddingObject.CustomerEmail
                                    ? email
                                    : biddingObject.CustomerEmail
                                }
                                disabled={biddingObject.CustomerEmail}
                                onChange={(event) => {
                                  SetEmail(event.target.value);
                                }}
                              >
                                <option value="">Select</option>
                                {approvedUserList.map((opt) => (
                                  <option value={opt.email}>{opt.email}</option>
                                ))}
                              </select>
                              <div
                                className="d-flex flex-column align-items-stretch ml-3"
                                style={{ gap: "5px" }}>
                                {user.userRole !== "SuperAdmin" && (
                                  <ButtonGroup
                                    aria-label="Basic example"
                                    className="btn-status-wrap"
                                  >
                                    {biddingListDetail[0]?.ApprovalStatus && (
                                      <>
                                        <div className={`btn btn-outline-${(biddingListDetail[0]?.ApprovalStatus == 'approved' ? 'secondary' : biddingListDetail[0]?.ApprovalStatus == 'rejected' ? 'primary' : 'grey')}   btn-slim ml-2 no-hover cursor-default btn-status`} >
                                           {biddingListDetail[0]?.ApprovalStatus}
                                        </div>
                                      </>
                                    )}
                                  </ButtonGroup>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="d-flex align-items-center search-bar-elements">
                            <span className="search-wrap">
                              <img
                                src={SearchIcon}
                                className="filter-search"
                                alt=""
                              />
                              <input
                                type="text"
                                placeholder="SKU / Description"
                                className="btn-slim search-input bg-black input-long"
                                onChange={(e) => {
                                  setSearchText(e.target.value ?? "");
                                }}
                                value={searchText}
                              />
                              {searchText != "" && (
                                <a
                                  className="filter-clear"
                                  style={{ right: "54px"}}
                                  onMouseDown={() => {
                                    setSearchText("");
                                    clearSearchClose()
                                  }}
                                >
                                  <img src={ClearIcon} alt="" />
                                </a>
                              )}
                              <input
                                type="button"
                                value="Search"
                                className="btn-slim search-btn"
                                onClick={(e) => {
                                  getGridData(
                                    {
                                      biddingGroupId:
                                        biddingObject.biddingGroupId,
                                      biddingListName:
                                        biddingObject.biddingListName,
                                      biddingListID:
                                        biddingObject.biddingListID,
                                      searchText: searchText ?? "",
                                    },
                                    props.isExternal,
                                    isEditList
                                  );
                                }}
                              />
                            </span>
                            <button
                              type="button"
                              className="btn-slim btn-download ml-3"
                              onClick={(d) => handleDownload('sent')}
                            >
                              <img src={DownloadIcon} alt="" />
                              Download
                            </button>
                            
                              {!biddingListDetail[0]?.CustomerCounterOffer &&
                                <button
                                  type="file"
                                  onClick={handleFileOpen_sent}
                                  className="btn-slim btn-download ml-2"
                                >
                                  <img src={UploadIcon} alt="" /> Upload
                                </button>
                              }
                          </div>
                        </div>
                      </div>
                      <div>
                        {gridInfo[0] === "Loading" && (
                          <>
                            <div className={`tableLoader_overlay`}>
                              <div className="tableLoader_box">
                                <div className="tableLoader_box-in">
                                  <Loader />
                                </div>
                              </div>
                            </div>
                            <div style={{ height: "160px" }}></div>
                          </>
                        )}
                      </div>
                      <div>
                        {gridInfo[0] !== "Loading" && (
                          <div className="position-relative">
                            <PersonalCareTableGrid
                              state={state}
                              pageInd={pageInd}
                              setPageInd={setPageInd}
                              columns={COLUMNS}
                              data={gridInfo}
                              SortBy={SortBy}
                              SortType={SortType}
                              setSortBy={setSortBy}
                              setSortType={setSortType}
                              fetchData={fetchData}
                              pageCount={pageCount}
                              totalRecordCount={totalRecordCount}
                              pageSizeNo={itemPerPage}
                              handleItemPerPage={handleItemPerPage}
                              handlePaginationReset={handlePaginationReset}
                              handleSearch={handleSearch}
                              handleEnterSearch={handleEnterSearch}
                              handleSearchReset={handleSearchReset}
                              searchValue={searchValue}
                              setSearchValue={setSearchValue}
                              setSelection={setSelection}
                              selection={selection}
                              publishLoading={publishLoading}
                              actions={actions}
                              searchText={searchText}
                              setSearchText={setSearchText}
                              getGridData={getGridData}
                              {...editCellProps}
                              setIsSelectAll={setIsSelectAll}
                              totalCount={totalCount}
                              isOfferSentInputDisabled={processOfferSentInputsEditable()}
                              isOfferToSend={true}
                              isSuperAdmin={user.userRole === "SuperAdmin"}
                              isBiddingApproved={
                                biddingListDetail[0]?.ApprovalStatus?.toLowerCase() ===
                                "approved"
                              }
                              offerType={'sent'}
                              biddingApprovalStatus={biddingListDetail[0]?.ApprovalStatus}
                              biddingStatus={biddingListDetail[0]?.BiddingStatus}
                              isSuperiorEditingBiddingList={isSuperiorEditingBiddingList || (props.detail == 'User wishes' ? false : true)}
                              isSuperAdminEdited={biddingListDetail[0]?.isSuperAdminEdited}
                              isExpand={isExpand}
                            />
                          </div>
                        )}
                        {props.detail == 'User wishes' &&
                          <div>
                            {isBiddingListApproved == null &&
                              <div className="flex align-items-center justify-content-end">
                                {isSuperiorEditingBiddingList &&
                                  <>
                                    <button
                                      type="button"
                                      className="btn-secondary btn-std-width btn-big"
                                      onClick={() => {
                                        updatedBySuperAdmin_biddingList(props.biddingWholeObject, props.biddingDataObject, 1, true);
                                        props.onBiddingPopupControl(false);
                                      }
                                      }
                                    >
                                      Update
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-grey btn-std-width ml-2 btn-big"
                                      onClick={() => {
                                        setSuperiorEditingBiddingList(false);
                                      }
                                      }
                                    >
                                      Cancel
                                    </button>
                                  </>
                                }
                                {!isSuperiorEditingBiddingList &&
                                  <>
                                    <button
                                      type="button"
                                      className="btn-secondary btn-std-width btn-big"
                                      onClick={() => {
                                        setSuperiorEditingBiddingList(true);
                                      }
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-secondary btn-std-width btn-big ml-2"
                                      onClick={() => {
                                        handleClickEditCell();
                                        updatedBySuperAdmin_biddingList(props.biddingWholeObject, props.biddingDataObject, 1);
                                        props.onBiddingPopupControl(false);
                                      }
                                      }
                                    >
                                      Confirm
                                    </button>
                                    <button
                                      type="button"
                                      className="btn-primary btn-std-width ml-2 btn-big"
                                      onClick={() => {
                                        updatedBySuperAdmin_biddingList(props.biddingWholeObject, props.biddingDataObject, 0);
                                        props.onBiddingPopupControl(false);
                                      }
                                      }
                                    >
                                      Reject
                                    </button>
                                  </>
                                }
                              </div>
                            }
                          </div>
                        }
                        {props.detail != 'User wishes' &&
                          !isEditList && !props.isNoEditList &&
                          !biddingListDetail[0]?.CustomerAccepted &&
                          !biddingListDetail[0]?.VendorAccepted &&
                          !biddingListDetail[0]?.VendorRejected &&
                          biddingListDetail[0]?.ApprovalStatus?.toLowerCase() !== "rejected" && (
                            <div className="flex align-items-center justify-content-between mt-2 edit-list-btn-div">
                              <div className="">
                                {!isEditList && (
                                  <button
                                    type="button"
                                    className="btn-slim btn-secondary"
                                    onClick={handleEditBiddingList}
                                    disabled={biddingObject.CustomerEmail}
                                  >
                                    <img src={EditIcon} alt="EditIcon" />
                                    &nbsp;&nbsp;Edit List
                                  </button>
                                )}
                              </div>
                            </div>
                          )}

                        {props.detail != 'User wishes' && isEditList && (
                          <button
                            type="button"
                            className="btn-slim btn-secondary"
                            onClick={() => {
                              setIsEditList(false);
                            }}
                          >
                            Go Back
                          </button>
                        )}
                        {props.detail != 'User wishes' && (
                          <div className="flex align-items-center justify-content-end mt-2 gap-4">
                            {!biddingListDetail[0]?.CustomerCounterOffer &&
                              !isCounterOffer && biddingListDetail[0]?.CustomerAccepted && biddingListDetail[0]?.BiddingStatus != "Planner accepted" && (
                                <button
                                  type="button"
                                  className={`btn btn-secondary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'false' ? 'processing' : ''}
                                  `}
                                  disabled={
                                    biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick === 'false'
                                  }
                                  onClick={() => handleAcceptClick(true, "User","accept")}
                                >Accept</button>
                              )}
                            
                            <button
                              disabled={(isColumnEdited && biddingListDetail[0]?.ApprovalStatus?.toLowerCase() ===
                                "approved") ||
                                (!email && !biddingObject.CustomerEmail) ||
                                (isEditList ? !selection.length : false) ||
                                biddingListDetail[0]?.CustomerAccepted ||
                                biddingListDetail[0]?.VendorAccepted ||
                                biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__UpdateOfferSentFields
                              }
                              onClick={() => {
                                var regex =
                                  /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i;
                                if (
                                  regex.test(email) ||
                                  regex.test(biddingObject.CustomerEmail ?? "")
                                ) {
                                  updateCellData();
                                } else {
                                  let sccs = <div className="tosterIcon"><div>Please enter valid email</div></div>
                                  toast.error(sccs, {
                                    containerId: "A",
                                  });
                                }
                              }}
                              type="button"
                              className={`btn btn-primary btn-std-width btn-big ${isSetSaveLoaderInit__UpdateOfferSentFields ? 'processing' : ''}
                              `}
                            >
                              Submit
                            </button>
                            { biddingListDetail[0]?.CustomerAccepted && (<button
                                type="button"
                                className={`btn btn-primary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'reject' ? 'processing' : ''}`}
                                disabled={
                                  biddingListDetail[0]?.VendorAccepted ||
                                  biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick  === 'reject'
                                }
                                onClick={() => {
                                  handleAcceptClick(true, "User", "reject");
                                }}
                              >
                                Reject
                              </button>)
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Tab>
            )}
            {!props.isExternal && (
              <Tab eventKey="offerreceived" title="Offer Received"
                disabled={
                  !biddingListDetail[0]?.CustomerEmail ||
                  !biddingListDetail[0]?.CustomerCounterOffer
                }
              >
                {tab === "offerreceived" && (
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative", zIndex: 22222 }}
                      >
                        {props.detail != 'User wishes' && (
                          <div className="d-flex align-items-center">
                            <select
                              options={apprCmrsF()}
                              className="btn-slim"
                              title={email}
                              style={{ width: "160px" }}
                              value={
                                biddingObject.CustomerEmail == "" ||
                                  !biddingObject.CustomerEmail
                                  ? email
                                  : biddingObject.CustomerEmail
                              }
                              disabled={biddingObject.CustomerEmail}
                              onChange={(event) => {
                                SetEmail(event.target.value);
                              }}
                            >
                              <option value="">Select</option>
                              {approvedUserList.map((opt) => (
                                <option value={opt.email}>{opt.email}</option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="d-flex align-items-center">
                          <span className="search-wrap">
                            <img src={SearchIcon} className="filter-search" alt="" />
                            <input
                              type="text"
                              placeholder="SKU / Description"
                              className="btn-slim search-input bg-black input-long"
                              onChange={(e) => {
                                setSearchText(e.target.value ?? "");
                              }}
                              value={searchText}
                            />
                            {searchText != "" && (
                              <a
                                className="filter-clear"
                                style={{ right: "54px"}}
                                onMouseDown={() => {
                                  setSearchText("");
                                  clearSearchClose()
                                }}
                              >
                                <img src={ClearIcon} alt="" />
                              </a>
                            )}
                            <input
                              type="button"
                              value="Search"
                              className="btn-slim search-btn"
                              onClick={(e) => {
                                getGridData(
                                  {
                                    biddingGroupId:
                                      biddingObject.biddingGroupId,
                                    biddingListName:
                                      biddingObject.biddingListName,
                                    biddingListID: biddingObject.biddingListID,
                                    searchText: searchText ?? "",
                                  },
                                  props.isExternal,
                                  isEditList
                                );
                              }}
                            />
                          </span>
                          <button
                            type="button"
                            className="btn-slim btn-download ml-3"
                            onClick={(w) => handleDownload('received')}
                          >
                            <img src={DownloadIcon} alt="" /> Download
                          </button>
                          <button
                            type="file"
                            onClick={handleFileOpen_received}
                            className="btn-slim btn-download ml-2"
                            disabled={!isCounterOffer}
                          >
                            <img src={UploadIcon} alt="" /> Upload
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      {gridInfo[0] !== "Loading" && (
                        <div className="">
                          <PersonalCareTableGrid
                            state={state}
                            pageInd={pageInd}
                            setPageInd={setPageInd}
                            columns={VENDORRECIEVDCOLUMNS}
                            data={gridInfo}
                            SortBy={SortBy}
                            SortType={SortType}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                            fetchData={fetchData}
                            pageCount={pageCount}
                            totalRecordCount={totalRecordCount}
                            pageSizeNo={itemPerPage}
                            handleItemPerPage={handleItemPerPage}
                            handlePaginationReset={handlePaginationReset}
                            handleSearch={handleSearch}
                            handleEnterSearch={handleEnterSearch}
                            handleSearchReset={handleSearchReset}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            setSelection={setSelection}
                            selection={selection}
                            publishLoading={publishLoading}
                            actions={actions}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            getGridData={getGridData}
                            {...editCellProps}
                            setIsSelectAll={setIsSelectAll}
                            totalCount={totalCount}
                            counterOffer={
                              biddingListDetail[0]?.CustomerCounterOffer
                            }
                            isOfferReceivedInputDisabled={processOfferReceivedInputsEditable()}
                            offerType={'received'}
                            biddingStatus={biddingListDetail[0]?.BiddingStatus}
                            // isOfferSentInputDisabled={processOfferSentInputsEditable()}
                            isExpand={isExpand}
                          />
                        </div>
                      )}
                      <div className="flex align-items-end justify-content-end mt-2">
                        <div className="d-flex add-comment-div">
                          <div className="col-md-12 mr-10">
                            <div className="d-flex align-items-center justify-content-between mb-1">
                              <h5 className="text-sm">
                                <i>Add Comment</i>
                              </h5>
                              <div>
                                <button
                                  onClick={() => {
                                    setComment("");
                                  }}
                                  type="button"
                                  className={` btn btn-slim btn-outline btn-outline-primary`}
                                >
                                  <span className="d-flex align-items-center justify-content-between gap-2">
                                    <svg
                                      viewBox="0 0 20 24"
                                      width="20"
                                      height="24"
                                      preserveAspectRatio="false"
                                    >
                                      <path
                                        fill="red"
                                        d="M13,0H11A3,3,0,0,0,8,3V4H2A1,1,0,0,0,2,6H3V20a4,4,0,0,0,4,4H17a4,4,0,0,0,4-4V6h1a1,1,0,0,0,0-2H16V3A3,3,0,0,0,13,0ZM10,3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V4H10Zm9,17a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6H19Z"
                                      />
                                      <path
                                        fill="red"
                                        d="M12,9a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V10A1,1,0,0,0,12,9Z"
                                      />
                                      <path
                                        fill="red"
                                        d="M15,18a1,1,0,0,0,2,0V10a1,1,0,0,0-2,0Z"
                                      />
                                      <path
                                        fill="red"
                                        d="M8,9a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V10A1,1,0,0,0,8,9Z"
                                      />
                                    </svg>

                                    <span>delete</span>
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div>
                              <div className="input-group textarea-with-button">
                                <textarea
                                  rows={2}
                                  value={comment}
                                  placeholder="Comment text here"
                                  aria-label="Comment"
                                  onChange={(e) => {
                                    setComment(e.target.value);
                                  }}
                                ></textarea>
                                <button
                                  className="input-group-text btn-secondary"
                                  disabled={!comment}
                                  onClick={() => {
                                    let payload = {
                                      biddingGroupId: biddingObject.BiddingGroupID,
                                      biddingListName:
                                        biddingObject.biddingListName,
                                      biddingListID: biddingObject.biddingListID,
                                      comments: comment,
                                      createdBy: userApiData.username,
                                      createdTo: biddingObject.VendorEmail,
                                    };

                                    addToBiidingComments(payload)
                                      .then((response) => {
                                        if (response.message) {
                                          console.log(response.message, "message comment")
                                          let sccs = <div className="tosterIcon"><div>Comment posted successfully</div></div>
                                          toast.success(sccs, {
                                            containerId: "A",
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log("when-accepted", err);
                                        let sccs = <div className="tosterIcon"><div>Something went wrong</div></div>
                                        toast.error(sccs, {
                                          containerId: "A",
                                        });
                                        
                                      });
                                  }}
                                >
                                  Post
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex gap-3 mb-1">

                          {!isCounterOffer && (
                            <button
                              type="button"
                              disabled={
                                biddingListDetail[0]?.VendorAccepted ||
                                biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick === 'false'
                              }
                              className={`btn-secondary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'false' ? 'processing' : ''}`}
                              onClick={() => handleAcceptClick(true, "User","accept")}
                            >
                              Accept
                            </button>
                          )}
                          {!isCounterOffer && (
                            <button
                              type="button"
                              className={`btn btn-primary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'counterOffer' ? 'processing' : ''}`}
                              title="Counter Offer"
                              disabled={
                                biddingListDetail[0]?.VendorAccepted ||
                                biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick  === 'counterOffer'
                              }
                              onClick={() => {
                                setIsCounterOffer(true);
                                handleClickEditCell();
                              }}
                            >
                              Count Offer
                            </button>
                          )}
                          {!isCounterOffer && (
                            <button
                              type="button"
                              className={`btn btn-primary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'reject' ? 'processing' : ''}`}
                              disabled={
                                biddingListDetail[0]?.VendorAccepted ||
                                biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick === 'reject'
                              }
                              onClick={() => {
                                handleAcceptClick(true, "User", "reject");
                              }}
                            >
                              Reject
                            </button>
                          )}
                          {isCounterOffer && (
                            <button
                              type="button"
                              className=" btn-primary btn-std-width btn-big"
                              onClick={() => {
                                updateCellData(
                                  null,
                                  null,
                                  null,
                                  null,
                                  false,
                                  "counterOffer"
                                );
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
            )}
          </Tabs>
          {props.isExternal && (
            <>
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between"></div>
                  <div>
                    <div className="d-flex align-items-center">
                      <span className="search-wrap">
                        <img
                          src={SearchIcon}
                          className="filter-search"
                          alt=""
                        />
                        <input
                          type="text"
                          placeholder="SKU / Description"
                          className="btn-slim search-input bg-black input-long"
                          onChange={(e) => {
                            setSearchText(e.target.value ?? "");
                          }}
                          value={searchText}
                        />
                        {searchText != "" && (
                          <a
                            className="filter-clear"
                            style={{ right: "54px"}}
                            onMouseDown={() => {
                              setSearchText("");
                              clearSearchClose()
                            }}
                          >
                            <img src={ClearIcon} alt="" />
                          </a>
                        )}
                        <input
                          type="button"
                          value="Search"
                          className="btn-slim search-btn"
                          onClick={(e) => {
                            getGridData(
                              {
                                biddingGroupId: biddingObject.biddingGroupId,
                                biddingListName: biddingObject.biddingListName,
                                biddingListID: biddingObject.biddingListID,
                                searchText: searchText ?? "",
                              },
                              props.isExternal,
                              isEditList
                            );
                          }}
                        />
                      </span>
                      <button
                        type="button"
                        className="btn-slim btn-download ml-3"
                        onClick={(g) => handleDownload()}
                      >
                        <img src={DownloadIcon} alt="" />
                        Download
                      </button>
                      <button
                        type="file"
                        onClick={handleFileOpen_customer}
                        className="btn-slim btn-download ml-2"
                        disabled={!isCounterOffer}
                      >
                        <img src={UploadIcon} alt="" /> Upload
                      </button>
                    </div>
                  </div>
                </div>
                {/* Loader */}
                <div>
                  {gridInfo[0] === "Loading" && (
                    <>
                      <div className={`tableLoader_overlay`}>
                        <div className="tableLoader_box">
                          <div className="tableLoader_box-in">
                            <Loader />
                          </div>
                        </div>
                      </div>
                      <div style={{ height: "160px" }}></div>
                    </>
                  )}
                </div>

                {/* Table grid */}
                <div>
                  {gridInfo[0] !== "Loading" && (
                    <div className=" ">
                      <PersonalCareTableGrid
                        state={state || isCounterOffer}
                        pageInd={pageInd}
                        setPageInd={setPageInd}
                        columns={CUSTCOLUMNS}
                        data={gridInfo}
                        SortBy={SortBy}
                        SortType={SortType}
                        setSortBy={setSortBy}
                        setSortType={setSortType}
                        fetchData={fetchData}
                        pageCount={pageCount}
                        totalRecordCount={totalRecordCount}
                        pageSizeNo={itemPerPage}
                        // loading={loading}
                        handleItemPerPage={handleItemPerPage}
                        handlePaginationReset={handlePaginationReset}
                        handleSearch={handleSearch}
                        handleEnterSearch={handleEnterSearch}
                        handleSearchReset={handleSearchReset}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        setSelection={setSelection}
                        selection={selection}
                        publishLoading={publishLoading}
                        actions={actions}
                        searchText={searchText}
                        setSearchText={setSearchText}
                        getGridData={getGridData}
                        isExternal={props.isExternal}
                        {...editCellProps}
                        setIsSelectAll={setIsSelectAll}
                        totalCount={totalCount}
                        counterOffer={
                          biddingListDetail[0]?.CustomerCounterOffer
                        }
                        biddingStatus={biddingListDetail[0]?.BiddingStatus}
                        // isOfferSentInputDisabled={processOfferSentInputsEditable()}
                        isExpand={isExpand}
                      />
                    </div>
                  )}
                </div>
                <div className="flex align-items-end justify-content-end mt-2">
                  {/* Add Comment */}
                  <div className="d-flex add-comment-div">
                    <div className="col-md-12 mr-10">
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <h5 className="text-sm">
                          <i>Add Comment</i>
                        </h5>
                        <div>
                          <button
                            onClick={() => {
                              setComment("");
                            }}
                            type="button"
                            className={` btn btn-slim btn-outline btn-outline-primary`}
                          >
                            <span className="d-flex align-items-center justify-content-between gap-2">
                              <svg
                                viewBox="0 0 20 24"
                                width="20"
                                height="24"
                                preserveAspectRatio="false"
                              >
                                <path
                                  fill="red"
                                  d="M13,0H11A3,3,0,0,0,8,3V4H2A1,1,0,0,0,2,6H3V20a4,4,0,0,0,4,4H17a4,4,0,0,0,4-4V6h1a1,1,0,0,0,0-2H16V3A3,3,0,0,0,13,0ZM10,3a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V4H10Zm9,17a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6H19Z"
                                />
                                <path
                                  fill="red"
                                  d="M12,9a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V10A1,1,0,0,0,12,9Z"
                                />
                                <path
                                  fill="red"
                                  d="M15,18a1,1,0,0,0,2,0V10a1,1,0,0,0-2,0Z"
                                />
                                <path
                                  fill="red"
                                  d="M8,9a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V10A1,1,0,0,0,8,9Z"
                                />
                              </svg>

                              <span>delete</span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className="input-group textarea-with-button">
                          <textarea
                            rows={2}
                            value={comment}
                            placeholder="Comment text here"
                            aria-label="Comment"
                            onChange={(e) => {
                              setComment(e.target.value);
                            }}
                          ></textarea>
                          <button
                            className="input-group-text btn-secondary"
                            disabled={!comment}
                            onClick={() => {
                              let payload = {
                                biddingGroupId: biddingObject.BiddingGroupID,
                                biddingListName:
                                  biddingObject.biddingListName,
                                biddingListID: biddingObject.biddingListID,
                                comments: comment,
                                createdBy: userApiData.username,
                                createdTo: biddingObject.VendorEmail,
                              };

                              addToBiidingComments(payload)
                                .then((response) => {
                                  if (response.message) {
                                    console.log(response, "message comment")
                                    let sccs = <div className="tosterIcon"><div>Comment posted successfully</div></div>
                                    toast.success(sccs, {
                                      containerId: "A",
                                    });
                                  }
                                })
                                .catch((err) => {
                                  console.log("when-accepted", err);
                                  let sccs = <div className="tosterIcon"><div>Something went wrong</div></div>
                                  toast.error(sccs, {
                                    containerId: "A",
                                  });
                                  
                                });
                            }}
                          >
                            Post
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex gap-3 mb-1">
                    {/* Accept button */}
                    {!isCounterOffer && (
                      <button
                        type="button"
                        className={`btn btn-secondary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'false' ? 'processing' : ''}`}
                        disabled={
                          biddingListDetail[0]?.VendorAccepted ||
                          biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick === 'false'
                        }
                        onClick={() => {
                          handleAcceptClick(false, "Customer","accept");
                        }}
                      >
                        Accept
                      </button>
                    )}
                    {/* Count Offer button */}
                    {!isCounterOffer && (
                      <button
                        type="button"
                        className={`btn btn-primary btn-std-width btn-big ${isSetSaveLoaderInit__handleAcceptClick  === 'counterOffer' ? 'processing' : ''}`}
                        disabled={
                          biddingListDetail[0]?.VendorAccepted ||
                          biddingListDetail[0]?.VendorRejected || isSetSaveLoaderInit__handleAcceptClick  === 'counterOffer'
                        }
                        onClick={() => {
                          setIsCounterOffer(true);
                          handleClickEditCell();
                        }}
                      >
                        Count Offer
                      </button>
                    )}
                    {/* Submit button */}
                    {isCounterOffer && (
                      <button
                        type="button"
                        className=" btn-primary btn-std-width btn-big"
                        disabled={
                          biddingListDetail[0]?.VendorAccepted ||
                          biddingListDetail[0]?.VendorRejected
                        }
                        onClick={() => {
                          updateCellData(null, null, null, null, true);
                        }}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </ULModal>
    </>
  );
};

export default Offer;
