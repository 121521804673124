import React, { useContext, useState } from "react";
import { EuropeCountry } from "./Assets/img/europe_country.jsx";
import { IndonesiaCountry } from "./Assets/img/indoneisa_country.jsx";
import { useHistory, useLocation } from "react-router-dom";
import "./MainLandingPage.css";
import { UserCheckContext } from "../src/App";
import { UserAccessContext, UserContext } from "../src/Components/Application";
import { ToastContainer, toast } from "react-toastify";

function MainLandingPage() {
  const history = useHistory();
  const isInternalUser = useContext(UserCheckContext)
  const userAccess = useContext(UserAccessContext)
  const user = useContext(UserContext)
  const [trackToaster, setTrackToaster] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.state);
  const value = queryParams.get('error');
  if(value === "no-access" && !trackToaster){
    toast.error(<div className="tosterIcon">You are not Authorised.</div>, {
      containerId: "A",
    });
    setTrackToaster(true)
    
  }
  const currentUrl = window.location.href;

  const handelIndonasiaLink=()=>{
    if(currentUrl.includes("-p-") || currentUrl.includes("https://polarisbusinesswaste.unilever.com")){
      window.open("https://bieno-da09-p-904329-webapi-08.azurewebsites.net","_blank")
    }
    else if(currentUrl.includes("-q-")){
      window.open("https://bieno-da11-q-904331-webapi-08.azurewebsites.net","_blank")
    }else{
      window.open("https://bieno-da12-d-904330-webapi-10.azurewebsites.net","_blank")
    }
  }

  
  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_CENTER}
        autoClose={2000}
      />
      
      <div className="maindiv"></div>
      <div className="mainLndingPage">
        <div className="mainContent">
          <h4>Business Unit</h4>
          <hr></hr>
          <div className="buttonGroup">
            <button
              className="mainPageButton"
              onClick={() => history.push(user.userRole === "Customer" ? "/biddingportal" : "/home")}//"/landingPage2"
            >
              <div style={{ width: "30%" }}>
                <EuropeCountry color="white" />
              </div>
              <h6 style={{ paddingLeft: "20px" }}>Europe</h6>
            </button>

            <button className="mainPageButton"
              onClick={handelIndonasiaLink}
            >

              <IndonesiaCountry color="white" />
              <div  style={{ marginLeft: "30px" }}>
              {/* className="disableInnerText" */}
                <h6>Indonesia</h6>
                {/* <p>Coming Soon...</p> */}
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainLandingPage;
