import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getPomPastSSLVsAbsQtySummary } from '../../../Services/PomSummaryService';
import Loader from "../../../Common/loader";
import { PayloadWithFilters, LABEL_COLOR } from "../../Pom/PomSummary/helpers";
import { UserAccessContext } from "../../Application";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const PastWeekLineChart = ({ dropDownValues }) => {


    const [chartData, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userAccess = useContext(UserAccessContext)



    const getApiData = async (payload) => {
        try {
            const response = await getPomPastSSLVsAbsQtySummary(payload);
            console.log("response", response);
            setData(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setIsLoading(false); // Update loading state after fetching
        }
    };

    useEffect(() => {
        if(dropDownValues != undefined) getApiData(PayloadWithFilters(dropDownValues,userAccess));
    }, [dropDownValues]);

    // const getApiData = React.useCallback(async () => {
    //     const response = await getPomPastSSLVsAbsQtySummary();
    //     console.log("response", response);
    //     setData(response.data);
    // }, [])

    // useEffect(() => {
    //     getApiData();
    // }, []);

    // const nextStepsRef = useRef();
    if (isLoading) {
        return <Loader />; // Display loader while data is loading
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },

        },
        scales: {
            x: {
                ticks: {
                    display: true,
                    color: LABEL_COLOR,
                    font: {
                         
                        family: "unilevershilling",
                    },
                     
                    maxRotation: 0, // Set to 0 to keep the labels horizontal
                    minRotation: 0,
                },
            },
            y: {
                gird: {
                    display: false,
                },
                display: true,
                ticks: {
                    callback: (value) => {
                        if (value === 0) return '€ 0';
                        const formattedValue = (value / 1000000).toFixed(2); // Convert to millions and round to 1 decimal place
                        return `€ ${formattedValue}M`;
                    },
                    color: LABEL_COLOR,
                    font: {
                        
                        family: 'unilevershilling',
                    },
                    stepSize: 100000,
                },
            },
        }
    };

    const dataValues = chartData?.map((data) => Number(data.keyValue).toFixed(2))
    // console.log(":: DATA ::", { dataValues, chartData })
    const data = {
        labels: chartData?.map(row => `W-${row.weekNo}`),
        datasets: [
            {
                // label: 'None',
                data: dataValues,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgb(255, 99, 132)",
                datalabels: {
                    display: false
                }
            }
        ],
    };
    return (
        <>
            {chartData.length > 0 && (
                <Line options={options} data={data} />
            )}
            {chartData.length <= 0 && (
                <div className="mt-3 chart_empty d-flex align-items-center justify-content-center h-100" style={{backgroundColor: 'rgba(0,0,0,0.1)'}}>
                    <p className="text-grey">No Data</p>
                </div>
            )}
        </>
    );

}


export default PastWeekLineChart;
