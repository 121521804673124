import React from "react";
import ULModal from "../Modal";
import CreateNewListPopupBody from "./CreateNewListPopupBody";

const CreateNewListPopup = ({
  show,
  onHide,
  inputName,
  setInputName,
  isNewListSaved,
  handleCreateNewList,
  OpenRecentCreatedList,
  from,
  isLoading
}) => {
  const heading = `Create New List`;
  return (
    <ULModal
      show={show}
      onHide={onHide}
      size="md"
      heading={heading}
      isheader={!isNewListSaved}
    >
      <CreateNewListPopupBody
        {...{
          onHide,
          inputName,
          setInputName,
          isNewListSaved,
          handleCreateNewList,
          OpenRecentCreatedList,
          from,
          isLoading,
        }}
      />
    </ULModal>
  );
};

export default CreateNewListPopup;
