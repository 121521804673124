import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import DownloadIcon from "../../../../src/Assets/img/download.svg";
import UploadIcon from "../../../../src/Assets/img/upload.svg";
import Loader from "../../../../src/Common/loader";
import StatusPopup from "../../../Common/StatusPopup/statusPopup";
import { v4 } from 'uuid';
import {
  InflowRiskVisibilityDetails,
  downloadInflowRiskDBDetails,
  downloadInflowRiskDetails,
  excelFileUpload,
  addPulishStatus,
  setPublishStatus,
  UpdateWasteRiskCellData,
  checkFileStatus,
  PendingActionsCount,
} from "../../../Services/InflowRiskVisibilityService";
import { notify } from "../../../../src/Common/toast";
import { COLUMNS } from "./Columns";
import { PerPageRecordNumber } from "../../../../src/utility/constants";
import TableGrid from "./TableGrid";
import moment from "moment";
import { UserAccessContext, UserContext } from "../../Application";

var fileSaver = require("file-saver");

const InflowRiskVisibilityTable = (props) => {
  const fileUploadInput = useRef(null);
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [state, setState] = useState("Loading")
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [pageInd, setPageInd] = useState(0)
  const [pageCount, setPageCount] = useState(0);
  const [uniqueId, setUniqueId] = useState("");
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [isShowFilePopup, setIsShowFilePopup] = useState(false);
  const [isFileProcessed, setIsFileProcessed] = useState(false);
  const [isFileStatusDone, setIsFileStatusDone] = useState(false);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [searchValue, setSearchValue] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [SortBy, setSortBy] = useState("fb_l4w");
  const [SortType, setSortType] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [selection, setSelection] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [publishCount, setPublishCount] = useState(0);
  const [publishLoading, setPublishLoading] = useState(false);
  const [refObj, setRefObj] = useState([]);
  const [filterText, setFilterText] = useState('')
  const [filterKey, setFilterKey] = useState('')
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [selectedCol, setSelectedCol] = useState([]);
  const [hasColUpdated, setHasColUpdated] = useState(false);
  const [columnSearch,SetColumnSearch] = useState({});
  const user = useContext(UserContext);
  const fetchIdRef = React.useRef(0);
  const userAccess = useContext(UserAccessContext)
  const [downloadClicked, setDownloadClicked] = useState(0);
  const [rowDownloadId,setRowDownloadIds] = useState([])
  const [clearColumnSearch,setColumnClearSearch]= useState(false)
  const [isSetSaveLoaderInit__publish, setSaveLoaderInit__publish] = useState(false);

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
  };
  useEffect(() => {
    console.log(gridInfo)
  }, [gridInfo])
   const setDropdownvaluesGlobal=(data1,data2)=>{
      if(data1){
        if(data1.length===0){
          return(data2)
        }
        else{
          return(data1)
        }
      }
      else{return(data2)}

   }
  const addToPublish = (values) => {
    let data = [];
    selection.forEach((val) => {
      data.push({
        product_code: val.original.product_code,
        country: val.original.country,
        countryId: val.original.countryId,
        mcoId: val.original.mcoId,
        status: values,
        descriptionId: val.original.descriptionId
      });
    });
    addPulishStatus({ data: data })
      .then((response) => {
        setSelection([]);
        setTimeout(() => { getPendingActionsCount(); }, 200)
        console.log(props.dropDownValues?.businessGroup)
        getGridData({
          brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
          countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
          categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
          businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup, userAccess?.inflowdata?.data.bussinessGroupId),
          mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
          plantCodeId: props.dropDownValues?.plant ?? [],
          actionTypeId: props.dropDownValues?.actiontype ?? [],
          riskTypeId: props.dropDownValues?.riskType ?? [],
          UserEmail: user.email,
          riskLevelId: props.dropDownValues?.riskLevel ?? [],
          forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
          forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
          foodSolutionIndicatorId: props.dropDownValues?.fsIndicator?? [],
        forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
          page,
          columnFilter:Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
          per_page: itemPerPage,
          sortby: SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
        });
        notify({
          message: `Sucessfully Added to Publish List`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish List failed`,
          type: "error",
        });
      });
  };

  const MINUTE_MS = 5000;
  const fileUpdateChecker = (type) => {
    checkFileStatus({ uniqueTaskId: uniqueId, "task": type, "module": "INFLOW" })
      .then((response) => {
        if (response.taskstatus === "completed") {
          setIsFileProcessed(false);
          setIsShowFilePopup(false)
          setIsFileStatusDone(false)
          if (
            type === "upload"
          ) {
            setIsTableLoading(true)
            setIsShowFilePopup(false)
            setTimeout(() => {
              getPendingActionsCount();
            }, 5000);
            notify({
              message: "File Upload Successfully!",
              type: "success",
            });
          } else {
            setIsShowFilePopup(false)
            setIsFileStatusDone(false)
            setIsFileProcessed(false)
          }
        }
      })
      .catch(() => {
        setIsFileProcessed(false);
        setIsFileStatusDone(false);
        setIsShowFilePopup(false);
      });
  };
  useEffect(() => {
    if (isFileProcessed || isFileStatusDone) {
      const interval = setInterval(() => {
        if (isFileProcessed) {
          fileUpdateChecker("download");
        } else if (isFileStatusDone) {
          fileUpdateChecker("upload");
        }
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [fileUpdateChecker, isFileProcessed, isFileStatusDone]);

  const gotoPublish = (values) => {
    if (!publishCount) {
      setIsLoading(false)
      notify({
        message: `There are no actions in publish list`,
        type: "warn",
      });
      return;
    }
    setSaveLoaderInit__publish(true);
    setIsLoading(true)
    setPublishStatus({
      UserEmail: user.email
    })
      .then((response) => {
        setSaveLoaderInit__publish(false);
        
        getPendingActionsCount();
        console.log(props.dropDownValues?.businessGroup)
        setTimeout(() => {
          getGridData({
            brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
            countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
            categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
            businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
            mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
            plantCodeId: props.dropDownValues?.plant ?? [],
            actionTypeId: props.dropDownValues?.actiontype ?? [],
            riskTypeId: props.dropDownValues?.riskType ?? [],
            riskLevelId: props.dropDownValues?.riskLevel ?? [],
            forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
            forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
            foodSolutionIndicatorId: props.dropDownValues?.fsIndicator?? [],
            forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
            columnFilter:Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
            page,
            per_page: itemPerPage,
            sortby: SortBy,
            complianceSorting: SortType,
            searchText: searchText ?? "",
          });
          props?.loadDropdownsData()
        }, 1000)
        setSelection([]);
        notify({
          message: `Sucessfully Updated`,
          type: "success",
        });
        setIsLoading(false)
      })
      .catch(() => {
        setSaveLoaderInit__publish(false);
        setIsLoading(false)
        notify({
          message: `Adding Publish status failed`,
          type: "error",
        });
      });
  };

  const toggleWarnMessageModal = () => {
    notify({
      message: `Please select sku's`,
      type: "warn",
    });
  };
  const getGridData = useCallback((payload) => {
    setState('Loading');
    console.log(payload);
    // console.log(payload);
    InflowRiskVisibilityDetails(payload).then((response) => {
      if (response.data && response.data.length) {
        response.data.forEach(row => {
          row.actionsList = response.actions;
        })
      }
      setGridInfo(response.data);
      setActions(response.actions);
      setTotalRecordCount(response.count);
      setTotalCount(response.totalCount)
      props.setStatistics({
        data: {
          risk_details: response.risk_details,
          brand_with_risk_sku: response.brand_with_risk_sku,
        },
      });
      setTimeout(() => { setState('Completed'); }, 200);
      setIsLoading(false);
    }).catch(() => {
      setState('Completed');
      setIsLoading(false);
    });
  }, [props.dropDownValues]);

  const getPendingActionsCount = useCallback((payload) => {
    PendingActionsCount(payload).then((response) => {
      setPublishCount(response.pendingActionsCount);
      setTimeout(() => { setIsLoading(false) }, 150);
    }).catch(() => {
      setIsLoading(false)
    });
  }, []);

  const onActionClick = (val) => {
    if (selection.length === 0) {
      notify({
        message: `Please select one record!`,
        type: "warn",
      });
      return;
    } else {
      addToPublish(val);
    }
  };

  const downloadData = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadInflowRiskDetails(payload).then((response) => {
    });
  };

  const handleColumnsSelected = (e) => {
    let columnVisibilitySelected = JSON.parse(localStorage.getItem('columnVisibilitySelected'));
    let columnVisibilitySelected_without_all = columnVisibilitySelected.filter(j => j !== 'all');
    setTimeout(() => {
      if(downloadClicked !== 0){
          handleDownload(columnVisibilitySelected_without_all);}
    }, 1000);
  }
  // console.log(props.dropDownValues)
  // const handleDownload = useCallback((columnsVisibilitySelected) => {
  const handleDownload = useCallback(() => {
    console.log(props.dropDownValues)
    let payload = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
        plantCodeId: props.dropDownValues?.plant ?? [],
        actionTypeId: props.dropDownValues?.actiontype ?? [],
        riskTypeId: props.dropDownValues?.riskType ?? [],
        riskLevelId: props.dropDownValues?.riskLevel ?? [],
        forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
        forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.fsIndicator ?? [],
        forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
        filename: "inflow_risk_details",
        complianceSorting: SortType,
        searchText: searchText ?? "",
        responseType: "arraybuffer",
        referenceID:rowDownloadId.map((i=>i.original.id)),
        // columnsSelected : columnsVisibilitySelected,
    }
    if(hasColUpdated){
     
      const updatedSelectedCol = selectedCol.filter(item => item?.trim() != "add_customer" && item?.trim() != "view_history")
      console.log(selectedCol)
      const editableArray = ["comment_ww","owner_scol","comment_scol","fc_adjustment_needed","owner_feo","comment_feo","actiontype"]
      let array1 = [];
      let array2 = [];
      updatedSelectedCol.map(item => {
        if(editableArray.includes(item)){
          array2.push(item)
        } else {
          array1.push(item)
        }
      })

      let combinedArray = [...array1, ...array2]
      let FixedColumns = ["product_code","description","brand","plantCode","country"]
      combinedArray = Array.from(new Set(FixedColumns.concat(combinedArray)));

      const nextStepIndex = combinedArray.findIndex(i => i == "nextStepId")
      if(nextStepIndex != -1){
        combinedArray[nextStepIndex] = "nextStep";
      }

      payload = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
        plantCodeId: props.dropDownValues?.plant ?? [],
        actionTypeId: props.dropDownValues?.actiontype ?? [],
        riskTypeId: props.dropDownValues?.riskType ?? [],
        riskLevelId: props.dropDownValues?.riskLevel ?? [],
        forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
        forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.fsIndicator ?? [],
        forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
        filename: "inflow_risk_details",
        complianceSorting: SortType,
        searchText: searchText ?? "",
        responseType: "arraybuffer",
        referenceID:rowDownloadId.map((i=>i.original.id)),
        // columnsSelected : columnsVisibilitySelected,
        selectedAttributes: combinedArray.toString(),
      }
    }

    if (gridInfo.length > 0) {
      downloadData(payload);
    } else {
    }
    // if (gridInfo.length > 0) {
    //   downloadData({
    //     brandId: props.dropDownValues?.brand ?? [],
    //     countryId: props.dropDownValues?.country ?? [],
    //     categoryId: props.dropDownValues?.category ?? [],
    //     businessGroupId: props.dropDownValues?.businessGroup ?? [],
    //     mcoId: props.dropDownValues?.mco ?? [],
    //     plantCodeId: props.dropDownValues?.plant ?? [],
    //     actionTypeId: props.dropDownValues?.actiontype ?? [],
    //     riskTypeId: props.dropDownValues?.riskType ?? [],
    //     riskLevelId: props.dropDownValues?.riskLevel ?? [],
    //     forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
    //     forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
    //     foodSolutionIndicatorId: props.dropDownValues?.fsIndicator ?? [],
    //     forecastFamilyId: props.dropDownValues?.forecastFamily ?? [],
    //     filename: "inflow_risk_details",
    //     complianceSorting: SortType,
    //     searchText: searchText ?? "",
    //     responseType: "arraybuffer",
    //     // columnsSelected : columnsVisibilitySelected,
    //   });
    // } else {
    // }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.brand,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.dealType,
    props.dropDownValues?.materialNumber,
    props.dropDownValues?.mco,
    props.dropDownValues?.plant,
    props.dropDownValues?.riskType,
    props.dropDownValues?.residualStatus,
    selectedCol,
    hasColUpdated,
    rowDownloadId,
  ]);
  const handleFileOpen = () => {
    fileUploadInput.current.click();
  };
  const uploadFileHandler = (event) => {
    const files = event.target.files[0];
    if (files) {
      let formData = new FormData();
      const fileType = files.name.indexOf(".xls") > 0;
      const fileSize = files.size / 1024 / 1024;
      const allowedFileSize = 10;
      if (fileType && fileSize < allowedFileSize) {
        formData.append("file", files);
        formData.append("UserEmail", user.email);
        setIsShowFilePopup(true)
        setIsFileProcessed(false)
        excelFileUpload(formData)
          .then((response) => {
            if (response.data.message == "SUCCESSFULLY_UPLOADED") {
              setIsFileStatusDone(true)
              setUniqueId(response.data.uniqueTaskId)

            }
          })
          .catch((error) => {
            notify({
              message: error,
              type: "error",
            });
          });
      } else if (fileSize > allowedFileSize) {
        notify({
          message: `File size exceeds ${allowedFileSize} MB`,
          type: "error",
        });
        files.value = null;
      } else {
        notify({
          message: "File format should be Excel only",
          type: "error",
        });
        files.value = null;
      }
    }
  };

  const downloadDataBase = (payload) => {
    let newUniqueId = v4();
    setUniqueId(newUniqueId)
    payload.uniqueTaskId = newUniqueId;
    setIsFileProcessed(true)
    setIsShowFilePopup(true)
    downloadInflowRiskDBDetails(payload).then((response) => {
    });
  };
  const handleDataBaseDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      downloadDataBase({
        alertTypeId: props.dropDownValues?.alert ?? [],
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId: props.dropDownValues?.businessGroup ?? [],
        plant_code: props.dropDownValues?.plant ?? [],
        filename: "DataBase_details",
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ? searchText.trim() : "",
       });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.plant,
    props.dropDownValues?.alert,
  ]);

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
   };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };

  const handleSearch = (val) => {
     setSearchParam(val);
  };

  const handleEnterSearch = (event, val) => {
    var key = event.keyCode || event.charCode;
    if (key === 13) {
      setSearchParam(val);
    }

    if ((val === "" || val.length === 1) && (key === 8 || key === 46)) {
      setSearchParam("");
    }
  };

  const handleSearchReset = () => {
    setSearchValue("");
    setSearchParam("");
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(100);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  useEffect(() => {
    getPendingActionsCount();
  }, [getPendingActionsCount]);
  useEffect(() => {
    refObj?.forEach((key) => {
      if (key[Object.keys(key)[0]].current) {
        if (key[Object.keys(key)[0]].current.state)
          key[Object.keys(key)[0]].current.state.value = "";
        else {
          key[Object.keys(key)[0]].current.value = "";
        }
      }
    });
    setFilterKey('');
    setFilterText('');
  }, [props.dropDownValues,searchText,clearColumnSearch]);
  useEffect(() => {
    if (props.dropDownValues) {
      let payLoadData = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId: setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
        plantCodeId: props.dropDownValues?.plant ?? [],
        actionTypeId: props.dropDownValues?.actiontype ?? [],
        riskTypeId: props.dropDownValues?.riskType ?? [],
        riskLevelId: props.dropDownValues?.riskLevel ?? [],
        forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
        forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.fsIndicator?? [],
        forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
        columnFilter:{},
        page,
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
      }
      getGridData(
        filterKey
          ? {
            ...payLoadData,
            datefilter: filterKey === "expiry_date" ? filterText : null,
            // columnFilter: filterKey,
            // columnFilterValue: filterKey === "expiry_date" ? null : filterText,
          }
          : payLoadData
      );
    }
  }, [ getGridData,props.dropDownValues,clearColumnSearch,
  ]);

  useEffect(() => {
    console.log(props.dropDownValues?.businessGroup)
    if (props.dropDownValues && userAccess) {
      let payLoadData = {
        brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
        countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
        categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
        businessGroupId:setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
        mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
        plantCodeId: props.dropDownValues?.plant ?? [],
        actionTypeId: props.dropDownValues?.actiontype ?? [],
        riskTypeId: props.dropDownValues?.riskType ?? [],
        riskLevelId: props.dropDownValues?.riskLevel ?? [],
        forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
        forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
        foodSolutionIndicatorId: props.dropDownValues?.fsIndicator?? [],
        forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
        columnFilter:Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
        page,
        per_page: itemPerPage,
        sortby: SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
      }
      getGridData(
        filterKey
          ? {
            ...payLoadData,
            datefilter: filterKey === "expiry_date" ? filterText : null,
            // columnFilter: filterKey,
            // columnFilterValue: filterKey === "expiry_date" ? null : filterText,
          }
          : payLoadData
      );
    }
  }, [
    SortBy,
    SortType,
    itemPerPage,
    page,
    userAccess,
  ]);
  
  const filterData = (value, key) => {
    // console.log(columnSearch)
    
    setIsLoading(true)
    const columnSearchAdd = {
      key:key,
      value:value, 
    }
     if(filterKey===''){
      console.log(filterKey)
      SetColumnSearch({ [columnSearchAdd.key]:columnSearchAdd.value?columnSearchAdd.value:""})
    }else{
      SetColumnSearch(prev=>{
        const result={ [columnSearchAdd.key]:columnSearchAdd.value?columnSearchAdd.value:""}
        return {...prev,...result}
        })
    }    
    setFilterKey(key);
    setFilterText(value);
    console.log(columnSearchAdd)
    console.log(key,value)
    
  };

  useEffect(()=>{ 
    let dateFilter;
    if("expiry_date" in columnSearch){
      dateFilter = columnSearch["expiry_date"];
    }
    else{
      dateFilter=null
    }
    setPageInd(0)
    console.log(props.dropDownValues?.businessGroup)
    getGridData({
      brandId: setDropdownvaluesGlobal(props.dropDownValues?.brand ,userAccess?.inflowdata?.data?.brandId),
      countryId: setDropdownvaluesGlobal(props.dropDownValues?.country,userAccess?.inflowdata?.data?.countryId),
      categoryId: setDropdownvaluesGlobal(props.dropDownValues?.category ,userAccess?.inflowdata?.data?.categoryId),
      businessGroupId:setDropdownvaluesGlobal(props.dropDownValues?.businessGroup,userAccess?.inflowdata?.data.bussinessGroupId),
      mcoId: setDropdownvaluesGlobal(props.dropDownValues?.mco ,userAccess?.inflowdata?.data?.mcoId),
      plantCodeId: props.dropDownValues?.plant ?? [],
      actionTypeId: props.dropDownValues?.actiontype ?? [],
      riskTypeId: props.dropDownValues?.riskType ?? [],
      riskLevelId: props.dropDownValues?.riskLevel ?? [],
      forecastbiasL12Id: props.dropDownValues?.forecastbiasL12w ?? [],
      forecastbiasL4Id: props.dropDownValues?.forecastbiasL4w ?? [],
      foodSolutionIndicatorId: props.dropDownValues?.fsIndicator?? [],
      forecastFamilyId: props.dropDownValues?.forecastfamily ?? [],
      page:1,
      per_page: itemPerPage,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      datefilter:dateFilter,
      columnFilter: Object.fromEntries(Object.entries(columnSearch).filter(([_, value]) => value !== "")),
      // columnFilterValue: columnSearch["expiry_date"] ? null : columnSearch["expiry_date"],
    });
    setTimeout(() => setIsLoading(false), 1000)

    console.log(columnSearch)
  },[columnSearch])

  const updateCellData = (row, column, value) => {
    const rowId = row.original.id;
    let updatedValue =
      column.id === "expiry_date" ? moment(value).format("DD-MM-YYYY") : value;
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);
    setClickedIndex(null);
    setClickedCell(null);
    var action = actions.find(v => v.id == updatedValue) || {}
    if (column.id === "actiontype") {
      updatedValue = (action || {}).action_name;
    }
    UpdateWasteRiskCellData({
      id: rowId,
      referenceID: row.original.product_code,
      country: row.original.country,
      countryId: row.original.countryId,
      descriptionId: row.original.descriptionId,
      plantCode: row.original.plantCode,
      key: column.id,
      updatedValue,
      mcoId: row.original.mcoId,
      UserEmail: user.email
    })
      .then((response) => {
        gridInfo?.forEach((val) => {
          if (val.id == rowId) {
            val[column.id] = updatedValue;
            if (column.id === "actiontype") {
              val[column.id] = action?.action_name;
              val.actionTypeId = action?.id
            }
          }
        });
        console.log("::: gridInfo :::", gridInfo)
        setGridInfo([...gridInfo]);
        if (response.message === "Successfully Initiated the record") {
          getPendingActionsCount(); //same record condition needs to add here (API changes)
          notify({
            message: "Successfully added to Publish List. Click Publish to save all changes",
            type: "success",
          });
        } else {
          notify({
            message: "Something Went Wrong!",
            type: "error",
          });
        }
      }, 100)
      .catch((error) => {
        console.log("error: ", error);
        notify({
          message: "No Response From Server. Please Try Again Later",
          type: "error",
        });
      });
  };

  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };

  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    filterData,
    actions,
    onActionClick
  };

  return (
    <div className="main-body">
      <div className="row">
        <div className="">
          <div className="table-actions">
            <button
              type="button"
              disabled={isLoading || isSetSaveLoaderInit__publish || userAccess?.inflowdata?.data.accessId === "1"?true:false}
              // disabled={isLoading || isSetSaveLoaderInit__publish}
              className={`btn btn-slim 
              ${publishCount === 0
                ? 'btn-published'
                : 'btn-download btn-published btn-published-with-badge cursor-pointer'} ${isSetSaveLoaderInit__publish ? 'processing' : ''}`}
              onClick={() => {
                setIsLoading(true)
                setPublishLoading(true)
                setTimeout(() => {
                  gotoPublish();
                  setPublishLoading(false)
                }, 50);
                setTimeout(() => {
                  setPublishLoading(false);
                }, 500);
              }}
            >
              Publish
              {publishCount !== 0 && (
                <span className="badge badge-light">{publishCount}</span>
              )}
            </button>
            <button
              type="file"
              onClick={handleFileOpen}
              className="btn-slim btn-download"
              style={{backgroundColor:"yellow"}}
              disabled={userAccess?.inflowdata?.data.accessId === "1"?true:false}
            >
              <img src={UploadIcon} alt="" /> Upload
            </button>
            <input
              type="file"
              name="file"
              id="input_uploadFileHandler"
              ref={fileUploadInput}
              onChange={uploadFileHandler}
              className="btn-download d-none"
            />
            <button
              type="button"
              className="btn-slim btn-download"
              onClick={handleDownload}
              // onClick={()=>{
              //   setDownloadClicked(Math.random(300));
              // }}
              style={{backgroundColor:"yellow"}}
            >
              <img src={DownloadIcon} alt="" />
              Download
            </button>
            <button
              type="button"
              className="btn-slim btn-download"
              onClick={handleDataBaseDownload}
            >
              <img src={DownloadIcon} alt="" />
              DataBase
            </button>
          </div>
        </div>
        <StatusPopup
          onRefreshClick={() => {
            fileUpdateChecker(isFileProcessed ? "download" : "upload");
          }}
          show={isShowFilePopup}
          onHide={(value) => {
            setIsShowFilePopup(value);
          }}
        ></StatusPopup>

        <div>
          <div className="body-container  body-container-table-with-pagination waste_risk_details_page ">
            {gridInfo[0] !== "Loading" && (
              <>
                {gridInfo && (
                  <TableGrid
                    state={state}
                    pageInd={pageInd}
                    setPageInd={setPageInd}
                    columns={COLUMNS}
                    data={gridInfo}
                    SortBy={SortBy}
                    SortType={SortType}
                    setSortBy={setSortBy}
                    setSortType={setSortType}
                    fetchData={fetchData}
                    pageCount={pageCount}
                    totalRecordCount={totalRecordCount}
                    publishLoading={publishLoading}
                    pageSizeNo={itemPerPage}
                    handleItemPerPage={handleItemPerPage}
                    handlePaginationReset={handlePaginationReset}
                    handleSearch={handleSearch}
                    handleEnterSearch={handleEnterSearch}
                    handleSearchReset={handleSearchReset}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setSelection={setSelection}
                    selection={selection}
                    toggleSelection={toggleSelection}
                    actions={actions}
                    refObj={refObj}
                    setRefObj={setRefObj}
                    onActionClick={onActionClick}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    getGridData={getGridData}
                    {...editCellProps}
                    isTableLoading={isTableLoading}
                    props={props}
                    isLoading={isLoading}
                    setIsSelectAll={setIsSelectAll}
                    toggleExpandCollapse={props.toggleExpandCollapse}
                    dropDownValues={props.dropDownValues}
                    isExpand={props.isExpand}
                    toggleWarnMessageModal={toggleWarnMessageModal}
                    totalCount={totalCount}
                    downloadClicked={downloadClicked}
                    handleColumnsSelected={handleColumnsSelected}
                    SetColumnSelected={props.SetColumnSelected}
                    setSelectedCol={setSelectedCol}
                    setHasColUpdated={setHasColUpdated}
                    setRowDownloadIds={setRowDownloadIds}
                    rowDownloadId={rowDownloadId}
                    setColumnClearSearch={setColumnClearSearch}
                    clearColumnSearch={clearColumnSearch}

                  />
                )}
              </>
            )}
            {gridInfo[0] === "Loading" && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InflowRiskVisibilityTable;
