import React, { useCallback, useEffect, useState, useRef } from "react";
import TableGrid from "../TableGrid";
import { COLUMNS } from "../ColumnsControlAdminsGroup";
import SearchIcon from "../../../../Assets/img/search.svg";
import ClearIcon from "../../../../Assets/img/icon-cancel-white.svg";
 
const Table = (props) => {
  const searchKeyword = useRef(null);
  const[userData, setUserData]=useState([]);
  const[userDataContainer, setUserDataContainer]=useState([]);
  useEffect(()=>{
    setUserData(props.data);
    setUserDataContainer(props.data);
  },[props]);
  const clearSearchClose = () => {
    searchKeyword.current.value = '';
    columnsListWrap();
  }
  const setKeywordFunc = () => {
    columnsListWrap();
  }
  const columnsListWrap = () => {
    var columnsFiltered = [''];
    var columnsFiltered2 = [''];
    var qq = [];
    var dw = [];
    if(searchKeyword.current.value != '' && searchKeyword.current.value != undefined) {
      columnsFiltered = userData.filter(e => e.group_name.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase()));
      columnsFiltered2 = userData.filter(e => e.admin_name.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase()));
      qq = [...columnsFiltered, ...columnsFiltered2];
      dw = [...new Set(qq)];
    } else {
      dw = userData;
    }
    console.log("on search",searchKeyword.current.value, userData);
    setUserDataContainer(dw);
  }
  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <div></div>
        <div>
          <div>
            <div className="search-bar d-flex align-items-center"  style={{width:'290px'}}>
              <span className="search-wrap-out w-100">
                <span className="search-wrap w-100">
                  <img src={SearchIcon} className="filter-search" alt="" />
                  <input
                    type="text"
                    placeholder="Group Name / Admin Name"
                    className="btn-slim search-input bg-black w-100"
                    ref={searchKeyword}
                  />
                  <a
                    className="filter-clear"
                    onClick={() => clearSearchClose()}
                  >
                    <img src={ClearIcon} alt="" />
                  </a>
                </span>
                <input
                  type="button"
                  value="Search"
                  className="btn-slim search-btn"
                  onClick={() => setKeywordFunc()}
                />
              </span>
            </div>

          </div>
        </div>
      </div>
      <TableGrid data={userDataContainer} key={userDataContainer.length} columns={COLUMNS}  display ={"controlAdminGroups"}></TableGrid>
    </>
  );
};

export default Table;
