import { API } from "../backend";
import axios from "axios";
import { notify } from "../../src/Common/toast";
var fileSaver = require("file-saver");


let centralDealManagementDetailsCancelToken;
export const CentralDealManagementDetails = async (payload) => {
  try {
    //Check if there are any previous pending requests
    if (typeof centralDealManagementDetailsCancelToken != typeof undefined) {
      centralDealManagementDetailsCancelToken.cancel("Operation canceled due to new request.")
    }

    //Save the cancel token for the current request
    centralDealManagementDetailsCancelToken = axios.CancelToken.source()

    const response = await axios.post(
      `${API}/store/getCentralDealManagement`,
      payload,
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const downloadCentralDealManagement = async (payload) => {
  axios.post(`${API}/store/downloadCentralDealManagement`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "central_deal_management_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const downloadSkuCentralDealManagement = async (payload) => {
  axios.post(`${API}/store/downloadSkuCentralDealManagement`, payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, "central_deal_management_details.xlsx");
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    return response;
  });
}

export const addPulishStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/addToPublishActions`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const setPublishStatus = async () => {
  try {
    const response = await axios.put(`${API}/store/updatePublishActions`);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const UpdateDealManagementCellData = async (payload) => {
  try {
    let response = await axios.put(
      `${API}/store/updateCentralDealPortal`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateCentralDealBiddingList = async (payload) => {
  try {
    let response = await axios.put(
      `${API}/store/updateCentralDealBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const AddToCentralDealBiddingList = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/vendor/createVendorBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const ViewCentralDealBiddingList = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/viewCentralDealBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const VendorBiddingList = async (payload, isExternal, isEditList) => {
  try {
    const response = await axios.post(
      isEditList
        ? `${API}/store/vendor/displayCompleteVendorBiddingList`
        : isExternal
          ? `${API}/store/customer/displayCustomerBiddingList`
          : `${API}/store/vendor/displayVendorBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateOfferSentFields = async (payload, isExternal) => {
  try {
    let response = await axios.put(
      isExternal
        ? `${API}/store/customer/updateCustomerBiddingList`
        : `${API}/store/vendor/updateVendorBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const checkFileStatus = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getTaskProfileUpdate`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const excelFileUpload = async (formData, isExternal) => {
  try {
    //console.log("formData ~~~~~~~~~~~~ excelFileUpload", formData)
    let response = await axios.post(isExternal ? `${API}/store/vendor/uploadCustomerBiddingList` : `${API}/store/vendor/uploadVendorBiddingList`, formData);
    return response;
  } catch (err) {
    return err.response;
  }
};

// export const setBiddingPublishStatus = async (payload) => {
//   try {
//     const response = await axios.put(`${API}/store/updateBiddingPublishActions`, payload);
//     return response.data;
//   } catch (err) {
//     console.log("Error: ", err);
//   }
// };

export const downloadVendorData = async (payload, isExternal) => {
  axios.post(isExternal ? `${API}/store/customer/downloadCustomerBiddingList`
    : `${API}/store/vendor/downloadVendorBiddingList`,
    payload, {
    headers: { 'Content-Type': 'application/json' },
    responseType: 'arraybuffer',
  }
  ).then((response) => {
    var blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    fileSaver.saveAs(blob, `${isExternal ? "bid_list.xlsx" : "bid_list.xlsx"}`);
    notify({
      message: `Download Completed.`,
      type: "success",
    });
    response.message = "Successfully Downloaded";
    return response;
  });
}



export const addToBiidingComments = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/addToBiddingComments`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getBiddingComments = async (payload) => {
  try {
    let response = await axios.post(`${API}/store/getBiddingComments`,payload);
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getApprovedCustomers = async (payload) => {
  try {
    let response = await axios.get(
      `${API}/store/getCustomers?status=approved`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const getCustomerApproval = async (payload) => {
  try {
    let response = await axios.post(
      `${API}/store/vendor/requestForBiddingApproval`,
      payload
    );
    return response;
  } catch (err) {
    console.log("Error: ", err);
  }
};


let centralDealManagementDetailsCancelTokenNew;
export const CentralDealManagementSKUDetails = async (payload) => {
  try {

    const response = await axios.post(
      `${API}/store/getSkuCentralDealManagement`,
      payload,
      // { cancelToken: centralDealManagementDetailsCancelTokenNew.token }
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};

export const UpdateBiddingList = async (payload) => {
  try {
    let response = await axios.post(
      `${API}/store/vendor/addSkuToVendorBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};
export const IndivisualVendorBiddingList = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/vendor/displayVendorBiddingList`,
      payload
    );
    return response.data;
  } catch (err) {
    console.log("Error: ", err);
  }
};