/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useContext } from "react";
import TypeCreate from "./TypeCreate";
import ControlAdminsGroup from "./ControlAdminsGroup";
import SearchIcon from "../../../Assets/img/search.svg";
import ClearIcon from "../../../Assets/img/icon-cancel-white.svg";
import { getUserGroups } from "../../../Services/userManagementService";
import { UserContext } from "../../Application";
import Loader from "../../../Common/loader";
const Groups = () => {
  const searchKeyword = useRef(null);
  const [searchVisible, showSearch] = useState(false);
  const [totalGroups, setTotalGroups] = useState([]);
  const [builtDemoGroups, setBuiltDemoGroups] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [mainData,setMaindata] = useState([]);
  const [updatedListData, setUpdatedListData] = useState([]);
  const [isLoading,setIsLoading]= useState(false)
  const user = useContext(UserContext);


  useEffect(() => {
    if(user.email){

    getUserGroups({
      UserEmail: user.email,
      UserRoleId: user.userRole==="SuperAdmin"?1:user.userRole==="Admin"?2:3,
      searchText: ""
    }).then(response => setMaindata(response.data))
  }
  }, [user.email]);

  useEffect(()=>{
    console.log(mainData)
    setTotalGroups(
      [...new Set(mainData
        .map(({ GroupId, GroupName ,UserRole,CreatedBy}) => ({
          GroupId,
          GroupName,          
          // UserRole,
          CreatedBy
        }))
        .filter(e => e.GroupId != null && e.GroupName !== null)
        .filter(e=>{
          if(user?.userRole==="User"){
            return(true)
          }
        else{
          return(e.CreatedBy===user.email)
        }})
        .map(JSON.stringify))
      ].map(JSON.parse))
  },[mainData])
  
  useEffect(() => {
    console.log(totalGroups)
    
    setFilteredArray(totalGroups)
  }, [totalGroups])

  const handle_set_filteredArray_after_updating = (data) => {
    //console.log("updates for specific group", data)
    //setMaindata(list)
    //setFilteredArray(list);
    let filteredArray_duplicate = [...filteredArray];
    filteredArray_duplicate.map(u => {
      if(u.GroupId === data.GroupId) {
       // let perm = JSON.stringify(data.Permission);
       // u.Permission = perm;
        u.GroupName = data.GroupName;
      }
    });
    setFilteredArray(filteredArray_duplicate);
    console.log("updates for specific group", filteredArray_duplicate);
  }

  useEffect(() => {
    console.log("filteredArray----->>>",filteredArray)
    setBuiltDemoGroups(
      (filteredArray.map((r,i) => {
        if(r.GroupName !== '') {
          return(<TypeCreate key={i} GroupId={r.GroupId} userRole={r.UserRole} createTypeLock={true} createType="Group" edit={true} name={r.GroupName} data={mainData} title="Edit this Group"  handle_set_filteredArray_after_updating={handle_set_filteredArray_after_updating} />)
        }
      }))
    )
  }, [filteredArray])


  const clearSearchClose = () => {
    searchKeyword.current.value = '';
    columnsListWrap();
    showSearch(false)
  }

  const columnsListWrap = () => {
    if (searchKeyword.current.value !== '' && searchKeyword.current.value !== undefined) {
      setFilteredArray(totalGroups.filter(e => e.GroupName.toString().toLowerCase().includes(searchKeyword.current.value.toString().toLowerCase())));
    } else {
      setFilteredArray(totalGroups);
    }
  }

  return (
    <>
      <div className="row h-100">
        <div className="col-md-12 h-100">
          <h3 className="heading mt-2">My Groups</h3>
          <p className="text-sm text-grey">Click to Edit or <a className="link" onClick={() => showSearch(true)}>Search</a></p>
          {searchVisible &&
            <>
              <div className="search-bar d-flex align-items-center">
                <span className="search-wrap-out w-100">
                  <span className="search-wrap w-100">
                    <img src={SearchIcon} className="filter-search" alt="" />
                    <input
                      type="text"
                      placeholder="Search Groups"
                      className="btn-slim search-input bg-black w-100 on-hover-dull-blue-bg"
                      ref={searchKeyword}
                    />
                    <a className="filter-clear" onClick={() => clearSearchClose()}><img src={ClearIcon} alt="" /></a>
                  </span>
                  <input
                    type="button"
                    value="Search"
                    className="btn-slim search-btn"
                    onClick={() => columnsListWrap()}
                  />
                </span>
              </div>
            </>
          }
          <div className={`list-wrap ${user.userRole==="SuperAdmin" ? 'isSuperAdmin' : ''}`}>
            <div className="list">
              {filteredArray.length?builtDemoGroups:mainData.length?<p  className="mt-5" style={{textAlign:"center"}}>No Data</p>:<Loader hideText={true} />}
            </div>
          </div>
          {user.userRole==="SuperAdmin" && <ControlAdminsGroup data={mainData}/>}
        </div>
      </div>
    </>
  );
}

export default Groups;