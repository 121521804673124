import React, { Component, useContext, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LandingPage from "../../Components/LandingPage";
import WasteRiskSummary from "../../../src/Components/InflowRiskVisibility/index";
import WasteRiskDetails from "../WasteRiskDetails";
import CentralDealManagementDetails from "../../Components/CentralDealManagement/CentralDealManagementDetails";
import DealCalendar from "../../Components/CentralDealCalender";
import InflowRiskVisibility from "../../Components/Inflow/InflowRiskVisibility";
import PomDetails from "../../Components/Pom/PomDetails";
import BiddingPortal from "../../Components/BiddingPortal";
import ComingSoon from "../../Common/ComingSoon";
import UserManagementDetails from "../../Components/UserManagement/UserManagementDetails";
import BiddingPortalInternal from "../../Components/BiddingPortalInternal";
import POMSummary from "../../Components/Pom/PomSummary/POMSummary";
import InflowSummary from "../../Components/Inflow/inflowSummary/InflowSummary";
import MainLandingPage from "../../MainLandingPage";
import MyNotifications from "../../Components/UserManagement/myNotifications/Notifications";
import EmailRedirectLink from "../../Components/CentralDealManagement/EmailRedirectLink";
import { UserCheckContext } from "../../App";
import { UserAccessContext, UserContext } from "../../Components/Application";

const AppRoutes = () => {
  const isInternalUser = useContext(UserCheckContext)
  const userAccess = useContext(UserAccessContext)
  
  const user = useContext(UserContext)
  const renderRouteOrRedirect = (path, component, condition) => {
    console.log(condition, "conditionnnnn", path)
    return condition ? (
      <Route exact path={path}>
        {component}
      </Route>
    ) : null;
  };
  const [redirected, setRedirected] = useState(false);
  
  useEffect(() => {
    if (!user) {
      // setRedirected(false);
      return;
    }
    
    // Check if the current path is the root path
    if (window.location.pathname === '/') {
      setRedirected(true);
    }
  }, [user]);

  const isSuperAdmin = (user) => {
    return user?.userRole === "SuperAdmin"
  }

  const isAdmin = (user) => {
    return user?.userRole === "Admin"
  }

  const isUser = (user) => {
    return user?.userRole === "User"
  }

  const isCustomer = (user) => {
    return user?.userRole === "Customer"
  }
  const fgSlobAccess = (userAccess) => {
    return userAccess?.fgSLOBdata?.data.toolId === "1"
  }

  const inflowAccess = (userAccess) => {
    return userAccess?.inflowdata?.data.toolId === "2"
  }

  const pomAccess = (userAccess) => {
    return userAccess?.pomdata?.data.toolId === "3"
  }

  return (
    <Switch>
      {renderRouteOrRedirect("/", <MainLandingPage />, true)}
      {renderRouteOrRedirect("/home", <LandingPage />, true)}

      {renderRouteOrRedirect("/risksummary", <WasteRiskSummary />, (isInternalUser && ((userAccess === undefined || fgSlobAccess(userAccess) && userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("1")) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/riskdetails", <WasteRiskDetails />, (isInternalUser && ((userAccess === undefined || fgSlobAccess(userAccess) && userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("2")) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/dealcalendar", <DealCalendar />, (isInternalUser && ((userAccess === undefined || fgSlobAccess(userAccess) && (userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("3") || userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("5"))) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/dealmanagement/:dealId?", <CentralDealManagementDetails />, (isInternalUser && ((userAccess === undefined || fgSlobAccess(userAccess) && (userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("4") || userAccess?.fgSLOBdata?.data.tabViewSettingId.includes("5"))) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/biddingportal", <BiddingPortal />, (userAccess === undefined || (isSuperAdmin(user) || (isCustomer(user)))))}

      {renderRouteOrRedirect("/inflow", <InflowRiskVisibility />, (isInternalUser && (userAccess === undefined || inflowAccess(userAccess) || (userAccess?.inflowdata?.data.length === undefined || isSuperAdmin(user)))))}

      {renderRouteOrRedirect("/inflowSummary", <InflowSummary />, (isInternalUser && (userAccess === undefined || inflowAccess(userAccess) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/pom", <PomDetails />, (isInternalUser && (userAccess === undefined || pomAccess(userAccess) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/pomsummary", <POMSummary />, (isInternalUser && (userAccess === undefined || pomAccess(userAccess) || isSuperAdmin(user))))}

      {renderRouteOrRedirect("/comingsoon", <ComingSoon />, true)}
      {renderRouteOrRedirect("/user-management", <UserManagementDetails />, (userAccess === undefined || isSuperAdmin(user) || isAdmin(user) || isUser(user) && isInternalUser))}
      {/* {renderRouteOrRedirect("/biddingportal-internal", <BiddingPortalInternal />, (userAccess === undefined || isInternalUser))} */}
      {renderRouteOrRedirect("/my-Notifications", <MyNotifications />, true)}
      {renderRouteOrRedirect("/biddingListLink", <EmailRedirectLink />, true)}

      <Route path="*">
        <Redirect to={{ pathname: '/', state: { error: 'no-access' } }} />
      </Route>
    </Switch>
  );
}

export default AppRoutes;
