import { DateRangeColumnFilter, DecimalToNumRound, RiskDetailsEditCell } from "./CellAction"; //CellCheckBox

export const COLUMNS = [
  {
    Header: "Deal Type",
    accessor: "dealTypeId",
    show: true,
    editable: true,
    sticky: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Material Number",
    accessor: "materialno",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "Material Description",
    accessor: "material_description",
    show: true,
    editable: false,
    sticky: true,
  },
  {
    Header: "EAN ZUN",
    accessor: "ean_zun",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
  },
  {
    Header: "Business Group",
    accessor: "business_group",
    show: true,
    editable: false,
  },
  {
    Header: "Plant-sp. matl status",
    accessor: "plants_sp_matl_status",
    show: true,
    editable: false,
  },
  {
    Header: "Plant Code",
    accessor: "plant_code",
    show: true,
    editable: false,
    // sticky: true,
  },
  {
    Header: "Country",
    accessor: "country",
    show: true,
    editable: false,
    // sticky: true,
  },
 
  

  {
    Header: "Residuals Status 26 weeks",
    accessor: "residual_status",
    show: true,
    editable: false,
  },
  {
    Header: "MCO",
    accessor: "mco",
    show: true,
    editable: false,
  },
  {
    Header: "Category",
    accessor: "category",
    show: true,
    editable: false,
  },
  {
    Header: "Brand",
    accessor: "brand",
    show: true,
    editable: false,
  },
  {
    Header: "L12 SPF GMRDR",
    accessor: "l12_spf_gmrdr",
    show: true,
    editable: false,
  },
  {
    Header: "L13 SPFV GMRDR",
    accessor: "l13_spfv_gmrdr",
    show: true,
    editable: false,
  },
  {
    Header: "No of days stock pending polaris",
    accessor: "no_of_days_stock_pending_polaris",
    show: true,
    editable: false,
  },
  {
    Header: "Standard Price per ZUN in EUR",
    accessor: "standard_price",
    show: true,
    editable: false,
    header_class: "fc-14w-data",
  },
  {
    Header: "Min Floor price per ZUN in EUR",
    accessor: "min_floor_price_per_zun_for_central_deal_in_eur",
    show: true,
    editable: false,
    header_class: "fc-14w-data",
  },
  {
    Header: "TPM file - Provisions in EUR",
    accessor: "tpm_provisions_eur",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
    header_class: "fc-14w-data",
  },
  {
    Header: "Total Stock ZUN",
    accessor: "total_stock_zun",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
    header_class: "fc-10w-data",
  },
  {
    Header: "Total Stock EUR",
    accessor: "total_stock_eur",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
    header_class: "fc-14w-data",
  },
  {
    Header: "13wk + 26wk Residual ZUN",
    accessor: "wk13_wk26_residual_zun",
    show: true,
    editable: false,
    header_class: "fc-10w-data",
  },
  {
    Header: "13wk + 26wk Residual EUR",
    accessor: "wk13_wk26_residual_eur",
    show: true,
    editable: false,
    round_off_values_no_decimal: true,
    header_class: "fc-14w-data",
  },
  {
    Header: "Blocked stock ZUN",
    accessor: "blockedStockZun",
    show: true,
    editable: false,
    integer_values: true,
    header_class: "fc-10w-data",
  },
  {
    Header: "Blocked stock EUR",
    accessor: "blockedStockEur",
    show: true,
    editable: false,
    integer_values: true,
    header_class: "fc-14w-data",
  },
  {
    Header: "13wk + 26wk Residual + Blocked stock ZUN",
    accessor: "wk13_wk26_Res_BlockedStockZun",
    show: true,
    editable: false,
    integer_values: true,
    header_class: "fc-10w-data",
  },
  {
    Header: "13wk + 26wk Residual + Blocked stock EUR",
    accessor: "wk13_wk26_Res_BlockedStockEur",
    show: true,
    editable: false,
    integer_values: true,
    header_class: "fc-14w-data",
  },
  
  // {
  //   Header:
  //     "13wk + 26wk Residual ZUNs (= data refresh at 5 days before Central Deal start time deadline)",
  //   accessor: "wk13_wk26_residual_zun_5days_before_deal_start",
  //   show: true,
  //   editable: false,
  //   round_off_values_no_decimal: true,
  //   header_class: "fc-14w-data",
  // },
  // {
  //   Header: "Delta alert in ZUN (13wk+26wk residual)",
  //   accessor: "delta_alert_in_zun",
  //   show: true,
  //   editable: false,
  //   round_off_values_no_decimal: true,
  //   header_class: "fc-14w-data",
  // },
  // {
  //   Header: "Delta alert in ZUN (Blocked Stock)",
  //   accessor: "deltaAlertInZunBlockedStock",
  //   show: true,
  //   editable: false,
  //   round_off_values_no_decimal: true,
  //   header_class: "fc-14w-data",
  // },
  // {
  //   Header: "Blocked Stock ZUNs (= data refresh at 5 days before Central Deal deadline)",
  //   accessor: "blockedStockZun_5D",
  //   show: true,
  //   editable: false,
  //   round_off_values_no_decimal: true,
  //   header_class: "fc-14w-data",
  // },
 
  { Header: "Batch code", accessor: "batch_code", show: true, editable: false },
  {
    Header: "Manufacturing date",
    accessor: "manufacturing_date",
    show: true,
    editable: false,
  },

  {
    Header: "Previous round deal type",
    accessor: "previous_month_deal_type",
    show: true,
    editable: false,
  },
  {
    Header: "Comment field",
    accessor: "comment_field",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Previous round comment field",
    accessor: "previous_month_comment_field",
    show: true,
    editable: false,
  },
  {
    Header: "Local deal completion month",
    accessor: "local_deal_completion_month",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Previous round local deal completion month",
    accessor: "previous_month_local_deal_completion_date",
    show: true,
    editable: false,
  },
  {
    Header: "Local deal quantity in ZUN",
    accessor: "local_deal_quantity_in_zun",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
    header_class: "fc-10w-data",
  },
  {
    Header: "Local deal in EUR",
    accessor: "local_deal_in_eur",
    show: true,
    editable: false,
    header_class: "fc-14w-data",
  },
  {
    Header: "Central deal quantity in ZUN",
    accessor: "central_deal_quantity_in_zun",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
    header_class: "fc-10w-data",
  },
  {
    Header: "Central deal in EUR",
    accessor: "central_deal_in_eur",
    show: true,
    editable: false,
    header_class: "fc-14w-data",
  },
  {
    Header: "Remaining quantity in ZUN",
    accessor: "remaining_quantity_in_zun",
    show: true,
    editable: false,
    header_class: "fc-10w-data",
  },
  // {
  //   Header: "Remaining stock in EUR",
  //   accessor: "remaining_stock_in_eur",
  //   show: true,
  //   editable: false,
  //   round_off_values_no_decimal: true,
  // },

  {
    Header: "Remaining quantity in EUR",
    accessor: "remaining_stock_in_eur",
    show: true,
    editable: false,
    Cell: DecimalToNumRound,
    header_class: "fc-14w-data",
    // round_off_values_no_decimal: true,
  },
  {
    Header: "Language on pack",
    accessor: "language_on_pack",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Production country name",
    accessor: "production_country_name",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Expiry date",
    accessor: "expiry_date",
    show: true,
    editable: true,
    Filter: DateRangeColumnFilter,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "Pack Type",
    accessor: "pack_type",
    show: true,
    editable: true,
    Cell: RiskDetailsEditCell,
  },
  {
    Header: "BoM",
    accessor: "bom",
    show: true,
    editable: false,
    body_class: "text-center",
    Cell: RiskDetailsEditCell,
  },

  

  // {
  //   Header: "Update date",
  //   accessor: "processedDate",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "ZUN per case",
  //   accessor: "zun_per_case2",
  //   show: true,
  // },
  // {
  //   Header: "ZUN per pallet",
  //   accessor: "zun_per_pallet2",
  //   show: true,
  // },
  {
    Header: "Version History",
    accessor: "view_history",
    show: true,
    editable: false,
    body_class: "text-center",
    Cell: RiskDetailsEditCell,
  },

];

export const columnHeaderByKey = COLUMNS.reduce((prev, curr) => {
  prev[curr.accessor] = curr.Header;
  return prev;
}, {})
