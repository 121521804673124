import { Tooltip } from "react-bootstrap";
export const PerPageRecordNumber = 100; //pagination

export const regex_empty = /^\s*$/i;
export const regex_letters_only = /^[A-Za-z\s]*$/i;
export const regex_number_only = /^\d+$/;
export const regex_email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


export const tableToolTip = (v) => {
  return <Tooltip id="tooltip" className="note" style={{maxWidth:"280px"}}>{v}</Tooltip>
}

export const pageTopElHt = (isTableContracted) => {
  let wss = document.getElementsByClassName("page-el");
  var pageTopElementHt = 0;
  for(let d of wss){
    pageTopElementHt += d.offsetHeight;
  }
  let hjg = window.innerHeight;
  let mlo = pageTopElementHt;
  return hjg - mlo;
}

export const setbiddingStatusClass = (biddingStatus) => {
  let biddingStatusText = '';
  if(biddingStatus.toLowerCase().includes("sent for customer") || biddingStatus.toLowerCase().includes("customer accepted") || biddingStatus.toLowerCase().includes("counter")) {
    biddingStatusText = 'in-progress';
  } else if(biddingStatus.toLowerCase().includes("planner accepted")) {
    biddingStatusText = 'accepted';
  } else if(biddingStatus.toLowerCase().includes("planner rejected")){
    biddingStatusText = 'rejected';
  } else if(biddingStatus.toLowerCase().includes("yet") || biddingStatus.toLowerCase().includes("awaiting") || biddingStatus.toLowerCase().includes("list approved") || biddingStatus.toLowerCase().includes("list rejected") || !biddingStatus){
    biddingStatusText = 'pending';
  }
  return biddingStatusText;
}

export const setbiddingStatusGroup = (biddingStatus) => {
  let biddingStatusText = '';
  if(biddingStatus.toLowerCase().includes("sent for customer") || biddingStatus.toLowerCase().includes("customer accepted") || biddingStatus.toLowerCase().includes("counter")) {
    biddingStatusText = 'b';
  } else if(biddingStatus.toLowerCase().includes("planner accepted")) {
    biddingStatusText = 'd';
  } else if(biddingStatus.toLowerCase().includes("planner rejected")){
    biddingStatusText = 'c';
  } else if(biddingStatus.toLowerCase().includes("yet") || biddingStatus.toLowerCase().includes("awaiting") || biddingStatus.toLowerCase().includes("list approved") || biddingStatus.toLowerCase().includes("list rejected") || !biddingStatus){
    biddingStatusText = 'a';
  }
  return biddingStatusText;
}

// ------- Comments Log functionality --------
export const getCommentsLog = (cellObj) => cellObj?.row?.original?.commentsLog?.split(".") || [];
const isWhitespaceString = str => !str.replace(/\s/g, '').length;
export const buildCommentsTooltip = (commentsLogLine) => {
  if(commentsLogLine === undefined) return [];
  return commentsLogLine.map((k,i) => {
    var t = [];
    var u = [];
    let e = k.trim();
    if(!isWhitespaceString(e)) {
        t = e.split(":");
        u = t[1].split("on");
    }   
   // return;
    return (
      <div key={i} className="comment-block">
        <div className="comment-sender-name">
          <p>{t[0]}</p> <span className="comment-date">{u[1]}:{t[2]}</span> : 
        </div>
        <div className="comment-details">
          <p>{u[0]}</p>
        </div>
      </div>
    )});
}


//Waste Risk Details Additional sorting dropdown

// { column : "", desc : ""}
export const headerDesc = [
  { column : "Selection", desc : ""},
  { column : "Plant-sp. matl status", desc : "Indicates the material status for a specific plant code (60 = ready for all uses ; 70 = intention to discontinue ; 80 = in discontinuation ; 90 = discontinued)"},
  { column : "Plant Code", desc : "This is the location where the residual is physically on stock"},
  { column : "Plant Name", desc : "This is the location where the residual is physically on stock. Plant name is linked to the plant code"},
  { column : "MCO", desc : "This is the MCO where the residual is assigned to"},
  { column : "Country Name", desc : "This is the country where the residual is assigned to"},
  { column : "(L12SPFGMRDR) SPF description", desc : "SPF = Size Pack Form"},
  { column : "(L13SPFVGMRDR) SPFV description", desc : "SPFV = Size Pack Form Variant"},
  { column : "Standard Price per ZUN in EUR", desc : "This shows the constant price at which a material is valuated without taking goods movements and invoices into account."},
  { column : "Residuals Status", desc : "We can have 4 different status types: Moving (Sold in the last 6 months, has fwd FC in the next 6 months and inventory cover < 26w demand) Slow Moving (Independent of the movement, has fwd FC in the next 6 months but cover > 26w demand) No Fwd Demand (Moved in the last 6 months but no fwd FC for the following 26 weeks) Non-Moving (No fwd FC in the next 6 months and not moved for the last 6 months)"},
  { column : "13wk + 26wk Residual ZUNs", desc : "Residuals are part of excess stock, which is covering more than 13 weeks of forward demand and exceeding MAX stock"},
  { column : "13wk + 26wk Residual ZUNs (= updated 5 days before Central Deal start time deadline)", desc : "This data column is being updated 5 days before the Central Deal Start Time deadline, to track if the residual quantity has changed or not"},
  { column : "Delta alert in ZUN", desc : "This shows the changes in residual quantity of 2 columns: '13wk +26 wk Residual ZUNs' vs '13wk + 26wk Residual ZUNs (= updated 5 days before Central Deal start time deadline)'"},
  { column : "Total Stock ZUNs", desc : "This shows the total quantity, we currently have on stock"},
  { column : "13wk + 26wk Residual EUR", desc : "This shows the residual value (= standard price multiplied with the residual quantity)"},
  { column : "Total Stock EUR", desc : "This shows the total stock value (= standard price multiplied with the total stock quantity)"},
  { column : "TPM Provisions EUR - previous month", desc : "This shows the provision value which has been submitted during the last cycle"},
  { column : "# of days residual is pending in Polaris", desc : "This is a day counter. It shows how many days the residual is visible in this tool"},
  { column : "Batch code", desc : "This shows the batch code of the production batch"},
  { column : "Manufacturing date", desc : "This shows the manufacturing date (is linked to the batchcode)"},
  { column : "Expiry Date", desc : "This shows the expiry date of the batch (is linked to the batchcode)"},
  { column : "Deal Type", desc : "This is a drop down list. Please select the option, which reflects your decision"},
  { column : "Previous round; Deal Type", desc : "This shows the decision which was made in the previous round"},
  { column : "Comment field", desc : "Feel free to add a comment for the current round cycle"},
  { column : "Previous round; Comment field", desc : "This shows the comment which was made in the previous round"},
  { column : "Local deal completion month", desc : "Please select the month when the goods will leave the MCO warehouse for the local deal (only fill in, when you have selected 'assigned to local deal' in the deal type column)"},
  { column : "Previous round ; Local Deal completion month", desc : "This shows the decision which was made in the previous round"},
  { column : "Local deal quantity in ZUNs", desc : "Please fill in the quantity which is planned for the local deal (only fill in, when you have selected 'assigned to local deal' in the deal type column)"},
  { column : "Central deal quantity in ZUNs", desc : "Please fill in the quantity which needs to be assigned to the central deal (only fill in, when you have selected 'assigned to central deal' in the deal type column)"},
  { column : "Remaining quantity in ZUN", desc : "This field shows the remaining quantity (= residual quantity - local deal quantity - central deal quantity)"},
  { column : "Local deal in EUR", desc : "This shows the local deal value (= standard price multiplied with the local deal quantity)"},
  { column : "Central deal in EUR", desc : "This shows the central deal value (= standard price multiplied with the central deal quantity)"},
  { column : "Remaining stock in EUR", desc : "This shows the remaining stock value (= standard price multiplied with the remaining quantity)"},
  { column : "Pack Type", desc : "Please review if the pack type is correct (only review, when you have selected 'assigned to central deal' in the deal type column). If the pack type is not correct, please update"},
  { column : "Language of pack", desc : "Please review if the language on pack is correct (only review, when you have selected 'assigned to central deal' in the deal type column). If not correct, please update. The data source for this data field is the ESB."},
  { column : "Production plant number", desc : "This shows the production plan number, where this product has been produced"},
  { column : "Production country name", desc : "This shows the production country, where this product has been produced"},
  { column : "Country Code", desc : "This shows the country code, where the residual is physically on stock (this field is linked to the plant code)"},
  { column : "City", desc : "This shows the city, where the residual is physically on stock (this field is linked to the plant code)"},
  { column : "Full Address", desc : "This shows the address, where the residual is physically on stock (this field is linked to the plant code)"},
  { column : "Postal Code", desc : "This shows the postal code, where the residual is physically on stock (this field is linked to the plant code)"},
  { column : "EAN ZUN", desc : "This shows the EAN code of the ZUN"},
  { column : "EAN Case", desc : "This shows the EAN code of the case"},
  { column : "ZUN per case", desc : "This shows how many ZUN we have in 1 case (CS)"},
  { column : "ZUN per pallet", desc : "This shows how many ZUN we have on 1 pallet"},
  { column : "Central deal quantity in pallets", desc : "This shows how many pallets we have assigned to central deal (based on the agreed quantity column)"},
  { column : "Agreed Central Deal quantity in ZUN", desc : "This shows the agreed central deal quantity, after negotiation with the customer (this field will be updated after the bidding portal process, or can be manually updated in case negotiation did not happen via the bidding portal)"},
  { column : "COGS in EUR", desc : "This shows the Cost Of Goods Sold (= the agreed central deal quantity multiplied with the standard price)"},
  { column : "Agreed Central Deal Price per ZUN in EUR", desc : "This shows the agreed central deal price, after negotiation with the customer (this field will be updated after the bidding portal process, or can be manually updated in case negotiation did not happen via the bidding portal)"},
  { column : "Sales Value EUR", desc : "This shows the sales value (agreed central deal quantity multiplied with the agreed price)"},
  { column : "Loss Value EUR", desc : "This shows the loss value (sales value - COGS)"},
  { column : "Margin%", desc : "This shows the margin % (sales value - COGS / sales value)"},
  { column : "Net COGS (removing prov.) EUR", desc : "This shows the net COGS (COGS - provision value)"},
  { column : "Net Margin%", desc : "This shows the net margin % (sales value - net COGS / sales value)"},
  { column : "SPF code (needed for the Floor Price)", desc : "This shows the Size Pack Form code"},
  { column : "Floor price per ZUN for Central Deal in EUR", desc : "This shows the Floor Price per ZUN"},
  { column : "% Variance to Floor Price", desc : "This shows the % variance (agreed central deal price - floor price / agreed central deal price)"},
  { column : "TP exworks DC, per ZUN", desc : "This shows the transfer price (exworks DC)"},
  { column : "Quantity available in ZUN", desc : "This shows the quantity available in ZUN"},
  { column : "Bid quantity in ZUN", desc : "Please fill in the quantity you want to buy"},
  { column : "Bid price per ZUN", desc : "Please fill in the price you want to offer"},
  { column : "Country code (where stock is located)", desc : "This shows the country code, where the stock is physically located"},
  { column : "Quantity available in pallets", desc : "This shows the quantity available, converted into # of pallets"},
  { column : "Language on pack", desc : "This shows the languages which are available on pack"},
  { column : "Pack type (including BoM list)", desc : "This shows the pack type in case it's not a standard pack type"},
  { column : "BoMs", desc : "This shows the components in the BoM, in case the pack type is not a standard pack type"},
];
// { column : "", desc : ""},
//KPIs in POM tool
// export const headerDescPOM = [
// { column : "MRDR description", desc :"Material description"},
// { column : "MRDR", desc :"Material code"},
// { column : "Plant code", desc :"Location (code) where this product is stored"},
// { column : "MRP controller", desc :"The group name, where this product is allocated to. This is used by the planning department in SAP "},
// { column : "MRP description", desc :"Description of the group name, where this product is allocated to. This is used by the planning department in SAP "},
// { column : "Best before date", desc :"Date at which the stock will expire and when it will be no longer possible to sell the stock"},
// { column : "Short shelf life date", desc :" Latest date at which we can sell the stock in a regular way to our customers, based on agreed rules. On this date the stock will be blocked in the warehouse"},
// { column : "Cost Price", desc :"The standard price"},
// { column : "#CUs in 1 case", desc :"The number of consumer units in 1  case"},
// { column : "Status ID", desc :"The reason code assigned to the blocked stock by the warehouse. This data comes from the Stock Freshness Report in SAP."},
// { column : "Status ID Description", desc :"16 = Customer Return Damage, 21 = Passed Short Shelf Life Date, 22 = Passed Expiry Date, 23 = Damage within DC, 24 = Damage at Receipt, 25 = Damage because of Transport, 26 = Obsolete Stock, 31 = Sent without Order, 40 = On order of the client (Unilever), 42 = Return Customer, 43 = Damaged During Repack"},
// { column : "Stock quantity", desc :"The total stock available in the warehouse"},
// { column : "Quantity available for sales offer", desc :"The quantity which can be offered to sales. Calculation logic: the first time a product appears in the tool, the quantity in this field will be equal to the obsoletes quantity. This is an editable field, and needs to be manually updated/reviewed on a regular basis by the planning team. During the weekly refresh, there is an automatic check in place (If the stock quantity is lower than the amount in this field, then it will be automatically overwritten to make it equal to the stock quantity. As this quantity can never exceed the total stock available in the warehouse)"},
// { column : "Quantity left for sales offer", desc :"Formula = Quantity available for sales offer - Quantity reserved for sales offer"},
// { column : "Quantity reserved for sales offer", desc :"The quantity reserved for a sales offer in the POM tool (= the sum of quantity of deals with status Plan and Confirmed)"},
// { column : "Category", desc :"Small C"},
// { column : "Country", desc :""},
// { column : "Group", desc :"This shows the L6 in the vanguard hierarchy"},
// { column : "Size Pack Form", desc :"This shows the L12 in the vanguard hierarchy"},
// { column : "Plant material status", desc :"60 = Active status, 70 = Intention to discontinue, 80 = In discontination 90 = Non active status"},
// { column : "Obsoletes quantity in CU", desc :"Obsoletes quantity in consumer units"},
// { column : "Obsoletes quantity", desc :"For blocked stock, this shows the blocked stock quantity. For unrestricted stock, when SSL data is available, it shows the stock which cannot be consumed by the forecast before the SSL date. When there is no SSL data available, it shows the stock remaining after 13 weeks of forecast."},
// { column : "Obsoletes quantity (in value)", desc :"Formula = Obsoletes quantity x Cost price"},
// { column : "Alert type", desc :"The stock status (blocked, non moving, no forward demand, no risk)"},
// { column : "#Cases on 1 layer", desc :"The number of cases on 1 layer"},
// { column : "#Layers on 1 pallet", desc :"The number of layers on 1 pallet"},
// { column : "Pallet type", desc :"The pallet type (800 = Euro pallet and 1000  = Industrial pallet)"},
// { column : "#Cases on 1 pallet", desc :"The number of cases on 1 pallet"},
// { column : "EAN code CU", desc :"The EAN code of the consumer unit"},
// { column : "EAN code case", desc :"The EAN code of the case"},
// { column : "EAN code pallet", desc :"The EAN code of the pallet"},
// { column : "Batch Code Details", desc :"This shows all the batch code numbers, we have for the obsoletes stock"},
// { column : "Number of Batches", desc :"This shows the total amount of batches, we have for the obsoletes stock"},
// { column : "Delta Obsolete Quantity", desc :"The obsoletes quantity in the POM tool, based on last week's snapshot"},
// { column : "Delta 4wk", desc :"The obsoletes quantity in the POM tool, based on the snapshot from 4 weeks ago"},
// { column : "Country of Ownership", desc :"This KPI is applicable for countries who share the same warehouse. Formula: (country FC of previous 52 weeks - actual sales of past 52 weeks) + future forecast (52 weeks). Compare this value between the countries who sell the same MRDR code from the same warehouse. The country with the highest value is assigned as the country of ownership"},
// { column : "# of Pallets", desc :"The amount of pallets. For Nutrition and Ice Cream the formula is: Quantity available for sales offer/Cases on 1 pallet. For HC, PC and B&W the formula is: (Quantity available for sales offer/#Cases on 1 pallet)/#CUs in 1 case"},
// { column : "BBD Expiry", desc :"The formula is: (number of days between “best before date” and “today’s date”) / total shelf life in days"},
// { column : "ISL Expiry", desc :"The formula is: (number of days between “short shelf life date” and “today’s date”) / total shelf life in days"},
// { column : "BBD Percentage At Last Sale Date", desc :"The formula is: (number of days between “best before date” and “batch last sales date”) / total shelf life in days. In this formula the 'batch last sales date' = the last date when the stock of the specific batch, will be fully consumed by the forecast"},
// { column : "TPM_Provision EUR", desc :"The provision value in the TPM file"},
// { column : "Fictive SSL Date", desc :"Checks if the Short Shelf Life date is present in the Stock Freshness Report. If there is no SSL date available in this report, then a fictive date will be applied in the SSL date column (today's date + 26 weeks = fictive SSL date)"},
// { column : "Food Solution Indicator", desc :"Indicates if this product belongs to Foodsolutions"},
// { column : "Communication Code", desc :"The communication code is mostly used by customers, whose are placing orders using communication code instead of EAN or MRDR."},
// { column : "Forecast Family Code", desc :"The Forecast Family is a group of Forecast Units (GTIN codes)"},
// { column : "Timestamp (date added to POM)", desc :"The date when the product is added to the POM tool"},
// { column : "Add/View Customer", desc :"Button to be clicked on, when you want to fill in the customer information, needed to reserve stock for a sales deal"},
// { column : "Version history", desc :"Shows the version history (to check who edited and uploaded the data to the tool)"},
// { column : "Owner", desc :"The owner of the action. The owner will mitigate the obsoletes risk"},
// { column : "Next Step", desc :"Shows the mitigation action, to liquidate the obsoletes stock. This is a dropdown list. You can choose: destroy, offer2sales, offer2charity, offer2trader, check4risk, export and rework"},
// { column : "Operations note", desc :"This is a comment field, to be maintained by the planning team"},
// { column : "Advice", desc :"This is the first proposal of the mitigation action, to be maintained by the planning team"},
// { column : "Sales note", desc :"This is a comment field, to be maintained by the CSP / CBD team"},
// { column : "Consumer Advise Price", desc :""},
// { column : "NIV", desc :""},
// { column : "Floor Price", desc :""},
// { column : "Brand", desc :""},
// { column : "Residual Nordics", desc :""},
// { column : "Discount", desc :""},
// { column : "Price/CU", desc :""},
// { column : "Price/Case", desc :""},


//   // { column : "Plant code", desc : "Plant at which this product is stored."},
//   // { column : "Plant material status", desc : "60 = In production, 70 = intention to discontinue, 80  = in discontination, stock still in SU or DC raw and pack cannot be ordered anymore, 90  = Non active"},
//   // { column : "Status ID", desc : "21 = Passed Short Shelf Life Date, 22 = Passed Expiry Date, 23 = Damage within DC, 24 = Damage at Receipt, 25 = Damage because of Transport, 26 = Obsolete Stock, 31 = Sent without Order, 40 = On order of the client (Unilever), 42 = Return Customer, 16 = Customer Return Damage, 43 = Damaged During Repack"},
//   // { column : "Short shelf life date", desc : "Latest date at which we can sell the stock into our customers. Will always be before the 'best before date'. \n3 months: normal red \n2 months: dark red \n1 month: thick red"},
//   // { column : "Best before date", desc : "Date at which the stock will expire and we will no longer be able to sell it. \n3 months: normal red \n2 months: dark red \n1 month: thick red"},
//   // { column : "Value - Obsoletes quantity", desc : "Formula = Obsolete Quantity x cost price"},
//   // { column : "Quantity available for sales offer (in value)", desc : "Formula = Quantity to offer x cost price"},
//   // { column : "Alert type", desc : "The stocks reason code."},
//   // { column : "Residual Qty Nordics", desc : "Over stocked Qty + Obsolete Quantity"}
// ];
export const headerDescPOM =
  [
    {column: "MRDR description", desc: "Material description"},
    {column: "MRDR", desc: "Material code"},
    {column: "Plant code", desc: "Location (code) where this product is kept on stock"},
    {column: "MRP controller", desc: "The group code name, where this product is allocated to. This is used by the planning department and is maintained in SAP in MM03 in the MRP 1 sheet"},
    {column: "MRP description", desc: "Description of the group name, linked to the MRP controller"},
    {column: "Best before date", desc: "Expiry date. This date is retrieved from SAP, from transaction Z2WMS1260. This transaction shows stock snapshot data, which is sent by the warehouse to SAP via EDI"},
    {column: "Short shelf life date", desc: "Latest date at which we can sell the stock in a regular way to our customers, based on agreed terms. On this date the stock will be blocked in the warehouse. This date is retrieved from SAP, from transaction Z2WMS1260."},
    {column: "Cost Price", desc: "The standard price. This is maintained in SAP in MM03 in the Costing 2 sheet"},
    {column: "#CUs in 1 case", desc: "The amount of consumer units in 1 case"},
    {column: "Status ID", desc: "This shows the reason code, in case the stock is blocked in the warehouse. This data is retrieved from SAP, from transaction Z2WMS1260. This transaction shows stock snapshot data, which is sent by the warehouse to SAP via EDI"},
    {column: "Status ID Description", desc: "Description of the status ID. 16 = Customer Return Damage, 21 = Passed Short Shelf Life Date, 22 = Passed Expiry Date, 23 = Damage within DC, 24 = Damage at Receipt, 25 = Damage because of Transport, 26 = Obsolete Stock, 31 = Sent without Order, 40 = On order of the client (Unilever), 42 = Return Customer, 43 = Damaged During Repack"},
    {column: "Stock quantity", desc: "The total quantity on stock in the warehouse"},
    {column: "Quantity available for sales offer", desc: "The quantity which can be offered to sales. Please maintain this field, when you have as next step 'offer2sales or 'offer2trader'. Calculation logic: The first time a product appears in the tool, the quantity in this field will be equal to the obsoletes quantity. This is an editable field, and needs to be updated/reviewed by the planning team on a weekly basis. During the weekly POM data refresh, there is an automatic check in place (If the stock quantity is lower than the amount in this field, then it will be automatically overwritten to make it equal to the stock quantity. We have this logic in place, because the quantity can never exceed the total stock available in the warehouse)"},
    {column: "Quantity left for sales offer", desc: "Formula: Quantity available for sales offer - Quantity reserved for sales offer"},
    {column: "Quantity reserved for sales offer (=sum of quantity of deals with status Plan, Confirmed)", desc: "The quantity reserved for a sales offer in the POM tool. Formula: the sum of quantity assigned to deals with status Plan and Confirmed, via the button 'add customers'."},
    {column: "Category", desc: "Small C"},
    {column: "Country", desc: "Country"},
    {column: "Group", desc: "This shows the L6 in the vanguard hierarchy"},
    {column: "Size Pack Form", desc: "This shows the L12 in the vanguard hierarchy"},
    {column: "Plant material status", desc: "60 = Active status, 70 = Intention to discontinue, 80 = In discontination 90 = Non active status"},
    {column: "Obsoletes quantity in CU", desc: "Obsoletes quantity in consumer units"},
    {column: "Obsoletes quantity", desc: "For blocked stock, this shows the blocked stock quantity. For unrestricted stock (for Nutrition and Ice Cream) when SSL data is available, it shows the stock which cannot be consumed by the forecast before the SSL date. For unrestricted stock (for HC, PC and B&W) there is no SSL data available, therefore it shows the stock remaining after 13 weeks of forecast."},
    {column: "Obsoletes quantity in value", desc: "Formula: Obsoletes quantity x Cost price"},
    {column: "Alert type", desc: "The stock status (blocked, non moving, no forward demand, no risk). Please be aware 'non moving' and 'no forward demand' do not follow the official calculation logic, which is in Aera"},
    {column: "#Cases on 1 layer", desc: "The number of cases on 1 layer"},
    {column: "#Layers on 1 pallet", desc: "The number of layers on 1 pallet"},
    {column: "Pallet type", desc: "The pallet type (800 = Euro pallet and 1000 = Industrial pallet)"},
    {column: "#Cases on 1 pallet", desc: "The number of cases on 1 pallet"},
    {column: "EAN code CU", desc: "The EAN code of the consumer unit"},
    {column: "EAN code case", desc: "The EAN code of the case"},
    {column: "EAN code pallet", desc: "The EAN code of the pallet"},
    {column: "Batch Code Details", desc: "This shows all the batch code numbers, available for the obsoletes stock"},
    {column: "Number of Batches", desc: "This shows the total amount of batches, available for the obsoletes stock"},
    {column: "Delta Obsolete Quantity", desc: "The difference in obsoletes quantity in the POM tool versus last week's snapshot"},
    {column: "Delta 4wk", desc: "The difference in obsoletes quantity in the POM tool, versus the snapshot from 4 weeks ago"},
    {column: "Country of Ownership", desc: "This KPI is applicable for countries who share the same warehouse. Formula: (country FC of previous 52 weeks - actual sales of past 52 weeks) + future forecast (52 weeks). Compare this value between the countries who sell the same MRDR code from the same warehouse. The country with the highest value is assigned as the country of ownership"},
    {column: "Consumer Advise Price", desc: "CSP can fill in the consumer advise price"},
    {column: "NIV", desc: "CSP can fill in the NIV"},
    {column: "Floor Price", desc: "CSP can fill in the floor price"},
    {column: "Brand", desc: "Brand"},
    {column: "Residual Qty Nordics", desc: "This field is specifically built for the Nordics team"},
    {column: "Discount", desc: "CSP can fill in the discount"},
    {column: "Price/CU", desc: "CSP can fill in the price needed per consumer unit"},
    {column: "Price/Case", desc: "CSP can fill in the price needed per case"},
    {column: "Reporting Family", desc: "The Reporting Family is a group of Forecast Units (GTIN codes), and is optional to be used by planning. This data is retrieved from Kinaxis DP"},
    {column: "SSL % Remaining", desc: "Formula: (number of days between “short shelf life date” and “today’s date”) / total shelf life in days"},
    {column: "BBD % Remaining", desc: "Formula: (number of days between “best before date” and “today’s date”) / total shelf life in days"},
    {column: "Business Group", desc: "Business Group"},
    {column: "# of Pallets", desc: "The amount of pallets. For Nutrition and Ice Cream the formula is: Quantity available for sales offer/Cases on 1 pallet. For HC, PC and B&W the formula is: (Quantity available for sales offer/#Cases on 1 pallet)/#CUs in 1 case"},
    {column: "BBD Percentage At Last Sale Date", desc: "Formula: (number of days between “best before date” and “batch last sales date”) / total shelf life in days. In this formula the 'batch last sales date' = the last date when the stock of the specific batch, will be fully consumed by the forecast"},
    {column: "TPM Provision EUR", desc: "The provision value in the TPM file. "},
    {column: "Fictive SSL Date", desc: "Checks if the Short Shelf Life date is present in SAP in transaction Z2WMS1260. If there is no SSL date available in this report, then a fictive date will be applied. For Nutrition and Ice Cream this will be (today's date + 26 weeks). For HC, PC and B&W this will be (today's date + 13 weeks)"},
    {column: "Food Solution Indicator", desc: "Indicates if this product belongs to Foodsolutions"},
    {column: "Communication Code", desc: "The communication code is mostly used by customers, whose are placing orders using the communication code instead of EAN or MRDR. This data is retrieved from SAP, in MM03 in the classification sheet"},
    {column: "Forecast Family Code", desc: "The Forecast Family is a group of Forecast Units (GTIN codes). This data is retrieved from Kinaxis DP"},
    {column: "Timestamp (date added to POM)", desc: "The date when the product is added to the POM tool"},
    {column: "Add/View Customer", desc: "Button to be clicked on, when you want to fill in the customer information, needed to reserve stock for a sales deal"},
    {column: "Version history", desc: "Shows the version history (to check who edited and uploaded the data to the tool)"},
    {column: "Next Step", desc: "Shows all the mitigation actions, available to liquidate the obsoletes stock. This is a dropdown list. "},
    {column: "Owner", desc: "The owner of the action. The owner will mitigate the obsoletes risk"},
    {column: "Operations note", desc: "This is a comment field, to be maintained by the planning team"},
    {column: "Advice", desc: "This field is optional, you can use it in case you want to assign a 2nd mitigation action (besides the 'next step' column) "},
    {column: "Sales note", desc: "This is a comment field, to be maintained by the CSP / CBD team"},

    
]


// export const headerDescInflow = [
//   { column : "BG", desc : "BG of the finished goods"},
//   { column : "Category", desc : "Category of the finished goods"},
//   { column : "Product Code", desc : "ID of the finished goods"},
//   { column : "Brand", desc : "Brand of the product"},
//   { column : "Description", desc : "Description of the product"},
//   { column : "Risk Type", desc : "Rootcause of the risk highlighted"},
//   { column : "Risk Level", desc : "Size if the risk present"},
//   { column : "Stock Today", desc : "Available volume on hand"},
//   { column : "Expected Inbounds", desc : "Planned intakes"},
//   { column : "Expected Outbounds", desc : "Expected sales based upon forecast"},
//   { column : "Total Risk", desc : "Total volume expected to be left in the WareHouse before arriving to ISL"},
//   { column : "Max DoH", desc : "Highest days on hand coverage of the period"},
//   { column : "ISL", desc : "Internal shelf life, also known as SSL"},
//   { column : "Actuals L4W", desc : "Sum of previous 4 weeks' sales"},
//   { column : "FC L4W", desc : "Sum of previous 4 weeks' forecast"},
//   { column : "ABS ERR L4W", desc : "Sum of previous 4 weeks' absolute error"},
//   { column : "FB% L4W", desc : "Forecast Bias of previous 4 weeks'"},
//   { column : "FC Next 4W", desc : "Sum of next 4 weeks' forecast"},
//   { column : "FC Diagnostics L4W", desc : "Displays the bias polarity"},
//   { column : "FC Key Drivers L4W", desc : "Displays the kay figure, which drives the performance"},
//   { column : "FB % L12weeks", desc : "Forecast Bias of previous 12 weeks'"},
//   { column : "FC Bias Level L4W", desc : "Bias comparison with the target"}
// ]
export const headerDescInflow =[
  { column: "MRDR", desc: "Material code" },
  { column: "MRDR Description", desc: "Material description" },
  { column: "Brand", desc: "Brand" },
  { column: "Plant Code", desc: "Location (code) where this product is kept on stock" },
  { column: "Country", desc: "Country" },
  { column: "Category", desc: "Small C category" },
  { column: "Business Group", desc: "Business Group" },
  { column: "Risk Type", desc: "Inventory buildup: Inbounds next 13 weeks > Outbounds next 13 weeks, Slow moving: Stock Today > Inbounds + Outbounds, Non-moving: Outbounds = 0, Moving: All else" },
  { column: "DOH vs ISL Risk Level", desc: "High: DoH > (ISL * 75%), Medium: DoH between (ISL * 50%) and (ISL * 75%), Low: DoH < (ISL * 50%), If there is no ISL data then: High: DoH > 182 DoH, Medium: DoH between 91 and 182 DoH, Low: DoH < 91 DoH" },
  { column: "Stock Today", desc: "Available stock (unrestricted stock)" },
  { column: "Expected Inbounds_PO", desc: "POs (inbounds) in the system during the next 13 weeks" },
  { column: "Expected Inbounds_PurRqs", desc: "PurRqs (planned inbounds) in the system during the next 13 weeks" },
  { column: "Expected Inbounds", desc: "The sum of Expected Inbounds (POs) + Expected Inbounds (PurRqs)" },
  { column: "Expected Outbounds", desc: "Expected total demand for the comings 13 weeks" },
  { column: "Stock Remaining", desc: "Formula: Stock Today + Expected Inbounds - Expected Outbounds" },
  { column: "Max DoH", desc: "Highest days on hand coverage during the next 13 weeks" },
  { column: "ISL", desc: "Internal shelf life in days (= Total shelf life in days - External shelf life in days). Data comes from in SAP, from MM03 and from the classification table" },
  { column: "Actuals L4W", desc: "Sum of previous 4 weeks sales" },
  { column: "FC L4W", desc: "Sum of previous 4 weeks forecast" },
  { column: "ABS ERR L4W", desc: "Sum of previous 4 weeks absolute error" },
  { column: "FC Next 4W", desc: "Sum of next 4 weeks forecast" },
  { column: "Bias%_L4W", desc: "The forecast bias in % of the previous 4 weeks" },
  { column: "Forecast Bias L4W", desc: "High negative bias: below -30%, Negative bias: between -30% and -5%, Within range: between -5% and +5%, Positive bias: between +5% and +30%, High positive bias: above +30%" },
  { column: "Actuals L12W", desc: "Sum of previous 12 weeks sales" },
  { column: "FC L12W", desc: "Sum of previous 12 weeks forecast" },
  { column: "ABS ERR L12W", desc: "Sum of previous 12 weeks absolute error" },
  { column: "Bias%_L12W", desc: "The forecast bias in % of the previous 12 weeks" },
  { column: "Forecast Bias L12W", desc: "High negative bias: below -30%, Negative bias: between -30% and -5%, Within range: between -5% and +5%, Positive bias: between +5% and +30%, High positive bias: above +30%" },
  { column: "Comment Waste Lead", desc: "Optional field to fill in for the waste lead in the market" },
  { column: "Owner Short Term planner", desc: "The name of the short term planner, who is assigned to take an action on this material code" },
  { column: "Comment Short Term Planner", desc: "Optional field to fill in for the short term planner" },
  { column: "FC adjustment needed?", desc: "Field to be filled in by the demand planner" },
  { column: "Owner Demand Planner", desc: "The name of the demand planner, who is assigned to take an action on this material code" },
  { column: "Comment Demand Planner", desc: "Optional field to fill in for the demand planner" },
  { column: "Action Type", desc: "Field to be filled in by the short term planner. This is a dropdown list of 7 items." },
  { column: "Forecast Family", desc: "The Forecast Family is a group of Forecast Units (GTIN codes). This data is retrieved from Kinaxis DP" },
  { column: "Food Solution Indicator", desc: "Indicates if this product belongs to Foodsolutions" },
  { column: "EAN_CS", desc: "The EAN code of the case" },
  { column: "MOQ", desc: "Minimum Order Quantity at the sourcing unit / supplier" },
  { column: "Update Date", desc: "The date when the data in the tool is refreshed" }
]

export const WasteRiskDetailsSortingDropdown = [
  {
    label: "Days Remaining",
    value: "",
  },
  {
    label: "Bock Type",
    value: "",
  },
  {
    label: "Inventory (Cases)",
    value: "",
  },
  {
    label: "Forecast vs Inventory",
    value: "",
  },
  {
    label: "Expected Waste (Cases)",
    value: "",
  },
  {
    label: "Expected Waste (R)",
    value: "",
  },
  {
    label: "Dispatch Rate",
    value: "",
  },
];
