import { API } from "../backend";
import axios from "axios";

export const PomNextStepVsAbsQtySummary = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getPomNextStepVsAbsQtySummary`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// export const getPomStatusIdDesVsAbsQtySummary = async (payload) => {
//     try {
//       const response = await axios.post(
//         `${API}/store/getPomStatusIdDesVsAbsQtySummary`,
//         payload
//       );
//       if (response.status === 200) return response.data;
//     } catch (error) {
//       console.log("Error", error);
//     }
//   };
export const getObsoletesSalesMigrationSummary = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getObsoletesSalesMigrationSummary`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};
export const getPomDealStatusSummary = async (payload) => {
  try {
    const response = await axios.post(
      `${API}/store/getPomDealStatusSummary`,
      payload
    );
    if (response.status === 200) return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

  export const getPomPastSSLVsAbsQtySummary = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getPomPastSSLVsAbsQtySummary`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  export const getPomComingSSLVsAbsQtySummary = async (payload) => {
    try {
      const response = await axios.post(
        `${API}/store/getPomComingSSLVsAbsQtySummary`,
        payload
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };