import { ImagePlaceHolder } from "../../BiddingPortal/BiddingPortalTable/CellAction";
import { EditCell, CustomerEditCell,VendorReceivedEditCell } from "./CellAction";

export const COLUMNS = [
  {
    Header: "SKU/Description",
    accessor: "material_description",
    show: true,
    editable: false,
    isBoth: true,
    Cell: ImagePlaceHolder,
    sticky:true
  },
  {
    Header: "Material Number",
    accessor: "materialno",
    show: true,
    editable: false,
    isBoth: true,
    sticky:true,
  },
  {
    Header: "Qty Available",
    accessor: "vendor_bid_quantity",
    show: true,
    sticky:true,
    // editable: true,
    isInternal: true,
    Cell: (cellObj) => {
      return (
        <span
          className={`text-white border-radius-std ${
             cellObj.value
          }`}
          title={`${cellObj.value}`}
        >
          {cellObj.value}
        </span>
      );
    },
  },
  {
    Header: "Qty to Offer for bid",
    accessor: "quantity_to_offer_bid",
    show: true,
     editable: true,
    isInternal: true,
     Cell: EditCell,
  },
  {
    Header: "Bid Start price",
    accessor: "vendor_bid_price",
    show: true,
    editable: true,
    isInternal: true,
    Cell: EditCell,
  },
  {
    Header: "Minimum Acceptance price",
    accessor: "minimum_acceptance_price",
    show: true,
    editable: true,
    isInternal: true,
    Cell: EditCell,
  },
  {
    Header: "Comments",
    accessor: "vendorComments",
    show: true,
    editable: true,
    isInternal: true,
    Cell: EditCell,
  },
  {
    Header: "Stock Location",
    accessor: "country",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Production Location",
    accessor: "production_country_name",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN ZUN",
    accessor: "ean_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN CARTON",
    accessor: "ean_carton",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per case",
    accessor: "zun_per_case",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per pallet",
    accessor: "zun_per_pallet",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Deal pallets ZUN",
    accessor: "deal_pallet_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Language on Pack",
    accessor: "language_on_pack",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Pack type",
    accessor: "pack_type",
    show: true,
    editable: false,
    isBoth: true,
  },
  // {
  //   Header: "Agreed Quantity in ZUN",
  //   accessor: "agreed_quantity_in_zun",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "COGS (Cost of Goods Sold) EUR",
  //   accessor: "cogs",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Agreed Price per ZUN in EUR",
  //   accessor: "price_per_zun_in_eur",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Sales Value EUR",
  //   accessor: "sales_value_eur",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Loss Value EUR",
  //   accessor: "loss_value_eur",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Margin%",
  //   accessor: "margin_percentage",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Net COGS (removing prov.) EUR",
  //   accessor: "net_cogs_removing_prov_eur",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Net Margin%",
  //   accessor: "net_margin_percentage",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  {
    Header: "SPF code (needed for the Floor Price)",
    accessor: "spf_code",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Min Floor price per ZUN for Central Deal in EUR (numbers only)",
    accessor: "min_floor_price_per_zun_for_central_deal_in_eur",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "TP exworks DC",
    accessor: "tp_exworks_dc",
    show: true,
    editable: false,
    isBoth: true,
  },
  // {
  //   Header: "Production country name",
  //   accessor: "Pack Type",
  //   show: true,
  //   editable: true,
  //   Cell: EditCell,
  // },
];

export const CUSTCOLUMNS = [
  {
    Header: "SKU/Description",
    accessor: "material_description",
    show: true,
    editable: false,
    isBoth: true,
    Cell: ImagePlaceHolder,
    sticky:true
  },
  // {
  //   Header: "Qty Available",
  //   accessor: "vendor_bid_quantity",
  //   show: true,
  //   // editable: true,
  //   isInternal: true,
  //   sticky:true
  // },
  {
    Header: "Qty to Offer for bid",
    accessor: "quantity_to_offer_bid",
    show: true,
  },
  {
    Header: "Bid Start price",
    accessor: "vendor_bid_price",
    show: true,
    // editable: true,
    isInternal: true,
  },
  // {
  //   Header: "Minimum Acceptance price",
  //   accessor: "minimum_acceptance_price",
  //   show: true,
  //   editable: false,
  // },
  // {
  //   Header: "Comments",
  //   accessor: "customerComments",
  //   show: true,
  //   editable: true,
  //   isInternal: true,
  //   Cell: EditCell,
  // },
  {
    Header: "Bid Qty",
    accessor: "customer_bid_quantity",
    show: true,
    editable: true,
    
     Cell: CustomerEditCell,
  },
  {
    Header: "Offer price per CU in Euro",
    accessor: "customer_bid_price",
    show: true,
    editable: true,
    Cell: CustomerEditCell,
  },
  {
    Header: "Stock Location",
    accessor: "country",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Production Location",
    accessor: "production_country_name",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN ZUN",
    accessor: "ean_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN CARTON",
    accessor: "ean_carton",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per case",
    accessor: "zun_per_case",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per pallet",
    accessor: "zun_per_pallet",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Deal pallets ZUN",
    accessor: "deal_pallet_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Language on Pack",
    accessor: "language_on_pack",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Comments Log",
    accessor: "commentsLog",
    show: true,
    editable: false,
    isBoth: true,
    Cell: CustomerEditCell
  },
  {
    Header: "Comments",
    accessor: "customerComments",
    show: true,
    editable: true,
    Cell: CustomerEditCell
    // Cell: EditCell,
    //
  },
  {
    Header: "BoM",
    accessor: "bom",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Plant Code",
    accessor: "plant_code",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Pack Type",
    accessor: "pack_type",
    show: true,
    editable: false,
    isBoth: true,
  }
  // {
  //   Header: "Production country name",
  //   accessor: "Pack Type",
  //   show: true,
  //   editable: true,
  //   Cell: EditCell,
  // },
];

export const VENDORRECIEVDCOLUMNS = [
  {
    Header: "SKU/Description",
    accessor: "material_description",
    show: true,
    editable: false,
    isBoth: true,
    sticky:true,
    Cell: ImagePlaceHolder,
  },
  {
    Header: "Material Number",
    accessor: "materialno",
    show: true,
    editable: false,
    isBoth: true,
    sticky:true,
  },
  {
    Header: "Qty Available",
    accessor: "vendor_bid_quantity",
    show: true,
    // editable: true,
    isInternal: true,
    sticky:true
  },
  {
    Header: "Qty to Offer for bid",
    accessor: "quantity_to_offer_bid",
    show: true,
     editable: true,
    isInternal: true,
     Cell: CustomerEditCell,
  },
  {
    Header: "Bid Start price",
    accessor: "vendor_bid_price",
    show: true,
    editable: true,
    isInternal: true,
    Cell: CustomerEditCell,
  },
  {
    Header: "Bid Qty",
    accessor: "customer_bid_quantity",
    show: true,
    editable: true,
    isInternal: true,
  },
  {
    Header: "Customer offer price",
    accessor: "customer_bid_price",
    show: true,
    editable: true,
    isInternal: true,
  },
  {
    Header: "Minimum Acceptance price",
    accessor: "minimum_acceptance_price",
    show: true,
    editable: true,
    isInternal: true,
  },
  {
    Header: "Comments",
    accessor: "vendorComments",
    show: true,
    editable: true,
    isInternal: true,
    Cell: CustomerEditCell,
   
  },
  {
    Header: "Comments Log",
    accessor: "commentsLog",
    show: true,
    editable: false,
    isBoth: true,
    Cell: CustomerEditCell,
  },
  {
    Header: "Stock Location",
    accessor: "country",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Production Location",
    accessor: "production_country_name",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN ZUN",
    accessor: "ean_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "EAN CARTON",
    accessor: "ean_carton",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per case",
    accessor: "zun_per_case",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "ZUN per pallet",
    accessor: "zun_per_pallet",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Deal pallets ZUN",
    accessor: "deal_pallet_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Language on Pack",
    accessor: "language_on_pack",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Agreed Quantity in ZUN",
    accessor: "agreed_quantity_in_zun",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "COGS (Cost of Goods Sold) EUR",
    accessor: "cogs",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Agreed Price per ZUN in EUR",
    accessor: "price_per_zun_in_eur",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Sales Value EUR",
    accessor: "sales_value_eur",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Loss Value EUR",
    accessor: "loss_value_eur",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Margin%",
    accessor: "margin_percentage",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Net COGS (removing prov.) EUR",
    accessor: "net_cogs_removing_prov_eur",
    show: true,
    editable: false,
    isBoth: true,
  },
  {
    Header: "Net Margin%",
    accessor: "net_margin_percentage",
    show: true,
    editable: false,
    isBoth: true,
  },
  // {
  //   Header: "SPF code (needed for the Floor Price)",
  //   accessor: "spf_code",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "Min Floor price per ZUN for Central Deal in EUR (numbers only)",
  //   accessor: "min_floor_price_per_zun_for_central_deal_in_eur",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },
  // {
  //   Header: "TP exworks DC",
  //   accessor: "tp_exworks_dc",
  //   show: true,
  //   editable: false,
  //   isBoth: true,
  // },

];
