import React from "react";
import DealManagementListTable from "./DealManagementListTable";
import ListTable from "./ListTable";

const DisplayListPopupBody = (props) => {
  /* const handleWindowClose = () => {
    props.onHide();
    props.setInputName("");
    props.setInputComment("");
  }; */

  return (
    <>
      <div className="">
        {props.from === "dealmanagement" ? (
          <DealManagementListTable {...{ ...props }} />
        ) : (
          <ListTable {...{ ...props }} />
        )}
      </div>
    </>
  );
};

export default DisplayListPopupBody;
