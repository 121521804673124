import React, { useEffect, useState, useCallback, useMemo } from "react";
import ULModal from "../../../Common/Modal";
import ReactTablePagination from "../../../Common/react-table-pagination";

import { getInflowLogs, getInflowEditedDatesLogs, getInflowPublishedDatesLogs } from "../../../Services/InflowRiskVisibilityService";
import { columnHeaderByKey } from "./Columns";
import Loader from "../../../Common/loader";



const formateTime = (timestamp) => {
    const dateObj = new Date(timestamp);

    // Extract date and time components
    const year = dateObj.getUTCFullYear();
    const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
    const day = dateObj.getUTCDate().toString().padStart(2, "0");
    const hours = dateObj.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObj.getUTCMinutes().toString().padStart(2, "0");
    const seconds = dateObj.getUTCSeconds().toString().padStart(2, "0");

    // Create the date and time string
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return dateTimeString;
}

const PAGE_SIZE = 10;


const ViewHistory = (props) => {
    const [data, setData] = useState([]);
    const [dates, setDates] = useState([]);
    const [publishDates, setPublishDates] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEditedOn, setSelectedEditedOn] = useState("");
    const [selectedPublishedOn, setSelectedPublishedOn] = useState("");
    const [editedOnLoading, setEditedOnLoading] = useState(false);
    const [publishedOnLoading, setPublishedOnLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);


    //   const [isLoading, setIsLoading] = useState(true);

    // useEffect(() => {
    //     const fetchData = async (payload) => {
    //         try {
    //             const response = await getPomLogs(payload);
    //             console.log("API Response:", response);
    //             setData(response);
    //         } catch (error) {
    //             console.log("Error fetching data: ", error);
    //         }
    //     };

    //     fetchData({});
    // }, []);

    const handleNextPag = async () => {
        setIsLoading(true);
        setPageNo(prev => prev + 1);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
    };

    const handlePrevPage = async () => {
        setIsLoading(true);
        setPageNo(prev => prev - 1);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setIsLoading(false);
    };

    const handleGotoPage = async (page) => {
        setIsLoading(true);
        setPageNo(page + 1);
        await getAPIData({
            materialno: props.record.product_code,
            UserEmail: props.email,
            countryId: props.countryId,
            plantCode: props.plantCode,
            pageNo: page + 1
        });
        setIsLoading(false);
    };

    const getAPIData = useCallback(async (payload) => {
        try {
            const response = await getInflowLogs(payload);
            console.log("API Response:", response);

            // Check if the response is an array before setting the data state
            if (Array.isArray(response.data)) {
                setData(response.data);
                setTotalCount(response.count);
                if (response.count >= 10) {
                    setTotalPage(response.count / PAGE_SIZE);
                } else {
                    setTotalPage(1);
                }
            } else {
                console.log("API Response is not an array:", response);
            }
            setPageLoading(false);
        } catch (error) {
            console.log("Error fetching data: ", error);
        }
    }, [pageNo])

    const getInflowDateData = useCallback(async (payload) => {
        try {
            const res = await getInflowEditedDatesLogs(payload);
            setDates(res.data)
            console.log("Data dates", res)
        } catch (error) {
            console.log("Error fetching data: ", error);
        }
    })

    const getInflowPublishedDates = useCallback(async (payload) => {
        try {
            const res = await getInflowPublishedDatesLogs(payload);
            setPublishDates(res.data)
            console.log("Data dates", res)
        } catch (error) {
            console.log("Error fetching data: ", error);
        }
    })

    useEffect(() => {
        setPageLoading(true)
        getAPIData({ materialno: props.record.product_code, UserEmail: props.email, countryId: props.countryId, plantCode: props.plantCode });
        getInflowDateData({ pageName: "inflow" });
        getInflowPublishedDates({ pageName: "inflow" });
    }, [pageNo]);

    const handleEditOn = ({ target: { value } }) => {
        setPageLoading(true)
        setTimeout(() => {
            setSelectedEditedOn(value);
            setSelectedPublishedOn("");
            setEditedOnLoading(true);
            getAPIData({
                materialno: props.record.product_code,
                UserEmail: props.email,
                countryId: props.countryId,
                plantCode: props.plantCode,
                editedDate: value,
            });
            setEditedOnLoading(false);
        }, [1000])
        setTimeout(() => {
            setPageLoading(false);
        }, 1000)
    }

    const handlePublishedOn = ({ target: { value } }) => {
        setPageLoading(true)
        setTimeout(() => {
            setSelectedPublishedOn(value);
            setSelectedEditedOn("");
            setPublishedOnLoading(true);
            getAPIData({
                materialno: props.record.product_code,
                UserEmail: props.email,
                countryId: props.countryId,
                plantCode: props.plantCode,
                publishedDate: value,

            });
            setPublishedOnLoading(false);
        }, [1000])
        setTimeout(() => {
            setPageLoading(false);
        }, 1000)
    }

    const infloweditedDates = dates?.map(row => row.editedBy);
    const updatedEditDates = infloweditedDates.filter(row => row != null)
    const inflowpublishedDates = publishDates?.map(row => row.editedBy);
    const updatedPublishEditDates = inflowpublishedDates.filter(row => row != null);

    // const columns = [
    //     // {
    //     //     "title": "Edited By",
    //     //     "key": "test"
    //     // },
    //     // {
    //     //     "title": "Published By",
    //     //     "key": "test"
    //     // },
    //     // {
    //     //     "title": "Edited On\nSelect\n2023-08-25\n2023-08-26",
    //     //     "key": "test"
    //     // },
    //     // {
    //     //     "title": "Published On\nSelect",
    //     //     "key": "test"
    //     // },
    //     {
    //         "title": "comment Waste Lead",
    //         "key": "comment_ww"
    //     },

    //     {
    //         "title": "Owner Short Term Planner",
    //         "key": "owner_scol"
    //     },

    //     {
    //         "title": "Comment Short Term Planner",
    //         "key": "comment_scol"
    //     },

    //     {
    //         "title": "Fc Adjustment Needed",
    //         "key": "fc_adjustment_needed"
    //     },

    //     {
    //         "title": "Owner Demand Planner",
    //         "key": "owner_feo"
    //     },

    //     {
    //         "title": "Comment Demand Planner",
    //         "key": "comment_feo"
    //     },

    //     {
    //         "title": "Action Type",
    //         "key": "actiontype"
    //     },


    // ]

    // const shouldApplyStyling = (oldValue, updatedValue, actualValue) => {
    //     const key = Object.keys(oldValue)[0];
    //     return oldValue[key] !== updatedValue[key] && actualValue[key] != null;
    // };

    const pageOptions = [];
    for (let i = 0; i < totalPage; i++) {
        pageOptions.push(i);
    }

    const lastRow = data?.slice(data.length - 1, data.length + 1);
    console.log("last row", lastRow)

    const rows = useMemo(() => {
        if (!data || !data.length) return [];
        return data.slice((pageNo - 1) * PAGE_SIZE, pageNo * PAGE_SIZE)
    }, [data, pageNo])

    return (
        <ULModal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            isheader="true"
            heading={
                props.rowdata[0][0].column.Header.id == "id"
                    ? props.rowdata[0].value
                    : ""
            }
            className={`page-modal viewBatchCodePopup`}
        >
            {/* <ul>
                {data.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul> */}
            <div className="d-flex flex-column justify-content-between flex-basis-100">
                <h5>Version History</h5>
                <div className="position-relative" >
                    <div className="table_design ">
                        <div className={`table sticky ${(data.length <= 0) ? 'no-data' : ''}`}>
                            <div className="header w-100">
                                <div className="tr">
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Edited By'>Edited By</span>
                                        </div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Publish By'>Published By</span></div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Mode Of Change'>Mode Of Change</span></div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Edited On'>Edited On
                                                <div>
                                                    <select
                                                        onChange={handleEditOn}
                                                        value={selectedEditedOn}
                                                        className="input-edit-mode btn-slim width-100" autoFocus>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {updatedEditDates?.map((value) => {
                                                            return <option value={value}>{value}</option>;
                                                        })}
                                                    </select>
                                                    {editedOnLoading && <Loader />}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Publish On'>Published On
                                                <div>
                                                    <select
                                                        onChange={handlePublishedOn}
                                                        value={selectedPublishedOn}
                                                        className="input-edit-mode btn-slim width-100" autoFocus>
                                                        <option value="" selected>
                                                            Select
                                                        </option>
                                                        {updatedPublishEditDates?.map((value) => {
                                                            return <option value={value}>{value}</option>;
                                                        })}
                                                    </select>
                                                    {publishedOnLoading && <Loader />}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Old Value'>KPI Name</span></div>
                                    </div>

                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Old Value'>Old Value</span></div>
                                    </div>
                                    <div className="th">
                                        <div className="th-in">
                                            <span className="th-text" title='Publish By'>New value</span></div>
                                    </div>

                                    {/* {
                                        columns.map((column, idx) => <React.Fragment key={idx}>
                                            <div className="th" >
                                                <div className="th-in">
                                                    <span className="th-text" title={`${column.title} Old Value`}>{`${column.title} Old Value`}</span>
                                                </div>
                                            </div>
                                            <div className="th" >
                                                <div className="th-in">
                                                    <span className="th-text" title={`${column.title} New Value`}>{`${column.title} New Value`}</span>
                                                </div>
                                            </div>
                                        </React.Fragment>)
                                    } */}
                                    {/* <div className="th">Mode Of Change</div>  */}
                                </div>
                            </div>
                            {data.length <= 0 && !pageLoading && (
                                <div className="body w-100">
                                    <div className="tr w-100">
                                        <div role="cell" className="td  w-100">
                                            <span className="td-in text-center">No Data</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {pageLoading ? (<Loader />) :
                                rows.length > 0 && (
                                    <div className="body w-100">
                                        {/* Data rows */}
                                        {!isLoading && rows.map((item, index) => {
                                            // const isOldValueEdited = shouldApplyStyling(JSON.parse(item.oldValue), JSON.parse(item.updatedValue), JSON.parse(item.oldValue));
                                            // const isUpdateValueEdited = shouldApplyStyling(JSON.parse(item.updatedValue), JSON.parse(item.oldValue), JSON.parse(item.updatedValue));

                                            return <div className="tr" key={index}>

                                                <div className="td">
                                                    <div className="td-in" title={item.editedBy}>
                                                        {item.editedBy}
                                                    </div>
                                                </div>
                                                <div className="td">
                                                    <div className="td-in" title={item.publishedBy}>
                                                        {item.publishedBy}
                                                    </div>
                                                </div>
                                                <div className="td">
                                                    <div className="td-in" title={item.editedMode}>
                                                        {item.editedMode}</div></div>
                                                <div className="td">
                                                    <div className="td-in" title={formateTime(item.editedOn)}>
                                                        {formateTime(item.editedOn)}</div></div>
                                                <div className="td">
                                                    <div className="td-in" title={formateTime(item.publishedOn)}>
                                                        {formateTime(item.publishedOn)}
                                                    </div>
                                                </div>
                                                <div className="td">
                                                    <div className="td-in" title={columnHeaderByKey[Object.keys(JSON.parse(item.oldValue))?.[0] ?? ""]}>
                                                        {columnHeaderByKey[Object.keys(JSON.parse(item.oldValue))?.[0] ?? ""]}
                                                    </div>
                                                </div>
                                                <div className="td">
                                                    <div className="td-in" title={JSON.parse(item.oldValue)?.[Object.keys(JSON.parse(item.oldValue))?.[0]] != "null" && (JSON.parse(item.oldValue)?.[Object.keys(JSON.parse(item.oldValue))?.[0]] ?? "")}>
                                                        {JSON.parse(item.oldValue)?.[Object.keys(JSON.parse(item.oldValue))?.[0]] != "null" && (JSON.parse(item.oldValue)?.[Object.keys(JSON.parse(item.oldValue))?.[0]] ?? "")}
                                                    </div>
                                                </div>
                                                <div className="td">
                                                    <div className="td-in" title={JSON.parse(item.updatedValue)?.[Object.keys(JSON.parse(item.updatedValue))?.[0]] ?? ""}>
                                                        {JSON.parse(item.updatedValue)?.[Object.keys(JSON.parse(item.updatedValue))?.[0]] ?? ""}
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                        )}
                                        {isLoading && <Loader />}
                                    </div>
                                )}

                        </div>
                    </div>
                    {totalCount >= 0 &&
                        <ReactTablePagination
                            page={pageNo}
                            gotoPage={handleGotoPage}
                            previousPage={handlePrevPage}
                            nextPage={handleNextPag}
                            canPreviousPage={pageNo > 1}
                            canNextPage={pageNo < totalCount}
                            pageOptions={pageOptions}
                            pageSize={PAGE_SIZE}
                            pageIndex={pageNo - 1}
                            pageCount={totalPage}
                            totalCount={totalCount}
                            dataLength={totalCount}

                        />
                    }
                </div>
            </div>
        </ULModal>
    );
};

export default React.memo(ViewHistory);
