import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import _ from "lodash";
import DownloadIcon from "../../Assets/img/download.svg";
import PlusIcon from "../../Assets/img/Plus.svg";
import ClickIcon from "../../Assets/img/clickicon.svg";
import LeftCaretIcon from "../../Assets/img/left_caret.svg";
import PinchIcon from "../../Assets/img/icon-pinch.svg";
import FolderIcon from "../../Assets/img/folder.svg";
// import SummaryTable from "./SummaryTable";
import {
  CentralDealManagementDetails,
  downloadCentralDealManagement,
  //excelFileUpload,
  addPulishStatus,
  setPublishStatus,
  UpdateDealManagementCellData,
  AddToCentralDealBiddingList,
  CentralDealManagementSKUDetails,
  IndivisualVendorBiddingList,
  UpdateBiddingList,
} from "../../Services/CentralDealManagementDetailServices";

import { getBiddingListName } from "../../Services/BiddingPortalService";

import Toast from "../../../src/Common/toast";
import { notify } from "../../../src/Common/toast";
import { COLUMNS } from "./common/Column";
import { PerPageRecordNumber } from "../../../src/utility/constants";
// import TableGrid from "./common/TableGrid";
import TableGrid from "../../Views/WasteRiskDetailsTable/TableGrid";
import EditBiddingListTableGrid from "../../Views/WasteRiskDetailsTable/EditBiddingListTableGrid";
import OpenIcon from "../../Assets/img/open.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Loader from "../../Common/loader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import CreateNewListPopup from "../../Common/CreateNewListPopup";
import DisplayListPopup from "../../Common/DisplayListPopup";
import { UserContext } from "../Application";
import { transform } from "lodash";
var fileSaver = require("file-saver");

export const StatusContext = React.createContext({});
const CentralDealManagementDetailsTable = (props) => {
  const [filterText,setFilterText]=useState('')
  const [filterKey,setFilterKey]=useState('')
  const user = useContext(UserContext);
  const history = useHistory();
  const [key, setKey] = useState(
    props?.heading || history.location.state?.dealId ? "ALL" : "unused"
  );
  const [gridInfo, setGridInfo] = useState(["Loading"]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageInd, setPageInd] = useState(0);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [itemPerPage, setItemPerPage] = useState(PerPageRecordNumber);
  const [SortBy, setSortBy] = useState(
    props.dealList?.length > 0 ? "dealTypeId" : "businessGroupId"
  );
  const [SortType, setSortType] = useState("ASC");
  const [listCreatorLoading, setListCreatorLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [actions, setActions] = useState([]);
  const [toastifyPosition] = useState("top-center");
  const [selection, setSelection] = useState([]);
  const [sortBySelection, setSortBySelection] = useState("Days Remaining");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [publishCount, setPublishCount] = useState(0);
  const [rowInlineEditChanges, setRowInlineEditChanges] = useState([]);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [clickedCell, setClickedCell] = useState(null);
  const [status, setStatus] = useState([]);

  //Create list Item
  const [createListItem, setCreateListItem] = useState([]);
  const [createNewListModal, setCreateNewListModal] = useState(false);
  const [displayListModal, setDisplayListModal] = useState(false);
  const [inputName, setInputName] = useState("");
  const [isNewListSaved, setIsNewListSaved] = useState(false);
  const [biddingList, setBiddingList] = useState([]);
  const [biddingGroupIdList, setBiddingGroupIdList] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [adjustPageGridHt, setAdjustPageGridHt] = useState(null);
  const fetchIdRef = React.useRef(0);

  const [existingBiddingListData, setExistingBiddingListData] = useState([]);
  const [existingBiddingListDetails, setExistingBiddingListDetails] = useState(
    []
  );
  const [refObj, setRefObj] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const [state, setState] = useState("Loading")

  console.log("Top selectedRowIds:====== ", selectedRowIds);

  const [reRenderKey, setReRenderKey] = useState(0);

  useEffect(() => {
      setReRenderKey(prevKey => prevKey + 1);
  }, [selectedRowIds]);

  console.log("props.editedBiddingListData: ====", props.editedBiddingListData);
  console.log("Top existingBiddingListData: ====", existingBiddingListData);

  const toggleSelection = (record) => {
    const idx = selection.findIndex((i) => i.id === record.id);
    if (idx === -1) {
      selection.push(record);
    } else {
      selection.splice(idx, 1);
    }
    setIsSelectAll(selection.length === itemPerPage);
    setSelection([...selection]);
  };
  const updateCellData = (row, column, value) => {
    const rowId = row.original.id;
    let index = rowInlineEditChanges.findIndex((x) => x.rowID === rowId);
    index !== -1 && rowInlineEditChanges.splice(index, 1);
    setClickedIndex(null);
    setClickedCell(null);

    UpdateDealManagementCellData({
      data: [
        {
          materialno: row.original.materialno,
          key_mrdr_plant: row.original.key_mrdr_plant,
          countryId: row.original.countryId,
          country: row.original.country,
          expiry_date:
            column.id === "expiry_date"
              ? value
                ? moment(value).format("DD-MM-YYYY")
                : row.original.expiry_date
              : row.original.expiry_date,
          pack_type:
            column.id === "pack_type"
              ? value
                ? value
                : row.original.pack_type
              : row.original.pack_type,
          language_on_pack:
            column.id === "language_on_pack"
              ? value
                ? value
                : row.original.language_on_pack
              : row.original.language_on_pack,
          production_plant_number:
            column.id === "production_plant_number"
              ? value
                ? value
                : row.original.production_plant_number
              : row.original.production_plant_number,
          production_country_name:
            column.id === "production_country_name"
              ? value
                ? value
                : row.original.production_country_name
              : row.original.production_country_name,
          agreed_quantity_in_zun:
            column.id === "agreed_quantity_in_zun"
              ? value
                ? value
                : row.original.agreed_quantity_in_zun
              : row.original.agreed_quantity_in_zun,
          status:
            column.id === "status"
              ? value
                ? value
                : row.original.status
              : row.original.status,
          agreed_price_per_zun_in_eur:
            column.id === "agreed_price_per_zun_in_eur"
              ? value
                ? value
                : row.original.agreed_price_per_zun_in_eur
              : row.original.agreed_price_per_zun_in_eur,
        },
      ],
    })
      .then((response) => {
        if (response.message === "Sucessfully Updated") {
          notify({
            message: "Data edited successfully!",
            type: "success",
          });
        } else {
          notify({
            message: "Something Went Wrong!",
            type: "error",
          });
        }
      }, 100)
      .catch((error) => {
        console.log("error: ", error);
        notify({
          message: "No Response From Server. Please Try Again Later",
          type: "error",
        });
      });
  };
  const handleClickEditCell = (cell) => {
    setClickedIndex(cell.row.index);
    setClickedCell(cell.column.id);
  };
  const filterData = (value, keyColumn) => {
    setFilterKey(keyColumn);
    setFilterText(value);
    getGridData({
      countryId: props.dropDownValues?.country ?? [],
      categoryId: props.dropDownValues?.category ?? [],
      businessGroupId: props.dropDownValues?.businessGroup ?? [],
      mcoId:props.dropDownValues?.mco ?? [],
      brandId:props.dropDownValues?.brandId ?? [],
      page,
      per_page: itemPerPage,
      sortby: SortBy,
      complianceSorting: SortType,
      searchText: searchText ? searchText.trim() : "",
      datefilter: keyColumn === "expiry_date"? value : null,
      columnFilter: keyColumn,
      columnFilterValue: keyColumn === "expiry_date" ? null : value,
      currentTabSelection: key?.toLowerCase(),
    });
  };
  const editCellProps = {
    handleClickEditCell,
    clickedIndex,
    clickedCell,
    updateCellData,
    status,
    filterData
  };

  const addToPublish = (values) => {
    let data = [];
    selection.forEach((val) => {
      data.push({
        materialno: val.materialno,
        status: values,
      });
    });
    addPulishStatus({ data: data })
      .then((response) => {
        setSelection([]);
        getGridData({
          brandId: props.dropDownValues?.brand ?? [],
          countryId: props.dropDownValues?.country ?? [],
          categoryId: props.dropDownValues?.category ?? [],
          businessGroupId: props.dropDownValues?.businessGroup ?? [],
          dealTypeId:
            props.dealList?.length > 0
              ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
              : props.dropDownValues?.dealType ?? [],
          mcoId: props.dropDownValues?.mco ?? [],
          page,
          per_page: itemPerPage,
          sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
        });
        notify({
          message: `Sucessfully Added to Publish List`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish List failed`,
          type: "error",
        });
      });
  };

  const gotoPublish = (values) => {
    if (!publishCount) {
      notify({
        message: `There are no actions in publish list`,
        type: "warn",
      });
      return;
    }
    setPublishStatus()
      .then((response) => {
        getGridData({
          brandId: props.dropDownValues?.brand ?? [],
          countryId: props.dropDownValues?.country ?? [],
          categoryId: props.dropDownValues?.category ?? [],
          businessGroupId: props.dropDownValues?.businessGroup ?? [],
          dealTypeId:
            props.dealList?.length > 0
              ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
              : props.dropDownValues?.dealType ?? [],
          materialNumberId: props.dropDownValues?.materialNumber ?? [],
          mcoId: props.dropDownValues?.mco ?? [],
          plantId: props.dropDownValues?.plant ?? [],
          residualStatusId: props.dropDownValues?.residualStatus ?? [],
          page,
          per_page: itemPerPage,
          sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
          complianceSorting: SortType,
          searchText: searchText ?? "",
        });
        setSelection([]);
        notify({
          message: `Sucessfully Updated`,
          type: "success",
        });
      })
      .catch(() => {
        notify({
          message: `Adding Publish status failed`,
          type: "error",
        });
      });
  };

  useEffect(() => {
    refObj?.forEach((key) => {
      if (key[Object.keys(key)[0]].current) {
        if (key[Object.keys(key)[0]].current.state)
          key[Object.keys(key)[0]].current.state.value = "";
        else {
          key[Object.keys(key)[0]].current.value = "";
        }
      }
    });
    setFilterKey('');
    setFilterText('')
  }, [props.dropDownValues]);

  const getSelectedRowIds = useCallback(
    (data) => {
      console.log(data, "dataaa")
      console.log(existingBiddingListData, "eeeexistingBiddingListData")
      if (existingBiddingListData.length > 0) {
        const wasteRiskDetailsIDs = existingBiddingListData.map(
          (item) => item.wasteRiskDetailsID
        );
        console.log(wasteRiskDetailsIDs, "wasteRiskDetailsIDs")
        let rowIds = [];
        data.forEach(
          (item, index) =>
            wasteRiskDetailsIDs.indexOf(item.id) !== -1 && rowIds.push(index)
        );
        const selectedRowIds = _.zipObject(
          rowIds,
          Array(rowIds.length).fill(true)
        );
        console.log(selectedRowIds, "selectedRowIdssssss")
        setSelectedRowIds(selectedRowIds);
      }
    },
    [existingBiddingListData]
  );

  useEffect(() => {
    getSelectedRowIds(gridInfo);
  }, [getSelectedRowIds, gridInfo]);

  const getGridData = useCallback((payload) => {
    setState('Loading');
    payload.currentTabSelection= key?.toLowerCase();
    if ((props.heading || history.location.state?.dealId) && history.location.state?.dealId!=="") {
      CentralDealManagementDetails(payload).then((response) => {
        if (response) {
          setGridInfo(response.data);
          setActions(response.actions);
          setTotalCount(response.totalCount);
          setBiddingGroupIdList(response.bidding_group_list);
          setTotalRecordCount(
            response.count ? response.count : response.data?.length
          );
          setPublishCount(response.published_count ?? 0);
          setStatus(response.status);
          getSelectedRowIds(response.data);
          setTimeout(() => { setState('Completed'); }, 200)
        }
      }).catch(()=>{setState('Completed');});;
    } else {
      payload.currentTabSelection= history.location.state?.dealId!==""?"":"unused";
      payload.dealTypeId=[]
      CentralDealManagementSKUDetails(payload).then((response) => {
        if (response) {
          setGridInfo(response.data);
          setActions(response.actions);
          setTotalCount(response.totalCount);
          setBiddingGroupIdList(response.bidding_group_list);
          setTotalRecordCount(
            response.count ? response.count : response.data?.length
          );
          setPublishCount(response.published_count ?? 0);
          setStatus(response.status);
          setTimeout(() => { setState('Completed'); }, 200)
        }
      }).catch(()=>{setState('Completed');});;
    }
  }, []);

  const onActionClick = (val) => {
    if (selection.length === 0) {
      notify({
        message: `Please select one record!`,
        type: "warn",
      });
      return;
    } else {
      addToPublish(val);
    }
  };

  const downloadData = (payload) => {
    downloadCentralDealManagement(payload).then((response) => {
      // if (response) {
      //   notify({
      //     message: `Download Completed.`,
      //     type: "success",
      //   });
      // } else {
      //   notify({
      //     message: `Download Failed.`,
      //     type: "error",
      //   });
      // }
    });
  };

  const handleDownload = useCallback(() => {
    if (gridInfo.length > 0) {
      notify({
        message: `Downloading the File. Please wait...`,
        type: "info",
      });
      downloadData({
        brandId: props.dropDownValues?.brand ?? [],
        countryId: props.dropDownValues?.country ?? [],
        categoryId: props.dropDownValues?.category ?? [],
        businessGroupId: props.dropDownValues?.businessGroup ?? [],
        dealTypeId:
          props.dealList?.length > 0
            ? [props.dealList[0]?.id ?? history.location.state?.dealId ?? 0]
            : props.dropDownValues?.dealType ?? [],
        materialNumberId: props.dropDownValues?.materialNumber ?? [],
        mcoId: props.dropDownValues?.mco ?? [],
        plantId: props.dropDownValues?.plant ?? [],
        residualStatusId: props.dropDownValues?.residualStatus ?? [],
        filename: "waste_risk_details",
        sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
        complianceSorting: SortType,
        searchText: searchText ?? "",
        responseType: "arraybuffer",
      });
    } else {
    }
  }, [
    SortBy,
    SortType,
    gridInfo.length,
    props.dealList,
    props.dropDownValues?.brand,
    props.dropDownValues?.businessGroup,
    props.dropDownValues?.category,
    props.dropDownValues?.country,
    props.dropDownValues?.dealType,
    props.dropDownValues?.materialNumber,
    props.dropDownValues?.mco,
    props.dropDownValues?.plant,
    props.dropDownValues?.residualStatus,
  ]);

  const handleItemPerPage = (val, gotoPage) => {
    setItemPerPage(val);
    gotoPage(1);
  };
  const handlePaginationReset = (gotoPage) => {
    setItemPerPage(PerPageRecordNumber);
    gotoPage(1);
  };
  const handleSearch = (val) => {
    // //setPage(0);
    // setSearchParam(val);
  };
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      if (fetchId === fetchIdRef.current) {
        setPage(pageIndex + 1);
        setPageCount(Math.ceil(totalRecordCount / pageSize));
      }
    },
    [totalRecordCount]
  );

  useEffect(() => {
    const payLoadData={
      brandId: props.dropDownValues?.brand ?? [],
      countryId: props.dropDownValues?.country ?? [],
      categoryId: props.dropDownValues?.category ?? [],
      businessGroupId: props.dropDownValues?.businessGroup ?? [],
      dealTypeId: history.location.state?.dealId
        ? [history.location.state?.dealId ?? 0]
        : props.dropDownValues?.dealType ?? [],
      mcoId: props.dropDownValues?.mco ?? [],
      page,
      per_page: itemPerPage,
      sortby: props.dealList?.length > 0 ? "dealTypeId" : SortBy,
      complianceSorting: SortType,
      currentTabSelection: key?.toLowerCase(),
      searchText: searchText ?? "",
    };
    getGridData(filterKey
    ? {
        ...payLoadData,
        datefilter: filterKey === "expiry_date" ? filterText : null,
        columnFilter: filterKey,
        columnFilterValue: filterKey === "expiry_date" ? null : filterText,
      }
    : payLoadData);
  }, [
    SortBy,
    SortType,
    getGridData,
    itemPerPage,
    page,
    props.dropDownValues,
    key,
    props.dealId,
    // props.editedBiddingListData
  ]);

  useEffect(() => {
    if (props.isExpand) {
      setItemPerPage(20);
    } else {
      setItemPerPage(PerPageRecordNumber);
    }
  }, [props.isExpand]);

  const handleSelectAllCheckBox = (e) => {
    setIsSelectAll(e.target.checked);
    setIsCheck(gridInfo.map((item) => item.id));

    if (isSelectAll) {
      setIsCheck([]);
      setSelection(gridInfo);
    } else {
      setSelection([]);
    }
  };

  const handleCheckBox = (e, cellInfo) => {
    const { value, checked } = e.target;

    setIsCheck([...isCheck, value]);
    if (!checked) {
      setSelection(selection.filter((item) => item.id !== value));
      setIsCheck(isCheck.filter((item) => item !== value));
    } else {
      setSelection([...selection, cellInfo.row.original]);
    }
  };

  //Create New List
  const toggleCreateNewListModal = () => {
    console.log("Hiiii");
    setIsNewListSaved(false);
    if (createListItem.length > 0) {
      setCreateNewListModal(!createNewListModal);
    }
  };

  const toggleWarnMessageModal = () => {
    notify({
      message: `Please select sku's to create a list`,
      type: "warn",
    });
  };
  const toggleDisplayListModal = (listName) => {
    setSelectedList(listName);
    setDisplayListModal(!displayListModal);
  };

  const BiddingListName = useCallback((payload) => {
    getBiddingListName(payload, true).then((response) => {
      setBiddingList(response.bidding_list);
      props.setBiddingList(response);
    });
  }, []);

  const handleCreateNewList = () => {
    setListCreatorLoading(true);
    const materialno = createListItem.map((item) => item.original.materialno);
    const key_mrdr_plant = createListItem.map(
      (item) => item.original.key_mrdr_plant
    );
    const payload = {
      biddingListName: inputName,
      vendorName: user.firstName,
      vendorEmail: user.email,
      customerName: "",
      customerEmail: "",
      materialno,
      key_mrdr_plant,
      biddingGroupId: props.dealList[0]?.id
        ? props.dealList[0]?.id
        : history.location.state?.dealId
        ? history.location.state?.dealId
        : 1,
      BiddingStatus: "",
    };
    AddToCentralDealBiddingList(payload)
      .then((response) => {
        setListCreatorLoading(false);
        if (response.message) {
          setIsNewListSaved(true);
          BiddingListName({ vendorEmail: user.email });
        }
      })
      .catch((err) => {
        setListCreatorLoading(false);
        console.log("Error: ", err);
      });
  };

  const OpenRecentCreatedList = () => {
    //UserBiddingListName();
    setSelectedList(inputName);
    setCreateNewListModal(false);
    setDisplayListModal(true);
    setIsNewListSaved(false);
  };

  const updateOfferPrice = (payload) => {
    setDisplayListModal(!displayListModal);
    notify({
      message: "Data updated successfully!",
      type: "success",
    });
  };

  useEffect(() => {
    if (Object.keys(user)?.length) BiddingListName({ vendorEmail: user.email });
  }, [BiddingListName, user]);

  const getIndivisualBiddingList = useCallback(() => {
    IndivisualVendorBiddingList({
      biddingListID: props.editedBiddingListData.biddingListID,
      biddingListName: props.editedBiddingListData.biddingListName,
    }).then((response) => {
        console.log(response, "responseee")
        setExistingBiddingListData(response.data);
        setExistingBiddingListDetails(response.biddingListDetail);
      }).catch((err) => console.log("Err: ", err));
  }, [
    props.editedBiddingListData.biddingListID,
    props.editedBiddingListData.biddingListName,
  ]);

  const updateBiddingPortalList = () => {
    const payload = {
      // data: createListItem.map((item) => item.original),
      // vendorName: user.firstName,
      // vendorEmail: user.email,
      // BiddingStatus: existingBiddingListDetails[0].BiddingStatus,
      // biddingGroupId: existingBiddingListDetails[0].BiddingGroupID,
      // biddingListName: existingBiddingListDetails[0].BiddingListName,
      // biddingListID: existingBiddingListDetails[0].BiddingListID,
      // customerEmail: null,
      // vendorOfferSent: existingBiddingListDetails[0].VendorOfferSent,
      // vendorAccepted: existingBiddingListDetails[0].VendorAccepted,
      // vendorRejected: existingBiddingListDetails[0].VendorRejected,
      // vendorCounterOffer: existingBiddingListDetails[0].VendorCounterOffer,
      biddingGroupId: existingBiddingListDetails[0].BiddingGroupID,
      biddingListName: existingBiddingListDetails[0].BiddingListName,
      biddingListID: existingBiddingListDetails[0].BiddingListID,
      wasteRiskId: createListItem.map((item) => item.original.wasteRiskId),
    };
    console.log("payload: ", payload);

    UpdateBiddingList(payload)
      .then((response) => {
        if (response.message == "Sucessfully Added to Bidding Portal List") {
          notify({
            type: "success",
            message: "Sucessfully Added to Bidding Portal List",
          });
          props.setIsEditBiddingList(false);
        }
      })
      .catch((err) => console.log("Err: ", err));
  };

  useEffect(() => {
    getIndivisualBiddingList();
  }, [getIndivisualBiddingList]);

  return (
    <StatusContext.Provider value={status}>
      <div className="main-body">
        <Toast position={toastifyPosition} />
        <div className="">
          {createNewListModal && (
            <CreateNewListPopup
              {...{
                inputName,
                setInputName,
                handleCreateNewList,
                isNewListSaved,
                OpenRecentCreatedList,
                show: createNewListModal,
                onHide: toggleCreateNewListModal,
                from: "dealmanagement",
                isLoading: listCreatorLoading,
              }}
            />
          )}
          {displayListModal && (
            <DisplayListPopup
              {...{
                selectedList,
                updateOfferPrice,
                //handleEditList,
                show: displayListModal,
                onHide: toggleDisplayListModal,
                from: "dealmanagement",
                clickedFrom: "dealmanagement",
              }}
            />
          )}

          <div className="d-flex row justify-content-between mt-3 mb-2">
            <div className="col-md-10">
              
              <div className="mb-3">
                <div>
                  {props?.heading !== undefined && (
                    <button
                      type="button"
                      className="btn-slim btn-published bg-red"
                      onClick={() => {
                        history.push("/dealcalendar");
                      }}
                    >
                      <img src={LeftCaretIcon} alt="" /> Go to Deal Calendar
                    </button>
                  )}

                  <button
                    type="button"
                    className={`btn-slim btn-published  ${
                      props?.heading !== undefined ? "mx-4" : ""
                    } ${selection.length ? "bg-red" : ""}`}
                    onClick={updateBiddingPortalList}
                  >
                    <img src={PlusIcon} alt="" /> Update The Bidding Portal List
                  </button>
                </div>
                {(props.dealList?.length > 0 || props.heading) && (
                  <div className="breadcrumb">
                    <p>
                      <span>Central Deal Calendar</span> &#62;{" "}
                      <span>{props.dealList?.[0]?.value || props.heading}</span>{" "}
                      &#62; <span>Central Deal Management</span>
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-2">
              <div className="table-actions p-0 page-el">
                <button
                  type="button"
                  className="btn-slim btn-download "
                  onClick={handleDownload}
                >
                  <img src={DownloadIcon} alt="" />
                  Download
                </button>
              </div>
            </div>
          </div>
          {/* <!--Action buttons End--> */}

          <div className="">
            <div
              className={`body-container  body-container-table-with-pagination central_deal_management_page ${
                props?.heading === undefined ? "no-heading" : 0
              }`}
            >
              {/* <!--Search Table Data Start--> */}
              <div className="d-block nav-tabs-wrap ">
                <div className="pull-left">
                  {props?.heading !== undefined && (
                    <div className="table-filters">
                      <div className="text-white">
                        <h5>{props?.heading}</h5>
                      </div>
                    </div>
                  )}
                </div>

                {/* <!--Search Table Data End--> */}

                <span
                  className="marginset btn-expand-contract-table pull-right mx-3 mr-0"
                  style={{ marginTop: -4 + "px" }}
                >
                  <span
                    className="info_btn"
                    onClick={() => {
                      setAdjustPageGridHt(Math.random(0.9));
                      setTimeout(() => {
                        setAdjustPageGridHt(null);
                      }, 2);
                    }}
                    title={
                      props.isExpand
                        ? "Minimise Datatable"
                        : "Maximise Datatable"
                    }
                  >
                    <img
                      className="flex align-items-center mt-0"
                      src={props.isExpand ? PinchIcon : OpenIcon}
                      alt="Open"
                    />
                  </span>
                </span>

                {!props?.heading && !history.location.state?.dealId && (
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => {
                      setGridInfo(["Loading"]);
                      return setKey(k);
                    }}
                    //     transform: translateY(-38px);
                    className="mb-3 "
                  >
                    <Tab eventKey="unused" title="Yet to sent">
                      {key === "unused" && (
                        <div>
                                <>
                                  {gridInfo && (
                                    <TableGrid
                                    state={state}
                                      pageInd={pageInd}
                                      setPageInd={setPageInd}
                                      columns={COLUMNS}
                                      data={gridInfo}
                                      SortBy={SortBy}
                                      SortType={SortType}
                                      setSortBy={setSortBy}
                                      setSortType={setSortType}
                                      fetchData={fetchData}
                                      pageCount={pageCount}
                                      totalRecordCount={totalRecordCount}
                                      pageSizeNo={itemPerPage}
                                      handleItemPerPage={handleItemPerPage}
                                      handlePaginationReset={
                                        handlePaginationReset
                                      }
                                      handleSearch={handleSearch}
                                      setSelection={setSelection}
                                      selection={selection}
                                      toggleSelection={toggleSelection}
                                      actions={actions}
                                      onActionClick={onActionClick}
                                      searchText={searchText}
                                      setSearchText={setSearchText}
                                      getGridData={getGridData}
                                      {...editCellProps}
                                      props={props}
                                      isSelectAll={isSelectAll}
                                      isNewListSaved={isNewListSaved}
                                      setIsSelectAll={setIsSelectAll}
                                      toggleExpandCollapse={
                                        props.toggleExpandCollapse
                                      }
                                      isExpand={props.isExpand}
                                      handleCheckBox={handleCheckBox}
                                      handleSelectAllCheckBox={
                                        handleSelectAllCheckBox
                                      }
                                      isCheck={isCheck}
                                      isNotRequiredTableCoumnSort={true}
                                      isNoActions
                                      setCreateListItem={setCreateListItem}
                                      totalCount={totalCount}
                                      adjustPageGridHt={adjustPageGridHt}
                                      page={"deal"}
                                      heading={props?.heading}
                                      isBarOnHover
                                    />
                                  )}
                                </>
                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="reserved" title="In Progress">
                      {/* {key === 'Layer_3' && <LayerThreeTable />} */}
                      {key === "reserved" && (
                        <div>
                          {gridInfo[0] != "Loading" && (
                            <>
                                  {gridInfo && (
                                    <TableGrid
                                    state={state}
                                      pageInd={pageInd}
                                      setPageInd={setPageInd}
                                      columns={COLUMNS}
                                      data={gridInfo}
                                      SortBy={SortBy}
                                      SortType={SortType}
                                      setSortBy={setSortBy}
                                      setSortType={setSortType}
                                      fetchData={fetchData}
                                      pageCount={pageCount}
                                      totalRecordCount={totalRecordCount}
                                      pageSizeNo={itemPerPage}
                                      handleItemPerPage={handleItemPerPage}
                                      handlePaginationReset={
                                        handlePaginationReset
                                      }
                                      handleSearch={handleSearch}
                                      setSelection={setSelection}
                                      selection={selection}
                                      toggleSelection={toggleSelection}
                                      actions={actions}
                                      onActionClick={onActionClick}
                                      searchText={searchText}
                                      setSearchText={setSearchText}
                                      getGridData={getGridData}
                                      {...editCellProps}
                                      props={props}
                                      isSelectAll={isSelectAll}
                                      setIsSelectAll={setIsSelectAll}
                                      toggleExpandCollapse={
                                        props.toggleExpandCollapse
                                      }
                                      isExpand={props.isExpand}
                                      handleCheckBox={handleCheckBox}
                                      handleSelectAllCheckBox={
                                        handleSelectAllCheckBox
                                      }
                                      isCheck={isCheck}
                                      isNotRequiredTableCoumnSort={true}
                                      isNoActions
                                      totalCount={totalCount}
                                      adjustPageGridHt={adjustPageGridHt}
                                      page={"deal"}
                                      isBarOnHover
                                    />
                                  )}</>
                          )}
                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                    <Tab eventKey="accepted" title="Accepted">
                      {key === "accepted" && (
                        <div>
                          {gridInfo[0] != "Loading" && (
                            <>
                                  {gridInfo && (
                                    <TableGrid
                                    state={state}
                                      pageInd={pageInd}
                                      setPageInd={setPageInd}
                                      columns={COLUMNS}
                                      data={gridInfo}
                                      SortBy={SortBy}
                                      SortType={SortType}
                                      setSortBy={setSortBy}
                                      setSortType={setSortType}
                                      fetchData={fetchData}
                                      pageCount={pageCount}
                                      totalRecordCount={totalRecordCount}
                                      pageSizeNo={itemPerPage}
                                      handleItemPerPage={handleItemPerPage}
                                      handlePaginationReset={
                                        handlePaginationReset
                                      }
                                      handleSearch={handleSearch}
                                      setSelection={setSelection}
                                      selection={selection}
                                      toggleSelection={toggleSelection}
                                      actions={actions}
                                      onActionClick={onActionClick}
                                      searchText={searchText}
                                      setSearchText={setSearchText}
                                      getGridData={getGridData}
                                      {...editCellProps}
                                      props={props}
                                      isSelectAll={isSelectAll}
                                      setIsSelectAll={setIsSelectAll}
                                      toggleExpandCollapse={
                                        props.toggleExpandCollapse
                                      }
                                      isExpand={props.isExpand}
                                      handleCheckBox={handleCheckBox}
                                      handleSelectAllCheckBox={
                                        handleSelectAllCheckBox
                                      }
                                      isNotRequiredTableCoumnSort={true}
                                      isCheck={isCheck}
                                      isNoActions
                                      totalCount={totalCount}
                                      adjustPageGridHt={adjustPageGridHt}
                                      page={"deal"}
                                      isBarOnHover
                                    />
                                  )}
                                </>
                          )}
                          {gridInfo[0] === "Loading" && <Loader />}
                        </div>
                      )}
                    </Tab>
                  </Tabs>
                )}
                {(props?.heading || history.location.state?.dealId) && (
                  
                  <div>
                    {gridInfo[0] != "Loading" && (
                      <>
                        {console.log(Object.keys(selectedRowIds), "Object.keys(selectedRowIds)")}
                        {(gridInfo || Object.keys(selectedRowIds).length > 0) && (
                          <EditBiddingListTableGrid
                            key={reRenderKey}
                            pageInd={pageInd}
                            setPageInd={setPageInd}
                            columns={COLUMNS}
                            data={gridInfo}
                            SortBy={SortBy}
                            SortType={SortType}
                            setSortBy={setSortBy}
                            setSortType={setSortType}
                            fetchData={fetchData}
                            pageCount={pageCount}
                            totalRecordCount={totalRecordCount}
                            pageSizeNo={itemPerPage}
                            handleItemPerPage={handleItemPerPage}
                            handlePaginationReset={
                              handlePaginationReset
                            }
                            handleSearch={handleSearch}
                            setSelection={setSelection}
                            selection={selection}
                            toggleSelection={toggleSelection}
                            actions={actions}
                            onActionClick={onActionClick}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            getGridData={getGridData}
                            {...editCellProps}
                            props={props}
                            isSelectAll={isSelectAll}
                            isNewListSaved={isNewListSaved}
                            setIsSelectAll={setIsSelectAll}
                            toggleExpandCollapse={
                              props.toggleExpandCollapse
                            }
                            isExpand={props.isExpand}
                            handleCheckBox={handleCheckBox}
                            handleSelectAllCheckBox={
                              handleSelectAllCheckBox
                            }
                            isCheck={isCheck}
                            isNotRequiredTableCoumnSort={true}
                            isNoActions
                            setCreateListItem={setCreateListItem}
                            totalCount={totalCount}
                            adjustPageGridHt={adjustPageGridHt}
                            page={"deal"}
                            heading={props?.heading}
                            existingBiddingListData={
                              existingBiddingListData
                            }
                            selectedRowIds={selectedRowIds}
                            refObj={refObj}
                            setRefObj={setRefObj}
                          />
                        )}
                      </>
                    )}

                    {gridInfo[0] === "Loading" && <Loader />}
                  </div>
                )}
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </StatusContext.Provider>
  );
};
export default CentralDealManagementDetailsTable;
