import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Chart, getElementAtEvent } from 'react-chartjs-2';
import Loader from '../../../Common/loader';
import { LABEL_COLOR, LEGEND_COLOR, PayloadWithFilters } from './helpers';
import { getPomDealStatusSummary } from '../../../Services/PomSummaryService';
import { UserAccessContext } from '../../Application';

function PomDealStatusYChart({ dropDownValues, selectedCountry, setCountry }) {
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const mcoChartRef = useRef();
    const [backgroundColorIndex, setBackgroundColorIndex] = useState(0);
    const userAccess = useContext(UserAccessContext)
    useEffect(() => {
        if (dropDownValues || selectedCountry) {
            getChartData(PayloadWithFilters(dropDownValues,userAccess));
        }
    }, [dropDownValues, selectedCountry]);

    useEffect(() => {
        setBackgroundColorIndex(0)
    }, [dropDownValues]);

    const getChartData = useCallback((filters) => {
        const payload = {
            ...filters,
            viewBy: "country",
        };
        getPomDealStatusSummary(payload)
            .then((response) => {
                setChartData(response?.PomMasterDetailsResult);
            })
            .finally(() => setIsLoading(false));
    });

    const dealStatusTotal = useMemo(
        () => chartData?.reduce((acc, d) => acc + d.cogs, 0),
        [chartData]
    );

    const cumulativeData = useMemo(
        () =>
            chartData?.length === 1
                ? chartData
                : [...[{ country: "TOTAL", cogs: dealStatusTotal }], ...chartData],
        [chartData]
    );

    const commaify = (value) => {
        var result = ("" + value).replace(/^(-?\d+)(\d{3})/, "$1,$2");
        return value == result ? result : commaify(result);
    };
    const options = {
        indexAxis: "y",
        responsive: true,
        aspectRatio: 1.1,
        layout: {
            padding: {
                right: 60,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || "";
                        if (label) {
                            label += ": ";
                        }
                        if (context.parsed.x !== null) {
                            label += (context.parsed.x / 1000000).toFixed(2) + `M`
                            //commaify((context.parsed.x/1000000)>1?Math.round(context.parsed.x/1000000):(context.parsed.x/1000000).toFixed(2)) +`M`;
                        }
                        return label;
                    },
                },
            },
            legend: {
                display: false,
                maxWidth: 20,
            },
            datalabels: {
                anchor: "end",
                align: "right",
                color: LEGEND_COLOR,
                font: {
                    size: 10,
                    family: "unilevershilling",
                },
                // formatter: (val) => commaify((val/1000)>1?parseInt(val/1000):(val/1000).toFixed(2)),
                formatter: (value) => `€ ${(value / 1000000).toFixed(2)}M`,
                offset: -1,
            },
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: LABEL_COLOR,
                    font: {
                        size: 8,
                        family: "unilevershilling",
                    },
                },
                type: "category",
            },
        },
        // maintainAspectRatio: false,
    };
    const dealStatusData = {
        labels: cumulativeData?.map((data) => data.country),
        datasets: [
            {
                label: "€",
                data: cumulativeData?.map((data) => Number(data.cogs).toFixed(2)),
                backgroundColor: [
                    // ...["rgb(84, 168, 216)"],
                    ...cumulativeData?.map((data) => cumulativeData[backgroundColorIndex] == data ? "rgb(84, 168, 216)" : "rgb(83, 91, 110)"),
                ],
            },
        ],
    };

    const captureElementAtEvent = (element) => {

        if (!element.length) return;


        const { index } = element[0];
        setBackgroundColorIndex(index);
        setCountry(dealStatusData.labels[index]);
    };

    const onClick = (event) => {
        const { current: chart } = mcoChartRef;
        if (!chart) return;
        captureElementAtEvent(getElementAtEvent(chart, event));
    };

    if (isLoading) return <Loader />;
    if (!chartData) return null;
    return (
        <Chart
            ref={mcoChartRef}
            type="bar"
            options={options}
            data={dealStatusData}
            onClick={onClick}
        />
    );

}

export default PomDealStatusYChart