export const COLUMNS = [
    {
      Header: "Group Name",
      accessor: "group_name",
      show: true,
      editable: false,
    },
    // {
    //   Header: "Group Type",
    //   accessor: "group_type",
    //   show: true,
    //   editable: false,
    // },
    {
      Header: "# Members",
      accessor: "members_list",
      show: true,
      editable: false,
    },
    {
      Header: "Members",
      accessor: "total_members",
      show: true,
      editable: false,
      // Cell: (cellObj)=>{
      //   console.log(cellObj)
      //   return(
      //     <select className="input-edit-mode width-150">
      //       <option hidden>
      //           see members
      //         </option>
      //       {cellObj.value.map(i=>{return(
      //         <option disabled>
      //           {i}
      //         </option>
      //       )              
      //       })}
      //     </select>
      //   )        
      // }
    },
    {
      Header: "Admin Name",
      accessor: "admin_name",
      show: true,
      editable: false,
    },
    // {
    //   Header: "MCO",
    //   accessor: "mco",
    //   show: true,
    //   editable: false,
    //   // Cell: (cellObj)=>{
    //   //   console.log(cellObj)
    //   //   return(
    //   //     <select className="input-edit-mode width-110">
    //   //       {cellObj.value.map(i=>{return(
    //   //         <option>
    //   //           {i}
    //   //         </option>
    //   //       )              
    //   //       })}
    //   //     </select>
    //   //   )        
    //   // }
    // },
    // {
    //   Header: "BG",
    //   accessor: "bussiness_group",
    //   show: true,
    //   editable: false,
    //   // Cell: (cellObj)=>{
    //   //   console.log(cellObj)
    //   //   return(
    //   //     <select className="input-edit-mode width-150">
    //   //       {cellObj.value.map(i=>{return(
    //   //         <option>
    //   //           {i}
    //   //         </option>
    //   //       )              
    //   //       })}
    //   //     </select>
    //   //   )        
    //   // }
    // },
];