import React from 'react'
import ULModal from '../../../Common/Modal'
import ForecastBiasL12WBarGraph from './ForecastBiasL12WBarGraph'
import ForecastBiasL4WBarGraph from './ForecastBiasL4WBarGraph'
import MitigationActionBarGraph from './MitigationActionBarGraph'
import NegitiveBiasBarChart from './NegitiveBiasBarChart'
import RiskLevelBarGraph from './RiskLevelBarGraph'
import RiskTypeGraph from './RiskTypeGraph'
import { LegendMarginPlugin } from "../../InflowRiskVisibility/plugins";
import "chart.js/auto";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Popup from 'reactjs-popup'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    LegendMarginPlugin
);


function InflowModelCharts(props) {
    const displayType = "popupDisplay"

    return (
        <div >
            <ULModal
                show={props.showModal}
                onHide={props.handleClose}
                size="lg"
                isheader="true"
                className={`page-modal`}
                heading={
                    props.chartType === "ForecastBiasL12WBarGraph" ? "Forecast Bias L12W" : 
                    props.chartType === "ForecastBiasL4WBarGraph" ? "Forecast Bias L4W" : 
                    props.chartType === "MitigationActionBarGraph" ? "Mitigation Actions" :
                    props.chartType === "NegitiveBiasBarChart" ? "Negative Bias" :
                    props.chartType === "RiskLevelBarGraph" ? "DoH vs ISL Risk Level" : "Risk Type" }
                subheading={"(Count of material codes)"}
                subheadingInSameLine={true}
            >
                {props.chartType === "ForecastBiasL12WBarGraph" && <div >
                    <div className='smallgraphbg'>
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ForecastBiasL12WBarGraph dropdownValues={props.dropDownValues} displayType={displayType} filterValues={props.filterValues}/>
                        </div>
                    </div>
                </div>}
                {props.chartType === "ForecastBiasL4WBarGraph" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }} >
                            <ForecastBiasL4WBarGraph dropdownValues={props.dropDownValues} displayType={displayType} filterValues={props.filterValues}/>
                        </div>
                    </div>
                </div>}
                {props.chartType === "MitigationActionBarGraph" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <MitigationActionBarGraph dropdownValues={props.dropDownValues} displayType={displayType} filterValues={props.filterValues}/>
                        </div>
                    </div>
                </div>}
                {props.chartType === "NegitiveBiasBarChart" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <NegitiveBiasBarChart dropdownValues={props.dropDownValues} displayType={displayType}filterValues={props.filterValues} />
                        </div>
                    </div>
                </div>}
                {props.chartType === "RiskLevelBarGraph" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <RiskLevelBarGraph dropdownValues={props.dropDownValues} displayType={displayType} filterValues={props.filterValues}/>
                        </div>
                    </div>
                </div>}
                {props.chartType === "RiskTypeGraph" && <div >
                    <div className="smallgraphbg">
                        <div className="chartbox" style={{ height: "350px" }}>
                            <RiskTypeGraph dropdownValues={props.dropDownValues} displayType={displayType} filterValues={props.filterValues} />
                        </div>
                    </div>
                </div>}
            </ULModal>
        </div>
    )
}

export default InflowModelCharts